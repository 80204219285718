import { EUorUk, FuelType, LorB, LorU } from "../../../../../gen/graphql/types";

export type AdditionalROBForDeparture = {
  id?: number;
  D_Fuel_Type: FuelType;
  D_Fuel_volume?: number;
};

export type Form = {
  D_Vessel_Name: string | null;
  D_Voy: string | null;
  D_Last_Voy: string | null;
  D_LorB: LorB | null;
  D_Departure_Port: string | null;
  D_EU_UK_port?: EUorUk | null;
  D_Port_L_U: LorU;
  D_UNLOC: string | null;
  D_Departure_Date_Time: Date | null;
  D_RU_Date_Time: Date | null;
  D_TimeZone_PorN: string | null;
  D_TimeZone_HH: string | null;
  D_TimeZone_MM: string | null;
  D_ROB_FO: number | null;
  D_ROB_MGO: number | null;
  D_ROB_MECO: number | null;
  D_ROB_MESO: number | null;
  D_ROB_GESO: number | null;
  D_ROB_FW: number | null;
  D_Additional_ROB?: AdditionalROBForDeparture[];
  D_Cargo_Name: string | null;
  D_Cargo_Qtty: number | null;
  D_Draft_Fore: number | null;
  D_Draft_Aft: number | null;
  D_Next_Port_Name: string | null;
  D_ETA: Date | null;
  D_ETB: Date | null;
  D_ETC: Date | null;
  D_ETD: Date | null;
  D_Remark: string | null;
  D_Spare_1: string | null;
  D_Spare_1_unit_name: string | null;
  D_Spare_2: string | null;
  D_Spare_2_unit_name: string | null;
  D_Spare_3: string | null;
  D_Spare_3_unit_name: string | null;
  D_Spare_4: string | null;
  D_Spare_4_unit_name: string | null;
  D_Spare_5: string | null;
  D_Spare_5_unit_name: string | null;
  D_Spare_6: string | null;
  D_Spare_6_unit_name: string | null;
  D_Spare_7: string | null;
  D_Spare_7_unit_name: string | null;
  D_Spare_8: string | null;
  D_Spare_8_unit_name: string | null;
  D_Spare_9: string | null;
  D_Spare_9_unit_name: string | null;
  D_Spare_10: string | null;
  D_Spare_10_unit_name: string | null;
  D_Spare_11: string | null;
  D_Spare_11_unit_name: string | null;
  D_Spare_12: string | null;
  D_Spare_12_unit_name: string | null;
  D_Spare_13: string | null;
  D_Spare_13_unit_name: string | null;
  D_Spare_14: string | null;
  D_Spare_14_unit_name: string | null;
  D_Spare_15: string | null;
  D_Spare_15_unit_name: string | null;
  D_Spare_16: string | null;
  D_Spare_16_unit_name: string | null;
  D_Spare_17: string | null;
  D_Spare_17_unit_name: string | null;
  D_Spare_18: string | null;
  D_Spare_18_unit_name: string | null;
  D_Spare_19: string | null;
  D_Spare_19_unit_name: string | null;
  D_Spare_20: string | null;
  D_Spare_20_unit_name: string | null;
};

export type Departure = {
  id: number;
  D_Vessel_Name: string;
  D_Voy: string;
  D_Last_Voy: string;
  D_LorB: LorB;
  D_Departure_Port: string;
  D_EU_UK_port?: EUorUk | null;
  D_Port_L_U?: LorU | null;
  D_UNLOC?: string | null;
  D_Departure_Date_Time: Date;
  D_RU_Date_Time: Date;
  D_Time_Zone: string;
  D_ROB_FO: number;
  D_ROB_MGO: number;
  D_ROB_MECO: number;
  D_ROB_MESO: number;
  D_ROB_GESO: number;
  D_ROB_FW: number;
  D_Cargo_Name: string;
  D_Cargo_Qtty: number;
  D_Draft_Fore: number;
  D_Draft_Aft: number;
  D_Next_Port_Name: string;
  D_ETA: Date;
  D_ETB: Date;
  D_ETC: Date;
  D_ETD: Date;
  D_Remark?: string | null | undefined;
  D_Spare_1?: string | null | undefined;
  D_Spare_1_unit_name?: string | null | undefined;
  D_Spare_2?: string | null | undefined;
  D_Spare_2_unit_name?: string | null | undefined;
  D_Spare_3?: string | null | undefined;
  D_Spare_3_unit_name?: string | null | undefined;
  D_Spare_4?: string | null | undefined;
  D_Spare_4_unit_name?: string | null | undefined;
  D_Spare_5?: string | null | undefined;
  D_Spare_5_unit_name?: string | null | undefined;
  D_Spare_6?: string | null | undefined;
  D_Spare_6_unit_name?: string | null | undefined;
  D_Spare_7?: string | null | undefined;
  D_Spare_7_unit_name?: string | null | undefined;
  D_Spare_8?: string | null | undefined;
  D_Spare_8_unit_name?: string | null | undefined;
  D_Spare_9?: string | null | undefined;
  D_Spare_9_unit_name?: string | null | undefined;
  D_Spare_10?: string | null | undefined;
  D_Spare_10_unit_name?: string | null | undefined;
  D_Spare_11?: string | null | undefined;
  D_Spare_11_unit_name?: string | null | undefined;
  D_Spare_12?: string | null | undefined;
  D_Spare_12_unit_name?: string | null | undefined;
  D_Spare_13?: string | null | undefined;
  D_Spare_13_unit_name?: string | null | undefined;
  D_Spare_14?: string | null | undefined;
  D_Spare_14_unit_name?: string | null | undefined;
  D_Spare_15?: string | null | undefined;
  D_Spare_15_unit_name?: string | null | undefined;
  D_Spare_16?: string | null | undefined;
  D_Spare_16_unit_name?: string | null | undefined;
  D_Spare_17?: string | null | undefined;
  D_Spare_17_unit_name?: string | null | undefined;
  D_Spare_18?: string | null | undefined;
  D_Spare_18_unit_name?: string | null | undefined;
  D_Spare_19?: string | null | undefined;
  D_Spare_19_unit_name?: string | null | undefined;
  D_Spare_20?: string | null | undefined;
  D_Spare_20_unit_name?: string | null | undefined;
};

export type Result = {
  succeed: boolean;
  code: string | null;
};

export type LocationState = {
  form?: Form;
  result?: Result;
};

export type Props = {
  form: Form;
};

export const initializeDeparture = {
  ship_id: null,
  user_id: null,
  D_Vessel_Name: null,
  D_Voy: null,
  D_Last_Voy: null,
  D_LorB: LorB.Ballast,
  D_Departure_Port: null,
  D_EU_UK_port: null,
  D_Port_L_U: LorU.N,
  D_UNLOC: "",
  D_Departure_Date_Time: null,
  D_RU_Date_Time: null,
  D_TimeZone_PorN: "+",
  D_TimeZone_HH: "00",
  D_TimeZone_MM: "00",
  D_ROB_FO: null,
  D_ROB_MGO: null,
  D_ROB_MECO: null,
  D_ROB_MESO: null,
  D_ROB_GESO: null,
  D_ROB_FW: null,
  D_Cargo_Name: null,
  D_Cargo_Qtty: null,
  D_Draft_Fore: null,
  D_Draft_Aft: null,
  D_Next_Port_Name: null,
  D_ETA: null,
  D_ETB: null,
  D_ETC: null,
  D_ETD: null,
  D_Remark: null,
  D_Spare_1: null,
  D_Spare_1_unit_name: null,
  D_Spare_2: null,
  D_Spare_2_unit_name: null,
  D_Spare_3: null,
  D_Spare_3_unit_name: null,
  D_Spare_4: null,
  D_Spare_4_unit_name: null,
  D_Spare_5: null,
  D_Spare_5_unit_name: null,
  D_Spare_6: null,
  D_Spare_6_unit_name: null,
  D_Spare_7: null,
  D_Spare_7_unit_name: null,
  D_Spare_8: null,
  D_Spare_8_unit_name: null,
  D_Spare_9: null,
  D_Spare_9_unit_name: null,
  D_Spare_10: null,
  D_Spare_10_unit_name: null,
  D_Spare_11: null,
  D_Spare_11_unit_name: null,
  D_Spare_12: null,
  D_Spare_12_unit_name: null,
  D_Spare_13: null,
  D_Spare_13_unit_name: null,
  D_Spare_14: null,
  D_Spare_14_unit_name: null,
  D_Spare_15: null,
  D_Spare_15_unit_name: null,
  D_Spare_16: null,
  D_Spare_16_unit_name: null,
  D_Spare_17: null,
  D_Spare_17_unit_name: null,
  D_Spare_18: null,
  D_Spare_18_unit_name: null,
  D_Spare_19: null,
  D_Spare_19_unit_name: null,
  D_Spare_20: null,
  D_Spare_20_unit_name: null,
};
