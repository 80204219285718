import React, { useMemo } from "react";
import { useOrganizationQuery } from "../../gen/graphql/types";
import Loading from "./Loading";

const useOwnerName = () => {
  const { data: response, loading: isLoading } = useOrganizationQuery({});
  const ownerName = useMemo(() => {
    return response?.organization?.name || "";
  }, [response]);

  return {
    isLoading,
    ownerName,
  };
};

const OwnerName = (): React.ReactElement => {
  const organization = useOwnerName();

  return (
    <>
      <Loading inner isLoading={organization.isLoading} zIndex={1} />
      {organization.ownerName}
    </>
  );
};

export default OwnerName;
