import {
  SpeedForm,
  FocForm,
} from "../../../../pages/Owner/Analysis/Setting/Create/types";
import { Chart } from "react-google-charts";
import { getSettingGraphData } from "../../../../pages/Owner/Analysis/Setting/settingSpeedData";
import { getSettingFocGraphData } from "../../../../pages/Owner/Analysis/Setting/settingFocData";
import {
  hotPink,
  paleGray,
  skyBlue,
  vividGreen,
  weightedGray,
} from "../../../../../utils/color";
import { Box } from "@mui/material";
import { memo } from "react";
import { compareForms } from "../../../../../utils/form-util";

type GraphProps = {
  form?: SpeedForm;
  focForm?: FocForm;
};

export const SettingReportView = memo(
  function ReportView(props: GraphProps) {
    let report: (string | number | null)[][] = [];
    if (props.form !== undefined) {
      report = [];
      report = getSettingGraphData(props.form);
    } else if (props.focForm !== undefined) {
      report = [];

      report = getSettingFocGraphData(props.focForm);
    }

    if (report.length === 0) return <></>;

    const options = {
      curveType: "function",
      theme: {
        chartArea: { width: "92%", height: "85%", right: 7 },
      },
      legend: {
        position: "none",
      },
      hAxis: {
        title: "Speed[knot]",
        viewWindow: {
          min: 0,
        },
        viewWindowMode: "maximized",
        titleTextStyle: {
          color: weightedGray,
          fontName: "Public Sans",
          italic: false,
        },
      },
      vAxis: {
        title: "Fuel consumption[mt]",
        viewWindow: {
          min: 0,
        },
        viewWindowMode: "maximized",
        titleTextStyle: {
          color: weightedGray,
          fontName: "Public Sans",
          italic: false,
        },
      },
      backgroundColor: paleGray,
      series: {
        0: {
          lineWidth: 0,
          color: vividGreen,
          pointSize: 10,
          pointShape: "square",
        },
        1: {
          lineWidth: 0,
          color: skyBlue,
          pointSize: 10,
          pointShape: "triangle",
        },
        2: {
          lineWidth: 0,
          color: hotPink,
          pointSize: 10,
          pointShape: "diamond",
        },
        3: { type: "line", lineWidth: 1.5, color: vividGreen, pointSize: 0 },
        4: { type: "line", lineWidth: 1.5, color: skyBlue, pointSize: 0 },
        5: { type: "line", lineWidth: 1.5, color: hotPink, pointSize: 0 },
      },
    };
    return (
      <Box justifyContent={"center"}>
        <Chart
          chartType="ScatterChart"
          width="1198px"
          height="500px"
          data={report}
          options={options}
        />
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      compareForms(prevProps.focForm, nextProps.focForm) &&
      compareForms(prevProps.form, nextProps.form)
    );
  }
);
