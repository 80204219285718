import { TextField } from "@mui/material";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { isDate } from "../../utils/type-checks";

type Props = {
  id: string;
  label: string;
  value: Date | null | undefined;
  onChange: (
    key: string,
    value: string | number | Date | null | undefined
  ) => void;
  onChangeKey: string;
  width?: string;
  isConfirm?: boolean;
  hasMinDate: boolean;
  required?: boolean;
};
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Etc/GMT");

const valueCheck = (props: Props) => {
  if (!props.value) {
    if (!props.isConfirm) {
      return {
        isError: false,
        errorMessage: "",
      };
    } else {
      return {
        isError: true,
        errorMessage: "This field is required.",
      };
    }
  } else {
    if (!isDate(props.value))
      return {
        isError: true,
        errorMessage: "Invalid date-time format.",
      };
  }

  return {
    isError: false,
    errorMessage: "",
  };
};

const UtcDateTimePicker = (props: Props) => {
  const { isError, errorMessage } = valueCheck(props);
  const minDate = dayjs().subtract(1, "month");

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateLibInstance={dayjs.utc}
    >
      <DesktopDateTimePicker
        inputFormat="YYYY/MM/DD H:mm"
        disableMaskedInput={true}
        ampm={false}
        renderInput={(renderProps) => (
          <TextField
            required={props.required}
            {...renderProps}
            size="small"
            error={isError}
            helperText={errorMessage}
            inputProps={{
              ...renderProps.inputProps,
              "data-testid": props.id,
            }}
            sx={{
              width: `${props.width || "22ch"}}`,
              color: "#FFFFFF",
              "& .MuiInputBase-input": { color: "#FFFFFF" },
              "& label.MuiInputLabel-root": {
                color: "#AAAAAA",
              },
              "& label.Mui-focused": {
                color: "#20BE89",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#20BE89",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#464E5A",
                },
                "&:hover fieldset": {
                  borderColor: "#AAAAAA",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#20BE89",
                },
                "&.Mui-error fieldset": {
                  borderColor: "#F44336",
                },
              },
              "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
                backgroundColor: "#20BE89 !important",
              },
            }}
          />
        )}
        {...(props.hasMinDate && { minDate })}
        label={props.label}
        value={props.value || null}
        onChange={(date) => {
          props.onChange(props.onChangeKey, date ? dayjs(date).toDate() : date);
        }}
      />
    </LocalizationProvider>
  );
};

export default UtcDateTimePicker;
