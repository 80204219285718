import { DepartureDexie } from "../../../../dexie/Departure";
import {
  QueryStatus,
  useCreateDepartureListMutation,
  DepartureInput,
} from "../../../../gen/graphql/types";
import { nonNullable } from "../../../../utils/formats";
import { checkQueryStatus } from "../../pages.module";
import { updateFrontDeparture } from "./SendDataUtil";
import { ErrorMessageData } from "../../../../hooks/errorMessage.hook";

type Props = {
  checkedDepartureList: DepartureDexie[];
  setCheckedDeparture: (value: React.SetStateAction<DepartureDexie[]>) => void;
  setCheckedBoxDeparture: (value: React.SetStateAction<number[]>) => void;
  createErrorData: (
    dataType: string,
    dataArr: unknown[]
  ) => {
    loading: boolean;
  };
  addErrorMessage: (newErrorMessage: ErrorMessageData) => void;
};

export const useCreateDepartureListMutationHook = () => {
  const [createDeparture, { loading: isDepartureLoading }] =
    useCreateDepartureListMutation();

  const createDepartureMutation = (props: Props) => {
    const departureVariables: DepartureInput[] = props.checkedDepartureList
      .map((departure) => departure.data)
      .filter(nonNullable);

    createDeparture({
      variables: {
        input: {
          DepartureListInput: departureVariables,
        },
      },
      onCompleted: async (data) => {
        try {
          checkQueryStatus(data.createDepartureList?.CreateDepartureListStatus);

          if (!data.createDepartureList) {
            throw new Error(
              "Server Error! createDepartureList response is empty."
            );
          } else if (!data.createDepartureList.createdDepartureCount) {
            throw new Error(
              "Server Error! createDepartureList createdDepartureCount is 0."
            );
          } else if (
            data.createDepartureList.CreateDepartureListStatus ===
            QueryStatus.Success
          ) {
            console.info("createDeparture Success!");
          }

          if (
            props.checkedDepartureList.length ===
            data.createDepartureList?.createdDepartureCount
          ) {
            props.checkedDepartureList.forEach((departure) => {
              updateFrontDeparture(departure);
            });
          }
          props.setCheckedDeparture([]);
          props.setCheckedBoxDeparture([]);
        } catch (e) {
          console.error(e);
          props.createErrorData("Departure", departureVariables);
          props.addErrorMessage({
            input: JSON.stringify(departureVariables),
            errorMessage: `Server Error! createDepartureList onCompleted:${e}`,
          });
        }
      },
      onError: (error) => {
        console.error(error);
        //エラーinputデータ送信
        props.createErrorData("Departure", departureVariables);
        props.addErrorMessage({
          input: JSON.stringify(departureVariables),
          errorMessage: `Server Error! createDepartureList onError:${error}`,
        });
      },
    });
  };
  return [{ createDepartureMutation, isDepartureLoading }];
};
