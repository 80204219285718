import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Form } from "../pages/Crew/NoonReport/Create/types";
import { useEffect, useState } from "react";

type Props = {
  id: string;
  label: string;
  value?: string | null;
  defaultValue: string | null;
  value1: string;
  value2: string;
  label1: string;
  label2: string;
  onUpdateForm?: (value: Form) => void;
  onChange: (
    key: string,
    value: string | number | Date | null | undefined
  ) => void;
};

const RadioButtun = (props: Props) => {
  const [selectedValue, setSelectedValue] = useState(
    props.defaultValue || props.value1
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    props.onChange(props.id, event.target.value);
  };

  useEffect(() => {
    if (props.value) setSelectedValue(props.value);
  }, [props.value]);

  return (
    <FormControl>
      <FormLabel
        id={props.id}
        sx={{
          color: "#AAAAAA",
          textAlign: "left",
          "&.Mui-focused": {
            color: "#AAAAAA",
          },
        }}
      >
        {props.label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleChange}
        value={selectedValue}
      >
        <FormControlLabel
          value={props.value1}
          control={
            <Radio
              inputProps={{
                "aria-label": `${props.id}_${props.value1}`,
              }}
              sx={{
                color: "#AAAAAA",
                "&.Mui-checked": {
                  color: "#20BE89",
                },
              }}
            />
          }
          sx={{
            color: "#AAAAAA",
          }}
          label={props.label1}
        />
        <FormControlLabel
          value={props.value2}
          control={
            <Radio
              inputProps={{
                "aria-label": `${props.id}_${props.value2}`,
              }}
              sx={{
                color: "#AAAAAA",
                "&.Mui-checked": {
                  color: "#20BE89",
                },
              }}
            />
          }
          label={props.label2}
          sx={{
            color: "#AAAAAA",
          }}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtun;
