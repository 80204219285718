import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Nowrap from "../../../atoms/Nowrap";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { formatToUtcYYYYMMDDHHMM } from "../../../../utils/formats";
import NowrapGray from "../../../atoms/NowrapGray";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import GrayButton from "../../../atoms/GrayButton";
import VesselNamesSelect from "../../../atoms/VesselNamesSelect";
import { Departure } from "../../../pages/Crew/Departure/Create/types";
import SucsessButton from "../../../atoms/SucsessButton";
import CsvDownloadModal from "../../../molecules/Owner/CsvDownloadModal";
import Loading from "../../../atoms/Loading";
import UtcDateTimePicker from "../../../atoms/UtcDateTimePicker";
import { ReportSearchCondition } from "../../../pages/pages.module";

type ConfirmProps = {
  hasFormError: boolean;
  departureList: Departure[];
  isLoading: boolean;
  onUpdateForm(value: ReportSearchCondition): void;
  form: ReportSearchCondition;
  onSearch: () => void;
  onUpdateSearchCondition(
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ): void;
  totalPageCount: number;
  onCsvDownload(): void;
  csvForm: ReportSearchCondition;
  onUpdateCsvForm(value: ReportSearchCondition): void;
  hasCsvFormError: boolean;
};

type CustomProps = {
  children?: React.ReactNode;
};

const CustomNowrap = (props: CustomProps) => {
  return (
    <NowrapGray color="#808080" borderLeftColor="#C9D0DC">
      {props.children}
    </NowrapGray>
  );
};

const DepartureReport = (props: ConfirmProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onChange = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    const form = {
      ...props.form,
      [key]: value,
    };

    props.onUpdateForm?.(form);
  };

  const onChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
    if (!value) {
      return;
    }

    props.form.pagination.page = value;

    props.onUpdateForm?.(props.form);
    props.onUpdateSearchCondition(props.form, true);
  };

  const onClickSeach = () => {
    props.onSearch();
  };

  return (
    <React.Fragment>
      <Loading isLoading={props.isLoading} />
      <Paper sx={{ borderRadius: "20px", backgroundColor: "#ECEEF2", mb: 5 }}>
        <Columns spaceBetween sx={{ pt: 1, mt: 0, mx: 2, mb: 0, fontSize: 24 }}>
          <Column>Departure Report List </Column>
          <SucsessButton
            ButtonName={"CSV Download"}
            disabled={false}
            onClick={() => setIsModalOpen(true)}
            sx={{ my: 1.5, fontSize: 16, color: "#FFFFFF" }}
          />
        </Columns>
        <Columns sx={{ mt: 0 }}>
          <Column sx={{ ml: 2 }}>
            <VesselNamesSelect
              value={props.form.imo_no ?? ""}
              minWidth={323}
              onChange={(event) => {
                onChange("imo_no", event.target.value);
              }}
            />
          </Column>
          <Column>
            <CommonTextFIeld
              id={"Voy"}
              label={"Voy#"}
              value={props.form.Voy}
              onChange={(e: string | null) => {
                onChange("Voy", e);
              }}
              width={"130px"}
              sx={{ "& .MuiInputBase-input": { color: "#333333 !important" } }}
            />
          </Column>
          <Column sx={{ ml: 2 }}>
            <Typography
              sx={{
                pt: 1,
                textAlign: "left",
                verticalAlign: "middle",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#aaaaaa",
              }}
            >
              From
            </Typography>
          </Column>
          <Column>
            <UtcDateTimePicker
              id={"From_DateTime"}
              label={"Date & Time"}
              value={props.form.From_DateTime}
              onChange={onChange}
              onChangeKey={"From_DateTime"}
              hasMinDate={false}
            />
          </Column>
          <Column>
            <Typography
              sx={{
                pt: 1,
                textAlign: "left",
                verticalAlign: "middle",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#aaaaaa",
              }}
            >
              To
            </Typography>
          </Column>
          <Column>
            <UtcDateTimePicker
              id={"To_DateTime"}
              label={"Date & Time"}
              value={props.form.To_DateTime}
              onChange={onChange}
              onChangeKey={"To_DateTime"}
              hasMinDate={false}
            />
          </Column>
          <Column>
            <GrayButton
              ButtonName={"Search"}
              disabled={false}
              width={"100px"}
              onClick={() => onClickSeach()}
              sx={{ backgroundColor: "#9FA6B0" }}
            />
          </Column>
        </Columns>

        <Box sx={{ px: 2 }}>
          <Table size="small" sx={{ mx: 1, color: "#333333" }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ mx: 1, color: "#333333" }}>
                  <CustomNowrap>ID</CustomNowrap>
                </TableCell>

                <TableCell>
                  <CustomNowrap>Vessel Name</CustomNowrap>
                </TableCell>
                <TableCell>
                  <CustomNowrap>Voy#</CustomNowrap>
                </TableCell>
                <TableCell>
                  <CustomNowrap>Last Voy#</CustomNowrap>
                </TableCell>
                <TableCell>
                  <CustomNowrap>L or B</CustomNowrap>
                </TableCell>
                <TableCell>
                  <CustomNowrap>{`Date & Time`}</CustomNowrap>
                </TableCell>
                <TableCell>
                  <CustomNowrap>Time Zone</CustomNowrap>
                </TableCell>

                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            {props.departureList.length ? (
              <TableBody>
                {props.departureList.map((departure) => {
                  return (
                    <TableRow key={departure.id}>
                      <TableCell>
                        <Link
                          style={{ color: "#20BE89" }}
                          to={{
                            pathname: `/owner/departure/detail/${departure.id}`,
                          }}
                        >
                          <Nowrap>{departure.id}</Nowrap>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Nowrap>{departure.D_Vessel_Name}</Nowrap>
                      </TableCell>
                      <TableCell>
                        <Nowrap>{departure.D_Voy}</Nowrap>
                      </TableCell>
                      <TableCell>
                        <Nowrap>{departure.D_Last_Voy}</Nowrap>
                      </TableCell>
                      <TableCell>
                        <Nowrap>{departure.D_LorB}</Nowrap>
                      </TableCell>
                      <TableCell>
                        <Nowrap>
                          {formatToUtcYYYYMMDDHHMM(
                            departure.D_Departure_Date_Time
                          )}
                        </Nowrap>
                      </TableCell>
                      <TableCell>
                        <Nowrap>{departure.D_Time_Zone}</Nowrap>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <></>
            )}
          </Table>
          <Box sx={{ px: 1, py: 1 }}>
            <Pagination
              count={Math.ceil(
                props.totalPageCount / props.form.pagination.limit
              )}
              page={props.form.pagination.page}
              onChange={onChangePage}
            />
          </Box>
          <CsvDownloadModal
            isOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onCsvDownload={props.onCsvDownload}
            csvForm={props.csvForm}
            onUpdateCsvForm={props.onUpdateCsvForm}
            hasCsvFormError={props.hasCsvFormError}
          />
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default DepartureReport;
