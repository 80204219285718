import React, { useState } from "react";
import Box from "@mui/material/Box";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import {
  BunkeringInformation,
  Props,
} from "../../../pages/Crew/Bunkering/Create/types";
import {
  Link,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Steppr from "../../../atoms/Stepper";
import CommonText from "../../../atoms/CommonText";
import { ReportDexie } from "../../../../dexie/Report";
import GrayButton from "../../../atoms/GrayButton";
import { useNavigate, useParams } from "react-router";
import { formatToUtcYYYYMMDDHHMM } from "../../../../utils/formats";
import { pageData } from "../../../../App";
import CustomTableCell from "../../../atoms/CustomTableCell";
import { TableDataRow } from "../../../molecules/Crew/Detail/Detail";
import { getFileName, getLatLong } from "../../../../utils/form-util";
import DeleteModal from "../../../atoms/DeleteModal";
import { Table4DataRow } from "../../../molecules/Crew/Detail/Detail";
import { lightGray, white } from "../../../../utils/color";
import { isNumeric } from "../../../../utils/type-checks";
import { useDownloadBunkeringFile } from "../../Owner/Bunkering/downloadFile.hook";
import { downLoadDexieBunkeringfile } from "./downLoadBunkeringfile";

type FormBlockProps = Props & {
  pageTitle: string;
  isPreview: boolean;
  previousReport?: ReportDexie | undefined;
  deleteReport?(): void;
  isSend: boolean;
  isServer: boolean;
};

const createBunkeringDataArray = (form: BunkeringInformation) => {
  const bunkeringArray = [];

  // 10回のループを実行して各Bunkeringの入力データをまとめる（未入力含む）
  for (let i = 1; i <= 10; i++) {
    const fuelName = form[`B_Fuel_name_${i}` as keyof typeof form];
    const mass = form[`B_Mass_${i}` as keyof typeof form];
    const volume = form[`B_Volume_${i}` as keyof typeof form];
    const density = form[`B_Density_${i}` as keyof typeof form];

    const bunkeringData = {
      B_Fuel_name: fuelName,
      B_Mass: mass,
      B_Volume: volume,
      B_Density: density,
    };
    bunkeringArray.push(bunkeringData);
  }

  return bunkeringArray;
};

const Detail = (props: FormBlockProps): React.ReactElement => {
  const navigate = useNavigate();
  const { reportId } = useParams<"reportId">();
  const report_id = isNumeric(reportId) ? Number(reportId) : 0;
  const previousReport = props.previousReport?.data;
  const [modalOpen, setIsModalOpen] = useState(false);
  const downloadBunkeringFile = useDownloadBunkeringFile(report_id);

  const onClickDelete = () => {
    if (props.deleteReport) {
      props.deleteReport();
    }
  };

  const {
    B_Fuel_name_1,
    B_Mass_1,
    B_Volume_1,
    B_Density_1,
    B_Fuel_name_2,
    B_Mass_2,
    B_Volume_2,
    B_Density_2,
    B_Fuel_name_3,
    B_Mass_3,
    B_Volume_3,
    B_Density_3,
    B_Fuel_name_4,
    B_Mass_4,
    B_Volume_4,
    B_Density_4,
    B_Fuel_name_5,
    B_Mass_5,
    B_Volume_5,
    B_Density_5,
    B_Fuel_name_6,
    B_Mass_6,
    B_Volume_6,
    B_Density_6,
    B_Fuel_name_7,
    B_Mass_7,
    B_Volume_7,
    B_Density_7,
    B_Fuel_name_8,
    B_Mass_8,
    B_Volume_8,
    B_Density_8,
    B_Fuel_name_9,
    B_Mass_9,
    B_Volume_9,
    B_Density_9,
    B_Fuel_name_10,
    B_Mass_10,
    B_Volume_10,
    B_Density_10,
  } = props.form;

  const bunkeringInformation = {
    B_Fuel_name_1,
    B_Mass_1,
    B_Volume_1,
    B_Density_1,
    B_Fuel_name_2,
    B_Mass_2,
    B_Volume_2,
    B_Density_2,
    B_Fuel_name_3,
    B_Mass_3,
    B_Volume_3,
    B_Density_3,
    B_Fuel_name_4,
    B_Mass_4,
    B_Volume_4,
    B_Density_4,
    B_Fuel_name_5,
    B_Mass_5,
    B_Volume_5,
    B_Density_5,
    B_Fuel_name_6,
    B_Mass_6,
    B_Volume_6,
    B_Density_6,
    B_Fuel_name_7,
    B_Mass_7,
    B_Volume_7,
    B_Density_7,
    B_Fuel_name_8,
    B_Mass_8,
    B_Volume_8,
    B_Density_8,
    B_Fuel_name_9,
    B_Mass_9,
    B_Volume_9,
    B_Density_9,
    B_Fuel_name_10,
    B_Mass_10,
    B_Volume_10,
    B_Density_10,
  };

  const bunkeringArray = createBunkeringDataArray(bunkeringInformation);

  return (
    <Box component="form" noValidate autoComplete="off">
      <Box sx={{ pt: 0.5, mx: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "top",
            justifycontent: "right",
            pb: 1,
          }}
        >
          <Column>
            <Typography
              component="h3"
              variant="subtitle1"
              align="center"
              sx={{
                lineHeight: 1,
                fontWeight: "bold",
                color: "#fff",
                fontSize: "1.5rem",
                verticalAlign: "super",
              }}
            >
              {props.pageTitle}
            </Typography>
          </Column>

          {props.isPreview || (
            <Column sx={{ width: "60%" }}>
              <Steppr step={1} />
            </Column>
          )}
          {props.isPreview && !props.isSend && (
            <Box
              sx={{
                display: "flex",
                marginLeft: "auto",
                mb: -2,
              }}
            >
              <Column>
                <GrayButton
                  ButtonName={"Edit"}
                  disabled={false}
                  onClick={() =>
                    reportId
                      ? navigate(pageData.crewBunkeringUpdate.path(reportId))
                      : console.error("Invalid Report ID")
                  }
                />
              </Column>
              <Column>
                <GrayButton
                  ButtonName={"Delete"}
                  disabled={false}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                />
              </Column>
            </Box>
          )}
        </Columns>
      </Box>
      <CommonText
        sx={{ color: "#FFFFFF", pl: 3, margin: "left", textAlign: "left" }}
      >
        {props.isPreview || `Confirm input data & click “Save” button.`}
      </CommonText>
      <Box sx={{ px: 2 }}>
        <Table
          color="inherit"
          sx={{ minWidth: 600, mx: 0, color: "#FFFFFF" }}
          aria-label="spanning table"
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "#FFFFFF", height: "20px" }}>
              <CustomTableCell align="center" colSpan={3}></CustomTableCell>
              <CustomTableCell />
              <CustomTableCell sx={{ color: "#AAAAAA", fontSize: "12px" }}>
                <span
                  style={{
                    color: "#AAAAAA",
                    paddingBottom: "-16px",
                    marginBottom: "-16px",
                  }}
                >
                  Previous Data
                </span>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableDataRow
              title={"Voyage number"}
              data={props.form.B_Voy}
              previousData={previousReport?.N_Voy}
            />
            <TableDataRow
              title={"Time Zone (UTC±)"}
              data={
                String(props.form.B_TimeZone_PorN) +
                props.form.B_TimeZone_HH +
                ":" +
                props.form.B_TimeZone_MM
              }
              previousData={previousReport?.N_TimeZone}
            />
            <TableDataRow
              title={"EU/UK"}
              data={props.form.B_EUorUK}
              previousData={previousReport?.N_LorB}
            />
            <TableDataRow
              title={"Date & Time (Local)"}
              data={formatToUtcYYYYMMDDHHMM(props.form.B_Bunkering_Date_time)}
            />
            <TableDataRow
              title={"Lat./ Lon."}
              data={getLatLong(
                props.form.B_Lat_Deg,
                props.form.B_Lat_Min,
                props.form.B_Lat_Direction,
                props.form.B_Long_Deg,
                props.form.B_Long_Min,
                props.form.B_Long_Direction
              )}
            />
            <TableDataRow title={`L or U`} data={props.form.B_Port_LorU} />
            <TableDataRow title={`Port Name`} data={props.form.B_PortName} />
            <TableDataRow title={`UNLOC`} data={props.form.B_UNLOC} />
            {bunkeringArray.map((bunkeringData, i) => (
              <Table4DataRow
                key={i}
                topTitle={`Fuel type ${i + 1}`}
                title1={"Fuel name"}
                data1={bunkeringData.B_Fuel_name}
                title2={"Mass (mt)"}
                data2={bunkeringData.B_Mass}
                title3={"Volume (m3)"}
                data3={bunkeringData.B_Volume}
                title4={"Density (mt/m3)"}
                data4={bunkeringData.B_Density}
                unitName1={"mt"}
                unitName2={"m3"}
                unitName3={"mt/m3"}
              />
            ))}
            <TableRow>
              <CustomTableCell sx={{ color: lightGray, width: "250px" }}>
                {"FileName"}
              </CustomTableCell>
              <CustomTableCell />
              <CustomTableCell
                colSpan={2}
                sx={{
                  color: white,
                  wordBreak: "break-word",
                  whiteSpace: "pre-line",
                }}
              >
                {props.form.B_Filename && isNumeric(reportId) ? (
                  <Link
                    onClick={() => {
                      if (props.isServer) {
                        downloadBunkeringFile.onDownload();
                      } else if (!props.isServer) {
                        downLoadDexieBunkeringfile(
                          Number(reportId),
                          getFileName(props.form.B_Filename)
                        );
                      }
                    }}
                  >
                    {getFileName(props.form.B_Filename)}
                  </Link>
                ) : props.form.B_Filename ? (
                  getFileName(props.form.B_Filename)
                ) : (
                  "-"
                )}
              </CustomTableCell>
              <CustomTableCell sx={{ color: lightGray }}></CustomTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <DeleteModal
        isOpen={modalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteFrontData={onClickDelete}
        id={Number(reportId)}
        voy={props.form.B_Voy ? props.form.B_Voy : ""}
        DateTime={formatToUtcYYYYMMDDHHMM(props.form.B_Bunkering_Date_time)}
      />
    </Box>
  );
};

export default Detail;
