import * as React from "react";
import { Alert, Box, SxProps, Theme } from "@mui/material";
import { ErrorMessageData } from "../../hooks/errorMessage.hook";

type Props = {
  sx?: SxProps<Theme>;
  errorMessageList: ErrorMessageData[];
};

const ErrorMessageList = (props: Props): React.ReactElement => {
  return (
    <>
      {props.errorMessageList.map((v, idx) => (
        <Box
          key={idx}
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "96%",
            margin: "auto",
            ...props.sx,
          }}
        >
          <Alert severity="error" key={idx} sx={{ my: 2 }}>
            {v.errorMessage}
          </Alert>
        </Box>
      ))}
    </>
  );
};

export default ErrorMessageList;
