import {
  AnalysisSearchParameterDetail,
  SteamMode,
} from "../../../../../gen/graphql/types";
import { nonNullable } from "../../../../../utils/formats";
import {
  DefaultFilterCondition,
  SpeedConsumptionFilterCondition,
} from "../../../../pages/Owner/Analysis/queries";

export type UpdateFilterFormProps = {
  id: number;
  onUpdateFilterForm(value: SpeedConsumptionFilterCondition): void;
};

type OmittedAnalysisSearchParameterDetail = Omit<
  AnalysisSearchParameterDetail,
  "created_at" | "updated_at"
>;

export function updateFilterForm(
  filter_title: string,
  setCheckedMeRevolutionOver100: (title: string, value: boolean) => void,
  onUpdateFilterForm: (value: SpeedConsumptionFilterCondition) => void,
  detail: OmittedAnalysisSearchParameterDetail
) {
  setCheckedMeRevolutionOver100(filter_title, !nonNullable(detail.to_me_rpm));
  onUpdateFilterForm({
    imo_no: detail.imo_no,
    voy: detail.n_voy || null,
    from_dateTime: detail.from_dateTime,
    to_dateTime: detail.to_dateTime,
    bf_scale: setNumberArr(
      DefaultFilterCondition.bf_scale,
      detail.from_bf_scale,
      detail.to_bf_scale
    ),
    me_revolution: setNumberArr(
      DefaultFilterCondition.me_revolution,
      detail.from_me_rpm,
      detail.to_me_rpm
    ),
    hrs_propelling: setNumberArr(
      DefaultFilterCondition.hrs_propelling,
      detail.from_hrs_propelling,
      detail.to_hrs_propelling
    ),
    ballast_or_laden: detail.n_LorB || null,
    steam_mode: (detail.n_steam_mode as SteamMode) || null,
    from_to_period: 0,
  });
}

const setNumberArr = (
  defaultNumberArr: number[] | null,
  fromNumber?: number | null,
  toNumber?: number | null
) => {
  if (!nonNullable(fromNumber)) return defaultNumberArr;

  if (!nonNullable(toNumber)) {
    return [Number(fromNumber)];
  } else {
    return [Number(fromNumber), Number(toNumber)];
  }
};
