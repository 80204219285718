import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Template from "../../../templates/Crew/NoonReport/ServerUpdate";
import { AdditionalROB, Form } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import {
  LorB,
  SteamMode,
  GetReportQuery,
  useGetReportQuery,
  LorU,
} from "../../../../gen/graphql/types";
import { pageData, useContextIDs } from "../../../../App";
import { emptyToDoubleQuotation } from "../../../../utils/formats";
import Loading from "../../../atoms/Loading";
import {
  useNoonReportForm,
  useNoonReportFormValidation,
} from "./noonReport.module";
import { filterArrUndefinedNull } from "../../../../utils/type-checks";
import { setDefaultNullGgraphQLInputToForm } from "../../../../utils/graphql-mappimg";
import {
  getLatLongValues,
  getTimeZoneValues,
} from "../../../../utils/form-util";

const useGetReport = (report_id: number, ship_id: number) => {
  const input = { id: report_id, ship_id };

  const { data: response, loading: isLoading } = useGetReportQuery({
    variables: { input },
    fetchPolicy: "network-only",
  });

  const defaultTimeZone = response?.getReport?.N_TimeZone || "+00:00";
  const defaultLatLong = response?.getReport?.N_LatLong || "0-0N/0-0E";

  const form = useMemo(() => mapToFormData(response), [response]);

  return {
    isLoading,
    form,
    defaultTimeZone,
    defaultLatLong,
  };
};

const mapToFormData = (response?: GetReportQuery): Form | null => {
  if (!response) return null;

  const report = response.getReport;

  if (!report) return null;

  const { TimeZone_PorN, TimeZone_HH, TimeZone_MM } = getTimeZoneValues(
    report.N_TimeZone
  );

  const {
    Lat_Deg,
    Lat_Min,
    Lat_Direction,
    Long_Deg,
    Long_Min,
    Long_Direction,
  } = getLatLongValues(report.N_LatLong);

  return {
    N_Vessel_name: report.N_Vessel_name || "",
    N_Voy: report.N_Voy || "",
    N_LorB: report.N_LorB || LorB.Ballast,
    N_Noon_Port: report.N_Noon_Port || null,
    N_UNLOC: report.N_UNLOC || null,
    N_EU_UK_port: report.N_EU_UK_port || null,
    N_Port_L_U: report.N_Port_L_U || LorU.N,
    N_DateTime: report.N_DateTime,
    N_TimeZone_PorN: TimeZone_PorN,
    N_TimeZone_HH: TimeZone_HH,
    N_TimeZone_MM: TimeZone_MM,
    N_Lat_Direction: Lat_Direction,
    N_Lat_Deg: Lat_Deg,
    N_Lat_Min: Lat_Min,
    N_Long_Direction: Long_Direction,
    N_Long_Deg: Long_Deg,
    N_Long_Min: Long_Min,
    N_Steam_mode: report.N_Steam_mode || SteamMode.Full,
    N_Hrs_propelling: setDefaultNullGgraphQLInputToForm(
      report.N_Hrs_propelling
    ),
    N_Dist_OG: setDefaultNullGgraphQLInputToForm(report.N_Dist_OG),
    N_Speed_OG: setDefaultNullGgraphQLInputToForm(report.N_Speed_OG),
    N_Speed_OW: setDefaultNullGgraphQLInputToForm(report.N_Speed_OW),
    N_ME_rpm_ave: setDefaultNullGgraphQLInputToForm(report.N_ME_rpm_ave),
    N_ME_rpm_total: setDefaultNullGgraphQLInputToForm(report.N_ME_rpm_total),
    N_ME_Revolution: setDefaultNullGgraphQLInputToForm(report.N_ME_Revolution),
    N_Slip: setDefaultNullGgraphQLInputToForm(report.N_Slip),

    N_FO_Consumption_per_day_ME: report.N_FO_Consumption_per_day_ME,
    N_FO_Consumption_per_day_GE: report.N_FO_Consumption_per_day_GE,
    N_FO_Consumption_per_day_BInc: report.N_FO_Consumption_per_day_BInc,

    N_FO_Additional_FuelType_1: report.N_FO_Additional_FuelType_1,
    N_FO_Additional_Usage_1: report.N_FO_Additional_Usage_1,
    N_FO_Additional_volume_1: report.N_FO_Additional_volume_1,
    N_FO_Additional_FuelType_2: report.N_FO_Additional_FuelType_2,
    N_FO_Additional_Usage_2: report.N_FO_Additional_Usage_2,
    N_FO_Additional_volume_2: report.N_FO_Additional_volume_2,
    N_FO_Additional_FuelType_3: report.N_FO_Additional_FuelType_3,
    N_FO_Additional_Usage_3: report.N_FO_Additional_Usage_3,
    N_FO_Additional_volume_3: report.N_FO_Additional_volume_3,
    N_FO_Additional_FuelType_4: report.N_FO_Additional_FuelType_4,
    N_FO_Additional_Usage_4: report.N_FO_Additional_Usage_4,
    N_FO_Additional_volume_4: report.N_FO_Additional_volume_4,
    N_FO_Additional_FuelType_5: report.N_FO_Additional_FuelType_5,
    N_FO_Additional_Usage_5: report.N_FO_Additional_Usage_5,
    N_FO_Additional_volume_5: report.N_FO_Additional_volume_5,

    N_MGO_Consumption_per_day_ME: report.N_MGO_Consumption_per_day_ME,
    N_MGO_Consumption_per_day_GE: report.N_MGO_Consumption_per_day_GE,
    N_MGO_Consumption_per_day_Boiler: report.N_MGO_Consumption_per_day_Boiler,

    N_MGO_Additional_FuelType_1: report.N_MGO_Additional_FuelType_1,
    N_MGO_Additional_Usage_1: report.N_MGO_Additional_Usage_1,
    N_MGO_Additional_volume_1: report.N_MGO_Additional_volume_1,
    N_MGO_Additional_FuelType_2: report.N_MGO_Additional_FuelType_2,
    N_MGO_Additional_Usage_2: report.N_MGO_Additional_Usage_2,
    N_MGO_Additional_volume_2: report.N_MGO_Additional_volume_2,
    N_MGO_Additional_FuelType_3: report.N_MGO_Additional_FuelType_3,
    N_MGO_Additional_Usage_3: report.N_MGO_Additional_Usage_3,
    N_MGO_Additional_volume_3: report.N_MGO_Additional_volume_3,
    N_MGO_Additional_FuelType_4: report.N_MGO_Additional_FuelType_4,
    N_MGO_Additional_Usage_4: report.N_MGO_Additional_Usage_4,
    N_MGO_Additional_volume_4: report.N_MGO_Additional_volume_4,
    N_MGO_Additional_FuelType_5: report.N_MGO_Additional_FuelType_5,
    N_MGO_Additional_Usage_5: report.N_MGO_Additional_Usage_5,
    N_MGO_Additional_volume_5: report.N_MGO_Additional_volume_5,

    N_LO_Consumption_per_day_MECO: report.N_LO_Consumption_per_day_MECO,
    N_LO_Consumption_per_day_MESO: report.N_LO_Consumption_per_day_MESO,
    N_LO_Consumption_per_day_GESO: report.N_LO_Consumption_per_day_GESO,

    N_Other_Additional_FuelType_1: report.N_Other_Additional_FuelType_1,
    N_Other_Additional_Usage_1: report.N_Other_Additional_Usage_1,
    N_Other_Additional_volume_1: report.N_Other_Additional_volume_1,
    N_Other_Additional_FuelType_2: report.N_Other_Additional_FuelType_2,
    N_Other_Additional_Usage_2: report.N_Other_Additional_Usage_2,
    N_Other_Additional_volume_2: report.N_Other_Additional_volume_2,
    N_Other_Additional_FuelType_3: report.N_Other_Additional_FuelType_3,
    N_Other_Additional_Usage_3: report.N_Other_Additional_Usage_3,
    N_Other_Additional_volume_3: report.N_Other_Additional_volume_3,
    N_Other_Additional_FuelType_4: report.N_Other_Additional_FuelType_4,
    N_Other_Additional_Usage_4: report.N_Other_Additional_Usage_4,
    N_Other_Additional_volume_4: report.N_Other_Additional_volume_4,
    N_Other_Additional_FuelType_5: report.N_Other_Additional_FuelType_5,
    N_Other_Additional_Usage_5: report.N_Other_Additional_Usage_5,
    N_Other_Additional_volume_5: report.N_Other_Additional_volume_5,

    N_Weather: report.N_Weather,
    N_Wind_direction: report.N_Wind_direction,
    N_BF_scale: report.N_BF_scale,
    N_Swell: report.N_Swell,
    N_Noon_Course: report.N_Noon_Course,
    N_Dist_to_go: report.N_Dist_to_go,
    N_Next_port: report.N_Next_port,
    N_ETA: report.N_ETA,
    N_ETB: report.N_ETB,
    N_ETD: report.N_ETD,
    N_ME_Fuel_index: setDefaultNullGgraphQLInputToForm(report.N_ME_Fuel_index),
    N_ME_RPM: setDefaultNullGgraphQLInputToForm(report.N_ME_RPM),
    N_ME_horsepower: setDefaultNullGgraphQLInputToForm(report.N_ME_horsepower),
    N_ME_power: setDefaultNullGgraphQLInputToForm(report.N_ME_power),
    N_ME_power_measurement_method: report.N_ME_power_measurement_method || null,
    N_Running_hours_SOx_scrubber: setDefaultNullGgraphQLInputToForm(
      report.N_Running_hours_SOx_scrubber
    ),
    N_Running_hours_ME_EGR: setDefaultNullGgraphQLInputToForm(
      report.N_Running_hours_ME_EGR
    ),
    N_Running_hours_GE_SCR: report.N_Running_hours_GE_SCR,
    N_ROB_FO: report.N_ROB_FO,
    N_ROB_MGO: report.N_ROB_MGO,
    N_ROB_MECO: report.N_ROB_MECO,
    N_ROB_MESO: report.N_ROB_MESO,
    N_ROB_GESO: report.N_ROB_GESO,
    N_ROB_FW: report.N_ROB_FW,
    N_Additional_ROB: filterArrUndefinedNull(
      report.N_Additional_ROB?.map((v) => {
        if (!v) return null;
        return {
          id: v.id,
          N_Fuel_Type: v.N_Fuel_Type,
          N_Fuel_volume: v.N_Fuel_volume,
        } as unknown as AdditionalROB;
      })
    ),
    N_ROB_Filename: report.N_ROB_Filename || "",
    N_FO_CO2_emission_per_unit: setDefaultNullGgraphQLInputToForm(
      report.N_FO_CO2_emission_per_unit
    ),
    N_MGO_CO2_emission_per_unit: setDefaultNullGgraphQLInputToForm(
      report.N_MGO_CO2_emission_per_unit
    ),
    N_FO_CO2_emission: setDefaultNullGgraphQLInputToForm(
      report.N_FO_CO2_emission
    ),
    N_MGO_CO2_emission: setDefaultNullGgraphQLInputToForm(
      report.N_MGO_CO2_emission
    ),
    N_Remark: setDefaultNullGgraphQLInputToForm(report.N_Remark),
    N_Spare_1: emptyToDoubleQuotation(report.N_Spare_1),
    N_Spare_1_unit_name: emptyToDoubleQuotation(report.N_Spare_1_unit_name),
    N_Spare_2: emptyToDoubleQuotation(report.N_Spare_2),
    N_Spare_2_unit_name: emptyToDoubleQuotation(report.N_Spare_2_unit_name),
    N_Spare_3: emptyToDoubleQuotation(report.N_Spare_3),
    N_Spare_3_unit_name: emptyToDoubleQuotation(report.N_Spare_3_unit_name),
    N_Spare_4: emptyToDoubleQuotation(report.N_Spare_4),
    N_Spare_4_unit_name: emptyToDoubleQuotation(report.N_Spare_4_unit_name),
    N_Spare_5: emptyToDoubleQuotation(report.N_Spare_5),
    N_Spare_5_unit_name: emptyToDoubleQuotation(report.N_Spare_5_unit_name),
    N_Spare_6: emptyToDoubleQuotation(report.N_Spare_6),
    N_Spare_6_unit_name: emptyToDoubleQuotation(report.N_Spare_6_unit_name),
    N_Spare_7: emptyToDoubleQuotation(report.N_Spare_7),
    N_Spare_7_unit_name: emptyToDoubleQuotation(report.N_Spare_7_unit_name),
    N_Spare_8: emptyToDoubleQuotation(report.N_Spare_8),
    N_Spare_8_unit_name: emptyToDoubleQuotation(report.N_Spare_8_unit_name),
    N_Spare_9: emptyToDoubleQuotation(report.N_Spare_9),
    N_Spare_9_unit_name: emptyToDoubleQuotation(report.N_Spare_9_unit_name),
    N_Spare_10: emptyToDoubleQuotation(report.N_Spare_10),
    N_Spare_10_unit_name: emptyToDoubleQuotation(report.N_Spare_10_unit_name),
    N_Spare_11: emptyToDoubleQuotation(report.N_Spare_11),
    N_Spare_11_unit_name: emptyToDoubleQuotation(report.N_Spare_11_unit_name),
    N_Spare_12: emptyToDoubleQuotation(report.N_Spare_12),
    N_Spare_12_unit_name: emptyToDoubleQuotation(report.N_Spare_12_unit_name),
    N_Spare_13: emptyToDoubleQuotation(report.N_Spare_13),
    N_Spare_13_unit_name: emptyToDoubleQuotation(report.N_Spare_13_unit_name),
    N_Spare_14: emptyToDoubleQuotation(report.N_Spare_14),
    N_Spare_14_unit_name: emptyToDoubleQuotation(report.N_Spare_14_unit_name),
    N_Spare_15: emptyToDoubleQuotation(report.N_Spare_15),
    N_Spare_15_unit_name: emptyToDoubleQuotation(report.N_Spare_15_unit_name),
    N_Spare_16: emptyToDoubleQuotation(report.N_Spare_16),
    N_Spare_16_unit_name: emptyToDoubleQuotation(report.N_Spare_16_unit_name),
    N_Spare_17: emptyToDoubleQuotation(report.N_Spare_17),
    N_Spare_17_unit_name: emptyToDoubleQuotation(report.N_Spare_17_unit_name),
    N_Spare_18: emptyToDoubleQuotation(report.N_Spare_18),
    N_Spare_18_unit_name: emptyToDoubleQuotation(report.N_Spare_18_unit_name),
    N_Spare_19: emptyToDoubleQuotation(report.N_Spare_19),
    N_Spare_19_unit_name: emptyToDoubleQuotation(report.N_Spare_19_unit_name),
    N_Spare_20: emptyToDoubleQuotation(report.N_Spare_20),
    N_Spare_20_unit_name: emptyToDoubleQuotation(report.N_Spare_20_unit_name),
  };
};

const ServerUpdate = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const { ship_id } = useContextIDs();
  const navigate = useNavigate();
  const ship = useShip();
  const [isConfirm, setIsConfirm] = useState(false);
  const [robFile, setFile] = useState<File | undefined>(undefined);

  const location = useLocation();

  if (!report_id) navigate("/");

  const formData = useGetReport(report_id, ship_id);

  const form = useNoonReportForm([location?.state?.form, formData.form]);
  const formValidation = useNoonReportFormValidation(form.data);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const onConfirm = () => {
    setIsConfirm(true);

    formValidation.hasErrorScrollUp();

    if (formValidation.hasError) return;
    navigate(pageData.serverUpdateReportConfirm.path(String(report_id)), {
      state: { form: form.data, robFile },
    });
  };

  return (
    <Default title="Noon Report" selectedTitle={"Noon Report"}>
      {!formData.isLoading && !form.isLoading ? (
        <Template
          defaultTimeZone={formData.defaultTimeZone}
          defaultLatLong={formData.defaultLatLong}
          form={form.data}
          reportId={reportId || ""}
          hasFormError={formValidation.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          ship={ship}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
          setFile={setFile}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Default>
  );
};

export default ServerUpdate;
