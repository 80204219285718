import { useMemo, useState } from "react";
import {
  FocForm,
  SpeedForm,
  initialFocFormValue,
  initialSpeedFormValue,
} from "./Create/types";
import {
  calculateY,
  getMaxValue,
  getReferenceDataCoefficient,
  getTrendlineValues,
} from "../analysisDataUtils";
import { isString } from "../../../../../utils/type-checks";
import { ReferenceData, ReferenceDataInput } from "../types";

export const useSpeedForm = () => {
  const [data, update] = useState<SpeedForm>(initialSpeedFormValue);

  const setReferenceData = (referenceDataList: ReferenceData[]): void => {
    const speedForm = getReferenceDataToSpeedForm(
      referenceDataList,
      data.imo_no
    );
    update(speedForm);
  };

  const setInitialSpeedForm = () => {
    update({
      ...initialSpeedFormValue,
      imo_no: data.imo_no,
    });
  };

  const hasSpeedFormError = useMemo(() => {
    const { imo_no } = data;

    const hasError = !imo_no;

    return hasError;
  }, [data]);

  return {
    data,
    update,
    setReferenceData,
    setInitialSpeedForm,
    hasSpeedFormError,
  };
};

const getReferenceDataToSpeedForm = (
  referenceDataList: ReferenceData[],
  imo_no: string
): SpeedForm => {
  return {
    imo_no,
    speed_OG_1: referenceDataList[0]?.Speed_OG || null,
    speed_OG_2: referenceDataList[1]?.Speed_OG || null,
    speed_OG_3: referenceDataList[2]?.Speed_OG || null,
    speed_OG_4: referenceDataList[3]?.Speed_OG || null,
    speed_OG_5: referenceDataList[4]?.Speed_OG || null,
    speed_OG_6: referenceDataList[5]?.Speed_OG || null,
    speed_OG_7: referenceDataList[6]?.Speed_OG || null,
    speed_OG_8: referenceDataList[7]?.Speed_OG || null,
    speed_OG_9: referenceDataList[8]?.Speed_OG || null,
    speed_OG_10: referenceDataList[9]?.Speed_OG || null,
    empty_1: referenceDataList[0]?.fuel_consumption_Ballast || null,
    empty_2: referenceDataList[1]?.fuel_consumption_Ballast || null,
    empty_3: referenceDataList[2]?.fuel_consumption_Ballast || null,
    empty_4: referenceDataList[3]?.fuel_consumption_Ballast || null,
    empty_5: referenceDataList[4]?.fuel_consumption_Ballast || null,
    empty_6: referenceDataList[5]?.fuel_consumption_Ballast || null,
    empty_7: referenceDataList[6]?.fuel_consumption_Ballast || null,
    empty_8: referenceDataList[7]?.fuel_consumption_Ballast || null,
    empty_9: referenceDataList[8]?.fuel_consumption_Ballast || null,
    empty_10: referenceDataList[9]?.fuel_consumption_Ballast || null,
    laden_1: referenceDataList[0]?.fuel_consumption_Laden || null,
    laden_2: referenceDataList[1]?.fuel_consumption_Laden || null,
    laden_3: referenceDataList[2]?.fuel_consumption_Laden || null,
    laden_4: referenceDataList[3]?.fuel_consumption_Laden || null,
    laden_5: referenceDataList[4]?.fuel_consumption_Laden || null,
    laden_6: referenceDataList[5]?.fuel_consumption_Laden || null,
    laden_7: referenceDataList[6]?.fuel_consumption_Laden || null,
    laden_8: referenceDataList[7]?.fuel_consumption_Laden || null,
    laden_9: referenceDataList[8]?.fuel_consumption_Laden || null,
    laden_10: referenceDataList[9]?.fuel_consumption_Laden || null,
    unique_1: referenceDataList[0]?.fuel_consumption_Unique || null,
    unique_2: referenceDataList[1]?.fuel_consumption_Unique || null,
    unique_3: referenceDataList[2]?.fuel_consumption_Unique || null,
    unique_4: referenceDataList[3]?.fuel_consumption_Unique || null,
    unique_5: referenceDataList[4]?.fuel_consumption_Unique || null,
    unique_6: referenceDataList[5]?.fuel_consumption_Unique || null,
    unique_7: referenceDataList[6]?.fuel_consumption_Unique || null,
    unique_8: referenceDataList[7]?.fuel_consumption_Unique || null,
    unique_9: referenceDataList[8]?.fuel_consumption_Unique || null,
    unique_10: referenceDataList[9]?.fuel_consumption_Unique || null,
  };
};

export const useFocForm = () => {
  const [data, update] = useState<FocForm>(initialFocFormValue);

  const setReferenceData = (referenceDataList: ReferenceData[]): void => {
    const focForm = getReferenceDataToFocForm(referenceDataList, data.imo_no);
    update(focForm);
  };

  const setInitialFocForm = () => {
    update({ ...initialFocFormValue, imo_no: data.imo_no });
  };

  const hasFocFormError = useMemo(() => {
    const { imo_no } = data;

    const hasFocError = !imo_no;

    return hasFocError;
  }, [data]);

  return {
    data,
    update,
    setInitialFocForm,
    setReferenceData,
    hasFocFormError,
  };
};

const getReferenceDataToFocForm = (
  referenceDataList: ReferenceData[],
  imo_no: string
): FocForm => {
  //データの都合上Speed_OGに設定した0にundefinedを設定する。Speed_OG=0の場合はそもそもAnalysisで表示されない、
  const filteredReferenceDataList: ReferenceDataInput[] = referenceDataList.map(
    (v) => {
      return {
        ...v,
        Speed_OG: Number(v.Speed_OG) == 0 ? undefined : v.Speed_OG,
      };
    }
  );

  return {
    imo_no,

    foc_1: filteredReferenceDataList[0]?.fuel_consumption_Ballast,
    empty_1: filteredReferenceDataList[0]?.Speed_OG,
    laden_1: filteredReferenceDataList[1]?.Speed_OG,
    unique_1: filteredReferenceDataList[2]?.Speed_OG,

    foc_2: filteredReferenceDataList[3]?.fuel_consumption_Ballast,
    empty_2: filteredReferenceDataList[3]?.Speed_OG,
    laden_2: filteredReferenceDataList[4]?.Speed_OG,
    unique_2: filteredReferenceDataList[5]?.Speed_OG,

    foc_3: filteredReferenceDataList[6]?.fuel_consumption_Ballast,
    empty_3: filteredReferenceDataList[6]?.Speed_OG,
    laden_3: filteredReferenceDataList[7]?.Speed_OG,
    unique_3: filteredReferenceDataList[8]?.Speed_OG,

    foc_4: filteredReferenceDataList[9]?.fuel_consumption_Ballast,
    empty_4: filteredReferenceDataList[9]?.Speed_OG,
    laden_4: filteredReferenceDataList[10]?.Speed_OG,
    unique_4: filteredReferenceDataList[11]?.Speed_OG,

    foc_5: filteredReferenceDataList[12]?.fuel_consumption_Ballast,
    empty_5: filteredReferenceDataList[12]?.Speed_OG,
    laden_5: filteredReferenceDataList[13]?.Speed_OG,
    unique_5: filteredReferenceDataList[14]?.Speed_OG,

    foc_6: filteredReferenceDataList[15]?.fuel_consumption_Ballast,
    empty_6: filteredReferenceDataList[15]?.Speed_OG,
    laden_6: filteredReferenceDataList[16]?.Speed_OG,
    unique_6: filteredReferenceDataList[17]?.Speed_OG,

    foc_7: filteredReferenceDataList[18]?.fuel_consumption_Ballast,
    empty_7: filteredReferenceDataList[18]?.Speed_OG,
    laden_7: filteredReferenceDataList[19]?.Speed_OG,
    unique_7: filteredReferenceDataList[20]?.Speed_OG,

    foc_8: filteredReferenceDataList[21]?.fuel_consumption_Ballast,
    empty_8: filteredReferenceDataList[21]?.Speed_OG,
    laden_8: filteredReferenceDataList[22]?.Speed_OG,
    unique_8: filteredReferenceDataList[23]?.Speed_OG,

    foc_9: filteredReferenceDataList[24]?.fuel_consumption_Ballast,
    empty_9: filteredReferenceDataList[24]?.Speed_OG,
    laden_9: filteredReferenceDataList[25]?.Speed_OG,
    unique_9: filteredReferenceDataList[26]?.Speed_OG,

    foc_10: filteredReferenceDataList[27]?.fuel_consumption_Ballast,
    empty_10: filteredReferenceDataList[27]?.Speed_OG,
    laden_10: filteredReferenceDataList[28]?.Speed_OG,
    unique_10: filteredReferenceDataList[29]?.Speed_OG,
  };
};

export const getSettingReport = (report: (string | number | null)[][]) => {
  const maxValueX = getMaxValue(report, 0);
  const maxValueY2 = getMaxValue(report, 1);
  const maxValueY3 = getMaxValue(report, 2);
  const maxValueY4 = getMaxValue(report, 3);

  const maxValueY =
    [maxValueY2, maxValueY3, maxValueY4].sort((a, b) => a - b).pop() || 0;

  const trendlineXValues = getTrendlineValues(
    isString(maxValueX) ? 0 : maxValueX
  );

  const emptyCoefficient = getReferenceDataCoefficient(report, 1);
  const ladenCoefficient = getReferenceDataCoefficient(report, 2);
  const uniqueCoefficient = getReferenceDataCoefficient(report, 3);

  trendlineXValues.forEach((x) => {
    const y1 = calculateY(emptyCoefficient, x, maxValueY);
    const y2 = calculateY(ladenCoefficient, x, maxValueY);
    const y3 = calculateY(uniqueCoefficient, x, maxValueY);

    report.push([x, NaN, NaN, NaN, y1, y2, y3]);
  });

  return report;
};
