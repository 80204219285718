import { Box, Stack } from "@mui/material";
import { FC } from "react";
import { SpeedConsumptionFilterCondition } from "../../../../pages/Owner/Analysis/queries";
import {
  limeGreen,
  plumPurple,
  sunnyYellow,
  taupeBrown,
  weightedGray,
} from "../../../../../utils/color";
import { FilterTitleList } from "../../../../pages/Owner/Analysis/const";
import { SpeedConsumptionIndividualFormView } from "./SpeedConsumptionIndividualFormView";

type Props = {
  filterForm1: SpeedConsumptionFilterCondition;
  filterForm2: SpeedConsumptionFilterCondition;
  filterForm3: SpeedConsumptionFilterCondition;
  filterForm4: SpeedConsumptionFilterCondition;
};

const VerticalBorderline = () => {
  return (
    <Box
      sx={{
        border: `0.5px solid #CBD5E1`,
        height: 380,
        fontFamily: "inherit",
        letterSpacing: "inherit",
      }}
    />
  );
};

export type ModalFormBoolean = {
  checkedMeRevolutionOver100: boolean;
};

export const SpeedConsumptionFormViews: FC<Props> = (props: Props) => {
  const style = {
    color: weightedGray,
    width: 1240,
    p: 2,
  };

  return (
    <>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent={"space-between"}
        >
          <SpeedConsumptionIndividualFormView
            filterTitle={FilterTitleList.Filter_1}
            titleColor={sunnyYellow}
            filterForm={props.filterForm1}
          />

          <VerticalBorderline />
          <SpeedConsumptionIndividualFormView
            filterTitle={FilterTitleList.Filter_2}
            titleColor={limeGreen}
            filterForm={props.filterForm2}
          />
          <VerticalBorderline />
          <SpeedConsumptionIndividualFormView
            filterTitle={FilterTitleList.Filter_3}
            titleColor={taupeBrown}
            filterForm={props.filterForm3}
          />
          <VerticalBorderline />
          <SpeedConsumptionIndividualFormView
            filterTitle={FilterTitleList.Filter_4}
            titleColor={plumPurple}
            filterForm={props.filterForm4}
          />
        </Stack>
      </Box>
    </>
  );
};
