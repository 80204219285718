import { SxProps, TableCell, Theme } from "@mui/material";

type TableCellProps = {
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  colSpan?: number;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  style?: React.CSSProperties | undefined;
};

const SettingTableCell = (props: TableCellProps) => {
  return (
    <TableCell
      align={props.align}
      colSpan={props.colSpan}
      style={props.style}
      sx={{
        color: "#808080",
        borderBottom: "1px solid #C9D0DC",
        py: 1,
        ...props.sx,
      }}
    >
      {props.children}
    </TableCell>
  );
};

export default SettingTableCell;
