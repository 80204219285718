import React from "react";
import { useNavigate, useParams } from "react-router";
import Template from "../../../templates/Crew/NoonReport/ReportDetail";
import { LocationState } from "./Create/types";
import Default from "../../../layouts/DefaultCrew";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../dexie/db";
import { useGetPreviousReport } from "../../../../hooks/dexie/dexie";
import {
  mapToFormData,
  useNoonReportForm,
  useNoonReportFormValidation,
} from "./noonReport.module";

type Props = LocationState;

const ReportDetail = (props: Props): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);

  const previousReport = useGetPreviousReport(report_id);

  const navigate = useNavigate();

  if (!report_id) navigate("/");

  const onBack = () => {
    navigate(`/`);
  };

  const deleteReport = () => {
    db.reports.delete(report_id).catch((e) => console.error(e));
    navigate(`/`);
  };

  const item = useLiveQuery(() => db.reports.get(report_id));

  const form = useNoonReportForm([
    props.form,
    item?.draftForm,
    mapToFormData(item?.data),
  ]);
  const formValidation = useNoonReportFormValidation(form.data);

  return (
    <Default title="Noon Report" selectedTitle={"Noon Report"}>
      <Template
        form={form.data}
        reportId={report_id}
        hasFormError={formValidation.hasError}
        onConfirm={onBack}
        previousReport={previousReport}
        deleteReport={deleteReport}
        isSend={item?.isSend !== undefined ? item?.isSend : true}
      />
    </Default>
  );
};

export default ReportDetail;
