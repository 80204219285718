import * as React from "react";
import Paper from "@mui/material/Paper";
import { Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import FormBlock from "../../../organisms/Crew/Bunkering/FormBlock";
import {
  Form,
  initializeBunkering,
  Props,
} from "../../../pages/Crew/Bunkering/Create/types";
import { ShipDexie } from "../../../../dexie/Ship";

type ConfirmProps = Props & {
  hasFormError: boolean;
  reportId: string;
  onUpdateForm(value: Form): void;
  onConfirm(): void;
  ship: ShipDexie | undefined;
  isConfirm: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  defaultTimeZone: string;
  defaultLatLong: string;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
};

const Update = (props: ConfirmProps): React.ReactElement => {
  const navigate = useNavigate();

  const onUpdateForm = (value: Form) => {
    props.onUpdateForm(value);
  };

  const onClickConfirm = () => {
    props.onConfirm();
  };

  return (
    <React.Fragment>
      <Paper sx={{ backgroundColor: "#27292E", borderRadius: "20px" }}>
        <FormBlock
          defaultBunkering={{
            isDraft: false,
            isSend: true,
            data: {
              ...initializeBunkering,
              id: Number(props.reportId),
              B_Timezone: props.defaultTimeZone,
              ship_id: 0,
              B_Vessel_Name: "",
              B_Voy: "",
              B_LatLong: props.defaultLatLong,
              B_Bunkering_Date_time: new Date(),
              B_PortName: "",
              B_UNLOC: "",
            },
          }}
          title="Update Bunkering"
          isPreview={false}
          onUpdateForm={onUpdateForm}
          form={props.form}
          ship={props.ship}
          isConfirm={props.isConfirm}
          setIsConfirm={props.setIsConfirm}
          setFile={props.setFile}
          isServer
        />
        <Divider textAlign="left"></Divider>

        <Box
          sx={{
            textAlign: "left",
            px: 2,
            py: 4,
          }}
        >
          <Button
            sx={{
              mx: 2,
              width: "250",
              backgroundColor: "#20BE89  !important",
              fontFamily: "Public Sans",
              "&.MuiButton-root": {
                fontFamily: "Public Sans",
              },
              textTransform: "none",
            }}
            variant="contained"
            onClick={onClickConfirm}
          >
            Confirm
          </Button>
          <Button
            sx={{ mx: 2, backgroundColor: "#555860 ", textTransform: "none" }}
            variant="contained"
            onClick={() => navigate("/")}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default Update;
