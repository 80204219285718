import { useCallback, useState } from "react";
import {
  useDeleteAnalysisSearchParameterMutation,
  useUpdateAnalysisSearchParameterTitleMutation,
  useUpsertAnalysisSearchParameterMutation,
  AnalysisSearchParameterInput,
  AnalysisSearchParameterDetailInput,
  AnalysisFilterType,
  useGetAnalysisSearchParameterLazyQuery,
} from "../../../../../gen/graphql/types";
import { SpeedConsumptionFilterCondition } from "../../../../pages/Owner/Analysis/queries";
import { nonNullable } from "../../../../../utils/formats";
import { FilterBooleanType } from "../../../../pages/Owner/Analysis/types";

export function useGetAnalysisSearchParameter() {
  const [
    getData,
    { data: analysisSearchParameter, loading: isSearchParameterLoading },
    ,
  ] = useGetAnalysisSearchParameterLazyQuery();

  const loadDataAnalysisSearchParameter = useCallback(
    async (renderString: string) => {
      await getData({
        variables: { ...{ renderString: renderString } },
        fetchPolicy: "network-only",
      });
    },
    [getData]
  );

  return {
    loadDataAnalysisSearchParameter,
    analysisSearchParameter,
    isSearchParameterLoading,
  };
}

export type AnalysisSearchTitleProps = {
  id: number;
  title: string;
};

export function useUpdateAnalysisSearchParameterTitle(
  loadData: (renderString: string) => Promise<void>
) {
  const [updateTitleData, setUpdateTitleData] =
    useState<AnalysisSearchTitleProps>({
      id: 0,
      title: "",
    });

  const [update, { loading: isLoading }] =
    useUpdateAnalysisSearchParameterTitleMutation();

  const updateTitle = () => {
    if (!updateTitleData.id || !updateTitleData.title) {
      alert("Invalid Input!");
      return;
    }
    update({
      variables: { input: updateTitleData },
      onCompleted: (data) => {
        if (data.updateAnalysisSearchParameterTitle?.title) {
          loadData(updateTitleData.title);
        }
      },
    });
  };

  return { updateTitleData, setUpdateTitleData, updateTitle, isLoading };
}

export function useDeleteAnalysisSearchParameter(
  loadData: (renderString: string) => Promise<void>
) {
  const [deleteAnalysisSearchParameterMutation, { loading: isDeleteLoading }] =
    useDeleteAnalysisSearchParameterMutation();

  const deleteAnalysisSearchParameter = (delete_id: number) => {
    if (!delete_id) {
      alert("Invalid Input!");
      return;
    }
    deleteAnalysisSearchParameterMutation({
      variables: { delete_id },
      onCompleted: (data) => {
        if (data.deleteAnalysisSearchParameter) {
          loadData(delete_id.toString());
        }
      },
    });
  };
  return { deleteAnalysisSearchParameter, isDeleteLoading };
}

export function useUpsertAnalysisSearchParameter(
  filterForm1: SpeedConsumptionFilterCondition,
  filterForm2: SpeedConsumptionFilterCondition,
  filterForm3: SpeedConsumptionFilterCondition,
  filterForm4: SpeedConsumptionFilterCondition,
  checkedMeRevolutionOver100: FilterBooleanType
) {
  const [analysisSearchParameterTitle, setAnalysisSearchParameterTitle] =
    useState<AnalysisSearchTitleProps>({
      id: 0,
      title: "",
    });
  const [onUpsert, { loading: isUpsertLoading }] =
    useUpsertAnalysisSearchParameterMutation();

  const upsertAnalysisSearchParameter = async () => {
    if (!analysisSearchParameterTitle.title) {
      alert("Invalid Input!");
      return false;
    }

    const analysisSearchParameterInput: AnalysisSearchParameterInput = {
      id: analysisSearchParameterTitle.id || 0,
      title: analysisSearchParameterTitle.title,
      AnalysisSearchParameterDetailInput:
        mapFormToAnalysisSearchParameterDetailInputArr([
          {
            filter_type: AnalysisFilterType.Filter1,
            form: filterForm1,
            checkedMeRevolutionOver100: checkedMeRevolutionOver100.filter1,
          },
          {
            filter_type: AnalysisFilterType.Filter2,
            form: filterForm2,
            checkedMeRevolutionOver100: checkedMeRevolutionOver100.filter2,
          },
          {
            filter_type: AnalysisFilterType.Filter3,
            form: filterForm3,
            checkedMeRevolutionOver100: checkedMeRevolutionOver100.filter3,
          },
          {
            filter_type: AnalysisFilterType.Filter4,
            form: filterForm4,
            checkedMeRevolutionOver100: checkedMeRevolutionOver100.filter4,
          },
        ]),
    };

    const response = await onUpsert({
      variables: { input: analysisSearchParameterInput },
    });
    return response.data?.upsertAnalysisSearchParameter;
  };

  return {
    upsertAnalysisSearchParameter,
    isUpsertLoading,
    analysisSearchParameterTitle,
    setAnalysisSearchParameterTitle,
  };
}

type mapFormToAnalysisSearchParameterDetailInputArrProps = {
  filter_type: AnalysisFilterType;
  form: SpeedConsumptionFilterCondition;
  checkedMeRevolutionOver100: boolean;
}[];

export const mapFormToAnalysisSearchParameterDetailInputArr = (
  props: mapFormToAnalysisSearchParameterDetailInputArrProps
): AnalysisSearchParameterDetailInput[] => {
  return (
    props
      .map((v) =>
        mapFormToAnalysisSearchParameterDetailInput(
          v.filter_type,
          v.form,
          v.checkedMeRevolutionOver100
        )
      )
      .filter((v): v is Exclude<typeof v, undefined> => nonNullable(v)) || []
  );
};

const mapFormToAnalysisSearchParameterDetailInput = (
  filter_type: AnalysisFilterType,
  form: SpeedConsumptionFilterCondition,
  checkedMeRevolutionOver100: boolean
): AnalysisSearchParameterDetailInput | undefined => {
  if (!form.imo_no || !form.from_dateTime || !form.to_dateTime) return;
  return {
    filter_type: filter_type,
    imo_no: form.imo_no,
    n_voy: form.voy || undefined,
    from_bf_scale: form.bf_scale ? form.bf_scale[0] : undefined,
    to_bf_scale: form.bf_scale ? form.bf_scale[1] : undefined,
    from_dateTime: form.from_dateTime,
    to_dateTime: form.to_dateTime,
    from_hrs_propelling: form.hrs_propelling
      ? form.hrs_propelling[0]
      : undefined,
    to_hrs_propelling: form.hrs_propelling ? form.hrs_propelling[1] : undefined,
    from_me_rpm: form.me_revolution ? form.me_revolution[0] : undefined,
    to_me_rpm:
      form.me_revolution && !checkedMeRevolutionOver100
        ? form.me_revolution[1]
        : undefined,
    n_LorB: form.ballast_or_laden || undefined,
    n_steam_mode: form.steam_mode || undefined,
  };
};
