import { useMemo } from "react";
import { useLazyInitializeState } from "../../../../utils/lazy-initialize-state";
import { Form, initializeBunkering } from "./Create/types";
import { emptyToDoubleQuotation } from "../../../../utils/formats";
import { BunkeringInput, LorU } from "../../../../gen/graphql/types";
import {
  getLatLong,
  getLatLongValues,
  getTimeZone,
} from "../../../../utils/form-util";
import { hasLatLonValidateError } from "../../../../infrastructure/common/validate";
import { setDefaultNullGgraphQLInputToForm } from "../../../../utils/graphql-mappimg";

type Maybe<T> = T | null | undefined;

export const useBunkeringForm = (initializes: Maybe<Form>[]) => {
  const [isLoading, data, update] = useLazyInitializeState<Form>(
    initializeBunkering,
    initializes
  );

  const hasError = useMemo(() => {
    const {
      B_Vessel_Name,
      B_Voy,
      B_TimeZone_PorN,
      B_TimeZone_HH,
      B_TimeZone_MM,
      B_Bunkering_Date_time,
      B_Lat_Direction,
      B_Lat_Deg,
      B_Lat_Min,
      B_Long_Direction,
      B_Long_Deg,
      B_Long_Min,
      B_PortName,
      B_UNLOC,
      B_Port_LorU,
    } = data;

    const hasError =
      [
        B_Vessel_Name,
        B_Voy,
        B_TimeZone_PorN,
        B_TimeZone_HH,
        B_TimeZone_MM,
        B_Bunkering_Date_time,
        B_Lat_Direction,
        B_Lat_Deg,
        B_Lat_Min,
        B_Long_Direction,
        B_Long_Deg,
        B_Long_Min,
        B_PortName,
        B_UNLOC,
        B_Port_LorU,
      ].some((item) => item === null || item === undefined || item === "") ||
      hasLatLonValidateError(
        B_Lat_Deg,
        B_Lat_Min,
        B_Lat_Direction,
        B_Long_Deg,
        B_Long_Min,
        B_Long_Direction
      );

    return hasError;
  }, [data]);

  const hasErrorScrollUp = () => {
    const {
      B_Vessel_Name,
      B_Voy,
      B_TimeZone_PorN,
      B_TimeZone_HH,
      B_TimeZone_MM,
      B_Bunkering_Date_time,
      B_Lat_Direction,
      B_Lat_Deg,
      B_Lat_Min,
      B_Long_Direction,
      B_Long_Deg,
      B_Long_Min,
      B_PortName,
      B_UNLOC,
      B_Port_LorU,
    } = data;

    const upperForm = [
      B_Vessel_Name,
      B_Voy,
      B_TimeZone_PorN,
      B_TimeZone_HH,
      B_TimeZone_MM,
      B_Bunkering_Date_time,
      B_Lat_Direction,
      B_Lat_Deg,
      B_Lat_Min,
      B_Long_Direction,
      B_Long_Deg,
      B_Long_Min,
      B_PortName,
      B_UNLOC,
      B_Port_LorU,
    ];

    if (
      upperForm.filter((v) => !v).length != 0 ||
      hasLatLonValidateError(
        B_Lat_Deg,
        B_Lat_Min,
        B_Lat_Direction,
        B_Long_Deg,
        B_Long_Min,
        B_Long_Direction
      )
    ) {
      window.scrollTo({
        top: 150,
        behavior: "smooth",
      });
    }
  };

  return {
    isLoading,
    data,
    hasError,
    update,
    hasErrorScrollUp,
  };
};

export const mapToBunkeringFormData = (
  report: BunkeringInput | undefined
): Form | null => {
  if (report === undefined) {
    return null;
  }

  const {
    Lat_Deg,
    Lat_Min,
    Lat_Direction,
    Long_Deg,
    Long_Min,
    Long_Direction,
  } = getLatLongValues(report.B_LatLong);

  return {
    B_Vessel_Name: report.B_Vessel_Name,
    B_Voy: report.B_Voy,
    B_TimeZone_PorN: String(report?.B_Timezone).slice(0, 1),
    B_TimeZone_HH: String(report?.B_Timezone).slice(1, 3),
    B_TimeZone_MM: String(report?.B_Timezone).slice(4, 6),
    B_Bunkering_Date_time: report.B_Bunkering_Date_time,
    B_Lat_Direction: Lat_Direction,
    B_Lat_Deg: Lat_Deg,
    B_Lat_Min: Lat_Min,
    B_Long_Direction: Long_Direction,
    B_Long_Deg: Long_Deg,
    B_Long_Min: Long_Min,
    B_PortName: report.B_PortName,
    B_UNLOC: report.B_UNLOC,
    B_Port_LorU: report.B_Port_LorU,
    B_EUorUK: setDefaultNullGgraphQLInputToForm(report.B_EUorUK),
    B_Fuel_name_1: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_1),
    B_Mass_1: setDefaultNullGgraphQLInputToForm(report.B_Mass_1),
    B_Volume_1: setDefaultNullGgraphQLInputToForm(report.B_Volume_1),
    B_Density_1: setDefaultNullGgraphQLInputToForm(report.B_Density_1),
    B_Fuel_name_2: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_2),
    B_Mass_2: setDefaultNullGgraphQLInputToForm(report.B_Mass_2),
    B_Volume_2: setDefaultNullGgraphQLInputToForm(report.B_Volume_2),
    B_Density_2: setDefaultNullGgraphQLInputToForm(report.B_Density_2),
    B_Fuel_name_3: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_3),
    B_Mass_3: setDefaultNullGgraphQLInputToForm(report.B_Mass_3),
    B_Volume_3: setDefaultNullGgraphQLInputToForm(report.B_Volume_3),
    B_Density_3: setDefaultNullGgraphQLInputToForm(report.B_Density_3),
    B_Fuel_name_4: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_4),
    B_Mass_4: setDefaultNullGgraphQLInputToForm(report.B_Mass_4),
    B_Volume_4: setDefaultNullGgraphQLInputToForm(report.B_Volume_4),
    B_Density_4: setDefaultNullGgraphQLInputToForm(report.B_Density_4),
    B_Fuel_name_5: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_5),
    B_Mass_5: setDefaultNullGgraphQLInputToForm(report.B_Mass_5),
    B_Volume_5: setDefaultNullGgraphQLInputToForm(report.B_Volume_5),
    B_Density_5: setDefaultNullGgraphQLInputToForm(report.B_Density_5),
    B_Fuel_name_6: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_6),
    B_Mass_6: setDefaultNullGgraphQLInputToForm(report.B_Mass_6),
    B_Volume_6: setDefaultNullGgraphQLInputToForm(report.B_Volume_6),
    B_Density_6: setDefaultNullGgraphQLInputToForm(report.B_Density_6),
    B_Fuel_name_7: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_7),
    B_Mass_7: setDefaultNullGgraphQLInputToForm(report.B_Mass_7),
    B_Volume_7: setDefaultNullGgraphQLInputToForm(report.B_Volume_7),
    B_Density_7: setDefaultNullGgraphQLInputToForm(report.B_Density_7),
    B_Fuel_name_8: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_8),
    B_Mass_8: setDefaultNullGgraphQLInputToForm(report.B_Mass_8),
    B_Volume_8: setDefaultNullGgraphQLInputToForm(report.B_Volume_8),
    B_Density_8: setDefaultNullGgraphQLInputToForm(report.B_Density_8),
    B_Fuel_name_9: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_9),
    B_Mass_9: setDefaultNullGgraphQLInputToForm(report.B_Mass_9),
    B_Volume_9: setDefaultNullGgraphQLInputToForm(report.B_Volume_9),
    B_Density_9: setDefaultNullGgraphQLInputToForm(report.B_Density_9),
    B_Fuel_name_10: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_10),
    B_Mass_10: setDefaultNullGgraphQLInputToForm(report.B_Mass_10),
    B_Volume_10: setDefaultNullGgraphQLInputToForm(report.B_Volume_10),
    B_Density_10: setDefaultNullGgraphQLInputToForm(report.B_Density_10),
    B_Filename: emptyToDoubleQuotation(report.B_Filename),
  };
};

export const mapToUpdateBunkeringMutationVariables = (
  form: Form,
  ship_id: number,
  report_id?: number
): BunkeringInput => {
  const B_Timezone = getTimeZone(
    form.B_TimeZone_PorN,
    form.B_TimeZone_HH,
    form.B_TimeZone_MM
  );
  const B_LatLong = getLatLong(
    form.B_Lat_Deg,
    form.B_Lat_Min,
    form.B_Lat_Direction,
    form.B_Long_Deg,
    form.B_Long_Min,
    form.B_Long_Direction
  );

  return {
    id: report_id || 0,
    ship_id,
    B_Vessel_Name: form.B_Vessel_Name || "",
    B_Voy: form.B_Voy || "",
    B_Timezone,
    B_Bunkering_Date_time: form.B_Bunkering_Date_time || new Date(),
    B_LatLong,
    B_PortName: form.B_PortName || "",
    B_UNLOC: form.B_UNLOC || "",
    B_Port_LorU: form.B_Port_LorU || LorU.Y,
    B_EUorUK: form.B_EUorUK,
    B_Fuel_name_1: form.B_Fuel_name_1,
    B_Mass_1: form.B_Mass_1,
    B_Volume_1: form.B_Volume_1,
    B_Density_1: form.B_Density_1,
    B_Fuel_name_2: form.B_Fuel_name_2,
    B_Mass_2: form.B_Mass_2,
    B_Volume_2: form.B_Volume_2,
    B_Density_2: form.B_Density_2,
    B_Fuel_name_3: form.B_Fuel_name_3,
    B_Mass_3: form.B_Mass_3,
    B_Volume_3: form.B_Volume_3,
    B_Density_3: form.B_Density_3,
    B_Fuel_name_4: form.B_Fuel_name_4,
    B_Mass_4: form.B_Mass_4,
    B_Volume_4: form.B_Volume_4,
    B_Density_4: form.B_Density_4,
    B_Fuel_name_5: form.B_Fuel_name_5,
    B_Mass_5: form.B_Mass_5,
    B_Volume_5: form.B_Volume_5,
    B_Density_5: form.B_Density_5,
    B_Fuel_name_6: form.B_Fuel_name_6,
    B_Mass_6: form.B_Mass_6,
    B_Volume_6: form.B_Volume_6,
    B_Density_6: form.B_Density_6,
    B_Fuel_name_7: form.B_Fuel_name_7,
    B_Mass_7: form.B_Mass_7,
    B_Volume_7: form.B_Volume_7,
    B_Density_7: form.B_Density_7,
    B_Fuel_name_8: form.B_Fuel_name_8,
    B_Mass_8: form.B_Mass_8,
    B_Volume_8: form.B_Volume_8,
    B_Density_8: form.B_Density_8,
    B_Fuel_name_9: form.B_Fuel_name_9,
    B_Mass_9: form.B_Mass_9,
    B_Volume_9: form.B_Volume_9,
    B_Density_9: form.B_Density_9,
    B_Fuel_name_10: form.B_Fuel_name_10,
    B_Mass_10: form.B_Mass_10,
    B_Volume_10: form.B_Volume_10,
    B_Density_10: form.B_Density_10,
    B_Filename: emptyToDoubleQuotation(form.B_Filename),
  };
};
