import React, { useState } from "react";
import Template from "../../../../templates/Crew/Cargo/Create";
import Default, { useShip } from "../../../../layouts/DefaultCrew";
import { useNavigate } from "react-router";
import {
  mapToCreateCargoMutationVariables,
  useCargoForm,
} from "../cargo.module";
import { CargoForm } from "../type";
import { CargoDexie } from "../../../../../dexie/Cargo";
import { db } from "../../../../../dexie/db";
import CargoUpdateDetail from "../../../../templates/Crew/Cargo/Update/CargoUpdateDetail";
import { CargoInput } from "../../../../../gen/graphql/types";
import { pageData } from "../../../../../App";

const CargoCreate = (): React.ReactElement => {
  const navigate = useNavigate();

  const ship = useShip();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isDetail, setIsDetail] = useState(false);

  const [cargoFile, setFile] = useState<File | undefined>(undefined);

  const form = useCargoForm([]);

  const onUpdateForm = (value: CargoForm) => {
    form.update(value);
  };

  const onSaveDraft = (value: CargoForm) => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");

    const newReport: CargoDexie = {
      id: undefined,
      isDraft: true,
      isSend: false,
      data: undefined,
      draftForm: value,
      cargoFile: value.C_Filename ? cargoFile : undefined,
    };

    db.cargo.add(newReport, 1).catch((e) => console.error(e));
    navigate(pageData.crewCargo);
  };

  const onConfirm = () => {
    setIsConfirm(true);
    form.hasErrorScrollUp();

    if (form.hasError) return;
    setIsDetail(true);
  };

  const onSave = () => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");

    const cargoInput: CargoInput = mapToCreateCargoMutationVariables(form.data);

    const createCargo: CargoDexie = {
      id: undefined,
      isDraft: false,
      isSend: false,
      data: cargoInput,
      draftForm: form.data,
      cargoFile: form.data.C_Filename && cargoFile ? cargoFile : undefined,
    };
    db.cargo.add(createCargo, 1).catch((e: unknown) => console.error(e));
    navigate(pageData.crewCargoCreateComplete);
  };

  return (
    <Default title="" selectedTitle={"Cargo"}>
      {isDetail ? (
        <CargoUpdateDetail
          action={"Create"}
          form={form.data}
          onSave={onSave}
          setIsDetail={setIsDetail}
        />
      ) : (
        <Template
          onUpdateForm={onUpdateForm}
          form={form.data}
          onSaveDraft={onSaveDraft}
          setFile={setFile}
          onConfirm={onConfirm}
          isConfirm={isConfirm}
          setIsDetail={setIsDetail}
        />
      )}
    </Default>
  );
};

export default CargoCreate;
