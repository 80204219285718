import axios, { AxiosError } from "axios";
import mime from "mime";

type TypeUploadFilesArg = TypeUploadFileArg[];

export const uploadFiles = async (props: TypeUploadFilesArg) => {
  return await Promise.all(props.map(async (v) => await uploadFile(v)));
};

export type TypeUploadFileArg = {
  remoteFilePath: string;
  fileContent: File;
};

type FileUploadResponse = {
  sucsess: boolean;
  filename?: string;
  errorMessage?: string;
};

export const uploadFile = async (
  args: TypeUploadFileArg
): Promise<FileUploadResponse> => {
  const { remoteFilePath, fileContent } = args;

  try {
    const contentType = mime.getType(fileContent.name ?? "") ?? "";

    const reader = new FileReader();
    const stream = await new Promise<ArrayBuffer | null>((resolve) => {
      reader.onload = (e) => {
        resolve(e.target?.result as ArrayBuffer | null);
      };
      reader.readAsArrayBuffer(fileContent);
    });

    if (stream) {
      await axios
        .put(remoteFilePath, stream, {
          headers: {
            "Content-Type": contentType,
          },
        })
        .catch((e) => {
          throw e;
        });

      return { sucsess: true };
    } else {
      //ファイルの読み込みエラー
      throw new Error("File loading error");
    }
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      // Axiosエラーをキャッチ
      const axiosError = e as AxiosError;
      if (axiosError.response) {
        // サーバーからのレスポンスがある場合
        console.error(axiosError.response.data);
        console.error(axiosError.response.status);
      } else if (axiosError.request) {
        // リクエストがサーバーに届かなかった場合
        console.error(axiosError.request);
      } else {
        // その他のエラー
        console.error(axiosError.message);
      }
    } else {
      // その他の未知のエラー
      console.error(e);
    }
    return {
      sucsess: false,
      filename: fileContent.name,
      errorMessage: String(e),
    };
  }
};

export function getUploadFileName(category: string, fileName: string) {
  return `${category}-${new Date().getTime()}-${fileName}`;
}
