import { LatLonMinutesMaxValue, LatLonMinutesMinValue, LatitudeDegMaxValue, LatitudeDegMinValue, LongitudeDegMaxValue, LongitudeDegMinValue } from "../../utils/const";
import { getLatLong } from "../../utils/form-util";

export const isValidLatLongFormat = (input: string): boolean => {
  const regex = /^\d+\.?\d*-\d+\.?\d*[NS]\/\d+\.?\d*-\d+\.?\d*[EW]$/;
  return regex.test(input);
}

export const isValidLatitudeDegValueRange = (latDeg: number): boolean => {
  return latDeg >= LatitudeDegMinValue && latDeg <= LatitudeDegMaxValue;
}

export const isValidLongitudeDegValueRange = (lonDeg: number): boolean => {
  return lonDeg >= LongitudeDegMinValue && lonDeg <= LongitudeDegMaxValue;
}

export const isValidLatLonMinValueRange = (value: number): boolean => {
  return value >= LatLonMinutesMinValue && value <= LatLonMinutesMaxValue;
}

export const hasLatLonValidateError = (
  latDeg: string | null,
  latMin: string | null,
  latDirection: string,
  longDeg: string | null,
  longMin: string | null,
  longDirection: string,
): boolean => {
  return !isValidLatLongFormat(getLatLong(
    latDeg,
    latMin,
    latDirection,
    longDeg,
    longMin,
    longDirection
  ))
  || !isValidLatLonMinValueRange(Number(latMin)) 
  || !isValidLatLonMinValueRange(Number(longMin))
  || !isValidLatitudeDegValueRange(Number(latDeg))
  || !isValidLongitudeDegValueRange(Number(longDeg));
}
