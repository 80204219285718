import * as React from "react";

type Props = {
  color?: string;
  borderLeftColor?: string;
  children?: React.ReactNode;
};

const NowrapGray = (props: Props): React.ReactElement => {
  return (
    <span
      style={{
        fontSize: 16,
        whiteSpace: "nowrap",
        color: `${props.color ? props.color : "#AAAAAA"}`,
        borderLeft: `1.5px solid ${
          props.borderLeftColor ? props.borderLeftColor : "#464E5A"
        }`,
        paddingLeft: "6px",
        marginLeft: "-8px",
      }}
    >
      {props.children}
    </span>
  );
};

export default NowrapGray;
