import dayjs from "dayjs";
import {
  LorB,
  SteamMode,
  MePowerMeasurementMethod,
  EUorUk,
  LorU,
  FuelType,
  FoFuelType,
  MgoFuelType,
  OtherFuelType,
} from "../../../../../gen/graphql/types";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export type Form = {
  N_Vessel_name: string | null;
  N_Voy: string | null;
  N_LorB: LorB | null;
  N_EU_UK_port: EUorUk | null;
  N_Port_L_U: LorU;
  N_Noon_Port: string | null;
  N_UNLOC: string | null;
  N_DateTime: Date | null;
  N_TimeZone_PorN: string | null;
  N_TimeZone_HH: string | null;
  N_TimeZone_MM: string | null;
  N_Lat_Direction: string;
  N_Lat_Deg: string | null;
  N_Lat_Min: string | null;
  N_Long_Direction: string;
  N_Long_Deg: string | null;
  N_Long_Min: string | null;
  N_Steam_mode: string | null;
  N_Hrs_propelling: number | null;
  N_Dist_OG: number | null;
  N_Speed_OG: number | null;
  N_Speed_OW: number | null;
  N_ME_rpm_ave: number | null;
  N_ME_rpm_total: number | null;
  N_ME_Revolution: number | null;
  N_Slip: number | null;
  N_FO_Consumption_per_day_ME?: number | null;
  N_FO_Consumption_per_day_GE?: number | null;
  N_FO_Consumption_per_day_BInc?: number | null;

  N_FO_Additional_FuelType_1?: FoFuelType | null;
  N_FO_Additional_Usage_1?: string | null;
  N_FO_Additional_volume_1?: number | null;
  N_FO_Additional_FuelType_2?: FoFuelType | null;
  N_FO_Additional_Usage_2?: string | null;
  N_FO_Additional_volume_2?: number | null;
  N_FO_Additional_FuelType_3?: FoFuelType | null;
  N_FO_Additional_Usage_3?: string | null;
  N_FO_Additional_volume_3?: number | null;
  N_FO_Additional_FuelType_4?: FoFuelType | null;
  N_FO_Additional_Usage_4?: string | null;
  N_FO_Additional_volume_4?: number | null;
  N_FO_Additional_FuelType_5?: FoFuelType | null;
  N_FO_Additional_Usage_5?: string | null;
  N_FO_Additional_volume_5?: number | null;

  N_MGO_Consumption_per_day_ME?: number | null;
  N_MGO_Consumption_per_day_GE?: number | null;
  N_MGO_Consumption_per_day_Boiler?: number | null;

  N_MGO_Additional_FuelType_1?: MgoFuelType | null;
  N_MGO_Additional_Usage_1?: string | null;
  N_MGO_Additional_volume_1?: number | null;
  N_MGO_Additional_FuelType_2?: MgoFuelType | null;
  N_MGO_Additional_Usage_2?: string | null;
  N_MGO_Additional_volume_2?: number | null;
  N_MGO_Additional_FuelType_3?: MgoFuelType | null;
  N_MGO_Additional_Usage_3?: string | null;
  N_MGO_Additional_volume_3?: number | null;
  N_MGO_Additional_FuelType_4?: MgoFuelType | null;
  N_MGO_Additional_Usage_4?: string | null;
  N_MGO_Additional_volume_4?: number | null;
  N_MGO_Additional_FuelType_5?: MgoFuelType | null;
  N_MGO_Additional_Usage_5?: string | null;
  N_MGO_Additional_volume_5?: number | null;

  N_LO_Consumption_per_day_MECO?: number | null;
  N_LO_Consumption_per_day_MESO?: number | null;
  N_LO_Consumption_per_day_GESO?: number | null;

  N_Other_Additional_FuelType_1?: OtherFuelType | null;
  N_Other_Additional_Usage_1?: string | null;
  N_Other_Additional_volume_1?: number | null;
  N_Other_Additional_FuelType_2?: OtherFuelType | null;
  N_Other_Additional_Usage_2?: string | null;
  N_Other_Additional_volume_2?: number | null;
  N_Other_Additional_FuelType_3?: OtherFuelType | null;
  N_Other_Additional_Usage_3?: string | null;
  N_Other_Additional_volume_3?: number | null;
  N_Other_Additional_FuelType_4?: OtherFuelType | null;
  N_Other_Additional_Usage_4?: string | null;
  N_Other_Additional_volume_4?: number | null;
  N_Other_Additional_FuelType_5?: OtherFuelType | null;
  N_Other_Additional_Usage_5?: string | null;
  N_Other_Additional_volume_5?: number | null;
  FO_AdditonalConsumption?: Fuelconsumption[];
  MGO_AdditonalConsumption?: Fuelconsumption[];
  Other_AdditonalConsumption?: Fuelconsumption[];
  N_Weather: string | null | undefined;
  N_Wind_direction: number | null | undefined;
  N_BF_scale: number | null | undefined;
  N_Swell: number | null | undefined;
  N_Noon_Course: number | null | undefined;
  N_Dist_to_go: number | null | undefined;
  N_Next_port: string | null | undefined;
  N_ETA: Date | null | undefined;
  N_ETB: Date | null | undefined;
  N_ETD: Date | null | undefined;
  N_ME_Fuel_index: number | null;
  N_ME_RPM: number | null;
  N_ME_horsepower: number | null;
  N_ME_power: number | null;
  N_ME_power_measurement_method: MePowerMeasurementMethod | null;
  N_Running_hours_SOx_scrubber: number | null;
  N_Running_hours_ME_EGR: number | null;
  N_Running_hours_GE_SCR: number | null;
  N_ROB_FO: number | null;
  N_ROB_MGO: number | null;
  N_ROB_MECO: number | null;
  N_ROB_MESO: number | null;
  N_ROB_GESO: number | null;
  N_ROB_FW: number | null;
  N_Additional_ROB?: AdditionalROB[];
  N_ROB_Filename?: string;
  N_FO_CO2_emission_per_unit: number | null;
  N_MGO_CO2_emission_per_unit: number | null;
  N_FO_CO2_emission: number | null;
  N_MGO_CO2_emission: number | null;
  N_Remark: string | null;
  N_Spare_1: string | null;
  N_Spare_1_unit_name: string | null;
  N_Spare_2: string | null;
  N_Spare_2_unit_name: string | null;
  N_Spare_3: string | null;
  N_Spare_3_unit_name: string | null;
  N_Spare_4: string | null;
  N_Spare_4_unit_name: string | null;
  N_Spare_5: string | null;
  N_Spare_5_unit_name: string | null;
  N_Spare_6: string | null;
  N_Spare_6_unit_name: string | null;
  N_Spare_7: string | null;
  N_Spare_7_unit_name: string | null;
  N_Spare_8: string | null;
  N_Spare_8_unit_name: string | null;
  N_Spare_9: string | null;
  N_Spare_9_unit_name: string | null;
  N_Spare_10: string | null;
  N_Spare_10_unit_name: string | null;
  N_Spare_11: string | null;
  N_Spare_11_unit_name: string | null;
  N_Spare_12: string | null;
  N_Spare_12_unit_name: string | null;
  N_Spare_13: string | null;
  N_Spare_13_unit_name: string | null;
  N_Spare_14: string | null;
  N_Spare_14_unit_name: string | null;
  N_Spare_15: string | null;
  N_Spare_15_unit_name: string | null;
  N_Spare_16: string | null;
  N_Spare_16_unit_name: string | null;
  N_Spare_17: string | null;
  N_Spare_17_unit_name: string | null;
  N_Spare_18: string | null;
  N_Spare_18_unit_name: string | null;
  N_Spare_19: string | null;
  N_Spare_19_unit_name: string | null;
  N_Spare_20: string | null;
  N_Spare_20_unit_name: string | null;
};

export type Fuelconsumption = {
  id?: number;
  N_Fuel_Type?: FuelType | null;
  N_Fuel_usage?: string;
  N_Fuel_volume?: number;
};

export type AdditionalROB = {
  id?: number;
  N_Fuel_Type: FuelType;
  N_Fuel_volume?: number;
};

export type Report = {
  id: number;
  N_Vessel_name: string;
  N_Voy: string;
  N_LorB: LorB;
  N_EU_UK_port?: EUorUk | null;
  N_Port_L_U?: LorU | null;
  N_Noon_Port?: string | null;
  N_UNLOC?: string | null;
  N_DateTime: Date;
  N_TimeZone: string;
  N_LatLong: string;
  N_Steam_mode: string;
  N_Hrs_propelling?: number | null;
  N_Dist_OG?: number | null;
  N_Speed_OG?: number | null;
  N_Speed_OW?: number | null;
  N_ME_rpm_ave?: number | null;
  N_ME_Revolution?: number | null;
  N_Slip?: number | null;
  N_Fuelconsumption?: Fuelconsumption[];
  N_Weather?: string | null;
  N_Wind_direction?: number | null | undefined;
  N_BF_scale?: number | null | undefined;
  N_Swell?: number | null | undefined;
  N_Noon_Course?: number | null | undefined;
  N_Dist_to_go?: number | null | undefined;
  N_Next_port?: string | null | undefined;
  N_ETA?: Date | null | undefined;
  N_ETB?: Date | null | undefined;
  N_ETD?: Date | null | undefined;
  N_ME_Fuel_index?: number | null;
  N_ME_RPM?: number | null;
  N_ME_horsepower?: number | null;
  N_ME_power?: number | null;
  N_ME_power_measurement_method?: MePowerMeasurementMethod | null;
  N_Running_hours_SOx_scrubber?: number | null;
  N_Running_hours_ME_EGR?: number | null;
  N_Running_hours_GE_SCR: number;
  N_ROB_FO: number;
  N_ROB_MGO: number;
  N_ROB_MECO: number;
  N_ROB_MESO: number;
  N_ROB_GESO: number;
  N_ROB_FW: number;
  N_FO_CO2_emission_per_unit?: number | null;
  N_MGO_CO2_emission_per_unit?: number | null;
  N_FO_CO2_emission: number;
  N_MGO_CO2_emission: number;
  N_Remark?: string | null | undefined;
  N_Spare_1?: string | null | undefined;
  N_Spare_1_unit_name?: string | null | undefined;
  N_Spare_2?: string | null | undefined;
  N_Spare_2_unit_name?: string | null | undefined;
  N_Spare_3?: string | null | undefined;
  N_Spare_3_unit_name?: string | null | undefined;
  N_Spare_4?: string | null | undefined;
  N_Spare_4_unit_name?: string | null | undefined;
  N_Spare_5?: string | null | undefined;
  N_Spare_5_unit_name?: string | null | undefined;
  N_Spare_6?: string | null | undefined;
  N_Spare_6_unit_name?: string | null | undefined;
  N_Spare_7?: string | null | undefined;
  N_Spare_7_unit_name?: string | null | undefined;
  N_Spare_8?: string | null | undefined;
  N_Spare_8_unit_name?: string | null | undefined;
  N_Spare_9?: string | null | undefined;
  N_Spare_9_unit_name?: string | null | undefined;
  N_Spare_10?: string | null | undefined;
  N_Spare_10_unit_name?: string | null | undefined;
  N_Spare_11?: string | null | undefined;
  N_Spare_11_unit_name?: string | null | undefined;
  N_Spare_12?: string | null | undefined;
  N_Spare_12_unit_name?: string | null | undefined;
  N_Spare_13?: string | null | undefined;
  N_Spare_13_unit_name?: string | null | undefined;
  N_Spare_14?: string | null | undefined;
  N_Spare_14_unit_name?: string | null | undefined;
  N_Spare_15?: string | null | undefined;
  N_Spare_15_unit_name?: string | null | undefined;
  N_Spare_16?: string | null | undefined;
  N_Spare_16_unit_name?: string | null | undefined;
  N_Spare_17?: string | null | undefined;
  N_Spare_17_unit_name?: string | null | undefined;
  N_Spare_18?: string | null | undefined;
  N_Spare_18_unit_name?: string | null | undefined;
  N_Spare_19?: string | null | undefined;
  N_Spare_19_unit_name?: string | null | undefined;
  N_Spare_20?: string | null | undefined;
  N_Spare_20_unit_name?: string | null | undefined;
  ship_id: number;
};

export type Result = {
  succeed: boolean;
  code: string | null;
};

export type LocationState = {
  form?: Form;
  result?: Result;
};

export type Props = {
  form: Form;
};

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Etc/GMT");

const initialDay = dayjs(
  new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    12 - new Date().getTimezoneOffset() / 60,
    0,
    0
  )
).toDate();

export const initializeReport: Form = {
  N_Vessel_name: null,
  N_Voy: null,
  N_LorB: LorB.Ballast,
  N_Noon_Port: null,
  N_EU_UK_port: null,
  N_Port_L_U: LorU.N,
  N_UNLOC: null,
  N_DateTime: initialDay,
  N_TimeZone_PorN: "+",
  N_TimeZone_HH: "00",
  N_TimeZone_MM: "00",
  N_Lat_Direction: "N",
  N_Lat_Deg: null,
  N_Lat_Min: null,
  N_Long_Direction: "E",
  N_Long_Deg: null,
  N_Long_Min: null,
  N_Steam_mode: SteamMode.Full,
  N_Hrs_propelling: null,
  N_Dist_OG: null,
  N_Speed_OG: null,
  N_Speed_OW: null,
  N_ME_rpm_ave: null,
  N_ME_rpm_total: null,
  N_ME_Revolution: null,
  N_Slip: null,
  N_Weather: undefined,
  N_Wind_direction: undefined,
  N_BF_scale: undefined,
  N_Swell: undefined,
  N_Noon_Course: undefined,
  N_Dist_to_go: undefined,
  N_Next_port: undefined,
  N_ETA: undefined,
  N_ETB: undefined,
  N_ETD: undefined,
  N_ME_Fuel_index: null,
  N_ME_RPM: null,
  N_ME_horsepower: null,
  N_ME_power: null,
  N_ME_power_measurement_method: MePowerMeasurementMethod.FuelIndex,
  N_Running_hours_SOx_scrubber: null,
  N_Running_hours_ME_EGR: null,
  N_Running_hours_GE_SCR: null,
  N_ROB_FO: null,
  N_ROB_MGO: null,
  N_ROB_MECO: null,
  N_ROB_MESO: null,
  N_ROB_GESO: null,
  N_ROB_FW: null,
  N_FO_CO2_emission_per_unit: null,
  N_MGO_CO2_emission_per_unit: null,
  N_FO_CO2_emission: null,
  N_MGO_CO2_emission: null,
  N_Remark: null,
  N_Spare_1: null,
  N_Spare_1_unit_name: null,
  N_Spare_2: null,
  N_Spare_2_unit_name: null,
  N_Spare_3: null,
  N_Spare_3_unit_name: null,
  N_Spare_4: null,
  N_Spare_4_unit_name: null,
  N_Spare_5: null,
  N_Spare_5_unit_name: null,
  N_Spare_6: null,
  N_Spare_6_unit_name: null,
  N_Spare_7: null,
  N_Spare_7_unit_name: null,
  N_Spare_8: null,
  N_Spare_8_unit_name: null,
  N_Spare_9: null,
  N_Spare_9_unit_name: null,
  N_Spare_10: null,
  N_Spare_10_unit_name: null,
  N_Spare_11: null,
  N_Spare_11_unit_name: null,
  N_Spare_12: null,
  N_Spare_12_unit_name: null,
  N_Spare_13: null,
  N_Spare_13_unit_name: null,
  N_Spare_14: null,
  N_Spare_14_unit_name: null,
  N_Spare_15: null,
  N_Spare_15_unit_name: null,
  N_Spare_16: null,
  N_Spare_16_unit_name: null,
  N_Spare_17: null,
  N_Spare_17_unit_name: null,
  N_Spare_18: null,
  N_Spare_18_unit_name: null,
  N_Spare_19: null,
  N_Spare_19_unit_name: null,
  N_Spare_20: null,
  N_Spare_20_unit_name: null,
};
