import { safeNumber } from "../../../../../utils/formats";
import { BaselineTitleList } from "../const";
import { FocForm } from "./Create/types";
import { getSettingReport } from "./settings.moble";

export const getSettingFocGraphData = (form: FocForm) => {
  const report: (string | number | null)[][] = form.foc_1
    ? [
        [
          "N_Speed_OG",
          BaselineTitleList.Ballast,
          BaselineTitleList.Laden,
          BaselineTitleList.Unique,
          "",
          "",
          "",
        ],
        [NaN, NaN, NaN, NaN, NaN, NaN, NaN],
        [
          safeNumber(form.empty_1),
          safeNumber(form.foc_1),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_1),
          NaN,
          safeNumber(form.foc_1),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_1),
          NaN,
          NaN,
          safeNumber(form.foc_1),
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.empty_2),
          safeNumber(form.foc_2),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_2),
          NaN,
          safeNumber(form.foc_2),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_2),
          NaN,
          NaN,
          safeNumber(form.foc_2),
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.empty_2),
          safeNumber(form.foc_2),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_2),
          NaN,
          safeNumber(form.foc_2),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_2),
          NaN,
          NaN,
          safeNumber(form.foc_2),
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.empty_3),
          safeNumber(form.foc_3),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_3),
          NaN,
          safeNumber(form.foc_3),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_3),
          NaN,
          NaN,
          safeNumber(form.foc_3),
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.empty_4),
          safeNumber(form.foc_4),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_4),
          NaN,
          safeNumber(form.foc_4),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_4),
          NaN,
          NaN,
          safeNumber(form.foc_4),
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.empty_5),
          safeNumber(form.foc_5),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_5),
          NaN,
          safeNumber(form.foc_5),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_5),
          NaN,
          NaN,
          safeNumber(form.foc_5),
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.empty_6),
          safeNumber(form.foc_6),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_6),
          NaN,
          safeNumber(form.foc_6),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_6),
          NaN,
          NaN,
          safeNumber(form.foc_6),
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.empty_7),
          safeNumber(form.foc_7),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_7),
          NaN,
          safeNumber(form.foc_7),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_7),
          NaN,
          NaN,
          safeNumber(form.foc_7),
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.empty_8),
          safeNumber(form.foc_8),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_8),
          NaN,
          safeNumber(form.foc_8),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_8),
          NaN,
          NaN,
          safeNumber(form.foc_8),
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.empty_9),
          safeNumber(form.foc_9),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_9),
          NaN,
          safeNumber(form.foc_9),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_9),
          NaN,
          NaN,
          safeNumber(form.foc_9),
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.empty_10),
          safeNumber(form.foc_10),
          NaN,
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.laden_10),
          NaN,
          safeNumber(form.foc_10),
          NaN,
          NaN,
          NaN,
          NaN,
        ],
        [
          safeNumber(form.unique_10),
          NaN,
          NaN,
          safeNumber(form.foc_10),
          NaN,
          NaN,
          NaN,
        ],
      ]
    : [];

  if (report.length === 0) return [];

  return getSettingReport(report);
};
