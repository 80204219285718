import React, { useEffect, useState } from "react";

import Template from "../../../../templates/Owner/Analysis/Settings/Setting";
import {
  SpeedForm,
  initialSpeedFormValue,
  FocForm,
  initialFocFormValue,
} from "./Create/types";
import DefaultOwner from "../../../../layouts/DefaultOwner";
import {
  CreateReferenceDataMutationVariables,
  ReferenceDataType,
  useCreateReferenceDataMutation,
} from "../../../../../gen/graphql/types";

import {
  mapToCreateFocReferenceDataMutationVariables,
  mapToCreateReferenceDataMutationVariables,
} from "./Create/detaSet";
import { ReferenceDataTypeIndex, UNSELECTED } from "../types";
import { useFocForm, useSpeedForm } from "./settings.moble";
import Loading from "../../../../atoms/Loading";
import CustomSnackbar from "../../../../atoms/CustomSnackbar";
import { useReferenceData } from "../analysis.hook";

const useConfirm = () => {
  const [addReferenceData, { loading: isLoading, error }] =
    useCreateReferenceDataMutation();
  const [isCreateSucusess, setIsSucusess] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const create = async (variables: CreateReferenceDataMutationVariables) => {
    addReferenceData({
      variables: {
        input: variables.input,
      },
      onCompleted(data) {
        setIsSucusess(data.createReferenceData?.success ?? false);
        setOpenSnackbar(true);
      },
    }).catch((e) => {
      console.error(e);
      console.error(error);
    });
  };

  return {
    isLoading,
    create,
    isCreateSucusess,
    openSnackbar,
    setOpenSnackbar,
  };
};

const Setting = (): React.ReactElement => {
  const speedForm = useSpeedForm();
  const focForm = useFocForm();
  const referenceDataList = useReferenceData();

  const onUpdateSpeedForm = (value: SpeedForm) => {
    if (!value.imo_no) {
      speedForm.update(initialSpeedFormValue);
      return;
    } else {
      speedForm.update(value);
    }

    referenceDataList.refetch(value.imo_no);
  };

  const onUpdateFocForm = (value: FocForm) => {
    if (!value.imo_no) {
      focForm.update(initialFocFormValue);
      return;
    } else {
      focForm.update(value);
    }

    referenceDataList.refetch(value.imo_no);
  };

  const { create, isLoading, isCreateSucusess, openSnackbar, setOpenSnackbar } =
    useConfirm();

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const onFocCreate = (value: FocForm) => {
    const variables = mapToCreateFocReferenceDataMutationVariables(value);
    create(variables);
    referenceDataList.refetch(value.imo_no);
  };

  const onSpeedCreate = (value: SpeedForm) => {
    const variables = mapToCreateReferenceDataMutationVariables(value);
    create(variables);
    referenceDataList.refetch(value.imo_no);
  };

  const [formType, setFormType] = useState<ReferenceDataTypeIndex>(UNSELECTED);

  const resetForm = () => {
    speedForm.setInitialSpeedForm();
    focForm.setInitialFocForm();
  };

  useEffect(
    () => {
      const referenceDataArr = referenceDataList.data.referenceDataList;
      if (
        !referenceDataArr ||
        referenceDataList.data.referenceDataList.length <= 0
      ) {
        formType && setFormType(UNSELECTED);
        resetForm();
        return;
      }

      const firstData = referenceDataArr[0];
      switch (firstData.dataType) {
        case ReferenceDataType.Speed:
          (() => {
            setFormType(ReferenceDataType.Speed);
            focForm.setInitialFocForm();
            speedForm.setReferenceData(referenceDataArr);
          })();
          break;
        case ReferenceDataType.Foc:
          (() => {
            setFormType(ReferenceDataType.Foc);
            speedForm.setInitialSpeedForm();
            focForm.setReferenceData(referenceDataList.data.referenceDataList);
          })();
          break;
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [referenceDataList.data.referenceDataList.length]
  );

  return (
    <DefaultOwner title="Noon Report" selectedTitle={"Analysis"}>
      <Template
        form={speedForm.data}
        hasSpeedFormError={speedForm.hasSpeedFormError}
        onUpdateSpeedForm={onUpdateSpeedForm}
        onSpeedCreate={onSpeedCreate}
        FocForm={focForm.data}
        hasFocFormError={focForm.hasFocFormError}
        onUpdateFocForm={onUpdateFocForm}
        onFocCreate={onFocCreate}
        formType={formType}
        setFormType={setFormType}
      />
      <Loading isLoading={isLoading} />

      <CustomSnackbar
        openSnackbar={openSnackbar}
        handleClose={handleClose}
        isCreateSucusess={isCreateSucusess}
      />
    </DefaultOwner>
  );
};

export default Setting;
