export const downloadTextData = (fileName: string, text: string): void => {
  // BOM for Excel
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, text], {
    type: "text",
  });

  const url = (window.URL || window.webkitURL).createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();

  // revoke
  (window.URL || window.webkitURL).revokeObjectURL(url);
};
