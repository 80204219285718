import React from "react";
import Box from "@mui/material/Box";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { Form, Props } from "../../../pages/Crew/Bunkering/Create/types";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import CommonNumberFIeld from "../../../atoms/CommonNumberFIeld";
import { ShipDexie } from "../../../../dexie/Ship";
import { EUorUk, LorU } from "../../../../gen/graphql/types";
import Steppr from "../../../atoms/Stepper";
import RadioButtun from "../../../atoms/RadioButton";
import LastReportText from "../../../atoms/LastReportText";
import UtcDateTimePicker from "../../../atoms/UtcDateTimePicker";
import { BunkeringDexie } from "../../../../dexie/Bunkering";
import {
  getFileName,
  getLatLongValues,
  setFileName,
} from "../../../../utils/form-util";
import { lightGray, steelBlue, white } from "../../../../utils/color";
import ClearIcon from "@mui/icons-material/Clear";
import { useShip } from "../../../layouts/DefaultCrew";
import CommonSelect from "../../../atoms/CommonSelect";
import { convertEmptyStringToNull } from "../../../../utils/formats";
import { Bunkaring } from "./Bunkaring";
import { calculateBunkeringTimezone } from "./calculation/calculateBunkeringValues";

export type NestedBankeringFormBlockProps = FormBlockProps & {
  onChange: (
    key: string,
    value: string | number | Date | null | undefined
  ) => void;
};

export type FormBlockProps = Props & {
  title: string;
  isPreview: boolean;
  onUpdateForm?: (value: Form) => void;
  ship?: ShipDexie;
  lastBunkering?: BunkeringDexie;
  defaultBunkering?: BunkeringDexie;
  isConfirm: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  isServer?: boolean;
};

const convertToNumberOrNull = (value: string | null): number | null => {
  return value === null || value === "" ? null : Number(value);
};

const FormBlock = (props: FormBlockProps): React.ReactElement => {
  const ship = useShip();
  const onChange = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    if (typeof value === "string") {
      value = convertEmptyStringToNull(value);
    }

    const form = {
      ...props.form,
      [key]: value,
    };

    props.onUpdateForm?.(form);
  };

  const onUpdateWithTimezone = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    if (!props.isServer) {
      const form = calculateBunkeringTimezone({
        form: { ...props.form, [key]: value },
      });

      props.onUpdateForm?.({
        ...form,
        [key]: value,
      });
      return;
    }
    onChange(key, value);
  };

  props.form.B_Vessel_Name = props.ship ? props.ship.name : "";
  const lastBunkering = props.lastBunkering?.data;

  return (
    <Box component="form" noValidate autoComplete="off">
      <Box sx={{ pt: 0.5, px: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "top",
          }}
        >
          <Column>
            <Typography
              component="h3"
              variant="subtitle1"
              align="center"
              sx={{
                lineHeight: 1,
                fontWeight: "bold",
                color: white,
                fontSize: "1.5rem",
                verticalAlign: "super",
              }}
            >
              {props.title}
            </Typography>
            <Typography
              sx={{
                pt: 1,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "12px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: white,
              }}
            >
              *(Prev. Data)
            </Typography>
          </Column>
          <Column sx={{ width: "60%" }}>
            <Steppr step={0} />
          </Column>
        </Columns>
      </Box>
      <Box sx={{ px: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Column
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CommonTextFIeld
              isConfirm={props.isConfirm}
              disabled={props.isPreview}
              id="B_Voy"
              label="Voyage number"
              placeholder=""
              value={props.form.B_Voy}
              onChange={(e: string | null) => {
                onChange("B_Voy", e);
              }}
            />
          </Column>
          <LastReportText
            lastData={String(lastBunkering?.B_Voy)}
            sx={{ pt: 2 }}
          />
        </Columns>
        <Columns>
          <Column>
            <UtcDateTimePicker
              id={"B_Bunkering_Date_time"}
              isConfirm={props.isConfirm}
              width="26ch"
              label={"Date & Time (Local)"}
              value={props.form.B_Bunkering_Date_time}
              onChange={onChange}
              onChangeKey={"B_Bunkering_Date_time"}
              hasMinDate={true}
            />
          </Column>
        </Columns>
        <Columns
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Column sx={{ pr: -2, mr: -4 }}>
            <Typography
              sx={{
                pl: 1,
                mb: 1.5,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            >
              Lat./ Lon.
            </Typography>
            <Typography
              sx={{
                pl: 1,
                mb: 2.5,
                textAlign: "left",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            >
              Lat.
            </Typography>
          </Column>
          <Column sx={{ pl: -2 }}>
            <Typography
              sx={{
                pb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            ></Typography>
            <CommonNumberFIeld
              isConfirm={props.isConfirm}
              disabled={props.isPreview}
              id="B_Lat_Deg"
              label="Deg."
              placeholder=""
              value={convertToNumberOrNull(props.form.B_Lat_Deg)}
              maxValue={90}
              minValue={0}
              sx={{ width: "80px" }}
              onChange={(e) => {
                onChange("B_Lat_Deg", e);
              }}
            />
          </Column>
          <Column sx={{ pl: -15 }}>
            <Typography sx={{ color: lightGray, pt: 2 }}>：</Typography>
          </Column>
          <Column sx={{ pl: -15 }}>
            <Typography
              sx={{
                pb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            ></Typography>
            <CommonNumberFIeld
              isConfirm={props.isConfirm}
              disabled={props.isPreview}
              id="B_Lat_Min"
              label="Min."
              placeholder=""
              value={convertToNumberOrNull(props.form.B_Lat_Min)}
              maxValue={60}
              minValue={0}
              sx={{ width: "80px" }}
              onChange={(e) => {
                onChange("B_Lat_Min", e);
              }}
            />
          </Column>
          <Column sx={{ mt: 2 }}>
            <RadioButtun
              id="B_Lat_Direction"
              label=" "
              label1="North"
              label2="South"
              defaultValue={
                props.defaultBunkering?.data.B_LatLong
                  ? getLatLongValues(props.defaultBunkering?.data.B_LatLong)
                      .Lat_Direction
                  : "N"
              }
              value1="N"
              value2="S"
              onChange={onChange}
            />
          </Column>
          <Column>
            <Typography
              sx={{
                pl: 2,
                mb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            ></Typography>
            <Typography
              sx={{
                pt: 1,
                pb: 1,
                textAlign: "left",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            >
              Lon.
            </Typography>
          </Column>
          <Column>
            <Typography
              sx={{
                pb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            ></Typography>
            <CommonNumberFIeld
              isConfirm={props.isConfirm}
              disabled={props.isPreview}
              id="B_Long_Deg"
              label="Deg."
              placeholder=""
              value={convertToNumberOrNull(props.form.B_Long_Deg)}
              maxValue={180}
              minValue={0}
              sx={{ width: "90px" }}
              onChange={(e) => onUpdateWithTimezone("B_Long_Deg", e)}
            />
          </Column>
          <Column sx={{ pl: -15 }}>
            <Typography sx={{ color: white, pt: 2 }}>：</Typography>
          </Column>
          <Column sx={{ pl: -15 }}>
            <Typography
              sx={{
                pb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            ></Typography>
            <CommonNumberFIeld
              isConfirm={props.isConfirm}
              disabled={props.isPreview}
              id="B_Long_Min"
              label="Min."
              placeholder=""
              value={convertToNumberOrNull(props.form.B_Long_Min)}
              maxValue={60}
              minValue={0}
              sx={{ width: "80px" }}
              onChange={(e) => onUpdateWithTimezone("B_Long_Min", e)}
            />
          </Column>
          <Column sx={{ mt: 2 }}>
            <RadioButtun
              id="B_Long_Direction"
              defaultValue={
                props.defaultBunkering?.data.B_LatLong
                  ? getLatLongValues(props.defaultBunkering?.data.B_LatLong)
                      .Long_Direction
                  : "E"
              }
              label=" "
              label1="East"
              label2="West"
              value1="E"
              value2="W"
              onChange={(e, value) => onUpdateWithTimezone(e, value)}
            />
          </Column>
          <Column sx={{ mt: -1 }}>
            <Typography
              sx={{
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
                pb: 0.5,
              }}
            >
              Time Zone (UTC±)
            </Typography>
            <RadioButtun
              id="B_Timezone"
              value={props.form.B_TimeZone_PorN}
              defaultValue={
                props.defaultBunkering?.data.B_Timezone.slice(0, 1) || "+"
              }
              label=""
              label1="＋"
              label2="ー"
              value1="+"
              value2="-"
              onChange={onChange}
            />
          </Column>
          <Column sx={{ pl: -2 }}>
            <Typography
              sx={{
                pb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            ></Typography>
            <Select
              size="small"
              labelId="B_TimeZone_HH"
              id="B_TimeZone_HH"
              label="Hour."
              placeholder="Hour."
              value={props.form.B_TimeZone_HH}
              onChange={(e: SelectChangeEvent<string | null>) => {
                onChange("B_TimeZone_HH", e.target.value);
              }}
              inputProps={{
                "aria-label": `B_TimeZone_HH`,
              }}
              sx={{
                width: "70px",
                "& .MuiSelect-outlined": {
                  color: white,
                },
                "& .MuiSelect-select": {
                  color: white,
                  border: `1px solid ${steelBlue}`,
                },
                "& .MuiSelect-select:hover": {
                  color: white,
                  border: `1px solid ${lightGray} !important`,
                },

                "& .MuiSelect-icon": {
                  color: "transparent",
                },
                "&:hover fieldset": {
                  border: steelBlue,
                },
                textAlign: "left",
              }}
            >
              {[...Array(15).keys()]
                .map((v) => ("00" + String(v)).slice(-2))
                .map((v, index) => {
                  return (
                    <MenuItem value={v} key={index}>
                      {v}
                    </MenuItem>
                  );
                })}
            </Select>
          </Column>
          <Column sx={{ pl: -15 }}>
            <Typography sx={{ color: lightGray, pt: 2 }}>：</Typography>
          </Column>
          <Column sx={{ pl: -15 }}>
            <Typography
              sx={{
                pb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            ></Typography>
            <Select
              size="small"
              labelId="B_TimeZone_MM"
              id="B_TimeZone_MM"
              label="Min."
              placeholder="Min."
              value={props.form.B_TimeZone_MM}
              onChange={(e: SelectChangeEvent<string | null>) => {
                onChange("B_TimeZone_MM", e.target.value);
              }}
              inputProps={{
                "aria-label": `B_TimeZone_MM`,
              }}
              sx={{
                width: "70px",
                "& .MuiSelect-outlined": {
                  color: white,
                },
                "& .MuiSelect-select": {
                  color: white,
                  border: `1px solid ${steelBlue}`,
                },
                "& .MuiSelect-select:hover": {
                  color: white,
                  border: `1px solid ${lightGray} !important`,
                },

                "& .MuiSelect-icon": {
                  color: "transparent",
                },
                "&:hover fieldset": {
                  border: steelBlue,
                },
                textAlign: "left",
              }}
            >
              {["00", "30", "45"].map((v, index) => {
                return (
                  <MenuItem value={v} key={index}>
                    {v}
                  </MenuItem>
                );
              })}
            </Select>
          </Column>
        </Columns>
        <Stack
          direction="row"
          spacing={2}
          alignItems="end"
          justifyContent={"left"}
        >
          <CommonTextFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="B_PortName"
            label="Port name"
            placeholder=""
            value={props.form.B_PortName}
            onChange={(e) => {
              onChange("B_PortName", e);
            }}
          />
          <CommonTextFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="B_UNLOC"
            label="UNLOC"
            placeholder=""
            value={props.form.B_UNLOC}
            onChange={(e) => {
              onChange("B_UNLOC", e);
            }}
          />
          <CommonSelect
            id="B_EUorUK"
            label="EU/UK"
            value={props.form.B_EUorUK || ""}
            onChange={(e) => {
              onChange("B_EUorUK", e);
            }}
            selectValues={Object.values(EUorUk)}
            isOptional={true}
            sx={{ m: "0 !important" }}
          />
          <CommonSelect
            id="B_Port_LorU"
            label="L or U"
            value={props.form.B_Port_LorU}
            onChange={(e) => {
              onChange("B_Port_LorU", e);
            }}
            selectValues={Object.values(LorU)}
          />
        </Stack>
        <Bunkaring {...props} onChange={onChange} />

        <Columns
          sx={{
            display: "flex",
            alignItems: "end",
          }}
        >
          <Column>
            <Typography
              sx={{
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
                pb: 0.5,
              }}
            >
              Evidence File Upload
            </Typography>
          </Column>
        </Columns>
        <Box
          sx={{
            mt: 3,
            opacity: 1,
            border: "dashed 1px #AAAAAA",
            borderRadius: "10px",
            width: "80%",
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <Typography>{getFileName(props.form.B_Filename)}</Typography>
            {props.form.B_Filename && (
              <IconButton onClick={() => onChange("B_Filename", "")}>
                <ClearIcon />
              </IconButton>
            )}
          </Stack>
          <Button variant="outlined" component="label">
            Upload
            <input
              type="file"
              hidden
              onChange={(event) => {
                const file = event.target.files?.item(0);
                if (file instanceof File) {
                  props.setFile(file);

                  onChange(
                    "B_Filename",
                    setFileName(file.name, ship?.IMO_No || "")
                  );
                } else {
                  console.warn("File does not exist");
                }
              }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FormBlock;
