import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../dexie/db";
import { DepartureDexie } from "../../../../dexie/Departure";
import Template from "../../../templates/Crew/Departure/Update";
import { useGetPreviousArrival } from "../Arrival/Update/Confirm";
import Loading from "../../../atoms/Loading";
import {
  mapToFormDepartureData,
  useDepartureForm,
  mapToDepartureMutationVariables,
} from "./departure.module";

const Update = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const [isConfirm, setIsConfirm] = useState(false);

  const navigate = useNavigate();
  const ship = useShip();
  const location = useLocation();
  const location_form = location.state;

  if (!report_id) navigate("/");

  const lastArrival = useGetPreviousArrival(report_id);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const onSaveDraft = (value: Form) => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToDepartureMutationVariables(value, ship?.ship_id);
    const updateDeparture: DepartureDexie = {
      id: report_id,
      isDraft: true,
      isSend: false,
      data: variables,
      draftForm: value,
    };
    db.departure
      .update(report_id, updateDeparture)
      .catch((e) => console.error(e));
    navigate(`/crew/departure`);
  };

  const departure = useLiveQuery(() => db.departure.get(report_id));

  const form = useDepartureForm([
    location_form,
    departure?.draftForm,
    mapToFormDepartureData(departure?.data),
  ]);

  const onConfirm = () => {
    setIsConfirm(true);
    if (form.hasError) return;
    navigate(`/departure/update/confirm/${reportId}`, {
      state: { form: form.data },
    });
  };

  return (
    <Default title="Noon Report" selectedTitle={"Departure"}>
      {departure && !form.isLoading ? (
        <Template
          defaultDepartue={departure}
          form={form.data}
          hasFormError={form.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          onSaveDraft={onSaveDraft}
          ship={ship}
          lastArrival={lastArrival}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Default>
  );
};

export default Update;
