import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Column from "../../../atoms/Column";
import {
  FilterBooleanType,
  ReferenceData,
  ReportGraph,
} from "../../../pages/Owner/Analysis/types";
import {
  BaselineTitleList,
  FilterTitleList,
} from "../../../pages/Owner/Analysis/const";
import {
  DefaultFilterCondition,
  SpeedConsumptionFilterCondition,
  compareFilterConditions,
} from "../../../pages/Owner/Analysis/queries";
import { Typography, Stack, Alert } from "@mui/material";
import SpeedConsumptionFilterModal from "../../../organisms/Owner/Analysis/SpeedConsumptionFilterModal";
import ShowHideGraphButton from "../../../atoms/ShowHideGraphButton";
import {
  limeGreen,
  paleGray,
  plumPurple,
  hotPink,
  skyBlue,
  sunnyYellow,
  taupeBrown,
  vividGreen,
  white,
} from "../../../../utils/color";
import { useNavigate } from "react-router";
import { pageData } from "../../../../App";
import LightGrayButton from "../../../atoms/LightGrayButton";
import SucsessButton from "../../../atoms/SucsessButton";
import { FuelConsumptionUsageFilterModal } from "../../../organisms/Owner/Analysis/FineTuningFilterModal";
import { useVesselNames } from "../../../atoms/VesselNamesSelect";
import {
  Legend,
  ReportView,
} from "../../../organisms/Owner/Analysis/ReportView";
import { SpeedConsumptionIndividualFilterModal } from "../../../organisms/Owner/Analysis/SpeedConsumptionIndividualFilterModal";
import {
  useGetAnalysisSearchParameter,
  useUpsertAnalysisSearchParameter,
} from "../../../organisms/Owner/Analysis/AnalysisSearchParameterModal/AnalysisSearchParameterModal.hook";
import Loading from "../../../atoms/Loading";
import { SaveFilteDataModal } from "../../../organisms/Owner/Analysis/SaveFilteDataModal";
import { DownloadZIP } from "../../../organisms/Owner/Analysis/DownloadZIP";

function isOnlySameShips(imoNoList: string[]): boolean {
  if (imoNoList.length === 0) return true; // If there are no elements, return true
  const firstElement = imoNoList[0];
  return imoNoList.every((element) => element === firstElement);
}

type AnalysisProps = {
  referenceDataList: ReferenceData[];
  graph1: ReportGraph[];
  filterForm1: SpeedConsumptionFilterCondition;
  hasFilterFormError1: boolean;
  onUpdateFilterForm1(value: SpeedConsumptionFilterCondition): void;
  onApplyFilter1(value: SpeedConsumptionFilterCondition): void;
  isGraph1DataFetched: boolean;
  graph2: ReportGraph[];
  filterForm2: SpeedConsumptionFilterCondition;
  hasFilterFormError2: boolean;
  onUpdateFilterForm2(value: SpeedConsumptionFilterCondition): void;
  onApplyFilter2(value: SpeedConsumptionFilterCondition): void;
  isGraph2DataFetched: boolean;
  graph3: ReportGraph[];
  filterForm3: SpeedConsumptionFilterCondition;
  hasFilterFormError3: boolean;
  onUpdateFilterForm3(value: SpeedConsumptionFilterCondition): void;
  onApplyFilter3(value: SpeedConsumptionFilterCondition): void;
  isGraph3DataFetched: boolean;
  graph4: ReportGraph[];
  filterForm4: SpeedConsumptionFilterCondition;
  hasFilterFormError4: boolean;
  onUpdateFilterForm4(value: SpeedConsumptionFilterCondition): void;
  onApplyFilter4(value: SpeedConsumptionFilterCondition): void;
  isGraph4DataFetched: boolean;
  onResetFilter1: () => void;
  onResetFilter2: () => void;
  onResetFilter3: () => void;
  onResetFilter4: () => void;
  onResetAllFilter: () => void;
  isMeUsageFilter: FilterBooleanType;
  setIsMeUsageFilter: React.Dispatch<React.SetStateAction<FilterBooleanType>>;
  onUpdateAllFilterForm(value: SpeedConsumptionFilterCondition): void;
  filterAllForm: {
    data: SpeedConsumptionFilterCondition;
    update: React.Dispatch<
      React.SetStateAction<SpeedConsumptionFilterCondition>
    >;
    hasFilterFormError: boolean;
  };
  isStandardDeviationFilter: FilterBooleanType;
  setIsStandardDeviationFilter: React.Dispatch<
    React.SetStateAction<FilterBooleanType>
  >;
};

const defaultCheckedMeRevolutionOver100: FilterBooleanType = {
  filter1: false,
  filter2: false,
  filter3: false,
  filter4: false,
};

export default function Analysis(props: AnalysisProps) {
  const navigate = useNavigate();
  const vesselName = useVesselNames();

  const { loadDataAnalysisSearchParameter, analysisSearchParameter } =
    useGetAnalysisSearchParameter();

  const [checkedMeRevolutionOver100, setCheckedMeRevolutionOver100] =
    useState<FilterBooleanType>(defaultCheckedMeRevolutionOver100);

  const {
    upsertAnalysisSearchParameter,
    isUpsertLoading,
    analysisSearchParameterTitle,
    setAnalysisSearchParameterTitle,
  } = useUpsertAnalysisSearchParameter(
    props.filterForm1,
    props.filterForm2,
    props.filterForm3,
    props.filterForm4,
    checkedMeRevolutionOver100
  );

  const [isGraph1, setGraph1] = useState<boolean>(false);
  const [isGraph2, setGraph2] = useState<boolean>(false);
  const [isGraph3, setGraph3] = useState<boolean>(false);
  const [isGraph4, setGraph4] = useState<boolean>(false);

  const onChangeGraph1 = () => {
    setGraph1(true);
    props.onApplyFilter1?.(props.filterForm1);
  };

  const onChangeGraph2 = () => {
    setGraph2(true);
    props.onApplyFilter2?.(props.filterForm2);
  };

  const onChangeGraph3 = () => {
    setGraph3(true);
    props.onApplyFilter3?.(props.filterForm3);
  };

  const onChangeGraph4 = () => {
    setGraph4(true);
    props.onApplyFilter4?.(props.filterForm4);
  };

  const onChangeAllGraph = () => {
    onChangeGraph1();
    onChangeGraph2();
    onChangeGraph3();
    onChangeGraph4();
  };

  const resetAllFilter = () => {
    setAnalysisSearchParameterTitle({ id: 0, title: "" });
    props.onResetAllFilter();
  };

  const [showModalFocUsage, setShowModalFocUsage] = useState(false);
  const [showModalAll, setShowModalAll] = useState(false);
  const [showModalIndividual, setShowModalIndividual] = useState(false);
  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);

  const [isDownloadZip, setIsDownloadZip] = useState(false);

  useEffect(() => {
    const graphs = [
      { isGraphDataFetched: props.isGraph1DataFetched, setGraph: setGraph1 },
      { isGraphDataFetched: props.isGraph2DataFetched, setGraph: setGraph2 },
      { isGraphDataFetched: props.isGraph3DataFetched, setGraph: setGraph3 },
      { isGraphDataFetched: props.isGraph4DataFetched, setGraph: setGraph4 },
    ];

    graphs.forEach((graph) => {
      if (graph.isGraphDataFetched) {
        graph.setGraph(true);
      }
    });
  }, [
    props.isGraph1DataFetched,
    props.isGraph2DataFetched,
    props.isGraph3DataFetched,
    props.isGraph4DataFetched,
  ]);

  const isSomeGraph =
    (props.graph1.length > 0 && isGraph1) ||
    (props.graph2.length > 0 && isGraph2) ||
    (props.graph3.length > 0 && isGraph3) ||
    (props.graph4.length > 0 && isGraph4);

  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ display: "flex" }}
    >
      <Paper
        sx={{
          backgroundColor: !isDownloadZip ? paleGray : undefined,
          borderRadius: "20px",
          width: "1240px",
          mb: 5,
        }}
      >
        <Loading isLoading={isDownloadZip || isUpsertLoading} />
        <Typography sx={{ fontSize: 24, width: 250, mt: 3, ml: 2, }}>
          Speed Consumption
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ p: 2, py: 3.5 }}
        >
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"flex-start"}
            alignItems={"center"}
            sx={{ mr: 3 }}
          >
            <SucsessButton
              ButtonName={FilterTitleList.Filter_All}
              disabled={false}
              sx={{
                color: white,
                fontSize: 14,
                pl: 2,
              }}
              onClick={() => setShowModalAll((prev) => !prev)}
            />
            <SucsessButton
              ButtonName={"Setting Individual Filters"}
              disabled={false}
              sx={{
                color: white,
                fontSize: 14,
                whiteSpace: "nowrap",
              }}
              onClick={() => setShowModalIndividual((prev) => !prev)}
            />
            <LightGrayButton
              ButtonName={"Clear All Filter"}
              sx={{ fontSize: 14 }}
              onClick={() => {
                setCheckedMeRevolutionOver100(
                  defaultCheckedMeRevolutionOver100
                );
                resetAllFilter();
              }}
            />
            <SucsessButton
              ButtonName={"Save Setting Filters"}
              disabled={false}
              sx={{
                color: white,
                fontSize: 14,
                pl: 2,
              }}
              onClick={() => {
                setShowSaveFilterModal((prev) => !prev);
              }}
            />
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            spacing={1}
          >
            <SucsessButton
              ButtonName={"Fine tuning filter"}
              disabled={false}
              sx={{
                width: "150px",

                color: white,
                fontSize: 14,
              }}
              onClick={() => setShowModalFocUsage((prev) => !prev)}
            />
            <SucsessButton
              ButtonName={"Setting Baseline"}
              disabled={false}
              sx={{
                width: "150px",

                color: white,
                fontSize: 14,
              }}
              onClick={() => navigate(pageData.ownerSetting.path())}
            />
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            pr: 10,
            py: 1,
            pl: 4,
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={1}
          >
            <ShowHideGraphButton
              ButtonName={FilterTitleList.Filter_1}
              isGraph={isGraph1}
              reportGraph={props.graph1}
              onClick={() =>
                setGraph1((v) => {
                  return v ? false : true;
                })
              }
              sx={{ borderRadius: 30, height: "30px" }}
              backgroundColor={sunnyYellow}
            />
            <ShowHideGraphButton
              ButtonName={FilterTitleList.Filter_2}
              isGraph={isGraph2}
              reportGraph={props.graph2}
              onClick={() =>
                setGraph2((v) => {
                  return v ? false : true;
                })
              }
              sx={{ borderRadius: 30, height: "30px" }}
              backgroundColor={limeGreen}
            />
            <ShowHideGraphButton
              ButtonName={FilterTitleList.Filter_3}
              isGraph={isGraph3}
              reportGraph={props.graph3}
              onClick={() =>
                setGraph3((v) => {
                  return v ? false : true;
                })
              }
              sx={{ borderRadius: 30, height: "30px" }}
              backgroundColor={taupeBrown}
            />
            <ShowHideGraphButton
              ButtonName={FilterTitleList.Filter_4}
              isGraph={isGraph4}
              reportGraph={props.graph4}
              onClick={() =>
                setGraph4((v) => {
                  return v ? false : true;
                })
              }
              sx={{ borderRadius: 30, height: "30px" }}
              backgroundColor={plumPurple}
            />
          </Stack>

          <Stack direction={"row"} alignItems={"center"}>
            <Legend
              title={BaselineTitleList.Ballast}
              circleColor={vividGreen}
            />
            <Legend title={BaselineTitleList.Laden} circleColor={skyBlue} />
            <Legend title={BaselineTitleList.Unique} circleColor={hotPink} />
          </Stack>
        </Stack>

        <DownloadZIP
          isDownloadZip={isDownloadZip}
          filterForm1={props.filterForm1}
          filterForm2={props.filterForm2}
          filterForm3={props.filterForm3}
          filterForm4={props.filterForm4}
          setIsDownloadZip={setIsDownloadZip}
          checkedMeRevolutionOver100={checkedMeRevolutionOver100}
          isMeUsageFilter={props.isMeUsageFilter}
        >
          {isSomeGraph ? (
            <ReportView
              referenceDataList={
                (isGraph1 || isGraph2 || isGraph3 || isGraph4) &&
                isOnlySameShips(
                  [
                    props.filterForm1.imo_no,
                    props.filterForm2.imo_no,
                    props.filterForm3.imo_no,
                    props.filterForm4.imo_no,
                  ].filter((v): v is NonNullable<typeof v> => v !== null)
                )
                  ? props.referenceDataList
                  : []
              }
              graph1={isGraph1 ? props.graph1 : []}
              graph2={isGraph2 ? props.graph2 : []}
              graph3={isGraph3 ? props.graph3 : []}
              graph4={isGraph4 ? props.graph4 : []}
              formGraph1={props.filterForm1}
              formGraph2={props.filterForm2}
              formGraph3={props.filterForm3}
              formGraph4={props.filterForm4}
              shipNames={{
                ship1: vesselName.getVesselName({
                  imo_no: props.filterForm1.imo_no,
                }),
                ship2: vesselName.getVesselName({
                  imo_no: props.filterForm2.imo_no,
                }),
                ship3: vesselName.getVesselName({
                  imo_no: props.filterForm3.imo_no,
                }),
                ship4: vesselName.getVesselName({
                  imo_no: props.filterForm4.imo_no,
                }),
              }}
              isDownloadZip={isDownloadZip}
            />
          ) : (
            <Column />
          )}
        </DownloadZIP>

        <Stack direction="row" justifyContent="flex-end" sx={{ px: 2, py: 2 }}>
          <SucsessButton
            ButtonName={"Download ZIP File"}
            disabled={!isSomeGraph}
            sx={{
              width: "200px",

              color: white,
              fontSize: 14,
            }}
            onClick={() => {
              setIsDownloadZip(true);
            }}
          />
        </Stack>
      </Paper>

      {compareFilterConditions(
        [
          props.filterForm1,
          props.filterForm2,
          props.filterForm3,
          props.filterForm4,
        ],
        DefaultFilterCondition
      ) && <Alert severity="warning">Set filter to view graphs</Alert>}

      <SpeedConsumptionFilterModal
        filterTitle={FilterTitleList.Filter_All}
        isOpen={showModalAll}
        filterForm={props.filterAllForm.data}
        onUpdateFilterForm={props.onUpdateAllFilterForm}
        setIsModalOpen={setShowModalAll}
        onChangeGraph={onChangeAllGraph}
        hasFilterFormError={props.filterAllForm.hasFilterFormError}
        onResetFilter={resetAllFilter}
        setCheckedMeRevolutionOver100={setCheckedMeRevolutionOver100}
        checkedMeRevolutionOver100={checkedMeRevolutionOver100}
        isMeUsageFilter={props.isMeUsageFilter}
        setIsMeUsageFilter={props.setIsMeUsageFilter}
      />
      <SpeedConsumptionIndividualFilterModal
        isOpen={showModalIndividual}
        filterForm1={props.filterForm1}
        filterForm2={props.filterForm2}
        filterForm3={props.filterForm3}
        filterForm4={props.filterForm4}
        onUpdateFilterForm1={props.onUpdateFilterForm1}
        onUpdateFilterForm2={props.onUpdateFilterForm2}
        onUpdateFilterForm3={props.onUpdateFilterForm3}
        onUpdateFilterForm4={props.onUpdateFilterForm4}
        setIsModalOpen={setShowModalIndividual}
        onChangeGraph={onChangeAllGraph}
        onResetFilter={resetAllFilter}
        setCheckedMeRevolutionOver100={setCheckedMeRevolutionOver100}
        checkedMeRevolutionOver100={checkedMeRevolutionOver100}
        isMeUsageFilter={props.isMeUsageFilter}
        setIsMeUsageFilter={props.setIsMeUsageFilter}
        hasFilterFormError={{
          filter1: props.hasFilterFormError1,
          filter2: props.hasFilterFormError2,
          filter3: props.hasFilterFormError3,
          filter4: props.hasFilterFormError4,
        }}
        setAnalysisSearchParameterTitle={setAnalysisSearchParameterTitle}
        loadDataAnalysisSearchParameter={loadDataAnalysisSearchParameter}
        analysisSearchParameter={analysisSearchParameter}
      />
      <FuelConsumptionUsageFilterModal
        isOpen={showModalFocUsage}
        setIsModalOpen={setShowModalFocUsage}
        isMeUsageFilter={props.isMeUsageFilter}
        setIsMeUsageFilter={props.setIsMeUsageFilter}
        isStandardDeviationFilter={props.isStandardDeviationFilter}
        setIsStandardDeviationFilter={props.setIsStandardDeviationFilter}
      />
      <SaveFilteDataModal
        isOpen={showSaveFilterModal}
        setIsModalOpen={setShowSaveFilterModal}
        analysisSearchTitle={analysisSearchParameterTitle}
        setAnalysisSearchParameterTitle={setAnalysisSearchParameterTitle}
        upsertAnalysisSearchParameter={upsertAnalysisSearchParameter}
        loadDataAnalysisSearchParameter={loadDataAnalysisSearchParameter}
      />
    </Stack>
  );
}
