/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Template from "../../../templates/Owner/NoonReport/NoonReport";
import { useApolloClient } from "@apollo/client";
import {
  CSV_DOWNLOAD_LIMIT,
  downloadCsvFile,
} from "../../../../utils/download-csv-util";
import { useContextReportSearchCondition } from "../../../../App";
import { genCsv } from "./NoonReportCsvFormat";
import { useParams } from "react-router";
import { useVesselNames } from "../../../atoms/VesselNamesSelect";
import { getNoonReportCsvDownloadData } from "./NoonReportUtil";
import {
  getReportList,
  mapReportListInput,
  useReports,
} from "../../../../hooks/commonQuery/noonReport.hook";
import { ReportSearchCondition } from "../../pages.module";
import { useSendReportToMrvMutation } from "../../../../gen/graphql/types";
import { queryParamImoNumber } from "../../../../utils/const";
import { useSearchParams } from "react-router-dom";

export const SEARCH_PAGE_LIMIT_OWNER = 15;

export const useCsvDownloadForm = () => {
  const [data, update] = useState<ReportSearchCondition>({
    imo_no: null,
    Voy: null,
    From_DateTime: null,
    To_DateTime: null,
    pagination: {
      page: 1,
      limit: CSV_DOWNLOAD_LIMIT,
    },
  });

  const hasCsvFormError = useMemo(() => {
    const { imo_no } = data;

    const hasError = !imo_no;

    return hasError;
  }, [data]);

  return {
    data,
    update,
    hasCsvFormError,
  };
};

const useForm = () => {
  const [data, update] = useState<ReportSearchCondition>({
    imo_no: null,
    Voy: null,
    From_DateTime: null,
    To_DateTime: null,
    pagination: {
      page: 1,
      limit: SEARCH_PAGE_LIMIT_OWNER,
    },
  });

  return {
    data,
    update,
  };
};

const NoonReport = (): React.ReactElement => {
  const reports = useReports();
  const form = useForm();
  const csvForm = useCsvDownloadForm();
  const client = useApolloClient();
  const shipList = useVesselNames();
  const [searchParams] = useSearchParams();
  const queryImoNumber = searchParams.get(queryParamImoNumber);
  const { imo_number: pathImoNumber } = useParams();
  const imoNumber = queryImoNumber || pathImoNumber;
  const { reportSearchCondition, setReportSearchCondition } =
    useContextReportSearchCondition();

  const onUpdateForm = (value: ReportSearchCondition) => {
    form.update(value);
  };

  const onUpdateCsvForm = (value: ReportSearchCondition) => {
    csvForm.update(value);
  };

  const onUpdateSearchCondition = (
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ) => {
    form.update(value);
    if (!isWithRefresh) {
      return;
    }

    reports.refetch(form.data);
  };

  const onCsvDownload = useCallback(async () => {
    csvForm.data.pagination.page = 1;
    const input = mapReportListInput(csvForm.data);

    try {
      let data = await getNoonReportCsvDownloadData(input, client);
      let reportList = getReportList(data);

      if (data?.reportList?.totalPageCount > CSV_DOWNLOAD_LIMIT) {
        const iterations = Math.ceil(
          data?.reportList?.totalPageCount / CSV_DOWNLOAD_LIMIT
        );

        for (let i = 1; i < iterations; i++) {
          csvForm.data.pagination.page = i + 1; // Adjust input to fetch the next page.
          const input = mapReportListInput(csvForm.data);
          data = await getNoonReportCsvDownloadData(input, client);
          const additionalReportList = getReportList(data);
          reportList = [...reportList, ...additionalReportList]; // merge the new data with existing reportList
        }
      }

      const { fileName, text } = await genCsv(
        csvForm.data,
        reportList.reverse()
      ).catch((e) => {
        console.error(e);
        throw Error("");
      });

      downloadCsvFile(fileName, text);
    } catch (e) {
      console.error(e);
      alert("Failed to download CSV");
    } finally {
      csvForm.data.pagination.page = 1;
    }
  }, [client, csvForm.data]);

  const onSearch = () => {
    form.data.pagination.page = 1;
    setReportSearchCondition(form.data);
    reports.refetch(form.data);
  };

  const [sendReportToMrvMutation, { loading: isSendReportLoading }] =
    useSendReportToMrvMutation();
  const [sendingReportId, setSendingReportId] = useState<number | null>(null);

  const onSendReportToMrv = useCallback(
    (reportId: number) => {
      return async () => {
        setSendingReportId(reportId);
        try {
          const result = await sendReportToMrvMutation({
            variables: {
              input: {
                reportId: reportId,
              },
            },
          });
          if (result.data?.sendReportToMrv?.success !== true) {
            throw Error(
              result.data?.sendReportToMrv?.message ??
                "Failed to send report to MRV"
            );
          }
        } catch (e) {
          if (e instanceof Error) {
            alert(e.message);
          } else {
            alert("Failed to send report to MRV");
          }
        }
        return;
      };
    },
    [sendReportToMrvMutation]
  );

  useEffect(() => {
    if (reportSearchCondition != undefined) {
      form.data = reportSearchCondition;
      form.update(form.data);
      reports.refetch(form.data);
      return;
    } else {
      if (!shipList.isLoading && imoNumber) {
        if (isNaN(parseInt(imoNumber))) {
          return;
        }
        form.data.imo_no = imoNumber;
      }
      reports.refetch(form.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipList.isLoading]);

  return (
    <Template
      reports={reports.data.reportList}
      isLoading={reports.isLoading}
      totalPageCount={reports.data.totalPageCount}
      hasCsvFormError={csvForm.hasCsvFormError}
      form={form.data}
      onUpdateForm={onUpdateForm}
      onSearch={onSearch}
      onUpdateSearchCondition={onUpdateSearchCondition}
      csvForm={csvForm.data}
      onUpdateCsvForm={onUpdateCsvForm}
      onCsvDownload={onCsvDownload}
      onSendReportToMrv={onSendReportToMrv}
      sendingInfo={{
        getIsMrvPortalIntegrationTargetByShipId:
          shipList.getIsMrvPortalIntegrationTargetByShipId,
        isSending: isSendReportLoading,
        reportId: sendingReportId,
      }}
    />
  );
};

export default NoonReport;
