import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme,
} from "@mui/material";
import type { SelectProps as MuiSelectProps } from "@mui/material";

type SelectProps = {
  label: string;
  value: string;
};

export type SelectFormProps = MuiSelectProps & {
  inputRef?: MuiSelectProps["ref"];
  errorMessage?: string;
  selectPropsList: SelectProps[];
  selectedValue: string;
  defaultValue?: string;
  sx?: SxProps<Theme>;
};

export const SelectForm: React.FC<SelectFormProps> = ({
  inputRef,
  errorMessage,
  selectPropsList,
  selectedValue,
  defaultValue,
  label,
  sx,
  ...rest
}) => {
  return (
    <div>
      <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select
          ref={inputRef}
          defaultValue={defaultValue}
          value={selectedValue}
          label={label}
          sx={{ ...sx }}
          {...rest}
        >
          {selectPropsList.map((props) => (
            <MenuItem key={props.value} value={props.value}>
              {props.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {!!errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
};
