import { useCallback, useState } from "react";

export const getTimeZoneValues = (timeZone: string) => {
  const TimeZone_PorN = timeZone.slice(0, 1);
  const TimeZone_HH = timeZone.slice(1, 3);
  const TimeZone_MM = timeZone.slice(4, 6);

  return { TimeZone_PorN, TimeZone_HH, TimeZone_MM };
};

export const getTimeZone = (
  timeZone_PorN: string | null | undefined,
  timeZone_HH: string | null | undefined,
  timeZone_MM: string | null | undefined
) => {
  const timeZone =
    (timeZone_PorN || "") + (timeZone_HH || "") + ":" + (timeZone_MM || "");
  return timeZone;
};

export const getLatLongValues = (latLong: string) => {
  const LatLongArr = latLong.split("/");
  const Lat = LatLongArr[0];
  const Lat_Deg = Lat.split("-")[0];
  const Lat_Min = Lat.split("-")[1].slice(0, -1);
  const Lat_Direction = Lat.split("-")[1].slice(-1);
  const Long = LatLongArr[1];
  const Long_Deg = Long.split("-")[0];
  const Long_Min = Long.split("-")[1].slice(0, -1);
  const Long_Direction = Long.split("-")[1].slice(-1);

  return {
    Lat_Deg,
    Lat_Min,
    Lat_Direction,
    Long_Deg,
    Long_Min,
    Long_Direction,
  };
};

export const getLatLong = (
  Lat_Deg?: string | number | null,
  Lat_Min?: string | number | null,
  Lat_Direction?: string | null,
  Long_Deg?: string | number | null,
  Long_Min?: string | number | null,
  Long_Direction?: string | number | null
) => {
  const LatLong =
    (Lat_Deg || "") +
    "-" +
    (Lat_Min || " ") +
    (Lat_Direction || " ") +
    "/" +
    (Long_Deg || "") +
    "-" +
    (Long_Min || " ") +
    (Long_Direction || " ");
  return LatLong;
};
export const setFileName = (fileName: string, imo_no: string) => {
  return `${imo_no}-${new Date().getTime()}-${fileName}`;
};

//setFileNameにおいてユニークにするために"-"二つの区切りを先頭につけることを前提としたファイル名取得
export const getFileName = (fileName = "") => {
  if (!fileName) return fileName;

  const splitStr: string[] = fileName.split("-");
  if (splitStr.length >= 3) {
    return splitStr.slice(2).join("-");
  } else {
    return fileName;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function compareForms(form1: any, form2: any): boolean {
  if (!form1 || !form2) {
    // If both forms are undefined or null, they're equal
    return form1 === form2;
  }

  const keys = Object.keys(form1);

  for (const key of keys) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value1 = (form1 as any)[key];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value2 = (form2 as any)[key];

    if (value1 !== value2) {
      return false;
    }
  }

  return true; // All properties are equal
}

export function useAdditonaConsumptionRowCounter(
  defaultIndex?: number,
  maxAdditonaConsumptionIndex = 5
) {
  const [additionalIndex, setCount] = useState(defaultIndex ? defaultIndex : 1);

  const increment = useCallback(() => {
    setCount((prevCount) =>
      prevCount < maxAdditonaConsumptionIndex ? prevCount + 1 : prevCount
    );
  }, [maxAdditonaConsumptionIndex]);

  const decrement = useCallback(() => {
    setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  }, []);

  const additionalRows = new Array(additionalIndex).fill(null);

  return {
    additionalIndex,
    increment,
    decrement,
    additionalRows,
    maxAdditonaConsumptionIndex,
  };
}
