import { ApolloClient } from "@apollo/client";
import {
  CargoListDocument,
  CargoListInput,
} from "../../../../gen/graphql/types";

export const getCargoCsvDownloadData = async (
  input: CargoListInput,
  client: ApolloClient<object>
) => {
  const { data } = await client.query({
    query: CargoListDocument,
    variables: { input },
    fetchPolicy: "network-only",
  });

  return data;
};
