/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import Template from "../../../templates/Owner/Departure/DepartureDetail";
import { LocationState, Form } from "../../Crew/Departure/Create/types";
import { useLazyInitializeState } from "../../../../utils/lazy-initialize-state";
import {
  LorB,
  GetOwnerDepartureQuery,
  useGetOwnerDepartureQuery,
  LorU,
} from "../../../../gen/graphql/types";
import { pageData } from "../../../../App";
import DefaultOwner from "../../../layouts/DefaultOwner";
import { emptyToDoubleQuotation } from "../../../../utils/formats";
import { filterArrUndefinedNull } from "../../../../utils/type-checks";

type Maybe<T> = T | null | undefined;

export const useForm = (initializes: Maybe<Form>[]) => {
  const initialize = {
    ship_id: null,
    user_id: null,
    D_Vessel_Name: null,
    D_Voy: null,
    D_Last_Voy: null,
    D_LorB: LorB.Ballast,
    D_Departure_Port: null,
    D_EU_UK_port: null,
    D_Port_L_U: LorU.N,
    D_UNLOC: "",
    D_Departure_Date_Time: null,
    D_RU_Date_Time: null,
    D_TimeZone_PorN: "+",
    D_TimeZone_HH: null,
    D_TimeZone_MM: "00",
    D_ROB_FO: null,
    D_ROB_MGO: null,
    D_ROB_MECO: null,
    D_ROB_MESO: null,
    D_ROB_GESO: null,
    D_ROB_FW: null,
    D_Cargo_Name: null,
    D_Cargo_Qtty: null,
    D_Draft_Fore: null,
    D_Draft_Aft: null,
    D_Next_Port_Name: null,
    D_ETA: null,
    D_ETB: null,
    D_ETC: null,
    D_ETD: null,
    D_Remark: null,
    D_Spare_1: null,
    D_Spare_1_unit_name: null,
    D_Spare_2: null,
    D_Spare_2_unit_name: null,
    D_Spare_3: null,
    D_Spare_3_unit_name: null,
    D_Spare_4: null,
    D_Spare_4_unit_name: null,
    D_Spare_5: null,
    D_Spare_5_unit_name: null,
    D_Spare_6: null,
    D_Spare_6_unit_name: null,
    D_Spare_7: null,
    D_Spare_7_unit_name: null,
    D_Spare_8: null,
    D_Spare_8_unit_name: null,
    D_Spare_9: null,
    D_Spare_9_unit_name: null,
    D_Spare_10: null,
    D_Spare_10_unit_name: null,
    D_Spare_11: null,
    D_Spare_11_unit_name: null,
    D_Spare_12: null,
    D_Spare_12_unit_name: null,
    D_Spare_13: null,
    D_Spare_13_unit_name: null,
    D_Spare_14: null,
    D_Spare_14_unit_name: null,
    D_Spare_15: null,
    D_Spare_15_unit_name: null,
    D_Spare_16: null,
    D_Spare_16_unit_name: null,
    D_Spare_17: null,
    D_Spare_17_unit_name: null,
    D_Spare_18: null,
    D_Spare_18_unit_name: null,
    D_Spare_19: null,
    D_Spare_19_unit_name: null,
    D_Spare_20: null,
    D_Spare_20_unit_name: null,
  };
  const [isLoading, data, update] = useLazyInitializeState<Form>(
    initialize,
    initializes
  );

  return {
    isLoading,
    data,
    update,
  };
};

const useGetDeparture = (report_id: number) => {
  const input = { id: report_id };

  const { data: response, loading: isLoading } = useGetOwnerDepartureQuery({
    variables: { input },
  });

  const form = useMemo(() => mapToFormData(response), [response]);

  return {
    isLoading,
    form,
  };
};

const mapToFormData = (response?: GetOwnerDepartureQuery): Form | null => {
  if (!response) return null;

  const report = response.getOwnerDeparture;

  if (!report) return null;

  const D_Vessel_Name = report.D_Vessel_Name;
  const D_Voy = report?.D_Voy;
  const D_Last_Voy = report?.D_Last_Voy;
  const D_LorB = report?.D_LorB || null;

  const D_Departure_Port = report?.D_Departure_Port;
  const D_Departure_Date_Time = report?.D_Departure_Date_Time;
  const D_TimeZone_PorN = report?.D_Time_Zone.slice(0, 1);
  const D_TimeZone_HH = report?.D_Time_Zone.slice(1, 3);
  const D_TimeZone_MM = report?.D_Time_Zone.slice(4, 6);
  const D_RU_Date_Time = report?.D_RU_Date_Time;

  const D_ROB_FO = Number(report?.D_ROB_FO);
  const D_ROB_MGO = Number(report?.D_ROB_MGO);
  const D_ROB_MECO = Number(report?.D_ROB_MECO);
  const D_ROB_MESO = Number(report?.D_ROB_MESO);
  const D_ROB_GESO = Number(report?.D_ROB_GESO);
  const D_ROB_FW = Number(report?.D_ROB_FW);
  const D_Cargo_Name = report?.D_Cargo_Name;
  const D_Cargo_Qtty = Number(report?.D_Cargo_Qtty);
  const D_Draft_Fore = Number(report?.D_Draft_Fore);
  const D_Draft_Aft = Number(report?.D_Draft_Aft);
  const D_Next_Port_Name = report?.D_Next_Port_Name;
  const D_ETA = report?.D_ETA;
  const D_ETB = report?.D_ETB;
  const D_ETC = report?.D_ETC;
  const D_ETD = report?.D_ETD;

  const D_Remark = report.D_Remark || "";

  return {
    D_Vessel_Name,
    D_Voy,
    D_Last_Voy,
    D_LorB: D_LorB || LorB.Ballast,
    D_Departure_Port,
    D_EU_UK_port: report.D_EU_UK_port,
    D_Port_L_U: report.D_Port_L_U || LorU.N,
    D_UNLOC: emptyToDoubleQuotation(report.D_UNLOC),
    D_Departure_Date_Time,
    D_RU_Date_Time,
    D_TimeZone_PorN,
    D_TimeZone_HH,
    D_TimeZone_MM,
    D_ROB_FO,
    D_ROB_MGO,
    D_ROB_MECO,
    D_ROB_MESO,
    D_ROB_GESO,
    D_ROB_FW,
    D_Additional_ROB: filterArrUndefinedNull(report.D_Additional_ROB),
    D_Cargo_Name,
    D_Cargo_Qtty,
    D_Draft_Fore,
    D_Draft_Aft,
    D_Next_Port_Name,
    D_ETA,
    D_ETB,
    D_ETC,
    D_ETD,
    D_Remark,
    D_Spare_1: emptyToDoubleQuotation(report.D_Spare_1),
    D_Spare_1_unit_name: emptyToDoubleQuotation(report.D_Spare_1_unit_name),
    D_Spare_2: emptyToDoubleQuotation(report.D_Spare_2),
    D_Spare_2_unit_name: emptyToDoubleQuotation(report.D_Spare_2_unit_name),
    D_Spare_3: emptyToDoubleQuotation(report.D_Spare_3),
    D_Spare_3_unit_name: emptyToDoubleQuotation(report.D_Spare_3_unit_name),
    D_Spare_4: emptyToDoubleQuotation(report.D_Spare_4),
    D_Spare_4_unit_name: emptyToDoubleQuotation(report.D_Spare_4_unit_name),
    D_Spare_5: emptyToDoubleQuotation(report.D_Spare_5),
    D_Spare_5_unit_name: emptyToDoubleQuotation(report.D_Spare_5_unit_name),
    D_Spare_6: emptyToDoubleQuotation(report.D_Spare_6),
    D_Spare_6_unit_name: emptyToDoubleQuotation(report.D_Spare_6_unit_name),
    D_Spare_7: emptyToDoubleQuotation(report.D_Spare_7),
    D_Spare_7_unit_name: emptyToDoubleQuotation(report.D_Spare_7_unit_name),
    D_Spare_8: emptyToDoubleQuotation(report.D_Spare_8),
    D_Spare_8_unit_name: emptyToDoubleQuotation(report.D_Spare_8_unit_name),
    D_Spare_9: emptyToDoubleQuotation(report.D_Spare_9),
    D_Spare_9_unit_name: emptyToDoubleQuotation(report.D_Spare_9_unit_name),
    D_Spare_10: emptyToDoubleQuotation(report.D_Spare_10),
    D_Spare_10_unit_name: emptyToDoubleQuotation(report.D_Spare_10_unit_name),
    D_Spare_11: emptyToDoubleQuotation(report.D_Spare_11),
    D_Spare_11_unit_name: emptyToDoubleQuotation(report.D_Spare_11_unit_name),
    D_Spare_12: emptyToDoubleQuotation(report.D_Spare_12),
    D_Spare_12_unit_name: emptyToDoubleQuotation(report.D_Spare_12_unit_name),
    D_Spare_13: emptyToDoubleQuotation(report.D_Spare_13),
    D_Spare_13_unit_name: emptyToDoubleQuotation(report.D_Spare_13_unit_name),
    D_Spare_14: emptyToDoubleQuotation(report.D_Spare_14),
    D_Spare_14_unit_name: emptyToDoubleQuotation(report.D_Spare_14_unit_name),
    D_Spare_15: emptyToDoubleQuotation(report.D_Spare_15),
    D_Spare_15_unit_name: emptyToDoubleQuotation(report.D_Spare_15_unit_name),
    D_Spare_16: emptyToDoubleQuotation(report.D_Spare_16),
    D_Spare_16_unit_name: emptyToDoubleQuotation(report.D_Spare_16_unit_name),
    D_Spare_17: emptyToDoubleQuotation(report.D_Spare_17),
    D_Spare_17_unit_name: emptyToDoubleQuotation(report.D_Spare_17_unit_name),
    D_Spare_18: emptyToDoubleQuotation(report.D_Spare_18),
    D_Spare_18_unit_name: emptyToDoubleQuotation(report.D_Spare_18_unit_name),
    D_Spare_19: emptyToDoubleQuotation(report.D_Spare_19),
    D_Spare_19_unit_name: emptyToDoubleQuotation(report.D_Spare_19_unit_name),
    D_Spare_20: emptyToDoubleQuotation(report.D_Spare_20),
    D_Spare_20_unit_name: emptyToDoubleQuotation(report.D_Spare_20_unit_name),
  };
};

type Props = LocationState;

const DepartureDetail = (props: Props): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);

  const navigate = useNavigate();

  if (!report_id) navigate("/");

  const formData = useGetDeparture(report_id);

  const onBack = () => {
    navigate(pageData.ownerDeparture.path());
  };

  const form = useForm([props.form, formData.form]);

  return (
    <DefaultOwner title="Noon Report" selectedTitle={"Departure"}>
      <Template form={form.data} reportId={report_id} onBack={onBack} />
    </DefaultOwner>
  );
};

export default DepartureDetail;
