import { useState } from "react";
import { pageData } from "../../../App";

export function useOwnerAnalysisPath() {
  const [selectedShipImoNumber, setSelectedShipImoNumber] = useState<string | null>(null);

  const getOwnerAnalysisPath = () => {
    if (!selectedShipImoNumber) {
      return pageData.ownerAnalysis.path();
    }
    return `${pageData.ownerAnalysis.path()}?imo_number=${selectedShipImoNumber}`;
  };

  return {selectedShipImoNumber, setSelectedShipImoNumber, getOwnerAnalysisPath};
}