import React from "react";
import Template from "../../../../templates/Crew/Arrival/ServerUpdate/ServerUpdateArrivalComplete";
import Default from "../../../../layouts/DefaultCrew";

const ServerUpdateArrivalComplete = (): React.ReactElement => {
  return (
    <Default title="" selectedTitle={"Arrival"}>
      <Template />
    </Default>
  );
};

export default ServerUpdateArrivalComplete;
