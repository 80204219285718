import { QueryStatus } from "../../gen/graphql/types";
import {
  Auth0Error,
  IncorrectInputError,
  ServerError,
  Exception,
  FailGetUploadUrlError,
} from "../../utils/error";

export type ReportSearchCondition = {
  ship_id?: number | null;
  imo_no: string | null;
  Voy: string | null;
  From_DateTime: Date | null;
  To_DateTime: Date | null;
  pagination: Pagination;
};

export type Pagination = {
  page: number;
  limit: number;
};

export const checkQueryStatus = (queryStatus?: QueryStatus) => {
  if (queryStatus === QueryStatus.Success) {
    console.info("checkQueryStatus Success!");
    return;
  }

  if (queryStatus === QueryStatus.IncorrectInput) {
    throw IncorrectInputError;
  }

  if (queryStatus === QueryStatus.Auth0Error) {
    throw Auth0Error;
  }

  if (queryStatus === QueryStatus.FailGetUploadUrl) {
    console.error(FailGetUploadUrlError.message);
  }

  if (queryStatus === QueryStatus.ServerError) {
    throw ServerError;
  }

  throw Exception;
};
