import dayjs from "dayjs";

export function isNumeric(value?: unknown): boolean {
  return !isNaN(Number(value));
}

export const isString = (value?: unknown): value is string | undefined =>
  typeof value === "string" || value instanceof String;

export const isDate = (
  value?: string | number | Date | dayjs.Dayjs | null | undefined
): value is Date => dayjs(value).isValid();

export const isEmpty = (
  value?: string | number | null
): value is null | undefined =>
  value === null || value === undefined || value === "";

export const nvl = (value?: string | null, value2 = ""): string => {
  return value == null ? value2 : value;
};

export const convertToNumberOrNull = (value?: string | null): number | null => {
  return value === null || value === "" || value === undefined
    ? null
    : Number(value);
};

export function filterArrUndefinedNull<T>(
  value?: T[] | null
): NonNullable<T>[] {
  if (!value) return [];
  return value.filter(
    (v): v is NonNullable<typeof v> => v !== undefined && v !== null
  );
}
