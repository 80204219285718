import { calculateLeastSquareFirstCoefficient } from "../../../../utils/calculate-least-square-first-coefficient";
import { nonNullable } from "../../../../utils/formats";
import { isString } from "../../../../utils/type-checks";
import { HtmlTooltip } from "./analysisData";

export const getTrendlineValues = (maxValueX: number) => {
  const b = maxValueX / 3000;
  const values = [...Array(3000)].map((_, i) => (i + 1) * b);
  values.unshift(0);
  return values;
};

export const getReferenceDataCoefficient = (
  report: (string | number | null | HtmlTooltip)[][],
  index: number
) => {
  const referenceDataList = report
    .map((v) => {
      if (!isString(v[index])) {
        return [Number(v[0]), Number(v[index])];
      } else {
        return [0, 0];
      }
    })
    .filter((k) => !isNaN(k[1]));

  const graphCoefficient = calculateLeastSquareFirstCoefficient(
    referenceDataList,
    3
  );

  return graphCoefficient;
};

export const getMaxValue = (
  report: (string | number | null | HtmlTooltip)[][],
  index: number
): number => {
  try {
    const sortedReport = report
      .filter((v): v is Exclude<typeof v, null> => nonNullable(v))
      .filter((v) => nonNullable(v[index]))
      .filter((v) => !isString(v[index]))
      .filter((v) => !isNaN(Number(v[index])))
      .sort((a, b) => Number(a[index]) - Number(b[index]));

    if (sortedReport.length === 0) return 0;

    const maxValue = sortedReport[sortedReport.length - 1][index]
      ? sortedReport[sortedReport.length - 1][index]
      : 0;

    return maxValue && !isString(maxValue) ? Number(maxValue) : 0;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const calculateY = (
  coefficient: number,
  x: number,
  maxValueY: number
) => {
  const y = coefficient ? coefficient * x ** 3 : NaN;

  if (y > maxValueY) return NaN;

  return y;
};
