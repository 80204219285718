/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import Template from "../../../templates/Owner/Cargo/CargoDetail";
import {
  useCargoListQuery,
  CargoListQuery,
} from "../../../../gen/graphql/types";
import { pageData } from "../../../../App";
import DefaultOwner from "../../../layouts/DefaultOwner";
import { CargoForm } from "../../Crew/Cargo/type";
import {
  getLatLongValues,
  getTimeZoneValues,
} from "../../../../utils/form-util";
import { useCargoForm } from "../../Crew/Cargo/cargo.module";

const useGetCargo = (report_id: number) => {
  const input = { id: report_id };

  const { data: response, loading: isLoading } = useCargoListQuery({
    variables: { input },
  });

  const form = useMemo(() => mapToFormData(response), [response]);

  return {
    isLoading,
    form,
  };
};

const mapToFormData = (response?: CargoListQuery): CargoForm | null => {
  const cargoList = response?.cargoList?.cargoList;

  if (!cargoList) return null;

  const report = cargoList[0];

  if (!report) return null;

  const LatLong = getLatLongValues(
    report.C_Latitude + "/" + report.C_Longitude
  );
  const TimeZone = getTimeZoneValues(report.C_Timezone);
  return {
    C_TimeZone_PorN: TimeZone.TimeZone_PorN,
    C_TimeZone_HH: TimeZone.TimeZone_HH,
    C_TimeZone_MM: TimeZone.TimeZone_MM,
    C_DateTime: report.C_DateTime,
    C_Lat_Direction: LatLong.Lat_Direction,
    C_Lat_Deg: LatLong.Lat_Deg,
    C_Lat_Min: LatLong.Lat_Min,
    C_Long_Direction: LatLong.Long_Direction,
    C_Long_Deg: LatLong.Long_Deg,
    C_Long_Min: LatLong.Long_Min,
    C_Voyage_No: report.C_Voyage_No,
    C_Cargo_Port: report.C_Cargo_Port,
    C_UNLOC: report.C_UNLOC,
    C_EU_UK_port: report.C_EU_UK_port,
    C_Port_L_U: report.C_Port_L_U,
    C_Loading_Mass: report.C_Loading_Mass || undefined,
    C_Loading_TEU_Full: report.C_Loading_TEU_Full || undefined,
    C_Loading_TEU_Empty: report.C_Loading_TEU_Empty || undefined,
    C_Loading_Unit: report.C_Loading_Unit || undefined,
    C_Loading_Lane_metres: report.C_Loading_Lane_metres || undefined,
    C_Loading_Number_of_Passengers:
      report.C_Loading_Number_of_Passengers || undefined,
    C_Loading_Volume: report.C_Loading_Volume || undefined,
    C_Unloading_Mass: report.C_Unloading_Mass || undefined,
    C_Unloading_TEU_Full: report.C_Unloading_TEU_Full || undefined,
    C_Unloading_TEU_Empty: report.C_Unloading_TEU_Empty || undefined,
    C_Unloading_Unit: report.C_Unloading_Unit || undefined,
    C_Unloading_Lane_metres: report.C_Unloading_Lane_metres || undefined,
    C_Unloading_Number_of_Passengers:
      report.C_Unloading_Number_of_Passengers || undefined,
    C_Unloading_Volume: report.C_Unloading_Volume || undefined,
    C_Total_Mass: report.C_Total_Mass || undefined,
    C_Total_TEU_Full: report.C_Total_TEU_Full || undefined,
    C_Total_TEU_Empty: report.C_Total_TEU_Empty || undefined,
    C_Total_Unit: report.C_Total_Unit || undefined,
    C_Total_Lane_metres: report.C_Total_Lane_metres || undefined,
    C_Total_Number_of_Passengers:
      report.C_Total_Number_of_Passengers || undefined,
    C_Total_Volume: report.C_Total_Volume || undefined,
    C_Displacement: report.C_Displacement || undefined,
    C_Water_density: report.C_Water_density || undefined,
    C_Total_ROB: report.C_Total_ROB || undefined,
    C_Filename: report.C_Filename || "",
  };
};

const CargoDetail = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);

  const navigate = useNavigate();

  if (!report_id) navigate("/");

  const formData = useGetCargo(report_id);

  const onBack = () => {
    navigate(pageData.ownerCargo);
  };
  const form = useCargoForm([formData.form]);

  return (
    <DefaultOwner title="" selectedTitle={"Cargo"}>
      <Template form={form.data} reportId={report_id} onBack={onBack} />
    </DefaultOwner>
  );
};

export default CargoDetail;
