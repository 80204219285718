import {
  BunkeringListInput,
  BunkeringListQuery,
  useBunkeringListLazyQuery,
} from "../../gen/graphql/types";
import { useMemo } from "react";
import { Bunkering } from "../../components/pages/Crew/Bunkering/Create/types";
import { ReportSearchCondition } from "../../components/pages/pages.module";
import dayjs from "dayjs";

export const getBunkeringList = (reports?: BunkeringListQuery): Bunkering[] => {
  const bunkeringList = (reports?.bunkeringList?.bunkeringList || [])
    .map((bunkering): Bunkering | null => bunkering)
    .filter((v): v is NonNullable<typeof v> => v != null)
    .sort((a, b) =>
      dayjs(b?.B_Bunkering_Date_time).diff(dayjs(a.B_Bunkering_Date_time))
    );
  return bunkeringList;
};

export const mapBunkeringListInput = (
  searchCondition: ReportSearchCondition
): BunkeringListInput => {
  return {
    ship_id: searchCondition?.ship_id || null,
    IMO_No: searchCondition.imo_no || null,
    B_Voy: searchCondition?.Voy,
    From_DateTime: searchCondition?.From_DateTime,
    To_DateTime: searchCondition?.To_DateTime,
    pagination: searchCondition.pagination,
  };
};

export const useBunkerings = () => {
  const [getData, { data: response, loading: isLoading }] =
    useBunkeringListLazyQuery();

  const data = useMemo(() => {
    const totalPageCount = response?.bunkeringList?.totalPageCount || 0;
    const bunkeringList = getBunkeringList(response);

    return { totalPageCount, bunkeringList: bunkeringList };
  }, [response]);

  const refetch = (searchCondition: ReportSearchCondition) => {
    const inputdata = mapBunkeringListInput(searchCondition);
    getData({
      variables: {
        input: {
          ...inputdata,
          pagination: {
            page: inputdata.pagination?.page,
            limit: inputdata.pagination?.limit,
          },
        },
      },
    });
  };

  return {
    isLoading,
    data,
    refetch,
  };
};
