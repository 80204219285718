export const calculateLeastSquareFirstCoefficient = (
  coordinates: number[][],
  dimension: number
) => {
  let x = 0;
  let y = 0;
  let x_to_nth_power_times_y_sum = 0;
  let x_to_2nth_power_sum = 0;
  let first_coefficient = 0;

  //FOCのSetting入力時に、SpeedOGにNaNが入ってくる場合に除外するfilter
  const values_length = coordinates.filter((v) => !isNaN(v[0])).length;

  if (values_length === 0) {
    return 0;
  }

  for (let i = 0; i < values_length; i++) {
    x = coordinates[i][0];
    y = coordinates[i][1];
    x_to_nth_power_times_y_sum += x ** dimension * y;
    x_to_2nth_power_sum += x ** (2 * dimension);
  }

  first_coefficient =
    x_to_nth_power_times_y_sum /
    values_length /
    (x_to_2nth_power_sum / values_length);

  return first_coefficient;
};
