import { EUorUk, FuelType, LorB, LorU } from "../../../../../gen/graphql/types";

export type AdditionalROBForArrival = {
  id?: number;
  A_Fuel_Type: FuelType;
  A_Fuel_volume?: number;
};

export type Form = {
  A_Vessel_Name?: string | null | undefined;
  A_Voy?: string | null | undefined;
  A_LorB: LorB | null;
  A_Arrival_Port: string | null;
  A_EU_UK_port?: EUorUk | null;
  A_Port_L_U: LorU;
  A_UNLOC: string | null;
  A_Arrival_Date_Time: Date | null;
  A_SB_or_Stop: Date | null;
  A_TimeZone_PorN?: string | null | undefined;
  A_TimeZone_HH?: string | null | undefined;
  A_TimeZone_MM?: string | null | undefined;
  A_ETB: Date | null;
  A_ETC: Date | null;
  A_ETD: Date | null;
  A_Berthed: Date | null;
  A_ROB_at_Arrival_FO: number | null;
  A_ROB_at_Arrival_MGO: number | null;
  A_ROB_at_Arrival_MECO: number | null;
  A_ROB_at_Arrival_MESO: number | null;
  A_ROB_at_Arrival_GESO: number | null;
  A_ROB_at_Arrival_FW: number | null;
  A_Hrs_since_Last_Noon: number | null;
  A_Distance_by_OG: number | null;
  A_FO_Consumption_per_day_ME: number | null;
  A_FO_Consumption_per_day_GE: number | null;
  A_FO_Consumption_per_day_B_and_Inc: number | null;
  A_MGO_Consumption_per_day_ME: number | null;
  A_MGO_Consumption_per_day_GE: number | null;
  A_MGO_Consumption_per_day_B_and_Inc: number | null;
  A_ROB_FO: number | null;
  A_ROB_MGO: number | null;
  A_ROB_MECO: number | null;
  A_ROB_MESO: number | null;
  A_ROB_GESO: number | null;
  A_ROB_FW: number | null;
  A_Additional_ROB?: AdditionalROBForArrival[];
  A_Draft_Fore: number | null;
  A_Draft_Aft: number | null;
  A_FO_CO2_emission: number | null;
  A_MGO_CO2_emission: number | null;
  A_Remark?: string | null | undefined;
  A_Spare_1?: string | null | undefined;
  A_Spare_1_unit_name?: string | null | undefined;
  A_Spare_2?: string | null | undefined;
  A_Spare_2_unit_name?: string | null | undefined;
  A_Spare_3?: string | null | undefined;
  A_Spare_3_unit_name?: string | null | undefined;
  A_Spare_4?: string | null | undefined;
  A_Spare_4_unit_name?: string | null | undefined;
  A_Spare_5?: string | null | undefined;
  A_Spare_5_unit_name?: string | null | undefined;
  A_Spare_6?: string | null | undefined;
  A_Spare_6_unit_name?: string | null | undefined;
  A_Spare_7?: string | null | undefined;
  A_Spare_7_unit_name?: string | null | undefined;
  A_Spare_8?: string | null | undefined;
  A_Spare_8_unit_name?: string | null | undefined;
  A_Spare_9?: string | null | undefined;
  A_Spare_9_unit_name?: string | null | undefined;
  A_Spare_10?: string | null | undefined;
  A_Spare_10_unit_name?: string | null | undefined;
  A_Spare_11?: string | null | undefined;
  A_Spare_11_unit_name?: string | null | undefined;
  A_Spare_12?: string | null | undefined;
  A_Spare_12_unit_name?: string | null | undefined;
  A_Spare_13?: string | null | undefined;
  A_Spare_13_unit_name?: string | null | undefined;
  A_Spare_14?: string | null | undefined;
  A_Spare_14_unit_name?: string | null | undefined;
  A_Spare_15?: string | null | undefined;
  A_Spare_15_unit_name?: string | null | undefined;
  A_Spare_16?: string | null | undefined;
  A_Spare_16_unit_name?: string | null | undefined;
  A_Spare_17?: string | null | undefined;
  A_Spare_17_unit_name?: string | null | undefined;
  A_Spare_18?: string | null | undefined;
  A_Spare_18_unit_name?: string | null | undefined;
  A_Spare_19?: string | null | undefined;
  A_Spare_19_unit_name?: string | null | undefined;
  A_Spare_20?: string | null | undefined;
  A_Spare_20_unit_name?: string | null | undefined;
};

export type Arrival = {
  id: number;
  A_Vessel_Name: string;
  A_Voy: string;
  A_LorB: LorB;
  A_Arrival_Port: string;
  A_EU_UK_port?: EUorUk | null;
  A_Port_L_U?: LorU | null;
  A_UNLOC?: string | null;
  A_Arrival_Date_Time: Date;
  A_SB_or_Stop: Date;
  A_Time_Zone: string;
  A_ETB: Date;
  A_ETC: Date;
  A_ETD: Date;
  A_Berthed: Date;
  A_ROB_at_Arrival_FO: number;
  A_ROB_at_Arrival_MGO: number;
  A_ROB_at_Arrival_MECO: number;
  A_ROB_at_Arrival_MESO: number;
  A_ROB_at_Arrival_GESO: number;
  A_ROB_at_Arrival_FW: number;
  A_Hrs_since_Last_Noon: number;
  A_Distance_by_OG: number;
  A_FO_Consumption_per_day_ME: number;
  A_FO_Consumption_per_day_GE: number;
  A_FO_Consumption_per_day_B_and_Inc: number;
  A_MGO_Consumption_per_day_ME: number;
  A_MGO_Consumption_per_day_GE: number;
  A_MGO_Consumption_per_day_B_and_Inc: number;
  A_ROB_FO: number;
  A_ROB_MGO: number;
  A_ROB_MECO: number;
  A_ROB_MESO: number;
  A_ROB_GESO: number;
  A_ROB_FW: number;
  A_Draft_Fore: number;
  A_Draft_Aft: number;
  A_FO_CO2_emission: number;
  A_MGO_CO2_emission: number;
  A_Remark?: string | null | undefined;
  A_Spare_1?: string | null | undefined;
  A_Spare_1_unit_name?: string | null | undefined;
  A_Spare_2?: string | null | undefined;
  A_Spare_2_unit_name?: string | null | undefined;
  A_Spare_3?: string | null | undefined;
  A_Spare_3_unit_name?: string | null | undefined;
  A_Spare_4?: string | null | undefined;
  A_Spare_4_unit_name?: string | null | undefined;
  A_Spare_5?: string | null | undefined;
  A_Spare_5_unit_name?: string | null | undefined;
  A_Spare_6?: string | null | undefined;
  A_Spare_6_unit_name?: string | null | undefined;
  A_Spare_7?: string | null | undefined;
  A_Spare_7_unit_name?: string | null | undefined;
  A_Spare_8?: string | null | undefined;
  A_Spare_8_unit_name?: string | null | undefined;
  A_Spare_9?: string | null | undefined;
  A_Spare_9_unit_name?: string | null | undefined;
  A_Spare_10?: string | null | undefined;
  A_Spare_10_unit_name?: string | null | undefined;
  A_Spare_11?: string | null | undefined;
  A_Spare_11_unit_name?: string | null | undefined;
  A_Spare_12?: string | null | undefined;
  A_Spare_12_unit_name?: string | null | undefined;
  A_Spare_13?: string | null | undefined;
  A_Spare_13_unit_name?: string | null | undefined;
  A_Spare_14?: string | null | undefined;
  A_Spare_14_unit_name?: string | null | undefined;
  A_Spare_15?: string | null | undefined;
  A_Spare_15_unit_name?: string | null | undefined;
  A_Spare_16?: string | null | undefined;
  A_Spare_16_unit_name?: string | null | undefined;
  A_Spare_17?: string | null | undefined;
  A_Spare_17_unit_name?: string | null | undefined;
  A_Spare_18?: string | null | undefined;
  A_Spare_18_unit_name?: string | null | undefined;
  A_Spare_19?: string | null | undefined;
  A_Spare_19_unit_name?: string | null | undefined;
  A_Spare_20?: string | null | undefined;
  A_Spare_20_unit_name?: string | null | undefined;
};

export type Result = {
  succeed: boolean;
  code?: string | null | undefined;
};

export type LocationState = {
  form?: Form;
  result?: Result;
};

export type Props = {
  form: Form;
};

export const initializeArrival = {
  ship_id: null,
  user_id: null,
  A_Vessel_Name: null,
  A_Voy: null,
  A_LorB: LorB.Ballast,
  A_Arrival_Port: null,
  A_EU_UK_port: null,
  A_Port_L_U: LorU.N,
  A_UNLOC: null,
  A_Arrival_Date_Time: null,
  A_SB_or_Stop: null,
  A_TimeZone_PorN: "+",
  A_TimeZone_HH: "00",
  A_TimeZone_MM: "00",
  A_Time_Zone: null,
  A_ETB: null,
  A_ETC: null,
  A_ETD: null,
  A_Berthed: null,
  A_ROB_at_Arrival_FO: null,
  A_ROB_at_Arrival_MGO: null,
  A_ROB_at_Arrival_MECO: null,
  A_ROB_at_Arrival_MESO: null,
  A_ROB_at_Arrival_GESO: null,
  A_ROB_at_Arrival_FW: null,
  A_Hrs_since_Last_Noon: null,
  A_Distance_by_OG: null,
  A_FO_Consumption_per_day_ME: null,
  A_FO_Consumption_per_day_GE: null,
  A_FO_Consumption_per_day_B_and_Inc: null,
  A_MGO_Consumption_per_day_ME: null,
  A_MGO_Consumption_per_day_GE: null,
  A_MGO_Consumption_per_day_B_and_Inc: null,
  A_ROB_FO: null,
  A_ROB_MGO: null,
  A_ROB_MECO: null,
  A_ROB_MESO: null,
  A_ROB_GESO: null,
  A_ROB_FW: null,
  A_Draft_Fore: null,
  A_Draft_Aft: null,
  A_FO_CO2_emission: null,
  A_MGO_CO2_emission: null,
  A_Remark: null,
  A_Spare_1: null,
  A_Spare_1_unit_name: null,
  A_Spare_2: null,
  A_Spare_2_unit_name: null,
  A_Spare_3: null,
  A_Spare_3_unit_name: null,
  A_Spare_4: null,
  A_Spare_4_unit_name: null,
  A_Spare_5: null,
  A_Spare_5_unit_name: null,
  A_Spare_6: null,
  A_Spare_6_unit_name: null,
  A_Spare_7: null,
  A_Spare_7_unit_name: null,
  A_Spare_8: null,
  A_Spare_8_unit_name: null,
  A_Spare_9: null,
  A_Spare_9_unit_name: null,
  A_Spare_10: null,
  A_Spare_10_unit_name: null,
  A_Spare_11: null,
  A_Spare_11_unit_name: null,
  A_Spare_12: null,
  A_Spare_12_unit_name: null,
  A_Spare_13: null,
  A_Spare_13_unit_name: null,
  A_Spare_14: null,
  A_Spare_14_unit_name: null,
  A_Spare_15: null,
  A_Spare_15_unit_name: null,
  A_Spare_16: null,
  A_Spare_16_unit_name: null,
  A_Spare_17: null,
  A_Spare_17_unit_name: null,
  A_Spare_18: null,
  A_Spare_18_unit_name: null,
  A_Spare_19: null,
  A_Spare_19_unit_name: null,
  A_Spare_20: null,
  A_Spare_20_unit_name: null,
};
