import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { useController } from "react-hook-form";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { RhfTextField } from "./RhfTextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { TextFieldProps } from "./TextField";
import { isDate } from "../../utils/type-checks";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Etc/GMT");

export type RhfDatePickerProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T>;

export const RhfDatePicker = <T extends FieldValues>(
  props: RhfDatePickerProps<T>
) => {
  const { name, control, sx } = props;
  const {
    field: { onChange, value },
  } = useController<T>({ name, control });

  const onSelectDate = (e: Date | null) => {
    onChange(e);
  };

  const onChangeText = (value: string) => {
    if (isDate(value)) onChange(dayjs(value).toDate());
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      dateLibInstance={dayjs.utc}
    >
      <DesktopDateTimePicker
        inputFormat="YYYY/MM/DD H:mm"
        ampm={false}
        value={value || null}
        onChange={(e: Date | null) => onSelectDate(e)}
        renderInput={(params) => (
          <RhfTextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: "YYYY/MM/DD H:mm",
            }}
            // error={""}
            onChange={(e) => {
              // 数値以外を弾く
              if (!/^\d*$/.test(e.target.value)) return;
              onChangeText(e.target.value);
            }}
            defaultValue={undefined}
            name={name}
            control={control}
            sx={{ ...sx }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
