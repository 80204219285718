import React from "react";
import CompleteTemplates from "../../../../templates/Crew/Cargo/Complete";
import Default from "../../../../layouts/DefaultCrew";

const Complete = (): React.ReactElement => {
  return (
    <Default title="" selectedTitle={"Cargo"}>
      <CompleteTemplates action={"Create"} />
    </Default>
  );
};

export default Complete;
