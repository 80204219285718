import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Template from "../../../templates/Crew/Bunkering/ServerUpdate";
import { Form } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import {
  useGetBunkeringQuery,
  GetBunkeringQuery,
  useUpdateBunkeringMutation,
} from "../../../../gen/graphql/types";
import { pageData, useContextIDs } from "../../../../App";
import Loading from "../../../atoms/Loading";
import {
  mapToUpdateBunkeringMutationVariables,
  useBunkeringForm,
} from "./bunkering.module";
import { getLatLongValues } from "../../../../utils/form-util";
import { zeroLatLong, zeroTimeZone } from "../../../../utils/const";
import { setDefaultNullGgraphQLInputToForm } from "../../../../utils/graphql-mappimg";
import ServerUpdateBunkeringConfirm from "../../../templates/Crew/Bunkering/ServerUpdate/ServerUpdateBunkeringConfirm";

import { uploadFile } from "../../../../utils/uploadFile.module";
import useErrorMessageList from "../../../../hooks/errorMessage.hook";

const useGetBunkering = (report_id: number, ship_id: number) => {
  const input = { id: report_id, ship_id };
  const { data: response, loading: isLoading } = useGetBunkeringQuery({
    variables: { input },
    fetchPolicy: "network-only",
  });
  const defaultTimeZone = response?.getBunkering?.B_Timezone || zeroTimeZone;
  const defaultLatLong = response?.getBunkering?.B_LatLong || zeroLatLong;
  const mapToFormBunkeringData = (
    response?: GetBunkeringQuery
  ): Form | null => {
    if (!response) return null;
    const report = response.getBunkering;

    if (!report) return null;

    const {
      Lat_Deg,
      Lat_Min,
      Lat_Direction,
      Long_Deg,
      Long_Min,
      Long_Direction,
    } = getLatLongValues(report.B_LatLong);

    return {
      B_Vessel_Name: report.B_Vessel_Name,
      B_Voy: report.B_Voy,
      B_TimeZone_PorN: String(report?.B_Timezone).slice(0, 1),
      B_TimeZone_HH: String(report?.B_Timezone).slice(1, 3),
      B_TimeZone_MM: String(report?.B_Timezone).slice(4, 6),
      B_Bunkering_Date_time: report.B_Bunkering_Date_time,
      B_Lat_Direction: Lat_Direction,
      B_Lat_Deg: Lat_Deg,
      B_Lat_Min: Lat_Min,
      B_Long_Direction: Long_Direction,
      B_Long_Deg: Long_Deg,
      B_Long_Min: Long_Min,
      B_PortName: report.B_PortName,
      B_UNLOC: report.B_UNLOC,
      B_Port_LorU: report.B_Port_LorU,
      B_EUorUK: report.B_EUorUK || null,
      B_Fuel_name_1: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_1),
      B_Mass_1: setDefaultNullGgraphQLInputToForm(report.B_Mass_1),
      B_Volume_1: setDefaultNullGgraphQLInputToForm(report.B_Volume_1),
      B_Density_1: setDefaultNullGgraphQLInputToForm(report.B_Density_1),
      B_Fuel_name_2: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_2),
      B_Mass_2: setDefaultNullGgraphQLInputToForm(report.B_Mass_2),
      B_Volume_2: setDefaultNullGgraphQLInputToForm(report.B_Volume_2),
      B_Density_2: setDefaultNullGgraphQLInputToForm(report.B_Density_2),
      B_Fuel_name_3: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_3),
      B_Mass_3: setDefaultNullGgraphQLInputToForm(report.B_Mass_3),
      B_Volume_3: setDefaultNullGgraphQLInputToForm(report.B_Volume_3),
      B_Density_3: setDefaultNullGgraphQLInputToForm(report.B_Density_3),
      B_Fuel_name_4: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_4),
      B_Mass_4: setDefaultNullGgraphQLInputToForm(report.B_Mass_4),
      B_Volume_4: setDefaultNullGgraphQLInputToForm(report.B_Volume_4),
      B_Density_4: setDefaultNullGgraphQLInputToForm(report.B_Density_4),
      B_Fuel_name_5: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_5),
      B_Mass_5: setDefaultNullGgraphQLInputToForm(report.B_Mass_5),
      B_Volume_5: setDefaultNullGgraphQLInputToForm(report.B_Volume_5),
      B_Density_5: setDefaultNullGgraphQLInputToForm(report.B_Density_5),
      B_Fuel_name_6: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_6),
      B_Mass_6: setDefaultNullGgraphQLInputToForm(report.B_Mass_6),
      B_Volume_6: setDefaultNullGgraphQLInputToForm(report.B_Volume_6),
      B_Density_6: setDefaultNullGgraphQLInputToForm(report.B_Density_6),
      B_Fuel_name_7: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_7),
      B_Mass_7: setDefaultNullGgraphQLInputToForm(report.B_Mass_7),
      B_Volume_7: setDefaultNullGgraphQLInputToForm(report.B_Volume_7),
      B_Density_7: setDefaultNullGgraphQLInputToForm(report.B_Density_7),
      B_Fuel_name_8: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_8),
      B_Mass_8: setDefaultNullGgraphQLInputToForm(report.B_Mass_8),
      B_Volume_8: setDefaultNullGgraphQLInputToForm(report.B_Volume_8),
      B_Density_8: setDefaultNullGgraphQLInputToForm(report.B_Density_8),
      B_Fuel_name_9: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_9),
      B_Mass_9: setDefaultNullGgraphQLInputToForm(report.B_Mass_9),
      B_Volume_9: setDefaultNullGgraphQLInputToForm(report.B_Volume_9),
      B_Density_9: setDefaultNullGgraphQLInputToForm(report.B_Density_9),
      B_Fuel_name_10: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_10),
      B_Mass_10: setDefaultNullGgraphQLInputToForm(report.B_Mass_10),
      B_Volume_10: setDefaultNullGgraphQLInputToForm(report.B_Volume_10),
      B_Density_10: setDefaultNullGgraphQLInputToForm(report.B_Density_10),
      B_Filename: report.B_Filename || undefined,
    };
  };
  const form = useMemo(() => mapToFormBunkeringData(response), [response]);

  return {
    isLoading,
    form,
    defaultTimeZone,
    defaultLatLong,
  };
};

const ServerUpdate = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const { ship_id } = useContextIDs();
  const navigate = useNavigate();
  const ship = useShip();
  const [isConfirm, setIsConfirm] = useState(false);
  const location = useLocation();

  const [isDetail, setIsDetail] = useState(false);

  const [bunkeringFile, setFile] = useState<File | undefined>(undefined);

  const [updateBunkering, { loading: isUpdateLoading }] =
    useUpdateBunkeringMutation();

  const { errorMessageList, addErrorMessage } = useErrorMessageList();

  if (!report_id) navigate("/");

  const formData = useGetBunkering(report_id, ship_id);

  const form = useBunkeringForm([location.state?.form, formData.form]);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const onUpdate = async () => {
    try {
      const variables = mapToUpdateBunkeringMutationVariables(
        form.data,
        ship_id,
        report_id
      );

      updateBunkering({
        fetchPolicy: "network-only",
        variables: { input: variables },
        onCompleted: async (data) => {
          try {
            if (data.updateBunkering?.isFileUpload && bunkeringFile) {
              const file = await uploadFile({
                remoteFilePath: data.updateBunkering.gcsFilePath,
                fileContent: bunkeringFile,
              });
              if (!file.sucsess) {
                //ファイルアップロードに失敗した場合は、DBのファイル名を削除する。
                form.data.B_Filename = undefined;
                await updateBunkering({
                  fetchPolicy: "network-only",
                  variables: { input: { ...variables, B_Filename: null } },
                  onError: (error) => {
                    console.error(error);
                    addErrorMessage({
                      input: JSON.stringify(variables),
                      errorMessage: `Failed to delete file name. ${error}`,
                    });
                  },
                });
                throw `File upload error. Data update succeeded. ${JSON.stringify(
                  file
                )}`;
              }
            }
            navigate(pageData.crewCreateReportComplete.path());
          } catch (error) {
            console.error(error);
            addErrorMessage({
              input: JSON.stringify(variables),
              errorMessage: `Server Update error! ${error}`,
            });
          }
        },
        onError: (error) => {
          console.error(error);
          addErrorMessage({
            input: JSON.stringify(variables),
            errorMessage: `Server Update error! ${error}`,
          });
        },
      });
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const onConfirm = () => {
    form.hasErrorScrollUp();
    setIsConfirm(true);
    if (form.hasError) return;
    setIsDetail(true);
  };

  const onBack = () => {
    setIsDetail(false);
  };

  if (formData.isLoading || form.isLoading) {
    return (
      <Default title="Bunkering" selectedTitle={"Bunkering"}>
        <Loading isLoading={formData.isLoading || form.isLoading} />
      </Default>
    );
  }

  return (
    <Default title="Bunkering" selectedTitle={"Bunkering"}>
      <Loading isLoading={isUpdateLoading} />
      {isDetail ? (
        <ServerUpdateBunkeringConfirm
          form={form.data}
          isLoading={false}
          onUpdate={onUpdate}
          reportId={report_id}
          setIsDetail={setIsDetail}
          onBack={onBack}
          errorMessageList={errorMessageList}
        />
      ) : !isUpdateLoading && !form.isLoading ? (
        <Template
          defaultTimeZone={formData.defaultTimeZone}
          defaultLatLong={formData.defaultLatLong}
          form={form.data}
          reportId={reportId || ""}
          hasFormError={form.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          ship={ship}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
          setFile={setFile}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Default>
  );
};

export default ServerUpdate;
