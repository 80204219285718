import React, { useState } from "react";
import Box from "@mui/material/Box";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { Link, Typography } from "@mui/material";
import Steppr from "../../../atoms/Stepper";
import CommonText from "../../../atoms/CommonText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomTableCell from "../../../atoms/CustomTableCell";
import GrayButton from "../../../atoms/GrayButton";
import { useNavigate, useParams } from "react-router";
import DeleteModal from "../../../atoms/DeleteModal";
import {
  TableDataRow,
  Table3DataRow,
  TableAnyDataRow,
} from "../../../molecules/Crew/Detail/Detail";
import { formatToUtcYYYYMMDDHHMM } from "../../../../utils/formats";
import { pageData } from "../../../../App";
import { CargoForm } from "../../../pages/Crew/Cargo/type";
import {
  getFileName,
  getLatLong,
  getTimeZone,
} from "../../../../utils/form-util";
import { lightGray, white } from "../../../../utils/color";
import { downLoadDexieCargofile } from "./downLoadCargofile";
import { isNumeric } from "../../../../utils/type-checks";
import { useDownloadCargoFile } from "../../Owner/Cargo/downloadFile.hook";

type FormBlockProps = {
  pageTitle: string;
  isPreview: boolean;
  deleteReport?(): void;
  isSend: boolean;
  form: CargoForm;
  isServer?: boolean;
};

const CargoDetail = (props: FormBlockProps): React.ReactElement => {
  const navigate = useNavigate();
  const { reportId } = useParams<"reportId">();
  const report_id = isNumeric(reportId) ? Number(reportId) : 0;

  const [modalOpen, setIsModalOpen] = useState(false);
  const downloadCargoFile = useDownloadCargoFile(report_id);

  const onClickDelete = () => {
    if (props.deleteReport) {
      props.deleteReport();
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <Box sx={{ pt: 0.5, mx: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "top",
            justifycontent: "right",
            pb: 1,
          }}
        >
          <Column>
            <Typography
              component="h3"
              variant="subtitle1"
              align="center"
              sx={{
                lineHeight: 1,
                fontWeight: "bold",
                color: "#fff",
                fontSize: "1.5rem",
                verticalAlign: "super",
              }}
            >
              {props.pageTitle}
            </Typography>
          </Column>

          {props.isPreview || (
            <Column sx={{ width: "60%" }}>
              <Steppr step={1} />
            </Column>
          )}
          {props.isPreview && !props.isSend && (
            <Box
              sx={{
                display: "flex",
                marginLeft: "auto",
                mb: -2,
              }}
            >
              <Column>
                <GrayButton
                  ButtonName={"Edit"}
                  disabled={false}
                  onClick={() =>
                    reportId
                      ? navigate(pageData.crewCargoUpdate.path(reportId))
                      : console.error("Invalid Report ID")
                  }
                />
              </Column>
              <Column>
                <GrayButton
                  ButtonName={"Delete"}
                  disabled={false}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                />
              </Column>
            </Box>
          )}
        </Columns>
      </Box>
      <CommonText
        sx={{ color: "#FFFFFF", pl: 3, margin: "left", textAlign: "left" }}
      >
        {props.isPreview || `Confirm input data & click “Save” button.`}
      </CommonText>

      <Box sx={{ px: 2 }}>
        <Table
          color="inherit"
          sx={{ minWidth: 600, mx: 0, color: "#FFFFFF" }}
          aria-label="spanning table"
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "#FFFFFF", height: "20px" }}>
              <CustomTableCell align="center" colSpan={3}></CustomTableCell>
              <CustomTableCell />
              <CustomTableCell sx={{ color: "#AAAAAA", fontSize: "12px" }}>
                {!props.isPreview && (
                  <span
                    style={{
                      color: "#AAAAAA",
                      paddingBottom: "-16px",
                      marginBottom: "-16px",
                    }}
                  >
                    Previous Data
                  </span>
                )}
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableDataRow title={"Voyage number"} data={props.form.C_Voyage_No} />
            <TableDataRow
              title={"Date & Time (Local)"}
              data={formatToUtcYYYYMMDDHHMM(props.form.C_DateTime)}
            />
            <TableDataRow
              title={"Time Zone (UTC±)"}
              data={getTimeZone(
                props.form.C_TimeZone_PorN,
                props.form.C_TimeZone_HH,
                props.form.C_TimeZone_MM
              )}
            />

            <TableDataRow
              title={"Lat./ Lon."}
              data={getLatLong(
                props.form.C_Lat_Deg?.toString(),
                props.form.C_Lat_Min,
                props.form.C_Lat_Direction,
                props.form.C_Long_Deg,
                props.form.C_Long_Min,
                props.form.C_Long_Direction
              )}
            />
            <TableDataRow title={"Port name"} data={props.form.C_Cargo_Port} />
            <TableDataRow title={"UNLOC"} data={props.form.C_UNLOC} />
            <TableDataRow title={"EU/UK"} data={props.form.C_EU_UK_port} />
            <TableDataRow title={"L or U"} data={props.form.C_Port_L_U} />

            <TableAnyDataRow
              topTitle={"Cargo Loading"}
              title={[
                "Mass",
                "TEU/Full",
                "TEU/Empty",
                "Unit",
                "Lane metres",
                "Number of Passengers",
                "Volume",
              ]}
              data={[
                props.form.C_Loading_Mass,
                props.form.C_Loading_TEU_Full,
                props.form.C_Loading_TEU_Empty,
                props.form.C_Loading_Unit,
                props.form.C_Loading_Lane_metres,
                props.form.C_Loading_Number_of_Passengers,
                props.form.C_Loading_Volume,
              ]}
              unitName={["mt","","","","metre","","m³"]}
            />

            <TableAnyDataRow
              topTitle={"Cargo Unloading"}
              title={[
                "Mass",
                "TEU/Full",
                "TEU/Empty",
                "Unit",
                "Lane metres",
                "Number of Passengers",
                "Volume",
              ]}
              data={[
                props.form.C_Unloading_Mass,
                props.form.C_Unloading_TEU_Full,
                props.form.C_Unloading_TEU_Empty,
                props.form.C_Unloading_Unit,
                props.form.C_Unloading_Lane_metres,
                props.form.C_Unloading_Number_of_Passengers,
                props.form.C_Unloading_Volume,
              ]}
              unitName={["mt","","","","metre","","m³"]}
            />
            <TableAnyDataRow
              topTitle={"Cargo Total"}
              title={[
                "Mass",
                "TEU/Full",
                "TEU/Empty",
                "Unit",
                "Lane metres",
                "Number of Passengers",
                "Volume",
              ]}
              data={[
                props.form.C_Total_Mass,
                props.form.C_Total_TEU_Full,
                props.form.C_Total_TEU_Empty,
                props.form.C_Total_Unit,
                props.form.C_Total_Lane_metres,
                props.form.C_Total_Number_of_Passengers,
                props.form.C_Total_Volume,
              ]}
              unitName={["mt","","","","metre","","m³"]}
            />
            <Table3DataRow
              topTitle={"Deadweight measurement"}
              title1={"Displacement"}
              data1={props.form.C_Displacement}
              unitName={"mt"}
              title2={"Water density"}
              data2={props.form.C_Water_density}
              title3={`Total ROB`}
              data3={props.form.C_Total_ROB}
            />

            <TableRow>
              <CustomTableCell sx={{ color: lightGray, width: "250px" }}>
                {"FileName"}
              </CustomTableCell>
              <CustomTableCell />
              <CustomTableCell
                colSpan={2}
                sx={{
                  color: white,
                  wordBreak: "break-word",
                  whiteSpace: "pre-line",
                }}
              >
                {props.form.C_Filename && isNumeric(reportId) ? (
                  <Link
                    onClick={() => {
                      if (props.isServer) {
                        downloadCargoFile.onDownload();
                      } else if (!props.isServer) {
                        downLoadDexieCargofile(
                          Number(reportId),
                          getFileName(props.form.C_Filename)
                        );
                      }
                    }}
                  >
                    {getFileName(props.form.C_Filename)}
                  </Link>
                ) : props.form.C_Filename ? (
                  getFileName(props.form.C_Filename)
                ) : (
                  "-"
                )}
              </CustomTableCell>
              <CustomTableCell sx={{ color: lightGray }}></CustomTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <DeleteModal
        isOpen={modalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteFrontData={onClickDelete}
        id={Number(reportId)}
        voy={props.form.C_Voyage_No ? props.form.C_Voyage_No : ""}
        DateTime={formatToUtcYYYYMMDDHHMM(props.form.C_DateTime)}
      />
    </Box>
  );
};

export default CargoDetail;
