export const zeroTimeZone = "+00:00";
export const zeroLatLong = "0-0N/0-0E";
export const queryParamImoNumber = "imo_number";
export const LatLonMinutesMinValue = 0;
export const LatLonMinutesMaxValue = 60;
export const LatitudeDegMinValue = 0;
export const LatitudeDegMaxValue = 90;
export const LongitudeDegMinValue = 0;
export const LongitudeDegMaxValue = 180;
export const BF_SCALE = [...Array(13).keys()].map((v) => String(v));
export const NoonReportDuplicateError = "A report with the same 'Voyage number' and 'Date&Time' already exists. Duplicate registrations are not allowed. Please check 'Voyage number' and 'Date&Time'.";
export const NoonReporSpeedOverTheGroundError = "Speed over the ground must be a maximum of 40. Please reconfirm the values for 'Hrs. Propelling' and 'Distance travelled over ground'."
export const NoonReportDistanceOverGroundError = "Distance over ground must be greater than or equal to 0 and less than or equal to 1000."
