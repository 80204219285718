import { db } from "../../../../dexie/db";
import { downloadDexieFile } from "../../../../utils/downloadDexieFile";

export const downLoadDexieCargofile = async (id: number, fileName: string) => {
  const data = await db.cargo.get(id);
  if (!data?.cargoFile) {
    alert("File does not exist.");
    return;
  }
  downloadDexieFile(fileName, data.cargoFile);
};
