import * as React from "react";
import Paper from "@mui/material/Paper";
import {
  Box,
  MenuItem,
  Modal,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Form, Props, Ship } from "../../../pages/Crew/SelectShips/type";
import { FC } from "react";
import SearchSelect from "../../../atoms/SearchSelect";
import Loading from "../../../atoms/Loading";
import SucsessButton from "../../../atoms/SucsessButton";
import LightGrayButton from "../../../atoms/LightGrayButton";
import {
  CrewBgColor,
  brightRed,
  white,
  CrewModalBgColor,
} from "../../../../utils/color";

type ConfirmProps = Props & {
  hasFormError: boolean;
  onUpdateForm(value: Form): void;
  onSubmit(ship: Ship): void;
  data: Ship[];
  isLoading: boolean;
  value: string | number | null;
  refetch: () => void;
};

type ShipSelectProps = ConfirmProps & {
  disabled?: boolean;
  required?: boolean;
  value: string | number | null;
  onChange(event: SelectChangeEvent<string>): void;
};

const ShipSelect = (props: ShipSelectProps): React.ReactElement => {
  return (
    <>
      <Loading inner isLoading={props.isLoading} zIndex={1} />

      <SearchSelect
        disabled={props.disabled || false}
        required={props.required || false}
        id="Vessel-Name"
        label="Vessel Name"
        labelId="Vessel-Name"
        value={props.value || ""}
        onChange={props.onChange}
        minWidth={400}
      >
        <MenuItem value="">
          <em>---</em>
        </MenuItem>
        {props.data.map((ship) => {
          return (
            <MenuItem key={ship.id} value={ship.id}>
              {ship.name}
            </MenuItem>
          );
        })}
      </SearchSelect>
    </>
  );
};

const SelectShips: FC<ConfirmProps> = (props: ConfirmProps) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onClickConfirm = () => {
    handleOpen();
  };

  const style = {
    color: white,
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 630,
    bgcolor: CrewModalBgColor,
    boxShadow: 24,
    p: 4,
  };

  const onChange = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    const form = {
      ...props.form,
      [key]: value,
    };

    props.onUpdateForm?.(form);
  };

  const selectShip = props.data.find((v) => {
    if (v.id === props.value) {
      return v.name;
    }
    return null;
  });

  const onSubmit = () => {
    if (!selectShip) return;
    props.onSubmit(selectShip);
  };

  return (
    <React.Fragment>
      <Paper
        sx={{
          height: "100vh",
          width: "100vw",
          backgroundColor: CrewBgColor,
          borderRadius: "0px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "35%",
            marginLeft: "-150px",
            margiTop: "-100px",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography sx={{ color: white, mb: "35px" }}>
            Please select your Vessel Name.
          </Typography>
          <ShipSelect
            form={props.form}
            hasFormError={props.hasFormError}
            onUpdateForm={props.onUpdateForm}
            data={props.data}
            isLoading={props.isLoading}
            refetch={props.refetch}
            value={props.value}
            onChange={(e) => onChange("ship_id", e.target.value)}
            onSubmit={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          {selectShip && (
            <SucsessButton
              sx={{ textAlign: "center", margin: "auto", mt: 4 }}
              ButtonName={"Confirm"}
              disabled={props.hasFormError}
              onClick={onClickConfirm}
            />
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Your Vessel Name is
              </Typography>
              <Typography sx={{ fontSize: "30px", py: 3 }}>
                {selectShip?.name}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                If correct, click “submit” button.
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, color: brightRed }}
              >
                Attention!
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ color: brightRed, pb: 10 }}
              >
                If you select wrong vessel name, you’ll have to reinstall this
                application
              </Typography>
              <SucsessButton
                sx={{ textAlign: "center", margin: "auto", mt: 4 }}
                ButtonName={"Submit"}
                disabled={props.hasFormError}
                onClick={onSubmit}
              />
              <LightGrayButton
                ButtonName={"Reselect"}
                sx={{ mt: 4, backgroundColor: "#9FA6B0" }}
                disabled={props.hasFormError}
                onClick={handleClose}
              />
            </Box>
          </Modal>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default SelectShips;
