import React from "react";
import Box from "@mui/material/Box";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { Form, Props } from "../../../pages/Crew/Arrival/Create/types";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import CommonNumberFIeld from "../../../atoms/CommonNumberFIeld";
import { ShipDexie } from "../../../../dexie/Ship";
import { EUorUk, LorB, LorU } from "../../../../gen/graphql/types";
import Steppr from "../../../atoms/Stepper";
import RadioButtun from "../../../atoms/RadioButton";
import LastReportText from "../../../atoms/LastReportText";
import { ReportDexie } from "../../../../dexie/Report";
import CustomTooltip from "../../../atoms/CustomTooltip";
import UtcDateTimePicker from "../../../atoms/UtcDateTimePicker";
import FormBlock_Spare from "./FormBlock_Spare";
import CommonSelect from "../../../atoms/CommonSelect";
import RobAdditionalForArrival from "./ROB/RobAdditionalForArrival";
import { convertEmptyStringToNull } from "../../../../utils/formats";

type FormBlockProps = Props & {
  title: string;
  isPreview: boolean;
  onUpdateForm?: (value: Form) => void;
  ship?: ShipDexie;
  lastReport?: ReportDexie;
  defaultTimeZone?: string;
  isConfirm: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormBlock = (props: FormBlockProps): React.ReactElement => {
  const onChange = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    if (typeof value === "string") {
      value = convertEmptyStringToNull(value);
    }

    const form = {
      ...props.form,
      [key]: value,
    };

    props.onUpdateForm?.(form);
  };
  props.form.A_Vessel_Name = props.ship ? props.ship.name : "";
  const lastReport = props.lastReport?.data;

  return (
    <Box component="form" noValidate autoComplete="off">
      <Box sx={{ pt: 0.5, px: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "top",
          }}
        >
          <Column>
            <Typography
              component="h3"
              variant="subtitle1"
              align="center"
              sx={{
                lineHeight: 1,
                fontWeight: "bold",
                color: "#fff",
                fontSize: "1.5rem",
                verticalAlign: "super",
              }}
            >
              {props.title}
            </Typography>
            <Typography
              sx={{
                pt: 1,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "12px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#ffffff",
              }}
            >
              *(Prev. Data)
            </Typography>
          </Column>
          <Column sx={{ width: "60%" }}>
            <Steppr step={0} />
          </Column>
        </Columns>
      </Box>
      <Box sx={{ px: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Column
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CommonTextFIeld
              isConfirm={props.isConfirm}
              disabled={props.isPreview}
              id="A_Voy"
              label="Voyage number"
              placeholder=""
              value={props.form.A_Voy}
              onChange={(e: string | null) => {
                onChange("A_Voy", e);
              }}
            />
          </Column>
          <LastReportText lastData={String(lastReport?.N_Voy)} sx={{ pt: 2 }} />
          <Column
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                "& .Mui-focused": {
                  color: "#20BE89",
                },
                "& .MuiFormLabel-root": {
                  color: "#AAAAAA",
                  border: `1px transparent`,
                },
                "& .MuiFormLabel-filled": {
                  color: "#AAAAAA",
                },
              }}
              size="small"
            >
              <InputLabel id="A_LorB" sx={{ color: "#AAAAAA" }}>
                L or B
              </InputLabel>
              <Select
                labelId="A_LorB"
                id="A_LorB"
                label="Loading condition"
                value={props.form.A_LorB}
                onChange={(e: SelectChangeEvent<string | null>) => {
                  onChange("A_LorB", e.target.value);
                }}
                sx={{
                  width: "18ch",
                  "& .MuiSelect-outlined": {
                    border: `1px solid #464E5A !important`,
                  },
                  "& .MuiSelect-select": {
                    color: "#FFFFFF",
                    border: `1px solid #464E5A`,
                  },
                  "& .MuiSelect-select:hover": {
                    color: "#FFFFFF",
                    border: `1px solid #AAAAAA !important`,
                  },
                  "& .MuiSelect-icon": {
                    color: "#FFFFFF",
                  },
                  "&:hover fieldset": {
                    border: "#FFFFFF",
                  },
                  textAlign: "left",
                }}
              >
                {Object.values(LorB).map((v, index) => {
                  return (
                    <MenuItem value={v} key={index}>
                      {v}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Column>
          <LastReportText
            lastData={String(lastReport?.N_Next_port)}
            sx={{ pt: 2 }}
          />
        </Columns>
        <Columns>
          <Column>
            <UtcDateTimePicker
              id={"A_Arrival_Date_Time"}
              isConfirm={props.isConfirm}
              width="26ch"
              label={"Arrival Date & Time (Local)"}
              value={props.form.A_Arrival_Date_Time}
              onChange={onChange}
              onChangeKey={"A_Arrival_Date_Time"}
              hasMinDate={true}
            />
          </Column>
          <Column>
            <UtcDateTimePicker
              id={"A_SB_or_Stop"}
              isConfirm={props.isConfirm}
              width="31ch"
              label={"S/B or Stop Date & Time (Local)"}
              value={props.form.A_SB_or_Stop}
              onChange={onChange}
              onChangeKey={"A_SB_or_Stop"}
              hasMinDate={true}
            />
            <CustomTooltip
              text={"Standby engine or Stop engine Date & Time (Local)"}
            />
          </Column>
        </Columns>
        <Columns
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Column sx={{ mt: -1 }}>
            <Typography
              sx={{
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#AAAAAA",
                pb: 0.5,
              }}
            >
              Time Zone (UTC±)
            </Typography>
            <RadioButtun
              id="A_TimeZone_PorN"
              defaultValue={props.defaultTimeZone?.slice(0, 1) || "+"}
              label=""
              label1="＋"
              label2="ー"
              value1="+"
              value2="-"
              onChange={onChange}
            />
          </Column>
          <Column sx={{ pl: -2 }}>
            <Typography
              sx={{
                pb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#AAAAAA",
              }}
            ></Typography>
            <Select
              size="small"
              labelId="A_TimeZone_HH"
              id="A_TimeZone_HH"
              label="Hour."
              placeholder="Hour."
              value={props.form.A_TimeZone_HH}
              onChange={(e: SelectChangeEvent<string | null>) => {
                onChange("A_TimeZone_HH", e.target.value);
              }}
              inputProps={{
                "aria-label": `A_TimeZone_HH`,
              }}
              sx={{
                width: "70px",
                "& .MuiSelect-outlined": {
                  color: "#FFFFFF",
                },
                "& .MuiSelect-select": {
                  color: "#FFFFFF",
                  border: `1px solid #464E5A`,
                },
                "& .MuiSelect-select:hover": {
                  color: "#FFFFFF",
                  border: `1px solid #AAAAAA !important`,
                },

                "& .MuiSelect-icon": {
                  color: "transparent",
                },
                "&:hover fieldset": {
                  border: "#464E5A",
                },
                textAlign: "left",
              }}
            >
              {[...Array(15).keys()]
                .map((v) => ("00" + String(v)).slice(-2))
                .map((v, index) => {
                  return (
                    <MenuItem value={v} key={index}>
                      {v}
                    </MenuItem>
                  );
                })}
            </Select>
          </Column>
          <Column sx={{ pl: -15 }}>
            <Typography sx={{ color: "#AAAAAA", pt: 2 }}>：</Typography>
          </Column>
          <Column sx={{ pl: -15 }}>
            <Typography
              sx={{
                pb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#AAAAAA",
              }}
            ></Typography>
            <Select
              size="small"
              labelId="A_TimeZone_MM"
              id="A_TimeZone_MM"
              label="Min."
              placeholder="Min."
              value={props.form.A_TimeZone_MM}
              onChange={(e: SelectChangeEvent<string | null>) => {
                onChange("A_TimeZone_MM", e.target.value);
              }}
              inputProps={{
                "aria-label": `A_TimeZone_MM`,
              }}
              sx={{
                width: "70px",
                "& .MuiSelect-outlined": {
                  color: "#FFFFFF",
                },
                "& .MuiSelect-select": {
                  color: "#FFFFFF",
                  border: `1px solid #464E5A`,
                },
                "& .MuiSelect-select:hover": {
                  color: "#FFFFFF",
                  border: `1px solid #AAAAAA !important`,
                },

                "& .MuiSelect-icon": {
                  color: "transparent",
                },
                "&:hover fieldset": {
                  border: "#464E5A",
                },
                textAlign: "left",
              }}
            >
              {["00", "30", "45"].map((v, index) => {
                return (
                  <MenuItem value={v} key={index}>
                    {v}
                  </MenuItem>
                );
              })}
            </Select>
          </Column>
        </Columns>
        <Stack
          direction="row"
          spacing={2}
          alignItems="end"
          justifyContent={"left"}
        >
          <CommonTextFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="A_Arrival_Port"
            label="Port name"
            placeholder=""
            value={props.form.A_Arrival_Port}
            onChange={(e) => {
              onChange("A_Arrival_Port", e);
            }}
          />
          <CommonTextFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="A_UNLOC"
            label="UNLOC"
            placeholder=""
            value={props.form.A_UNLOC}
            onChange={(e) => {
              onChange("A_UNLOC", e);
            }}
          />
          <CommonSelect
            id="A_EU_UK_port"
            label="EU/UK"
            value={props.form.A_EU_UK_port || ""}
            onChange={(e) => {
              onChange("A_EU_UK_port", e);
            }}
            selectValues={Object.values(EUorUk)}
            isOptional={true}
          />
          <CommonSelect
            id="A_Port_L_U"
            label="L or U"
            value={props.form.A_Port_L_U}
            onChange={(e) => {
              onChange("A_Port_L_U", e);
            }}
            selectValues={Object.values(LorU)}
          />
        </Stack>

        <Columns
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Column>
            <UtcDateTimePicker
              id={"A_ETB"}
              isConfirm={props.isConfirm}
              label={"ETB"}
              value={props.form.A_ETB}
              onChange={onChange}
              onChangeKey={"A_ETB"}
              hasMinDate={true}
            />
            <CustomTooltip text={"Estimated Time of Berthing"} />
          </Column>

          <Column>
            <UtcDateTimePicker
              id={"A_ETC"}
              isConfirm={props.isConfirm}
              label={"ETC"}
              value={props.form.A_ETC}
              onChange={onChange}
              onChangeKey={"A_ETC"}
              hasMinDate={true}
            />
            <CustomTooltip text={"Estimated Time of Completion"} />
          </Column>
          <Column>
            <UtcDateTimePicker
              id={"A_ETD"}
              isConfirm={props.isConfirm}
              label={"ETD"}
              value={props.form.A_ETD}
              onChange={onChange}
              onChangeKey={"A_ETD"}
              hasMinDate={true}
            />
            <CustomTooltip text={"Estimated Time of Departure"} />
          </Column>
          <Column>
            <UtcDateTimePicker
              id={"A_Berthed"}
              isConfirm={props.isConfirm}
              label={"Berthed (Local)"}
              value={props.form.A_Berthed}
              onChange={onChange}
              onChangeKey={"A_Berthed"}
              hasMinDate={true}
            />
          </Column>
        </Columns>
        <Box>
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column sx={{ mb: -3 }}>
              <Typography
                sx={{
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: "14px",
                  fontFamily: "Public Sans",
                  lineHeight: "auto",
                  color: "#AAAAAA",
                  pl: 1,
                  mb: -2,
                }}
              >
                ROB at Arrival
              </Typography>
              <CustomTooltip
                text={"Retention of Oil on Board"}
                sx={{ mt: -2, ml: 14, mb: 0 }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_at_Arrival_FO"
                label="FO (mt)"
                placeholder=""
                value={props.form.A_ROB_at_Arrival_FO}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_at_Arrival_FO", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_at_Arrival_MGO"
                label="MGO (mt)"
                placeholder=""
                value={props.form.A_ROB_at_Arrival_MGO}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_at_Arrival_MGO", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_at_Arrival_MECO"
                label="MECO (L)"
                placeholder=""
                value={props.form.A_ROB_at_Arrival_MECO}
                unitName={"L"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_at_Arrival_MECO", e);
                }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_at_Arrival_MESO"
                label="MESO (L)"
                placeholder=""
                value={props.form.A_ROB_at_Arrival_MESO}
                unitName={"L"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_at_Arrival_MESO", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_at_Arrival_GESO"
                label="GESO (L)"
                placeholder=""
                value={props.form.A_ROB_at_Arrival_GESO}
                unitName={"L"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_at_Arrival_GESO", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_at_Arrival_FW"
                label="FW (mt)"
                placeholder=""
                value={props.form.A_ROB_at_Arrival_FW}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_at_Arrival_FW", e);
                }}
              />
            </Column>
          </Columns>

          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_Hrs_since_Last_Noon"
                label="Hours since last noon report (hour)"
                placeholder=""
                value={props.form.A_Hrs_since_Last_Noon}
                unitName={"hour"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_Hrs_since_Last_Noon", e);
                }}
                sx={{ width: "380px" }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_Distance_by_OG"
                label="Distance travelled over ground (nautical mile)"
                placeholder=""
                value={props.form.A_Distance_by_OG}
                unitName={"nautical mile"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_Distance_by_OG", e);
                }}
                sx={{ width: "550px" }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column>
              <Typography
                sx={{
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: "14px",
                  fontFamily: "Public Sans",
                  lineHeight: "auto",
                  color: "#AAAAAA",
                  pl: 1,
                  mb: -2,
                }}
              >
                FO Consumption per day
              </Typography>
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_FO_Consumption_per_day_ME"
                label="M/E (mt)"
                placeholder=""
                value={props.form.A_FO_Consumption_per_day_ME}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_FO_Consumption_per_day_ME", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_FO_Consumption_per_day_GE"
                label="G/E (mt)"
                placeholder=""
                value={props.form.A_FO_Consumption_per_day_GE}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_FO_Consumption_per_day_GE", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_FO_Consumption_per_day_B_and_Inc"
                label={`Boiler & Incinerator (mt)`}
                placeholder=""
                value={props.form.A_FO_Consumption_per_day_B_and_Inc}
                unitName={"mt"}
                minValue={0}
                sx={{ width: "280px" }}
                onChange={(e: string | null) => {
                  onChange("A_FO_Consumption_per_day_B_and_Inc", e);
                }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column>
              <Typography
                sx={{
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: "14px",
                  fontFamily: "Public Sans",
                  lineHeight: "auto",
                  color: "#AAAAAA",
                  pl: 1,
                  mb: -2,
                }}
              >
                MGO Consumption per day
              </Typography>
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_MGO_Consumption_per_day_ME"
                label="M/E (mt)"
                placeholder=""
                value={props.form.A_MGO_Consumption_per_day_ME}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_MGO_Consumption_per_day_ME", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_MGO_Consumption_per_day_GE"
                label="G/E (mt)"
                placeholder=""
                value={props.form.A_MGO_Consumption_per_day_GE}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_MGO_Consumption_per_day_GE", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_MGO_Consumption_per_day_B_and_Inc"
                label={`Boiler & Incinerator (mt)`}
                placeholder=""
                value={props.form.A_MGO_Consumption_per_day_B_and_Inc}
                unitName={"mt"}
                minValue={0}
                sx={{ width: "280px" }}
                onChange={(e: string | null) => {
                  onChange("A_MGO_Consumption_per_day_B_and_Inc", e);
                }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column sx={{ mb: -3 }}>
              <Typography
                sx={{
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: "14px",
                  fontFamily: "Public Sans",
                  lineHeight: "auto",
                  color: "#AAAAAA",
                  pl: 1,
                  mb: -2,
                }}
              >
                ROB
              </Typography>
              <CustomTooltip
                text={"Retention of Oil on Board"}
                sx={{ mt: -2, ml: 5.5, mb: 0 }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_FO"
                label="FO (mt)"
                placeholder=""
                value={props.form.A_ROB_FO}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_FO", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_MGO"
                label="MGO (mt)"
                placeholder=""
                value={props.form.A_ROB_MGO}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_MGO", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_MECO"
                label="MECO (L)"
                placeholder=""
                value={props.form.A_ROB_MECO}
                unitName={"L"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_MECO", e);
                }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_MESO"
                label="MESO (L)"
                placeholder=""
                value={props.form.A_ROB_MESO}
                unitName={"L"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_MESO", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_GESO"
                label="GESO (L)"
                placeholder=""
                value={props.form.A_ROB_GESO}
                unitName={"L"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_GESO", e);
                }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_ROB_FW"
                label="FW (mt)"
                placeholder=""
                value={props.form.A_ROB_FW}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_ROB_FW", e);
                }}
              />
            </Column>
          </Columns>
          <RobAdditionalForArrival {...props} />
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column>
              <Typography
                sx={{
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: "14px",
                  fontFamily: "Public Sans",
                  lineHeight: "auto",
                  color: "#AAAAAA",
                  pl: 1,
                  mb: -2,
                }}
              >
                Draft
              </Typography>
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_Draft_Fore"
                label="Fore (m)"
                placeholder=""
                value={props.form.A_Draft_Fore}
                unitName={"m"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_Draft_Fore", e);
                }}
              />
            </Column>

            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_Draft_Aft"
                label="Aft (m)"
                placeholder=""
                value={props.form.A_Draft_Aft}
                unitName={"m"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_Draft_Aft", e);
                }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_FO_CO2_emission"
                label="FO CO2 emission"
                placeholder=""
                value={props.form.A_FO_CO2_emission}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_FO_CO2_emission", e);
                }}
                sx={{ width: "230px" }}
              />
            </Column>

            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="A_MGO_CO2_emission"
                label="MGO CO2 emission"
                placeholder=""
                value={props.form.A_MGO_CO2_emission}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("A_MGO_CO2_emission", e);
                }}
                sx={{ width: "240px" }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              pl: 3,
              display: "flex",
              alignItems: "end",
            }}
          >
            <TextField
              disabled={props.isPreview}
              sx={{
                margin: "left",
                width: "1000px",
                pt: 4,
                "& .MuiInputBase-input": { color: "#FFFFFF" },
                "& label.Mui-focused": {
                  color: "#20BE89",
                },
                "& label": {
                  mt: 4,
                  color: "#AAAAAA",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#464E5A",
                  },
                  "&:hover fieldset": {
                    borderColor: "#AAAAAA",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#20BE89",
                  },
                },
                textAlign: "left",
              }}
              rows={6}
              id="D_Remark"
              label="Remark"
              placeholder=""
              multiline
              value={props.form.A_Remark || ""}
              inputProps={{
                maxLength: 2000,
              }}
              onChange={(e) => {
                onChange("A_Remark", e.target.value);
              }}
            />
          </Columns>
          <FormBlock_Spare {...props} />
        </Box>
      </Box>
    </Box>
  );
};

export default FormBlock;
