import { useMemo } from "react";
import { useLazyInitializeState } from "../../../../utils/lazy-initialize-state";
import { Form, initializeDeparture } from "./Create/types";
import { emptyToDoubleQuotation } from "../../../../utils/formats";
import { AdditionalRobForDepartureInput, DepartureInput, LorB, LorU } from "../../../../gen/graphql/types";
import { filterArrUndefinedNull } from "../../../../utils/type-checks";

type Maybe<T> = T | null | undefined;

export const useDepartureForm = (initializes: Maybe<Form>[]) => {
  const [isLoading, data, update] = useLazyInitializeState<Form>(
    initializeDeparture,
    initializes
  );

  const hasError = useMemo(() => {
    const {
      D_Vessel_Name,
      D_Voy,
      D_Last_Voy,
      D_LorB,
      D_Departure_Port,
      D_Departure_Date_Time,
      D_RU_Date_Time,
      D_TimeZone_PorN,
      D_TimeZone_HH,
      D_TimeZone_MM,
      D_ROB_FO,
      D_ROB_MGO,
      D_ROB_MECO,
      D_ROB_MESO,
      D_ROB_GESO,
      D_ROB_FW,
      D_Cargo_Name,
      D_Cargo_Qtty,
      D_Draft_Fore,
      D_Draft_Aft,
      D_Next_Port_Name,
      D_ETA,
      D_ETB,
      D_ETC,
      D_ETD,
      // D_Remark,
    } = data;

    const hasError = [
      D_Vessel_Name,
      D_Voy,
      D_Last_Voy,
      D_LorB,
      D_Departure_Port,
      D_Departure_Date_Time,
      D_RU_Date_Time,
      D_TimeZone_PorN,
      D_TimeZone_HH,
      D_TimeZone_MM,
      D_ROB_FO,
      D_ROB_MGO,
      D_ROB_MECO,
      D_ROB_MESO,
      D_ROB_GESO,
      D_ROB_FW,
      D_Cargo_Name,
      D_Cargo_Qtty,
      D_Draft_Fore,
      D_Draft_Aft,
      D_Next_Port_Name,
      D_ETA,
      D_ETB,
      D_ETC,
      D_ETD,
      // D_Remark
    ].some((item) => item === null || item === undefined || item === "");

    return hasError;
  }, [data]);

  const hasErrorScrollUp = () => {
    const {
      D_Vessel_Name,
      D_Voy,
      D_Last_Voy,
      D_LorB,
      D_Departure_Port,
      D_Departure_Date_Time,
      D_RU_Date_Time,
      D_ROB_FO,
      D_ROB_MGO,
      D_ROB_MECO,
      D_ROB_MESO,
      D_ROB_GESO,
      D_ROB_FW,
      D_Cargo_Name,
      D_Cargo_Qtty,
      D_Draft_Fore,
      D_Draft_Aft,
      D_Next_Port_Name,
      D_ETA,
      D_ETB,
      D_ETC,
      D_ETD,
    } = data;

    const upperForm = [
      D_Vessel_Name,
      D_Voy,
      D_Last_Voy,
      D_LorB,
      D_Departure_Port,
      D_Departure_Date_Time,
      D_RU_Date_Time,
    ];

    const lowerForm = [
      D_ROB_FO,
      D_ROB_MGO,
      D_ROB_MECO,
      D_ROB_MESO,
      D_ROB_GESO,
      D_ROB_FW,
      D_Cargo_Name,
      D_Cargo_Qtty,
      D_Draft_Fore,
      D_Draft_Aft,
      D_Next_Port_Name,
      D_ETA,
      D_ETB,
      D_ETC,
      D_ETD,
    ];

    if (upperForm.filter((v) => !v).length != 0) {
      window.scrollTo({
        top: 150,
        behavior: "smooth",
      });
    } else if (lowerForm.filter((v) => !v).length != 0) {
      window.scrollTo({
        top: 400,
        behavior: "smooth",
      });
    }
  };

  return {
    isLoading,
    data,
    hasError,
    update,
    hasErrorScrollUp,
  };
};

export const mapToFormDepartureData = (
  report: DepartureInput | undefined
): Form | null => {
  if (report === undefined) {
    return null;
  }
  const D_Vessel_Name = report.D_Vessel_Name;
  const D_Voy = report?.D_Voy;
  const D_Last_Voy = report?.D_Last_Voy;
  const D_LorB = report?.D_LorB || null;

  const D_Departure_Port = report?.D_Departure_Port;
  const D_Departure_Date_Time = report?.D_Departure_Date_Time;
  const D_TimeZone_PorN = String(report?.D_Time_Zone).slice(0, 1);
  const D_TimeZone_HH = String(report?.D_Time_Zone).slice(1, 3);
  const D_TimeZone_MM = String(report?.D_Time_Zone).slice(4, 6);
  const D_RU_Date_Time = report?.D_RU_Date_Time;

  const D_ROB_FO = report?.D_ROB_FO;
  const D_ROB_MGO = report?.D_ROB_MGO;
  const D_ROB_MECO = report?.D_ROB_MECO;
  const D_ROB_MESO = report?.D_ROB_MESO;
  const D_ROB_GESO = report?.D_ROB_GESO;
  const D_ROB_FW = report?.D_ROB_FW;
  const D_Cargo_Name = report?.D_Cargo_Name;
  const D_Cargo_Qtty = report?.D_Cargo_Qtty;
  const D_Draft_Fore = report?.D_Draft_Fore;
  const D_Draft_Aft = report?.D_Draft_Aft;
  const D_Next_Port_Name = report?.D_Next_Port_Name;
  const D_ETA = report?.D_ETA;
  const D_ETB = report?.D_ETB;
  const D_ETC = report?.D_ETC;
  const D_ETD = report?.D_ETD;

  const D_Remark = report?.D_Remark || "";

  return {
    D_Vessel_Name,
    D_Voy,
    D_Last_Voy,
    D_LorB: D_LorB || LorB.Ballast,
    D_Departure_Port,
    D_EU_UK_port: report.D_EU_UK_port,
    D_Port_L_U: report.D_Port_L_U || LorU.N,
    D_UNLOC: report.D_UNLOC || emptyToDoubleQuotation(report.D_UNLOC),
    D_Departure_Date_Time,
    D_RU_Date_Time,
    D_TimeZone_PorN,
    D_TimeZone_HH,
    D_TimeZone_MM,
    D_ROB_FO,
    D_ROB_MGO,
    D_ROB_MECO,
    D_ROB_MESO,
    D_ROB_GESO,
    D_ROB_FW,
    D_Additional_ROB: filterArrUndefinedNull(report.D_Additional_ROB),
    D_Cargo_Name,
    D_Cargo_Qtty,
    D_Draft_Fore,
    D_Draft_Aft,
    D_Next_Port_Name,
    D_ETA,
    D_ETB,
    D_ETC,
    D_ETD,
    D_Remark,
    D_Spare_1: emptyToDoubleQuotation(report.D_Spare_1),
    D_Spare_1_unit_name: emptyToDoubleQuotation(report.D_Spare_1_unit_name),
    D_Spare_2: emptyToDoubleQuotation(report.D_Spare_2),
    D_Spare_2_unit_name: emptyToDoubleQuotation(report.D_Spare_2_unit_name),
    D_Spare_3: emptyToDoubleQuotation(report.D_Spare_3),
    D_Spare_3_unit_name: emptyToDoubleQuotation(report.D_Spare_3_unit_name),
    D_Spare_4: emptyToDoubleQuotation(report.D_Spare_4),
    D_Spare_4_unit_name: emptyToDoubleQuotation(report.D_Spare_4_unit_name),
    D_Spare_5: emptyToDoubleQuotation(report.D_Spare_5),
    D_Spare_5_unit_name: emptyToDoubleQuotation(report.D_Spare_5_unit_name),
    D_Spare_6: emptyToDoubleQuotation(report.D_Spare_6),
    D_Spare_6_unit_name: emptyToDoubleQuotation(report.D_Spare_6_unit_name),
    D_Spare_7: emptyToDoubleQuotation(report.D_Spare_7),
    D_Spare_7_unit_name: emptyToDoubleQuotation(report.D_Spare_7_unit_name),
    D_Spare_8: emptyToDoubleQuotation(report.D_Spare_8),
    D_Spare_8_unit_name: emptyToDoubleQuotation(report.D_Spare_8_unit_name),
    D_Spare_9: emptyToDoubleQuotation(report.D_Spare_9),
    D_Spare_9_unit_name: emptyToDoubleQuotation(report.D_Spare_9_unit_name),
    D_Spare_10: emptyToDoubleQuotation(report.D_Spare_10),
    D_Spare_10_unit_name: emptyToDoubleQuotation(report.D_Spare_10_unit_name),
    D_Spare_11: emptyToDoubleQuotation(report.D_Spare_11),
    D_Spare_11_unit_name: emptyToDoubleQuotation(report.D_Spare_11_unit_name),
    D_Spare_12: emptyToDoubleQuotation(report.D_Spare_12),
    D_Spare_12_unit_name: emptyToDoubleQuotation(report.D_Spare_12_unit_name),
    D_Spare_13: emptyToDoubleQuotation(report.D_Spare_13),
    D_Spare_13_unit_name: emptyToDoubleQuotation(report.D_Spare_13_unit_name),
    D_Spare_14: emptyToDoubleQuotation(report.D_Spare_14),
    D_Spare_14_unit_name: emptyToDoubleQuotation(report.D_Spare_14_unit_name),
    D_Spare_15: emptyToDoubleQuotation(report.D_Spare_15),
    D_Spare_15_unit_name: emptyToDoubleQuotation(report.D_Spare_15_unit_name),
    D_Spare_16: emptyToDoubleQuotation(report.D_Spare_16),
    D_Spare_16_unit_name: emptyToDoubleQuotation(report.D_Spare_16_unit_name),
    D_Spare_17: emptyToDoubleQuotation(report.D_Spare_17),
    D_Spare_17_unit_name: emptyToDoubleQuotation(report.D_Spare_17_unit_name),
    D_Spare_18: emptyToDoubleQuotation(report.D_Spare_18),
    D_Spare_18_unit_name: emptyToDoubleQuotation(report.D_Spare_18_unit_name),
    D_Spare_19: emptyToDoubleQuotation(report.D_Spare_19),
    D_Spare_19_unit_name: emptyToDoubleQuotation(report.D_Spare_19_unit_name),
    D_Spare_20: emptyToDoubleQuotation(report.D_Spare_20),
    D_Spare_20_unit_name: emptyToDoubleQuotation(report.D_Spare_20_unit_name),
  };
};

export const mapToDepartureMutationVariables = (
  form: Form,
  ship_id: number,
  report_id?: number
): DepartureInput => {
  const {
    D_Vessel_Name,
    D_Voy,
    D_Last_Voy,
    D_LorB,
    D_Departure_Port,
    D_EU_UK_port,
    D_Port_L_U,
    D_UNLOC,
    D_Departure_Date_Time,
    D_RU_Date_Time,
    D_TimeZone_PorN,
    D_TimeZone_HH,
    D_TimeZone_MM,
    D_ROB_FO,
    D_ROB_MGO,
    D_ROB_MECO,
    D_ROB_MESO,
    D_ROB_GESO,
    D_ROB_FW,
    D_Cargo_Name,
    D_Cargo_Qtty,
    D_Draft_Fore,
    D_Draft_Aft,
    D_Next_Port_Name,
    D_ETA,
    D_ETB,
    D_ETC,
    D_ETD,
    D_Remark,
    D_Spare_1,
    D_Spare_1_unit_name,
    D_Spare_2,
    D_Spare_2_unit_name,
    D_Spare_3,
    D_Spare_3_unit_name,
    D_Spare_4,
    D_Spare_4_unit_name,
    D_Spare_5,
    D_Spare_5_unit_name,
    D_Spare_6,
    D_Spare_6_unit_name,
    D_Spare_7,
    D_Spare_7_unit_name,
    D_Spare_8,
    D_Spare_8_unit_name,
    D_Spare_9,
    D_Spare_9_unit_name,
    D_Spare_10,
    D_Spare_10_unit_name,
    D_Spare_11,
    D_Spare_11_unit_name,
    D_Spare_12,
    D_Spare_12_unit_name,
    D_Spare_13,
    D_Spare_13_unit_name,
    D_Spare_14,
    D_Spare_14_unit_name,
    D_Spare_15,
    D_Spare_15_unit_name,
    D_Spare_16,
    D_Spare_16_unit_name,
    D_Spare_17,
    D_Spare_17_unit_name,
    D_Spare_18,
    D_Spare_18_unit_name,
    D_Spare_19,
    D_Spare_19_unit_name,
    D_Spare_20,
    D_Spare_20_unit_name,
  } = form;

  const D_Time_Zone =
    (D_TimeZone_PorN || "") + D_TimeZone_HH + ":" + D_TimeZone_MM;

  return {
    id: report_id || 0,
    ship_id,
    D_Vessel_Name: D_Vessel_Name || "",
    D_Voy: D_Voy || "",
    D_Last_Voy: D_Last_Voy || "",
    D_LorB: D_LorB || LorB.Ballast,
    D_Departure_Port: D_Departure_Port || "",
    D_EU_UK_port: D_EU_UK_port,
    D_UNLOC: D_UNLOC || "",
    D_Port_L_U: D_Port_L_U,
    D_Departure_Date_Time: D_Departure_Date_Time || new Date(),
    D_RU_Date_Time: D_RU_Date_Time || new Date(),
    D_Time_Zone: D_TimeZone_MM ? D_Time_Zone : "",
    D_ROB_FO: D_ROB_FO ?? 0,
    D_ROB_MGO: D_ROB_MGO ?? 0,
    D_ROB_MECO: D_ROB_MECO ?? 0,
    D_ROB_MESO: D_ROB_MESO ?? 0,
    D_ROB_GESO: D_ROB_GESO ?? 0,
    D_ROB_FW: D_ROB_FW ?? 0,
    D_Additional_ROB: filterArrUndefinedNull(
      form.D_Additional_ROB?.filter((v) => v.D_Fuel_volume !== undefined).map(
        (v) => {
          return {
            ...v,
            id: v.id || 0,
            D_Fuel_volume: v.D_Fuel_volume || 0,
            D_Fuel_Type: v.D_Fuel_Type,
          };
        }
      )
    ) as AdditionalRobForDepartureInput[],
    D_Cargo_Name: D_Cargo_Name || "",
    D_Cargo_Qtty: D_Cargo_Qtty ?? 0,
    D_Draft_Fore: D_Draft_Fore ?? 0,
    D_Draft_Aft: D_Draft_Aft ?? 0,
    D_Next_Port_Name: D_Next_Port_Name || "",
    D_ETA: D_ETA || new Date(),
    D_ETB: D_ETB || new Date(),
    D_ETC: D_ETC || new Date(),
    D_ETD: D_ETD || new Date(),
    D_Remark,
    D_Spare_1,
    D_Spare_1_unit_name,
    D_Spare_2,
    D_Spare_2_unit_name,
    D_Spare_3,
    D_Spare_3_unit_name,
    D_Spare_4,
    D_Spare_4_unit_name,
    D_Spare_5,
    D_Spare_5_unit_name,
    D_Spare_6,
    D_Spare_6_unit_name,
    D_Spare_7,
    D_Spare_7_unit_name,
    D_Spare_8,
    D_Spare_8_unit_name,
    D_Spare_9,
    D_Spare_9_unit_name,
    D_Spare_10,
    D_Spare_10_unit_name,
    D_Spare_11,
    D_Spare_11_unit_name,
    D_Spare_12,
    D_Spare_12_unit_name,
    D_Spare_13,
    D_Spare_13_unit_name,
    D_Spare_14,
    D_Spare_14_unit_name,
    D_Spare_15,
    D_Spare_15_unit_name,
    D_Spare_16,
    D_Spare_16_unit_name,
    D_Spare_17,
    D_Spare_17_unit_name,
    D_Spare_18,
    D_Spare_18_unit_name,
    D_Spare_19,
    D_Spare_19_unit_name,
    D_Spare_20,
    D_Spare_20_unit_name,
  };
};
