import { Form } from "../../../pages/Crew/Bunkering/Create/types";

type AdditionalKeyStringsType = {
  fuelNameKey: string;
  massKey: string;
  volumeKey: string;
  densityKey: string;
};

export const additionalBankeringKeyStrings = {
  fuelNameKey: "B_Fuel_name_",
  massKey: "B_Mass_",
  volumeKey: "B_Volume_",
  densityKey: "B_Density_",
} as AdditionalKeyStringsType;

function isAnyAdditionalPropertySet(index: number, form: Form): boolean {
  const fuelTypeKey = `${additionalBankeringKeyStrings.fuelNameKey}${index}`;
  const massKeyKey = `${additionalBankeringKeyStrings.massKey}${index}`;
  const volumeKey = `${additionalBankeringKeyStrings.volumeKey}${index}`;
  const densityKey = `${additionalBankeringKeyStrings.densityKey}${index}`;

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (form as any)[fuelTypeKey] != null ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (form as any)[massKeyKey] != null ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (form as any)[volumeKey] != null || // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (form as any)[densityKey] != null
  );
}

export function checkBankeringArraysLength(form: Form): number {
  const maxIndex = 10;

  let length = 0;

  for (let i = 1; i <= maxIndex; i++) {
    if (isAnyAdditionalPropertySet(i, form)) {
      length++;
    }
  }

  return length;
}
