import { SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";

type Props = {
  sx?: SxProps<Theme>;
};

export const Circle = (props: Props) => {
  return (
    <Box
      sx={{
        width: 22,
        height: 22,
        borderRadius: "50%",
        display: "inline-block",
        ...props.sx,
      }}
    />
  );
};
