import { getOffsetForLongitude } from "../../../../../utils/calc-timezone";
import { Form } from "../../../../pages/Crew/Bunkering/Create/types";

type TimezoneProps = {
  form: Form;
  isServer?: boolean;
};

export function calculateBunkeringTimezone(props: TimezoneProps) {
  if (!props.isServer) {
    const timezone = getOffsetForLongitude({
      longDirection: props.form.B_Long_Direction,
      longDeg: props.form.B_Long_Deg,
      longMin: props.form.B_Long_Min,
    });

    if (timezone) {
      props.form.B_TimeZone_PorN = timezone.TimeZone_PorN;
      props.form.B_TimeZone_HH = timezone.TimeZone_HH;
      props.form.B_TimeZone_MM = timezone.TimeZone_MM;
    }
  }
  return props.form;
}
