import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import DefaultCrew from "../../../../layouts/DefaultCrew";
import Template from "../../../../templates/Crew/Departure/ServerUpdate/ServerUpdateDepartureConfirm";
import { Form, LocationState } from "../Create/types";
import { UpdateDepartureDocument } from "../../../../../gen/graphql/types";
import { pageData, useContextIDs } from "../../../../../App";
import { useMutation } from "@apollo/client";
import useErrorMessageList from "../../../../../hooks/errorMessage.hook";
import { mapToDepartureMutationVariables } from "../departure.module";

type LocationForm = {
  form: Form;
};

const ServerUpdateDepartureConfirm = (
  props: LocationState
): React.ReactElement => {
  const { ship_id } = useContextIDs();
  const { reportId } = useParams<"reportId">();

  const navigate = useNavigate();

  const [updateDeparture, { error }] = useMutation(UpdateDepartureDocument);
  const { errorMessageList, addErrorMessage } = useErrorMessageList();

  if (reportId === undefined) navigate("/");

  const report_id = Number(reportId);

  const location = useLocation();

  const location_form = location.state as LocationForm;

  if (!props.form && !location_form.form) {
    navigate(-1);
  }

  const form = props.form || location_form.form;

  const onUpdate = async () => {
    try {
      const variables = mapToDepartureMutationVariables(
        form,
        ship_id,
        report_id
      );

      updateDeparture({
        fetchPolicy: "network-only",
        variables: { input: variables },
        onCompleted: (data) => {
          try {
            if (!data) {
              throw `Server Update error! ${data}`;
            }
            navigate(pageData.serverUpdateDepartureComplete.path());
          } catch (error) {
            console.error(error);
            addErrorMessage({
              input: JSON.stringify(variables),
              errorMessage: `Server Update error! ${error}`,
            });
          }
        },
        onError: (error) => {
          console.error(error);
          addErrorMessage({
            input: JSON.stringify(variables),
            errorMessage: `Server Update error! ${error}`,
          });
        },
      });
    } catch (e) {
      console.error(e);
      console.error(error);
      return;
    }
  };

  const onBack = () => {
    if (!reportId) return;
    navigate(pageData.serverUpdateDeparture.path(reportId), {
      state: { form: form },
    });
  };

  return (
    <DefaultCrew title="" selectedTitle={"Departure"}>
      <Template
        form={location_form.form}
        isLoading={false}
        onUpdate={onUpdate}
        onBack={onBack}
        reportId={report_id}
        errorMessageList={errorMessageList}
      />
    </DefaultCrew>
  );
};

export default ServerUpdateDepartureConfirm;
