import * as React from "react";
import Paper from "@mui/material/Paper";
import {
  Link,
  Pagination,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Nowrap from "../../../atoms/Nowrap";
import { formatToUtcYYYYMMDDHHMM } from "../../../../utils/formats";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { CargoDexie } from "../../../../dexie/Cargo";
import NowrapGray from "../../../atoms/NowrapGray";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import CustomTableCell from "../../../atoms/CustomTableCell";
import SucsessButton from "../../../atoms/SucsessButton";
import { useNavigate } from "react-router";
import DeleteModal from "../../../atoms/DeleteModal";
import { useState } from "react";
import GrayButton from "../../../atoms/GrayButton";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import { useAuth0 } from "@auth0/auth0-react";
import { pageData } from "../../../../App";
import UtcDateTimePicker from "../../../atoms/UtcDateTimePicker";
import { SEARCH_PAGE_LIMIT_CREW } from "../../../pages/Crew/crew.module";
import { getLatLong, getTimeZone } from "../../../../utils/form-util";
import { ReportSearchCondition } from "../../../pages/pages.module";

type ConfirmProps = {
  cargoReports: CargoDexie[];
  deleteReport(id: number): void;
  onUpdateForm(value: ReportSearchCondition): void;
  form: ReportSearchCondition;
  isServerData: boolean;
  onSearch: () => void;
  totalPageCount: number;
  onUpdateSearchCondition(
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ): void;
};

const Cargo = (props: ConfirmProps): React.ReactElement => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [modalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<CargoDexie>();
  const [page, setPage] = useState<number>(1);

  const onChange = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    const form = {
      ...props.form,
      [key]: value,
    };

    props.onUpdateForm?.(form);
  };

  const onCangeFrontPage = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
    if (!value) {
      return;
    }

    props.form.pagination.page = value;

    props.onUpdateForm?.(props.form);
    props.onUpdateSearchCondition(props.form, true);
  };

  const reportList = !props.isServerData
    ? SEARCH_PAGE_LIMIT_CREW > 0
      ? props.cargoReports.slice(
          page * SEARCH_PAGE_LIMIT_CREW - SEARCH_PAGE_LIMIT_CREW,
          page * SEARCH_PAGE_LIMIT_CREW
        )
      : props.cargoReports
    : props.cargoReports;

  return (
    <React.Fragment>
      <Paper
        sx={{
          backgroundColor: "#27292E",
          borderRadius: "15px",
          overflowY: "hidden",
          ".container::-webkit-scrollbar": {
            display: "none",
          },
          mb: 3,
        }}
      >
        <Columns spaceBetween sx={{ pt: 3, mx: 2, fontSize: 24 }}>
          <Column sx={{ color: "#FFFFFF", pt: 0, pl: 0 }}>
            Cargo Evidence Report List
          </Column>
          <SucsessButton
            ButtonName={"Create Report"}
            disabled={false}
            onClick={() => navigate(pageData.crewCargoCreate)}
            sx={{ mt: 0, fontSize: 16 }}
          />
        </Columns>
        {isAuthenticated && (
          <>
            <Columns sx={{ pb: 2 }}>
              <Column sx={{ ml: 2 }}>
                <CommonTextFIeld
                  id={"Voy"}
                  label={"Voy#"}
                  value={props.form.Voy}
                  onChange={(e: string | null) => {
                    onChange("Voy", e);
                  }}
                  width={"130px"}
                />
              </Column>
              <Column>
                <Typography
                  sx={{
                    pt: 1,
                    textAlign: "left",
                    verticalAlign: "middle",
                    fontSize: "16px",
                    fontFamily: "Public Sans",
                    lineHeight: "auto",
                    color: "#aaaaaa",
                  }}
                >
                  From
                </Typography>
              </Column>
              <Column>
                <UtcDateTimePicker
                  id={"From_DateTime"}
                  label={"Date & Time"}
                  value={props.form.From_DateTime}
                  onChange={onChange}
                  onChangeKey={"From_DateTime"}
                  hasMinDate={false}
                />
              </Column>
              <Column>
                <Typography
                  sx={{
                    pt: 1,
                    textAlign: "left",
                    verticalAlign: "middle",
                    fontSize: "16px",
                    fontFamily: "Public Sans",
                    lineHeight: "auto",
                    color: "#aaaaaa",
                  }}
                >
                  To
                </Typography>
              </Column>
              <Column>
                <UtcDateTimePicker
                  id={"To_DateTime"}
                  label={"Date & Time"}
                  value={props.form.To_DateTime}
                  onChange={onChange}
                  onChangeKey={"To_DateTime"}
                  hasMinDate={false}
                />
              </Column>

              <Column>
                <GrayButton
                  ButtonName={"Search"}
                  disabled={false}
                  width={"100px"}
                  onClick={() => props.onSearch()}
                />
              </Column>
            </Columns>
          </>
        )}
        <Box sx={{ px: 2 }}>
          <Table size="small" sx={{ mx: 0, color: "#FFFFFF" }}>
            <TableHead>
              <TableRow>
                <CustomTableCell>
                  <NowrapGray>ID</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>Type</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>Status</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>Voy#</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>EU/UK</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>{`Date & Time`}</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>Time Zone</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>Lat./Lon.</NowrapGray>
                </CustomTableCell>
                <CustomTableCell />
                <CustomTableCell />
              </TableRow>
            </TableHead>
            {props.cargoReports.length ? (
              <TableBody>
                {reportList.map((report) => {
                  const key = report.id || report.reportId;
                  const data = report.draftForm;
                  if (!data) return <></>;
                  return (
                    <TableRow key={key}>
                      <CustomTableCell>
                        {!props.isServerData ? (
                          <Link
                            aria-disabled={true}
                            color="#20BE89"
                            sx={{ fontSize: 16 }}
                            onClick={() =>
                              key &&
                              navigate(pageData.crewCargoDetail.path(key))
                            }
                          >
                            {key}
                          </Link>
                        ) : (
                          <span>{report.reportId}</span>
                        )}
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {report.isDraft ? (
                            <span style={{ color: "#FFC700" }}>Draft</span>
                          ) : (
                            "Fixed"
                          )}
                        </Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {report.isSend ? (
                            "Sent"
                          ) : (
                            <span style={{ color: "#FFC700" }}>Unsent</span>
                          )}
                        </Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{data?.C_Voyage_No}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{data?.C_EU_UK_port}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {formatToUtcYYYYMMDDHHMM(data.C_DateTime)}
                        </Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {getTimeZone(
                            data.C_TimeZone_PorN,
                            data.C_TimeZone_HH,
                            data.C_TimeZone_MM
                          )}
                        </Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {getLatLong(
                            report.draftForm?.C_Lat_Deg,
                            report.draftForm?.C_Lat_Min,
                            report.draftForm?.C_Lat_Direction,
                            report.draftForm?.C_Long_Deg,
                            report.draftForm?.C_Long_Min,
                            report.draftForm?.C_Long_Direction
                          )}
                        </Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        {props.isServerData ? (
                          <IconButton
                            size="small"
                            disabled={false}
                            data-testid={`update-${key}`}
                            sx={{
                              height: "29px",
                              width: "38px",
                              my: 0.1,
                              color: "#AAAAAA",
                              backgroundColor: "#555860",
                              borderRadius: "10%",
                              "&.Mui-disabled": {
                                color: "#555555",
                                backgroundColor: "#36383F",
                              },
                              boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                            }}
                            onClick={() => {
                              navigate(
                                pageData.serverUpdateCargo.path(
                                  String(report.reportId)
                                )
                              );
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            disabled={report.isSend}
                            data-testid={`update-${key}`}
                            sx={{
                              height: "29px",
                              width: "38px",
                              my: 0.1,
                              color: "#AAAAAA",
                              backgroundColor: "#555860",
                              borderRadius: "10%",
                              "&.Mui-disabled": {
                                color: "#555555",
                                backgroundColor: "#36383F",
                              },
                              boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                            }}
                            onClick={() => {
                              key &&
                                navigate(pageData.crewCargoUpdate.path(key));
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </IconButton>
                        )}
                      </CustomTableCell>
                      <CustomTableCell>
                        <IconButton
                          size="small"
                          disabled={report.isSend}
                          data-testid={`delete-${key}`}
                          sx={{
                            height: "28px",
                            width: "40px",
                            my: 0.1,
                            color: "#AAAAAA",
                            backgroundColor: "#555860",
                            borderRadius: "10%",
                            boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                            "&.Mui-disabled": {
                              color: "#555555",
                              backgroundColor: "#36383F",
                            },
                          }}
                          onClick={() => {
                            setIsModalOpen(true);
                            setModalData(report);
                          }}
                        >
                          <DeleteOutlinedIcon fontSize="small" />
                        </IconButton>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <></>
            )}
          </Table>
          {props.isServerData ? (
            <Box sx={{ px: 1, py: 1 }}>
              <Pagination
                count={Math.ceil(
                  props.totalPageCount / props.form.pagination.limit
                )}
                defaultPage={1}
                page={props.form.pagination.page}
                onChange={() => onChangePage}
              />
            </Box>
          ) : (
            <Box sx={{ px: 1, py: 1 }}>
              <Pagination
                count={Math.ceil(
                  props.cargoReports.length / SEARCH_PAGE_LIMIT_CREW
                )}
                page={page}
                defaultPage={1}
                onChange={() => onCangeFrontPage}
              />
            </Box>
          )}
        </Box>

        <DeleteModal
          isOpen={modalOpen}
          setIsModalOpen={setIsModalOpen}
          deleteFrontData={props.deleteReport}
          id={modalData?.id !== undefined ? modalData.id : 0}
          voy={
            modalData && modalData.draftForm?.C_Voyage_No !== undefined
              ? modalData?.draftForm.C_Voyage_No
              : ""
          }
          DateTime={
            (modalData &&
              modalData.draftForm?.C_DateTime &&
              formatToUtcYYYYMMDDHHMM(modalData?.draftForm.C_DateTime)) ||
            ""
          }
        />
      </Paper>
    </React.Fragment>
  );
};

export default Cargo;
