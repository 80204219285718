import dayjs from "dayjs";
import {
  ArrivalListInput,
  ArrivalListQuery,
  useArrivalListLazyQuery,
} from "../../gen/graphql/types";
import { useMemo } from "react";
import { Arrival } from "../../components/pages/Crew/Arrival/Create/types";
import { ReportSearchCondition } from "../../components/pages/pages.module";

const getArrivalList = (reports?: ArrivalListQuery): Arrival[] => {
  const reportList = (reports?.arrivalList?.arrivalList || [])
    .map((arrival): Arrival | null => arrival)
    .filter((v): v is NonNullable<typeof v> => v != null)
    .sort((a, b) =>
      dayjs(b.A_Arrival_Date_Time).diff(dayjs(a.A_Arrival_Date_Time))
    );

  return reportList;
};

export const mapArrivalListInput = (
  searchCondition: ReportSearchCondition
): ArrivalListInput => {
  return {
    ship_id: searchCondition?.ship_id || null,
    IMO_No: searchCondition.imo_no || null,
    A_Voy: searchCondition?.Voy,
    From_DateTime: searchCondition?.From_DateTime,
    To_DateTime: searchCondition?.To_DateTime,
    pagination: searchCondition.pagination,
  };
};

export const useArrivals = () => {
  const [getData, { data: response, loading: isLoading }] =
    useArrivalListLazyQuery();

  const data = useMemo(() => {
    const totalPageCount = response?.arrivalList?.totalPageCount || 0;
    const arrivalList = getArrivalList(response);

    return { totalPageCount, arrivalList: arrivalList };
  }, [response]);

  const refetch = (searchCondition: ReportSearchCondition) => {
    const inputdata = mapArrivalListInput(searchCondition);
    getData({
      variables: {
        input: {
          ...inputdata,
          pagination: {
            page: inputdata.pagination?.page,
            limit: inputdata.pagination?.limit,
          },
        },
      },
    });
  };

  return {
    isLoading,
    data,
    refetch,
  };
};
