import * as React from "react";
import Paper from "@mui/material/Paper";
import {
  Link,
  Pagination,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Nowrap from "../../../atoms/Nowrap";
import { formatToUtcYYYYMMDDHHMM } from "../../../../utils/formats";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import NowrapGray from "../../../atoms/NowrapGray";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CustomTableCell from "../../../atoms/CustomTableCell";
import SucsessButton from "../../../atoms/SucsessButton";
import { useNavigate } from "react-router";
import { useState } from "react";
import { DepartureDexie } from "../../../../dexie/Departure";
import DeleteModalDepature from "../../../atoms/DeleteModalDepature";
import { pageData } from "../../../../App";
import { useAuth0 } from "@auth0/auth0-react";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import GrayButton from "../../../atoms/GrayButton";
import UtcDateTimePicker from "../../../atoms/UtcDateTimePicker";
import { ReportSearchCondition } from "../../../pages/pages.module";
import { SEARCH_PAGE_LIMIT_CREW } from "../../../pages/Crew/crew.module";

type ConfirmProps = {
  reports: DepartureDexie[];
  deleteReport(id: number): void;
  form: ReportSearchCondition;
  onUpdateForm(value: ReportSearchCondition): void;
  totalPageCount: number;
  isServerData: boolean;
  onSearch: () => void;
  onUpdateSearchCondition(
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ): void;
};

const Departure = (props: ConfirmProps): React.ReactElement => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [modalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<DepartureDexie>();

  const [page, setPage] = useState<number>(1);

  const onChange = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    const form = {
      ...props.form,
      [key]: value,
    };

    props.onUpdateForm?.(form);
  };

  const onCangeFrontPage = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
    if (!value) {
      return;
    }

    props.form.pagination.page = value;

    props.onUpdateForm?.(props.form);
    props.onUpdateSearchCondition(props.form, true);
  };

  const onClickSeach = () => {
    props.onSearch();
  };

  const reportList = !props.isServerData
    ? SEARCH_PAGE_LIMIT_CREW > 0
      ? props.reports.slice(
          page * SEARCH_PAGE_LIMIT_CREW - SEARCH_PAGE_LIMIT_CREW,
          page * SEARCH_PAGE_LIMIT_CREW
        )
      : props.reports
    : props.reports;

  return (
    <React.Fragment>
      <Paper
        sx={{
          backgroundColor: "#27292E",
          borderRadius: "15px",
          overflowY: "hidden",
          ".container::-webkit-scrollbar": {
            display: "none",
          },
          mb: 3,
        }}
      >
        <Columns spaceBetween sx={{ pt: 3, mx: 2, fontSize: 24 }}>
          <Column sx={{ color: "#FFFFFF", pt: 0, pl: 0 }}>
            Departure Report List
          </Column>
          <SucsessButton
            ButtonName={"Create Report"}
            disabled={false}
            onClick={() => navigate("/departure/create")}
            sx={{ mt: 0, fontSize: 16 }}
          />
        </Columns>
        {isAuthenticated && (
          <>
            <Columns sx={{ pb: 2 }}>
              <Column sx={{ ml: 2 }}>
                <CommonTextFIeld
                  id={"Voy"}
                  label={"Voy#"}
                  value={props.form.Voy}
                  onChange={(e: string | null) => {
                    onChange("Voy", e);
                  }}
                  width={"130px"}
                />
              </Column>
              <Column>
                <Typography
                  sx={{
                    pt: 1,
                    textAlign: "left",
                    verticalAlign: "middle",
                    fontSize: "16px",
                    fontFamily: "Public Sans",
                    lineHeight: "auto",
                    color: "#aaaaaa",
                  }}
                >
                  From
                </Typography>
              </Column>
              <Column>
                <UtcDateTimePicker
                  id={"From_DateTime"}
                  label={"Date & Time"}
                  value={props.form.From_DateTime}
                  onChange={onChange}
                  onChangeKey={"From_DateTime"}
                  hasMinDate={false}
                />
              </Column>
              <Column>
                <Typography
                  sx={{
                    pt: 1,
                    textAlign: "left",
                    verticalAlign: "middle",
                    fontSize: "16px",
                    fontFamily: "Public Sans",
                    lineHeight: "auto",
                    color: "#aaaaaa",
                  }}
                >
                  To
                </Typography>
              </Column>
              <Column>
                <UtcDateTimePicker
                  id={"To_DateTime"}
                  label={"Date & Time"}
                  value={props.form.To_DateTime}
                  onChange={onChange}
                  onChangeKey={"To_DateTime"}
                  hasMinDate={false}
                />
              </Column>

              <Column>
                <GrayButton
                  ButtonName={"Search"}
                  disabled={false}
                  width={"100px"}
                  onClick={() => onClickSeach()}
                />
              </Column>
            </Columns>
          </>
        )}
        <Box sx={{ px: 2 }}>
          <Table size="small" sx={{ mx: 0, color: "#FFFFFF" }}>
            <TableHead>
              <TableRow>
                <CustomTableCell>
                  <NowrapGray>ID</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>Type</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>Status</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>Voy#</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>L or B</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>Departure Port</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>{`Date & Time`}</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>Time Zone</NowrapGray>
                </CustomTableCell>
                <CustomTableCell />
                <CustomTableCell />
              </TableRow>
            </TableHead>
            {props.reports.length ? (
              <TableBody>
                {reportList.map((departure) => {
                  const key = departure.id || departure.departurId;
                  const data = departure.data;
                  return (
                    <TableRow key={key}>
                      <CustomTableCell>
                        {!props.isServerData ? (
                          <Link
                            aria-disabled={true}
                            color="#20BE89"
                            sx={{ fontSize: 16 }}
                            onClick={() =>
                              navigate(
                                pageData.crewDepartureDetail.path(String(key))
                              )
                            }
                          >
                            {key}
                          </Link>
                        ) : (
                          <span>{departure.departurId}</span>
                        )}
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {departure.isDraft ? (
                            <span style={{ color: "#FFC700" }}>Draft</span>
                          ) : (
                            "Fixed"
                          )}
                        </Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {departure.isSend ? (
                            "Sent"
                          ) : (
                            <span style={{ color: "#FFC700" }}>Unsent</span>
                          )}
                        </Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{data.D_Voy}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{data.D_LorB}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{data.D_Departure_Port}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {formatToUtcYYYYMMDDHHMM(data.D_Departure_Date_Time)}
                        </Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{data.D_Time_Zone}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        {props.isServerData ? (
                          <IconButton
                            aria-label="delete"
                            size="small"
                            disabled={false}
                            sx={{
                              height: "29px",
                              width: "38px",
                              my: 0.1,
                              color: "#AAAAAA",
                              backgroundColor: "#555860",
                              borderRadius: "10%",
                              "&.Mui-disabled": {
                                color: "#555555",
                                backgroundColor: "#36383F",
                              },
                              boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                            }}
                            onClick={() => {
                              navigate(
                                pageData.serverUpdateDeparture.path(
                                  String(departure.departurId)
                                )
                              );
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="delete"
                            size="small"
                            disabled={departure.isSend}
                            sx={{
                              height: "29px",
                              width: "38px",
                              my: 0.1,
                              color: "#AAAAAA",
                              backgroundColor: "#555860",
                              borderRadius: "10%",
                              "&.Mui-disabled": {
                                color: "#555555",
                                backgroundColor: "#36383F",
                              },
                              boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                            }}
                            onClick={() => {
                              navigate(`/departure/update/${key}`);
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </IconButton>
                        )}
                      </CustomTableCell>

                      <CustomTableCell>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          disabled={departure.isSend}
                          sx={{
                            height: "28px",
                            width: "40px",
                            my: 0.1,
                            color: "#AAAAAA",
                            backgroundColor: "#555860",
                            borderRadius: "10%",
                            "&.Mui-disabled": {
                              color: "#555555",
                              backgroundColor: "#36383F",
                            },
                            boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                          }}
                          onClick={() => {
                            setIsModalOpen(true);
                            setModalData(departure);
                          }}
                        >
                          <DeleteOutlinedIcon fontSize="small" />
                        </IconButton>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <></>
            )}
          </Table>
          {props.isServerData ? (
            <Box sx={{ px: 1, py: 1 }}>
              <Pagination
                count={Math.ceil(
                  props.totalPageCount / props.form.pagination.limit
                )}
                defaultPage={1}
                page={props.form.pagination.page}
                onChange={onChangePage}
              />
            </Box>
          ) : (
            <Box sx={{ px: 1, py: 1 }}>
              <Pagination
                count={Math.ceil(props.reports.length / SEARCH_PAGE_LIMIT_CREW)}
                page={page}
                defaultPage={1}
                onChange={onCangeFrontPage}
              />
            </Box>
          )}
        </Box>

        <DeleteModalDepature
          isOpen={modalOpen}
          setIsModalOpen={setIsModalOpen}
          deleteFrontData={props.deleteReport}
          id={modalData?.id !== undefined ? modalData.id : 0}
          voy={modalData?.data.D_Voy !== undefined ? modalData?.data.D_Voy : ""}
          DateTime={formatToUtcYYYYMMDDHHMM(
            modalData?.data.D_Departure_Date_Time
          )}
        />
      </Paper>
    </React.Fragment>
  );
};

export default Departure;
