import * as React from "react";
import { Typography } from "@mui/material";

const Optional = (): React.ReactElement => {
  return (
    <Typography
      sx={{
        textAlign: "right",
        verticalAlign: "top",
        fontSize: "10px",
        fontFamily: "Public Sans",
        lineHeight: "auto",
        color: "#aaaaaa",
        fontStyle: "oblique",
      }}
    >
      Optional
    </Typography>
  );
};

export default Optional;
