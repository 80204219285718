import { Arrival } from "../../../../gen/graphql/types";
import {
  formatToCsvString,
  genSearchConditionHash,
} from "../../../../utils/download-csv-util";
import { formatToUtcYYYYMMDDHHMM, formatToYYYYMMDDHHMM } from "../../../../utils/formats";
import { ReportSearchCondition } from "../../pages.module";

type CsvArrival = Omit<
  Arrival,
  "created_at" | "updated_at" | "ship_id" | "ship" | "user" | "user_id"
>;

export const genCsv = (
  searchCondition: ReportSearchCondition,
  arrivalList: CsvArrival[]
) => {
  const genFileName = async () => {
    const hashed = await genSearchConditionHash(searchCondition).catch((e) => {
      throw e;
    });
    const formatted = formatToYYYYMMDDHHMM(new Date(), "-", "", "");
    return `Arrival${formatted}-${hashed}.csv`;
  };

  const genText = () => {
    const head = [
      '"Report ID"',
      '"Vessel Name"',
      '"Voyage number"',
      '"L or B"',
      '"Port name"',
      '"UNLOC"',
      '"EU/UK"',
      '"Port L/U"',
      '"Arrival Date & Time (Local)"',
      '"S/B or Stop Date & Time (Local)"',
      '"Time Zone (UTC±)"',
      '"Berthed (Local)"',
      '"ETB"',
      '"ETC"',
      '"ETD"',
      '"ROB at Arrival FO (mt)"',
      '"ROB at Arrival MGO (mt)"',
      '"ROB at Arrival MECO (L)"',
      '"ROB at Arrival MESO (L)"',
      '"ROB at Arrival GESO (L)"',
      '"ROB at Arrival FW (mt)"',
      '"Hours since last noon report (hour)"',
      '"Distance travelled over ground (nautical mile)"',
      '"FO Consumption per day M/E (mt)"',
      '"FO Consumption per day G/E (mt)"',
      '"FO Consumption per day Boiler & Incinerator (mt)"',
      '"MGO Consumption per day M/E (mt)"',
      '"MGO Consumption per day G/E (mt)"',
      '"MGO Consumption per day Boiler & Incinerator (mt)"',
      '"ROB FO (mt)"',
      '"ROB MGO (mt)"',
      '"ROB MECO (L)"',
      '"ROB MESO (L)"',
      '"ROB GESO (L)"',
      '"ROB FW (mt)"',
      '"ROB Fuel Type 1"',
      '"ROB Fuel Volume 1"',
      '"ROB Fuel Type 2"',
      '"ROB Fuel Volume 2"',
      '"ROB Fuel Type 3"',
      '"ROB Fuel Volume 3"',
      '"ROB Fuel Type 4"',
      '"ROB Fuel Volume 4"',
      '"ROB Fuel Type 5"',
      '"ROB Fuel Volume 5"',
      '"ROB Fuel Type 6"',
      '"ROB Fuel Volume 6"',
      '"ROB Fuel Type 7"',
      '"ROB Fuel Volume 7"',
      '"ROB Fuel Type 8"',
      '"ROB Fuel Volume 8"',
      '"ROB Fuel Type 9"',
      '"ROB Fuel Volume 9"',
      '"ROB Fuel Type 10"',
      '"ROB Fuel Volume 10"',
      '"Draft Fore (m)"',
      '"Draft Aft (m)"',
      '"FO CO2 emission"',
      '"MGO CO2 emission"',
      '"Remark"',
      '"Spare 1"',
      '"Spare 1 unit name"',
      '"Spare 2"',
      '"Spare 2 unit name"',
      '"Spare 3"',
      '"Spare 3 unit name"',
      '"Spare 4"',
      '"Spare 4 unit name"',
      '"Spare 5"',
      '"Spare 5 unit name"',
      '"Spare 6"',
      '"Spare 6 unit name"',
      '"Spare 7"',
      '"Spare 7 unit name"',
      '"Spare 8"',
      '"Spare 8 unit name"',
      '"Spare 9"',
      '"Spare 9 unit name"',
      '"Spare 10"',
      '"Spare 10 unit name"',
      '"Spare 11"',
      '"Spare 11 unit name"',
      '"Spare 12"',
      '"Spare 12 unit name"',
      '"Spare 13"',
      '"Spare 13 unit name"',
      '"Spare 14"',
      '"Spare 14 unit name"',
      '"Spare 15"',
      '"Spare 15 unit name"',
      '"Spare 16"',
      '"Spare 16 unit name"',
      '"Spare 17"',
      '"Spare 17 unit name"',
      '"Spare 18"',
      '"Spare 18 unit name"',
      '"Spare 19"',
      '"Spare 19 unit name"',
      '"Spare 20"',
      '"Spare 20 unit name"',
    ].join(",");
    const body = arrivalList.map((report) => {
      const report_id = report.id;
      const A_Vessel_Name = formatToCsvString(report.A_Vessel_Name);
      const A_Voy = formatToCsvString(report.A_Voy);
      const A_LorB = formatToCsvString(report.A_LorB);

      const A_Arrival_Date_Time = formatToUtcYYYYMMDDHHMM(
        report.A_Arrival_Date_Time
      );
      const A_SB_or_Stop = formatToUtcYYYYMMDDHHMM(report.A_SB_or_Stop);
      const A_Time_Zone = formatToCsvString(report.A_Time_Zone);
      const A_Arrival_Port = formatToCsvString(report.A_Arrival_Port);
      const A_UNLOC = formatToCsvString(report.A_UNLOC);
      const A_EU_UK_port = report.A_EU_UK_port;
      const A_Port_L_U = report.A_Port_L_U;
      const A_ETB = formatToUtcYYYYMMDDHHMM(report.A_ETB);
      const A_ETC = formatToUtcYYYYMMDDHHMM(report.A_ETC);
      const A_ETD = formatToUtcYYYYMMDDHHMM(report.A_ETD);
      const A_Berthed = formatToUtcYYYYMMDDHHMM(report.A_Berthed);
      const A_ROB_at_Arrival_FO = report.A_ROB_at_Arrival_FO;
      const A_ROB_at_Arrival_MGO = report.A_ROB_at_Arrival_MGO;
      const A_ROB_at_Arrival_MECO = report.A_ROB_at_Arrival_MECO;
      const A_ROB_at_Arrival_MESO = report.A_ROB_at_Arrival_MESO;
      const A_ROB_at_Arrival_GESO = report.A_ROB_at_Arrival_GESO;
      const A_ROB_at_Arrival_FW = report.A_ROB_at_Arrival_FW;
      const A_Hrs_since_Last_Noon = report.A_Hrs_since_Last_Noon;
      const A_Distance_by_OG = report.A_Distance_by_OG;
      const A_FO_Consumption_per_day_ME = report.A_FO_Consumption_per_day_ME;
      const A_FO_Consumption_per_day_GE = report.A_FO_Consumption_per_day_GE;
      const A_FO_Consumption_per_day_B_and_Inc =
        report.A_FO_Consumption_per_day_B_and_Inc;
      const A_MGO_Consumption_per_day_ME = report.A_MGO_Consumption_per_day_ME;
      const A_MGO_Consumption_per_day_GE = report.A_MGO_Consumption_per_day_GE;
      const A_MGO_Consumption_per_day_B_and_Inc =
        report.A_MGO_Consumption_per_day_B_and_Inc;
      const A_ROB_FO = report.A_ROB_FO;
      const A_ROB_MGO = report.A_ROB_MGO;
      const A_ROB_MECO = report.A_ROB_MECO;
      const A_ROB_MESO = report.A_ROB_MESO;
      const A_ROB_GESO = report.A_ROB_GESO;
      const A_ROB_FW = report.A_ROB_FW;
      const All_Additional_ROB_csv_data: string[][] = [];
      const All_Additional_ROB_Arr = report.A_Additional_ROB || [];

      for (let i = 0; i < 10; i++) {
        const dataAtIndex = All_Additional_ROB_Arr
          ? All_Additional_ROB_Arr[i]
          : false;

        if (dataAtIndex) {
          All_Additional_ROB_csv_data.push([
            dataAtIndex.A_Fuel_Type as string,
            dataAtIndex.A_Fuel_volume
              ? dataAtIndex.A_Fuel_volume.toString()
              : "-",
          ]);
        } else {
          All_Additional_ROB_csv_data.push(["", ""]);
        }
      }
      const A_Draft_Fore = report.A_Draft_Fore;
      const A_Draft_Aft = report.A_Draft_Aft;
      const A_FO_CO2_emission = report.A_FO_CO2_emission;
      const A_MGO_CO2_emission = report.A_MGO_CO2_emission;
      const A_Remark = formatToCsvString(report.A_Remark);
      const A_Spare_1 = formatToCsvString(report.A_Spare_1);
      const A_Spare_1_unit_name = formatToCsvString(
        report.A_Spare_1_unit_name
      );
      const A_Spare_2 = formatToCsvString(report.A_Spare_2);
      const A_Spare_2_unit_name = formatToCsvString(
        report.A_Spare_2_unit_name
      );
      const A_Spare_3 = formatToCsvString(report.A_Spare_3);
      const A_Spare_3_unit_name = formatToCsvString(
        report.A_Spare_3_unit_name
      );
      const A_Spare_4 = formatToCsvString(report.A_Spare_4);
      const A_Spare_4_unit_name = formatToCsvString(
        report.A_Spare_4_unit_name
      );
      const A_Spare_5 = formatToCsvString(report.A_Spare_5);
      const A_Spare_5_unit_name = formatToCsvString(
        report.A_Spare_5_unit_name
      );
      const A_Spare_6 = formatToCsvString(report.A_Spare_6);
      const A_Spare_6_unit_name = formatToCsvString(
        report.A_Spare_6_unit_name
      );
      const A_Spare_7 = formatToCsvString(report.A_Spare_7);
      const A_Spare_7_unit_name = formatToCsvString(
        report.A_Spare_7_unit_name
      );
      const A_Spare_8 = formatToCsvString(report.A_Spare_8);
      const A_Spare_8_unit_name = formatToCsvString(
        report.A_Spare_8_unit_name
      );
      const A_Spare_9 = formatToCsvString(report.A_Spare_9);
      const A_Spare_9_unit_name = formatToCsvString(
        report.A_Spare_9_unit_name
      );
      const A_Spare_10 = formatToCsvString(report.A_Spare_10);
      const A_Spare_10_unit_name = formatToCsvString(
        report.A_Spare_10_unit_name
      );
      const A_Spare_11 = formatToCsvString(report.A_Spare_11);
      const A_Spare_11_unit_name = formatToCsvString(
        report.A_Spare_11_unit_name
      );
      const A_Spare_12 = formatToCsvString(report.A_Spare_12);
      const A_Spare_12_unit_name = formatToCsvString(
        report.A_Spare_12_unit_name
      );
      const A_Spare_13 = formatToCsvString(report.A_Spare_13);
      const A_Spare_13_unit_name = formatToCsvString(
        report.A_Spare_13_unit_name
      );
      const A_Spare_14 = formatToCsvString(report.A_Spare_14);
      const A_Spare_14_unit_name = formatToCsvString(
        report.A_Spare_14_unit_name
      );
      const A_Spare_15 = formatToCsvString(report.A_Spare_15);
      const A_Spare_15_unit_name = formatToCsvString(
        report.A_Spare_15_unit_name
      );
      const A_Spare_16 = formatToCsvString(report.A_Spare_16);
      const A_Spare_16_unit_name = formatToCsvString(
        report.A_Spare_16_unit_name
      );
      const A_Spare_17 = formatToCsvString(report.A_Spare_17);
      const A_Spare_17_unit_name = formatToCsvString(
        report.A_Spare_17_unit_name
      );
      const A_Spare_18 = formatToCsvString(report.A_Spare_18);
      const A_Spare_18_unit_name = formatToCsvString(
        report.A_Spare_18_unit_name
      );
      const A_Spare_19 = formatToCsvString(report.A_Spare_19);
      const A_Spare_19_unit_name = formatToCsvString(
        report.A_Spare_19_unit_name
      );
      const A_Spare_20 = formatToCsvString(report.A_Spare_20);
      const A_Spare_20_unit_name = formatToCsvString(
        report.A_Spare_20_unit_name
      );
      return [
        report_id,
        A_Vessel_Name,
        A_Voy,
        A_LorB,
        A_Arrival_Port,
        A_UNLOC,
        A_EU_UK_port,
        A_Port_L_U,
        A_Arrival_Date_Time,
        A_SB_or_Stop,
        A_Time_Zone,
        A_Berthed,
        A_ETB,
        A_ETC,
        A_ETD,
        A_ROB_at_Arrival_FO,
        A_ROB_at_Arrival_MGO,
        A_ROB_at_Arrival_MECO,
        A_ROB_at_Arrival_MESO,
        A_ROB_at_Arrival_GESO,
        A_ROB_at_Arrival_FW,
        A_Hrs_since_Last_Noon,
        A_Distance_by_OG,
        A_FO_Consumption_per_day_ME,
        A_FO_Consumption_per_day_GE,
        A_FO_Consumption_per_day_B_and_Inc,
        A_MGO_Consumption_per_day_ME,
        A_MGO_Consumption_per_day_GE,
        A_MGO_Consumption_per_day_B_and_Inc,
        A_ROB_FO,
        A_ROB_MGO,
        A_ROB_MECO,
        A_ROB_MESO,
        A_ROB_GESO,
        A_ROB_FW,
        All_Additional_ROB_csv_data.flatMap((v) => v),
        A_Draft_Fore,
        A_Draft_Aft,
        A_FO_CO2_emission,
        A_MGO_CO2_emission,
        A_Remark,
        A_Spare_1,
        A_Spare_1_unit_name,
        A_Spare_2,
        A_Spare_2_unit_name,
        A_Spare_3,
        A_Spare_3_unit_name,
        A_Spare_4,
        A_Spare_4_unit_name,
        A_Spare_5,
        A_Spare_5_unit_name,
        A_Spare_6,
        A_Spare_6_unit_name,
        A_Spare_7,
        A_Spare_7_unit_name,
        A_Spare_8,
        A_Spare_8_unit_name,
        A_Spare_9,
        A_Spare_9_unit_name,
        A_Spare_10,
        A_Spare_10_unit_name,
        A_Spare_11,
        A_Spare_11_unit_name,
        A_Spare_12,
        A_Spare_12_unit_name,
        A_Spare_13,
        A_Spare_13_unit_name,
        A_Spare_14,
        A_Spare_14_unit_name,
        A_Spare_15,
        A_Spare_15_unit_name,
        A_Spare_16,
        A_Spare_16_unit_name,
        A_Spare_17,
        A_Spare_17_unit_name,
        A_Spare_18,
        A_Spare_18_unit_name,
        A_Spare_19,
        A_Spare_19_unit_name,
        A_Spare_20,
        A_Spare_20_unit_name,
      ].join(",");
    });

    return [head, ...body].join("\n");
  };

  return (async () => {
    const fileName = await genFileName().catch((e) => {
      throw e;
    });
    const text = genText();

    return {
      fileName,
      text,
    };
  })();
};
