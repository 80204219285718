import * as React from "react";
import { SxProps, Theme, Typography } from "@mui/material";

type Props = {
  lastData: string;
  unitName?: string;
  sx?: SxProps<Theme>;
};

const LastReportText = (props: Props): React.ReactElement => {
  const unitName = props.unitName ? " " + props.unitName : "";

  const data =
    props.lastData !== "" && props.lastData !== "undefined"
      ? "(" + props.lastData + unitName + ")"
      : "";

  return (
    <Typography
      sx={{
        px: 1,
        color: "#AAAAAA",
        lineHeight: 1,
        fontSize: "0.9rem",
        verticalalign: "middle",
        alignItems: "normal",
        ...props.sx,
      }}
    >
      {data}
    </Typography>
  );
};

export default LastReportText;
