import React from "react";
import Template from "../../../../templates/Crew/Arrival/Create/Complete";
import Default from "../../../../layouts/DefaultCrew";

const Complete = (): React.ReactElement => {
  return (
    <Default title="" selectedTitle={"Arrival"}>
      <Template />
    </Default>
  );
};

export default Complete;
