import Columns from "../../../../atoms/Columns";
import Column from "../../../../atoms/Column";
import { IconButton, Stack, Typography } from "@mui/material";
import CommonNumberFIeld from "../../../../atoms/CommonNumberFIeld";
import { MgoFuelType } from "../../../../../gen/graphql/types";

import CommonTextFIeld from "../../../../atoms/CommonTextFIeld";
import { NestedFormBlockProps } from "../FormBlock";
import {
  lightGray,
  darkGray,
  charcoalGray,
  midnightBlue,
} from "../../../../../utils/color";
import {
  additionalKeyStrings,
  createAdditionalArrays,
} from "../detailAdditionalConsumption.module";
import CommonSelect from "../../../../atoms/CommonSelect";
import Optional from "../../../../atoms/Optional";
import { useAdditonaConsumptionRowCounter } from "../../../../../utils/form-util";

export function MgoConsumption(props: NestedFormBlockProps) {
  const mgoAdditionalArrays = createAdditionalArrays(
    additionalKeyStrings.MGO,
    props.form
  );

  const { increment, decrement, additionalRows, maxAdditonaConsumptionIndex } =
    useAdditonaConsumptionRowCounter(mgoAdditionalArrays.titles.length);

  return (
    <>
      <Columns
        sx={{
          display: "flex",
          alignItems: "end",
        }}
      >
        <Column>
          <Typography
            sx={{
              textAlign: "left",
              verticalAlign: "top",
              fontSize: "18px",
              fontFamily: "Public Sans",
              lineHeight: "auto",
              color: lightGray,
              pl: 1,
              mb: -2,
            }}
          >
            MGO Consumption per day
          </Typography>
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Typography
            sx={{
              color: lightGray,
              pl: 2,
              pr: 2,
              width: "250px",
              textAlign: "left",
            }}
          >
            M/E (mt)
          </Typography>{" "}
        </Column>
        <Column>
          <CommonNumberFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="N_MGO_Consumption_per_day_ME"
            label="Volume"
            placeholder=""
            value={
              props.form.N_MGO_Consumption_per_day_ME === 0
                ? 0
                : props.form.N_MGO_Consumption_per_day_ME
            }
            unitName={"mt"}
            minValue={0}
            maxValue={999}
            onChange={(e: string | null) => {
              props.onChange("N_MGO_Consumption_per_day_ME", e);
            }}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Typography
            sx={{
              color: lightGray,
              pl: 2,
              pr: 2,
              width: "250px",
              textAlign: "left",
            }}
          >
            G/E (mt)
          </Typography>{" "}
        </Column>
        <Column>
          <CommonNumberFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="N_MGO_Consumption_per_day_GE"
            label="Volume"
            placeholder=""
            value={
              props.form.N_MGO_Consumption_per_day_GE === 0
                ? 0
                : props.form.N_MGO_Consumption_per_day_GE
            }
            unitName={"mt"}
            minValue={0}
            maxValue={999}
            onChange={(e: string | null) => {
              props.onChange("N_MGO_Consumption_per_day_GE", e);
            }}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Typography
            sx={{
              color: lightGray,
              pl: 2,
              pr: 2,
              width: "250px",
              textAlign: "left",
            }}
          >
            Boiler (mt)
          </Typography>{" "}
        </Column>
        <Column>
          <CommonNumberFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="N_MGO_Consumption_per_day_Boiler"
            label="Volume"
            placeholder=""
            value={
              props.form.N_MGO_Consumption_per_day_Boiler === 0
                ? 0
                : props.form.N_MGO_Consumption_per_day_Boiler
            }
            unitName={"mt"}
            minValue={0}
            maxValue={999}
            onChange={(e: string | null) => {
              props.onChange("N_MGO_Consumption_per_day_Boiler", e);
            }}
          />
        </Column>
      </Columns>
      <>
        {props.ship?.is_mrv_portal_integration_target &&
          additionalRows.map((_, index) => {
            const additionalLoopIndex = index + 1;
            const fuelTypeKey =
              additionalKeyStrings.MGO.fuelTypeKey +
              additionalLoopIndex.toString();
            const usageKey =
              additionalKeyStrings.MGO.usageKey +
              additionalLoopIndex.toString();
            const volumeKey =
              additionalKeyStrings.MGO.volumeKey +
              additionalLoopIndex.toString();

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const fuelTypeValue = (props.form as any)[fuelTypeKey] || "";
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const usageValue = (props.form as any)[usageKey] || "";
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const volumeValue = (props.form as any)[volumeKey] || "";

            return (
              <Columns key={additionalLoopIndex}>
                <Column sx={{ ml: "16px" }}>
                  <CommonSelect
                    id={fuelTypeKey}
                    label={"Fuel Type"}
                    key={fuelTypeKey}
                    value={fuelTypeValue}
                    onChange={function (value: string): void {
                      props.onChange(fuelTypeKey, value);
                    }}
                    selectValues={Object.values(MgoFuelType)}
                    isOptional
                  />
                </Column>
                <Column>
                  <Optional />
                  <CommonTextFIeld
                    disabled={props.isPreview}
                    id="usageKey"
                    label="Usage"
                    placeholder=""
                    value={usageValue}
                    onChange={(e: string | null) => {
                      props.onChange(usageKey, e);
                    }}
                    sx={{ width: "250px" }}
                  />
                </Column>
                <Column>
                  <Optional />
                  <CommonNumberFIeld
                    id={volumeKey}
                    label="Volume"
                    placeholder=""
                    value={volumeValue}
                    unitName={"mt"}
                    minValue={0}
                    maxValue={999}
                    onChange={(e: string | null) => {
                      props.onChange(volumeKey, e);
                    }}
                  />
                </Column>
                {additionalRows.length === additionalLoopIndex && (
                  <Stack direction={"row"} sx={{ pt: 1.8 }}>
                    <Column>
                      <IconButton
                        disabled={
                          !(fuelTypeValue && usageValue && volumeValue) ||
                          additionalLoopIndex >= maxAdditonaConsumptionIndex
                        }
                        aria-label="delete"
                        size="small"
                        sx={{
                          height: "40px",
                          width: "40px",
                          fontSize: "25px",
                          my: 0.1,
                          color: lightGray,
                          backgroundColor: darkGray,
                          borderRadius: "10%",
                          "&.Mui-disabled": {
                            color: charcoalGray,
                            backgroundColor: midnightBlue,
                          },
                          boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                        }}
                        onClick={() => increment()}
                      >
                        +
                      </IconButton>
                    </Column>
                    <Column>
                      <IconButton
                        disabled={additionalLoopIndex === 1}
                        aria-label="delete"
                        size="small"
                        sx={{
                          height: "40px",
                          width: "40px",
                          fontSize: "25px",
                          my: 0.1,
                          color: lightGray,
                          backgroundColor: darkGray,
                          borderRadius: "10%",
                          "&.Mui-disabled": {
                            color: charcoalGray,
                            backgroundColor: midnightBlue,
                          },
                          boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                        }}
                        onClick={() => {
                          props.onUpdateForm?.({
                            ...props.form,
                            [fuelTypeKey]: null,
                            [usageKey]: null,
                            [volumeKey]: null,
                          });

                          decrement();
                        }}
                      >
                        -
                      </IconButton>
                    </Column>
                  </Stack>
                )}
              </Columns>
            );
          })}
      </>
    </>
  );
}

export default MgoConsumption;
