import { Button, Divider, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Column from "../../../../atoms/Column";
import Columns from "../../../../atoms/Columns";
import Loading from "../../../../atoms/Loading";
import { useNavigate, useParams } from "react-router";
import { Props } from "../../../../pages/Crew/Departure/Create/types";
import Detail from "../../../../organisms/Crew/Departure/Detail";
import SucsessButton from "../../../../atoms/SucsessButton";
import { ArrivalDexie } from "../../../../../dexie/Arrival";

type ConfirmProps = Props & {
  isLoading: boolean;
  onUpdate(): Promise<void>;
  onBack(): void;
  reportId: number;
  previousArrival: ArrivalDexie | undefined;
};

const Confirm = (props: ConfirmProps): React.ReactElement => {
  const navigate = useNavigate();
  const { reportId } = useParams<"reportId">();

  const onClickSave = () => {
    props.onUpdate();
  };

  const onClickBack = () => {
    props.onBack();
  };

  return (
    <React.Fragment>
      <Loading isLoading={props.isLoading} />

      <Paper sx={{ backgroundColor: "#27292E", borderRadius: "20px" }}>
        <Detail
          isPreview={false}
          form={props.form}
          pageTitle={"Update Departure Report"}
          lastArrival={props.previousArrival}
          isSend={false}
          reportId={props.reportId}
        />
        <Divider textAlign="left"></Divider>

        <Box sx={{ p: 2 }}>
          <Columns>
            <Column>
              <SucsessButton
                ButtonName={"Save"}
                disabled={false}
                onClick={onClickSave}
              />
            </Column>

            <Column>
              <Button
                sx={{
                  mx: 2,
                  color: "#AAAAAA",
                  width: 150,
                  backgroundColor: "#555860",
                  textTransform: "none",
                }}
                variant="contained"
                disabled={false}
                onClick={() => {
                  navigate(`/departure/update/${reportId}`, { state: props.form });
                }}
              >
                {`Back & Edit`}
              </Button>
            </Column>
            <Column>
              <Button
                sx={{
                  mx: 2,
                  width: 150,
                  color: "#AAAAAA",
                  backgroundColor: "#555860 ",
                  textTransform: "none",
                }}
                variant="contained"
                disabled={false}
                onClick={() => onClickBack()}
              >
                Cancel
              </Button>
            </Column>
          </Columns>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default Confirm;
