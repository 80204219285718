import { orgRound } from "../../../../../utils/calculate";
import { isNanFinite } from "../../../../../utils/isNanFinite";

type Props = {
    N_Hrs_propelling: number | null;
    FO_CO2_emission_SUM: number;
    summer_deadweight: number;
    N_Speed_OG: number | null;
}

export function cal_N_FO_CO2_Emission_Per_Unit(props: Props) {
    if (props.N_Hrs_propelling === null ||  props.N_Speed_OG === null) {
        return 0;
    }

    if (props.summer_deadweight === 0) {
        return 0;
    }

    const N_FO_CO2_emission_per_unit = orgRound(
          (props.FO_CO2_emission_SUM * 3.114 * 1000000) / props.N_Hrs_propelling / (props.summer_deadweight * props.N_Speed_OG),
        1000
    );

    if (isNanFinite(N_FO_CO2_emission_per_unit)) {
        return 0;
    }

    return N_FO_CO2_emission_per_unit;
}