import React from "react";
import Box from "@mui/material/Box";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { Props } from "../../../pages/Crew/Bunkering/Create/types";
import { Link, Typography } from "@mui/material";
import CommonText from "../../../atoms/CommonText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomOwnerTableCell from "../../../atoms/CustomOwnerTableCell";
import {
  TableDataRow,
  Table4DataRow,
} from "../../../molecules/Owner/Detail/Detail";
import { formatToUtcYYYYMMDDHHMM } from "../../../../utils/formats";
import { getFileName, getLatLong } from "../../../../utils/form-util";
import { mediumGray, weightedGray } from "../../../../utils/color";
import { isNumeric } from "../../../../utils/type-checks";
import { useParams } from "react-router";
import { useDownloadBunkeringFile } from "./downloadFile.hook";

type FormBlockProps = Props & {
  pageTitle: string;
  isPreview: boolean;
};

const Detail = (props: FormBlockProps): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = isNumeric(reportId) ? Number(reportId) : 0;
  const downloadBunkeringFile = useDownloadBunkeringFile(report_id);

  return (
    <Box component="form" noValidate autoComplete="off">
      <Box sx={{ pt: 0.5, mx: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "top",
            justifycontent: "right",
            pb: 1,
          }}
        >
          <Column>
            <Typography
              component="h3"
              variant="subtitle1"
              align="center"
              sx={{
                lineHeight: 1,
                fontWeight: "bold",
                color: "#333333",
                fontSize: "1.5rem",
                verticalAlign: "super",
              }}
            >
              {props.pageTitle}
            </Typography>
          </Column>
        </Columns>
      </Box>
      <CommonText
        sx={{ color: "#FFFFFF", pl: 3, margin: "left", textAlign: "left" }}
      >
        {props.isPreview || `Confirm input data & click “Save” button.`}
      </CommonText>

      <Box sx={{ px: 2 }}>
        <Table
          color="inherit"
          sx={{ minWidth: 600, mx: 0, color: "#FFFFFF" }}
          aria-label="spanning table"
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "#FFFFFF", height: "20px" }}>
              <CustomOwnerTableCell align="center" colSpan={3}></CustomOwnerTableCell>
              <CustomOwnerTableCell />
              <CustomOwnerTableCell sx={{ color: "#AAAAAA", fontSize: "12px" }}></CustomOwnerTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableDataRow
              title={"Vessel Name"}
              data={props.form.B_Vessel_Name}
            />
            <TableDataRow
              title={"Voyage number"}
              data={props.form.B_Voy}
            />
            <TableDataRow
              title={"Time Zone (UTC±)"}
              data={
                String(props.form.B_TimeZone_PorN) +
                props.form.B_TimeZone_HH +
                ":" +
                props.form.B_TimeZone_MM
              }
            />
            <TableDataRow
              title={"EU/UK"}
              data={props.form.B_EUorUK}
            />
            <TableDataRow
              title={"Date & Time (Local)"}
              data={formatToUtcYYYYMMDDHHMM(props.form.B_Bunkering_Date_time)}
            />
            <TableDataRow
              title={"Lat./ Lon."}
              data={getLatLong(
                props.form.B_Lat_Deg,
                props.form.B_Lat_Min,
                props.form.B_Lat_Direction,
                props.form.B_Long_Deg,
                props.form.B_Long_Min,
                props.form.B_Long_Direction
              )}
            />
            <TableDataRow
              title={`L or U`}
              data={props.form.B_Port_LorU}
            />
            <TableDataRow
              title={`Port Name`}
              data={props.form.B_PortName}
            />
            <TableDataRow
              title={`UNLOC`}
              data={props.form.B_UNLOC}
            />
            <Table4DataRow 
              topTitle={"Fuel type 1"}
              title1={"Fuel name"}
              data1={props.form.B_Fuel_name_1}
              title2={"Mass (mt)"}
              data2={props.form.B_Mass_1}
              title3={"Volume (m3)"}
              data3={props.form.B_Volume_1}
              title4={"Density (mt/m3)"}
              data4={props.form.B_Density_1}
              unitName1={"mt"}
              unitName2={"m3"}
              unitName3={"mt/m3"}
            />
            <Table4DataRow 
              topTitle={"Fuel type 2"}
              title1={"Fuel name"}
              data1={props.form.B_Fuel_name_2}
              title2={"Mass (mt)"}
              data2={props.form.B_Mass_2}
              title3={"Volume (m3)"}
              data3={props.form.B_Volume_2}
              title4={"Density (mt/m3)"}
              data4={props.form.B_Density_2}
              unitName1={"mt"}
              unitName2={"m3"}
              unitName3={"mt/m3"}
            />
            <Table4DataRow 
              topTitle={"Fuel type 3"}
              title1={"Fuel name"}
              data1={props.form.B_Fuel_name_3}
              title2={"Mass (mt)"}
              data2={props.form.B_Mass_3}
              title3={"Volume (m3)"}
              data3={props.form.B_Volume_3}
              title4={"Density (mt/m3)"}
              data4={props.form.B_Density_3}
              unitName1={"mt"}
              unitName2={"m3"}
              unitName3={"mt/m3"}
            />
            <Table4DataRow 
              topTitle={"Fuel type 4"}
              title1={"Fuel name"}
              data1={props.form.B_Fuel_name_4}
              title2={"Mass (mt)"}
              data2={props.form.B_Mass_4}
              title3={"Volume (m3)"}
              data3={props.form.B_Volume_4}
              title4={"Density (mt/m3)"}
              data4={props.form.B_Density_4}
              unitName1={"mt"}
              unitName2={"m3"}
              unitName3={"mt/m3"}
            />
            <Table4DataRow 
              topTitle={"Fuel type 5"}
              title1={"Fuel name"}
              data1={props.form.B_Fuel_name_5}
              title2={"Mass (mt)"}
              data2={props.form.B_Mass_5}
              title3={"Volume (m3)"}
              data3={props.form.B_Volume_5}
              title4={"Density (mt/m3)"}
              data4={props.form.B_Density_5}
              unitName1={"mt"}
              unitName2={"m3"}
              unitName3={"mt/m3"}
            />
            <Table4DataRow 
              topTitle={"Fuel type 6"}
              title1={"Fuel name"}
              data1={props.form.B_Fuel_name_6}
              title2={"Mass (mt)"}
              data2={props.form.B_Mass_6}
              title3={"Volume (m3)"}
              data3={props.form.B_Volume_6}
              title4={"Density (mt/m3)"}
              data4={props.form.B_Density_6}
              unitName1={"mt"}
              unitName2={"m3"}
              unitName3={"mt/m3"}
            />
            <Table4DataRow 
              topTitle={"Fuel type 7"}
              title1={"Fuel name"}
              data1={props.form.B_Fuel_name_7}
              title2={"Mass (mt)"}
              data2={props.form.B_Mass_7}
              title3={"Volume (m3)"}
              data3={props.form.B_Volume_7}
              title4={"Density (mt/m3)"}
              data4={props.form.B_Density_7}
              unitName1={"mt"}
              unitName2={"m3"}
              unitName3={"mt/m3"}
            />
            <Table4DataRow 
              topTitle={"Fuel type 8"}
              title1={"Fuel name"}
              data1={props.form.B_Fuel_name_8}
              title2={"Mass (mt)"}
              data2={props.form.B_Mass_8}
              title3={"Volume (m3)"}
              data3={props.form.B_Volume_8}
              title4={"Density (mt/m3)"}
              data4={props.form.B_Density_8}
              unitName1={"mt"}
              unitName2={"m3"}
              unitName3={"mt/m3"}
            />
            <Table4DataRow 
              topTitle={"Fuel type 9"}
              title1={"Fuel name"}
              data1={props.form.B_Fuel_name_9}
              title2={"Mass (mt)"}
              data2={props.form.B_Mass_9}
              title3={"Volume (m3)"}
              data3={props.form.B_Volume_9}
              title4={"Density (mt/m3)"}
              data4={props.form.B_Density_9}
              unitName1={"mt"}
              unitName2={"m3"}
              unitName3={"mt/m3"}
            />
            <Table4DataRow 
              topTitle={"Fuel type 10"}
              title1={"Fuel name"}
              data1={props.form.B_Fuel_name_10}
              title2={"Mass (mt)"}
              data2={props.form.B_Mass_10}
              title3={"Volume (m3)"}
              data3={props.form.B_Volume_10}
              title4={"Density (mt/m3)"}
              data4={props.form.B_Density_10}
              unitName1={"mt"}
              unitName2={"m3"}
              unitName3={"mt/m3"}
            />
            <TableRow>
              <CustomOwnerTableCell sx={{ color: mediumGray, width: "250px" }}>
                {"FileName"}
              </CustomOwnerTableCell>
              <CustomOwnerTableCell />
              <CustomOwnerTableCell
                colSpan={2}
                sx={{
                  color: weightedGray,
                  wordBreak: "break-word",
                  whiteSpace: "pre-line",
                }}
              >
                {props.form.B_Filename && isNumeric(report_id) ? (
                  <Link onClick={() => downloadBunkeringFile.onDownload()}>
                    {getFileName(props.form.B_Filename)}
                  </Link>
                ) : (
                  "-"
                )}
              </CustomOwnerTableCell>
              <CustomOwnerTableCell
                sx={{ color: weightedGray }}
              ></CustomOwnerTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default Detail;
