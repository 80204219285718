import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import Default, { useShip } from "../../../../layouts/DefaultCrew";
import { pageData, useContextIDs } from "../../../../../App";
import Loading from "../../../../atoms/Loading";
import Template from "../../../../templates/Crew/Cargo/ServerUpdate";
import { CargoForm } from "../type";
import {
  mapToCreateCargoMutationVariables,
  useCargoForm,
} from "../cargo.module";
import {
  getLatLongValues,
  getTimeZoneValues,
} from "../../../../../utils/form-util";
import {
  Cargo,
  useCargoListQuery,
  useUpdateCargoMutation,
} from "../../../../../gen/graphql/types";
import CargoServerUpdateDetail from "../../../../templates/Crew/Cargo/ServerUpdate/CargoServerUpdateDetail";
import { uploadFile } from "../../../../../utils/uploadFile.module";
import useErrorMessageList from "../../../../../hooks/errorMessage.hook";

const mapToCargoForm = (
  cargoList?: (Cargo | null)[] | null
): CargoForm | undefined => {
  if (!cargoList || cargoList.length === 0 || !cargoList[0]) return;
  const cargo = cargoList[0];
  const timeZone = getTimeZoneValues(cargo.C_Timezone);
  const LatLong = getLatLongValues(cargo.C_Latitude + "/" + cargo.C_Longitude);

  return {
    ...cargo,
    C_TimeZone_PorN: timeZone.TimeZone_PorN,
    C_TimeZone_HH: timeZone.TimeZone_HH,
    C_TimeZone_MM: timeZone.TimeZone_MM,
    C_Lat_Direction: LatLong.Lat_Direction,
    C_Lat_Deg: LatLong.Lat_Deg,
    C_Lat_Min: LatLong.Lat_Min,
    C_Long_Direction: LatLong.Long_Direction,
    C_Long_Deg: LatLong.Long_Deg,
    C_Long_Min: LatLong.Long_Min,
    C_Loading_Mass: cargo.C_Loading_Mass || undefined,
    C_Loading_TEU_Full: cargo.C_Loading_TEU_Full || undefined,
    C_Loading_TEU_Empty: cargo.C_Loading_TEU_Empty || undefined,
    C_Loading_Unit: cargo.C_Loading_Unit || undefined,
    C_Loading_Lane_metres: cargo.C_Loading_Lane_metres || undefined,
    C_Loading_Number_of_Passengers:
      cargo.C_Loading_Number_of_Passengers || undefined,
    C_Loading_Volume: cargo.C_Loading_Volume || undefined,
    C_Unloading_Mass: cargo.C_Unloading_Mass || undefined,
    C_Unloading_TEU_Full: cargo.C_Unloading_TEU_Full || undefined,
    C_Unloading_TEU_Empty: cargo.C_Unloading_TEU_Empty || undefined,
    C_Unloading_Unit: cargo.C_Unloading_Unit || undefined,
    C_Unloading_Lane_metres: cargo.C_Unloading_Lane_metres || undefined,
    C_Unloading_Number_of_Passengers:
      cargo.C_Unloading_Number_of_Passengers || undefined,
    C_Unloading_Volume: cargo.C_Unloading_Volume || undefined,
    C_Total_Mass: cargo.C_Total_Mass || undefined,
    C_Total_TEU_Full: cargo.C_Total_TEU_Full || undefined,
    C_Total_TEU_Empty: cargo.C_Total_TEU_Empty || undefined,
    C_Total_Unit: cargo.C_Total_Unit || undefined,
    C_Total_Lane_metres: cargo.C_Total_Lane_metres || undefined,
    C_Total_Number_of_Passengers:
      cargo.C_Total_Number_of_Passengers || undefined,
    C_Total_Volume: cargo.C_Total_Volume || undefined,
    C_Displacement: cargo.C_Displacement || undefined,
    C_Water_density: cargo.C_Displacement || undefined,
    C_Total_ROB: cargo.C_Displacement || undefined,
    C_Filename: cargo.C_Filename || undefined,
  };
};

const ServerUpdate = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const { ship_id } = useContextIDs();
  const navigate = useNavigate();
  const ship = useShip();

  const [isConfirm, setIsConfirm] = useState(false);
  const [isDetail, setIsDetail] = useState(false);

  if (!report_id) navigate(pageData.crewCargo);

  const { data: reports, loading: isCargoListLoading } = useCargoListQuery({
    variables: {
      input: { id: report_id, ship_id: ship_id },
    },
    fetchPolicy: "network-only",
  });

  const { errorMessageList, addErrorMessage } = useErrorMessageList();

  const [updateCargo, { loading: isUpdateLoading }] = useUpdateCargoMutation();

  const form = useCargoForm([mapToCargoForm(reports?.cargoList?.cargoList)]);

  const [cargoFile, setFile] = useState<File | undefined>(undefined);

  const onUpdateForm = (value: CargoForm) => {
    form.update(value);
  };

  const onSave = () => {
    const variables = mapToCreateCargoMutationVariables(form.data, report_id);
    updateCargo({
      variables: { input: variables },
      onCompleted: (data) => {
        if (data.updateCargo?.isFileUpload && cargoFile) {
          uploadFile({
            remoteFilePath: data.updateCargo.gcsFilePath,
            fileContent: cargoFile,
          });
        }
      },
    });

    updateCargo({
      fetchPolicy: "network-only",
      variables: { input: variables },
      onCompleted: async (data) => {
        try {
          if (data.updateCargo?.isFileUpload && cargoFile) {
            const file = await uploadFile({
              remoteFilePath: data.updateCargo.gcsFilePath,
              fileContent: cargoFile,
            });
            if (!file.sucsess) {
              //ファイルアップロードに失敗した場合は、DBのファイル名を削除する。
              form.data.C_Filename = undefined;
              await updateCargo({
                fetchPolicy: "network-only",
                variables: { input: { ...variables, C_Filename: null } },
                onError: (error) => {
                  console.error(error);
                  addErrorMessage({
                    input: JSON.stringify(variables),
                    errorMessage: `Failed to delete file name. ${error}`,
                  });
                },
              });
              throw `File upload error. Data update succeeded. ${JSON.stringify(
                file
              )}`;
            }
          }
          navigate(pageData.crewCargoUpdateComplete);
        } catch (error) {
          console.error(error);
          addErrorMessage({
            input: JSON.stringify(variables),
            errorMessage: `Server Update error! ${error}`,
          });
        }
      },
      onError: (error) => {
        console.error(error);
        addErrorMessage({
          input: JSON.stringify(variables),
          errorMessage: `Server Update error! ${error}`,
        });
      },
    });
  };

  const onConfirm = () => {
    form.hasErrorScrollUp();
    setIsConfirm(true);
    if (form.hasError) return;
    setIsDetail(true);
  };

  return (
    <Default title="" selectedTitle={"Cargo"}>
      <Loading isLoading={isUpdateLoading} />
      {isDetail ? (
        <CargoServerUpdateDetail
          action={"Update"}
          form={form.data}
          onSave={onSave}
          setIsDetail={setIsDetail}
          errorMessageList={errorMessageList}
        />
      ) : !isCargoListLoading && !form.isLoading ? (
        <Template
          cargo={
            reports?.cargoList?.cargoList && reports?.cargoList?.cargoList[0]
              ? reports.cargoList.cargoList[0]
              : null
          }
          form={form.data}
          reportId={reportId || ""}
          hasFormError={form.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          ship={ship}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
          setFile={setFile}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Default>
  );
};

export default ServerUpdate;
