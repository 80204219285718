import { Box, Grid, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  weightedGray,
  paleGray,
  wedgwoodBlue,
} from "../../../../../utils/color";
import {
  AnalysisFilterType,
  GetAnalysisSearchParameterQuery,
} from "../../../../../gen/graphql/types";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { formatToYYYYMMDD } from "../../../../../utils/formats";

import { AnalysisSearchParameterModalTextFIeld } from "./AnalysisSearchParameterModalTextFIeld";
import {
  AnalysisSearchTitleProps,
  useDeleteAnalysisSearchParameter,
  useUpdateAnalysisSearchParameterTitle,
} from "./AnalysisSearchParameterModal.hook";
import Loading from "../../../../atoms/Loading";
import CloseIcon from "@mui/icons-material/Close";
import {
  DefaultFilterCondition,
  SpeedConsumptionFilterCondition,
} from "../../../../pages/Owner/Analysis/queries";
import { updateFilterForm } from "./AnalysisSearchParameterModal.module";
import { FilterTitleList } from "../../../../pages/Owner/Analysis/const";

type Props = {
  isOpen: boolean;
  setIsAnalysisSearchParameterModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  onUpdateFilterForm1(value: SpeedConsumptionFilterCondition): void;
  onUpdateFilterForm2(value: SpeedConsumptionFilterCondition): void;
  onUpdateFilterForm3(value: SpeedConsumptionFilterCondition): void;
  onUpdateFilterForm4(value: SpeedConsumptionFilterCondition): void;
  setCheckedMeRevolutionOver100: (title: string, value: boolean) => void;
  setAnalysisSearchParameterTitle: React.Dispatch<
    React.SetStateAction<AnalysisSearchTitleProps>
  >;
  loadDataAnalysisSearchParameter: (renderString: string) => Promise<void>;
  analysisSearchParameter: GetAnalysisSearchParameterQuery | undefined;
};

const GRID_ROW_XS_VALUES = {
  TOTAL: 12,
  R1: 1.2,
  R2: 2.2,
  R3: 7.4,
  R4: 1.2,
};

const HEADER_GRID_STYLE = {
  borderBottom: "1px solid #CBD5E1",
  color: wedgwoodBlue,
  fontWeight: "500",
  height: "30%",
  pb: 0.3,
};

const HEADER_TITLE_STYLE = {
  borderLeft: "2px solid #CBD5E1",
  height: 20,
  pl: 0.8,
};

const BODY_GRID_TEXT_STYLE = {
  pl: 0.5,
};

const BODY_GRID_ICON_STYLE = {
  py: 1.2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const BoxStyle = {
  color: weightedGray,
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 780,
  bgcolor: paleGray,
  boxShadow: 24,
  p: 2,
};

export function AnalysisSearchParameterModal(props: Props) {
  const handleClose = () => props.setIsAnalysisSearchParameterModal(false);
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const {
    updateTitleData,
    setUpdateTitleData,
    updateTitle,
    isLoading: updateLoading,
  } = useUpdateAnalysisSearchParameterTitle(
    props.loadDataAnalysisSearchParameter
  );

  const { deleteAnalysisSearchParameter, isDeleteLoading } =
    useDeleteAnalysisSearchParameter(props.loadDataAnalysisSearchParameter);

  useEffect(() => {
    props.loadDataAnalysisSearchParameter(
      props.analysisSearchParameter?.getAnalysisSearchParameter.length.toString() ||
        ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open={props.isOpen} onClose={handleClose}>
      <Box sx={BoxStyle}>
        <Loading isLoading={updateLoading || isDeleteLoading} />
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ pb: 2 }}
        >
          <Typography sx={{ fontSize: "24px" }}>
            {"Saved Individual Filters"}
          </Typography>
          <CloseIcon onClick={handleClose} />
        </Stack>
        <Grid
          container
          item
          xs={GRID_ROW_XS_VALUES.TOTAL}
          justifyContent={"center"}
          alignItems="center"
        >
          <Grid item xs={GRID_ROW_XS_VALUES.R1} sx={HEADER_GRID_STYLE}>
            <Box sx={HEADER_TITLE_STYLE}>View</Box>
          </Grid>

          <Grid
            item
            xs={GRID_ROW_XS_VALUES.R2}
            sx={HEADER_GRID_STYLE}
            alignItems="baseline"
          >
            <Box sx={HEADER_TITLE_STYLE}>Date</Box>
          </Grid>
          <Grid item xs={GRID_ROW_XS_VALUES.R3} sx={HEADER_GRID_STYLE}>
            <Box sx={HEADER_TITLE_STYLE}>Setting Name</Box>
          </Grid>
          <Grid
            item
            xs={GRID_ROW_XS_VALUES.R4}
            sx={HEADER_GRID_STYLE}
            alignItems="baseline"
          >
            <Box sx={HEADER_TITLE_STYLE}>Delete</Box>
          </Grid>

          {props.analysisSearchParameter?.getAnalysisSearchParameter?.length ===
            0 && <Typography sx={{ py: 3 }}>No Data</Typography>}

          {props.analysisSearchParameter?.getAnalysisSearchParameter?.map(
            (v) => {
              if (!v) return <></>;
              const AnalysisSearchParameterDetail =
                v.AnalysisSearchParameterDetail;
              return (
                <>
                  <Grid
                    item
                    xs={GRID_ROW_XS_VALUES.R1}
                    sx={BODY_GRID_ICON_STYLE}
                  >
                    <SearchIcon
                      fontSize="medium"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        props.setAnalysisSearchParameterTitle({
                          id: v.id,
                          title: v.title,
                        });

                        const detail1 = AnalysisSearchParameterDetail?.find(
                          (v) => v?.filter_type === AnalysisFilterType.Filter1
                        );
                        const detail2 = AnalysisSearchParameterDetail?.find(
                          (v) => v?.filter_type === AnalysisFilterType.Filter2
                        );
                        const detail3 = AnalysisSearchParameterDetail?.find(
                          (v) => v?.filter_type === AnalysisFilterType.Filter3
                        );
                        const detail4 = AnalysisSearchParameterDetail?.find(
                          (v) => v?.filter_type === AnalysisFilterType.Filter4
                        );

                        if (detail1) {
                          updateFilterForm(
                            FilterTitleList.Filter_1,
                            props.setCheckedMeRevolutionOver100,
                            props.onUpdateFilterForm1,
                            detail1
                          );
                        } else {
                          props.onUpdateFilterForm1(DefaultFilterCondition);
                        }

                        if (detail2) {
                          updateFilterForm(
                            FilterTitleList.Filter_2,
                            props.setCheckedMeRevolutionOver100,
                            props.onUpdateFilterForm2,
                            detail2
                          );
                        } else {
                          props.onUpdateFilterForm2(DefaultFilterCondition);
                        }

                        if (detail3) {
                          updateFilterForm(
                            FilterTitleList.Filter_3,
                            props.setCheckedMeRevolutionOver100,
                            props.onUpdateFilterForm3,
                            detail3
                          );
                        } else {
                          props.onUpdateFilterForm3(DefaultFilterCondition);
                        }

                        if (detail4) {
                          updateFilterForm(
                            FilterTitleList.Filter_4,
                            props.setCheckedMeRevolutionOver100,
                            props.onUpdateFilterForm4,
                            detail4
                          );
                        } else {
                          props.onUpdateFilterForm4(DefaultFilterCondition);
                        }
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={GRID_ROW_XS_VALUES.R2}
                    sx={BODY_GRID_TEXT_STYLE}
                  >
                    {formatToYYYYMMDD(v.created_at)}
                  </Grid>
                  <Grid
                    item
                    xs={GRID_ROW_XS_VALUES.R3}
                    sx={BODY_GRID_TEXT_STYLE}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      sx={{ width: 400 }}
                    >
                      {isEditing === v.id ? (
                        <AnalysisSearchParameterModalTextFIeld
                          id={`edit-${v.id}`}
                          label={""}
                          value={updateTitleData.title}
                          sx={{
                            "& .MuiInputBase-input": { color: weightedGray },
                          }}
                          onChange={(e) => {
                            setUpdateTitleData({ id: v.id, title: e });
                          }}
                          width={"130px"}
                          onUpdate={() => {
                            updateTitle();
                            setIsEditing(null);
                          }}
                        />
                      ) : (
                        <Box>{v.title}</Box>
                      )}
                      {isEditing !== v.id && (
                        <EditIcon
                          fontSize="medium"
                          onClick={() => {
                            setUpdateTitleData({ id: v.id, title: v.title });
                            setIsEditing(v.id);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={GRID_ROW_XS_VALUES.R4}
                    sx={BODY_GRID_ICON_STYLE}
                  >
                    <DeleteOutlinedIcon
                      fontSize="medium"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setUpdateTitleData({
                          id: v.id,
                          title: v.id.toString(),
                        });
                        deleteAnalysisSearchParameter(v.id);
                      }}
                    />
                  </Grid>
                </>
              );
            }
          )}
        </Grid>
      </Box>
    </Modal>
  );
}
