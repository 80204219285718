import { useDownloadBunkeringFileLazyQuery } from "../../../../gen/graphql/types";

import { downloadFile } from "../../../../utils/downloadFile";

export const useDownloadBunkeringFile = (bunkeringFileId: number) => {
  const [getData, { loading: isLoading, error }] = useDownloadBunkeringFileLazyQuery();

  const onDownload = () => {
    getData({
      variables: { id: bunkeringFileId },
      onCompleted: (data) => {
        if (data?.downloadBunkeringFile?.url) {
          downloadFile(data.downloadBunkeringFile.url);
        } else {
          console.error(error);
          alert("Failed to obtain download URL.");
        }
      },
    });
  };

  return {
    isLoading,
    onDownload,
  };
};
