import React, { useState } from "react";
import {
  ReportInput,
  InputMaybe,
  DepartureInput,
  ArrivalInput,
  BunkeringInput,
  CargoInput,
  useCheckOnlineLazyQuery,
  useCheckDuplicateNoonReportListLazyQuery,
} from "../../../../gen/graphql/types";

import Template from "../../../templates/Crew/SendingMGMT/SendData";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import { db } from "../../../../dexie/db";
import { useLiveQuery } from "dexie-react-hooks";
import { ReportDexie } from "../../../../dexie/Report";
import { DepartureDexie } from "../../../../dexie/Departure";
import { ArrivalDexie } from "../../../../dexie/Arrival";
import {
  mapToArrivalInput,
  mapToBunkeringInput,
  mapToCargoInput,
  mapToDepartureInput,
  useCreateErrorData,
} from "./SendDataUtil";
import { BunkeringDexie } from "../../../../dexie/Bunkering";
import { CargoDexie } from "../../../../dexie/Cargo";
import {
  formatToUtcYYYYMMDDHHMMSS,
  nonNullable,
} from "../../../../utils/formats";
import { useCreateCargoListMutationHook } from "./cargo.hook";
import { useCreateBunkeringListMutationHook } from "./bunkering.hook";
import { useCreateReportListMutationHook } from "./noonReport.hook";
import { useCreateArrivalListMutationHook } from "./arrival.hook";
import { useCreateDepartureListMutationHook } from "./departure.hook";
import useErrorMessageList from "../../../../hooks/errorMessage.hook";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import { checkBrowser } from "../../../../utils/check-browser";
import { downloadTextData } from "../../../../utils/download-text-file";
import { check } from "prettier";
import { Form } from "../NoonReport/Create/types";
import { NoonReportDuplicateError } from "../../../../utils/error";

const SendData = (): React.ReactElement => {
  const { user } = useAuth0();
  const ship = useShip();
  const { errorMessageList, addErrorMessage } = useErrorMessageList();

  const { createErrorData } = useCreateErrorData();
  const [checkedReportList, setCheckedReport] = useState<ReportDexie[]>([]);
  const [checkedBoxListReport, setCheckedBoxReport] = useState<number[]>([]);
  const [checkedDepartureList, setCheckedDeparture] = useState<
    DepartureDexie[]
  >([]);
  const [checkedBoxListDeparture, setCheckedBoxDeparture] = useState<number[]>(
    []
  );
  const [checkedArrivalList, setCheckedArrival] = useState<ArrivalDexie[]>([]);
  const [checkedBoxListArrival, setCheckedBoxArrival] = useState<number[]>([]);
  const [checkedBunkeringList, setCheckedBunkering] = useState<
    BunkeringDexie[]
  >([]);
  const [checkedBoxListBunkering, setCheckedBoxBunkering] = useState<number[]>(
    []
  );

  const [checkedCargoList, setCheckedCargo] = useState<CargoDexie[]>([]);
  const [checkedBoxListCargo, setCheckedBoxCargo] = useState<number[]>([]);

  const reportList = useLiveQuery(() => db.reports.reverse().toArray(), []);
  const departureList = useLiveQuery(
    () => db.departure.reverse().toArray(),
    []
  );
  const arrivalList = useLiveQuery(() => db.arrival.reverse().toArray(), []);
  const bunkeringList = useLiveQuery(
    () => db.bunkering.reverse().toArray(),
    []
  );

  const [checkOnline] = useCheckOnlineLazyQuery();
  const [checkDuplicateNoonReportList] = useCheckDuplicateNoonReportListLazyQuery();

  const cargoList =
    useLiveQuery(() => db.cargo.reverse().toArray(), [])
      ?.filter((v) => v.isDraft === false)
      .filter((v) => v.isSend === false) || [];

  const filterdReports =
    reportList
      ?.filter((v) => v.isDraft === false)
      .filter((v) => v.isSend === false) || [];
  const filterdDepartures =
    departureList
      ?.filter((v) => v.isDraft === false)
      .filter((v) => v.isSend === false) || [];
  const filterdArrivals =
    arrivalList
      ?.filter((v) => v.isDraft === false)
      .filter((v) => v.isSend === false) || [];
  const filterdBunkerings =
    bunkeringList
      ?.filter((v) => v.isDraft === false)
      .filter((v) => v.isSend === false) || [];

  const filterdCargoList =
    cargoList
      ?.filter((v) => v.isDraft === false)
      .filter((v) => v.isSend === false) || [];

  const handleChangeReport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const report = filterdReports[Number(event.target.value)];

    let resultList: ReportDexie[] = [];
    let resultcheckedBoxList: number[] = [];
    if (event.target.checked === true && report.id) {
      checkedReportList.push(report);
      checkedBoxListReport.push(Number(event.target.value));
      resultList = checkedReportList.slice(0, checkedReportList.length); // slice are for rendering.
      resultcheckedBoxList = checkedBoxListReport.slice(
        0,
        checkedBoxListReport.length
      );
    } else {
      resultList = checkedReportList.filter((v) => v.id !== report.id);
      resultcheckedBoxList = checkedBoxListReport.filter(
        (v) => v !== Number(event.target.value)
      );
    }

    setCheckedReport(resultList);
    setCheckedBoxReport(resultcheckedBoxList);
  };

  const handleChangeAllReport = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let resultList: ReportDexie[] = [];
    let resultcheckedBoxList: number[] = [];
    if (event.target.checked === true) {
      resultList = filterdReports.slice(0, filterdReports.length);
      resultcheckedBoxList = Object.keys(filterdReports)
        .map((v) => Number(v))
        .slice(0, filterdReports.length);
      setCheckedReport(resultList);
      setCheckedBoxReport(resultcheckedBoxList);
    } else if (event.target.checked === false) {
      resultList = [];
      resultcheckedBoxList = [];
    }
    setCheckedReport(resultList);
    setCheckedBoxReport(resultcheckedBoxList);
  };

  const handleChangeDeparture = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const departure = filterdDepartures[Number(event.target.value)];

    let resultList: DepartureDexie[] = [];
    let resultcheckedBoxList: number[] = [];
    if (event.target.checked === true && departure.id) {
      checkedDepartureList.push(departure);
      checkedBoxListDeparture.push(Number(event.target.value));
      resultList = checkedDepartureList.slice(0, checkedDepartureList.length); // slice are for rendering.
      resultcheckedBoxList = checkedBoxListDeparture.slice(
        0,
        checkedBoxListDeparture.length
      );
    } else {
      resultList = checkedDepartureList.filter((v) => v.id !== departure.id);
      resultcheckedBoxList = checkedBoxListDeparture.filter(
        (v) => v !== Number(event.target.value)
      );
    }

    setCheckedDeparture(resultList);
    setCheckedBoxDeparture(resultcheckedBoxList);
  };

  const handleChangeAllDeparture = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let resultList: DepartureDexie[] = [];
    let resultcheckedBoxList: number[] = [];
    if (event.target.checked === true) {
      resultList = filterdDepartures.slice(0, filterdDepartures.length);
      resultcheckedBoxList = Object.keys(filterdDepartures)
        .map((v) => Number(v))
        .slice(0, filterdDepartures.length);
      setCheckedDeparture(resultList);
      setCheckedBoxDeparture(resultcheckedBoxList);
    } else if (event.target.checked === false) {
      resultList = [];
      resultcheckedBoxList = [];
    }
    setCheckedDeparture(resultList);
    setCheckedBoxDeparture(resultcheckedBoxList);
  };

  const handleChangeArrival = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrival = filterdArrivals[Number(event.target.value)];
    let resultList: ArrivalDexie[] = [];
    let resultcheckedBoxList: number[] = [];
    if (event.target.checked === true && arrival.id) {
      checkedArrivalList.push(arrival);
      checkedBoxListArrival.push(Number(event.target.value));
      resultList = checkedArrivalList.slice(0, checkedArrivalList.length); // slice are for rendering.
      resultcheckedBoxList = checkedBoxListArrival.slice(
        0,
        checkedBoxListArrival.length
      );
    } else {
      resultList = checkedArrivalList.filter((v) => v.id !== arrival.id);
      resultcheckedBoxList = checkedBoxListArrival.filter(
        (v) => v !== Number(event.target.value)
      );
    }

    setCheckedArrival(resultList);
    setCheckedBoxArrival(resultcheckedBoxList);
  };

  const handleChangeAllArrival = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let resultList: ArrivalDexie[] = [];
    let resultcheckedBoxList: number[] = [];
    if (event.target.checked === true) {
      resultList = filterdArrivals.slice(0, filterdArrivals.length);
      resultcheckedBoxList = Object.keys(filterdArrivals)
        .map((v) => Number(v))
        .slice(0, filterdArrivals.length);
      setCheckedArrival(resultList);
      setCheckedBoxArrival(resultcheckedBoxList);
    } else if (event.target.checked === false) {
      resultList = [];
      resultcheckedBoxList = [];
    }
    setCheckedArrival(resultList);
    setCheckedBoxArrival(resultcheckedBoxList);
  };

  const handleChangeBunkering = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const bunkering = filterdBunkerings[Number(event.target.value)];
    let resultList: BunkeringDexie[] = [];
    let resultcheckedBoxList: number[] = [];
    if (event.target.checked === true && bunkering.id) {
      checkedBunkeringList.push(bunkering);
      checkedBoxListBunkering.push(Number(event.target.value));
      resultList = checkedBunkeringList.slice(0, checkedBunkeringList.length); // slice are for rendering.
      resultcheckedBoxList = checkedBoxListBunkering.slice(
        0,
        checkedBoxListBunkering.length
      );
    } else {
      resultList = checkedBunkeringList.filter((v) => v.id !== bunkering.id);
      resultcheckedBoxList = checkedBoxListBunkering.filter(
        (v) => v !== Number(event.target.value)
      );
    }

    setCheckedBunkering(resultList);
    setCheckedBoxBunkering(resultcheckedBoxList);
  };

  const handleChangeAllBunkering = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let resultList: BunkeringDexie[] = [];
    let resultcheckedBoxList: number[] = [];
    if (event.target.checked === true) {
      resultList = filterdBunkerings.slice(0, filterdBunkerings.length);
      resultcheckedBoxList = Object.keys(filterdBunkerings)
        .map((v) => Number(v))
        .slice(0, filterdBunkerings.length);
      setCheckedBunkering(resultList);
      setCheckedBoxBunkering(resultcheckedBoxList);
    } else if (event.target.checked === false) {
      resultList = [];
      resultcheckedBoxList = [];
    }
    setCheckedBunkering(resultList);
    setCheckedBoxBunkering(resultcheckedBoxList);
  };

  const handleChangeCargo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cargo = filterdCargoList[Number(event.target.value)];
    let resultList: CargoDexie[] = [];
    let resultcheckedBoxList: number[] = [];
    if (event.target.checked === true && cargo.id) {
      checkedCargoList.push(cargo);
      checkedBoxListCargo.push(Number(event.target.value));
      resultList = checkedCargoList.slice(0, checkedCargoList.length); // slice are for rendering.
      resultcheckedBoxList = checkedBoxListCargo.slice(
        0,
        checkedBoxListCargo.length
      );
    } else {
      resultList = checkedCargoList.filter((v) => v.id !== cargo.id);
      resultcheckedBoxList = checkedBoxListCargo.filter(
        (v) => v !== Number(event.target.value)
      );
    }

    setCheckedCargo(resultList);
    setCheckedBoxCargo(resultcheckedBoxList);
  };

  const handleChangeAllCargo = (event: React.ChangeEvent<HTMLInputElement>) => {
    let resultList: CargoDexie[] = [];
    let resultcheckedBoxList: number[] = [];
    if (event.target.checked === true) {
      resultList = filterdCargoList.slice(0, filterdCargoList.length);
      resultcheckedBoxList = Object.keys(filterdCargoList)
        .map((v) => Number(v))
        .slice(0, filterdCargoList.length);
      setCheckedCargo(resultList);
      setCheckedBoxCargo(resultcheckedBoxList);
    } else if (event.target.checked === false) {
      resultList = [];
      resultcheckedBoxList = [];
    }
    setCheckedCargo(resultList);
    setCheckedBoxCargo(resultcheckedBoxList);
  };

  const [{ createBunkeringMutation, isBunkeringLoading }] =
    useCreateBunkeringListMutationHook();
  const [{ createCargoMutation, isCargoLoading }] =
    useCreateCargoListMutationHook();
  const [{ createReportMutation, isReportLoading }] =
    useCreateReportListMutationHook();
  const [{ createArrivalMutation, isArrivalLoading }] =
    useCreateArrivalListMutationHook();
  const [{ createDepartureMutation, isDepartureLoading }] =
    useCreateDepartureListMutationHook();

  const onCreate = async () => {
    try {
      const reportVariables: InputMaybe<InputMaybe<ReportInput>[]> | undefined =
        checkedReportList.map((report) => report.data);

      const departureVariables:
        | InputMaybe<InputMaybe<DepartureInput>[]>
        | undefined = checkedDepartureList.map((report) =>
        mapToDepartureInput(report)
      );
      const arrivalVariables:
        | InputMaybe<InputMaybe<ArrivalInput>[]>
        | undefined = checkedArrivalList.map((report) =>
        mapToArrivalInput(report)
      );
      const bunkeringVariables:
        | InputMaybe<InputMaybe<BunkeringInput>[]>
        | undefined = checkedBunkeringList.map((report) =>
        mapToBunkeringInput(report)
      );

      const cargoVariables: CargoInput[] = checkedCargoList
        .map((cargo) => mapToCargoInput(cargo))
        .filter(nonNullable);

      const isOnline = await checkOnline({
        fetchPolicy: "network-only",
        onError: (error) => {
          addErrorMessage({
            input: `isOnline onError`,
            errorMessage: `No network connection is available.${String(error)}`,
          });
        },
      });

      if (!isOnline.data?.checkOnline) {
        addErrorMessage({
          input: [
            "reportInput",
            JSON.stringify(reportVariables),
            "departureInput",
            JSON.stringify(departureVariables),
            "ArrivalInput",
            JSON.stringify(arrivalVariables),
            "BunkeringInput",
            JSON.stringify(bunkeringVariables),
            "CargoInput",
            JSON.stringify(cargoVariables),
          ].join("\n"),
          errorMessage: `Please check your internet connection and submit again.`,
        });
        return;
      }

      // Noon Report
      if (checkedReportList.length > 0) {
        try {
          const checkDuplicate = await checkDuplicateNoonReportList({
            fetchPolicy: "network-only",
            variables: {
              input: {
                CheckDuplicateNoonReportListInput: checkedReportList.map((v) => {
                  const reportInput = v.data;
                  return {
                    ship_id: reportInput.ship_id,
                    N_Voy: reportInput.N_Voy,
                    N_DateTime: reportInput.N_DateTime,
                  };
                }),
              },
            },
          });

          const { duplicatedNoonReportList, notDuplicatedNoonReportList } = checkedReportList.reduce<{duplicatedNoonReportList: ReportDexie[], notDuplicatedNoonReportList: ReportDexie[]}>((acc, checkedReport) => {
            const isDuplicated = checkDuplicate.data?.checkDuplicateNoonReportList.duplicatedNoonReports?.some((duplicatedNoonReport) => {
              const dateTime = new Date(String(duplicatedNoonReport?.N_DateTime));
              const isDateTimeMatch = checkedReport.data.N_DateTime.getTime() === dateTime.getTime();
              return isDateTimeMatch && duplicatedNoonReport?.N_Voy === checkedReport.data.N_Voy && duplicatedNoonReport?.ship_id === checkedReport.data.ship_id;
            });

            if (isDuplicated) {
              acc.duplicatedNoonReportList.push(checkedReport);
            } else {
              acc.notDuplicatedNoonReportList.push(checkedReport);
            }

            return acc;
          }, { duplicatedNoonReportList: [], notDuplicatedNoonReportList: [] });

          if (notDuplicatedNoonReportList.length > 0) {
            createReportMutation({
              notDuplicatedNoonReportList,
              setCheckedReport,
              setCheckedBoxReport,
              createErrorData,
              addErrorMessage,
            });
          };

          if (duplicatedNoonReportList.length > 0) {
            addErrorMessage({
              input: JSON.stringify(duplicatedNoonReportList.map((v) => v.data)),
              errorMessage: NoonReportDuplicateError.message,
            });
            return;
          };
        } catch (e) {
          console.error(e);
          //エラーinputデータ送信
          createErrorData("Noon Report", reportVariables);
          addErrorMessage({
            input: JSON.stringify(reportVariables),
            errorMessage: `Server Error! Noon Report catch:${e}`,
          });
        }
      }

      // Departure
      if (checkedDepartureList.length > 0) {
        try {
          createDepartureMutation({
            checkedDepartureList,
            setCheckedDeparture,
            setCheckedBoxDeparture,
            createErrorData,
            addErrorMessage,
          });
        } catch (e) {
          console.error(e);
          //エラーinputデータ送信
          createErrorData("Departure", departureVariables);
          addErrorMessage({
            input: JSON.stringify(departureVariables),
            errorMessage: `Server Error! Departure catch:${e}`,
          });
        }
      }

      // Arrival
      if (checkedArrivalList.length > 0) {
        try {
          createArrivalMutation({
            checkedArrivalList,
            setCheckedArrival,
            setCheckedBoxArrival,
            createErrorData,
            addErrorMessage,
          });
        } catch (e) {
          console.error(e);
          //エラーinputデータ送信
          createErrorData("Arrival", arrivalVariables);
          addErrorMessage({
            input: JSON.stringify(arrivalVariables),
            errorMessage: `Server Error! Arrival catch:${e}`,
          });
        }
      }

      // Bunkering
      if (checkedBunkeringList.length > 0) {
        try {
          if (!ship?.IMO_No)
            throw new Error("IMO_No does not exist in the Indexed DB");

          createBunkeringMutation({
            checkedBunkeringList,
            ship,
            setCheckedBunkering,
            setCheckedBoxBunkering,
            createErrorData,
            addErrorMessage,
          });
        } catch (e) {
          console.error(e);

          //エラーinputデータ送信
          createErrorData("Bunkering", bunkeringVariables);
          addErrorMessage({
            input: JSON.stringify(bunkeringVariables),
            errorMessage: `Server Error! Bunkering catch:${e}`,
          });
        }
      }

      // Cargo
      if (checkedCargoList.length > 0) {
        try {
          if (!ship?.IMO_No)
            throw new Error("IMO_No does not exist in the Indexed DB");

          createCargoMutation({
            checkedCargoList,
            ship,
            setCheckedCargo,
            setCheckedBoxCargo,
            createErrorData,
            addErrorMessage,
          });
        } catch (e) {
          console.error(e);

          //エラーinputデータ送信
          createErrorData("Cargo", cargoVariables);
          addErrorMessage({
            input: JSON.stringify(cargoVariables),
            errorMessage: `Server Error! Cargo catch:${e}`,
          });
        }
      }

      setCheckedReport([]);
      setCheckedBoxReport([]);
      setCheckedDeparture([]);
      setCheckedBoxDeparture([]);
      setCheckedArrival([]);
      setCheckedBoxArrival([]);
      setCheckedBunkering([]);
      setCheckedBoxBunkering([]);
      setCheckedCargo([]);
      setCheckedBoxCargo([]);
    } catch (e) {
      console.error(e);
      addErrorMessage({
        input: "onCreate catch",
        errorMessage: `Server Error! onCreate catch:${e}`,
      });
    }
  };

  const onDownloadErrorInfo = () => {
    downloadTextData(
      ship?.IMO_No + "-" + formatToUtcYYYYMMDDHHMMSS(new Date()),
      [
        "Noon Report",
        `Date:${dayjs().utc().format()}`,
        `Imo No; ${ship?.IMO_No}`,
        `email:${user?.email}`,
        `browser: ${checkBrowser()}`,
        `url: ${window.location.href}`,
      ]
        .concat(
          errorMessageList.flatMap((v) => {
            return ["", "errorMessage:", v.errorMessage, "inputData:", v.input];
          })
        )
        .join("\n")
    );
  };

  return (
    <Default title="Noon Report" selectedTitle={"Sending MGMT"}>
      <Template
        reports={filterdReports || []}
        departures={filterdDepartures || []}
        arrivals={filterdArrivals || []}
        bunkerings={filterdBunkerings || []}
        cargoList={filterdCargoList}
        hasFormError={true}
        handleChangeReport={handleChangeReport}
        handleChangeAllReport={handleChangeAllReport}
        checkedBoxListReport={checkedBoxListReport}
        handleChangeDeparture={handleChangeDeparture}
        handleChangeAllDeparture={handleChangeAllDeparture}
        checkedBoxListDeparture={checkedBoxListDeparture}
        handleChangeArrival={handleChangeArrival}
        handleChangeAllArrival={handleChangeAllArrival}
        checkedBoxListArrival={checkedBoxListArrival}
        handleChangeBunkering={handleChangeBunkering}
        handleChangeAllBunkering={handleChangeAllBunkering}
        checkedBoxListBunkering={checkedBoxListBunkering}
        handleChangeCargo={handleChangeCargo}
        handleChangeAllCargo={handleChangeAllCargo}
        checkedBoxListCargo={checkedBoxListCargo}
        onCreate={onCreate}
        isLoading={
          isReportLoading ||
          isArrivalLoading ||
          isDepartureLoading ||
          isBunkeringLoading ||
          isCargoLoading
        }
        errorMessageList={errorMessageList}
        onDownloadErrorInfo={onDownloadErrorInfo}
      />
    </Default>
  );
};

export default SendData;
