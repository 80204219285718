import distance from "@turf/distance"
import { point } from "@turf/helpers"

type Coordinate = {
  latitude: {
    deg: number
    min: number
    direction: string
  }
  longitude: {
    deg: number
    min: number
    direction: string
  }
}

/**
 * Calculate distance between two coordinates
 * Unit: km
 * @param from
 * @param to
 * @returns
 */
export const calcDistance = (from: Coordinate, to: Coordinate): number => {
  const fromLatitude = (from.latitude.deg + from.latitude.min / 60) * (from.latitude.direction === "N" ? 1 : -1)
  const fromLongitude = (from.longitude.deg + from.longitude.min / 60) * (from.longitude.direction === "E" ? 1 : -1)
  const toLatitude = (to.latitude.deg + to.latitude.min / 60) * (to.latitude.direction === "N" ? 1 : -1)
  const toLongitude = (to.longitude.deg + to.longitude.min / 60) * (to.longitude.direction === "E" ? 1 : -1)

  return distance(
    point([fromLongitude, fromLatitude]),
    point([toLongitude, toLatitude]),
    {
      units: "kilometers"
    }
  )
}
