import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { backendUrl } from "../../../../../AppApolloProvider";
import {
  AnalysisFilterType,
  AnalysisSearchParameterDetailInput,
} from "../../../../../gen/graphql/types";
import { FilterBooleanType } from "../../../../pages/Owner/Analysis/types";

const getIsMeUsageFilter = (
  analysisFilterType: AnalysisFilterType,
  isMeUsageFilter: FilterBooleanType
) => {
  switch (analysisFilterType) {
    case AnalysisFilterType.Filter1:
      return isMeUsageFilter.filter1;
    case AnalysisFilterType.Filter2:
      return isMeUsageFilter.filter2;
    case AnalysisFilterType.Filter3:
      return isMeUsageFilter.filter3;
    case AnalysisFilterType.Filter4:
      return isMeUsageFilter.filter4;
  }
};

export const useDownloadCsv = () => {
  const auth0 = useAuth0();

  const handleCsvDownload = async (
    filterConditions: AnalysisSearchParameterDetailInput[],
    isMeUsageFilter: FilterBooleanType
  ) => {
    if (filterConditions.length === 0) {
      alert("No filter is set.");
      return;
    }
    const claims = await auth0.getIdTokenClaims();
    if (claims === undefined) {
      return;
    }
    const sendFilterConditions = filterConditions.map((v) => {
      return {
        ...v,
        isMeUsageFilter: getIsMeUsageFilter(v.filter_type, isMeUsageFilter),
      };
    });

    try {
      // データのダウンロード
      const { data } = await axios<Blob>({
        url: backendUrl.replace(/graphql/i, "downloadAnalysisCsv"),
        params: {
          filterConditions: sendFilterConditions,
        },
        headers: {
          Authorization: `Bearer ${claims?.__raw}`,
          "Access-Control-Allow-Origin": "*",
        },
        method: "GET",
        responseType: "blob",
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  };
  return { handleCsvDownload };
};
