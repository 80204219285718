import React from "react";
import { SxProps, TextField, Theme } from "@mui/material";

type Props = {
  disabled?: boolean;
  placeholder?: string;
  id: string;
  label: string;
  value: string | null | undefined;
  width?: string;
  rows?: number;
  onChange(value: string): void;
  onUpdate(): void;
  sx?: SxProps<Theme>;
  isConfirm?: boolean;
  formErrorMessage?: string;
};

const valueCheck = (props: Props) => {
  if (!props.value) {
    if (!props.isConfirm) {
      return {
        isError: false,
        errorMessage: "",
      };
    } else {
      return {
        isError: true,
        errorMessage: "This field is required.",
      };
    }
  }
  return {
    isError: false,
    errorMessage: "",
  };
};

export function AnalysisSearchParameterModalTextFIeld(props: Props) {
  const { isError, errorMessage } = valueCheck(props);

  return (
    <TextField
      name={props.id}
      size="small"
      disabled={props.disabled}
      sx={{
        width: 400,
        "& .MuiInputBase-root": {
          height: "35px",
        },
        "& .MuiInputBase-input": {
          color: "#1D3846",
          height: "100%",
          padding: "0px 14px",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#1D3846",
          },
          "&:hover fieldset": {
            borderColor: "#1D3846",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#1D3846",
          },
        },
        height: 35,
        py: 0.1,
        ...props.sx,
      }}
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      value={props.value || ""}
      inputProps={{
        maxLength: 50,
        "aria-label": `${props.id}`,
      }}
      rows={props.rows}
      onChange={(e) => props.onChange(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          props.onUpdate();
        }
      }}
      error={
        isError ||
        (props.formErrorMessage !== undefined && props.formErrorMessage !== "")
      }
      helperText={errorMessage || props.formErrorMessage || ""}
    />
  );
}
