import Dexie, { Table } from "dexie";
import { ArrivalDexie } from "./Arrival";
import { ArrivalList } from "./ArrivalInputList";
import { DepartureDexie } from "./Departure";
import { DepartureList } from "./DepartureList";
import { populate } from "./populate";
import { ReportDexie } from "./Report";
import { ReportList } from "./ReportList";
import { ShipDexie } from "./Ship";
import { ShipList } from "./ShipList";
import { BunkeringList } from "./BunkeringList";
import { BunkeringDexie } from "./Bunkering";
import { CargoDexie } from "./Cargo";

export class NoonReportDB extends Dexie {
  reportList!: Table<ReportList, number>;
  reports!: Table<ReportDexie, number>;
  shipList!: Table<ShipList, number>;
  ship!: Table<ShipDexie, number>;
  departureList!: Table<DepartureList, number>;
  departure!: Table<DepartureDexie, number>;
  arrivalList!: Table<ArrivalList, number>;
  arrival!: Table<ArrivalDexie, number>;
  bunkeringList!: Table<BunkeringList, number>;
  bunkering!: Table<BunkeringDexie, number>;
  cargo!: Table<CargoDexie, number>;

  constructor() {
    super("NoonReportDB");
    this.version(1).stores({
      reportList: "++id",
      reports: "++id, reportListId",
    });
    this.version(2).stores({
      reportList: "++id",
      reports: "++id, reportListId",
      shipList: "++id",
      ship: "++id, shipListId",
    });
    this.version(3).stores({
      reportList: "++id",
      reports: "++id, reportListId, *isSend",
      shipList: "++id",
      ship: "++id, shipListId",
    });
    this.version(4).stores({
      reportList: "++id",
      reports: "++id, reportListId, *isSend",
      shipList: "++id",
      ship: "++id, shipListId",
      departureList: "++id",
      departure: "++id, departureId",
    });
    this.version(5).stores({
      reportList: "++id",
      reports: "++id, reportListId, *isSend",
      shipList: "++id",
      ship: "++id, shipListId",
      departureList: "++id",
      departure: "++id, departureId",
      arrivalList: "++id",
      arrival: "++id, arrivalId",
    });
    this.version(6).stores({
      reportList: "++id",
      reports: "++id, reportListId, *isSend",
      shipList: "++id",
      ship: "++id, shipListId",
      departureList: "++id",
      departure: "++id, departureId",
      arrivalList: "++id",
      arrival: "++id, arrivalId",
      bunkeringList: "++id",
      bunkering: "++id, bunkeringId",
    });
    this.version(7).stores({
      reports: "++id",
      ship: "++id",
      departure: "++id",
      arrival: "++id",
      bunkering: "++id",
      cargo: "++id",
    });
  }

  deleteList(reportListId: number) {
    return this.transaction("rw", this.reports, this.reportList, () => {
      this.reports.where({ reportListId }).delete();
      this.reportList.delete(reportListId);
    });
  }
}

export const db = new NoonReportDB();

db.on("populate", populate);

// export function resetDatabase() {
//   return db.transaction("rw", db.todoLists, db.todoItems, async () => {
//     await Promise.all(db.tables.map((table) => table.clear()));
//     await populate();
//   });
// }
