import {
  formatToCsvString,
  genSearchConditionHash,
} from "../../../../utils/download-csv-util";
import { formatToUtcYYYYMMDDHHMM, formatToYYYYMMDDHHMM } from "../../../../utils/formats";
import { Bunkering } from "../../Crew/Bunkering/Create/types";
import { ReportSearchCondition } from "../../pages.module";

export const genCsv = (
  searchCondition: ReportSearchCondition,
  reportList: Bunkering[]
) => {
  const genFileName = async () => {
    const hashed = await genSearchConditionHash(searchCondition).catch((e) => {
      throw e;
    });
    const formatted = formatToYYYYMMDDHHMM(new Date(), "-", "", "");
    return `Bunkering${formatted}-${hashed}.csv`;
  };

  const genText = () => {
    const head = [
      '"Report ID"',
      '"Vessel Name"',
      '"Voyage number"',
      '"Time Zone (UTC±)"',
      '"EU/UK"',
      '"Date & Time (Local)"',
      '"Lat./ Lon."',
      '"Port L/U"',
      '"Port name"',
      '"UNLOC"',
      '"Bunkering Fuel name 1"',
      '"Bunkering Mass 1 (mt)"',
      '"Bunkering Volume 1 (m3)"',
      '"Bunkering Density 1 (mt/m3)"',
      '"Bunkering Fuel name 2"',
      '"Bunkering Mass 2 (mt)"',
      '"Bunkering Volume 2 (m3)"',
      '"Bunkering Density 2 (mt/m3)"',
      '"Bunkering Fuel name 3"',
      '"Bunkering Mass 3 (mt)"',
      '"Bunkering Volume 3 (m3)"',
      '"Bunkering Density 3 (mt/m3)"',
      '"Bunkering Fuel name 4"',
      '"Bunkering Mass 4 (mt)"',
      '"Bunkering Volume 4 (m3)"',
      '"Bunkering Density 4 (mt/m3)"',
      '"Bunkering Fuel name 5"',
      '"Bunkering Mass 5 (mt)"',
      '"Bunkering Volume 5 (m3)"',
      '"Bunkering Density 5 (mt/m3)"',
      '"Bunkering Fuel name 6"',
      '"Bunkering Mass 6 (mt)"',
      '"Bunkering Volume 6 (m3)"',
      '"Bunkering Density 6 (mt/m3)"',
      '"Bunkering Fuel name 7"',
      '"Bunkering Mass 7 (mt)"',
      '"Bunkering Volume 7 (m3)"',
      '"Bunkering Density 7 (mt/m3)"',
      '"Bunkering Fuel name 8"',
      '"Bunkering Mass 8 (mt)"',
      '"Bunkering Volume 8 (m3)"',
      '"Bunkering Density 8 (mt/m3)"',
      '"Bunkering Fuel name 9"',
      '"Bunkering Mass 9 (mt)"',
      '"Bunkering Volume 9 (m3)"',
      '"Bunkering Density 9 (mt/m3)"',
      '"Bunkering Fuel name 10"',
      '"Bunkering Mass 10 (mt)"',
      '"Bunkering Volume 10 (m3)"',
      '"Bunkering Density 10 (mt/m3)"',
    ].join(",");
    const body = reportList.map((report) => {
      const report_id = report.id;
      const B_Vessel_Name = formatToCsvString(report.B_Vessel_Name);
      const B_Voy = formatToCsvString(report.B_Voy);
      const B_Bunkering_Date_time = formatToUtcYYYYMMDDHHMM(
        report.B_Bunkering_Date_time
      );
      const B_Timezone = formatToCsvString(report.B_Timezone);
      const B_LatLong = formatToCsvString(report.B_LatLong);
      const B_PortName = formatToCsvString(report.B_PortName);
      const B_UNLOC = formatToCsvString(report.B_UNLOC);
      const B_EUorUK = report.B_EUorUK;
      const B_Port_LorU = formatToCsvString(report.B_Port_LorU);
      const B_Fuel_name_1 = formatToCsvString(report.B_Fuel_name_1);
      const B_Mass_1 = report.B_Mass_1;
      const B_Volume_1 = report.B_Volume_1;
      const B_Density_1 = report.B_Density_1;
      const B_Fuel_name_2 = formatToCsvString(report.B_Fuel_name_2);
      const B_Mass_2 = report.B_Mass_2;
      const B_Volume_2 = report.B_Volume_2;
      const B_Density_2 = report.B_Density_2;
      const B_Fuel_name_3 = formatToCsvString(report.B_Fuel_name_3);
      const B_Mass_3 = report.B_Mass_3;
      const B_Volume_3 = report.B_Volume_3;
      const B_Density_3 = report.B_Density_3;
      const B_Fuel_name_4 = formatToCsvString(report.B_Fuel_name_4);
      const B_Mass_4 = report.B_Mass_4;
      const B_Volume_4 = report.B_Volume_4;
      const B_Density_4 = report.B_Density_4;
      const B_Fuel_name_5 = formatToCsvString(report.B_Fuel_name_5);
      const B_Mass_5 = report.B_Mass_5;
      const B_Volume_5 = report.B_Volume_5;
      const B_Density_5 = report.B_Density_5;
      const B_Fuel_name_6 = formatToCsvString(report.B_Fuel_name_6);
      const B_Mass_6 = report.B_Mass_6;
      const B_Volume_6 = report.B_Volume_6;
      const B_Density_6 = report.B_Density_6;
      const B_Fuel_name_7 = formatToCsvString(report.B_Fuel_name_7);
      const B_Mass_7 = report.B_Mass_7;
      const B_Volume_7 = report.B_Volume_7;
      const B_Density_7 = report.B_Density_7;
      const B_Fuel_name_8 = formatToCsvString(report.B_Fuel_name_8);
      const B_Mass_8 = report.B_Mass_8;
      const B_Volume_8 = report.B_Volume_8;
      const B_Density_8 = report.B_Density_8;
      const B_Fuel_name_9 = formatToCsvString(report.B_Fuel_name_9);
      const B_Mass_9 = report.B_Mass_9;
      const B_Volume_9 = report.B_Volume_9;
      const B_Density_9 = report.B_Density_9;
      const B_Fuel_name_10 = formatToCsvString(report.B_Fuel_name_10);
      const B_Mass_10 = report.B_Mass_10;
      const B_Volume_10 = report.B_Volume_10;
      const B_Density_10 = report.B_Density_10;

      return [
        report_id,
        B_Vessel_Name,
        B_Voy,
        B_Timezone,
        B_EUorUK,
        B_Bunkering_Date_time,
        B_LatLong,
        B_Port_LorU,
        B_PortName,
        B_UNLOC,
        B_Fuel_name_1,
        B_Mass_1,
        B_Volume_1,
        B_Density_1,
        B_Fuel_name_2,
        B_Mass_2,
        B_Volume_2,
        B_Density_2,
        B_Fuel_name_3,
        B_Mass_3,
        B_Volume_3,
        B_Density_3,
        B_Fuel_name_4,
        B_Mass_4,
        B_Volume_4,
        B_Density_4,
        B_Fuel_name_5,
        B_Mass_5,
        B_Volume_5,
        B_Density_5,
        B_Fuel_name_6,
        B_Mass_6,
        B_Volume_6,
        B_Density_6,
        B_Fuel_name_7,
        B_Mass_7,
        B_Volume_7,
        B_Density_7,
        B_Fuel_name_8,
        B_Mass_8,
        B_Volume_8,
        B_Density_8,
        B_Fuel_name_9,
        B_Mass_9,
        B_Volume_9,
        B_Density_9,
        B_Fuel_name_10,
        B_Mass_10,
        B_Volume_10,
        B_Density_10,
      ].join(",");
    });

    return [head, ...body].join("\n");
  };

  return (async () => {
    const fileName = await genFileName().catch((e) => {
      throw e;
    });
    const text = genText();

    return {
      fileName,
      text,
    };
  })();
};
