import { CargoDexie } from "../../../../dexie/Cargo";
import { ShipDexie } from "../../../../dexie/Ship";
import {
  CargoInput,
  QueryStatus,
  useCreateCargoListMutation,
} from "../../../../gen/graphql/types";
import { nonNullable } from "../../../../utils/formats";
import { uploadFiles } from "../../../../utils/uploadFile.module";
import { checkQueryStatus } from "../../pages.module";
import { mapToCargoInput, updateFrontCargo } from "./SendDataUtil";
import { ErrorMessageData } from "../../../../hooks/errorMessage.hook";

type Props = {
  checkedCargoList: CargoDexie[];
  ship: ShipDexie;
  setCheckedCargo: (value: React.SetStateAction<CargoDexie[]>) => void;
  setCheckedBoxCargo: (value: React.SetStateAction<number[]>) => void;
  createErrorData: (
    dataType: string,
    dataArr: unknown[]
  ) => {
    loading: boolean;
  };
  addErrorMessage: (newErrorMessage: ErrorMessageData) => void;
};

export const useCreateCargoListMutationHook = () => {
  const [createCargo, { loading: isCargoLoading }] =
    useCreateCargoListMutation();

  const createCargoMutation = (props: Props) => {
    const cargoVariables: CargoInput[] = props.checkedCargoList
      .map((cargo) => mapToCargoInput(cargo))
      .filter(nonNullable);

    createCargo({
      variables: {
        input: {
          CargoListInput: cargoVariables,

          IMO_No: props.ship.IMO_No,
        },
      },
      onCompleted: async (data) => {
        try {
          checkQueryStatus(data.createCargoList?.CreateCargoListStatus);

          if (!data.createCargoList) {
            throw new Error("Server Error! createCargoList response is empty.");
          } else if (!data.createCargoList.createdCargoCount) {
            throw new Error(
              "Server Error! createCargoList createCargoListCount is 0."
            );
          } else if (
            data.createCargoList.CreateCargoListStatus === QueryStatus.Success
          ) {
            console.info("createCargo Success!");
          }

          const failedGetUploadUrlDbFileList: string[] = [];

          const upLoadFilePath = data.createCargoList.uploadFilePaths
            .filter((v): v is NonNullable<typeof v> => v !== null)
            .map((v) => {
              if (v.gcsFilePath === "") {
                failedGetUploadUrlDbFileList.push(v.dbFilePath);
                return;
              }

              const fileData = props.checkedCargoList
                .filter((v): v is NonNullable<typeof v> => v !== undefined)
                .find((local) => local.data?.C_Filename === v.dbFilePath);

              if (!fileData || !fileData.cargoFile) return;
              return {
                localFilePath: v.dbFilePath,
                remoteFilePath: v.gcsFilePath,
                fileContent: fileData.cargoFile,
              };
            })
            .filter((v): v is NonNullable<typeof v> => v !== undefined);

          const failedUploadFileList = (
            await uploadFiles(upLoadFilePath)
          ).filter((v) => !v.sucsess);

          if (
            failedUploadFileList.length > 0 ||
            failedGetUploadUrlDbFileList.length > 0
          ) {
            props.addErrorMessage({
              input: JSON.stringify(cargoVariables),
              errorMessage:
                `Failed to upload the following files. ${failedUploadFileList
                  .map((v) => v.filename)
                  .concat(failedGetUploadUrlDbFileList)
                  .join(",")}` +
                failedUploadFileList.map((v) => v.errorMessage).join("\n"),
            });
          }

          if (
            props.checkedCargoList.length ===
            data.createCargoList?.createdCargoCount
          ) {
            props.checkedCargoList.forEach((report) => {
              updateFrontCargo(report);
            });
          }
          props.setCheckedCargo([]);
          props.setCheckedBoxCargo([]);
        } catch (e) {
          console.error(e);
          props.createErrorData("Cargo", cargoVariables);
          props.addErrorMessage({
            input: JSON.stringify(cargoVariables),
            errorMessage: `Server Error! createCargoList onCompleted:${e}`,
          });
        }
      },
      onError: (error) => {
        console.error(error);
        //エラーinputデータ送信
        props.createErrorData("Cargo", cargoVariables);
        props.addErrorMessage({
          input: JSON.stringify(cargoVariables),
          errorMessage: `Server Error! createCargoList onError:${error}`,
        });
      },
    });
  };
  return [{ createCargoMutation, isCargoLoading }];
};
