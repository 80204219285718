import React, { useState } from "react";
import Box from "@mui/material/Box";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { Link, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReportDexie } from "../../../../dexie/Report";
import { useParams } from "react-router";
import DeleteModal from "../../../atoms/DeleteModal";
import {
  TableDataRow,
  Table3DataRow,
  TableAnyDataRow,
} from "../../../molecules/Owner/Detail/Detail";
import { formatToUtcYYYYMMDDHHMM } from "../../../../utils/formats";
import { CargoForm } from "../../../pages/Crew/Cargo/type";
import { getFileName, getLatLong, getTimeZone} from "../../../../utils/form-util";
import CustomOwnerTableCell from "../../../atoms/CustomOwnerTableCell";
import { mediumGray, weightedGray, white } from "../../../../utils/color";
import { isNumeric } from "../../../../utils/type-checks";
import { useDownloadCargoFile } from "./downloadFile.hook";
import Loading from "../../../atoms/Loading";

type FormBlockProps = {
  pageTitle: string;
  isPreview: boolean;
  previousReport?: ReportDexie | undefined;
  deleteReport?(): void;
  isSend: boolean;
  form: CargoForm;
};

const CargoDetail = (props: FormBlockProps): React.ReactElement => {
  const { reportId } = useParams<"reportId">();

  const report_id = isNumeric(reportId) ? Number(reportId) : 0;
  const [modalOpen, setIsModalOpen] = useState(false);
  const downloadCargoFile = useDownloadCargoFile(report_id);

  const onClickDelete = () => {
    if (props.deleteReport) {
      props.deleteReport();
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <Loading isLoading={downloadCargoFile.isLoading} />
      <Box sx={{ pt: 0.5, mx: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "top",
            justifycontent: "right",
            pb: 1,
          }}
        >
          <Column>
            <Typography
              component="h3"
              variant="subtitle1"
              align="center"
              sx={{
                lineHeight: 1,
                fontWeight: "bold",
                color: "#333333",
                fontSize: "1.5rem",
                verticalAlign: "super",
              }}
            >
              {props.pageTitle}
            </Typography>
          </Column>
        </Columns>
      </Box>

      <Box sx={{ px: 2 }}>
        <Table
          color="inherit"
          sx={{ minWidth: 600, mx: 0, color: white }}
          aria-label="spanning table"
        >
          <TableHead>
            <TableRow sx={{ borderBottom: white, height: "20px" }}>
              <CustomOwnerTableCell
                align="center"
                colSpan={3}
              ></CustomOwnerTableCell>
              <CustomOwnerTableCell />
              <CustomOwnerTableCell></CustomOwnerTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableDataRow title={"Voyage number"} data={props.form.C_Voyage_No} />
            <TableDataRow
              title={"Date & Time (Local)"}
              data={formatToUtcYYYYMMDDHHMM(props.form.C_DateTime)}
            />
            <TableDataRow
              title={"Time Zone (UTC±)"}
              data={getTimeZone(
                props.form.C_TimeZone_PorN,
                props.form.C_TimeZone_HH,
                props.form.C_TimeZone_MM
              )}
            />

            <TableDataRow
              title={"Lat./ Lon."}
              data={getLatLong(
                props.form.C_Lat_Deg?.toString(),
                props.form.C_Lat_Min,
                props.form.C_Lat_Direction,
                props.form.C_Long_Deg,
                props.form.C_Long_Min,
                props.form.C_Long_Direction
              )}
            />
            <TableDataRow title={"Port name"} data={props.form.C_Cargo_Port} />
            <TableDataRow title={"UNLOC"} data={props.form.C_UNLOC} />
            <TableDataRow title={"EU/UK"} data={props.form.C_EU_UK_port} />
            <TableDataRow title={"L or U"} data={props.form.C_Port_L_U} />

            <TableAnyDataRow
              topTitle={"Cargo Loading"}
              title={[
                "Mass",
                "TEU/Full",
                "TEU/Empty",
                "Unit",
                "Lane metres",
                "Number of Passengers",
                "Volume",
              ]}
              data={[
                props.form.C_Loading_Mass,
                props.form.C_Loading_TEU_Full,
                props.form.C_Loading_TEU_Empty,
                props.form.C_Loading_Unit,
                props.form.C_Loading_Lane_metres,
                props.form.C_Loading_Number_of_Passengers,
                props.form.C_Loading_Volume,
              ]}
              unitName={["mt","","","","metre","","m³"]}
            />
            <TableAnyDataRow
              topTitle={"Cargo Unloading"}
              title={[
                "Mass",
                "TEU/Full",
                "TEU/Empty",
                "Unit",
                "Lane metres",
                "Number of Passengers",
                "Volume",
              ]}
              data={[
                props.form.C_Unloading_Mass,
                props.form.C_Unloading_TEU_Full,
                props.form.C_Unloading_TEU_Empty,
                props.form.C_Unloading_Unit,
                props.form.C_Unloading_Lane_metres,
                props.form.C_Unloading_Number_of_Passengers,
                props.form.C_Unloading_Volume,
              ]}
              unitName={["mt","","","","metre","","m³"]}
            />
            <TableAnyDataRow
              topTitle={"Cargo Total"}
              title={[
                "Mass",
                "TEU/Full",
                "TEU/Empty",
                "Unit",
                "Lane metres",
                "Number of Passengers",
                "Volume",
              ]}
              data={[
                props.form.C_Total_Mass,
                props.form.C_Total_TEU_Full,
                props.form.C_Total_TEU_Empty,
                props.form.C_Total_Unit,
                props.form.C_Total_Lane_metres,
                props.form.C_Total_Number_of_Passengers,
                props.form.C_Total_Volume,
              ]}
              unitName={["mt","","","","metre","","m³"]}
            />
            <Table3DataRow
              topTitle={"Deadweight measurement"}
              title1={"Displacement"}
              data1={props.form.C_Displacement}
              unitName={"mt"}
              title2={"Water density"}
              data2={props.form.C_Water_density}
              title3={`Total ROB`}
              data3={props.form.C_Total_ROB}
            />

            <TableRow>
              <CustomOwnerTableCell sx={{ color: mediumGray, width: "250px" }}>
                {"FileName"}
              </CustomOwnerTableCell>
              <CustomOwnerTableCell />
              <CustomOwnerTableCell
                colSpan={2}
                sx={{
                  color: weightedGray,
                  wordBreak: "break-word",
                  whiteSpace: "pre-line",
                }}
              >
                {props.form.C_Filename && isNumeric(reportId) ? (
                  <Link onClick={() => downloadCargoFile.onDownload()}>
                    {getFileName(props.form.C_Filename)}
                  </Link>
                ) : (
                  "-"
                )}
              </CustomOwnerTableCell>
              <CustomOwnerTableCell
                sx={{ color: weightedGray }}
              ></CustomOwnerTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <DeleteModal
        isOpen={modalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteFrontData={onClickDelete}
        id={Number(reportId)}
        voy={props.form.C_Voyage_No ? props.form.C_Voyage_No : ""}
        DateTime={formatToUtcYYYYMMDDHHMM(props.form.C_DateTime)}
      />
    </Box>
  );
};

export default CargoDetail;
