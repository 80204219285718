import { Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { RhfSelectForm } from "../../atoms/RhfSelectForm";
import { RhfTextField } from "../../atoms/RhfTextField";
import { RhfDatePicker } from "../../atoms/RhfDatePicker";
import { RhfRadioGroup } from "../../atoms/RhfRadioGroup";

export const RhfSampleForm: FC = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        sx={{
          paddingTop: (theme) => theme.spacing(3),
        }}
      >
        <Divider
          sx={{
            backgroundColor: "gray[400]",
            height: "2px",
            width: "100%",
          }}
        />

        <Grid
          container
          sx={{
            marginY: (theme) => theme.spacing(4),
            display: "flex",
          }}
        >
          <Grid item xs={3}>
            <Typography
              variant={"h6"}
              sx={{
                fontWeight: "700",
                marginRight: (theme) => theme.spacing(2),
              }}
            ></Typography>
          </Grid>
          <Grid item xs={9} ml={-1.2} pr={1}>
            <Stack direction="column" justifyContent="center" rowGap={1}>
              <Grid container alignItems="center" rowGap={1} columnGap={1}>
                <Grid xs={4} item>
                  <Typography pl={theme.spacing(2)}>textSample</Typography>
                </Grid>
                <Grid xs={7} item>
                  <RhfTextField
                    name={"textSample"}
                    placeholder={"Text Sample"}
                  />
                </Grid>
                <Grid xs={4} item>
                  <Typography pl={theme.spacing(2)}>numberSample</Typography>
                </Grid>
                <Grid xs={7} item>
                  <RhfTextField
                    type="number"
                    name={"numberSample"}
                    placeholder={"Number Sample"}
                  />
                </Grid>
                <Grid xs={4} item>
                  <Typography pl={theme.spacing(2)}>dateSample</Typography>
                </Grid>
                <Grid xs={7} item>
                  <RhfDatePicker name={"dateSample"} />
                </Grid>
                <Grid xs={4} item>
                  <Typography pl={theme.spacing(2)}>selectSample</Typography>
                </Grid>
                <Grid xs={7} item>
                  <RhfSelectForm
                    defaultValue={"1"}
                    selectPropsList={[
                      { label: "", value: "" },
                      { label: "test1", value: "1" },
                      { label: "test2", value: "2" },
                      { label: "test3", value: "3" },
                    ]}
                    name={"selectSample"}
                  />
                </Grid>
                <Grid xs={4} item>
                  <Typography pl={theme.spacing(2)}>radioSample</Typography>
                </Grid>
                <Grid xs={7} item>
                  <RhfRadioGroup
                    row
                    defaultRadioValue={"testRadio1"}
                    name={"radioSample"}
                    radioPropsList={[
                      { label: "testRadio1", value: "testRadio1" },
                      { label: "testRadio2", value: "testRadio2" },
                      { label: "testRadio3", value: "testRadio3" },
                    ]}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
