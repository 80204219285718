import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { db } from "../../../../../dexie/db";
import { ReportDexie } from "../../../../../dexie/Report";
import Default, { useShip } from "../../../../layouts/DefaultCrew";
import Template from "../../../../templates/Crew/NoonReport/Update/Confirm";
import { Form, LocationState } from "../Create/types";
import { useGetPreviousReport } from "../../../../../hooks/dexie/dexie";
import { mapToCreateReportMutationVariables } from "../noonReport.module";

type LocationForm = {
  form: Form;
  robFile?: File;
};

const Confirm = (props: LocationState): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const ship = useShip();
  const navigate = useNavigate();

  if (reportId === undefined) navigate("/");

  const report_id = Number(reportId);

  const previousReport = useGetPreviousReport(report_id);

  const location = useLocation();

  const location_form = location.state as LocationForm;

  if (!props.form && !location_form.form) {
    navigate(-1);
  }

  const form = props.form || location_form.form;

  const onUpdate = async () => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToCreateReportMutationVariables(form, ship?.ship_id);

    const updateReport: ReportDexie = {
      id: report_id,
      isDraft: false,
      isSend: false,
      data: variables,
      draftForm: form,
      robFile: location_form.robFile,
    };
    db.reports.update(Number(reportId), updateReport);

    navigate("/update/complete");
  };

  const onBack = () => {
    navigate("/");
  };

  return (
    <Default title="" selectedTitle={"Noon Report"}>
      <Template
        form={location_form.form}
        isLoading={false}
        onUpdate={onUpdate}
        onBack={onBack}
        reportId={report_id}
        previousReport={previousReport}
      />
    </Default>
  );
};

export default Confirm;
