import * as React from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { Props } from "../../../pages/Crew/NoonReport/Create/types";
import Detail from "../../../organisms/Crew/NoonReport/Detail";
import { ReportDexie } from "../../../../dexie/Report";

type ConfirmProps = Props & {
  hasFormError: boolean;
  onConfirm(): void;
  reportId: number;
  previousReport: ReportDexie | undefined;
  deleteReport(): void;
  isSend: boolean;
};

const ReportDetail = (props: ConfirmProps): React.ReactElement => {
  const onClickConfirm = () => {
    props.onConfirm();
  };

  return (
    <React.Fragment>
      <Paper sx={{ backgroundColor: "#27292E", borderRadius: "20px" }}>
        <Detail
          isPreview={true}
          form={props.form}
          reportId={props.reportId}
          pageTitle={"Noon Report"}
          previousReport={props.previousReport}
          deleteReport={props.deleteReport}
          isSend={props.isSend}
        />

        <Box
          sx={{
            textAlign: "left",
            px: 2,
            py: 4,
          }}
        >
          <Button
            sx={{
              mx: 2,
              width: "200px",
              height: "40px",
              backgroundColor: "#20BE89  !important",
              fontFamily: "Public Sans",
              "&.MuiButton-root": {
                fontFamily: "Public Sans",
              },
              textTransform: "none",
            }}
            variant="contained"
            onClick={onClickConfirm}
          >
            Back
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default ReportDetail;
