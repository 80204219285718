import React from "react";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { Form, Props } from "../../../pages/Crew/NoonReport/Create/types";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import { ShipDexie } from "../../../../dexie/Ship";
import { Typography } from "@mui/material";

type FormBlockProps = Props & {
  title: string;
  isPreview: boolean;
  onUpdateForm?: (value: Form) => void;
  ship?: ShipDexie;
  isConfirm: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormBlock_Spare = (props: FormBlockProps): React.ReactElement => {
  const onChange = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    const form = {
      ...props.form,
      [key]: value,
    };

    props.onUpdateForm?.(form);
  };

  return (
    <>
      <Columns
        sx={{
          display: "flex",
          alignItems: "end",
        }}
      >
        <Column>
          <Typography
            sx={{
              textAlign: "left",
              verticalAlign: "top",
              fontSize: "14px",
              fontFamily: "Public Sans",
              lineHeight: "auto",
              color: "#AAAAAA",
              pl: 1,
              mb: -2,
            }}
          >
            Spares
          </Typography>
        </Column>
      </Columns>
      <Columns
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_1"
            label="Spare 1"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_1}
            onChange={(e) => onChange("N_Spare_1", e)}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_1_unit_name"
            label="unit 1"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_1_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_1_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_2"
            label="Spare 2"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_2}
            onChange={(e: string | null) => {
              onChange("N_Spare_2", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_2_unit_name"
            label="unit 2"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_2_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_2_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_3"
            label="Spare 3"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_3}
            onChange={(e: string | null) => {
              onChange("N_Spare_3", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_3_unit_name"
            label="unit 3"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_3_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_3_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_4"
            label="Spare 4"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_4}
            onChange={(e: string | null) => {
              onChange("N_Spare_4", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_4_unit_name"
            label="unit 4"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_4_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_4_unit_name", e);
            }}
          />
        </Column>
      </Columns>
      <Columns
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_5"
            label="Spare 5"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_5}
            onChange={(e: string | null) => {
              onChange("N_Spare_5", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_5_unit_name"
            label="unit 5"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_5_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_5_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_6"
            label="Spare 6"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_6}
            onChange={(e: string | null) => {
              onChange("N_Spare_6", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_6_unit_name"
            label="unit 6"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_6_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_6_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_7"
            label="Spare 7"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_7}
            onChange={(e: string | null) => {
              onChange("N_Spare_7", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_7_unit_name"
            label="unit 7"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_7_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_7_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_8"
            label="Spare 8"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_8}
            onChange={(e: string | null) => {
              onChange("N_Spare_8", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_8_unit_name"
            label="unit 8"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_8_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_8_unit_name", e);
            }}
          />
        </Column>
      </Columns>
      <Columns
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_9"
            label="Spare 9"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_9}
            onChange={(e: string | null) => {
              onChange("N_Spare_9", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_9_unit_name"
            label="unit 9"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_9_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_9_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_10"
            label="Spare 10"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_10}
            onChange={(e: string | null) => {
              onChange("N_Spare_10", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_10_unit_name"
            label="unit 10"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_10_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_10_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_11"
            label="Spare 11"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_11}
            onChange={(e: string | null) => {
              onChange("N_Spare_11", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_11_unit_name"
            label="unit 11"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_11_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_11_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_12"
            label="Spare 12"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_12}
            onChange={(e: string | null) => {
              onChange("N_Spare_12", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_12_unit_name"
            label="unit 12"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_12_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_12_unit_name", e);
            }}
          />
        </Column>
      </Columns>
      <Columns
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_13"
            label="Spare 13"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_13}
            onChange={(e: string | null) => {
              onChange("N_Spare_13", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_13_unit_name"
            label="unit 13"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_13_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_13_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_14"
            label="Spare 14"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_14}
            onChange={(e: string | null) => {
              onChange("N_Spare_14", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_14_unit_name"
            label="unit 14"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_14_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_14_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_15"
            label="Spare 15"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_15}
            onChange={(e: string | null) => {
              onChange("N_Spare_15", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_15_unit_name"
            label="unit 15"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_15_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_15_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_16"
            label="Spare 16"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_16}
            onChange={(e: string | null) => {
              onChange("N_Spare_16", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_16_unit_name"
            label="unit 16"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_16_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_16_unit_name", e);
            }}
          />
        </Column>
      </Columns>
      <Columns
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_17"
            label="Spare 17"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_17}
            onChange={(e: string | null) => {
              onChange("N_Spare_17", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_17_unit_name"
            label="unit 17"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_17_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_17_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_18"
            label="Spare 18"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_18}
            onChange={(e: string | null) => {
              onChange("N_Spare_18", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_18_unit_name"
            label="unit 18"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_18_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_18_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_19"
            label="Spare 19"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_19}
            onChange={(e: string | null) => {
              onChange("N_Spare_19", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_19_unit_name"
            label="unit 19"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_19_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_19_unit_name", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_20"
            label="Spare 20"
            sx={{ width: 130, mr: -0.5 }}
            value={props.form.N_Spare_20}
            onChange={(e: string | null) => {
              onChange("N_Spare_20", e);
            }}
          />
        </Column>
        <Column>
          <CommonTextFIeld
            disabled={props.isPreview}
            id="N_Spare_20_unit_name"
            label="unit 20"
            sx={{ width: 82, mr: 2 }}
            value={props.form.N_Spare_20_unit_name}
            onChange={(e: string | null) => {
              onChange("N_Spare_20_unit_name", e);
            }}
          />
        </Column>
      </Columns>
    </>
  );
};

export default FormBlock_Spare;
