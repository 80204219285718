import { SxProps, Theme, Typography } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};

const Text = (props: Props): React.ReactElement => {
  return (
    <Typography
      sx={{
        fontFamily: "Public Sans",
        color: "#FFFFFF",
        fontSize: "18px",
        whiteSpace: "pre-line",
        wordBreak: "break-all",
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default Text;
