import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Template from "../../../templates/Crew/Bunkering/Create";
import { Form, LocationState } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import { db } from "../../../../dexie/db";
import { pageData } from "../../../../App";
import { useLiveQuery } from "dexie-react-hooks";
import { BunkeringDexie } from "../../../../dexie/Bunkering";
import { mapToCreateBunkeringMutationVariables } from "./Create/Confirm";
import { useBunkeringForm } from "./bunkering.module";
import ConfirmTemplate from "../../../templates/Crew/Bunkering/Create/Confirm";

type LocationForm = {
  form: Form;
};

export const useGetLastFrontBunkering = () => {
  const bunkeringList = useLiveQuery(
    () => db.bunkering.toArray().catch((e) => console.error(e)),
    []
  );
  if (
    !bunkeringList ||
    bunkeringList.length === 0 ||
    !bunkeringList[bunkeringList.length - 1]
  ) {
    return;
  }

  const lastReport = bunkeringList[bunkeringList.length - 1];

  return lastReport;
};

const Create = (props: LocationState): React.ReactElement => {
  const navigate = useNavigate();

  const location = useLocation();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [bunkeringFile, setFile] = useState<File | undefined>(undefined);

  const location_form = location.state as LocationForm;

  const form1 = props.form || location_form?.form || props.form;

  const form = useBunkeringForm([form1]);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const ship = useShip();

  const onSaveDraft = (value: Form) => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToCreateBunkeringMutationVariables(value, ship?.ship_id);

    const newBunkering: BunkeringDexie = {
      id: undefined,
      isDraft: true,
      isSend: false,
      data: variables,
      draftForm: value,
      bunkeringFile: value.B_Filename ? bunkeringFile : undefined,
    };
    db.bunkering.add(newBunkering, 1).catch((e) => console.error(e));
    navigate(pageData.crewBunkering.path());
  };

  const onCreate = async () => {
    try {
      if (!ship?.ship_id) throw Error("Error! ship id is missing");

      const variables = mapToCreateBunkeringMutationVariables(
        form.data,
        ship?.ship_id
      );

      const newBunkering: BunkeringDexie = {
        id: undefined,
        isDraft: false,
        isSend: false,
        data: variables,
        draftForm: form.data,
        bunkeringFile: form.data.B_Filename && bunkeringFile ? bunkeringFile : undefined
      };

      db.bunkering.add(newBunkering, 1);
      console.log(newBunkering);
    } catch (error) {
      console.error(error);
      alert("Failed to create data.");
      return;
    }

    navigate("/bunkering/create/complete");
  };

  const lastBunkering = useGetLastFrontBunkering();

  const onConfirm = () => {
    setIsConfirm(true);
    console.log('confirm', form.data)

    form.hasErrorScrollUp();

    if (form.hasError) return;
    setIsDetail(true)
    // navigate("/bunkering/create/confirm", { state: { form: form.data } });
  };

  const onBack = () => {
    console.log('back')
  };

  return (
    <Default title="" selectedTitle={"Bunkering"}>
      {isDetail ? (
        <ConfirmTemplate
          form={form.data}
          onCreate={onCreate}
          onBack={onBack}
          lastReport={undefined}
          setIsDetail={setIsDetail}
        />
        ) : (
        <Template
          form={form.data}
          hasFormError={form.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          onSaveDraft={onSaveDraft}
          ship={ship}
          lastBunkering={lastBunkering}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
          setFile={setFile}
        />
      )}
    </Default>
  );
};

export default Create;
