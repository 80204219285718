import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { db } from "../../../../../dexie/db";
import Default, { useShip } from "../../../../layouts/DefaultCrew";
import Template from "../../../../templates/Crew/Arrival/Update/Confirm";
import { Form, LocationState } from "../Create/types";
import { useLiveQuery } from "dexie-react-hooks";
import { useGetPreviousReport } from "../../../../../hooks/dexie/dexie";
import { ArrivalDexie } from "../../../../../dexie/Arrival";
import { mapToArrivalMutationVariables } from "../arrival.module";

export const useGetPreviousArrival = (reportId: number) => {
  const arrivalList = useLiveQuery(
    () => db.arrival.toArray().catch((e) => console.error(e)),
    []
  );
  if (
    !arrivalList ||
    arrivalList.length === 0 ||
    !arrivalList[arrivalList.length - 1]
  ) {
    return;
  }
  const arrival = arrivalList.findIndex((v) => v.id === reportId);

  const previousArrival = arrivalList[arrival - 1];

  return previousArrival;
};

type LocationForm = {
  form: Form;
};

const Confirm = (props: LocationState): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const ship = useShip();
  const navigate = useNavigate();

  if (reportId === undefined) navigate("/");

  const report_id = Number(reportId);

  const previousReport = useGetPreviousReport(report_id);

  const location = useLocation();

  const location_form = location.state as LocationForm;

  if (!props.form && !location_form.form) {
    navigate(-1);
  }

  const form = props.form || location_form.form;

  const onUpdate = async () => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToArrivalMutationVariables(form, ship?.ship_id);

    const updateArrival: ArrivalDexie = {
      id: report_id,
      isDraft: false,
      isSend: false,
      data: variables,
    };
    db.arrival.update(Number(reportId), updateArrival);
    navigate("/arrival/create/complete");
  };

  const onBack = () => {
    navigate("/crew/arrival");
  };

  return (
    <Default title="" selectedTitle={"Arrival"}>
      <Template
        form={location_form.form}
        isLoading={false}
        onUpdate={onUpdate}
        onBack={onBack}
        reportId={report_id}
        previousReport={previousReport}
      />
    </Default>
  );
};

export default Confirm;
