import { orgRound } from "../../../../../utils/calculate";
import { isNanFinite } from "../../../../../utils/isNanFinite";

export function cal_N_ME_rpm_ave(
  N_Hrs_propelling: number | null,
  N_ME_Revolution: number | null
) {
  if (N_Hrs_propelling === null || N_ME_Revolution === null) {
    return null;
  }

  const N_ME_rpm_ave = orgRound(N_ME_Revolution / N_Hrs_propelling / 60, 1000);

  if (isNanFinite(N_ME_rpm_ave)) {
    return 0;
  }

  return N_ME_rpm_ave;
}
