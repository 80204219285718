import React from "react";
import { useLocation, useNavigate } from "react-router";
import { db } from "../../../../../dexie/db";
import { DepartureDexie } from "../../../../../dexie/Departure";
import Default, { useShip } from "../../../../layouts/DefaultCrew";
import Template from "../../../../templates/Crew/Departure/Create/Confirm";
import { Form, LocationState } from "./types";
import { mapToDepartureMutationVariables } from "../departure.module";

type LocationForm = {
  form: Form;
};

const Confirm = (props: LocationState): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const ship = useShip();

  const location_form = location.state as LocationForm;

  if (!props.form && !location_form.form) {
    alert("Input value is not set.");
    navigate(-1);
  }
  const form = props.form || location_form.form;

  const onCreate = async () => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToDepartureMutationVariables(form, ship?.ship_id);
    try {
      const newDeparture: DepartureDexie = {
        id: undefined,
        isDraft: false,
        isSend: false,
        data: variables,
        draftForm: form,
      };
      db.departure.add(newDeparture, 1);
    } catch (error) {
      console.error(error);
      alert("Failed to create data.");
      return;
    }

    navigate("/departure/create/complete");
  };

  const onBack = () => {
    navigate(`/departure/create`, { state: { form: form } });
  };

  return (
    <Default title="" selectedTitle={"Noon Report"}>
      <Template
        form={location_form.form}
        onCreate={onCreate}
        onBack={onBack}
        lastReport={undefined}
      />
    </Default>
  );
};

export default Confirm;
