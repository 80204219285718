import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Typography } from "@mui/material";

const steps = ["Create", "Confirm", "Submit"];

type Props = {
  step: number;
};

export default function Steppr(props: Props) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={props.step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              sx={{
                "& .MuiStepLabel-root": {
                  color: "#20BE89",
                  backgroundColor: "#FFFFFF",
                },
                "& .Mui-active": {
                  color: "#20BE89 !important",
                },
                "& .Mui-completed ": {
                  color: "#20BE89 !important",
                },

                "& .MuiStepper-alternativeLabel": {
                  color: "#20BE89",
                },
                "& .MuiStepLabel-iconContainer": {
                  backgroundColor: "#808080",
                  borderRadius: "60%",
                },
              }}
            >
              <Typography sx={{ color: "#FFFFFF", mt: -1.5 }}>
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
