import React from "react";
import Columns from "../../../../atoms/Columns";
import Column from "../../../../atoms/Column";
import { Form, Props } from "../../../../pages/Crew/Departure/Create/types";
import { ShipDexie } from "../../../../../dexie/Ship";
import CommonNumberFIeld from "../../../../atoms/CommonNumberFIeld";
import { FuelType } from "../../../../../gen/graphql/types";
import {
  charcoalGray,
  darkGray,
  lightGray,
  midnightBlue,
} from "../../../../../utils/color";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { setAdditionalAdditionalROBForDeparture } from "./RobAdditional.module";

type FormBlockProps = Props & {
  title: string;
  isPreview: boolean;
  onUpdateForm?: (value: Form) => void;
  ship?: ShipDexie;
  isConfirm: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
};

const formIndexZero = 0;

const RobAdditionalForDeparture = (props: FormBlockProps): React.ReactElement => {
  const D_Additional_ROB = props.form.D_Additional_ROB || [];

  const addAdditonalForm = () => {
    if (D_Additional_ROB.length > 9) return;

    D_Additional_ROB.push({
      D_Fuel_Type: FuelType.EeOh,
      D_Fuel_volume: undefined,
    });
    props.form.D_Additional_ROB = D_Additional_ROB;

    props.onUpdateForm?.({
      ...props.form,
      D_Additional_ROB: D_Additional_ROB,
    });
  };

  const deleteAdditonalForm = (deleteFormIndex: number) => {
    // indexが無効な場合
    if (deleteFormIndex < 0 || deleteFormIndex >= D_Additional_ROB.length)
      return;

    props.form.D_Additional_ROB = [
      ...D_Additional_ROB.slice(0, deleteFormIndex),
      ...D_Additional_ROB.slice(deleteFormIndex + 1),
    ];
    props.onUpdateForm?.({ ...props.form });
  };

  const onChangeConsumption = (
    index: number,
    fuelType?: FuelType,
    value?: string | number | null
  ) => {
    if (!fuelType) return;

    const form = {
      ...props.form,
      D_Additional_ROB: setAdditionalAdditionalROBForDeparture(
        fuelType,
        index,
        value ? Number(value) : undefined,
        props.form.D_Additional_ROB
      ),
    };

    props.onUpdateForm?.(form);
  };

  return (
    <>
      <Columns>
        <Column>
          <FormControl
            sx={{
              width: "250px",
              "& .MuiInputBase-input": {
                color: "#FFFFFF",
                borderColor: "#464E5A",
              },
              "& .MuiFormLabel-root": {
                color: "#AAAAAA",
                border: `1px transparent`,
                "&:hover fieldset": {
                  color: "#20BE89",
                },
              },
            }}
            size="small"
          >
            <InputLabel id="Fuel_type" sx={{ color: "#AAAAAA" }}>
              Fuel Type
            </InputLabel>
            <Select
              labelId="N_LorB"
              id={`D_Additional_ROB&_${formIndexZero}`}
              key={`D_Additional_ROB&_${formIndexZero}`}
              value={D_Additional_ROB[formIndexZero]?.D_Fuel_Type || ""}
              onChange={(e: SelectChangeEvent<string | null>) => {
                if (e && !e.target.value) return;
                onChangeConsumption(
                  formIndexZero,
                  e.target.value as FuelType,
                  D_Additional_ROB && D_Additional_ROB[formIndexZero]
                    ? D_Additional_ROB[formIndexZero].D_Fuel_volume
                    : undefined
                );
              }}
              sx={{
                width: "18ch",
                "& .MuiSelect-outlined": {
                  border: `1px solid #464E5A !important`,
                },
                "& .MuiSelect-select": {
                  color: "#FFFFFF",
                  border: `1px solid #464E5A`,
                },
                "& .MuiSelect-select:hover": {
                  color: "#FFFFFF",
                  border: `1px solid #AAAAAA !important`,
                },
                "& .MuiSelect-icon": {
                  color: "#FFFFFF",
                },
                "&:hover fieldset": {
                  border: "#FFFFFF",
                },
                textAlign: "left",
              }}
            >
              <MenuItem value={""} key={-1}>
                {""}
              </MenuItem>
              {Object.values(FuelType).map((v, index) => {
                return (
                  <MenuItem value={v} key={index}>
                    {v}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Column>
        <Column>
          <CommonNumberFIeld
            id={`D_Additional_ROB_${formIndexZero}`}
            label="Volume"
            placeholder=""
            value={
              D_Additional_ROB && D_Additional_ROB[formIndexZero]?.D_Fuel_volume
            }
            unitName={"mt"}
            minValue={0}
            maxValue={9999}
            onChange={(e: string | null) => {
              if (!D_Additional_ROB || !D_Additional_ROB[formIndexZero]) return;
              onChangeConsumption(
                formIndexZero,
                D_Additional_ROB && D_Additional_ROB[formIndexZero]
                  ? D_Additional_ROB[formIndexZero].D_Fuel_Type
                  : undefined,
                Number(e)
              );
            }}
          />
        </Column>
        <Column>
          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              height: "40px",
              width: "40px",
              fontSize: "25px",
              my: 0.1,
              color: lightGray,
              backgroundColor: darkGray,
              borderRadius: "10%",
              "&.Mui-disabled": {
                color: charcoalGray,
                backgroundColor: midnightBlue,
              },
              boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
            }}
            onClick={() => addAdditonalForm()}
          >
            +
          </IconButton>
        </Column>
        <Column>
          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              height: "40px",
              width: "40px",
              fontSize: "25px",
              my: 0.1,
              color: lightGray,
              backgroundColor: darkGray,
              borderRadius: "10%",
              "&.Mui-disabled": {
                color: charcoalGray,
                backgroundColor: midnightBlue,
              },
              boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
            }}
            onClick={() => deleteAdditonalForm(formIndexZero)}
          >
            -
          </IconButton>
        </Column>
      </Columns>
      {D_Additional_ROB &&
        D_Additional_ROB.length > 1 &&
        D_Additional_ROB.map((v, index) => {
          if (index === 0) return <></>;
          const formIndex = index;
          return (
            <>
              <Columns>
                <Column>
                  <FormControl
                    sx={{
                      width: "250px",
                      "& .MuiInputBase-input": {
                        color: "#FFFFFF",
                        borderColor: "#464E5A",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#AAAAAA",
                        border: `1px transparent`,
                        "&:hover fieldset": {
                          color: "#20BE89",
                        },
                      },
                    }}
                    size="small"
                  >
                    <InputLabel id="Fuel_type" sx={{ color: "#AAAAAA" }}>
                      Fuel Type
                    </InputLabel>
                    <Select
                      key={`Fuel-Type-${index}`}
                      labelId="N_LorB"
                      id="N_LorB"
                      label="Loading condition"
                      value={D_Additional_ROB[formIndex]?.D_Fuel_Type || ""}
                      onChange={(e: SelectChangeEvent<string | null>) => {
                        if (e && !e.target.value) return;
                        onChangeConsumption(
                          formIndex,
                          e.target.value as FuelType,
                          D_Additional_ROB && D_Additional_ROB[formIndex]
                            ? D_Additional_ROB[formIndex].D_Fuel_volume
                            : undefined
                        );
                      }}
                      sx={{
                        width: "18ch",
                        "& .MuiSelect-outlined": {
                          border: `1px solid #464E5A !important`,
                        },
                        "& .MuiSelect-select": {
                          color: "#FFFFFF",
                          border: `1px solid #464E5A`,
                        },
                        "& .MuiSelect-select:hover": {
                          color: "#FFFFFF",
                          border: `1px solid #AAAAAA !important`,
                        },
                        "& .MuiSelect-icon": {
                          color: "#FFFFFF",
                        },
                        "&:hover fieldset": {
                          border: "#FFFFFF",
                        },
                        textAlign: "left",
                      }}
                    >
                      <MenuItem value={""} key={-1}>
                        {""}
                      </MenuItem>
                      {Object.values(FuelType).map((v, index) => {
                        return (
                          <MenuItem value={v} key={index}>
                            {v}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Column>

                <Column>
                  <CommonNumberFIeld
                    key={`N_Additional_ROB_${formIndex}`}
                    id={`N_Additional_ROB_${formIndex}`}
                    label="Volume"
                    placeholder=""
                    value={
                      D_Additional_ROB &&
                      D_Additional_ROB[formIndex]?.D_Fuel_volume
                    }
                    unitName={"mt"}
                    minValue={0}
                    maxValue={9999}
                    onChange={(e: string | null) => {
                      if (!D_Additional_ROB || !D_Additional_ROB[formIndex])
                        return;
                      onChangeConsumption(
                        formIndex,
                        D_Additional_ROB && D_Additional_ROB[formIndex]
                          ? D_Additional_ROB[formIndex].D_Fuel_Type
                          : undefined,
                        Number(e)
                      );
                    }}
                  />
                </Column>
                <Column>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={Number.isNaN(
                      D_Additional_ROB[formIndex]?.D_Fuel_volume
                    )}
                    sx={{
                      height: "40px",
                      width: "40px",
                      fontSize: "25px",
                      my: 0.1,
                      color: lightGray,
                      backgroundColor: darkGray,
                      borderRadius: "10%",
                      "&.Mui-disabled": {
                        color: charcoalGray,
                        backgroundColor: midnightBlue,
                      },
                      boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                    }}
                    onClick={() => addAdditonalForm()}
                  >
                    +
                  </IconButton>
                </Column>
                <Column>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={false}
                    sx={{
                      height: "40px",
                      width: "40px",
                      fontSize: "25px",
                      my: 0.1,
                      color: lightGray,
                      backgroundColor: darkGray,
                      borderRadius: "10%",
                      "&.Mui-disabled": {
                        color: charcoalGray,
                        backgroundColor: midnightBlue,
                      },
                      boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                    }}
                    onClick={() => deleteAdditonalForm(formIndex)}
                  >
                    -
                  </IconButton>
                </Column>
              </Columns>
            </>
          );
        })}
    </>
  );
};

export default RobAdditionalForDeparture;
