import React, { useEffect, useState } from "react";
import Default from "../../../layouts/DefaultCrew";
import Template from "../../../templates/Crew/Departure/Departure";
import { Departure as DepartureType } from "./Create/types";
import { db } from "../../../../dexie/db";
import { useLiveQuery } from "dexie-react-hooks";
import {
  useContextIDs,
  useContextReportSearchCondition,
} from "../../../../App";
import { DepartureDexie } from "../../../../dexie/Departure";
import { DepartureInput } from "../../../../gen/graphql/types";
import { useDepartures } from "../../../../hooks/commonQuery/departure.hook";
import dayjs from "dayjs";
import { useCrewSearchForm } from "../crew.module";
import { ReportSearchCondition } from "../../pages.module";

export const mapToDepartureVariables = (
  report: DepartureType
): DepartureInput => {
  return {
    ...report,
    ship_id: 0,
    D_ROB_FO: 0,
    D_ROB_MGO: 0,
    D_ROB_MECO: 0,
    D_ROB_MESO: 0,
    D_ROB_GESO: 0,
    D_ROB_FW: 0,
    D_Cargo_Name: "",
    D_Cargo_Qtty: 0,
    D_Draft_Fore: 0,
    D_Draft_Aft: 0,
    D_Next_Port_Name: "",
    D_Remark: undefined,
  };
};

const Departure = (): React.ReactElement => {
  const frontDbDepature = useLiveQuery(() => db.departure.toArray(), [])?.sort(
    (a, b) =>
      dayjs(b.data.D_Departure_Date_Time).diff(
        dayjs(a.data.D_Departure_Date_Time)
      )
  );
  const { ship_id } = useContextIDs();

  const reports = useDepartures();
  const [isServerData, setIsServerData] = useState(false);

  const { reportSearchCondition, setReportSearchCondition } =
    useContextReportSearchCondition();

  const form = useCrewSearchForm(ship_id, reportSearchCondition);

  const onUpdateForm = (value: ReportSearchCondition) => {
    form.update(value);
  };

  const onUpdateSearchCondition = (
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ) => {
    form.update(value);
    if (!isWithRefresh) {
      return;
    }

    reports.refetch(form.data);
  };

  const onSearch = () => {
    form.data.pagination.page = 1;
    setReportSearchCondition(form.data);
    form.data.ship_id = ship_id;

    if (!form.data.Voy && !form.data.From_DateTime && !form.data.To_DateTime) {
      setIsServerData(false);
    } else {
      reports.refetch(form.data);
    }
  };

  const serverReportList: DepartureDexie[] = reports.data.departureList.map(
    (report) => {
      const variavle = mapToDepartureVariables(report);
      return {
        id: undefined,
        isDraft: false,
        isSend: true,
        departurId: report.id,
        data: variavle,
      };
    }
  );

  useEffect(() => {
    if (
      serverReportList.length > 0 &&
      !reports.isLoading &&
      (form.data.Voy || form.data.From_DateTime || form.data.To_DateTime)
    ) {
      setIsServerData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.data.From_DateTime,
    form.data.To_DateTime,
    form.data.Voy,
    reports.isLoading,
    serverReportList.length,
  ]);

  const deleteReport = (id: number) => {
    db.departure.delete(id).catch((e) => console.error(e));
  };

  return (
    <Default title="" selectedTitle={"Departure"}>
      <Template
        reports={isServerData ? serverReportList : frontDbDepature || []}
        deleteReport={deleteReport}
        isServerData={isServerData}
        form={form.data}
        onUpdateForm={onUpdateForm}
        totalPageCount={reports.data.totalPageCount}
        onSearch={onSearch}
        onUpdateSearchCondition={onUpdateSearchCondition}
      />
    </Default>
  );
};

export default Departure;
