import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Template from "../../../templates/Crew/NoonReport/Create";
import { Form, LocationState } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import { ReportDexie } from "../../../../dexie/Report";
import { db } from "../../../../dexie/db";
import { useLiveQuery } from "dexie-react-hooks";
import {
  mapToCreateReportMutationVariables,
  useNoonReportForm,
  useNoonReportFormValidation,
} from "./noonReport.module";
import {
  useGetLastFrontDeparture,
  useGetLastFrontReport,
} from "../../../../hooks/dexie/dexie";
import { setDefaultLastReports } from "./Create.module";

type LocationForm = {
  form: Form;
  robFile?: File;
};

const Create = (props: LocationState): React.ReactElement => {
  const navigate = useNavigate();
  const ship = useShip();
  const location = useLocation();
  const [isConfirm, setIsConfirm] = useState(false);
  const location_form = location.state as LocationForm;
  const [robFile, setFile] = useState<File | undefined>(
    location_form?.robFile || undefined
  );

  const reportList = useLiveQuery(
    () => db.reports.reverse().toArray(),
    []
  )?.filter((v) => v.isDraft === false);
  const form1 = props.form || location_form?.form || props.form;

  const lastReport = useGetLastFrontReport();
  const lastDeparture = useGetLastFrontDeparture();

  const form = useNoonReportForm([form1]);
  const formValidation = useNoonReportFormValidation(
    form.data,
    reportList,
    lastReport
  );

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };
  const onSaveDraft = (value: Form) => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToCreateReportMutationVariables(value, ship?.ship_id);

    //既存ユーザーのデータと一覧表示のために、dataも必須で登録する
    const newReport: ReportDexie = {
      id: undefined,
      isDraft: true,
      isSend: false,
      data: variables,
      draftForm: value,
      robFile: value.N_ROB_Filename ? robFile : undefined,
    };

    db.reports.add(newReport, 1).catch((e) => console.error(e));
    navigate(`/`);
  };

  const onConfirm = () => {
    setIsConfirm(true);

    formValidation.manageNoonReportErrorMessage();
    formValidation.hasErrorScrollUp();
    if (formValidation.hasError) {
      return;
    }

    navigate("/create/confirm", {
      state: { form: form.data, robFile },
    });
  };

  useEffect(() => {
    if (
      location_form?.form ||
      (!lastReport?.draftForm && !lastDeparture?.draftForm)
    )
      return;

    setDefaultLastReports(
      form.data,
      form.update,
      lastReport?.draftForm,
      lastDeparture
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lastReport?.draftForm?.N_ROB_FO,
    lastDeparture?.draftForm?.D_Departure_Port,
  ]);

  return (
    <Default title="Noon Report" selectedTitle={"Noon Report"}>
      <Template
        form={form.data}
        hasFormError={formValidation.hasError}
        onUpdateForm={onUpdateForm}
        onConfirm={onConfirm}
        onSaveDraft={onSaveDraft}
        ship={ship}
        lastReport={lastReport}
        isConfirm={isConfirm}
        setIsConfirm={setIsConfirm}
        setFile={setFile}
        errorMessageList={formValidation.errorMessageList}
      />
    </Default>
  );
};

export default Create;
