import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ReferenceDataType } from "../../../../../../gen/graphql/types";
import { ReferenceDataTypeIndex } from "../../../../../pages/Owner/Analysis/types";

type Props = {
  setFormType: React.Dispatch<React.SetStateAction<ReferenceDataTypeIndex>>;
  formType?: ReferenceDataTypeIndex;
  isDisabel: boolean;
};

export default function SetingRadioGroup(props: Props) {
  const handleChange = (event: { target: { value: string } }) => {
    props.setFormType(
      event.target.value === ReferenceDataType.Speed
        ? ReferenceDataType.Speed
        : ReferenceDataType.Foc
    );
  };

  return (
    <FormControl
      sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      defaultValue={props.formType}
    >
      <FormLabel id="row-radio-buttons-group-label" sx={{ mr: 2 }}>
        Setting Basis
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={props.formType}
        onChange={handleChange}
      >
        <FormControlLabel
          value={ReferenceDataType.Speed}
          control={<Radio />}
          label="speed Basis"
          disabled={props.isDisabel}
        />
        <FormControlLabel
          value={ReferenceDataType.Foc}
          control={<Radio />}
          label="Foc Basis"
          disabled={props.isDisabel}
        />
      </RadioGroup>
    </FormControl>
  );
}
