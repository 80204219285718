import React, { useState } from "react";
import Default from "../../../layouts/DefaultCrew";
import Template from "../../../templates/Crew/Cargo";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../dexie/db";
import dayjs from "dayjs";
import { ReportSearchCondition } from "../../pages.module";
import {
  useContextIDs,
  useContextReportSearchCondition,
} from "../../../../App";
import { useCrewSearchForm } from "../crew.module";
import { useCargo } from "../../../../hooks/commonQuery/cargo.hook";
import { CargoDexie } from "../../../../dexie/Cargo";
import { mapToCargoFormData } from "./cargo.module";

const Cargo = (): React.ReactElement => {
  const { reportSearchCondition, setReportSearchCondition } =
    useContextReportSearchCondition();

  const { ship_id } = useContextIDs();
  const seachform = useCrewSearchForm(ship_id, reportSearchCondition);
  const reports = useCargo();
  const [isServerData, setIsServerData] = useState(false);

  const frontDbCargo =
    useLiveQuery(() => db.cargo.toArray(), [])?.sort((a, b) =>
      dayjs(b.draftForm?.C_DateTime).diff(dayjs(a.draftForm?.C_DateTime))
    ) || [];

  const onUpdateForm = (value: ReportSearchCondition) => {
    seachform.update(value);
  };

  const onUpdateSearchCondition = (
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ) => {
    seachform.update(value);
    if (!isWithRefresh) {
      return;
    }

    reports.refetch(seachform.data);
  };

  const serverReportList: CargoDexie[] = reports.data.cargoList.map(
    (report) => {
      const form = mapToCargoFormData(report);
      return {
        id: undefined,
        isDraft: false,
        isSend: true,
        reportId: report.id,
        data: undefined,
        draftForm: form,
      };
    }
  );

  const deleteReport = (report_id: number) => {
    db.cargo.delete(report_id).catch((e) => console.error(e));
  };

  const onSearch = () => {
    seachform.data.pagination.page = 1;

    seachform.data.ship_id = ship_id;

    if (
      !seachform.data.Voy &&
      !seachform.data.From_DateTime &&
      !seachform.data.To_DateTime
    ) {
      setIsServerData(false);
    } else {
      reports.refetch(seachform.data);
      setReportSearchCondition(seachform.data);
      setIsServerData(true);
    }
  };

  return (
    <Default title="" selectedTitle={"Cargo"}>
      <Template
        cargoReports={isServerData ? serverReportList : frontDbCargo || []}
        isServerData={isServerData}
        deleteReport={deleteReport}
        onUpdateForm={onUpdateForm}
        form={seachform.data}
        onSearch={onSearch}
        totalPageCount={reports.data.totalPageCount}
        onUpdateSearchCondition={onUpdateSearchCondition}
      />
    </Default>
  );
};

export default Cargo;
