import React from "react";
import { useLocation, useNavigate } from "react-router";
import { db } from "../../../../../dexie/db";
import { ReportDexie } from "../../../../../dexie/Report";
import Default, { useShip } from "../../../../layouts/DefaultCrew";
import Template from "../../../../templates/Crew/NoonReport/Create/Confirm";
import { useGetLastFrontReport } from "../../../../../hooks/dexie/dexie";
import { Form, LocationState } from "./types";
import { mapToCreateReportMutationVariables } from "../noonReport.module";

type LocationForm = {
  form: Form;
  robFile?: File;
};

const Confirm = (props: LocationState): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const ship = useShip();
  const lastReport = useGetLastFrontReport();

  const location_form = location.state as LocationForm;

  if (!props.form && !location_form?.form) {
    console.error("Input value is not set.");
    alert("Input value is not set.");
    navigate(-1);
  }
  const form = props.form || location_form?.form;

  const onCreate = async () => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToCreateReportMutationVariables(form, ship?.ship_id);

    try {
      const newReport: ReportDexie = {
        id: undefined,
        isDraft: false,
        isSend: false,
        data: variables,
        draftForm: form,
        robFile: location_form.robFile,
      };
      db.reports.add(newReport, 1);
    } catch (error) {
      alert("Failed to create data.");
      return;
    }

    navigate("/create/complete");
  };

  const onBack = () => {
    navigate(`/crew/create`, {
      state: { form: form, robFile: lastReport?.robFile },
    });
  };

  return (
    <Default title="" selectedTitle={"Noon Report"}>
      <Template
        form={location_form.form}
        onCreate={onCreate}
        onBack={onBack}
        lastReport={lastReport}
      />
    </Default>
  );
};

export default Confirm;
