import { safeNumber } from "../../../../../utils/formats";
import { BaselineTitleList } from "../const";

import { SpeedForm } from "./Create/types";
import { getSettingReport } from "./settings.moble";

export const getSettingGraphData = (form: SpeedForm) => {
  const report: (string | number | null)[][] =
    form.speed_OG_1 !== null && form.empty_1 !== null
      ? [
          [
            "N_Speed_OG",
            BaselineTitleList.Ballast,
            BaselineTitleList.Laden,
            BaselineTitleList.Unique,
            "",
            "",
            "",
          ],
          [NaN, NaN, NaN, NaN, NaN, NaN, NaN],
          [
            safeNumber(form.speed_OG_1),
            safeNumber(form.empty_1),
            safeNumber(form.laden_1),
            safeNumber(form.unique_1),
            NaN,
            NaN,
            NaN,
          ],
          [
            safeNumber(form.speed_OG_2),
            safeNumber(form.empty_2),
            safeNumber(form.laden_2),
            safeNumber(form.unique_2),
            NaN,
            NaN,
            NaN,
          ],
          [
            safeNumber(form.speed_OG_3),
            safeNumber(form.empty_3),
            safeNumber(form.laden_3),
            safeNumber(form.unique_3),
            NaN,
            NaN,
            NaN,
          ],
          [
            safeNumber(form.speed_OG_4),
            safeNumber(form.empty_4),
            safeNumber(form.laden_4),
            safeNumber(form.unique_4),
            NaN,
            NaN,
            NaN,
          ],
          [
            safeNumber(form.speed_OG_5),
            safeNumber(form.empty_5),
            safeNumber(form.laden_5),
            safeNumber(form.unique_5),
            NaN,
            NaN,
            NaN,
          ],
          [
            safeNumber(form.speed_OG_6),
            safeNumber(form.empty_6),
            safeNumber(form.laden_6),
            safeNumber(form.unique_6),
            NaN,
            NaN,
            NaN,
          ],
          [
            safeNumber(form.speed_OG_7),
            safeNumber(form.empty_7),
            safeNumber(form.laden_7),
            safeNumber(form.unique_7),
            NaN,
            NaN,
            NaN,
          ],
          [
            form.speed_OG_8,
            safeNumber(form.empty_8),
            safeNumber(form.laden_8),
            safeNumber(form.unique_8),
            NaN,
            NaN,
            NaN,
          ],
          [
            safeNumber(form.speed_OG_9),
            safeNumber(form.empty_9),
            safeNumber(form.laden_9),
            safeNumber(form.unique_9),
            NaN,
            NaN,
            NaN,
          ],
          [
            safeNumber(form.speed_OG_10),
            safeNumber(form.empty_10),
            safeNumber(form.laden_10),
            safeNumber(form.unique_10),
            NaN,
            NaN,
            NaN,
          ],
        ]
      : [];

  if (report.length === 0) return [];

  return getSettingReport(report);
};
