import FileSaver from "file-saver";
import mime from "mime";

export const downloadFile = (url: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // ファイルをダウンロード
      FileSaver(
        blob,
        `${Date.now().toString()}.${mime.getExtension(blob.type)}`
      );
    })
    .catch((e) => {
      console.error(e);
      alert("failed to download file.");
    });
};
