import { useEffect, useState } from "react";

type Maybe<T> = T | null | undefined;

const hasInitialize = <T>(value: Maybe<T>): value is T => !!value;

export const useLazyInitializeState = <T>(
  initialize: T,
  lazyInitializes: Maybe<T>[]
): [boolean, T, React.Dispatch<React.SetStateAction<T>>] => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [data, setData] = useState<T>(initialize);

  useEffect(() => {
    if (isInitialized) {
      return;
    }

    const lazyInitialize = lazyInitializes.find(
      (lazyInitialize) => !!lazyInitialize
    );

    if (!hasInitialize(lazyInitialize)) {
      return;
    }

    setIsInitialized(true);

    setData(lazyInitialize);

    setIsLoading(false);
  }, [isInitialized, lazyInitializes]);

  return [isLoading, data, setData];
};
