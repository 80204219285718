import { useDownloadRobFileLazyQuery } from "../../../../gen/graphql/types";

import { downloadFile } from "../../../../utils/downloadFile";

export const useDownloadRobFileQuery = (cargoFileId: number) => {
  const [getData, { loading: isLoading, error }] =
    useDownloadRobFileLazyQuery();

  const onDownload = () => {
    getData({
      variables: { id: cargoFileId },
      onCompleted: (data) => {
        if (data?.downloadRobFile?.url) {
          downloadFile(data.downloadRobFile.url);
        } else {
          console.error(error);
          alert("Failed to obtain download URL.");
        }
      },
    });
  };

  return {
    isLoading,
    onDownload,
  };
};
