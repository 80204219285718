import React from "react";
import type { ReactNode } from "react";
import { BaseErrorBoundary } from "./BaseErrorBoundary";

export const ReportingErrorBoundary = ({
  children,
}: {
  children: ReactNode;
}) => (
  <BaseErrorBoundary
    onError={(error) => console.error("report", error)}
    fallbackRender={(error) => {
      // only for visualize
      if (error instanceof Error) {
        return (
          <>
            reported!!: {error.name}: {error.message}
          </>
        );
      }
      return <>reported!!: {JSON.stringify(error)}</>;
    }}
  >
    {children}
  </BaseErrorBoundary>
);
