import { getOffsetForLongitude } from "../../../../../utils/calc-timezone";
import { CargoForm } from "../../../../pages/Crew/Cargo/type";

type TimezoneProps = {
  form: CargoForm;
};

export function calculateCargoTimezone(props: TimezoneProps) {
  const timezone = getOffsetForLongitude({
    longDirection: props.form.C_Long_Direction,
    longDeg: props.form.C_Long_Deg,
    longMin: props.form.C_Long_Min,
  });

  if (timezone) {
    props.form.C_TimeZone_PorN = timezone.TimeZone_PorN;
    props.form.C_TimeZone_HH = timezone.TimeZone_HH;
    props.form.C_TimeZone_MM = timezone.TimeZone_MM;
  }

  return props.form;
}
