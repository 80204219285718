import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../dexie/db";
import Template from "../../../templates/Crew/Bunkering/Update";
import { useGetPreviousBunkering } from "./Update/Confirm";
import Loading from "../../../atoms/Loading";
import {
  mapToBunkeringFormData,
  mapToUpdateBunkeringMutationVariables,
  useBunkeringForm,
} from "./bunkering.module";
import { BunkeringDexie } from "../../../../dexie/Bunkering";

const Update = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);

  const navigate = useNavigate();
  const ship = useShip();
  const [isConfirm, setIsConfirm] = useState(false);
  const location = useLocation();
  const location_form = location.state;

  if (!report_id) navigate("/");

  const previousBunkering = useGetPreviousBunkering(report_id);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const onSaveDraft = (value: Form) => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToUpdateBunkeringMutationVariables(
      value,
      ship.ship_id
    );
    const updateBunkering: BunkeringDexie = {
      id: report_id,
      isDraft: true,
      isSend: false,
      data: variables,
      draftForm: value,
      bunkeringFile: value.B_Filename ? bunkeringFile : undefined,
    };
    db.bunkering
      .update(report_id, updateBunkering)
      .catch((e) => console.error(e));
    navigate(`/crew/bunkering`);
  };

  const onConfirm = () => {
    setIsConfirm(true);

    form.hasErrorScrollUp();

    if (form.hasError) return;
    navigate(`/bunkering/update/confirm/${reportId}`, {
      state: { form: form.data },
    });
  };

  const bunkering = useLiveQuery(() => db.bunkering.get(report_id));
  const [bunkeringFile, setFile] = useState<File | undefined>(
    bunkering?.bunkeringFile
  );

  const form = useBunkeringForm([
    location_form,
    bunkering?.draftForm,
    mapToBunkeringFormData(bunkering?.data),
  ]);

  return (
    <Default title="Noon Report" selectedTitle={"Bunkering"}>
      {bunkering && !form.isLoading ? (
        <Template
          defaultBunkering={bunkering}
          form={form.data}
          hasFormError={form.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          onSaveDraft={onSaveDraft}
          ship={ship}
          lastBunkering={previousBunkering}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
          setFile={setFile}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Default>
  );
};

export default Update;
