import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { db } from "../../../../../dexie/db";
import Default from "../../../../layouts/DefaultCrew";
import Template from "../../../../templates/Crew/NoonReport/ServerUpdate/Confirm";
import { Form } from "../Create/types";
import { LocationState } from "./types";
import { useLiveQuery } from "dexie-react-hooks";
import {
  QueryStatus,
  ReportInput,
  useUpdateReportMutation,
} from "../../../../../gen/graphql/types";
import { pageData, useContextIDs } from "../../../../../App";
import { mapToCreateReportMutationVariables } from "../noonReport.module";
import { uploadFile } from "../../../../../utils/uploadFile.module";
import useErrorMessageList from "../../../../../hooks/errorMessage.hook";
import { NoonReportDuplicateError } from "../../../../../utils/error";

const mapToUpdateReportMutationVariables = (
  form: Form,
  report_id: number,
  ship_id: number
): ReportInput => {
  const mutationVariables = mapToCreateReportMutationVariables(form, ship_id);

  return {
    ...mutationVariables,
    id: report_id,
  };
};

export const useGetPreviousReport = (reportId: number) => {
  const reportList = useLiveQuery(
    () => db.reports.toArray().catch((e) => console.error(e)),
    []
  );
  if (
    !reportList ||
    reportList.length === 0 ||
    !reportList[reportList.length - 1]
  ) {
    return;
  }
  const report = reportList.findIndex((v) => v.id === reportId);

  const previousReport = reportList[report - 1];

  return previousReport;
};

type LocationForm = {
  form: Form;
  robFile?: File;
};

const Confirm = (props: LocationState): React.ReactElement => {
  const { ship_id } = useContextIDs();
  const { reportId } = useParams<"reportId">();

  const navigate = useNavigate();

  const [updateReport, { error }] = useUpdateReportMutation();
  const { errorMessageList, addErrorMessage } = useErrorMessageList();

  if (reportId === undefined) navigate("/");

  const report_id = Number(reportId);

  const location = useLocation();

  const location_form = location.state as LocationForm;

  if (!props.form && !location_form.form) {
    navigate(-1);
  }

  const form = props.form || location_form.form;

  const onUpdate = async () => {
    if (errorMessageList.length > 0) return;
    try {
      const variables = mapToUpdateReportMutationVariables(
        form,
        report_id,
        ship_id
      );
      updateReport({
        fetchPolicy: "network-only",
        variables: { input: variables },
        onCompleted: async (data) => {
          try {
            if (data.updateReport.UpdateReportStatus === QueryStatus.DuplicateError) {
              throw NoonReportDuplicateError;
            }

            if (data.updateReport.isFileUpload && location_form.robFile) {
              const file = await uploadFile({
                remoteFilePath: data.updateReport.gcsFilePath,
                fileContent: location_form.robFile,
              });
              if (!file.sucsess) {
                //ファイルアップロードに失敗した場合は、DBのファイル名を削除する。
                location_form.form.N_ROB_Filename = undefined;
                await updateReport({
                  fetchPolicy: "network-only",
                  variables: { input: { ...variables, N_ROB_Filename: null } },
                  onError: (error) => {
                    console.error(error);
                    addErrorMessage({
                      input: JSON.stringify(variables),
                      errorMessage: `Failed to delete file name. ${error}`,
                    });
                  },
                });
                throw `File upload error. Data update succeeded. ${JSON.stringify(
                  file
                )}`;
              }
            }
            navigate(pageData.crewCreateReportComplete.path());
          } catch (error) {
            console.error(error);
            addErrorMessage({
              input: JSON.stringify(variables),
              errorMessage: `Server Update error! ${error}`,
            });
          }
        },
        onError: (error) => {
          console.error(error);
          addErrorMessage({
            input: JSON.stringify(variables),
            errorMessage: `Server Update error! ${error}`,
          });
        },
      });
    } catch (e) {
      console.error(e);
      console.error(error);
      return;
    }
  };

  const onBack = () => {
    if (!reportId) return;
    navigate(pageData.serverUpdateReport.path(reportId), {
      state: { form: form },
    });
  };

  return (
    <Default title="" selectedTitle={"Noon Report"}>
      <Template
        form={location_form.form}
        isLoading={false}
        onUpdate={onUpdate}
        onBack={onBack}
        reportId={report_id}
        errorMessageList={errorMessageList}
      />
    </Default>
  );
};

export default Confirm;
