import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useAuth0 } from "@auth0/auth0-react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../dexie/db";
import { ShipDexie } from "../../dexie/Ship";
import {
  authCheckOwner,
  pageData,
  useSetShipId,
  useContextIDs,
} from "../../App";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { CrewBgColor } from "../../utils/color";
import { GlobalFooter } from "./GlobalFooter/GlobalFooter";
import { RouteCrewSelectShip } from "../../RouteConfig";
import { useCrewSyncShip } from "./hooks/useCrewSyncShip";
import { MUILink } from "../atoms/Link/Link";

const BootstrapButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "1px 25px",
  border: "1px solid",
  lineHeight: 1.5,
  height: 24,
  width: 98,
  backgroundColor: "#1A1C21",
  borderColor: "#F5F5F5",
  fontFamily: ["Public Sans"].join(","),
  "&:hover": {
    backgroundColor: "#20BE89",
    borderColor: "#20BE89",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const mdTheme = createTheme({
  typography: { fontFamily: "Public Sans" },
  palette: {
    mode: "dark",
    primary: {
      main: "#20BE89",
      contrastText: "#fff",
    },
  },
});

export const useShip = () => {
  const ships = useLiveQuery(
    () =>
      db.ship.toArray().catch((e) => {
        console.error(e);
      }),
    []
  );

  if (!ships || ships.length === 0 || !ships[0]) {
    return;
  }
  return ships[0];
};

export type SelectedTitle =
  | "Noon Report"
  | "Departure"
  | "Arrival"
  | "Bunkering"
  | "Cargo"
  | "Sending MGMT";

const setSelectedTitleColor = (selectedTitle: SelectedTitle, title: string) => {
  return selectedTitle === title ? "#20BE89" : "";
};

const headerItemWidth = 1.3;

type DefaultProps = {
  title: string;
  children?: React.ReactNode;
  selectedTitle: SelectedTitle;
};

const isShip = async () => {
  const ship = await db.ship.toArray();
  return ship.length > 0 && ship[0];
};

const Content = (props: DefaultProps) => {
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
    logout,
    loginWithPopup,
  } = useAuth0();

  const setShipIdContext = useSetShipId();
  const { ship_id: ship_id_context } = useContextIDs();

  const [shipDexie, setShipDexie] = useState<ShipDexie>();

  useEffect(() => {
    async function InitialLoginCheck() {
      const ship = await isShip();

      if (ship) {
        !ship_id_context && setShipIdContext(ship.ship_id);
        setShipDexie(ship);
      }

      if (isLoading) return;
      if (!user) return;

      if (!isAuthenticated && !ship) {
        loginWithRedirect();
      }

      if (authCheckOwner(user)) {
        navigate(pageData.ownerRoot.path());
        return <></>;
      }

      if (!ship) {
        navigate(RouteCrewSelectShip);
      }
    }
    InitialLoginCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    navigate,
    ship_id_context,
    user,
  ]);

  // リロードした時に実行される処理
  useCrewSyncShip();

  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          backgroundColor: CrewBgColor,
          width: "100%",
          pl: -10,
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            pt: 3,
            mr: -20,
            display: "flex",
            color: "#FFFFFF",
            justifyContent: "center",
            backgroundColor: "#1A1C21",
            minWidth: "1240px",
            margin: "0 auto",
            mb: 1,
          }}
        >
          <Box
            component="img"
            sx={{
              height: 26,
              width: 160,
              mr: 1.5,
              justifyContent: "center",
              mt: 0.2,
            }}
            alt=""
            src="/images/seawise_crew.png"
          />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, textAlign: "left", fontSize: 18 }}
          >
            {shipDexie?.name}
            <span style={{ color: "#AAAAAA" }}>
              {shipDexie ? `(${shipDexie?.IMO_No})` : ""}
            </span>
          </Typography>
          <MUILink
            color="inherit"
            underline="none"
            to="/"
            style={{
              paddingLeft: headerItemWidth,
              paddingRight: headerItemWidth,
              fontSize: 18,
              color: `${setSelectedTitleColor(
                props.selectedTitle,
                "Noon Report"
              )}`,
            }}
          >
            Noon
          </MUILink>
          <MUILink
            color="inherit"
            underline="none"
            to={pageData.crewDeparture.path()}
            style={{
              paddingLeft: headerItemWidth,
              paddingRight: headerItemWidth,
              fontSize: 18,
              color: `${setSelectedTitleColor(
                props.selectedTitle,
                "Departure"
              )}`,
            }}
          >
            Departure
          </MUILink>
          <MUILink
            color="inherit"
            underline="none"
            to="/crew/arrival"
            style={{
              paddingLeft: headerItemWidth,
              paddingRight: headerItemWidth,
              fontSize: 18,
              color: `${setSelectedTitleColor(props.selectedTitle, "Arrival")}`,
            }}
          >
            Arrival
          </MUILink>
          {shipDexie?.is_mrv_portal_integration_target && (
            <MUILink
              color="inherit"
              underline="none"
              to="/crew/bunkering"
              style={{
                paddingLeft: headerItemWidth,
                paddingRight: headerItemWidth,
                fontSize: 18,
                color: `${setSelectedTitleColor(
                  props.selectedTitle,
                  "Bunkering"
                )}`,
              }}
            >
              Bunkering
            </MUILink>
          )}
          {shipDexie?.is_mrv_portal_integration_target && (
            <MUILink
              color="inherit"
              underline="none"
              to="/crew/cargo"
              style={{
                paddingLeft: headerItemWidth,
                paddingRight: headerItemWidth,
                fontSize: 18,
                color: `${setSelectedTitleColor(props.selectedTitle, "Cargo")}`,
              }}
            >
              Cargo
            </MUILink>
          )}
          <MUILink
            color="inherit"
            underline="none"
            to="/sendData"
            style={{
              paddingLeft: headerItemWidth,
              paddingRight: 1.5,
              fontSize: 18,
              color: `${setSelectedTitleColor(
                props.selectedTitle,
                "Sending MGMT"
              )}`,
            }}
          >
            Sending
          </MUILink>
          {!isLoading && isAuthenticated ? (
            <BootstrapButton
              loading={isLoading}
              variant="outlined"
              color="inherit"
              onClick={() => logout({ returnTo: window.location.origin })}
              sx={{
                borderRadius: "30px",
                mt: 0.25,
                mr: 1.5,
              }}
            >
              LOGOUT
            </BootstrapButton>
          ) : (
            <BootstrapButton
              loading={isLoading}
              variant="outlined"
              color="inherit"
              onClick={loginWithPopup}
              sx={{
                borderRadius: "30px",
                mt: 0.25,
                mr: 1.5,
              }}
            >
              LOGIN
            </BootstrapButton>
          )}
        </Box>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "dark"
                ? "#1A1C21"
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100%",
            overflow: "auto",
            width: "100%",
            pl: -10,
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              mt: 1,
              mb: 4,
              width: "1240px",
              "&.MuiContainer-root": {
                px: 0,
                margin: "0 auto",
              },
            }}
          >
            <Grid item xs={12}>
              {props.children}
            </Grid>
          </Container>
        </Box>
        <GlobalFooter />
      </Box>
    </ThemeProvider>
  );
};

const Default = (props: DefaultProps): React.ReactElement => {
  return (
    <Content title={props.title} selectedTitle={props.selectedTitle}>
      {props.children}
    </Content>
  );
};

export default Default;
