import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Template from "../../../templates/Crew/Departure/ServerUpdate";
import { Form } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import {
  LorB,
  useGetDepartureQuery,
  GetDepartureQuery,
  LorU,
} from "../../../../gen/graphql/types";
import { pageData, useContextIDs } from "../../../../App";
import { emptyToDoubleQuotation } from "../../../../utils/formats";
import Loading from "../../../atoms/Loading";
import { useDepartureForm } from "./departure.module";

const useGetReport = (report_id: number, ship_id: number) => {
  const input = { id: report_id, ship_id };
  const { data: response, loading: isLoading } = useGetDepartureQuery({
    variables: { input },
    fetchPolicy: "network-only",
  });

  const defaultTimeZone = response?.getDeparture?.D_Time_Zone || "+00:00";
  const form = useMemo(() => mapToFormDepartureData(response), [response]);

  return {
    isLoading,
    form,
    defaultTimeZone,
  };
};

const mapToFormDepartureData = (response?: GetDepartureQuery): Form | null => {
  if (!response) return null;

  const report = response.getDeparture;

  if (!report) return null;
  const D_Vessel_Name = report.D_Vessel_Name;
  const D_Voy = report?.D_Voy;
  const D_Last_Voy = report?.D_Last_Voy;
  const D_LorB = report?.D_LorB || null;

  const D_Departure_Port = report?.D_Departure_Port;
  const D_EU_UK_port = report.D_EU_UK_port;
  const D_Port_L_U = report.D_Port_L_U;

  const D_Departure_Date_Time = report?.D_Departure_Date_Time;
  const D_TimeZone_PorN = String(report?.D_Time_Zone).slice(0, 1);
  const D_TimeZone_HH = String(report?.D_Time_Zone).slice(1, 3);
  const D_TimeZone_MM = String(report?.D_Time_Zone).slice(4, 6);
  const D_RU_Date_Time = report?.D_RU_Date_Time;

  const D_ROB_FO = report?.D_ROB_FO;
  const D_ROB_MGO = report?.D_ROB_MGO;
  const D_ROB_MECO = report?.D_ROB_MECO;
  const D_ROB_MESO = report?.D_ROB_MESO;
  const D_ROB_GESO = report?.D_ROB_GESO;
  const D_ROB_FW = report?.D_ROB_FW;
  const D_Cargo_Name = report?.D_Cargo_Name;
  const D_Cargo_Qtty = report?.D_Cargo_Qtty;
  const D_Draft_Fore = report?.D_Draft_Fore;
  const D_Draft_Aft = report?.D_Draft_Aft;
  const D_Next_Port_Name = report?.D_Next_Port_Name;
  const D_ETA = report?.D_ETA;
  const D_ETB = report?.D_ETB;
  const D_ETC = report?.D_ETC;
  const D_ETD = report?.D_ETD;

  const D_Remark = report?.D_Remark || "";

  return {
    D_Vessel_Name,
    D_Voy,
    D_Last_Voy,
    D_LorB: D_LorB || LorB.Ballast,
    D_Departure_Port,
    D_EU_UK_port,
    D_Port_L_U: D_Port_L_U || LorU.N,
    D_UNLOC: emptyToDoubleQuotation(report.D_UNLOC),
    D_Departure_Date_Time,
    D_RU_Date_Time,
    D_TimeZone_PorN,
    D_TimeZone_HH,
    D_TimeZone_MM,
    D_ROB_FO,
    D_ROB_MGO,
    D_ROB_MECO,
    D_ROB_MESO,
    D_ROB_GESO,
    D_ROB_FW,
    D_Cargo_Name,
    D_Cargo_Qtty,
    D_Draft_Fore,
    D_Draft_Aft,
    D_Next_Port_Name,
    D_ETA,
    D_ETB,
    D_ETC,
    D_ETD,
    D_Remark,
    D_Spare_1: emptyToDoubleQuotation(report.D_Spare_1),
    D_Spare_1_unit_name: emptyToDoubleQuotation(report.D_Spare_1_unit_name),
    D_Spare_2: emptyToDoubleQuotation(report.D_Spare_2),
    D_Spare_2_unit_name: emptyToDoubleQuotation(report.D_Spare_2_unit_name),
    D_Spare_3: emptyToDoubleQuotation(report.D_Spare_3),
    D_Spare_3_unit_name: emptyToDoubleQuotation(report.D_Spare_3_unit_name),
    D_Spare_4: emptyToDoubleQuotation(report.D_Spare_4),
    D_Spare_4_unit_name: emptyToDoubleQuotation(report.D_Spare_4_unit_name),
    D_Spare_5: emptyToDoubleQuotation(report.D_Spare_5),
    D_Spare_5_unit_name: emptyToDoubleQuotation(report.D_Spare_5_unit_name),
    D_Spare_6: emptyToDoubleQuotation(report.D_Spare_6),
    D_Spare_6_unit_name: emptyToDoubleQuotation(report.D_Spare_6_unit_name),
    D_Spare_7: emptyToDoubleQuotation(report.D_Spare_7),
    D_Spare_7_unit_name: emptyToDoubleQuotation(report.D_Spare_7_unit_name),
    D_Spare_8: emptyToDoubleQuotation(report.D_Spare_8),
    D_Spare_8_unit_name: emptyToDoubleQuotation(report.D_Spare_8_unit_name),
    D_Spare_9: emptyToDoubleQuotation(report.D_Spare_9),
    D_Spare_9_unit_name: emptyToDoubleQuotation(report.D_Spare_9_unit_name),
    D_Spare_10: emptyToDoubleQuotation(report.D_Spare_10),
    D_Spare_10_unit_name: emptyToDoubleQuotation(report.D_Spare_10_unit_name),
    D_Spare_11: emptyToDoubleQuotation(report.D_Spare_11),
    D_Spare_11_unit_name: emptyToDoubleQuotation(report.D_Spare_11_unit_name),
    D_Spare_12: emptyToDoubleQuotation(report.D_Spare_12),
    D_Spare_12_unit_name: emptyToDoubleQuotation(report.D_Spare_12_unit_name),
    D_Spare_13: emptyToDoubleQuotation(report.D_Spare_13),
    D_Spare_13_unit_name: emptyToDoubleQuotation(report.D_Spare_13_unit_name),
    D_Spare_14: emptyToDoubleQuotation(report.D_Spare_14),
    D_Spare_14_unit_name: emptyToDoubleQuotation(report.D_Spare_14_unit_name),
    D_Spare_15: emptyToDoubleQuotation(report.D_Spare_15),
    D_Spare_15_unit_name: emptyToDoubleQuotation(report.D_Spare_15_unit_name),
    D_Spare_16: emptyToDoubleQuotation(report.D_Spare_16),
    D_Spare_16_unit_name: emptyToDoubleQuotation(report.D_Spare_16_unit_name),
    D_Spare_17: emptyToDoubleQuotation(report.D_Spare_17),
    D_Spare_17_unit_name: emptyToDoubleQuotation(report.D_Spare_17_unit_name),
    D_Spare_18: emptyToDoubleQuotation(report.D_Spare_18),
    D_Spare_18_unit_name: emptyToDoubleQuotation(report.D_Spare_18_unit_name),
    D_Spare_19: emptyToDoubleQuotation(report.D_Spare_19),
    D_Spare_19_unit_name: emptyToDoubleQuotation(report.D_Spare_19_unit_name),
    D_Spare_20: emptyToDoubleQuotation(report.D_Spare_20),
    D_Spare_20_unit_name: emptyToDoubleQuotation(report.D_Spare_20_unit_name),
  };
};

const ServerUpdate = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const { ship_id } = useContextIDs();
  const navigate = useNavigate();
  const ship = useShip();
  const [isConfirm, setIsConfirm] = useState(false);
  const location = useLocation();

  if (!report_id) navigate("/");

  const formData = useGetReport(report_id, ship_id);

  const form = useDepartureForm([location.state?.form, formData.form]);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const onConfirm = () => {
    form.hasErrorScrollUp();
    setIsConfirm(true);
    if (form.hasError) return;
    navigate(pageData.serverUpdateDepartureConfirm.path(String(report_id)), {
      state: { form: form.data },
    });
  };

  return (
    <Default title="Noon Report" selectedTitle={"Departure"}>
      {!formData.isLoading && !form.isLoading ? (
        <Template
          defaultTimeZone={formData.defaultTimeZone}
          form={form.data}
          reportId={reportId || ""}
          hasFormError={form.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          ship={ship}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Default>
  );
};

export default ServerUpdate;
