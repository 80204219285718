import React from "react";
import { Props } from "../../../../pages/Crew/NoonReport/Create/types";
import { ShipDexie } from "../../../../../dexie/Ship";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { getFileName, setFileName } from "../../../../../utils/form-util";
import { useShip } from "../../../../layouts/DefaultCrew";
import ClearIcon from "@mui/icons-material/Clear";

type FormBlockProps = Props & {
  title: string;
  isPreview: boolean;
  onChange: (
    key: string,
    value: string | number | Date | null | undefined
  ) => void;
  ship?: ShipDexie;
  isConfirm: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
};

const RobFileUpload = (props: FormBlockProps): React.ReactElement => {
  const ship = useShip();

  return (
    <>
      <Box
        sx={{
          mt: 3,
          opacity: 1,
          border: "dashed 1px #AAAAAA",
          borderRadius: "10px",
          width: "80%",
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent={"center"}>
          <Typography>{getFileName(props.form.N_ROB_Filename)}</Typography>
          {props.form.N_ROB_Filename && (
            <IconButton onClick={() => props.onChange("N_ROB_Filename", "")}>
              <ClearIcon />
            </IconButton>
          )}
        </Stack>
        <Button variant="outlined" component="label">
          Upload
          <input
            type="file"
            hidden
            onChange={(event) => {
              const file = event.target.files?.item(0);
              if (file instanceof File) {
                props.setFile(file);

                props.onChange(
                  "N_ROB_Filename",
                  setFileName(file.name, ship?.IMO_No || "")
                );
              } else {
                console.warn("File does not exist");
              }
            }}
          />
        </Button>
      </Box>
    </>
  );
};

export default RobFileUpload;
