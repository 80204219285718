import { useCallback } from "react";
import { useRhfSampleForm } from "./schemaRhfSample";

export const useRhfSample = () => {
  const rhfSampleForm = useRhfSampleForm();

  const onSave = useCallback(async () => {
    const rhfSaample = rhfSampleForm.getValues();

    console.log("rhfSaample", rhfSaample);
    alert(
      `textSample: ${rhfSaample.textSample}\n numberSample: ${rhfSaample.numberSample}\n dateSample: ${rhfSaample.dateSample}\n selectSample: ${rhfSaample.selectSample}\n radioSample: ${rhfSaample.radioSample}`
    );
  }, [rhfSampleForm]);

  return { rhfSampleForm, onSave };
};
