import { Form } from "../../components/pages/Crew/NoonReport/Create/types";
import {
  N_Dist_OG_MAX_VALUE,
  N_Dist_OG_MIN_VALUE,
  N_Speed_OG_MAX_VALUE,
  N_Speed_OG_MIN_VALUE,
} from "../../components/pages/Crew/NoonReport/const";
import { ReportDexie } from "../../dexie/Report";

export const hasDuplicateNoonReportError = (
  reportList: ReportDexie[] = [],
  form: Form
): boolean => {
  if (!reportList || !form.N_Voy || !form.N_DateTime) {
    return false;
  }

  const duplicateReport = reportList.find(
    (report) =>
      report.data.N_Voy === form.N_Voy &&
      report.data.N_DateTime.getTime() === form.N_DateTime?.getTime()
  );

  return !!duplicateReport;
};

export const hasSpeedOverTheGroundError = (form: Form): boolean => {
  const N_Speed_OG = form.N_Speed_OG;
  if (N_Speed_OG == null) return false;

  return N_Speed_OG < N_Speed_OG_MIN_VALUE || N_Speed_OG > N_Speed_OG_MAX_VALUE;
};

export const hasDistanceTravelledOverGroundError = (form: Form): boolean => {
  const N_Dist_OG = form.N_Dist_OG;
  if (N_Dist_OG == null) return false;

  return N_Dist_OG < N_Dist_OG_MIN_VALUE || N_Dist_OG > N_Dist_OG_MAX_VALUE;
};

export const hasMeRpmTotalError = (
  form: Form,
  previousReport?: ReportDexie
): boolean => {
  if (
    form.N_ME_rpm_total === null ||
    previousReport?.draftForm?.N_ME_rpm_total === null ||
    previousReport?.draftForm?.N_ME_rpm_total === undefined
  ) {
    return false;
  }

  return form.N_ME_rpm_total < previousReport.draftForm?.N_ME_rpm_total;
};
