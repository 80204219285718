import { FoFuelType, FuelType } from "../../../../../gen/graphql/types";
import { Fuelconsumption } from "../../../../pages/Crew/NoonReport/Create/types";

export const convertFoFuelTypeToFuelType = (foFuel: FoFuelType) => {
  switch (foFuel) {
    case FoFuelType.Hsfo:
      return FuelType.Hsfo;
    case FoFuelType.Vlsfo:
      return FuelType.Vlsfo;
    case FoFuelType.Uvlsfo:
      return FuelType.Uvlsfo;
  }
};

export const checkIsDisable = (
  index: number,
  Fuelconsumption?: Fuelconsumption[]
) => {
  return (
    Fuelconsumption === undefined ||
    (Fuelconsumption && Fuelconsumption[index] === undefined) ||
    !Number.isNaN(Fuelconsumption[index].N_Fuel_volume)
  );
};

export enum FoConsumptionDefaultUsageEnum {
  FO_Consumption_per_day_ME = "M/E",
  N_FO_Consumption_per_day_GE = "G/E",
  N_FO_Consumption_per_day_BInc = "Boiler & incinerator",
}

export const FoConsumptionDefaultUsageEnumValues: string[] = Object.values(
  FoConsumptionDefaultUsageEnum
);

export enum MgoConsumptionDefaultUsageEnum {
  N_MGO_Consumption_per_day_ME = "M/E",
  N_MGO_Consumption_per_day_GE = "G/E",
  N_MGO_Consumption_per_day_Boiler = "Boiler",
}

export const MgoConsumptionDefaultUsageEnumValues: string[] = Object.values(
  MgoConsumptionDefaultUsageEnum
);
