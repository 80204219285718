import * as React from "react";
import Paper from "@mui/material/Paper";
import { Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import FormBlock from "../../../../organisms/Crew/Cargo/FormBlock";
import { ShipDexie } from "../../../../../dexie/Ship";
import { CargoForm } from "../../../../pages/Crew/Cargo/type";
import { Cargo } from "../../../../../gen/graphql/types";
import { getTimeZoneValues } from "../../../../../utils/form-util";

type ConfirmProps = {
  hasFormError: boolean;
  reportId: string;
  onUpdateForm(value: CargoForm): void;
  onConfirm(): void;
  ship: ShipDexie | undefined;
  isConfirm: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  form: CargoForm;
  cargo: Cargo | null;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
};

const ServerUpdate = (props: ConfirmProps): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Paper sx={{ backgroundColor: "#27292E", borderRadius: "20px" }}>
        <FormBlock
          defaultRadioButton={{
            defaultTimeZonePlusMinus: props.cargo
              ? getTimeZoneValues(props.cargo.C_Timezone).TimeZone_PorN
              : "+",
            defaultLatDirection: props.cargo
              ? props.cargo.C_Latitude[props.cargo.C_Latitude.length - 1]
              : "+",
            defaultLongDirection: props.cargo
              ? props.cargo.C_Longitude[props.cargo.C_Longitude.length - 1]
              : "+",
          }}
          title="Update Cargo Report"
          isPreview={false}
          onUpdateForm={props.onUpdateForm}
          form={props.form}
          ship={props.ship}
          isConfirm={props.isConfirm}
          setFile={props.setFile}
          isServer
        />
        <Divider textAlign="left"></Divider>

        <Box
          sx={{
            textAlign: "left",
            px: 2,
            py: 4,
          }}
        >
          <Button
            sx={{
              mx: 2,
              width: "250",
              backgroundColor: "#20BE89  !important",
              fontFamily: "Public Sans",
              "&.MuiButton-root": {
                fontFamily: "Public Sans",
              },
              textTransform: "none",
            }}
            variant="contained"
            onClick={() => props.onConfirm()}
          >
            Confirm
          </Button>
          <Button
            sx={{ mx: 2, backgroundColor: "#555860 ", textTransform: "none" }}
            variant="contained"
            onClick={() => navigate("/")}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default ServerUpdate;
