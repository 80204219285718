import Columns from "../../../../atoms/Columns";
import Column from "../../../../atoms/Column";
import { IconButton, Stack, Typography } from "@mui/material";
import CommonNumberFIeld from "../../../../atoms/CommonNumberFIeld";
import CommonTextFIeld from "../../../../atoms/CommonTextFIeld";
import { NestedFormBlockProps } from "../FormBlock";
import {
  lightGray,
  darkGray,
  charcoalGray,
  midnightBlue,
} from "../../../../../utils/color";
import {
  additionalKeyStrings,
  createAdditionalArrays,
} from "../detailAdditionalConsumption.module";
import CommonSelect from "../../../../atoms/CommonSelect";
import Optional from "../../../../atoms/Optional";
import { OtherFuelType } from "../../../../../gen/graphql/types";
import { useAdditonaConsumptionRowCounter } from "../../../../../utils/form-util";

export function OtherConsumption(props: NestedFormBlockProps) {
  const otherAdditionalArrays = createAdditionalArrays(
    additionalKeyStrings.OTHER,
    props.form
  );
  const { increment, decrement, additionalRows, maxAdditonaConsumptionIndex } =
    useAdditonaConsumptionRowCounter(otherAdditionalArrays.titles.length);

  return (
    <>
      <Columns
        sx={{
          display: "flex",
          alignItems: "end",
        }}
      >
        <Column>
          <Typography
            sx={{
              textAlign: "left",
              verticalAlign: "top",
              fontSize: "18px",
              fontFamily: "Public Sans",
              lineHeight: "auto",
              color: lightGray,
              pl: 1,
              mb: -2,
            }}
          >
            Other Fuel Consumption per day
          </Typography>
        </Column>
      </Columns>

      <>
        {additionalRows.map((_, index) => {
          const additionalLoopIndex = index + 1;
          const fuelTypeKey =
            additionalKeyStrings.OTHER.fuelTypeKey +
            additionalLoopIndex.toString();
          const usageKey =
            additionalKeyStrings.OTHER.usageKey +
            additionalLoopIndex.toString();
          const volumeKey =
            additionalKeyStrings.OTHER.volumeKey +
            additionalLoopIndex.toString();

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fuelTypeValue = (props.form as any)[fuelTypeKey] || "";
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const usageValue = (props.form as any)[usageKey] || "";
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const volumeValue = (props.form as any)[volumeKey] || "";

          return (
            <Columns key={additionalLoopIndex}>
              <Column sx={{ ml: "16px" }}>
                <CommonSelect
                  id={fuelTypeKey}
                  label={"Fuel Type"}
                  key={fuelTypeKey}
                  value={fuelTypeValue}
                  onChange={function (value: string): void {
                    props.onChange(fuelTypeKey, value);
                  }}
                  selectValues={Object.values(OtherFuelType)}
                  isOptional
                />
              </Column>
              <Column>
                <Optional />
                <CommonTextFIeld
                  disabled={props.isPreview}
                  id="usageKey"
                  label="Usage"
                  placeholder=""
                  value={usageValue}
                  onChange={(e: string | null) => {
                    props.onChange(usageKey, e);
                  }}
                  sx={{ width: "250px" }}
                />
              </Column>
              <Column>
                <Optional />
                <CommonNumberFIeld
                  id={volumeKey}
                  label="Volume"
                  placeholder=""
                  value={volumeValue}
                  unitName={"mt"}
                  minValue={0}
                  maxValue={999}
                  onChange={(e: string | null) => {
                    props.onChange(volumeKey, e);
                  }}
                />
              </Column>
              {additionalRows.length === additionalLoopIndex && (
                <Stack direction={"row"} sx={{ pt: 1.8 }}>
                  <Column>
                    <IconButton
                      disabled={
                        !(fuelTypeValue && usageValue && volumeValue) ||
                        additionalLoopIndex >= maxAdditonaConsumptionIndex
                      }
                      aria-label="delete"
                      size="small"
                      sx={{
                        height: "40px",
                        width: "40px",
                        fontSize: "25px",
                        my: 0.1,
                        color: lightGray,
                        backgroundColor: darkGray,
                        borderRadius: "10%",
                        "&.Mui-disabled": {
                          color: charcoalGray,
                          backgroundColor: midnightBlue,
                        },
                        boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                      }}
                      onClick={() => increment()}
                    >
                      +
                    </IconButton>
                  </Column>
                  <Column>
                    <IconButton
                      disabled={additionalLoopIndex === 1}
                      aria-label="delete"
                      size="small"
                      sx={{
                        height: "40px",
                        width: "40px",
                        fontSize: "25px",
                        my: 0.1,
                        color: lightGray,
                        backgroundColor: darkGray,
                        borderRadius: "10%",
                        "&.Mui-disabled": {
                          color: charcoalGray,
                          backgroundColor: midnightBlue,
                        },
                        boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                      }}
                      onClick={() => {
                        props.onUpdateForm?.({
                          ...props.form,
                          [fuelTypeKey]: null,
                          [usageKey]: null,
                          [volumeKey]: null,
                        });

                        decrement();
                      }}
                    >
                      -
                    </IconButton>
                  </Column>
                </Stack>
              )}
            </Columns>
          );
        })}
      </>
    </>
  );
}
