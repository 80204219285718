export const calculateSpeedOG = (N_Dist_OG: number | null, N_Hrs_propelling: number | null) => {
  if (N_Dist_OG === null || N_Hrs_propelling === null) {
    return 0;
  }

  if (N_Hrs_propelling == 0) {// 除算エラーを回避するため
    return 0;
  }

  return N_Dist_OG / N_Hrs_propelling;
};
