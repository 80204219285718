import React, { useState } from "react";
import Box from "@mui/material/Box";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { Props } from "../../../pages/Crew/NoonReport/Create/types";
import { Link, TableCell, Typography } from "@mui/material";
import Steppr from "../../../atoms/Stepper";
import CommonText from "../../../atoms/CommonText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomTableCell from "../../../atoms/CustomTableCell";
import { ReportDexie } from "../../../../dexie/Report";
import GrayButton from "../../../atoms/GrayButton";
import { useNavigate, useParams } from "react-router";
import DeleteModal from "../../../atoms/DeleteModal";
import {
  TableDataRow,
  Table3DataRow,
  Table6DataRow,
  TableAnyDataRow,
  SpareDataRow,
} from "../../../molecules/Crew/Detail/Detail";
import { formatToUtcYYYYMMDDHHMM } from "../../../../utils/formats";
import { getFileName, getLatLong } from "../../../../utils/form-util";
import { convertDegreeToDirection } from "../../../../utils/convert-wind-direction-degree";
import { FuelType } from "../../../../gen/graphql/types";
import { filterAdditionalROBByFuelType } from "./ROB/RobAdditional.module";
import { lightGray, white } from "../../../../utils/color";
import { isNumeric } from "../../../../utils/type-checks";
import { downLoadDexieReportfile } from "./downLoadReportfile";
import { useDownloadRobFileQuery } from "../../Owner/NoonReport/downloadRobFile.hook";
import Loading from "../../../atoms/Loading";
import {
  FoConsumptionDefaultUsageEnumValues,
  MgoConsumptionDefaultUsageEnumValues,
} from "./Consumption/consumptionForm.module";
import {
  additionalKeyStrings,
  createAdditionalArrays,
} from "./detailAdditionalConsumption.module";

type FormBlockProps = Props & {
  pageTitle: string;
  isPreview: boolean;
  reportId: number;
  previousReport?: ReportDexie | undefined;
  deleteReport?(): void;
  isSend: boolean;
  isServer?: boolean;
};

const Detail = (props: FormBlockProps): React.ReactElement => {
  const navigate = useNavigate();
  const { reportId } = useParams<"reportId">();
  const previousReport = props.previousReport?.data;
  const [modalOpen, setIsModalOpen] = useState(false);

  const report_id = isNumeric(reportId) ? Number(reportId) : 0;
  const downloadRobFile = useDownloadRobFileQuery(report_id);

  const foAdditionalArrays = createAdditionalArrays(
    additionalKeyStrings.FO,
    props.form
  );

  const mgoAdditionalArrays = createAdditionalArrays(
    additionalKeyStrings.MGO,
    props.form
  );

  const otherAdditionalArrays = createAdditionalArrays(
    additionalKeyStrings.OTHER,
    props.form
  );

  const onClickDelete = () => {
    if (props.deleteReport) {
      props.deleteReport();
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <Loading isLoading={downloadRobFile.isLoading} />
      <Box sx={{ pt: 0.5, mx: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "top",
            justifycontent: "right",
            pb: 1,
          }}
        >
          <Column>
            <Typography
              component="h3"
              variant="subtitle1"
              align="center"
              sx={{
                lineHeight: 1,
                fontWeight: "bold",
                color: "#fff",
                fontSize: "1.5rem",
                verticalAlign: "super",
              }}
            >
              {props.pageTitle}
            </Typography>
          </Column>

          {props.isPreview || (
            <Column sx={{ width: "60%" }}>
              <Steppr step={1} />
            </Column>
          )}
          {props.isPreview && !props.isSend && (
            <Box
              sx={{
                display: "flex",
                marginLeft: "auto",
                mb: -2,
              }}
            >
              <Column>
                <GrayButton
                  ButtonName={"Edit"}
                  disabled={false}
                  onClick={() => navigate(`/update/${reportId}`)}
                />
              </Column>
              <Column>
                <GrayButton
                  ButtonName={"Delete"}
                  disabled={false}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                />
              </Column>
            </Box>
          )}
        </Columns>
      </Box>
      <CommonText
        sx={{ color: "#FFFFFF", pl: 3, margin: "left", textAlign: "left" }}
      >
        {props.isPreview || `Confirm input data & click “Save” button.`}
      </CommonText>

      <Box sx={{ px: 2 }}>
        <Table
          color="inherit"
          sx={{ minWidth: 600, mx: 0, color: "#FFFFFF" }}
          aria-label="spanning table"
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "#FFFFFF", height: "20px" }}>
              <CustomTableCell align="center" colSpan={3}></CustomTableCell>
              <CustomTableCell />
              <CustomTableCell sx={{ color: "#AAAAAA", fontSize: "12px" }}>
                <span
                  style={{
                    color: "#AAAAAA",
                    paddingBottom: "-16px",
                    marginBottom: "-16px",
                  }}
                >
                  Previous Data
                </span>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableDataRow
              title={"Voy#"}
              data={props.form.N_Voy}
              previousData={previousReport?.N_Voy}
            />
            <TableDataRow
              title={"Time Zone"}
              data={
                String(props.form.N_TimeZone_PorN) +
                props.form.N_TimeZone_HH +
                ":" +
                props.form.N_TimeZone_MM
              }
              previousData={previousReport?.N_TimeZone}
            />
            <TableDataRow
              title={"L or B"}
              data={props.form.N_LorB}
              previousData={previousReport?.N_LorB}
            />
            <TableDataRow title={"Port Name"} data={props.form.N_Noon_Port} />
            <TableDataRow title={"UNLOC"} data={props.form.N_UNLOC} />
            <TableDataRow title={"EU/UK"} data={props.form.N_EU_UK_port} />
            <TableDataRow title={"Port L/U"} data={props.form.N_Port_L_U} />
            <TableDataRow
              title={"Date & Time"}
              data={formatToUtcYYYYMMDDHHMM(props.form.N_DateTime)}
            />
            <TableDataRow
              title={"Lat./ Long"}
              data={getLatLong(
                props.form.N_Lat_Deg,
                props.form.N_Lat_Min,
                props.form.N_Lat_Direction,
                props.form.N_Long_Deg,
                props.form.N_Long_Min,
                props.form.N_Long_Direction
              )}
            />
            <TableDataRow
              title={`Steam. mode`}
              data={props.form.N_Steam_mode}
            />
            <TableDataRow
              title={`Hrs. Propelling`}
              data={props.form.N_Hrs_propelling}
              unitName={"hour"}
            />
            <TableDataRow
              title={`Distance travelled over ground (nautical mile)`}
              data={props.form.N_Dist_OG}
              unitName={"nautical mile"}
            />
            <TableDataRow
              title={`Speed over the ground (avg. knot)`}
              data={props.form.N_Speed_OG}
              unitName={"knot"}
              previousData={previousReport?.N_Speed_OG}
            />
            <TableDataRow
              title={`Log speed (avg. knot)`}
              data={props.form.N_Speed_OW}
              unitName={"knot"}
              previousData={previousReport?.N_Speed_OW}
            />
            <TableDataRow
              title={`M/E rpm - avg.`}
              data={props.form.N_ME_rpm_ave}
              unitName={"rpm"}
              previousData={previousReport?.N_ME_rpm_ave}
            />
            <TableDataRow
              title={`M/E rpm - total.`}
              data={props.form.N_ME_rpm_total}
              unitName={"rpm"}
              previousData={previousReport?.N_ME_rpm_total}
            />
            <TableDataRow
              title={`M/E Revolution-Noon to Noon`}
              data={props.form.N_ME_Revolution}
            />
            <TableDataRow
              title={`Slip ratio (%)`}
              data={props.form.N_Slip}
              unitName={"%"}
            />

            <TableAnyDataRow
              topTitle={"FO Consumption per day"}
              title={FoConsumptionDefaultUsageEnumValues.concat(
                foAdditionalArrays.titles
              )}
              data={[
                props.form.N_FO_Consumption_per_day_ME,
                props.form.N_FO_Consumption_per_day_GE,
                props.form.N_FO_Consumption_per_day_BInc,
              ].concat(foAdditionalArrays.volumes)}
              unitName={Array(foAdditionalArrays.titles.length + 3).fill("mt")}
            />

            <TableAnyDataRow
              topTitle={"MGO Consumption per day"}
              title={MgoConsumptionDefaultUsageEnumValues.concat(
                mgoAdditionalArrays.titles
              )}
              data={[
                props.form.N_MGO_Consumption_per_day_ME,
                props.form.N_MGO_Consumption_per_day_GE,
                props.form.N_MGO_Consumption_per_day_Boiler,
              ].concat(mgoAdditionalArrays.volumes)}
              unitName={Array(mgoAdditionalArrays.titles.length + 3).fill("mt")}
            />

            <Table3DataRow
              topTitle={"LO Consumption per day"}
              title1={"MECO"}
              data1={props.form.N_LO_Consumption_per_day_MECO}
              unitName={"L"}
              title2={"MESO"}
              data2={props.form.N_LO_Consumption_per_day_MESO}
              title3={`GESO`}
              data3={props.form.N_LO_Consumption_per_day_GESO}
            />

            <TableAnyDataRow
              topTitle={"Other Consumption per day"}
              title={otherAdditionalArrays.titles}
              data={otherAdditionalArrays.volumes}
              unitName={Array(otherAdditionalArrays.titles.length).fill("mt")}
            />

            <TableDataRow title={`Weather`} data={props.form.N_Weather} />
            <TableDataRow
              title={`Wind direction`}
              data={convertDegreeToDirection(props.form.N_Wind_direction)}
            />
            <TableDataRow title={`BF scale`} data={props.form.N_BF_scale} />
            <TableDataRow title={`Swell`} data={props.form.N_Swell} />
            <TableDataRow
              title={`Noon Course`}
              data={props.form.N_Noon_Course}
              unitName={"degree"}
            />
            <TableDataRow
              title={`Dist. to go`}
              data={props.form.N_Dist_to_go}
              unitName={"nautical mile"}
              previousData={previousReport?.N_Dist_to_go}
            />
            <TableDataRow
              title={`Next port`}
              data={props.form.N_Next_port}
              previousData={
                previousReport?.N_Next_port
                  ? String(previousReport?.N_Next_port)
                  : ""
              }
            />
            <TableDataRow
              title={`ETA`}
              data={formatToUtcYYYYMMDDHHMM(props.form.N_ETA)}
              previousData={formatToUtcYYYYMMDDHHMM(previousReport?.N_ETA)}
            />
            <TableDataRow
              title={`ETB`}
              data={formatToUtcYYYYMMDDHHMM(props.form.N_ETB)}
              previousData={formatToUtcYYYYMMDDHHMM(previousReport?.N_ETB)}
            />
            <TableDataRow
              title={`ETD`}
              data={formatToUtcYYYYMMDDHHMM(props.form.N_ETD)}
              previousData={formatToUtcYYYYMMDDHHMM(previousReport?.N_ETD)}
            />
            <TableDataRow
              title={`M/E Fuel index`}
              data={props.form.N_ME_Fuel_index}
              unitName={"%"}
            />
            <TableDataRow
              title={`M/E RPM`}
              data={props.form.N_ME_RPM}
              unitName={"rpm"}
            />
            <TableDataRow
              title={`M/E horsepower`}
              data={props.form.N_ME_horsepower}
              unitName={"kw"}
            />
            <TableDataRow
              title={`M/E power`}
              data={props.form.N_ME_power}
              unitName={"%"}
            />
            <TableDataRow
              title={`M/E power measurement method`}
              data={props.form.N_ME_power_measurement_method}
            />
            <Table3DataRow
              topTitle={"Running hours"}
              title1={"SOx scrubber"}
              data1={props.form.N_Running_hours_SOx_scrubber}
              unitName={"hour"}
              previousData1={previousReport?.N_Running_hours_SOx_scrubber}
              title2={"M/E EGR"}
              data2={props.form.N_Running_hours_ME_EGR}
              previousData2={previousReport?.N_Running_hours_ME_EGR}
              title3={`G/E SCR`}
              data3={props.form.N_Running_hours_GE_SCR}
              previousData3={previousReport?.N_Running_hours_GE_SCR}
            />
            <Table6DataRow
              topTitle={"ROB"}
              title1={"FO (mt)"}
              data1={props.form.N_ROB_FO}
              previousData1={previousReport?.N_ROB_FO}
              title2={"MGO (mt)"}
              data2={props.form.N_ROB_MGO}
              previousData2={previousReport?.N_ROB_MGO}
              title3={"MECO (L)"}
              data3={props.form.N_ROB_MECO}
              previousData3={previousReport?.N_ROB_MECO}
              title4={"MESO (L)"}
              data4={props.form.N_ROB_MESO}
              previousData4={previousReport?.N_ROB_MESO}
              title5={"GESO (L)"}
              data5={props.form.N_ROB_GESO}
              previousData5={previousReport?.N_ROB_GESO}
              title6={"FW (mt)"}
              data6={props.form.N_ROB_FW}
              previousData6={previousReport?.N_ROB_FW}
              unitName1={"mt"}
              unitName2={"L"}
            />

            <TableAnyDataRow
              topTitle={"Additional ROB"}
              title={
                filterAdditionalROBByFuelType(Object.values(FuelType), [
                  ...(props.form.N_Additional_ROB || []),
                ])?.map((v) => v.N_Fuel_Type) || []
              }
              data={
                filterAdditionalROBByFuelType(Object.values(FuelType), [
                  ...(props.form.N_Additional_ROB || []),
                ])?.map((v) => v.N_Fuel_volume) || []
              }
              unitName={Array(
                filterAdditionalROBByFuelType(Object.values(FuelType), [
                  ...(props.form.N_Additional_ROB || []),
                ])?.length
              ).fill("mt")}
            />

            <TableRow>
              <CustomTableCell sx={{ color: lightGray, width: "250px" }}>
                {"FileName"}
              </CustomTableCell>
              <CustomTableCell />
              <CustomTableCell
                colSpan={2}
                sx={{
                  color: white,
                  wordBreak: "break-word",
                  whiteSpace: "pre-line",
                }}
              >
                {props.form.N_ROB_Filename && isNumeric(reportId) ? (
                  <Link
                    onClick={() => {
                      if (props.isServer) {
                        downloadRobFile.onDownload();
                      } else if (!props.isServer) {
                        downLoadDexieReportfile(
                          Number(reportId),
                          getFileName(props.form.N_ROB_Filename || undefined)
                        );
                      }
                    }}
                  >
                    {getFileName(props.form.N_ROB_Filename)}
                  </Link>
                ) : props.form.N_ROB_Filename ? (
                  getFileName(props.form.N_ROB_Filename)
                ) : (
                  "-"
                )}
              </CustomTableCell>
              <CustomTableCell sx={{ color: lightGray }}></CustomTableCell>
            </TableRow>
            <TableDataRow
              title={`FO CO2 emission`}
              data={props.form.N_FO_CO2_emission_per_unit}
              unitName={"g/ton-nautical miles"}
              previousData={previousReport?.N_FO_CO2_emission_per_unit}
            />
            <TableDataRow
              title={`MGO CO2 emission`}
              data={props.form.N_MGO_CO2_emission_per_unit}
              unitName={"g/ton-nautical miles"}
              previousData={previousReport?.N_MGO_CO2_emission_per_unit}
            />
            <TableDataRow
              title={`FO CO2 emission`}
              data={props.form.N_FO_CO2_emission}
              unitName={"mt"}
              previousData={previousReport?.N_FO_CO2_emission}
            />
            <TableDataRow
              title={`MGO CO2 emission`}
              data={props.form.N_MGO_CO2_emission}
              unitName={"mt"}
              previousData={previousReport?.N_MGO_CO2_emission}
            />
            <TableDataRow title={`Remark`} data={props.form.N_Remark} />
            <TableRow>
              <TableCell
                rowSpan={21}
                sx={{
                  verticalAlign: "top",
                  color: "#AAAAAA",
                  borderBottom: "1px solid #464E5A",
                }}
              >
                Spare
              </TableCell>
            </TableRow>
            <SpareDataRow
              title={`Spare 1`}
              data={props.form.N_Spare_1}
              unitName={props.form.N_Spare_1_unit_name}
            />
            <SpareDataRow
              title={`Spare 2`}
              data={props.form.N_Spare_2}
              unitName={props.form.N_Spare_2_unit_name}
            />
            <SpareDataRow
              title={`Spare 3`}
              data={props.form.N_Spare_3}
              unitName={props.form.N_Spare_3_unit_name}
            />
            <SpareDataRow
              title={`Spare 4`}
              data={props.form.N_Spare_4}
              unitName={props.form.N_Spare_4_unit_name}
            />
            <SpareDataRow
              title={`Spare 5`}
              data={props.form.N_Spare_5}
              unitName={props.form.N_Spare_5_unit_name}
            />
            <SpareDataRow
              title={`Spare 6`}
              data={props.form.N_Spare_6}
              unitName={props.form.N_Spare_6_unit_name}
            />
            <SpareDataRow
              title={`Spare 7`}
              data={props.form.N_Spare_7}
              unitName={props.form.N_Spare_7_unit_name}
            />
            <SpareDataRow
              title={`Spare 8`}
              data={props.form.N_Spare_8}
              unitName={props.form.N_Spare_8_unit_name}
            />
            <SpareDataRow
              title={`Spare 9`}
              data={props.form.N_Spare_9}
              unitName={props.form.N_Spare_9_unit_name}
            />
            <SpareDataRow
              title={`Spare 10`}
              data={props.form.N_Spare_10}
              unitName={props.form.N_Spare_10_unit_name}
            />
            <SpareDataRow
              title={`Spare 11`}
              data={props.form.N_Spare_11}
              unitName={props.form.N_Spare_11_unit_name}
            />
            <SpareDataRow
              title={`Spare 12`}
              data={props.form.N_Spare_12}
              unitName={props.form.N_Spare_12_unit_name}
            />
            <SpareDataRow
              title={`Spare 13`}
              data={props.form.N_Spare_13}
              unitName={props.form.N_Spare_13_unit_name}
            />
            <SpareDataRow
              title={`Spare 14`}
              data={props.form.N_Spare_14}
              unitName={props.form.N_Spare_14_unit_name}
            />
            <SpareDataRow
              title={`Spare 15`}
              data={props.form.N_Spare_15}
              unitName={props.form.N_Spare_15_unit_name}
            />
            <SpareDataRow
              title={`Spare 16`}
              data={props.form.N_Spare_16}
              unitName={props.form.N_Spare_16_unit_name}
            />
            <SpareDataRow
              title={`Spare 17`}
              data={props.form.N_Spare_17}
              unitName={props.form.N_Spare_17_unit_name}
            />
            <SpareDataRow
              title={`Spare 18`}
              data={props.form.N_Spare_18}
              unitName={props.form.N_Spare_18_unit_name}
            />
            <SpareDataRow
              title={`Spare 19`}
              data={props.form.N_Spare_19}
              unitName={props.form.N_Spare_19_unit_name}
            />
            <SpareDataRow
              title={`Spare 20`}
              data={props.form.N_Spare_20}
              unitName={props.form.N_Spare_20_unit_name}
            />
          </TableBody>
        </Table>
      </Box>
      <DeleteModal
        isOpen={modalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteFrontData={onClickDelete}
        id={props.reportId}
        voy={props.form.N_Voy ? props.form.N_Voy : ""}
        DateTime={formatToUtcYYYYMMDDHHMM(props.form.N_DateTime)}
      />
    </Box>
  );
};

export default Detail;
