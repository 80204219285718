import Columns from "../../../../atoms/Columns";
import Column from "../../../../atoms/Column";
import { Typography } from "@mui/material";
import CommonNumberFIeld from "../../../../atoms/CommonNumberFIeld";
import { NestedFormBlockProps } from "../FormBlock";
import { lightGray } from "../../../../../utils/color";

export function LoConsumption(props: NestedFormBlockProps) {
  return (
    <>
      <Columns
        sx={{
          display: "flex",
          alignItems: "end",
        }}
      >
        <Column>
          <Typography
            sx={{
              textAlign: "left",
              verticalAlign: "top",
              fontSize: "18px",
              fontFamily: "Public Sans",
              lineHeight: "auto",
              color: lightGray,
              pl: 1,
              mb: -2,
            }}
          >
            LO Consumption per day
          </Typography>
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Typography
            sx={{
              color: lightGray,
              pl: 2,
              pr: 2,
              width: "250px",
              textAlign: "left",
            }}
          >
            MECO (L)
          </Typography>{" "}
        </Column>
        <Column>
          <CommonNumberFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="N_LO_Consumption_per_day_MECO"
            label="Volume"
            placeholder=""
            value={
              props.form.N_LO_Consumption_per_day_MECO === 0
                ? 0
                : props.form.N_LO_Consumption_per_day_MECO
            }
            unitName={"L"}
            minValue={0}
            maxValue={999}
            onChange={(e: string | null) => {
              props.onChange("N_LO_Consumption_per_day_MECO", e);
            }}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Typography
            sx={{
              color: lightGray,
              pl: 2,
              pr: 2,
              width: "250px",
              textAlign: "left",
            }}
          >
            MESO (L)
          </Typography>{" "}
        </Column>
        <Column>
          <CommonNumberFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="N_LO_Consumption_per_day_MESO"
            label="Volume"
            placeholder=""
            value={
              props.form.N_LO_Consumption_per_day_MESO === 0
                ? 0
                : props.form.N_LO_Consumption_per_day_MESO
            }
            unitName={"L"}
            minValue={0}
            maxValue={999}
            onChange={(e: string | null) => {
              props.onChange("N_LO_Consumption_per_day_MESO", e);
            }}
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Typography
            sx={{
              color: lightGray,
              pl: 2,
              pr: 2,
              width: "250px",
              textAlign: "left",
            }}
          >
            GESO (L)
          </Typography>{" "}
        </Column>
        <Column>
          <CommonNumberFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="N_LO_Consumption_per_day_GESO"
            label="Volume"
            placeholder=""
            value={
              props.form.N_LO_Consumption_per_day_GESO === 0
                ? 0
                : props.form.N_LO_Consumption_per_day_GESO
            }
            unitName={"L"}
            minValue={0}
            maxValue={999}
            onChange={(e: string | null) => {
              props.onChange("N_LO_Consumption_per_day_GESO", e);
            }}
          />
        </Column>
      </Columns>
    </>
  );
}

export default LoConsumption;
