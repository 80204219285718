import { Box, Modal, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import { AnalysisSearchTitleProps } from "./AnalysisSearchParameterModal/AnalysisSearchParameterModal.hook";
import { weightedGray, white } from "../../../../utils/color";
import SucsessButton from "../../../atoms/SucsessButton";
import LightGrayButton from "../../../atoms/LightGrayButton";

export type AddDetailData = {
  detailIndex: number;
  parentParts: string;
  childIndexes: number;
  addIndex: string;
};

type LoadProps = {
  title: string;
};

type Props = {
  isOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  analysisSearchTitle: AnalysisSearchTitleProps;
  setAnalysisSearchParameterTitle: React.Dispatch<
    React.SetStateAction<AnalysisSearchTitleProps>
  >;
  upsertAnalysisSearchParameter: () => Promise<boolean | undefined>;
  loadDataAnalysisSearchParameter: (renderString: string) => Promise<void>;
};

export const SaveFilteDataModal: FC<Props> = (props: Props) => {
  const handleClose = () => {
    props.setIsModalOpen(false);
  };

  const onChange = (value: string) => {
    props.setAnalysisSearchParameterTitle({
      ...props.analysisSearchTitle,
      title: value,
    });
  };

  const loadData = (loadProps: LoadProps) => {
    props.loadDataAnalysisSearchParameter(loadProps.title);
  };

  const onClickCreate = async () => {
    const response = await props.upsertAnalysisSearchParameter();

    handleClose();
    if (response) {
      loadData({ ...{ title: props.analysisSearchTitle.title } });
    }
  };

  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#ECEEF2",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography sx={{ fontSize: "24px", pt: 1 }}>
          Save Filter Title
        </Typography>

        <Typography
          sx={{
            pt: 3,
            pb: 1,
            textAlign: "left",
            verticalAlign: "middle",
            fontSize: "18px",
            lineHeight: "auto",
            fontWeight: "bold",
          }}
        >
          Filter Title
        </Typography>
        <CommonTextFIeld
          id={"Title"}
          label={""}
          width={"400px"}
          value={props.analysisSearchTitle.title}
          sx={{
            "& .MuiInputBase-input": { color: weightedGray },
          }}
          onChange={(e) => onChange(e)}
        />

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          sx={{ p: 1, mt: 4 }}
        >
          <SucsessButton
            ButtonName={"Save"}
            disabled={!props.analysisSearchTitle.title}
            sx={{
              width: "150px",

              color: white,
              fontSize: 14,
            }}
            onClick={onClickCreate}
          />
          <LightGrayButton
            ButtonName={"Cancel"}
            sx={{ fontSize: 14 }}
            onClick={() => {
              handleClose();
            }}
          />
        </Stack>
      </Box>
    </Modal>
  );
};
