import { Button, SxProps, Theme } from "@mui/material";
import { darkGrayHoverColor, lightGray9FA6B0, white } from "../../utils/color";

type Props = {
  fontSize?: number;
  width?: string;
  ButtonName: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
};

const LightGrayButton = (props: Props) => {
  return (
    <Button
      sx={{
        fontSize: props.fontSize,
        mx: 2,
        width: props.width || 150,
        color: white,
        backgroundColor: lightGray9FA6B0,
        textTransform: "none",
        fontFamily: "Public Sans",
        ":hover": {
          backgroundColor: darkGrayHoverColor,
        },
        ...props.sx,
      }}
      variant="contained"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.ButtonName}
    </Button>
  );
};

export default LightGrayButton;
