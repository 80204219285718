export function setDefaultNullGgraphQLInputToForm<
  T extends number | string | null
>(value: T | undefined): T | null {
  if (value === undefined) {
    return null;
  } else {
    return value;
  }
}

export function setDefaultValueGraphQLRequiredInput<
  T extends number | string | null
>(value: T | undefined): T | null {
  if (value === undefined) {
    return null;
  } else {
    return value;
  }
}
