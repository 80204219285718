import React, { useCallback, useEffect } from "react";
import { CargoListQuery } from "../../../../gen/graphql/types";
import Template from "../../../templates/Owner/Cargo";
import DefaultOwner from "../../../layouts/DefaultOwner";
import { useCsvDownloadForm } from "../NoonReport/NoonReport";
import {
  CSV_DOWNLOAD_LIMIT,
  downloadCsvFile,
} from "../../../../utils/download-csv-util";
import { useApolloClient } from "@apollo/client";
import { useContextReportSearchCondition } from "../../../../App";
import { genCargoCsv } from "./CargoCsvFormat";
import { getCargoCsvDownloadData } from "./CargoUtil";
import dayjs from "dayjs";
import { ReportSearchCondition } from "../../pages.module";
import {
  mapCargoListInput,
  useCargo,
} from "../../../../hooks/commonQuery/cargo.hook";
import { useOwnerSearchForm } from "../owner.module";

const getCargoList = (reports?: CargoListQuery) => {
  const reportList = (reports?.cargoList?.cargoList || [])
    .map((cargo) => cargo)
    .filter((v): v is NonNullable<typeof v> => v != null)
    .sort((a, b) => dayjs(b.C_DateTime).diff(dayjs(a.C_DateTime)));

  return reportList;
};

const Cargo = (): React.ReactElement => {
  const reports = useCargo();
  const csvForm = useCsvDownloadForm();
  const client = useApolloClient();
  const { reportSearchCondition, setReportSearchCondition } =
    useContextReportSearchCondition();

  const form = useOwnerSearchForm(reportSearchCondition);

  const onUpdateForm = (value: ReportSearchCondition) => {
    form.update(value);
  };

  const onUpdateCsvForm = (value: ReportSearchCondition) => {
    csvForm.update(value);
  };

  const onUpdateSearchCondition = (value: ReportSearchCondition) => {
    form.update(value);

    reports.refetch(form.data);
  };

  const onCsvDownload = useCallback(async () => {
    csvForm.data.pagination.page = 1;
    const input = mapCargoListInput(csvForm.data);
    try {
      let data = await getCargoCsvDownloadData(input, client);
      let reportList = getCargoList(data);

      if (data?.arrivalList?.totalPageCountt > CSV_DOWNLOAD_LIMIT) {
        const iterations = Math.ceil(
          data?.arrivalList?.totalPageCount / CSV_DOWNLOAD_LIMIT
        );

        for (let i = 1; i < iterations; i++) {
          csvForm.data.pagination.page = i + 1; // Adjust input to fetch the next page.
          const input = mapCargoListInput(csvForm.data);
          data = await getCargoCsvDownloadData(input, client);
          const additionalReportList = getCargoList(data);
          reportList = [...reportList, ...additionalReportList]; // merge the new data with existing reportList
        }
      }

      const { fileName, text } = await genCargoCsv(
        csvForm.data,
        reportList.reverse()
      ).catch((e: unknown) => {
        console.error(e);
        throw Error(String(e));
      });

      downloadCsvFile(fileName, text);
    } catch (e) {
      console.error(e);
      alert("Failed to download CSV");
    } finally {
      csvForm.data.pagination.page = 1;
    }
  }, [client, csvForm.data]);

  const onSearch = () => {
    form.data.pagination.page = 1;
    setReportSearchCondition(form.data);
    reports.refetch(form.data);
  };
  useEffect(() => {
    if (reportSearchCondition != undefined) {
      form.data = reportSearchCondition;
      form.update(form.data);
      reports.refetch(form.data);
      return;
    } else {
      reports.refetch(form.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultOwner title="Noon Report" selectedTitle={"Cargo"}>
      <Template
        cargoList={reports.data.cargoList}
        isLoading={reports.isLoading}
        totalPageCount={reports.data.totalPageCount}
        hasFormError={true}
        onUpdateForm={onUpdateForm}
        form={form.data}
        onSearch={onSearch}
        onUpdateSearchCondition={onUpdateSearchCondition}
        onCsvDownload={onCsvDownload}
        csvForm={csvForm.data}
        onUpdateCsvForm={onUpdateCsvForm}
        hasCsvFormError={csvForm.hasCsvFormError}
      />
    </DefaultOwner>
  );
};

export default Cargo;
