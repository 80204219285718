import React, { useState } from "react";
import Box from "@mui/material/Box";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { Props } from "../../../pages/Crew/Departure/Create/types";
import { TableCell, Typography } from "@mui/material";
import Steppr from "../../../atoms/Stepper";
import CommonText from "../../../atoms/CommonText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomTableCell from "../../../atoms/CustomTableCell";
import dayjs from "dayjs";
import GrayButton from "../../../atoms/GrayButton";
import { useNavigate, useParams } from "react-router";
import DeleteModalDepature from "../../../atoms/DeleteModalDepature";
import { isDate } from "../../../../utils/type-checks";
import { ArrivalDexie } from "../../../../dexie/Arrival";
import {
  emptyToHyphen,
  formatToUtcYYYYMMDDHHMM,
} from "../../../../utils/formats";
import { pageData } from "../../../../App";
import {
  TableDataRow,
  Table2DataRow,
  Table6DataRow,
  SpareDataRow,
  TableAnyDataRow,
} from "../../../molecules/Crew/Detail/Detail";
import { filterAdditionalROBForDepartureByFuelType } from "./ROB/RobAdditional.module";
import { FuelType } from "../../../../gen/graphql/types";

type FormBlockProps = Props & {
  pageTitle: string;
  isPreview: boolean;
  reportId: number;
  lastArrival?: ArrivalDexie | undefined;
  deleteReport?(): void;
  isSend: boolean;
};

const Detail = (props: FormBlockProps): React.ReactElement => {
  const navigate = useNavigate();
  const { reportId } = useParams<"reportId">();
  const lastArrival = props.lastArrival?.data;
  const [modalOpen, setIsModalOpen] = useState(false);

  const onClickDelete = () => {
    if (props.deleteReport) {
      props.deleteReport();
    }
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <Box sx={{ pt: 0.5, mx: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "top",
            justifycontent: "right",
            pb: 1,
          }}
        >
          <Column>
            <Typography
              component="h3"
              variant="subtitle1"
              align="center"
              sx={{
                lineHeight: 1,
                fontWeight: "bold",
                color: "#fff",
                fontSize: "1.5rem",
                verticalAlign: "super",
              }}
            >
              {props.pageTitle}
            </Typography>
          </Column>

          {props.isPreview || (
            <Column sx={{ width: "60%" }}>
              <Steppr step={1} />
            </Column>
          )}
          {props.isPreview && !props.isSend && (
            <Box
              sx={{
                display: "flex",
                marginLeft: "auto",
                mb: -2,
              }}
            >
              <Column>
                <GrayButton
                  ButtonName={"Edit"}
                  disabled={false}
                  onClick={() => {
                    reportId
                      ? navigate(pageData.crewDepartureUpdate.path(reportId))
                      : console.error("Invalid Report ID");
                  }}
                />{" "}
              </Column>
              <Column>
                <GrayButton
                  ButtonName={"Delete"}
                  disabled={false}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                />
              </Column>
            </Box>
          )}
        </Columns>
      </Box>
      <CommonText
        sx={{ color: "#FFFFFF", pl: 3, margin: "left", textAlign: "left" }}
      >
        {props.isPreview || `Confirm input data & click “Save” button.`}
      </CommonText>

      <Box sx={{ px: 2 }}>
        <Table
          color="inherit"
          sx={{ minWidth: 600, mx: 0, color: "#FFFFFF" }}
          aria-label="spanning table"
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "#FFFFFF", height: "20px" }}>
              <CustomTableCell align="center" colSpan={3}></CustomTableCell>
              <CustomTableCell />
              <CustomTableCell sx={{ color: "#AAAAAA", fontSize: "12px" }}>
                {!props.isPreview && (
                  <span
                    style={{
                      color: "#AAAAAA",
                      paddingBottom: "-16px",
                      marginBottom: "-16px",
                    }}
                  >
                    Previous Data
                  </span>
                )}
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableDataRow title={"Voy#"} data={props.form.D_Voy} />
            <TableDataRow
              title={"Last Voy#"}
              data={props.form.D_Last_Voy}
              previousData={!props.isPreview ? lastArrival?.A_Voy : ""}
            />
            <TableDataRow title={"L or B"} data={props.form.D_LorB} />
            <TableDataRow
              title={"Departure port"}
              data={props.form.D_Departure_Port}
              previousData={!props.isPreview ? lastArrival?.A_Arrival_Port : ""}
            />
            <TableDataRow title={"UNLOC"} data={props.form.D_UNLOC} />
            <TableDataRow title={"EU/UK"} data={props.form.D_EU_UK_port} />
            <TableDataRow title={"Port L/U"} data={props.form.D_Port_L_U} />
            <TableDataRow
              title={"Date & Time"}
              data={formatToUtcYYYYMMDDHHMM(props.form.D_Departure_Date_Time)}
            />
            <TableDataRow
              title={"R/U Date & Time"}
              data={formatToUtcYYYYMMDDHHMM(props.form.D_RU_Date_Time)}
            />
            <TableDataRow
              title={"Time Zone"}
              data={
                props.form.D_TimeZone_MM
                  ? String(props.form.D_TimeZone_PorN) +
                    props.form.D_TimeZone_HH +
                    ":" +
                    props.form.D_TimeZone_MM
                  : ""
              }
              previousData={!props.isPreview ? lastArrival?.A_Time_Zone : ""}
            />
            <Table6DataRow
              topTitle={"ROB"}
              title1={"FO (mt)"}
              data1={props.form.D_ROB_FO}
              title2={"MGO (mt)"}
              data2={props.form.D_ROB_MGO}
              title3={"MECO (L)"}
              data3={props.form.D_ROB_MECO}
              title4={"MESO (L)"}
              data4={props.form.D_ROB_MESO}
              title5={"GESO (L)"}
              data5={props.form.D_ROB_GESO}
              title6={"FW (mt)"}
              data6={props.form.D_ROB_FW}
              unitName1={"mt"}
              unitName2={"L"}
            />
            <TableAnyDataRow
              topTitle={"Additional ROB"}
              title={
                filterAdditionalROBForDepartureByFuelType(Object.values(FuelType), [
                  ...(props.form.D_Additional_ROB || []),
                ])?.map((v) => v.D_Fuel_Type) || []
              }
              data={
                filterAdditionalROBForDepartureByFuelType(Object.values(FuelType), [
                  ...(props.form.D_Additional_ROB || []),
                ])?.map((v) => v.D_Fuel_volume) || []
              }
              unitName={Array(
                filterAdditionalROBForDepartureByFuelType(Object.values(FuelType), [
                  ...(props.form.D_Additional_ROB || []),
                ])?.length
              ).fill("mt")}
            />
            <TableDataRow title={`Cargo name`} data={props.form.D_Cargo_Name} />
            <TableDataRow title={`Cargo Qtty`} data={props.form.D_Cargo_Qtty} />
            <Table2DataRow
              topTitle={"Draft"}
              title1={"Fore (m)"}
              data1={props.form.D_Draft_Fore}
              unitName={"m"}
              title2={"Aft (m)"}
              data2={props.form.D_Draft_Aft}
            />
            <TableDataRow
              title={`Next port name`}
              data={props.form.D_Next_Port_Name}
            />
            <TableDataRow
              title={`ETA`}
              data={
                isDate(props.form.D_ETA)
                  ? dayjs(props.form.D_ETA).format("YYYY/MM/DD HH:mm")
                  : ""
              }
            />
            <TableDataRow
              title={`ETB`}
              data={formatToUtcYYYYMMDDHHMM(props.form.D_ETB)}
            />
            <TableDataRow
              title={`ETC`}
              data={formatToUtcYYYYMMDDHHMM(props.form.D_ETC)}
            />
            <TableDataRow
              title={`ETD`}
              data={formatToUtcYYYYMMDDHHMM(props.form.D_ETD)}
            />
            <TableDataRow title={`Remark`} data={props.form.D_Remark} />

            <TableRow>
              <TableCell
                rowSpan={21}
                sx={{
                  verticalAlign: "top",
                  color: "#AAAAAA",
                  borderBottom: "1px solid #464E5A",
                }}
              >
                Spare
              </TableCell>
            </TableRow>
            <SpareDataRow
              title={`Spare 1`}
              data={props.form.D_Spare_1}
              unitName={props.form.D_Spare_1_unit_name}
            />
            <SpareDataRow
              title={`Spare 2`}
              data={props.form.D_Spare_2}
              unitName={props.form.D_Spare_2_unit_name}
            />
            <SpareDataRow
              title={`Spare 3`}
              data={props.form.D_Spare_3}
              unitName={props.form.D_Spare_3_unit_name}
            />
            <SpareDataRow
              title={`Spare 4`}
              data={props.form.D_Spare_4}
              unitName={props.form.D_Spare_4_unit_name}
            />
            <SpareDataRow
              title={`Spare 5`}
              data={props.form.D_Spare_5}
              unitName={props.form.D_Spare_5_unit_name}
            />
            <SpareDataRow
              title={`Spare 6`}
              data={props.form.D_Spare_6}
              unitName={props.form.D_Spare_6_unit_name}
            />
            <SpareDataRow
              title={`Spare 7`}
              data={props.form.D_Spare_7}
              unitName={props.form.D_Spare_7_unit_name}
            />
            <SpareDataRow
              title={`Spare 8`}
              data={props.form.D_Spare_8}
              unitName={props.form.D_Spare_8_unit_name}
            />
            <SpareDataRow
              title={`Spare 9`}
              data={props.form.D_Spare_9}
              unitName={props.form.D_Spare_9_unit_name}
            />
            <SpareDataRow
              title={`Spare 10`}
              data={props.form.D_Spare_10}
              unitName={props.form.D_Spare_10_unit_name}
            />
            <SpareDataRow
              title={`Spare 11`}
              data={props.form.D_Spare_11}
              unitName={props.form.D_Spare_11_unit_name}
            />
            <SpareDataRow
              title={`Spare 12`}
              data={props.form.D_Spare_12}
              unitName={props.form.D_Spare_12_unit_name}
            />
            <SpareDataRow
              title={`Spare 13`}
              data={props.form.D_Spare_13}
              unitName={props.form.D_Spare_13_unit_name}
            />
            <SpareDataRow
              title={`Spare 14`}
              data={props.form.D_Spare_14}
              unitName={props.form.D_Spare_14_unit_name}
            />
            <SpareDataRow
              title={`Spare 15`}
              data={props.form.D_Spare_15}
              unitName={props.form.D_Spare_15_unit_name}
            />
            <SpareDataRow
              title={`Spare 16`}
              data={props.form.D_Spare_16}
              unitName={props.form.D_Spare_16_unit_name}
            />
            <SpareDataRow
              title={`Spare 17`}
              data={props.form.D_Spare_17}
              unitName={props.form.D_Spare_17_unit_name}
            />
            <SpareDataRow
              title={`Spare 18`}
              data={props.form.D_Spare_18}
              unitName={props.form.D_Spare_18_unit_name}
            />
            <SpareDataRow
              title={`Spare 19`}
              data={props.form.D_Spare_19}
              unitName={props.form.D_Spare_19_unit_name}
            />
            <SpareDataRow
              title={`Spare 20`}
              data={props.form.D_Spare_20}
              unitName={props.form.D_Spare_20_unit_name}
            />
          </TableBody>
        </Table>
      </Box>

      <DeleteModalDepature
        isOpen={modalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteFrontData={onClickDelete}
        id={props.reportId}
        voy={emptyToHyphen(props.form.D_Voy)}
        DateTime={formatToUtcYYYYMMDDHHMM(props.form.D_Departure_Date_Time)}
      />
    </Box>
  );
};

export default Detail;
