import { orgRound } from "../../../../../utils/calculate";
import { isNanFinite } from "../../../../../utils/isNanFinite";

type Props = {
    MGO_CO2_emission_SUM: number;
    summer_deadweight: number | null;
    N_Hrs_propelling: number | null;
    N_Speed_OG: number | null;
}

export function cal_N_MGO_CO2_emission_per_unit(props: Props) {
    if (props.summer_deadweight === null || props.N_Hrs_propelling === null || props.N_Speed_OG === null) {
        return 0;
    }

    if (props.summer_deadweight === 0) {
        return 0;
    }

    const N_MGO_CO2_emission_per_unit = orgRound(
        (props.MGO_CO2_emission_SUM * 3.206 * 1000000) /
          props.N_Hrs_propelling /
          (props.summer_deadweight * props.N_Speed_OG),
        1000
      );
    
      if (isNanFinite(N_MGO_CO2_emission_per_unit)) {
        return 0;
    }

    return N_MGO_CO2_emission_per_unit;
}
