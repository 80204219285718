import { FuelType } from "../../../../../gen/graphql/types";
import { AdditionalROBForArrival } from "../../../../pages/Crew/Arrival/Create/types";

export const setAdditionalAdditionalROBForArrival = (
  fuelType: FuelType,
  targetIndex?: number,
  value?: number,
  A_Additional_ROB: AdditionalROBForArrival[] = []
) => {
  const updatedAdditionalROB = [...A_Additional_ROB];

  if (targetIndex === undefined || !updatedAdditionalROB[targetIndex]) {
    updatedAdditionalROB.push({
      A_Fuel_Type: fuelType,
      A_Fuel_volume: value,
    });
  } else {
    updatedAdditionalROB[targetIndex] = {
      A_Fuel_Type: fuelType,
      A_Fuel_volume: value,
    };
  }
  return updatedAdditionalROB;
};

export const filterAdditionalROBForArrivalByFuelType = (
  fuelTypeList: FuelType[],
  additionalROB?: AdditionalROBForArrival[]
) => {
  return additionalROB?.filter((v) => fuelTypeList.includes(v.A_Fuel_Type));
};
