export const FilterTitleList = {
  Filter_1: "Filter1",
  Filter_2: "Filter2",
  Filter_3: "Filter3",
  Filter_4: "Filter4",
  Filter_All: "Setting All Filters",
  Filter_Individual: "Setting Individual Filters",
};

export const BaselineTitleList = {
  Ballast: "Ballast",
  Laden: "Laden",
  Unique: "Unique",
};
