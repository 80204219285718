import { Box, Modal, Stack, Typography } from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import { SpeedConsumptionFilterCondition } from "../../../pages/Owner/Analysis/queries";
import {
  greenTurquoise,
  limeGreen,
  paleGray,
  plumPurple,
  sunnyYellow,
  taupeBrown,
  weightedGray,
  white,
} from "../../../../utils/color";
import LightGrayButton from "../../../atoms/LightGrayButton";
import SucsessButton from "../../../atoms/SucsessButton";
import { FilterTitleList } from "../../../pages/Owner/Analysis/const";
import { FilterBooleanType } from "../../../pages/Owner/Analysis/types";
import { areAllFiltersTrue } from "../../../pages/Owner/Analysis/analysisUtils";
import { SpeedConsumptionIndividualForm } from "./SpeedConsumptionIndividualForm";
import { AnalysisSearchParameterModal } from "./AnalysisSearchParameterModal";
import { AnalysisSearchTitleProps } from "./AnalysisSearchParameterModal/AnalysisSearchParameterModal.hook";
import { GetAnalysisSearchParameterQuery } from "../../../../gen/graphql/types";

type Props = {
  isOpen: boolean;
  filterForm1: SpeedConsumptionFilterCondition;
  filterForm2: SpeedConsumptionFilterCondition;
  filterForm3: SpeedConsumptionFilterCondition;
  filterForm4: SpeedConsumptionFilterCondition;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateFilterForm1(value: SpeedConsumptionFilterCondition): void;
  onUpdateFilterForm2(value: SpeedConsumptionFilterCondition): void;
  onUpdateFilterForm3(value: SpeedConsumptionFilterCondition): void;
  onUpdateFilterForm4(value: SpeedConsumptionFilterCondition): void;
  onChangeGraph(): void;
  hasFilterFormError: FilterBooleanType;
  onResetFilter(): void;
  checkedMeRevolutionOver100: FilterBooleanType;
  setCheckedMeRevolutionOver100: React.Dispatch<
    React.SetStateAction<FilterBooleanType>
  >;
  isMeUsageFilter: FilterBooleanType;
  setIsMeUsageFilter: React.Dispatch<React.SetStateAction<FilterBooleanType>>;
  setAnalysisSearchParameterTitle: React.Dispatch<
    React.SetStateAction<AnalysisSearchTitleProps>
  >;
  loadDataAnalysisSearchParameter: (renderString: string) => Promise<void>;
  analysisSearchParameter: GetAnalysisSearchParameterQuery | undefined;
};

const VerticalBorderline = () => {
  return (
    <Box
      sx={{
        border: `1px solid  ${greenTurquoise}`,
        height: 705,
      }}
    />
  );
};

export type ModalFormBoolean = {
  checkedMeRevolutionOver100: boolean;
};

export const SpeedConsumptionIndividualFilterModal: FC<Props> = (
  props: Props
) => {
  const [isAnalysisSearchParameterModal, setIsAnalysisSearchParameterModal] =
    useState(false);

  const handleClose = () => props.setIsModalOpen(false);
  const onClickApplyFilter = () => {
    props.onChangeGraph();
    handleClose();
  };

  const modalFormBoolean = (title: string): ModalFormBoolean => {
    switch (title) {
      case FilterTitleList.Filter_1:
        return {
          checkedMeRevolutionOver100: props.checkedMeRevolutionOver100.filter1,
        };
      case FilterTitleList.Filter_2:
        return {
          checkedMeRevolutionOver100: props.checkedMeRevolutionOver100.filter2,
        };

      case FilterTitleList.Filter_3:
        return {
          checkedMeRevolutionOver100: props.checkedMeRevolutionOver100.filter3,
        };
      case FilterTitleList.Filter_4:
        return {
          checkedMeRevolutionOver100: props.checkedMeRevolutionOver100.filter4,
        };

      case FilterTitleList.Filter_All:
        return {
          checkedMeRevolutionOver100: areAllFiltersTrue(
            props.checkedMeRevolutionOver100
          ),
        };
      default:
        return {
          checkedMeRevolutionOver100: false,
        };
    }
  };

  const setCheckedMeRevolutionOver100 = useCallback(
    (title: string, value: boolean) => {
      switch (title) {
        case FilterTitleList.Filter_1:
          props.setCheckedMeRevolutionOver100((prev) => {
            return { ...prev, filter1: value };
          });
          break;
        case FilterTitleList.Filter_2:
          props.setCheckedMeRevolutionOver100((prev) => {
            return { ...prev, filter2: value };
          });
          break;

        case FilterTitleList.Filter_3:
          props.setCheckedMeRevolutionOver100((prev) => {
            return { ...prev, filter3: value };
          });
          break;
        case FilterTitleList.Filter_4:
          props.setCheckedMeRevolutionOver100((prev) => {
            return { ...prev, filter4: value };
          });
          break;
        case FilterTitleList.Filter_All:
          props.setCheckedMeRevolutionOver100({
            filter1: value,
            filter2: value,
            filter3: value,
            filter4: value,
          });
          break;
      }
    },
    [props]
  );

  const handleOnClear = () => {
    setCheckedMeRevolutionOver100(FilterTitleList.Filter_All, false);
    props.onResetFilter();
  };

  const style = {
    color: weightedGray,
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1240,
    bgcolor: paleGray,
    boxShadow: 24,
    p: 2,
    height: 850,
  };

  return (
    <>
      <Modal
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ pb: 2 }}
          >
            <Typography sx={{ fontSize: "24px" }}>
              {FilterTitleList.Filter_Individual}
            </Typography>
            <SucsessButton
              ButtonName={"View Saved Filters"}
              disabled={false}
              sx={{
                color: white,
                fontSize: 14,
                pl: 2,
              }}
              onClick={() => setIsAnalysisSearchParameterModal((prev) => !prev)}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent={"space-between"}
          >
            <SpeedConsumptionIndividualForm
              filterTitle={FilterTitleList.Filter_1}
              titleColor={sunnyYellow}
              filterForm={props.filterForm1}
              onUpdateFilterForm={props.onUpdateFilterForm1}
              onChangeGraph={props.onChangeGraph}
              onResetFilter={props.onResetFilter}
              setCheckedMeRevolutionOver100={setCheckedMeRevolutionOver100}
              checkedMeRevolutionOver100={props.checkedMeRevolutionOver100}
              isMeUsageFilter={props.isMeUsageFilter}
              setIsMeUsageFilter={props.setIsMeUsageFilter}
              modalFormBoolean={modalFormBoolean}
            />

            <VerticalBorderline />
            <SpeedConsumptionIndividualForm
              filterTitle={FilterTitleList.Filter_2}
              titleColor={limeGreen}
              filterForm={props.filterForm2}
              onUpdateFilterForm={props.onUpdateFilterForm2}
              onChangeGraph={props.onChangeGraph}
              onResetFilter={props.onResetFilter}
              setCheckedMeRevolutionOver100={setCheckedMeRevolutionOver100}
              checkedMeRevolutionOver100={props.checkedMeRevolutionOver100}
              isMeUsageFilter={props.isMeUsageFilter}
              setIsMeUsageFilter={props.setIsMeUsageFilter}
              modalFormBoolean={modalFormBoolean}
            />
            <VerticalBorderline />
            <SpeedConsumptionIndividualForm
              filterTitle={FilterTitleList.Filter_3}
              titleColor={taupeBrown}
              filterForm={props.filterForm3}
              onUpdateFilterForm={props.onUpdateFilterForm3}
              onChangeGraph={props.onChangeGraph}
              onResetFilter={props.onResetFilter}
              setCheckedMeRevolutionOver100={setCheckedMeRevolutionOver100}
              checkedMeRevolutionOver100={props.checkedMeRevolutionOver100}
              isMeUsageFilter={props.isMeUsageFilter}
              setIsMeUsageFilter={props.setIsMeUsageFilter}
              modalFormBoolean={modalFormBoolean}
            />
            <VerticalBorderline />
            <SpeedConsumptionIndividualForm
              filterTitle={FilterTitleList.Filter_4}
              titleColor={plumPurple}
              filterForm={props.filterForm4}
              onUpdateFilterForm={props.onUpdateFilterForm4}
              onChangeGraph={props.onChangeGraph}
              onResetFilter={props.onResetFilter}
              setCheckedMeRevolutionOver100={setCheckedMeRevolutionOver100}
              checkedMeRevolutionOver100={props.checkedMeRevolutionOver100}
              isMeUsageFilter={props.isMeUsageFilter}
              setIsMeUsageFilter={props.setIsMeUsageFilter}
              modalFormBoolean={modalFormBoolean}
            />
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent={"flex-start"}
            sx={{ mt: 3 }}
          >
            <SucsessButton
              ButtonName={"Apply"}
              disabled={
                props.hasFilterFormError.filter1 &&
                props.hasFilterFormError.filter2 &&
                props.hasFilterFormError.filter3 &&
                props.hasFilterFormError.filter4
              }
              onClick={() => {
                onClickApplyFilter();
              }}
              sx={{ mx: 0, width: 200, color: white }}
            />
            <LightGrayButton
              ButtonName={"Clear"}
              onClick={handleOnClear}
              sx={{ mx: 0 }}
            />
            <LightGrayButton
              ButtonName={"Cancel"}
              onClick={handleClose}
              sx={{ mx: 0 }}
            />
          </Stack>
        </Box>
      </Modal>
      <AnalysisSearchParameterModal
        isOpen={isAnalysisSearchParameterModal}
        setIsAnalysisSearchParameterModal={setIsAnalysisSearchParameterModal}
        onUpdateFilterForm1={props.onUpdateFilterForm1}
        onUpdateFilterForm2={props.onUpdateFilterForm2}
        onUpdateFilterForm3={props.onUpdateFilterForm3}
        onUpdateFilterForm4={props.onUpdateFilterForm4}
        setAnalysisSearchParameterTitle={props.setAnalysisSearchParameterTitle}
        setCheckedMeRevolutionOver100={setCheckedMeRevolutionOver100}
        loadDataAnalysisSearchParameter={props.loadDataAnalysisSearchParameter}
        analysisSearchParameter={props.analysisSearchParameter}
      />
    </>
  );
};
