import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Form } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../dexie/db";
import Template from "../../../templates/Crew/Arrival/Update";
import { useGetPreviousReport } from "../../../../hooks/dexie/dexie";
import { ArrivalDexie } from "../../../../dexie/Arrival";
import Loading from "../../../atoms/Loading";
import {
  mapToArrivalFormData,
  mapToArrivalMutationVariables,
  useArrivalForm,
} from "./arrival.module";

const Update = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);

  const navigate = useNavigate();
  const ship = useShip();
  const [isConfirm, setIsConfirm] = useState(false);
  const location = useLocation();
  const location_form = location.state;

  if (!report_id) navigate("/");

  const previousReport = useGetPreviousReport(report_id);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const onSaveDraft = (value: Form) => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToArrivalMutationVariables(value, ship?.ship_id);
    const updateArrival: ArrivalDexie = {
      id: report_id,
      isDraft: true,
      isSend: false,
      data: variables,
      draftForm: value,
    };
    db.arrival.update(report_id, updateArrival).catch((e) => console.error(e));
    navigate(`/crew/arrival`);
  };

  const onConfirm = () => {
    setIsConfirm(true);
    if (form.hasError) return;
    navigate(`/arrival/update/confirm/${reportId}`, {
      state: { form: form.data },
    });
  };

  const arrival = useLiveQuery(() => db.arrival.get(report_id));

  const form = useArrivalForm([
    location_form,
    arrival?.draftForm,
    mapToArrivalFormData(arrival?.data),
  ]);

  return (
    <Default title="Noon Report" selectedTitle={"Arrival"}>
      {arrival && !form.isLoading ? (
        <Template
          defaultArrival={arrival}
          form={form.data}
          hasFormError={form.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          onSaveDraft={onSaveDraft}
          ship={ship}
          lastReport={previousReport}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Default>
  );
};

export default Update;
