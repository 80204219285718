import { FuelType } from "../../../../../gen/graphql/types";
import { AdditionalROBForDeparture } from "../../../../pages/Crew/Departure/Create/types";

export const setAdditionalAdditionalROBForDeparture = (
  fuelType: FuelType,
  targetIndex?: number,
  value?: number,
  D_Additional_ROB: AdditionalROBForDeparture[] = []
) => {
  const updatedAdditionalROB = [...D_Additional_ROB];

  if (targetIndex === undefined || !updatedAdditionalROB[targetIndex]) {
    updatedAdditionalROB.push({
      D_Fuel_Type: fuelType,
      D_Fuel_volume: value,
    });
  } else {
    updatedAdditionalROB[targetIndex] = {
      D_Fuel_Type: fuelType,
      D_Fuel_volume: value,
    };
  }
  return updatedAdditionalROB;
};

export const filterAdditionalROBForDepartureByFuelType = (
  fuelTypeList: FuelType[],
  additionalROB?: AdditionalROBForDeparture[]
) => {
  return additionalROB?.filter((v) => fuelTypeList.includes(v.D_Fuel_Type));
};
