import * as React from "react";
import DefaultOwner from "../../layouts/DefaultOwner";
import NoonReport from "../../pages/Owner/NoonReport/NoonReport";

const Owner = (): React.ReactElement => {
  return (
    <DefaultOwner title="Noon Report" selectedTitle={"Noon Report"}>
      <NoonReport />
    </DefaultOwner>
  );
};

export default Owner;
