import Link from "@mui/material/Link"
import { Link as RouterLink } from "react-router-dom";

type Props = {
  color: "inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error" | undefined;
  to: string;
  underline?: "none" | "hover" | "always";
  style?: React.CSSProperties;
  children?: React.ReactNode;
}
export function MUILink({ ...props}: Props): JSX.Element {
  return (
    <Link
      component={RouterLink}
      color={props.color}
      underline={props.underline ?? "none"}
      to={props.to}
      sx={props.style}
      >{props.children}</Link>
  )
}