import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { IconButton, Typography } from "@mui/material";
import CommonNumberFIeld from "../../../atoms/CommonNumberFIeld";
import { FuelType } from "../../../../gen/graphql/types";
import { NestedBankeringFormBlockProps } from "./FormBlock";
import {
  charcoalGray,
  darkGray,
  lightGray,
  midnightBlue,
  darkSlateBlue,
} from "../../../../utils/color";

import {
  additionalBankeringKeyStrings,
  checkBankeringArraysLength,
} from "./bunkering.module";
import Optional from "../../../atoms/Optional";
import CommonSelect from "../../../atoms/CommonSelect";
import { useAdditonaConsumptionRowCounter } from "../../../../utils/form-util";

export function Bunkaring(props: NestedBankeringFormBlockProps) {
  const { increment, decrement, additionalRows, maxAdditonaConsumptionIndex } =
    useAdditonaConsumptionRowCounter(
      checkBankeringArraysLength(props.form),
      10
    );

  return (
    <>
      <Columns
        sx={{
          mb: 0,
        }}
      >
        <Column>
          <Typography
            sx={{
              textAlign: "left",
              verticalAlign: "top",
              fontSize: "16px",
              fontFamily: "Public Sans",
              lineHeight: "auto",
              color: lightGray,
            }}
          >
            Bunkering
          </Typography>
        </Column>
      </Columns>

      {additionalRows.map((_, index) => {
        const additionalLoopIndex = index + 1;

        const fuelTypeKey =
          additionalBankeringKeyStrings.fuelNameKey +
          additionalLoopIndex.toString();
        const massKey =
          additionalBankeringKeyStrings.massKey +
          additionalLoopIndex.toString();
        const volumeKey =
          additionalBankeringKeyStrings.volumeKey +
          additionalLoopIndex.toString();

        const densityKey =
          additionalBankeringKeyStrings.densityKey +
          additionalLoopIndex.toString();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fuelTypeValue = (props.form as any)[fuelTypeKey] || "";
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const massValue = (props.form as any)[massKey] || "";
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const volumeValue = (props.form as any)[volumeKey] || "";
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const densityValue = (props.form as any)[densityKey] || "";

        return (
          <Columns
            key={additionalLoopIndex}
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "0px",
            }}
            data-bunkeringFromNum={1}
          >
            <Column
              sx={{
                paddingTop: "0px",
              }}
            >
              <Optional />
              <CommonSelect
                id={fuelTypeKey}
                label={`Fuel Type ${additionalLoopIndex}`}
                key={fuelTypeKey}
                value={fuelTypeValue}
                onChange={function (value: string): void {
                  props.onChange(fuelTypeKey, value);
                }}
                selectValues={Object.values(FuelType)}
              />
            </Column>
            <Column
              sx={{
                paddingTop: "0px",
              }}
            >
              <Optional />
              <CommonNumberFIeld
                disabled={props.isPreview}
                id={massKey}
                label={`Mass ${additionalLoopIndex}`}
                placeholder=""
                value={massValue}
                unitName={"mt"}
                minValue={0}
                onChange={(e: string | null) => {
                  props.onChange(massKey, e);
                }}
              />
            </Column>
            <Column
              sx={{
                paddingTop: "0px",
              }}
            >
              <Optional />
              <CommonNumberFIeld
                disabled={props.isPreview}
                id={volumeKey}
                label={`Volume ${additionalLoopIndex}`}
                placeholder=""
                value={volumeValue}
                unitName={"m³"}
                minValue={0}
                onChange={(e: string | null) => {
                  props.onChange(volumeKey, e);
                }}
              />
            </Column>
            <Column
              sx={{
                paddingTop: "0px",
              }}
            >
              <Optional />
              <CommonNumberFIeld
                disabled={props.isPreview}
                id={densityKey}
                label={`Density ${additionalLoopIndex}`}
                placeholder=""
                value={densityValue}
                unitName={"mt/m³"}
                minValue={0}
                onChange={(e: string | null) => {
                  props.onChange(densityKey, e);
                }}
              />
            </Column>
            {additionalRows.length === additionalLoopIndex && (
              <>
                <Column sx={{ pt: 1.5 }}>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={
                      !(
                        fuelTypeValue &&
                        massValue &&
                        volumeValue &&
                        densityValue
                      ) || additionalLoopIndex >= maxAdditonaConsumptionIndex
                    }
                    sx={{
                      height: "40px",
                      width: "40px",
                      fontSize: "25px",
                      my: 0.1,
                      color: lightGray,
                      backgroundColor: darkGray,
                      borderRadius: "10%",
                      "&.Mui-disabled": {
                        color: charcoalGray,
                        backgroundColor: midnightBlue,
                      },
                      boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                    }}
                    onClick={() => increment()}
                  >
                    +
                  </IconButton>
                </Column>
                <Column sx={{ pt: 1.5 }}>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={false}
                    sx={{
                      height: "40px",
                      width: "40px",
                      fontSize: "25px",
                      my: 0.1,
                      color: lightGray,
                      backgroundColor: darkGray,
                      borderRadius: "10%",
                      "&.Mui-disabled": {
                        color: charcoalGray,
                        backgroundColor: darkSlateBlue,
                      },
                      boxShadow: "0px 3px 3px rgba(0,0,0,0.3)",
                    }}
                    onClick={() => {
                      props.onUpdateForm?.({
                        ...props.form,
                        [fuelTypeKey]: null,
                        [massKey]: null,
                        [volumeKey]: null,
                        [densityKey]: null,
                      });

                      decrement();
                    }}
                  >
                    -
                  </IconButton>
                </Column>
              </>
            )}
          </Columns>
        );
      })}
    </>
  );
}
