import dayjs from "dayjs";
import { isDate, isEmpty } from "./type-checks";

const STANDIN = "-";

export const emptyToHyphen = (value?: string | number | null): string =>
  !isEmpty(value) ? String(value) : STANDIN;

export const emptyToDoubleQuotation = (
  value?: string | number | null
): string => (!isEmpty(value) ? String(value) : "");

const format = (
  formatText: string,
  value?: string | number | Date | null,
  standin: typeof STANDIN | "" = STANDIN
): string => {
  if (!isDate(value) && isEmpty(value)) {
    return standin;
  }

  const dayjsDate = dayjs(value);

  return dayjsDate.isValid() ? dayjsDate.format(formatText) : standin;
};

export const formatToYYYYMMDD = (
  value?: string | number | Date | null,
  separator = "/",
  standin: typeof STANDIN | "" = STANDIN
): string => {
  return format(`YYYY${separator}MM${separator}DD`, value, standin);
};

export const formatToYYYYMMDDHHMM = (
  value?: string | number | Date | null,
  separator = " ",
  dateSeparator = "/",
  timeSeparator = ":",
  standin: typeof STANDIN | "" = STANDIN
): string => {
  return format(
    `YYYY${dateSeparator}MM${dateSeparator}DD${separator}HH${timeSeparator}mm`,
    value,
    standin
  );
};

export const formatToUtcYYYYMMDDHHMM = (value?: Date | null): string => {
  return value ? dayjs.utc(value).format("YYYY/MM/DD HH:mm") : "";
};

export const formatToUtcYYYYMMDDHHMMSS = (
  value?: Date | null,
  format = "YYYYMMDDHHmmss"
): string => {
  return value ? dayjs.utc(value).format(format) : "";
};

export const formatToYYYYMMDDHHMMSS = (
  value?: Date | null,
  format = "YYYYMMDDHHmmss"
): string => {
  return value ? dayjs(value).format(format) : "";
};

export const nonNullable = <T>(value: T): value is NonNullable<T> =>
  value !== null && value !== undefined;

export function safeNumber(n: number | null | undefined): number {
  if (n === null || n === undefined) {
    return NaN;
  } else {
    return Number(n);
  }
}

export function safeNumberForMappingInput(
  n: number | null | undefined
): number | undefined {
  if (n === null || n === undefined) {
    return undefined;
  } else {
    return Number(n);
  }
}

export function safeNumberForMappingInputRequrie(
  n: number | null | undefined
): number {
  return safeNumberForMappingInput(n) || 0;
}

export const convertEmptyStringToNull = (value: string): string | null => {
  if (value === "") {
    return null;
  }
  return value;
};
