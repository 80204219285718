import { SxProps, Theme } from "@mui/material";
import CommonNumberFIeld from "../../../../../atoms/CommonNumberFIeld";

type SettingNumberFieldProps = {
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  id: string;
  label: string;
  value: number | null | undefined;
  onChange(value: number | string | null): void;
  sx?: SxProps<Theme>;
};

const SettingNumberField = (
  props: SettingNumberFieldProps
): React.ReactElement => {
  return (
    <CommonNumberFIeld
      id={props.id}
      label={props.label}
      placeholder=""
      value={props.value}
      sx={{
        "& .MuiInputBase-input": { color: "#333333", fontSize: 11 },
        "& label.MuiInputLabel-root": {
          color: "#AAAAAA",
        },
        "& label.Mui-focused": {
          color: "#20BE89",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#20BE89",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#808EA4",
          },
          "&:hover fieldset": {
            borderColor: "#AAAAAA",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#20BE89",
          },
        },
        width: "86px",
        pl: 0.5,
      }}
      onChange={(e) => {
        props.onChange(e === "" ? null : Number(e));
      }}
    />
  );
};

export default SettingNumberField;
