import { convertDegreeToDirection } from "../../../../utils/convert-wind-direction-degree";
import {
  formatToCsvString,
  genSearchConditionHash,
} from "../../../../utils/download-csv-util";
import { formatToUtcYYYYMMDDHHMM, formatToYYYYMMDDHHMM } from "../../../../utils/formats";
import { Report } from "../../../../gen/graphql/types";

import { ReportSearchCondition } from "../../pages.module";

type CsvReport = Omit<
  Report,
  "created_at" | "updated_at" | "ship_id" | "ship" | "user" | "user_id"
>;

export const genCsv = (
  searchCondition: ReportSearchCondition,
  reportList: CsvReport[]
) => {
  const genFileName = async () => {
    const hashed = await genSearchConditionHash(searchCondition).catch((e) => {
      throw e;
    });
    const formatted = formatToYYYYMMDDHHMM(new Date(), "-", "", "");
    return `Noon_Report_${formatted}-${hashed}.csv`;
  };

  const genText = () => {
    const head = [
      '"Report ID"',
      '"Vessel name"',
      '"Voyage number"',
      '"Time Zone"',
      '"L or B"',
      '"Port Name"',
      '"UNLOC"',
      '"EU/UK"',
      '"Port L/U"',
      '"Date & Time (Local)"',
      '"Lat./ Lon"',
      '"Steam mode"',
      '"Hrs. Propelling"',
      '"Distance travelled over ground (nautical mile)"',
      '"Speed over the ground (avg. knot)"',
      '"Log speed (avg. knot)"',
      '"M/E rpm - avg."',
      '"M/E rpm - total."',
      '"M/E Revolution - Noon to Noon"',
      '"Slip ratio (%)"',
      '"FO Consumption per day M/E (mt)"',
      '"FO Consumption per day G/E (mt)"',
      '"FO Consumption per day Boiler & incinerator (mt)"',
      '"FO Additional FuelType 1"',
      '"FO Additional Usage 1"',
      '"FO Additional volume 1"',
      '"FO Additional FuelType 2"',
      '"FO Additional Usage 2"',
      '"FO Additional volume 2"',
      '"FO Additional FuelType 3"',
      '"FO Additional Usage 3"',
      '"FO Additional volume 3"',
      '"FO Additional FuelType 4"',
      '"FO Additional Usage 4"',
      '"FO Additional volume 4"',
      '"FO Additional FuelType 5"',
      '"FO Additional Usage 5"',
      '"FO Additional volume 5"',
      '"MGO Consumption per day M/E (mt)"',
      '"MGO Consumption per day G/E (mt)"',
      '"MGO Consumption per day Boiler (mt)"',
      '"MGO Additional FuelType 1"',
      '"MGO Additional Usage 1"',
      '"MGO Additional volume 1"',
      '"MGO Additional FuelType 2"',
      '"MGO Additional Usage 2"',
      '"MGO Additional volume 2"',
      '"MGO Additional FuelType 3"',
      '"MGO Additional Usage 3"',
      '"MGO Additional volume 3"',
      '"MGO Additional FuelType 4"',
      '"MGO Additional Usage 4"',
      '"MGO Additional volume 4"',
      '"MGO Additional FuelType 5"',
      '"MGO Additional Usage 5"',
      '"MGO Additional volume 5"',
      '"LO Consumption per day MECO (L)"',
      '"LO Consumption per day MESO(L)"',
      '"LO Consumption per day GESO(L)"',
      '"Other Fuel Consumption per day FuelType 1"',
      '"Other Fuel Consumption per day Usage 1"',
      '"Other Fuel Consumption per day volume 1"',
      '"Other Fuel Consumption per day FuelType 2"',
      '"Other Fuel Consumption per day Usage 2"',
      '"Other Fuel Consumption per day volume 2"',
      '"Other Fuel Consumption per day FuelType 3"',
      '"Other Fuel Consumption per day Usage 3"',
      '"Other Fuel Consumption per day volume 3"',
      '"Other Fuel Consumption per day FuelType 4"',
      '"Other Fuel Consumption per day Usage 4"',
      '"Other Fuel Consumption per day volume 4"',
      '"Other Fuel Consumption per day FuelType 5"',
      '"Other Fuel Consumption per day Usage 5"',
      '"Other Fuel Consumption per day volume 5"',
      '"Weather"',
      '"Wind Direction"',
      '"BF Scale"',
      '"Swell"',
      '"Noon Course"',
      '"Dist to go"',
      '"Next port"',
      '"ETA"',
      '"ETB"',
      '"ETD"',
      '"M/E Fuel index"',
      '"M/E RPM"',
      '"M/E horsepower"',
      '"M/E power"',
      '"M/E power measurement method,"',
      '"Running hours SOx scrubber"',
      '"Running hours SOx M/E EGR"',
      '"Running hours SOx G/E SCR"',
      '"ROB FO (mt)"',
      '"ROB MGO (mt)"',
      '"ROB MECO (L)"',
      '"ROB MESO (L)"',
      '"ROB GESO (L)"',
      '"ROB FW (mt)"',
      '"ROB Fuel Type 1"',
      '"ROB Fuel Volume 1"',
      '"ROB Fuel Type 2"',
      '"ROB Fuel Volume 2"',
      '"ROB Fuel Type 3"',
      '"ROB Fuel Volume 3"',
      '"ROB Fuel Type 4"',
      '"ROB Fuel Volume 4"',
      '"ROB Fuel Type 5"',
      '"ROB Fuel Volume 5"',
      '"ROB Fuel Type 6"',
      '"ROB Fuel Volume 6"',
      '"ROB Fuel Type 7"',
      '"ROB Fuel Volume 7"',
      '"ROB Fuel Type 8"',
      '"ROB Fuel Volume 8"',
      '"ROB Fuel Type 9"',
      '"ROB Fuel Volume 9"',
      '"ROB Fuel Type 10"',
      '"ROB Fuel Volume 10"',
      '"FO CO2 emission"',
      '"MGO CO2 emission"',
      '"FO CO2 emission"',
      '"MGO CO2 emission"',
      '"Remark"',
      '"Spare 1"',
      '"Spare 1 unit name"',
      '"Spare 2"',
      '"Spare 2 unit name"',
      '"Spare 3"',
      '"Spare 3 unit name"',
      '"Spare 4"',
      '"Spare 4 unit name"',
      '"Spare 5"',
      '"Spare 5 unit name"',
      '"Spare 6"',
      '"Spare 6 unit name"',
      '"Spare 7"',
      '"Spare 7 unit name"',
      '"Spare 8"',
      '"Spare 8 unit name"',
      '"Spare 9"',
      '"Spare 9 unit name"',
      '"Spare 10"',
      '"Spare 10 unit name"',
      '"Spare 11"',
      '"Spare 11 unit name"',
      '"Spare 12"',
      '"Spare 12 unit name"',
      '"Spare 13"',
      '"Spare 13 unit name"',
      '"Spare 14"',
      '"Spare 14 unit name"',
      '"Spare 15"',
      '"Spare 15 unit name"',
      '"Spare 16"',
      '"Spare 16 unit name"',
      '"Spare 17"',
      '"Spare 17 unit name"',
      '"Spare 18"',
      '"Spare 18 unit name"',
      '"Spare 19"',
      '"Spare 19 unit name"',
      '"Spare 20"',
      '"Spare 20 unit name"',
    ].join(",");
    const body = reportList.map((report) => {
      const report_id = report.id;
      const N_Vessel_name = formatToCsvString(report.N_Vessel_name);
      const N_Voy = formatToCsvString(report.N_Voy);
      const N_LorB = formatToCsvString(report.N_LorB);
      const N_DateTime = formatToUtcYYYYMMDDHHMM(report.N_DateTime);
      const N_TimeZone = formatToCsvString(report.N_TimeZone);
      const N_LatLong = formatToCsvString(report.N_LatLong);
      const N_Noon_Port = formatToCsvString(report.N_Noon_Port);
      const N_UNLOC = formatToCsvString(report.N_UNLOC);
      const N_EU_UK_port = report.N_EU_UK_port;
      const N_Port_L_U = report.N_Port_L_U;
      const N_Steam_mode = formatToCsvString(report.N_Steam_mode);
      const N_Hrs_propelling = report.N_Hrs_propelling;
      const N_Dist_OG = report.N_Dist_OG;
      const N_Speed_OG = report.N_Speed_OG;
      const N_Speed_OW = report.N_Speed_OW;
      const N_ME_rpm_ave = report.N_ME_rpm_ave;
      const N_ME_rpm_total = report.N_ME_rpm_total;
      const N_ME_Revolution = report.N_ME_Revolution;
      const N_Slip = report.N_Slip;

      const N_FO_Consumption_per_day_ME = report.N_FO_Consumption_per_day_ME;
      const N_FO_Consumption_per_day_GE = report.N_FO_Consumption_per_day_GE;
      const N_FO_Consumption_per_day_BInc =
        report.N_FO_Consumption_per_day_BInc;

      const N_FO_Additional_FuelType_1 = report.N_FO_Additional_FuelType_1;
      const N_FO_Additional_Usage_1 = formatToCsvString(report.N_FO_Additional_Usage_1);
      const N_FO_Additional_volume_1 = report.N_FO_Additional_volume_1;
      const N_FO_Additional_FuelType_2 = report.N_FO_Additional_FuelType_2;
      const N_FO_Additional_Usage_2 = formatToCsvString(report.N_FO_Additional_Usage_2);
      const N_FO_Additional_volume_2 = report.N_FO_Additional_volume_2;
      const N_FO_Additional_FuelType_3 = report.N_FO_Additional_FuelType_3;
      const N_FO_Additional_Usage_3 = formatToCsvString(report.N_FO_Additional_Usage_3);
      const N_FO_Additional_volume_3 = report.N_FO_Additional_volume_3;
      const N_FO_Additional_FuelType_4 = report.N_FO_Additional_FuelType_4;
      const N_FO_Additional_Usage_4 = formatToCsvString(report.N_FO_Additional_Usage_4);
      const N_FO_Additional_volume_4 = report.N_FO_Additional_volume_4;
      const N_FO_Additional_FuelType_5 = report.N_FO_Additional_FuelType_5;
      const N_FO_Additional_Usage_5 = formatToCsvString(report.N_FO_Additional_Usage_5);
      const N_FO_Additional_volume_5 = report.N_FO_Additional_volume_5;

      const N_MGO_Consumption_per_day_ME = report.N_MGO_Consumption_per_day_ME;
      const N_MGO_Consumption_per_day_GE = report.N_MGO_Consumption_per_day_GE;
      const N_MGO_Consumption_per_day_Boiler =
        report.N_MGO_Consumption_per_day_Boiler;

      const N_MGO_Additional_FuelType_1 = report.N_MGO_Additional_FuelType_1;
      const N_MGO_Additional_Usage_1 = formatToCsvString(report.N_MGO_Additional_Usage_1);
      const N_MGO_Additional_volume_1 = report.N_MGO_Additional_volume_1;
      const N_MGO_Additional_FuelType_2 = report.N_MGO_Additional_FuelType_2;
      const N_MGO_Additional_Usage_2 = formatToCsvString(report.N_MGO_Additional_Usage_2);
      const N_MGO_Additional_volume_2 = report.N_MGO_Additional_volume_2;
      const N_MGO_Additional_FuelType_3 = report.N_MGO_Additional_FuelType_3;
      const N_MGO_Additional_Usage_3 = formatToCsvString(report.N_MGO_Additional_Usage_3);
      const N_MGO_Additional_volume_3 = report.N_MGO_Additional_volume_3;
      const N_MGO_Additional_FuelType_4 = report.N_MGO_Additional_FuelType_4;
      const N_MGO_Additional_Usage_4 = formatToCsvString(report.N_MGO_Additional_Usage_4);
      const N_MGO_Additional_volume_4 = report.N_MGO_Additional_volume_4;
      const N_MGO_Additional_FuelType_5 = report.N_MGO_Additional_FuelType_5;
      const N_MGO_Additional_Usage_5 = formatToCsvString(report.N_MGO_Additional_Usage_5);
      const N_MGO_Additional_volume_5 = report.N_MGO_Additional_volume_5;

      const N_LO_Consumption_per_day_MECO =
        report.N_LO_Consumption_per_day_MECO;
      const N_LO_Consumption_per_day_MESO =
        report.N_LO_Consumption_per_day_MESO;
      const N_LO_Consumption_per_day_GESO =
        report.N_LO_Consumption_per_day_GESO;

      const N_Other_Additional_FuelType_1 =
        report.N_Other_Additional_FuelType_1;
      const N_Other_Additional_Usage_1 = formatToCsvString(report.N_Other_Additional_Usage_1);
      const N_Other_Additional_volume_1 = report.N_Other_Additional_volume_1;
      const N_Other_Additional_FuelType_2 =
        report.N_Other_Additional_FuelType_2;
      const N_Other_Additional_Usage_2 = formatToCsvString(report.N_Other_Additional_Usage_2);
      const N_Other_Additional_volume_2 = report.N_Other_Additional_volume_2;
      const N_Other_Additional_FuelType_3 =
        report.N_Other_Additional_FuelType_3;
      const N_Other_Additional_Usage_3 = formatToCsvString(report.N_Other_Additional_Usage_3);
      const N_Other_Additional_volume_3 = report.N_Other_Additional_volume_3;
      const N_Other_Additional_FuelType_4 =
        report.N_Other_Additional_FuelType_4;
      const N_Other_Additional_Usage_4 = formatToCsvString(report.N_Other_Additional_Usage_4);
      const N_Other_Additional_volume_4 = report.N_Other_Additional_volume_4;
      const N_Other_Additional_FuelType_5 =
        report.N_Other_Additional_FuelType_5;
      const N_Other_Additional_Usage_5 = formatToCsvString(report.N_Other_Additional_Usage_5);
      const N_Other_Additional_volume_5 = report.N_Other_Additional_volume_5;

      const N_Weather = formatToCsvString(report.N_Weather);
      const N_Wind_direction = convertDegreeToDirection(
        report.N_Wind_direction
      );
      const N_BF_scale = report.N_BF_scale;
      const N_Swell = report.N_Swell;
      const N_Noon_Course = report.N_Noon_Course;
      const N_Dist_to_go = report.N_Dist_to_go;
      const N_Next_port = formatToCsvString(report.N_Next_port);
      const N_ETA = formatToUtcYYYYMMDDHHMM(report.N_ETA);
      const N_ETB = formatToUtcYYYYMMDDHHMM(report.N_ETB);
      const N_ETD = formatToUtcYYYYMMDDHHMM(report.N_ETD);
      const N_ME_Fuel_index = report.N_ME_Fuel_index;
      const N_ME_RPM = report.N_ME_RPM;
      const N_ME_horsepower = report.N_ME_horsepower;
      const N_ME_power = report.N_ME_power;
      const N_ME_power_measurement_method =
        report.N_ME_power_measurement_method;
      const N_Running_hours_SOx_scrubber = report.N_Running_hours_SOx_scrubber;
      const N_Running_hours_ME_EGR = report.N_Running_hours_ME_EGR;
      const N_Running_hours_GE_SCR = report.N_Running_hours_GE_SCR;
      const N_ROB_FO = report.N_ROB_FO;
      const N_ROB_MGO = report.N_ROB_MGO;
      const N_ROB_MECO = report.N_ROB_MECO;
      const N_ROB_MESO = report.N_ROB_MESO;
      const N_ROB_GESO = report.N_ROB_GESO;
      const N_ROB_FW = report.N_ROB_FW;

      const All_Additional_ROB_csv_data: string[][] = [];
      const All_Additional_ROB_Arr = report.N_Additional_ROB || [];

      for (let i = 0; i < 10; i++) {
        const dataAtIndex = All_Additional_ROB_Arr
          ? All_Additional_ROB_Arr[i]
          : false;

        if (dataAtIndex) {
          All_Additional_ROB_csv_data.push([
            dataAtIndex.N_Fuel_Type as string,
            dataAtIndex.N_Fuel_volume
              ? dataAtIndex.N_Fuel_volume.toString()
              : "-",
          ]);
        } else {
          All_Additional_ROB_csv_data.push(["", ""]);
        }
      }
      const N_FO_CO2_emission_per_unit = report.N_FO_CO2_emission_per_unit;
      const N_MGO_CO2_emission_per_unit = report.N_MGO_CO2_emission_per_unit;
      const N_FO_CO2_emission = report.N_FO_CO2_emission;
      const N_MGO_CO2_emission = report.N_MGO_CO2_emission;
      const N_Remark = formatToCsvString(report.N_Remark);
      const N_Spare_1 = formatToCsvString(report.N_Spare_1);
      const N_Spare_1_unit_name = formatToCsvString(
        report.N_Spare_1_unit_name
      );
      const N_Spare_2 = formatToCsvString(report.N_Spare_2);
      const N_Spare_2_unit_name = formatToCsvString(
        report.N_Spare_2_unit_name
      );
      const N_Spare_3 = formatToCsvString(report.N_Spare_3);
      const N_Spare_3_unit_name = formatToCsvString(
        report.N_Spare_3_unit_name
      );
      const N_Spare_4 = formatToCsvString(report.N_Spare_4);
      const N_Spare_4_unit_name = formatToCsvString(
        report.N_Spare_4_unit_name
      );
      const N_Spare_5 = formatToCsvString(report.N_Spare_5);
      const N_Spare_5_unit_name = formatToCsvString(
        report.N_Spare_5_unit_name
      );
      const N_Spare_6 = formatToCsvString(report.N_Spare_6);
      const N_Spare_6_unit_name = formatToCsvString(
        report.N_Spare_6_unit_name
      );
      const N_Spare_7 = formatToCsvString(report.N_Spare_7);
      const N_Spare_7_unit_name = formatToCsvString(
        report.N_Spare_7_unit_name
      );
      const N_Spare_8 = formatToCsvString(report.N_Spare_8);
      const N_Spare_8_unit_name = formatToCsvString(
        report.N_Spare_8_unit_name
      );
      const N_Spare_9 = formatToCsvString(report.N_Spare_9);
      const N_Spare_9_unit_name = formatToCsvString(
        report.N_Spare_9_unit_name
      );
      const N_Spare_10 = formatToCsvString(report.N_Spare_10);
      const N_Spare_10_unit_name = formatToCsvString(
        report.N_Spare_10_unit_name
      );
      const N_Spare_11 = formatToCsvString(report.N_Spare_11);
      const N_Spare_11_unit_name = formatToCsvString(
        report.N_Spare_11_unit_name
      );
      const N_Spare_12 = formatToCsvString(report.N_Spare_12);
      const N_Spare_12_unit_name = formatToCsvString(
        report.N_Spare_12_unit_name
      );
      const N_Spare_13 = formatToCsvString(report.N_Spare_13);
      const N_Spare_13_unit_name = formatToCsvString(
        report.N_Spare_13_unit_name
      );
      const N_Spare_14 = formatToCsvString(report.N_Spare_14);
      const N_Spare_14_unit_name = formatToCsvString(
        report.N_Spare_14_unit_name
      );
      const N_Spare_15 = formatToCsvString(report.N_Spare_15);
      const N_Spare_15_unit_name = formatToCsvString(
        report.N_Spare_15_unit_name
      );
      const N_Spare_16 = formatToCsvString(report.N_Spare_16);
      const N_Spare_16_unit_name = formatToCsvString(
        report.N_Spare_16_unit_name
      );
      const N_Spare_17 = formatToCsvString(report.N_Spare_17);
      const N_Spare_17_unit_name = formatToCsvString(
        report.N_Spare_17_unit_name
      );
      const N_Spare_18 = formatToCsvString(report.N_Spare_18);
      const N_Spare_18_unit_name = formatToCsvString(
        report.N_Spare_18_unit_name
      );
      const N_Spare_19 = formatToCsvString(report.N_Spare_19);
      const N_Spare_19_unit_name = formatToCsvString(
        report.N_Spare_19_unit_name
      );
      const N_Spare_20 = formatToCsvString(report.N_Spare_20);
      const N_Spare_20_unit_name = formatToCsvString(
        report.N_Spare_20_unit_name
      );

      return [
        report_id,
        N_Vessel_name,
        N_Voy,
        N_TimeZone,
        N_LorB,
        N_Noon_Port,
        N_UNLOC,
        N_EU_UK_port,
        N_Port_L_U,
        N_DateTime,
        N_LatLong,
        N_Steam_mode,
        N_Hrs_propelling,
        N_Dist_OG,
        N_Speed_OG,
        N_Speed_OW,
        N_ME_rpm_ave,
        N_ME_rpm_total,
        N_ME_Revolution,
        N_Slip,
        N_FO_Consumption_per_day_ME,
        N_FO_Consumption_per_day_GE,
        N_FO_Consumption_per_day_BInc,
        N_FO_Additional_FuelType_1,
        N_FO_Additional_Usage_1,
        N_FO_Additional_volume_1,
        N_FO_Additional_FuelType_2,
        N_FO_Additional_Usage_2,
        N_FO_Additional_volume_2,
        N_FO_Additional_FuelType_3,
        N_FO_Additional_Usage_3,
        N_FO_Additional_volume_3,
        N_FO_Additional_FuelType_4,
        N_FO_Additional_Usage_4,
        N_FO_Additional_volume_4,
        N_FO_Additional_FuelType_5,
        N_FO_Additional_Usage_5,
        N_FO_Additional_volume_5,
        N_MGO_Consumption_per_day_ME,
        N_MGO_Consumption_per_day_GE,
        N_MGO_Consumption_per_day_Boiler,
        N_MGO_Additional_FuelType_1,
        N_MGO_Additional_Usage_1,
        N_MGO_Additional_volume_1,
        N_MGO_Additional_FuelType_2,
        N_MGO_Additional_Usage_2,
        N_MGO_Additional_volume_2,
        N_MGO_Additional_FuelType_3,
        N_MGO_Additional_Usage_3,
        N_MGO_Additional_volume_3,
        N_MGO_Additional_FuelType_4,
        N_MGO_Additional_Usage_4,
        N_MGO_Additional_volume_4,
        N_MGO_Additional_FuelType_5,
        N_MGO_Additional_Usage_5,
        N_MGO_Additional_volume_5,
        N_LO_Consumption_per_day_MECO,
        N_LO_Consumption_per_day_MESO,
        N_LO_Consumption_per_day_GESO,
        N_Other_Additional_FuelType_1,
        N_Other_Additional_Usage_1,
        N_Other_Additional_volume_1,
        N_Other_Additional_FuelType_2,
        N_Other_Additional_Usage_2,
        N_Other_Additional_volume_2,
        N_Other_Additional_FuelType_3,
        N_Other_Additional_Usage_3,
        N_Other_Additional_volume_3,
        N_Other_Additional_FuelType_4,
        N_Other_Additional_Usage_4,
        N_Other_Additional_volume_4,
        N_Other_Additional_FuelType_5,
        N_Other_Additional_Usage_5,
        N_Other_Additional_volume_5,
        N_Weather,
        N_Wind_direction,
        N_BF_scale,
        N_Swell,
        N_Noon_Course,
        N_Dist_to_go,
        N_Next_port,
        N_ETA,
        N_ETB,
        N_ETD,
        N_ME_Fuel_index,
        N_ME_RPM,
        N_ME_horsepower,
        N_ME_power,
        N_ME_power_measurement_method,
        N_Running_hours_SOx_scrubber,
        N_Running_hours_ME_EGR,
        N_Running_hours_GE_SCR,
        N_ROB_FO,
        N_ROB_MGO,
        N_ROB_MECO,
        N_ROB_MESO,
        N_ROB_GESO,
        N_ROB_FW,
        All_Additional_ROB_csv_data.flatMap((v) => v),
        N_FO_CO2_emission_per_unit,
        N_MGO_CO2_emission_per_unit,
        N_FO_CO2_emission,
        N_MGO_CO2_emission,
        N_Remark,
        N_Spare_1,
        N_Spare_1_unit_name,
        N_Spare_2,
        N_Spare_2_unit_name,
        N_Spare_3,
        N_Spare_3_unit_name,
        N_Spare_4,
        N_Spare_4_unit_name,
        N_Spare_5,
        N_Spare_5_unit_name,
        N_Spare_6,
        N_Spare_6_unit_name,
        N_Spare_7,
        N_Spare_7_unit_name,
        N_Spare_8,
        N_Spare_8_unit_name,
        N_Spare_9,
        N_Spare_9_unit_name,
        N_Spare_10,
        N_Spare_10_unit_name,
        N_Spare_11,
        N_Spare_11_unit_name,
        N_Spare_12,
        N_Spare_12_unit_name,
        N_Spare_13,
        N_Spare_13_unit_name,
        N_Spare_14,
        N_Spare_14_unit_name,
        N_Spare_15,
        N_Spare_15_unit_name,
        N_Spare_16,
        N_Spare_16_unit_name,
        N_Spare_17,
        N_Spare_17_unit_name,
        N_Spare_18,
        N_Spare_18_unit_name,
        N_Spare_19,
        N_Spare_19_unit_name,
        N_Spare_20,
        N_Spare_20_unit_name,
      ].join(",");
    });

    return [head, ...body].join("\n");
  };

  return (async () => {
    const fileName = await genFileName().catch((e) => {
      throw e;
    });
    const text = genText();

    return {
      fileName,
      text,
    };
  })();
};
