import {
  Box,
  FormControl,
  Slider,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import React, { FC, useCallback } from "react";
import VesselNamesSelect from "../../../atoms/VesselNamesSelect";
import {
  SpeedConsumptionFilterCondition,
  meRevolutionMax,
} from "../../../pages/Owner/Analysis/queries";
import Optional from "../../../atoms/Optional";
import UtcDateTimePicker from "../../../atoms/UtcDateTimePicker";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import { LorB, SteamMode } from "../../../../gen/graphql/types";
import { convertEmptyStringToNull } from "../../../../utils/formats";
import {
  greenTurquoise,
  lightGray,
  sliderColor,
  steelBlue,
  weightedGray,
  white,
} from "../../../../utils/color";
import ControlCheckbox from "../../../atoms/ControlCheckbox";
import { FilterBooleanType } from "../../../pages/Owner/Analysis/types";
import { OptionalWithChildren } from "../../../atoms/OptionalWithChildren";
import CommonSelect from "../../../atoms/CommonSelect";
import { ModalFormBoolean } from "./SpeedConsumptionIndividualFilterModal";
import {
  beaufortScale,
  hrsPropelling,
  meRpmRange,
} from "./defaultFilterFormValue";
import { Circle } from "../../../atoms/Circle";

type Props = {
  filterTitle: string;
  titleColor: string;
  filterForm: SpeedConsumptionFilterCondition;
  onUpdateFilterForm(value: SpeedConsumptionFilterCondition): void;
  onChangeGraph(): void;
  onResetFilter(): void;
  checkedMeRevolutionOver100: FilterBooleanType;
  isMeUsageFilter: FilterBooleanType;
  setIsMeUsageFilter: React.Dispatch<React.SetStateAction<FilterBooleanType>>;
  setCheckedMeRevolutionOver100: (title: string, value: boolean) => void;
  modalFormBoolean: (title: string) => ModalFormBoolean;
};

export const SpeedConsumptionIndividualForm: FC<Props> = (props: Props) => {
  const onChange = useCallback(
    (
      key: string,
      value: string | number | number[] | Date | null | undefined
    ) => {
      if (typeof value === "string") {
        value = convertEmptyStringToNull(value);
      }

      const form = {
        ...props.filterForm,
        [key]: value,
      };

      props.onUpdateFilterForm(form);
    },
    [props]
  );

  const defaultWidth = 285;

  const handleBeaufortChange = (event: Event, newValue: number | number[]) => {
    onChange("bf_scale", newValue as number[]);
  };

  const handleMeRevolutionChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    const valueArray = newValue as number[];
    if (Array.isArray(newValue) && valueArray[1] !== meRevolutionMax) {
      props.setCheckedMeRevolutionOver100(props.filterTitle, false);
    }
    onChange("me_revolution", valueArray);
  };

  const handleCheckMeRevolutionOver100Change = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        onChange("me_revolution", [
          props.filterForm.me_revolution
            ? props.filterForm.me_revolution[0]
            : 0,
        ]); // Filterのstateにおいてme_revolutionの上限を取り払う (100以上も取得対象とするため)
        props.setCheckedMeRevolutionOver100(props.filterTitle, checked);
        return;
      } else if (!checked) {
        if (props.filterForm.me_revolution?.length == 1) {
          onChange("me_revolution", [
            props.filterForm.me_revolution
              ? props.filterForm.me_revolution[0]
              : 0,
            100,
          ]);
        } else {
          onChange("me_revolution", props.filterForm.me_revolution);
        }
      }

      props.setCheckedMeRevolutionOver100(props.filterTitle, checked);
    },
    [onChange, props]
  );

  const handleHrsPropellingChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    onChange("hrs_propelling", newValue as number[]);
  };

  return (
    <Box
      sx={{
        width: defaultWidth,
      }}
    >
      <Stack spacing={2} justifyContent="flex-start" alignItems="center">
        <Stack spacing={1}>
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            sx={{ pb: 0.5 }}
          >
            <Circle sx={{ bgcolor: props.titleColor }} />
            <Typography sx={{ fontSize: "20px" }}>
              {props.filterTitle}
            </Typography>
          </Stack>
          <VesselNamesSelect
            value={props.filterForm.imo_no ?? ""}
            minWidth={defaultWidth}
            sx={{
              "& .MuiSelect-select": {
                color: weightedGray,
              },
              "& .MuiSelect-select:hover": {
                color: weightedGray,
              },
            }}
            onChange={(event) => {
              onChange("imo_no", event.target.value);
            }}
          />
          <OptionalWithChildren>
            <CommonTextFIeld
              id={"voy"}
              label={"Voy#"}
              value={props.filterForm.voy}
              sx={{
                "& .MuiInputBase-input": { color: weightedGray },
              }}
              onChange={(e: string | null) => {
                onChange("voy", e);
              }}
              width={"130px"}
            />
          </OptionalWithChildren>
        </Stack>

        <Grid
          rowSpacing={2}
          container
          item
          xs={12}
          justifyContent={"flex-start"}
          sx={{ p: 0 }}
        >
          <Grid item xs={2.5} sx={{ p: 0 }}>
            <Typography
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
                p: 0,
              }}
            >
              From
            </Typography>
          </Grid>
          <Grid item xs={9.5}>
            <UtcDateTimePicker
              width="220px"
              id={"from_dateTime"}
              label={"Date & Time"}
              value={props.filterForm.from_dateTime}
              onChange={onChange}
              onChangeKey={"from_dateTime"}
              hasMinDate={false}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Typography
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
              }}
            >
              To
            </Typography>
          </Grid>
          <Grid item xs={9.5}>
            <UtcDateTimePicker
              width="220px"
              id={"to_dateTime"}
              label={"Date & Time"}
              value={props.filterForm.to_dateTime}
              onChange={onChange}
              onChangeKey={"to_dateTime"}
              hasMinDate={false}
            />
          </Grid>
        </Grid>

        <Box>
          <Typography id="N_BF_scale">Beaufort</Typography>
          <Optional />
          <FormControl
            sx={{
              minWidth: 120,
              "& .MuiInputBase-input": {
                color: white,
                borderColor: steelBlue,
              },
              "& .MuiFormLabel-root": {
                color: lightGray,
                border: `1px transparent`,
                "&:hover fieldset": {
                  color: lightGray,
                },
              },
              "& .MuiSelect-icon": {
                color: weightedGray,
              },
            }}
            size="small"
          >
            <Box
              sx={{
                width: "280px",
                marginTop: "5px",
                pl: 1.2,
              }}
            >
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={props.filterForm.bf_scale || []}
                min={0}
                max={12}
                onChange={handleBeaufortChange}
                valueLabelDisplay="auto"
                marks={beaufortScale}
                sx={{ color: sliderColor }}
              />
            </Box>
          </FormControl>
        </Box>

        <Box>
          <Typography id="N_ME_RPM">M/E rpm - avg.</Typography>
          <Optional />
          <FormControl
            sx={{
              minWidth: 120,
              "& .MuiInputBase-input": {
                color: white,
                borderColor: steelBlue,
              },
              "& .MuiFormLabel-root": {
                color: lightGray,
                border: `1px transparent`,
                "&:hover fieldset": {
                  color: greenTurquoise,
                },
              },
              "& .MuiSelect-icon": {
                color: weightedGray,
              },
            }}
            size="small"
          >
            <Box
              sx={{
                width: "280px",
                marginTop: "5px",
                pl: 1,
                display: "flex",
              }}
            >
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={
                  props.modalFormBoolean(props.filterTitle)
                    .checkedMeRevolutionOver100
                    ? [
                        props.filterForm.me_revolution
                          ? props.filterForm.me_revolution[0]
                          : 0,
                        100,
                      ]
                    : props.filterForm.me_revolution || []
                }
                min={0}
                max={100}
                onChange={handleMeRevolutionChange}
                valueLabelDisplay="auto"
                marks={meRpmRange}
                sx={{ color: sliderColor }}
              />
              <ControlCheckbox
                checked={
                  props.modalFormBoolean(props.filterTitle)
                    .checkedMeRevolutionOver100
                }
                onChange={handleCheckMeRevolutionOver100Change}
                label="Unlimited"
              />
            </Box>
          </FormControl>
        </Box>

        <Box>
          <Typography id="N_BF_scale">Hrs. propelling</Typography>
          <Optional />
          <FormControl
            sx={{
              minWidth: 120,
              "& .MuiInputBase-input": {
                color: white,
                borderColor: steelBlue,
              },
              "& .MuiFormLabel-root": {
                color: lightGray,
                border: `1px transparent`,
                "&:hover fieldset": {
                  color: greenTurquoise,
                },
              },
              "& .MuiSelect-icon": {
                color: weightedGray,
              },
            }}
            size="small"
          >
            <Box
              sx={{
                width: "275px",
                marginTop: "5px",
                pl: 1,
              }}
            >
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={props.filterForm.hrs_propelling || []}
                min={0}
                max={30}
                onChange={handleHrsPropellingChange}
                valueLabelDisplay="auto"
                marks={hrsPropelling}
                sx={{ color: sliderColor }}
              />
            </Box>
          </FormControl>
        </Box>

        <Stack direction={"row"} spacing={2}>
          <CommonSelect
            isOwner
            isOptional
            id={"N_LorB"}
            label={"L or B"}
            value={props.filterForm.ballast_or_laden || ""}
            sx={{ width: 115 }}
            onChange={(value) => {
              onChange("ballast_or_laden", value);
            }}
            selectValues={Object.values(LorB)}
          />

          <CommonSelect
            isOwner
            isOptional
            id={"N_Steam_mode"}
            label={"Steam Mode"}
            value={props.filterForm.steam_mode || ""}
            sx={{ width: 145 }}
            onChange={(value) => {
              onChange("steam_mode", value);
            }}
            selectValues={Object.values(SteamMode)}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
