import { useAuth0, User } from "@auth0/auth0-react";
import { FoFuelType, FuelType, UserAuthCodes } from "./gen/graphql/types";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { useShip } from "./components/layouts/DefaultCrew";
import { RouteConfig } from "./RouteConfig";
import { ReportSearchCondition } from "./components/pages/pages.module";
import { ShipDexie } from "./dexie/Ship";
import { convertFoFuelTypeToFuelType } from "./components/organisms/Crew/NoonReport/Consumption/consumptionForm.module";
import { GlobalLoadProvider } from "./context/LoadProvider";

export type METADATA = {
  noon_report: {
    permission: UserAuthCodes;
  };
};

type ContextInterface = {
  readonly ship_id: number;
  readonly fuelType: FuelType;
};

type ReportSerchConditionContextType = {
  reportSearchCondition: ReportSearchCondition | undefined;
  setReportSearchCondition: Dispatch<
    SetStateAction<ReportSearchCondition | undefined>
  >;
};

type Props = {
  children: ReactNode;
  user?: User;
  ship?: ShipDexie;
};

const OrganizationIdContext = createContext<ContextInterface | undefined>(
  undefined
);

const setShipIdContext = createContext<Dispatch<SetStateAction<number>>>(
  () => undefined
);

const reportSerchConditionContext = createContext<
  ReportSerchConditionContextType | undefined
>(undefined);

const setOrganizationIdContext = createContext<
  Dispatch<SetStateAction<number>>
>(() => undefined);

export const OrganizationIdProvider = (props: Props) => {
  const [shipId, setShipId] = useState<number>(props.ship?.ship_id || 0);

  const [reportSearchCondition, setReportSearchCondition] = useState<
    ReportSearchCondition | undefined
  >();

  return (
    <OrganizationIdContext.Provider
      value={{
        ship_id: shipId,
        fuelType: convertFoFuelTypeToFuelType(
          props.ship?.fo_fuel_type || FoFuelType.Hsfo
        ),
      }}
    >
      <setShipIdContext.Provider value={setShipId}>
        <reportSerchConditionContext.Provider
          value={{ reportSearchCondition, setReportSearchCondition }}
        >
          <GlobalLoadProvider>{props.children}</GlobalLoadProvider>
        </reportSerchConditionContext.Provider>
      </setShipIdContext.Provider>
    </OrganizationIdContext.Provider>
  );
};

export const useSetShipId = () => useContext(setShipIdContext);

export const useSetOrganizationId = () => useContext(setOrganizationIdContext);

export const useContextIDs = () => {
  const context = useContext(OrganizationIdContext);

  if (context === undefined) {
    throw new Error("useCount must be used within a OrganizationIdProvider");
  }
  return context;
};

export const useContextReportSearchCondition = () => {
  const context = useContext(reportSerchConditionContext);

  if (context === undefined) {
    throw new Error(
      "useContext must be used within a noonReportSerchConditionContext"
    );
  }

  return context;
};

export const getMetaData = (user: User): METADATA => {
  try {
    if (!user[`app_metadata`]) throw Error("The value of auth0 is invalid.");
    return user[`app_metadata`];
  } catch (e) {
    console.error(e);
    return {
      noon_report: {
        permission: UserAuthCodes.Crew,
      },
    };
  }
};

export const authCheckCrew = (user: User) => {
  try {
    const metadata = getMetaData(user);
    if (!metadata || !metadata.noon_report.permission)
      throw Error("The value of auth0 is invalid. authCheckCrew");
    return metadata && metadata.noon_report.permission === UserAuthCodes.Crew;
  } catch (e) {
    console.error(e);
    alert("Authorization authentication error");
    return true;
  }
};

export const authCheckOwner = (user: User) => {
  try {
    const metadata = getMetaData(user);
    if (!metadata || !metadata.noon_report.permission)
      throw Error("The value of auth0 is invalid. authCheckOwner");
    return metadata.noon_report.permission === UserAuthCodes.Owner;
  } catch (e) {
    console.error(e);
    alert("Authorization authentication error");
    return false;
  }
};

export const pageData = {
  crewCreateReportComplete: {
    path(): string {
      return `/create/complete`;
    },
  },

  serverUpdateReport: {
    path(reportId: string): string {
      return `/report/serverUpdate/${reportId}`;
    },
  },
  serverUpdateReportConfirm: {
    path(reportId: string): string {
      return `/report/serverUpdate/confirm/${reportId}`;
    },
  },

  crewDeparture: {
    path(): string {
      return `/crew/departure`;
    },
  },
  crewDepartureUpdate: {
    path(reportId: string): string {
      return `/departure/update/${reportId}`;
    },
  },
  crewDepartureDetail: {
    path(reportId: string): string {
      return `/crew/departure/reportDetail/${reportId}`;
    },
  },

  serverUpdateDeparture: {
    path(reportId: string): string {
      return `/departure/serverUpdate/${reportId}`;
    },
  },
  serverUpdateDepartureConfirm: {
    path(reportId: string): string {
      return `/departure/serverUpdate/confirm/${reportId}`;
    },
  },
  serverUpdateDepartureComplete: {
    path(): string {
      return `/departure/serverUpdate/complete`;
    },
  },

  crewArrival: {
    path(): string {
      return `/crew/arrival`;
    },
  },
  crewArrivalUpdate: {
    path(reportId: string): string {
      return `/arrival/update/${reportId}`;
    },
  },

  crewArrivalDetail: {
    path(reportId: string): string {
      return `/crew/arrival/reportDetail/${reportId}`;
    },
  },

  serverUpdateArrival: {
    path(reportId: string): string {
      return `/arrival/serverUpdate/${reportId}`;
    },
  },

  serverUpdateArrivalConfirm: {
    path(reportId: string): string {
      return `/arrival/serverUpdate/confirm/${reportId}`;
    },
  },
  serverUpdateArrivalComplete: {
    path(): string {
      return `/arrival/serverUpdate/complete`;
    },
  },

  crewBunkering: {
    path(): string {
      return `/crew/bunkering`;
    },
  },
  crewBunkeringUpdate: {
    path(reportId: string): string {
      return `/bunkering/update/${reportId}`;
    },
  },

  crewBunkeringDetail: {
    path(reportId: string): string {
      return `/crew/bunkering/reportDetail/${reportId}`;
    },
  },

  serverUpdateBunkering: {
    path(reportId: string): string {
      return `/bunkering/serverUpdate/${reportId}`;
    },
  },

  serverUpdateBunkeringConfirm: {
    path(reportId: string): string {
      return `/bunkering/serverUpdate/confirm/${reportId}`;
    },
  },
  serverUpdateBunkeringComplete: {
    path(): string {
      return `/bunkering/serverUpdate/complete`;
    },
  },
  crewCargo: `/crew/cargo`,
  crewCargoCreate: `/crew/cargo/create`,
  crewCargoCreateComplete: `/crew/cargo/create/complete`,

  crewCargoUpdate: {
    path(reportId: string | number): string {
      return `/cargo/update/${reportId}`;
    },
  },

  crewCargoUpdateComplete: `/crew/cargo/update/complete`,

  crewCargoDetail: {
    path(reportId: string | number): string {
      return `/crew/cargo/reportDetail/${reportId}`;
    },
  },

  serverUpdateCargo: {
    path(reportId: string): string {
      return `/cargo/serverUpdate/${reportId}`;
    },
  },

  serverUpdateCargoConfirm: {
    path(reportId: string): string {
      return `/cargo/serverUpdate/confirm/${reportId}`;
    },
  },
  serverUpdateCargoComplete: {
    path(): string {
      return `/cargo/serverUpdate/complete`;
    },
  },

  ownerRoot: {
    path(): string {
      return `/owner`;
    },
  },

  ownerRportDetail: {
    path(reportId: string): string {
      return `/owner/report/detail/${reportId}`;
    },
  },

  ownerDeparture: {
    path(): string {
      return `/owner/departure`;
    },
  },
  ownerDepartureDetail: {
    path(reportId: string): string {
      return `/owner/departure/detail/${reportId}`;
    },
  },
  ownerArrival: {
    path(): string {
      return `/owner/arrival`;
    },
  },
  ownerArrivalDetail: {
    path(reportId: string): string {
      return `/owner/arrival/detail/${reportId}`;
    },
  },
  ownerBunkering: {
    path(): string {
      return `/owner/bunkering`;
    },
  },
  ownerBunkeringDetail: {
    path(reportId: string): string {
      return `/owner/bunkering/detail/${reportId}`;
    },
  },

  ownerCargo: `/owner/cargo`,

  ownerAnalysis: {
    path(): string {
      return `/owner/analysis`;
    },
  },

  ownerSetting: {
    path(): string {
      return `/owner/analysis/setting`;
    },
  },
};

function App() {
  const { user } = useAuth0();
  const ship = useShip();

  return (
    <>
      <OrganizationIdProvider user={user} ship={ship}>
        <RouteConfig />
      </OrganizationIdProvider>
    </>
  );
}

export default App;
