import { memo } from "react";
import { Chart } from "react-google-charts";
import { ReportGraph } from "../../../pages/Owner/Analysis/types";
import { BaselineTitleList } from "../../../pages/Owner/Analysis/const";
import { SpeedConsumptionFilterCondition } from "../../../pages/Owner/Analysis/queries";
import {
  getReportGraphData,
  ReportViewProps,
} from "../../../pages/Owner/Analysis/analysisData";
import { Box, Typography } from "@mui/material";
import {
  limeGreen,
  paleGray,
  plumPurple,
  hotPink,
  skyBlue,
  sunnyYellow,
  taupeBrown,
  vividGreen,
  weightedGray,
} from "../../../../utils/color";

const getLegendSymbol = (title: string) => {
  switch (title) {
    case BaselineTitleList.Ballast:
      return `■`;
    case BaselineTitleList.Laden:
      return `▲`;
    case BaselineTitleList.Unique:
      return `◆`;
  }
};

type LegendProps = {
  title: string;
  circleColor: string;
};
export const Legend = (props: LegendProps) => {
  return (
    <Box sx={{ display: "flex", textAlign: "right", alignContent: "center" }}>
      <Typography
        sx={{
          fontSize: 12,

          color: props.circleColor,
          pr: 0.5,
          pt: 0.25,
        }}
      >
        {getLegendSymbol(props.title)}
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          pr: 2,
          fontFamily: "inherit",
          letterSpacing: "inherit",
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

export type ViewProps = ReportViewProps & {
  formGraph1: SpeedConsumptionFilterCondition;
  formGraph2: SpeedConsumptionFilterCondition;
  formGraph3: SpeedConsumptionFilterCondition;
  formGraph4: SpeedConsumptionFilterCondition;
  shipNames: { ship1: string; ship2: string; ship3: string; ship4: string };
  isDownloadZip: boolean;
};

export const ReportView = memo(
  function ReportView(props: ViewProps) {
    const report = getReportGraphData(props);

    const options = {
      curveType: "function",
      colors: [sunnyYellow, limeGreen, taupeBrown, plumPurple],
      tooltip: { isHtml: true },
      legend: {
        position: "none",
        alignment: "end",
        lineWidth: 0,
        textStyle: {
          fontSize: 16,
        },
        maxLines: 2,
      },

      series: {
        4: {
          lineWidth: 0,
          color: vividGreen,
          pointSize: 10,
          pointShape: "square",
        },
        5: {
          lineWidth: 0,
          color: skyBlue,
          pointSize: 10,
          pointShape: "triangle",
        },
        6: {
          lineWidth: 0,
          color: hotPink,
          pointSize: 10,
          pointShape: "diamond",
        },
        7: { type: "line", lineWidth: 1.5, color: sunnyYellow, pointSize: 0 },
        8: { type: "line", lineWidth: 1.5, color: limeGreen, pointSize: 0 },
        9: { type: "line", lineWidth: 1.5, color: taupeBrown, pointSize: 0 },
        10: { type: "line", lineWidth: 1.5, color: plumPurple, pointSize: 0 },
        11: { type: "line", lineWidth: 1.5, color: vividGreen, pointSize: 0 },
        12: { type: "line", lineWidth: 1.5, color: skyBlue, pointSize: 0 },
        13: { type: "line", lineWidth: 1.5, color: hotPink, pointSize: 0 },
      },
      theme: {
        chartArea: {
          width: !props.isDownloadZip ? "77%" : "85%",
          height: !props.isDownloadZip ? "77%" : "85%",
        },
      },
      hAxis: {
        title: "Speed[knot]",
        minValue: 0,
        viewWindow: {
          min: 0,
        },
        viewWindowMode: "maximized",
        titleTextStyle: {
          color: weightedGray,
          fontName: "Public Sans",
          italic: false,
        },
      },
      vAxis: {
        title: "Fuel consumption per day[mt]",
        minValue: 0,
        viewWindow: {
          min: 0,
        },
        viewWindowMode: "maximized",
        titleTextStyle: {
          color: weightedGray,
          fontName: "Public Sans",
          italic: false,
        },
      },
      backgroundColor: !props.isDownloadZip ? paleGray : undefined,
    };

    return (
      <Box sx={{ mb: 2 }}>
        <Chart
          chartType="ScatterChart"
          width="1240px"
          height="700px"
          data={[...report]}
          options={options}
        />
      </Box>
    );
  },
  (prevProps, nextProps) => {
    const compareGraphDataArray = (
      array1: ReportGraph[],
      array2: ReportGraph[]
    ): boolean => {
      if (array1.length !== array2.length) {
        return false;
      }

      if (array1.length === 0 && array2.length === 0) {
        return true;
      }

      const randomIndex = Math.floor(Math.random() * array1.length);

      // (lengthが同じだった場合)ランダムで選択したindex番号のオブジェクトのidとN_FO_Consumption_per_dayが等しいかどうかを確認する
      return (
        array1[randomIndex].id === array2[randomIndex].id &&
        array1[randomIndex].N_FO_Consumption_per_day ===
          array2[randomIndex].N_FO_Consumption_per_day
      );
    };

    return (
      prevProps.isDownloadZip === nextProps.isDownloadZip &&
      compareGraphDataArray(prevProps.graph1, nextProps.graph1) &&
      compareGraphDataArray(prevProps.graph2, nextProps.graph2) &&
      compareGraphDataArray(prevProps.graph3, nextProps.graph3) &&
      compareGraphDataArray(prevProps.graph4, nextProps.graph4) &&
      prevProps.referenceDataList.length === nextProps.referenceDataList.length
    );
  }
);
