import { db } from "./db";

export async function populate() {
  await db.reportList.add({
    title: "Report",
  });

  await db.shipList.add({
    title: "Report",
  });
}
