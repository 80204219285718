import { Box, Button, Stack, SxProps, Theme } from "@mui/material";
import { ReportGraph } from "../pages/Owner/Analysis/types";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { lightGrayD3D3D3, white } from "../../utils/color";

type Props = {
  fontSize?: number;
  width?: string;
  isGraph: boolean;
  reportGraph: ReportGraph[];
  backgroundColor: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  ButtonName: string;
  sx?: SxProps<Theme>;
};

const ShowHideGraphButton = (props: Props) => {
  const onButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.onClick(e);
  };

  const isDisable = props.reportGraph.length === 0;

  const getBackgroundColor = () => {
    if (isDisable) {
      return lightGrayD3D3D3; // isDisableがtrueの場合は常に灰色
    }
    return props.isGraph ? props.backgroundColor : "transparent";
  };

  return (
    <Button
      sx={{
        fontSize: 14,
        mx: 0,
        color: !isDisable && props.isGraph ? white : props.backgroundColor,
        backgroundColor: `${getBackgroundColor()} !important`,
        border:
          !isDisable && !props.isGraph
            ? `2px solid ${props.backgroundColor}`
            : isDisable
            ? lightGrayD3D3D3
            : "none",
        textTransform: "none",
        fontFamily: "Public Sans",
        width: 150,
        ...props.sx,
      }}
      variant="contained"
      disabled={isDisable}
      onClick={(e) => onButtonClick(e)}
    >
      <Stack direction={"row"} spacing={1}>
        <Box>{props.ButtonName}</Box>
        {props.isGraph ? (
          <VisibilityOutlinedIcon />
        ) : (
          <VisibilityOffOutlinedIcon />
        )}
      </Stack>
    </Button>
  );
};

export default ShowHideGraphButton;
