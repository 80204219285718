import React from "react";
import { useNavigate, useParams } from "react-router";
import { db } from "../../../../../dexie/db";
import Default from "../../../../layouts/DefaultCrew";
import Template from "../../../../templates/Crew/Cargo/ReportDetail";
import { useLiveQuery } from "dexie-react-hooks";
import { pageData } from "../../../../../App";
import { useCargoForm } from "../cargo.module";

const ReportDetail = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const navigate = useNavigate();

  const onBack = () => {
    navigate(pageData.crewCargo);
  };

  const deleteReport = () => {
    db.cargo.delete(report_id).catch((e) => console.error(e));
    navigate(pageData.crewCargo);
  };

  const cargo = useLiveQuery(() => db.cargo.get(report_id));

  const form = useCargoForm([cargo?.draftForm]);

  return (
    <Default title="" selectedTitle={"Cargo"}>
      <Template
        form={form.data}
        reportId={report_id}
        deleteReport={deleteReport}
        onBack={onBack}
        isSend={cargo?.isSend !== undefined ? cargo?.isSend : true}
      />
    </Default>
  );
};

export default ReportDetail;
