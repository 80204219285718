import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const ANALYSIS_PDF_ID = "print-analysis-pdf";

export const createAnalysisPDF = async () => {
  const target = document.getElementById(ANALYSIS_PDF_ID);

  if (target === null) return;
  const pdf = new jsPDF();
  await html2canvas(target, { scale: 2 }).then((canvas) => {
    const imgData = canvas.toDataURL("image/svg", 1.0);
    const imgWidth = 190;
    const imgHeight = imgWidth / 1;
    const pageWidth = pdf.internal.pageSize.getWidth();
    const xPos = (pageWidth - imgWidth) / 2; // 中央揃え
    pdf.addImage(imgData, "SVG", xPos, 5, imgWidth, imgHeight);
  });

  return pdf;
};
