import { EUorUk, LorU } from "../../../../gen/graphql/types";

export type CargoForm = {
  C_TimeZone_PorN: string;
  C_TimeZone_HH?: string;
  C_TimeZone_MM?: string;
  C_DateTime?: Date;
  C_Lat_Direction: string;
  C_Lat_Deg: string | null;
  C_Lat_Min: string | null;
  C_Long_Direction: string;
  C_Long_Deg: string | null;
  C_Long_Min: string | null;
  C_Voyage_No: string;
  C_Cargo_Port: string;
  C_UNLOC: string;
  C_EU_UK_port: EUorUk;
  C_Port_L_U: LorU;
  C_Loading_Mass?: number;
  C_Loading_TEU_Full?: number;
  C_Loading_TEU_Empty?: number;
  C_Loading_Unit?: number;
  C_Loading_Lane_metres?: number;
  C_Loading_Number_of_Passengers?: number;
  C_Loading_Volume?: number;
  C_Unloading_Mass?: number;
  C_Unloading_TEU_Full?: number;
  C_Unloading_TEU_Empty?: number;
  C_Unloading_Unit?: number;
  C_Unloading_Lane_metres?: number;
  C_Unloading_Number_of_Passengers?: number;
  C_Unloading_Volume?: number;
  C_Total_Mass?: number;
  C_Total_TEU_Full?: number;
  C_Total_TEU_Empty?: number;
  C_Total_Unit?: number;
  C_Total_Lane_metres?: number;
  C_Total_Number_of_Passengers?: number;
  C_Total_Volume?: number;
  C_Displacement?: number;
  C_Water_density?: number;
  C_Total_ROB?: number;
  C_Filename?: string;
};

export const initializeCargo: CargoForm = {
  C_TimeZone_PorN: "+",
  C_TimeZone_HH: "00",
  C_TimeZone_MM: "00",
  C_Cargo_Port: "",
  C_DateTime: undefined,
  C_Displacement: undefined,
  C_EU_UK_port: EUorUk.Eu,
  C_Lat_Direction: "N",
  C_Lat_Deg: null,
  C_Lat_Min: null,
  C_Long_Direction: "E",
  C_Long_Deg: null,
  C_Long_Min: null,
  C_Loading_Lane_metres: undefined,
  C_Loading_Mass: undefined,
  C_Loading_Number_of_Passengers: undefined,
  C_Loading_Unit: undefined,
  C_Loading_Volume: undefined,
  C_Port_L_U: LorU.N,
  C_Total_ROB: undefined,
  C_UNLOC: "",
  C_Unloading_Lane_metres: undefined,
  C_Unloading_Mass: undefined,
  C_Unloading_Number_of_Passengers: undefined,
  C_Unloading_Unit: undefined,
  C_Unloading_Volume: undefined,
  C_Voyage_No: "",
  C_Water_density: undefined,
  C_Filename: undefined,
};
