import React from "react";
import { useNavigate, useParams } from "react-router";
import { db } from "../../../../dexie/db";
import Default from "../../../layouts/DefaultCrew";
import Template from "../../../templates/Crew/Bunkering/ReportDetail";
import { LocationState } from "./Create/types";
import { useLiveQuery } from "dexie-react-hooks";
import { pageData } from "../../../../App";
import { useGetLastFrontReport } from "../../../../hooks/dexie/dexie";
import { mapToBunkeringFormData, useBunkeringForm } from "./bunkering.module";

const BunkeringDetail = (props: LocationState): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const navigate = useNavigate();

  const onBack = () => {
    navigate(pageData.crewBunkering.path());
  };

  const deleteReport = () => {
    db.bunkering.delete(report_id).catch((e) => console.error(e));
    navigate(pageData.crewBunkering.path());
  };

  const bunkering = useLiveQuery(() => db.bunkering.get(report_id));

  const form = useBunkeringForm([
    props.form,
    mapToBunkeringFormData(bunkering?.data),
  ]);

  const previousReport = useGetLastFrontReport();

  return (
    <Default title="" selectedTitle={"Bunkering"}>
      <Template
        form={form.data}
        reportId={report_id}
        deleteReport={deleteReport}
        onBack={onBack}
        previousReport={previousReport}
        isSend={bunkering?.isSend !== undefined ? bunkering?.isSend : true}
      />
    </Default>
  );
};

export default BunkeringDetail;
