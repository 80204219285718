export type SpeedForm = {
  imo_no: string;
  speed_OG_1: number | null;
  speed_OG_2: number | null;
  speed_OG_3: number | null;
  speed_OG_4: number | null;
  speed_OG_5: number | null;
  speed_OG_6: number | null;
  speed_OG_7: number | null;
  speed_OG_8: number | null;
  speed_OG_9: number | null;
  speed_OG_10: number | null;
  empty_1: number | null;
  empty_2: number | null;
  empty_3: number | null;
  empty_4: number | null;
  empty_5: number | null;
  empty_6: number | null;
  empty_7: number | null;
  empty_8: number | null;
  empty_9: number | null;
  empty_10: number | null;
  laden_1: number | null;
  laden_2: number | null;
  laden_3: number | null;
  laden_4: number | null;
  laden_5: number | null;
  laden_6: number | null;
  laden_7: number | null;
  laden_8: number | null;
  laden_9: number | null;
  laden_10: number | null;
  unique_1: number | null;
  unique_2: number | null;
  unique_3: number | null;
  unique_4: number | null;
  unique_5: number | null;
  unique_6: number | null;
  unique_7: number | null;
  unique_8: number | null;
  unique_9: number | null;
  unique_10: number | null;
};

export type Result = {
  succeed: boolean;
  code: string | null;
};

export type LocationState = {
  form?: SpeedForm;
  result?: Result;
};

export type Props = {
  form: SpeedForm;
};

export type FocForm = {
  imo_no: string;
  foc_1?: number;
  foc_2?: number;
  foc_3?: number;
  foc_4?: number;
  foc_5?: number;
  foc_6?: number;
  foc_7?: number;
  foc_8?: number;
  foc_9?: number;
  foc_10?: number;
  empty_1?: number;
  empty_2?: number;
  empty_3?: number;
  empty_4?: number;
  empty_5?: number;
  empty_6?: number;
  empty_7?: number;
  empty_8?: number;
  empty_9?: number;
  empty_10?: number;
  laden_1?: number;
  laden_2?: number;
  laden_3?: number;
  laden_4?: number;
  laden_5?: number;
  laden_6?: number;
  laden_7?: number;
  laden_8?: number;
  laden_9?: number;
  laden_10?: number;
  unique_1?: number;
  unique_2?: number;
  unique_3?: number;
  unique_4?: number;
  unique_5?: number;
  unique_6?: number;
  unique_7?: number;
  unique_8?: number;
  unique_9?: number;
  unique_10?: number;
};

export const initialSpeedFormValue: SpeedForm = {
  imo_no: "",
  speed_OG_1: null,
  speed_OG_2: null,
  speed_OG_3: null,
  speed_OG_4: null,
  speed_OG_5: null,
  speed_OG_6: null,
  speed_OG_7: null,
  speed_OG_8: null,
  speed_OG_9: null,
  speed_OG_10: null,
  empty_1: null,
  empty_2: null,
  empty_3: null,
  empty_4: null,
  empty_5: null,
  empty_6: null,
  empty_7: null,
  empty_8: null,
  empty_9: null,
  empty_10: null,
  laden_1: null,
  laden_2: null,
  laden_3: null,
  laden_4: null,
  laden_5: null,
  laden_6: null,
  laden_7: null,
  laden_8: null,
  laden_9: null,
  laden_10: null,
  unique_1: null,
  unique_2: null,
  unique_3: null,
  unique_4: null,
  unique_5: null,
  unique_6: null,
  unique_7: null,
  unique_8: null,
  unique_9: null,
  unique_10: null,
};

export const initialFocFormValue: FocForm = {
  imo_no: "",
  foc_1: undefined,
  foc_2: undefined,
  foc_3: undefined,
  foc_4: undefined,
  foc_5: undefined,
  foc_6: undefined,
  foc_7: undefined,
  foc_8: undefined,
  foc_9: undefined,
  foc_10: undefined,
  empty_1: undefined,
  empty_2: undefined,
  empty_3: undefined,
  empty_4: undefined,
  empty_5: undefined,
  empty_6: undefined,
  empty_7: undefined,
  empty_8: undefined,
  empty_9: undefined,
  empty_10: undefined,
  laden_1: undefined,
  laden_2: undefined,
  laden_3: undefined,
  laden_4: undefined,
  laden_5: undefined,
  laden_6: undefined,
  laden_7: undefined,
  laden_8: undefined,
  laden_9: undefined,
  laden_10: undefined,
  unique_1: undefined,
  unique_2: undefined,
  unique_3: undefined,
  unique_4: undefined,
  unique_5: undefined,
  unique_6: undefined,
  unique_7: undefined,
  unique_8: undefined,
  unique_9: undefined,
  unique_10: undefined,
};
