import { ApolloClient } from "@apollo/client";
import {
  ReportListDocument,
  ReportListInput,
} from "../../../../gen/graphql/types";

export const getNoonReportCsvDownloadData = async (
  input: ReportListInput,
  client: ApolloClient<object>
) => {
  const { data } = await client.query({
    query: ReportListDocument,
    variables: { input },
    fetchPolicy: "network-only",
  });

  return data;
};
