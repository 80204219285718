import { DepartureDexie } from "../../../../dexie/Departure";
import { Form, initializeReport } from "./Create/types";

export const setDefaultLastReports = (
  form: Form,
  update: (value: React.SetStateAction<Form>) => void,
  lastReportForm?: Form,
  lastDeparture?: DepartureDexie
) => {
  const report = lastReportForm ? lastReportForm : initializeReport;

  update({
    ...form,
    N_ROB_FO: report.N_ROB_FO,
    N_ROB_MGO: report.N_ROB_MGO,
    N_ROB_MECO: report.N_ROB_MECO,
    N_ROB_MESO: report.N_ROB_MESO,
    N_ROB_GESO: report.N_ROB_GESO,
    N_ROB_FW: report.N_ROB_FW,
    N_Additional_ROB: report.N_Additional_ROB,
    N_Next_port: lastDeparture?.draftForm?.D_Next_Port_Name
      ? lastDeparture?.draftForm?.D_Next_Port_Name
      : lastDeparture?.data?.D_Next_Port_Name,
  });
};
