import { useLazyInitializeState } from "../../../../utils/lazy-initialize-state";
import { Form, initializeReport } from "./Create/types";
import { emptyToDoubleQuotation } from "../../../../utils/formats";
import {
  LorB,
  LorU,
  ReportInput,
  SteamMode,
  AdditionalRobInput,
} from "../../../../gen/graphql/types";

import {
  getLatLong,
  getLatLongValues,
  getTimeZone,
  getTimeZoneValues,
} from "../../../../utils/form-util";
import { filterArrUndefinedNull } from "../../../../utils/type-checks";
import { setDefaultNullGgraphQLInputToForm } from "../../../../utils/graphql-mappimg";

import { hasLatLonValidateError } from "../../../../infrastructure/common/validate";
import { ReportDexie } from "../../../../dexie/Report";
import useErrorMessageList from "../../../../hooks/errorMessage.hook";
import {
  hasSpeedOverTheGroundError,
  hasDuplicateNoonReportError,
  hasDistanceTravelledOverGroundError,
  hasMeRpmTotalError,
} from "../../../../infrastructure/NoonReport/validate";
import {
  NoonReporSpeedOverTheGroundError,
  NoonReportDistanceOverGroundError,
  NoonReportDuplicateError,
} from "../../../../utils/const";

type Maybe<T> = T | null | undefined;

export const useNoonReportForm = (initializes: Maybe<Form>[]) => {
  const [isLoading, data, update] = useLazyInitializeState<Form>(
    initializeReport,
    initializes
  );

  return {
    isLoading,
    data,
    update,
  };
};

export const useNoonReportFormValidation = (
  form: Form,
  reportList?: ReportDexie[],
  previousReport?: ReportDexie
) => {
  const { errorMessageList, manageErrorMessage } = useErrorMessageList();
  const {
    N_Vessel_name,
    N_Voy,
    N_LorB,
    N_DateTime,
    N_TimeZone_PorN,
    N_TimeZone_HH,
    N_TimeZone_MM,
    N_Lat_Direction,
    N_Lat_Deg,
    N_Lat_Min,
    N_Long_Direction,
    N_Long_Deg,
    N_Long_Min,
    N_Steam_mode,
    N_Hrs_propelling,
    N_Dist_OG,
    N_Speed_OG,
    N_Speed_OW,
    N_ME_rpm_ave,
    N_ME_Revolution,
    // N_Slip,
    N_FO_Consumption_per_day_ME,
    N_FO_Consumption_per_day_GE,
    N_FO_Consumption_per_day_BInc,
    N_MGO_Consumption_per_day_ME,
    N_MGO_Consumption_per_day_GE,
    N_MGO_Consumption_per_day_Boiler,
    N_LO_Consumption_per_day_MECO,
    N_LO_Consumption_per_day_MESO,
    N_LO_Consumption_per_day_GESO,

    N_ME_Fuel_index,
    N_ME_RPM,
    N_ME_horsepower,
    N_ME_power,
    N_ME_power_measurement_method,
    N_Running_hours_SOx_scrubber,
    N_Running_hours_ME_EGR,
    N_Running_hours_GE_SCR,
    N_ROB_FO,
    N_ROB_MGO,
    N_ROB_MECO,
    N_ROB_MESO,
    N_ROB_GESO,
    N_ROB_FW,
    // N_Additional_ROB,
    // N_FO_CO2_emission_per_unit,
    // N_MGO_CO2_emission_per_unit,
    // N_FO_CO2_emission,
    // N_MGO_CO2_emission,
    // N_Remark,
  } = form;

  const commonFields = [
    N_Vessel_name,
    N_Voy,
    N_LorB,
    N_DateTime,
    N_TimeZone_PorN,
    N_TimeZone_HH,
    N_TimeZone_MM,
    N_Lat_Direction,
    N_Lat_Deg,
    N_Lat_Min,
    N_Long_Direction,
    N_Long_Deg,
    N_Long_Min,
    N_Steam_mode,
    N_FO_Consumption_per_day_ME,
    N_FO_Consumption_per_day_GE,
    N_FO_Consumption_per_day_BInc,
    N_MGO_Consumption_per_day_ME,
    N_MGO_Consumption_per_day_GE,
    N_MGO_Consumption_per_day_Boiler,
    N_LO_Consumption_per_day_MECO,
    N_LO_Consumption_per_day_MESO,
    N_LO_Consumption_per_day_GESO,
    N_ME_horsepower,
    N_ME_power,
    N_ME_power_measurement_method,
    N_Running_hours_GE_SCR,
    N_ROB_FO,
    N_ROB_MGO,
    N_ROB_MECO,
    N_ROB_MESO,
    N_ROB_GESO,
    N_ROB_FW,
  ];

  const formFieldsBySteamMode =
    N_Steam_mode !== SteamMode.Stop
      ? [
          N_Hrs_propelling,
          N_Dist_OG,
          N_Speed_OG,
          N_Speed_OW,
          N_ME_rpm_ave,
          N_ME_Revolution,
          N_ME_Fuel_index,
          N_ME_RPM,
          N_Running_hours_SOx_scrubber,
          N_Running_hours_ME_EGR,
        ]
      : [];

  const allFields = [...commonFields, ...formFieldsBySteamMode];

  const {
    noonReportValidationError,
    hasDuplicateNoonReportErr,
    hasSpeedOverTheGroundErr,
    hasDistanceTravelledOverGroundErr,
  } = validateForm(form, reportList, previousReport);

  const hasFormErrors = (
    allFields: (string | number | Date | null | undefined)[]
  ): boolean => {
    return (
      allFields.some(
        (field) => field === null || field === undefined || field === ""
      ) || noonReportValidationError
    );
  };
  const hasError = hasFormErrors(allFields);

  const manageNoonReportErrorMessage = () => {
    manageErrorMessage(
      hasDuplicateNoonReportErr,
      NoonReportDuplicateError,
      form
    );
    manageErrorMessage(
      hasDistanceTravelledOverGroundErr,
      NoonReportDistanceOverGroundError,
      form
    );
    manageErrorMessage(
      hasSpeedOverTheGroundErr,
      NoonReporSpeedOverTheGroundError,
      form
    );
  };

  const hasErrorScrollUp = () => {
    const upperForm = [
      N_Vessel_name,
      N_Voy,
      N_LorB,
      N_DateTime,
      N_TimeZone_PorN,
      N_TimeZone_HH,
      N_TimeZone_MM,
      N_Lat_Direction,
      N_Lat_Deg,
      N_Lat_Min,
      N_Long_Direction,
      N_Long_Deg,
      N_Long_Min,
      N_Steam_mode,
      N_Hrs_propelling,
      N_Dist_OG,
      N_Speed_OG,
      N_Speed_OW,
      N_ME_rpm_ave,
      N_ME_Revolution,
      N_FO_Consumption_per_day_ME,
      N_FO_Consumption_per_day_GE,
      N_FO_Consumption_per_day_BInc,
      N_MGO_Consumption_per_day_ME,
      N_MGO_Consumption_per_day_GE,
      N_MGO_Consumption_per_day_Boiler,
      N_LO_Consumption_per_day_MECO,
      N_LO_Consumption_per_day_MESO,
      N_LO_Consumption_per_day_GESO,
    ];

    const lowerForm = [
      N_ME_Fuel_index,
      N_ME_RPM,
      N_ME_horsepower,
      N_ME_power,
      N_ME_power_measurement_method,
      N_Running_hours_SOx_scrubber,
      N_Running_hours_ME_EGR,
      N_Running_hours_GE_SCR,
      N_ROB_FO,
      N_ROB_MGO,
      N_ROB_MECO,
      N_ROB_MESO,
      N_ROB_GESO,
      N_ROB_FW,
    ];

    if (
      upperForm.some((v) => v === null || v === undefined || v === "") ||
      noonReportValidationError
    ) {
      window.scrollTo({
        top: 50,
        behavior: "smooth",
      });
    } else if (lowerForm.some((v) => v === null || v === undefined)) {
      window.scrollTo({
        top: 1200,
        behavior: "smooth",
      });
    }
  };

  return {
    hasError,
    hasErrorScrollUp,
    manageNoonReportErrorMessage,
    errorMessageList,
  };
};

const validateForm = (
  form: Form,
  reportList?: ReportDexie[],
  previousReport?: ReportDexie
) => {
  const hasDuplicateNoonReportErr = hasDuplicateNoonReportError(
    reportList,
    form
  );
  const hasSpeedOverTheGroundErr = hasSpeedOverTheGroundError(form);
  const hasDistanceTravelledOverGroundErr =
    hasDistanceTravelledOverGroundError(form);

  const hasLatLonValidateErr = hasLatLonValidateError(
    form.N_Lat_Deg,
    form.N_Lat_Min,
    form.N_Lat_Direction,
    form.N_Long_Deg,
    form.N_Long_Min,
    form.N_Long_Direction
  );

  const hasMeRpmTotalErr = hasMeRpmTotalError(form, previousReport);

  return {
    noonReportValidationError:
      hasLatLonValidateErr ||
      hasDistanceTravelledOverGroundErr ||
      hasSpeedOverTheGroundErr ||
      hasDuplicateNoonReportErr ||
      hasMeRpmTotalErr,
    hasDuplicateNoonReportErr,
    hasSpeedOverTheGroundErr,
    hasDistanceTravelledOverGroundErr,
  };
};

export const mapToFormData = (report: ReportInput | undefined): Form | null => {
  if (report === undefined) {
    return null;
  }

  const { TimeZone_PorN, TimeZone_HH, TimeZone_MM } = getTimeZoneValues(
    report.N_TimeZone
  );

  const {
    Lat_Deg,
    Lat_Min,
    Lat_Direction,
    Long_Deg,
    Long_Min,
    Long_Direction,
  } = getLatLongValues(report.N_LatLong);

  return {
    N_Vessel_name: report.N_Vessel_name || "",
    N_Voy: report.N_Voy || "",
    N_LorB: report.N_LorB || LorB.Ballast,
    N_Noon_Port: report.N_Noon_Port || null,
    N_UNLOC: report.N_UNLOC || null,
    N_EU_UK_port: report.N_EU_UK_port || null,
    N_Port_L_U: report.N_Port_L_U || LorU.N,
    N_DateTime: report.N_DateTime,
    N_TimeZone_PorN: TimeZone_PorN,
    N_TimeZone_HH: TimeZone_HH,
    N_TimeZone_MM: TimeZone_MM,
    N_Lat_Direction: Lat_Direction,
    N_Lat_Deg: Lat_Deg,
    N_Lat_Min: Lat_Min,
    N_Long_Direction: Long_Direction,
    N_Long_Deg: Long_Deg,
    N_Long_Min: Long_Min,
    N_Steam_mode: report.N_Steam_mode || SteamMode.Full,
    N_Hrs_propelling: setDefaultNullGgraphQLInputToForm(
      report.N_Hrs_propelling
    ),
    N_Dist_OG: setDefaultNullGgraphQLInputToForm(report.N_Dist_OG),
    N_Speed_OG: setDefaultNullGgraphQLInputToForm(report.N_Speed_OG),
    N_Speed_OW: setDefaultNullGgraphQLInputToForm(report.N_Speed_OW),
    N_ME_rpm_ave: setDefaultNullGgraphQLInputToForm(report.N_ME_rpm_ave),
    N_ME_rpm_total: setDefaultNullGgraphQLInputToForm(report.N_ME_rpm_total),
    N_ME_Revolution: setDefaultNullGgraphQLInputToForm(report.N_ME_Revolution),
    N_Slip: setDefaultNullGgraphQLInputToForm(report.N_Slip),

    N_FO_Consumption_per_day_ME: report.N_FO_Consumption_per_day_ME,
    N_FO_Consumption_per_day_GE: report.N_FO_Consumption_per_day_GE,
    N_FO_Consumption_per_day_BInc: report.N_FO_Consumption_per_day_BInc,

    N_FO_Additional_FuelType_1: report.N_FO_Additional_FuelType_1,
    N_FO_Additional_Usage_1: report.N_FO_Additional_Usage_1,
    N_FO_Additional_volume_1: report.N_FO_Additional_volume_1,
    N_FO_Additional_FuelType_2: report.N_FO_Additional_FuelType_2,
    N_FO_Additional_Usage_2: report.N_FO_Additional_Usage_2,
    N_FO_Additional_volume_2: report.N_FO_Additional_volume_2,
    N_FO_Additional_FuelType_3: report.N_FO_Additional_FuelType_3,
    N_FO_Additional_Usage_3: report.N_FO_Additional_Usage_3,
    N_FO_Additional_volume_3: report.N_FO_Additional_volume_3,
    N_FO_Additional_FuelType_4: report.N_FO_Additional_FuelType_4,
    N_FO_Additional_Usage_4: report.N_FO_Additional_Usage_4,
    N_FO_Additional_volume_4: report.N_FO_Additional_volume_4,
    N_FO_Additional_FuelType_5: report.N_FO_Additional_FuelType_5,
    N_FO_Additional_Usage_5: report.N_FO_Additional_Usage_5,
    N_FO_Additional_volume_5: report.N_FO_Additional_volume_5,

    N_MGO_Consumption_per_day_ME: report.N_MGO_Consumption_per_day_ME,
    N_MGO_Consumption_per_day_GE: report.N_MGO_Consumption_per_day_GE,
    N_MGO_Consumption_per_day_Boiler: report.N_MGO_Consumption_per_day_Boiler,

    N_MGO_Additional_FuelType_1: report.N_MGO_Additional_FuelType_1,
    N_MGO_Additional_Usage_1: report.N_MGO_Additional_Usage_1,
    N_MGO_Additional_volume_1: report.N_MGO_Additional_volume_1,
    N_MGO_Additional_FuelType_2: report.N_MGO_Additional_FuelType_2,
    N_MGO_Additional_Usage_2: report.N_MGO_Additional_Usage_2,
    N_MGO_Additional_volume_2: report.N_MGO_Additional_volume_2,
    N_MGO_Additional_FuelType_3: report.N_MGO_Additional_FuelType_3,
    N_MGO_Additional_Usage_3: report.N_MGO_Additional_Usage_3,
    N_MGO_Additional_volume_3: report.N_MGO_Additional_volume_3,
    N_MGO_Additional_FuelType_4: report.N_MGO_Additional_FuelType_4,
    N_MGO_Additional_Usage_4: report.N_MGO_Additional_Usage_4,
    N_MGO_Additional_volume_4: report.N_MGO_Additional_volume_4,
    N_MGO_Additional_FuelType_5: report.N_MGO_Additional_FuelType_5,
    N_MGO_Additional_Usage_5: report.N_MGO_Additional_Usage_5,
    N_MGO_Additional_volume_5: report.N_MGO_Additional_volume_5,

    N_LO_Consumption_per_day_MECO: report.N_LO_Consumption_per_day_MECO,
    N_LO_Consumption_per_day_MESO: report.N_LO_Consumption_per_day_MESO,
    N_LO_Consumption_per_day_GESO: report.N_LO_Consumption_per_day_GESO,

    N_Other_Additional_FuelType_1: report.N_Other_Additional_FuelType_1,
    N_Other_Additional_Usage_1: report.N_Other_Additional_Usage_1,
    N_Other_Additional_volume_1: report.N_Other_Additional_volume_1,
    N_Other_Additional_FuelType_2: report.N_Other_Additional_FuelType_2,
    N_Other_Additional_Usage_2: report.N_Other_Additional_Usage_2,
    N_Other_Additional_volume_2: report.N_Other_Additional_volume_2,
    N_Other_Additional_FuelType_3: report.N_Other_Additional_FuelType_3,
    N_Other_Additional_Usage_3: report.N_Other_Additional_Usage_3,
    N_Other_Additional_volume_3: report.N_Other_Additional_volume_3,
    N_Other_Additional_FuelType_4: report.N_Other_Additional_FuelType_4,
    N_Other_Additional_Usage_4: report.N_Other_Additional_Usage_4,
    N_Other_Additional_volume_4: report.N_Other_Additional_volume_4,
    N_Other_Additional_FuelType_5: report.N_Other_Additional_FuelType_5,
    N_Other_Additional_Usage_5: report.N_Other_Additional_Usage_5,
    N_Other_Additional_volume_5: report.N_Other_Additional_volume_5,

    N_Weather: report.N_Weather,
    N_Wind_direction: report.N_Wind_direction,
    N_BF_scale: report.N_BF_scale,
    N_Swell: report.N_Swell,
    N_Noon_Course: report.N_Noon_Course,
    N_Dist_to_go: report.N_Dist_to_go,
    N_Next_port: report.N_Next_port,
    N_ETA: report.N_ETA,
    N_ETB: report.N_ETB,
    N_ETD: report.N_ETD,
    N_ME_Fuel_index: setDefaultNullGgraphQLInputToForm(report.N_ME_Fuel_index),
    N_ME_RPM: setDefaultNullGgraphQLInputToForm(report.N_ME_RPM),
    N_ME_horsepower: setDefaultNullGgraphQLInputToForm(report.N_ME_horsepower),
    N_ME_power: setDefaultNullGgraphQLInputToForm(report.N_ME_power),
    N_ME_power_measurement_method: report.N_ME_power_measurement_method || null,
    N_Running_hours_SOx_scrubber: setDefaultNullGgraphQLInputToForm(
      report.N_Running_hours_SOx_scrubber
    ),
    N_Running_hours_ME_EGR: setDefaultNullGgraphQLInputToForm(
      report.N_Running_hours_ME_EGR
    ),
    N_Running_hours_GE_SCR: report.N_Running_hours_GE_SCR,
    N_ROB_FO: report.N_ROB_FO,
    N_ROB_MGO: report.N_ROB_MGO,
    N_ROB_MECO: report.N_ROB_MECO,
    N_ROB_MESO: report.N_ROB_MESO,
    N_ROB_GESO: report.N_ROB_GESO,
    N_ROB_FW: report.N_ROB_FW,
    N_Additional_ROB: filterArrUndefinedNull(report.N_Additional_ROB),
    N_ROB_Filename: report.N_ROB_Filename || undefined,
    N_FO_CO2_emission_per_unit: setDefaultNullGgraphQLInputToForm(
      report.N_FO_CO2_emission_per_unit
    ),
    N_MGO_CO2_emission_per_unit: setDefaultNullGgraphQLInputToForm(
      report.N_MGO_CO2_emission_per_unit
    ),
    N_FO_CO2_emission: setDefaultNullGgraphQLInputToForm(
      report.N_FO_CO2_emission
    ),
    N_MGO_CO2_emission: setDefaultNullGgraphQLInputToForm(
      report.N_MGO_CO2_emission
    ),
    N_Remark: setDefaultNullGgraphQLInputToForm(report.N_Remark),
    N_Spare_1: emptyToDoubleQuotation(report.N_Spare_1),
    N_Spare_1_unit_name: emptyToDoubleQuotation(report.N_Spare_1_unit_name),
    N_Spare_2: emptyToDoubleQuotation(report.N_Spare_2),
    N_Spare_2_unit_name: emptyToDoubleQuotation(report.N_Spare_2_unit_name),
    N_Spare_3: emptyToDoubleQuotation(report.N_Spare_3),
    N_Spare_3_unit_name: emptyToDoubleQuotation(report.N_Spare_3_unit_name),
    N_Spare_4: emptyToDoubleQuotation(report.N_Spare_4),
    N_Spare_4_unit_name: emptyToDoubleQuotation(report.N_Spare_4_unit_name),
    N_Spare_5: emptyToDoubleQuotation(report.N_Spare_5),
    N_Spare_5_unit_name: emptyToDoubleQuotation(report.N_Spare_5_unit_name),
    N_Spare_6: emptyToDoubleQuotation(report.N_Spare_6),
    N_Spare_6_unit_name: emptyToDoubleQuotation(report.N_Spare_6_unit_name),
    N_Spare_7: emptyToDoubleQuotation(report.N_Spare_7),
    N_Spare_7_unit_name: emptyToDoubleQuotation(report.N_Spare_7_unit_name),
    N_Spare_8: emptyToDoubleQuotation(report.N_Spare_8),
    N_Spare_8_unit_name: emptyToDoubleQuotation(report.N_Spare_8_unit_name),
    N_Spare_9: emptyToDoubleQuotation(report.N_Spare_9),
    N_Spare_9_unit_name: emptyToDoubleQuotation(report.N_Spare_9_unit_name),
    N_Spare_10: emptyToDoubleQuotation(report.N_Spare_10),
    N_Spare_10_unit_name: emptyToDoubleQuotation(report.N_Spare_10_unit_name),
    N_Spare_11: emptyToDoubleQuotation(report.N_Spare_11),
    N_Spare_11_unit_name: emptyToDoubleQuotation(report.N_Spare_11_unit_name),
    N_Spare_12: emptyToDoubleQuotation(report.N_Spare_12),
    N_Spare_12_unit_name: emptyToDoubleQuotation(report.N_Spare_12_unit_name),
    N_Spare_13: emptyToDoubleQuotation(report.N_Spare_13),
    N_Spare_13_unit_name: emptyToDoubleQuotation(report.N_Spare_13_unit_name),
    N_Spare_14: emptyToDoubleQuotation(report.N_Spare_14),
    N_Spare_14_unit_name: emptyToDoubleQuotation(report.N_Spare_14_unit_name),
    N_Spare_15: emptyToDoubleQuotation(report.N_Spare_15),
    N_Spare_15_unit_name: emptyToDoubleQuotation(report.N_Spare_15_unit_name),
    N_Spare_16: emptyToDoubleQuotation(report.N_Spare_16),
    N_Spare_16_unit_name: emptyToDoubleQuotation(report.N_Spare_16_unit_name),
    N_Spare_17: emptyToDoubleQuotation(report.N_Spare_17),
    N_Spare_17_unit_name: emptyToDoubleQuotation(report.N_Spare_17_unit_name),
    N_Spare_18: emptyToDoubleQuotation(report.N_Spare_18),
    N_Spare_18_unit_name: emptyToDoubleQuotation(report.N_Spare_18_unit_name),
    N_Spare_19: emptyToDoubleQuotation(report.N_Spare_19),
    N_Spare_19_unit_name: emptyToDoubleQuotation(report.N_Spare_19_unit_name),
    N_Spare_20: emptyToDoubleQuotation(report.N_Spare_20),
    N_Spare_20_unit_name: emptyToDoubleQuotation(report.N_Spare_20_unit_name),
  };
};

export const mapToCreateReportMutationVariables = (
  form: Form,
  ship_id: number
): ReportInput => {
  const {
    N_Vessel_name,
    N_Voy,
    N_LorB,
    N_Noon_Port,
    N_UNLOC,
    N_Port_L_U,
    N_EU_UK_port,
    N_DateTime,
    N_TimeZone_PorN,
    N_TimeZone_HH,
    N_TimeZone_MM,
    N_Lat_Direction,
    N_Lat_Deg,
    N_Lat_Min,
    N_Long_Direction,
    N_Long_Deg,
    N_Long_Min,
    N_Steam_mode,
    N_Hrs_propelling,
    N_Dist_OG,
    N_Speed_OG,
    N_Speed_OW,
    N_ME_rpm_ave,
    N_ME_rpm_total,
    N_ME_Revolution,
    N_Slip,

    N_FO_Consumption_per_day_ME,
    N_FO_Consumption_per_day_GE,
    N_FO_Consumption_per_day_BInc,

    N_FO_Additional_FuelType_1,
    N_FO_Additional_Usage_1,
    N_FO_Additional_volume_1,
    N_FO_Additional_FuelType_2,
    N_FO_Additional_Usage_2,
    N_FO_Additional_volume_2,
    N_FO_Additional_FuelType_3,
    N_FO_Additional_Usage_3,
    N_FO_Additional_volume_3,
    N_FO_Additional_FuelType_4,
    N_FO_Additional_Usage_4,
    N_FO_Additional_volume_4,
    N_FO_Additional_FuelType_5,
    N_FO_Additional_Usage_5,
    N_FO_Additional_volume_5,

    N_MGO_Consumption_per_day_ME,
    N_MGO_Consumption_per_day_GE,
    N_MGO_Consumption_per_day_Boiler,

    N_MGO_Additional_FuelType_1,
    N_MGO_Additional_Usage_1,
    N_MGO_Additional_volume_1,
    N_MGO_Additional_FuelType_2,
    N_MGO_Additional_Usage_2,
    N_MGO_Additional_volume_2,
    N_MGO_Additional_FuelType_3,
    N_MGO_Additional_Usage_3,
    N_MGO_Additional_volume_3,
    N_MGO_Additional_FuelType_4,
    N_MGO_Additional_Usage_4,
    N_MGO_Additional_volume_4,
    N_MGO_Additional_FuelType_5,
    N_MGO_Additional_Usage_5,
    N_MGO_Additional_volume_5,

    N_LO_Consumption_per_day_MECO,
    N_LO_Consumption_per_day_MESO,
    N_LO_Consumption_per_day_GESO,

    N_Other_Additional_FuelType_1,
    N_Other_Additional_Usage_1,
    N_Other_Additional_volume_1,
    N_Other_Additional_FuelType_2,
    N_Other_Additional_Usage_2,
    N_Other_Additional_volume_2,
    N_Other_Additional_FuelType_3,
    N_Other_Additional_Usage_3,
    N_Other_Additional_volume_3,
    N_Other_Additional_FuelType_4,
    N_Other_Additional_Usage_4,
    N_Other_Additional_volume_4,
    N_Other_Additional_FuelType_5,
    N_Other_Additional_Usage_5,
    N_Other_Additional_volume_5,
    N_Weather,
    N_Wind_direction,
    N_BF_scale,
    N_Swell,
    N_Noon_Course,
    N_Dist_to_go,
    N_Next_port,
    N_ETA,
    N_ETB,
    N_ETD,
    N_ME_Fuel_index,
    N_ME_RPM,
    N_ME_horsepower,
    N_ME_power,
    N_ME_power_measurement_method,
    N_Running_hours_SOx_scrubber,
    N_Running_hours_ME_EGR,
    N_Running_hours_GE_SCR,
    N_ROB_FO,
    N_ROB_MGO,
    N_ROB_MECO,
    N_ROB_MESO,
    N_ROB_GESO,
    N_ROB_FW,
    N_ROB_Filename,
    N_FO_CO2_emission_per_unit,
    N_MGO_CO2_emission_per_unit,
    N_FO_CO2_emission,
    N_MGO_CO2_emission,
    N_Remark,
    N_Spare_1,
    N_Spare_1_unit_name,
    N_Spare_2,
    N_Spare_2_unit_name,
    N_Spare_3,
    N_Spare_3_unit_name,
    N_Spare_4,
    N_Spare_4_unit_name,
    N_Spare_5,
    N_Spare_5_unit_name,
    N_Spare_6,
    N_Spare_6_unit_name,
    N_Spare_7,
    N_Spare_7_unit_name,
    N_Spare_8,
    N_Spare_8_unit_name,
    N_Spare_9,
    N_Spare_9_unit_name,
    N_Spare_10,
    N_Spare_10_unit_name,
    N_Spare_11,
    N_Spare_11_unit_name,
    N_Spare_12,
    N_Spare_12_unit_name,
    N_Spare_13,
    N_Spare_13_unit_name,
    N_Spare_14,
    N_Spare_14_unit_name,
    N_Spare_15,
    N_Spare_15_unit_name,
    N_Spare_16,
    N_Spare_16_unit_name,
    N_Spare_17,
    N_Spare_17_unit_name,
    N_Spare_18,
    N_Spare_18_unit_name,
    N_Spare_19,
    N_Spare_19_unit_name,
    N_Spare_20,
    N_Spare_20_unit_name,
  } = form;

  const N_TimeZone = getTimeZone(N_TimeZone_PorN, N_TimeZone_HH, N_TimeZone_MM);
  const N_LatLong = getLatLong(
    N_Lat_Deg,
    N_Lat_Min,
    N_Lat_Direction,
    N_Long_Deg,
    N_Long_Min,
    N_Long_Direction
  );

  return {
    ship_id,
    N_Vessel_name: N_Vessel_name || "",
    N_Voy: N_Voy || "",
    N_LorB: N_LorB || LorB.Ballast,
    N_Noon_Port,
    N_UNLOC,
    N_Port_L_U,
    N_EU_UK_port,
    N_DateTime: N_DateTime || new Date(),
    N_TimeZone: N_TimeZone_MM ? N_TimeZone : "",
    N_LatLong: N_LatLong,
    N_Steam_mode: N_Steam_mode || SteamMode.Full,
    N_Hrs_propelling: N_Hrs_propelling || 0,
    N_Dist_OG: N_Dist_OG || 0,
    N_Speed_OG: N_Speed_OG || 0,
    N_Speed_OW: N_Speed_OW || 0,
    N_ME_rpm_ave: N_ME_rpm_ave || 0,
    N_ME_rpm_total: N_ME_rpm_total || 0,
    N_ME_Revolution: N_ME_Revolution || 0,
    N_Slip: N_Slip || 0,

    N_FO_Consumption_per_day_ME: N_FO_Consumption_per_day_ME || 0,
    N_FO_Consumption_per_day_GE: N_FO_Consumption_per_day_GE || 0,
    N_FO_Consumption_per_day_BInc: N_FO_Consumption_per_day_BInc || 0,

    N_FO_Additional_FuelType_1,
    N_FO_Additional_Usage_1,
    N_FO_Additional_volume_1,
    N_FO_Additional_FuelType_2,
    N_FO_Additional_Usage_2,
    N_FO_Additional_volume_2,
    N_FO_Additional_FuelType_3,
    N_FO_Additional_Usage_3,
    N_FO_Additional_volume_3,
    N_FO_Additional_FuelType_4,
    N_FO_Additional_Usage_4,
    N_FO_Additional_volume_4,
    N_FO_Additional_FuelType_5,
    N_FO_Additional_Usage_5,
    N_FO_Additional_volume_5,

    N_MGO_Consumption_per_day_ME: N_MGO_Consumption_per_day_ME || 0,
    N_MGO_Consumption_per_day_GE: N_MGO_Consumption_per_day_GE || 0,
    N_MGO_Consumption_per_day_Boiler: N_MGO_Consumption_per_day_Boiler || 0,

    N_MGO_Additional_FuelType_1,
    N_MGO_Additional_Usage_1,
    N_MGO_Additional_volume_1,
    N_MGO_Additional_FuelType_2,
    N_MGO_Additional_Usage_2,
    N_MGO_Additional_volume_2,
    N_MGO_Additional_FuelType_3,
    N_MGO_Additional_Usage_3,
    N_MGO_Additional_volume_3,
    N_MGO_Additional_FuelType_4,
    N_MGO_Additional_Usage_4,
    N_MGO_Additional_volume_4,
    N_MGO_Additional_FuelType_5,
    N_MGO_Additional_Usage_5,
    N_MGO_Additional_volume_5,

    N_LO_Consumption_per_day_MECO: N_LO_Consumption_per_day_MECO || 0,
    N_LO_Consumption_per_day_MESO: N_LO_Consumption_per_day_MESO || 0,
    N_LO_Consumption_per_day_GESO: N_LO_Consumption_per_day_GESO || 0,

    N_Other_Additional_FuelType_1,
    N_Other_Additional_Usage_1,
    N_Other_Additional_volume_1,
    N_Other_Additional_FuelType_2,
    N_Other_Additional_Usage_2,
    N_Other_Additional_volume_2,
    N_Other_Additional_FuelType_3,
    N_Other_Additional_Usage_3,
    N_Other_Additional_volume_3,
    N_Other_Additional_FuelType_4,
    N_Other_Additional_Usage_4,
    N_Other_Additional_volume_4,
    N_Other_Additional_FuelType_5,
    N_Other_Additional_Usage_5,
    N_Other_Additional_volume_5,

    N_Weather: N_Weather || undefined,
    N_Wind_direction,
    N_BF_scale,
    N_Swell,
    N_Noon_Course,
    N_Dist_to_go,
    N_Next_port: N_Next_port || "",
    N_ETA,
    N_ETB,
    N_ETD,
    N_ME_Fuel_index,
    N_ME_RPM,
    N_ME_horsepower,
    N_ME_power,
    N_ME_power_measurement_method: N_ME_power_measurement_method || null,
    N_Running_hours_SOx_scrubber,
    N_Running_hours_ME_EGR,
    N_Running_hours_GE_SCR: N_Running_hours_GE_SCR || 0,
    N_ROB_FO: N_ROB_FO || 0,
    N_ROB_MGO: N_ROB_MGO || 0,
    N_ROB_MECO: N_ROB_MECO || 0,
    N_ROB_MESO: N_ROB_MESO || 0,
    N_ROB_GESO: N_ROB_GESO || 0,
    N_ROB_FW: N_ROB_FW || 0,
    N_Additional_ROB: filterArrUndefinedNull(
      form.N_Additional_ROB?.filter((v) => v.N_Fuel_volume !== undefined).map(
        (v) => {
          return {
            ...v,
            id: v.id || 0,
            N_Fuel_volume: v.N_Fuel_volume || 0,
            N_Fuel_Type: v.N_Fuel_Type,
          };
        }
      )
    ) as AdditionalRobInput[],
    N_ROB_Filename,
    N_FO_CO2_emission_per_unit: N_FO_CO2_emission_per_unit || 0,
    N_MGO_CO2_emission_per_unit: N_MGO_CO2_emission_per_unit || 0,
    N_FO_CO2_emission: N_FO_CO2_emission || 0,
    N_MGO_CO2_emission: N_MGO_CO2_emission || 0,
    N_Remark: N_Remark || "",
    N_Spare_1,
    N_Spare_1_unit_name,
    N_Spare_2,
    N_Spare_2_unit_name,
    N_Spare_3,
    N_Spare_3_unit_name,
    N_Spare_4,
    N_Spare_4_unit_name,
    N_Spare_5,
    N_Spare_5_unit_name,
    N_Spare_6,
    N_Spare_6_unit_name,
    N_Spare_7,
    N_Spare_7_unit_name,
    N_Spare_8,
    N_Spare_8_unit_name,
    N_Spare_9,
    N_Spare_9_unit_name,
    N_Spare_10,
    N_Spare_10_unit_name,
    N_Spare_11,
    N_Spare_11_unit_name,
    N_Spare_12,
    N_Spare_12_unit_name,
    N_Spare_13,
    N_Spare_13_unit_name,
    N_Spare_14,
    N_Spare_14_unit_name,
    N_Spare_15,
    N_Spare_15_unit_name,
    N_Spare_16,
    N_Spare_16_unit_name,
    N_Spare_17,
    N_Spare_17_unit_name,
    N_Spare_18,
    N_Spare_18_unit_name,
    N_Spare_19,
    N_Spare_19_unit_name,
    N_Spare_20,
    N_Spare_20_unit_name,
  };
};
