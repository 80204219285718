import React, { useEffect, useState } from "react";
import Default from "../../../layouts/DefaultCrew";
import Template from "../../../templates/Crew/Arrival/Arrival";
import { Arrival as ArrivalType } from "./Create/types";
import { db } from "../../../../dexie/db";
import { useLiveQuery } from "dexie-react-hooks";
import { ReportingErrorBoundary } from "../../../atoms/ReportingErrorBoundary";
import { ArrivalInput } from "../../../../gen/graphql/types";
import {
  useContextIDs,
  useContextReportSearchCondition,
} from "../../../../App";
import { useArrivals } from "../../Owner/Arrival/Arrival";
import { ArrivalDexie } from "../../../../dexie/Arrival";
import dayjs from "dayjs";
import { ReportSearchCondition } from "../../pages.module";
import { useCrewSearchForm } from "../crew.module";

export const mapToArrivalVariables = (report: ArrivalType): ArrivalInput => {
  return {
    ...report,
    ship_id: 0,
    A_ROB_at_Arrival_FO: 0,
    A_ROB_at_Arrival_MGO: 0,
    A_ROB_at_Arrival_MECO: 0,
    A_ROB_at_Arrival_MESO: 0,
    A_ROB_at_Arrival_GESO: 0,
    A_ROB_at_Arrival_FW: 0,
    A_Hrs_since_Last_Noon: 0,
    A_Distance_by_OG: 0,
    A_FO_Consumption_per_day_ME: 0,
    A_FO_Consumption_per_day_GE: 0,
    A_FO_Consumption_per_day_B_and_Inc: 0,
    A_MGO_Consumption_per_day_ME: 0,
    A_MGO_Consumption_per_day_GE: 0,
    A_MGO_Consumption_per_day_B_and_Inc: 0,
    A_ROB_FO: 0,
    A_ROB_MGO: 0,
    A_ROB_MECO: 0,
    A_ROB_MESO: 0,
    A_ROB_GESO: 0,
    A_ROB_FW: 0,
    A_Draft_Fore: 0,
    A_Draft_Aft: 0,
    A_FO_CO2_emission: 0,
    A_MGO_CO2_emission: 0,
    A_Remark: undefined,
  };
};

const Arrival = (): React.ReactElement => {
  const frontDbArrival = useLiveQuery(
    () => db.arrival.reverse().toArray(),
    []
  )?.sort((a, b) =>
    dayjs(b.data.A_Arrival_Date_Time).diff(dayjs(a.data.A_Arrival_Date_Time))
  );

  const { ship_id } = useContextIDs();

  const reports = useArrivals();
  const [isServerData, setIsServerData] = useState(false);

  const { reportSearchCondition, setReportSearchCondition } =
    useContextReportSearchCondition();
  const form = useCrewSearchForm(ship_id, reportSearchCondition);

  const onUpdateForm = (value: ReportSearchCondition) => {
    form.update(value);
  };

  const onUpdateSearchCondition = (
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ) => {
    form.update(value);
    if (!isWithRefresh) {
      return;
    }

    reports.refetch(form.data);
  };

  const onSearch = () => {
    form.data.pagination.page = 1;
    setReportSearchCondition(form.data);
    form.data.ship_id = ship_id;

    if (!form.data.Voy && !form.data.From_DateTime && !form.data.To_DateTime) {
      setIsServerData(false);
    } else {
      reports.refetch(form.data);
    }
  };

  const serverReportList: ArrivalDexie[] = reports.data.arrivalList.map(
    (report) => {
      const variavle = mapToArrivalVariables(report);
      return {
        id: undefined,
        isDraft: false,
        isSend: true,
        arrivalId: report.id,
        data: variavle,
      };
    }
  );

  useEffect(() => {
    if (
      serverReportList.length > 0 &&
      !reports.isLoading &&
      (form.data.Voy || form.data.From_DateTime || form.data.To_DateTime)
    ) {
      setIsServerData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.data.From_DateTime,
    form.data.To_DateTime,
    form.data.Voy,
    reports.isLoading,
    serverReportList.length,
  ]);

  const deleteReport = (id: number) => {
    db.arrival.delete(id).catch((e) => console.error(e));
  };

  return (
    <Default title="" selectedTitle={"Arrival"}>
      <ReportingErrorBoundary>
        <Template
          reports={isServerData ? serverReportList : frontDbArrival || []}
          deleteReport={deleteReport}
          isServerData={isServerData}
          form={form.data}
          onUpdateForm={onUpdateForm}
          totalPageCount={reports.data.totalPageCount}
          onSearch={onSearch}
          onUpdateSearchCondition={onUpdateSearchCondition}
        />
      </ReportingErrorBoundary>
    </Default>
  );
};

export default Arrival;
