import React from "react";
import classnames from "classnames";
import Box from "@mui/material/Box";
import style from "./Columns.module.scss";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

type Props = {
  spaceBetween?: boolean;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};

const Columns = (props: Props): React.ReactElement => {
  const spaceBetween = props.spaceBetween;
  const classNames = classnames(style.columns, {
    [style.spaceBetween]: spaceBetween,
  });

  return (
    <Box className={classNames} sx={{ my: 2, ml: -2, ...props.sx }}>
      {props.children}
    </Box>
  );
};

export default Columns;
