import { Cargo } from "../../../../gen/graphql/types";
import {
  formatToCsvString,
  genSearchConditionHash,
} from "../../../../utils/download-csv-util";
import { formatToUtcYYYYMMDDHHMM, formatToYYYYMMDDHHMM } from "../../../../utils/formats";
import { ReportSearchCondition } from "../../pages.module";

export const genCargoCsv = (
  searchCondition: ReportSearchCondition,
  reportList: Cargo[]
) => {
  const genFileName = async () => {
    const hashed = await genSearchConditionHash(searchCondition).catch((e) => {
      throw e;
    });
    const formatted = formatToYYYYMMDDHHMM(new Date(), "-", "", "");
    return `Cargo${formatted}-${hashed}.csv`;
  };

  const genText = () => {
    const head = [
      '"Report ID"',
      '"IMO number"',
      '"Voyage number"',
      '"Date & Time (Local)"',
      '"Time Zone (UTC±)"',
      '"Lat."',
      '"Lon."',
      '"Port name"',
      '"UNLOC"',
      '"EU/UK"',
      '"Port L/U"',
      '"Cargo Loading Mass (mt)"',
      '"Cargo Loading TEU/Full"',
      '"Cargo Loading TEU/Empty"',
      '"Cargo Loading Unit"',
      '"Cargo Loading Lane_metres (metre)"',
      '"Cargo Loading Number_of_Passengers"',
      '"Cargo Loading Volume (m³)"',
      '"Cargo Unloading Mass (mt)"',
      '"Cargo Unloading TEU/Full"',
      '"Cargo Unloading TEU/Empty"',
      '"Cargo Unloading Unit"',
      '"Cargo Unloading Lane_metres (metre)"',
      '"Cargo Unloading Number_of_Passengers"',
      '"Cargo Unloading Volume (m³)"',
      '"Cargo Total Mass (mt)"',
      '"Cargo Total TEU/Full"',
      '"Cargo Total TEU/Empty"',
      '"Cargo Total Unit"',
      '"Cargo Total Lane_metres (metre)"',
      '"Cargo Total Number_of_Passengers"',
      '"Cargo Total Volume (m³)"',
      '"Deadweight measurement Displacement (mt)"',
      '"Deadweight measurement Water_density (mt)"',
      '"Deadweight measurement Total_ROB (mt)"',
      '"Filename"',
    ].join(",");
    const body = reportList.map((report) => {
      return [
        report.id,
        formatToCsvString(report.imo_no),
        formatToCsvString(report.C_Voyage_No),
        formatToUtcYYYYMMDDHHMM(report.C_DateTime),
        formatToCsvString(report.C_Timezone),
        formatToCsvString(report.C_Latitude),
        formatToCsvString(report.C_Longitude),
        formatToCsvString(report.C_Cargo_Port),
        formatToCsvString(report.C_UNLOC),
        formatToCsvString(report.C_EU_UK_port),
        formatToCsvString(report.C_Port_L_U),
        report.C_Loading_Mass,
        report.C_Loading_TEU_Full,
        report.C_Loading_TEU_Empty,
        report.C_Loading_Unit,
        report.C_Loading_Lane_metres,
        report.C_Loading_Number_of_Passengers,
        report.C_Loading_Volume,

        report.C_Unloading_Mass,
        report.C_Unloading_TEU_Full,
        report.C_Unloading_TEU_Empty,
        report.C_Unloading_Unit,
        report.C_Unloading_Lane_metres,
        report.C_Unloading_Number_of_Passengers,
        report.C_Unloading_Volume,

        report.C_Total_Mass,
        report.C_Total_TEU_Full,
        report.C_Total_TEU_Empty,
        report.C_Total_Unit,
        report.C_Total_Lane_metres,
        report.C_Total_Number_of_Passengers,
        report.C_Total_Volume,

        report.C_Displacement,
        report.C_Water_density,
        report.C_Total_ROB,
        formatToCsvString(report.C_Filename),
      ].join(",");
    });

    return [head, ...body].join("\n");
  };

  return (async () => {
    const fileName = await genFileName().catch((e) => {
      throw e;
    });
    const text = genText();

    return {
      fileName,
      text,
    };
  })();
};
