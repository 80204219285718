import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { nonNullable } from "../../utils/formats";

type Props = {
  placeholder?: string;
  id: string;
  label: string;
  value: number | undefined;
  onChange(value: string): void;
  maxValue?: number;
  minValue?: number;
  unitName?: string;
};

const numberMinMaxChk = (props: Props) => {
  if (!props.value) {
    return {
      isError: false,
      errorMessage: "",
    };
  }

  if (nonNullable(props.minValue) && props.value < props.minValue) {
    return {
      isError: true,
      errorMessage: `Input value is below the minimum value ${props.minValue}.`,
    };
  } else if (nonNullable(props.maxValue) && props.maxValue < props.value) {
    return {
      isError: true,
      errorMessage: `Input value exceeds the maximum value ${props.maxValue}.`,
    };
  } else {
    return {
      isError: false,
      errorMessage: "",
    };
  }
};

const CommonNumberFIeldStandard = (props: Props): React.ReactElement => {
  const { isError, errorMessage } = numberMinMaxChk(props);

  return (
    <TextField
      variant="standard"
      size="small"
      disabled={true}
      InputProps={{
        "aria-label": `${props.id}`,
        endAdornment: (
          <InputAdornment position="end">
            <span style={{ color: "#AAAAAA" }}>{props.unitName}</span>
          </InputAdornment>
        ),
      }}
      sx={{
        textAlign: "right",
        color: "#FFFFFF",
        "& MuiInputAdornment-standard": {
          color: "#20BE89",
        },
        "& .MuiInputBase-input": {
          color: "#FFFFFF",
          cursor: "not-allowed",
        },
        "& label.MuiInputLabel-root": {
          color: "#AAAAAA",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#20BE89",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#464E5A",
          },
          "&:hover fieldset": {
            borderColor: "#AAAAAA",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#20BE89",
          },
        },
      }}
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      value={props.value}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
      inputProps={{
        "aria-label": `${props.id}`,
      }}
      helperText={errorMessage}
      error={isError}
    />
  );
};

export default CommonNumberFIeldStandard;
