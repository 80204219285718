import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import { FC, memo } from "react";
import { FormProvider } from "react-hook-form";
import { useRhfSample } from "./RhfSample.hook";

import Default from "../../layouts/DefaultCrew";
import { RhfSampleForm } from "../../organisms/react-hook-form/RhfSampleForm";

export const RhfSample: FC = memo(function RhfSample() {
  const { rhfSampleForm, onSave } = useRhfSample();

  return (
    <Default title="" selectedTitle={"Noon Report"}>
      <FormProvider {...rhfSampleForm}>
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="column"
          flexShrink="unset"
          width="100%"
        >
          <Box
            sx={{
              width: "100%",
              paddingLeft: (theme) => theme.spacing(15),
              paddingRight: (theme) => theme.spacing(15),
            }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box />
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  borderRadius: (theme) => theme.spacing(2),
                }}
                onClick={() => onSave()}
              >
                pages側 設定値確認
              </Button>
            </Grid>

            <RhfSampleForm />

            <Divider
              sx={{
                backgroundColor: "gray[400]",
                height: "2px",
                width: "100%",
              }}
            />
          </Box>
        </Stack>
      </FormProvider>{" "}
    </Default>
  );
});
