import { FuelType } from "../../../../gen/graphql/types";
import { Form } from "../../../pages/Crew/NoonReport/Create/types";

type AdditionalKeyStringsType = {
  fuelTypeKey: string;
  usageKey: string;
  volumeKey: string;
};

export const additionalKeyStrings = {
  FO: {
    fuelTypeKey: "N_FO_Additional_FuelType_",
    usageKey: "N_FO_Additional_Usage_",
    volumeKey: "N_FO_Additional_volume_",
  } as AdditionalKeyStringsType,
  MGO: {
    fuelTypeKey: "N_MGO_Additional_FuelType_",
    usageKey: "N_MGO_Additional_Usage_",
    volumeKey: "N_MGO_Additional_volume_",
  } as AdditionalKeyStringsType,
  OTHER: {
    fuelTypeKey: "N_Other_Additional_FuelType_",
    usageKey: "N_Other_Additional_Usage_",
    volumeKey: "N_Other_Additional_volume_",
  } as AdditionalKeyStringsType,
};

function isAnyAdditionalPropertySet(
  keyString: AdditionalKeyStringsType,
  index: number,
  form: Form
): boolean {
  const fuelTypeKey = `${keyString.fuelTypeKey}${index}`;
  const usageKey = `${keyString.usageKey}${index}`;
  const volumeKey = `${keyString.volumeKey}${index}`;

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (form as any)[fuelTypeKey] != null ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (form as any)[usageKey] != null ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (form as any)[volumeKey] != null
  );
}

export function createAdditionalArrays(
  keyString: AdditionalKeyStringsType,
  form: Form
): {
  titles: string[];
  volumes: (number | undefined)[];
} {
  const maxIndex = 5; // Assuming there are 5 sets of properties

  const titles: string[] = [];
  const volumes: (number | undefined)[] = [];

  for (let i = 1; i <= maxIndex; i++) {
    if (isAnyAdditionalPropertySet(keyString, i, form)) {
      titles.push(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (((form as any)[`${keyString.usageKey}${i}`] as string) || "") +
          " (" +
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (((form as any)[`${keyString.fuelTypeKey}${i}`] as FuelType) || "") +
          ")"
      );
      volumes.push(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !Number.isNaN((form as any)[`${keyString.volumeKey}${i}`])
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ((form as any)[`${keyString.volumeKey}${i}`] as number)
          : undefined
      );
    }
  }

  return { titles, volumes };
}
