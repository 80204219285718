import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

type Props = {
  checked: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void;
  label: string;
}

const ControlCheckbox = (props: Props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          onChange={props.onChange}
        />
      }
      labelPlacement="bottom"
      label={<span style={{ fontSize: 14, position: "absolute", bottom: "-7px", right: "-2px" }}>{props.label}</span>}
      sx={{
        position: "relative",
        bottom: "6px",
        left: "6px",
        marginRight: 0,
        width: 90
      }}
    />
  );
}

export default ControlCheckbox;