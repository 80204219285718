export const lightGray = "#AAAAAA";
export const darkGray = "#555860";
export const darkGrayHoverColor = "#656565";
export const charcoalGray = "#555555";
export const midnightBlue = "#36383F";
export const white = "#FFFFFF";
export const steelBlue = "#464E5A";
export const wedgwoodBlue = "#467BA0";
export const greenTurquoise = "#20BE89";
export const darkSlateBlue = "#36383F";
export const mediumGray = "#888888";
export const lightGrayC9D0DC = "#C9D0DC";
export const weightedGray = "#333333";

export const weightedFontGray = "#808080";
export const lightGrayD3D3D3 = "#D3D3D3";
export const lightGray9FA6B0 = "#9FA6B0";

export const CrewBgColor = "#1A1C21";
export const OwnerBgColor = "#F5F5F5";

export const CrewModalBgColor = "#27292E";

export const sunnyYellow = "#F2C11B";
export const limeGreen = "#B0C91A";
export const taupeBrown = "#987644";
export const plumPurple = "#973865";
export const vividGreen = "#109618";
export const skyBlue = "#0099c6";
export const hotPink = "#ff69b4";

export const sliderColor = "#84868a";

export const paleGray = "#ECEEF2";
export const brightRed = "#F44336";
export const oliveGreen = "#C0B91A";
