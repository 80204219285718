import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import Column from "../../atoms/Column";
import Columns from "../../atoms/Columns";
import CommonTextFIeld from "../../atoms/CommonTextFIeld";
import Optional from "../../atoms/Optional";
import UtcDateTimePicker from "../../atoms/UtcDateTimePicker";
import VesselNamesSelect from "../../atoms/VesselNamesSelect";
import { ReportSearchCondition } from "../../pages/pages.module";
import SucsessButton from "../../atoms/SucsessButton";
import { white } from "../../../utils/color";

type Props = {
  isOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  csvForm: ReportSearchCondition;
  onUpdateCsvForm(value: ReportSearchCondition): void;
  onCsvDownload(): void;
  hasCsvFormError: boolean;
};

const CsvDownloadModal: FC<Props> = (props: Props) => {
  const handleClose = () => props.setIsModalOpen(false);

  const onChange = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    const form = {
      ...props.csvForm,
      [key]: value,
    };

    props.onUpdateCsvForm?.(form);
  };

  const onClickDownload = () => {
    props.onCsvDownload();
    handleClose();
  };

  const style = {
    color: "#333333",
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "#ECEEF2",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography sx={{ fontSize: "24px", pt: 1 }}>
          CSV Download Settings
        </Typography>
        <Columns>
          <Column sx={{ ml: 0 }}>
            <VesselNamesSelect
              value={props.csvForm.imo_no ?? ""}
              minWidth={323}
              onChange={(event) => {
                onChange("imo_no", event.target.value);
              }}
            />
          </Column>
        </Columns>
        <Stack direction="row" spacing={2} alignItems={"center"} sx={{ pt: 3 }}>
          <Typography
            sx={{
              textAlign: "left",
              verticalAlign: "middle",
              fontSize: "16px",
              fontFamily: "Public Sans",
              lineHeight: "auto",
              color: "#aaaaaa",
            }}
          >
            From
          </Typography>

          <UtcDateTimePicker
            id={"From_DateTime"}
            label={"Date & Time"}
            value={props.csvForm.From_DateTime}
            onChange={onChange}
            onChangeKey={"From_DateTime"}
            hasMinDate={false}
          />

          <Typography
            sx={{
              textAlign: "left",
              verticalAlign: "middle",
              fontSize: "16px",
              fontFamily: "Public Sans",
              lineHeight: "auto",
              color: "#aaaaaa",
            }}
          >
            To
          </Typography>

          <UtcDateTimePicker
            id={"To_DateTime"}
            label={"Date & Time"}
            value={props.csvForm.To_DateTime}
            onChange={onChange}
            onChangeKey={"To_DateTime"}
            hasMinDate={false}
          />
        </Stack>
        <Columns>
          <Column>
            <Optional />
            <CommonTextFIeld
              id={"Voy"}
              label={"Voy#"}
              value={props.csvForm.Voy}
              onChange={(e: string | null) => {
                onChange("Voy", e);
              }}
              width={"130px"}
              sx={{ "& .MuiInputBase-input": { color: "#333333 !important" } }}
            />
          </Column>
        </Columns>
        <SucsessButton
          ButtonName={"CSV Download"}
          disabled={
            props.csvForm.imo_no ||
            props.csvForm.From_DateTime ||
            props.csvForm.To_DateTime
              ? false
              : true
          }
          onClick={() => onClickDownload()}
          sx={{ mt: 4, width: 150, color: white }}
        />
        <Button
          sx={{
            textTransform: "none",
            ml: 2,
            mt: 4,
            width: 150,
            backgroundColor: "#9FA6B0",
            fontFamily: ["Public Sans"].join(","),
            color: "#FFFFFF",
            "& .MuiInputBase-input": { color: "#FFFFFF" },
            "& .MuiTextField-root": { color: "#FFFFFF" },

            "& label.Mui-focused": {
              color: "#20BE89",
            },
            "& label.MuiInputLabel-root": {
              color: "#AAAAAA",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#20BE89",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#464E5A",
              },
              "&:hover fieldset": {
                borderColor: "#AAAAAA",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#20BE89",
              },
            },
          }}
          variant="contained"
          disabled={false}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default CsvDownloadModal;
