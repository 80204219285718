import { Alert, Snackbar } from "@mui/material";

type Props = {
  isCreateSucusess: boolean;
  handleClose: () => void;
  openSnackbar: boolean;
};

const CustomSnackbar = (props: Props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={props.openSnackbar}
      autoHideDuration={3000}
      onClose={props.handleClose}
    >
      <Alert
        variant={"standard"}
        onClose={props.handleClose}
        severity={`${props.isCreateSucusess ? "success" : "error"}`}
        sx={{
          width: "100%",
        }}
      >
        {props.isCreateSucusess ? "Success!!" : "Error!!"}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
