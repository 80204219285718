import React from "react";
import { SxProps, TextField, Theme } from "@mui/material";

type Props = {
  disabled?: boolean;
  placeholder?: string;
  id: string;
  label: string;
  value: string | null | undefined;
  width?: string;
  rows?: number;
  onChange(value: string): void;
  sx?: SxProps<Theme>;
  isConfirm?: boolean;
  formErrorMessage?: string;
};

const valueCheck = (props: Props) => {
  if (!props.value) {
    if (!props.isConfirm) {
      return {
        isError: false,
        errorMessage: "",
      };
    } else {
      return {
        isError: true,
        errorMessage: "This field is required.",
      };
    }
  }
  return {
    isError: false,
    errorMessage: "",
  };
};

const CommonTextFIeld = (props: Props): React.ReactElement => {
  const { isError, errorMessage } = valueCheck(props);

  return (
    <TextField
      name={props.id}
      size="small"
      disabled={props.disabled}
      sx={{
        width: `${props.width ? props.width : "18ch"}}`,
        "& .MuiInputBase-input": { color: "#FFFFFF" },
        "& label.Mui-focused": {
          color: "#20BE89",
        },
        "& label": {
          color: "#AAAAAA",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#464E5A",
          },
          "&:hover fieldset": {
            borderColor: "#AAAAAA",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#20BE89",
          },
        },
        ...props.sx,
      }}
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      value={props.value || ""}
      inputProps={{
        maxLength: 50,
        "aria-label": `${props.id}`,
      }}
      rows={props.rows}
      onChange={(e) => props.onChange(e.target.value)}
      error={
        isError ||
        (props.formErrorMessage !== undefined && props.formErrorMessage !== "")
      }
      helperText={errorMessage || props.formErrorMessage || ""}
    />
  );
};

export default CommonTextFIeld;
