import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { TextField, TextFieldProps } from "./TextField";

export type RhfTextFieldProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T>;

export const RhfTextField = <T extends FieldValues>(
  props: RhfTextFieldProps<T>
) => {
  const { name, control, sx, type } = props;
  const {
    field: { ref, ...rest },
    fieldState: { error },
  } = useController<T>({ name, control });

  return (
    <TextField
      type={type ?? "text"}
      inputRef={ref}
      {...rest}
      {...props}
      errorMessage={(error && error.message) || props.errorMessage}
      sx={{ ...sx }}
    />
  );
};
