import { ArrivalDexie } from "../../../../dexie/Arrival";
import {
  QueryStatus,
  ArrivalInput,
  useCreateArrivalListMutation,
} from "../../../../gen/graphql/types";
import { nonNullable } from "../../../../utils/formats";
import { checkQueryStatus } from "../../pages.module";
import { updateFrontArrival } from "./SendDataUtil";
import { ErrorMessageData } from "../../../../hooks/errorMessage.hook";

type Props = {
  checkedArrivalList: ArrivalDexie[];
  setCheckedArrival: (value: React.SetStateAction<ArrivalDexie[]>) => void;
  setCheckedBoxArrival: (value: React.SetStateAction<number[]>) => void;
  createErrorData: (
    dataType: string,
    dataArr: unknown[]
  ) => {
    loading: boolean;
  };
  addErrorMessage: (newErrorMessage: ErrorMessageData) => void;
};

export const useCreateArrivalListMutationHook = () => {
  const [createArrival, { loading: isArrivalLoading }] =
    useCreateArrivalListMutation();

  const createArrivalMutation = (props: Props) => {
    const arrivalVariables: ArrivalInput[] = props.checkedArrivalList
      .map((arrival) => arrival.data)
      .filter(nonNullable);

    createArrival({
      variables: {
        input: {
          ArrivalListInput: arrivalVariables,
        },
      },
      onCompleted: async (data) => {
        try {
          checkQueryStatus(data.createArrivalList?.CreateArrivalListStatus);

          if (!data.createArrivalList) {
            throw new Error(
              "Server Error! createArrivalList response is empty."
            );
          } else if (!data.createArrivalList.createdArrivalCount) {
            throw new Error(
              "Server Error! createArrivalList createArrivalListCount is 0."
            );
          } else if (
            data.createArrivalList.CreateArrivalListStatus ===
            QueryStatus.Success
          ) {
            console.info("createArrival Success!");
          }

          if (
            props.checkedArrivalList.length ===
            data.createArrivalList?.createdArrivalCount
          ) {
            props.checkedArrivalList.forEach((arrival) => {
              updateFrontArrival(arrival);
            });
          }
          props.setCheckedArrival([]);
          props.setCheckedBoxArrival([]);
        } catch (e) {
          console.error(e);
          props.createErrorData("Arrival", arrivalVariables);
          props.addErrorMessage({
            input: JSON.stringify(arrivalVariables),
            errorMessage: `Server Error! createArrivalList onCompleted:${e}`,
          });
        }
      },
      onError: (error) => {
        console.error(error);
        //エラーinputデータ送信
        props.createErrorData("Arrival", arrivalVariables);
        props.addErrorMessage({
          input: JSON.stringify(arrivalVariables),
          errorMessage: `Server Error! createArrivalList onError:${error}`,
        });
      },
    });
  };
  return [{ createArrivalMutation, isArrivalLoading }];
};
