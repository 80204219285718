import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useArrivalListLazyQuery,
  ArrivalListQuery,
} from "../../../../gen/graphql/types";
import Template from "../../../templates/Owner/Arrival/Arrival";
import { Arrival } from "../../Crew/Arrival/Create/types";
import DefaultOwner from "../../../layouts/DefaultOwner";
import {
  SEARCH_PAGE_LIMIT_OWNER,
  useCsvDownloadForm,
} from "../NoonReport/NoonReport";
import {
  CSV_DOWNLOAD_LIMIT,
  downloadCsvFile,
} from "../../../../utils/download-csv-util";
import { useApolloClient } from "@apollo/client";
import { useContextReportSearchCondition } from "../../../../App";
import { genCsv } from "./ArrivalCsvFormat";
import { getArrivalCsvDownloadData } from "./ArrivalUtil";
import dayjs from "dayjs";
import { ReportSearchCondition } from "../../pages.module";
import { mapArrivalListInput } from "../../../../hooks/commonQuery/arrival.hook";

export type Pagination = {
  page: number;
  limit: number;
};

const getArrivalList = (reports?: ArrivalListQuery): Arrival[] => {
  const reportList = (reports?.arrivalList?.arrivalList || [])
    .map((arrival): Arrival | null => arrival)
    .filter((v): v is NonNullable<typeof v> => v != null)
    .sort((a, b) =>
      dayjs(b.A_Arrival_Date_Time).diff(dayjs(a.A_Arrival_Date_Time))
    );

  return reportList;
};

const useForm = () => {
  const [data, update] = useState<ReportSearchCondition>({
    imo_no: null,
    Voy: null,
    From_DateTime: null,
    To_DateTime: null,
    pagination: {
      page: 1,
      limit: SEARCH_PAGE_LIMIT_OWNER,
    },
  });

  return {
    data,
    update,
  };
};

export const useArrivals = () => {
  const [getData, { data: response, loading: isLoading }] =
    useArrivalListLazyQuery();

  const data = useMemo(() => {
    const totalPageCount = response?.arrivalList?.totalPageCount || 0;
    const arrivalList = getArrivalList(response);

    return { totalPageCount, arrivalList: arrivalList };
  }, [response]);

  const refetch = (searchCondition: ReportSearchCondition) => {
    const inputdata = mapArrivalListInput(searchCondition);
    getData({
      variables: {
        input: {
          ...inputdata,
          pagination: {
            page: inputdata.pagination?.page,
            limit: inputdata.pagination?.limit,
          },
        },
      },
      fetchPolicy: "network-only",
    });
  };

  return {
    isLoading,
    data,
    refetch,
  };
};

const ArrivalReport = (): React.ReactElement => {
  const reports = useArrivals();
  const form = useForm();
  const csvForm = useCsvDownloadForm();
  const client = useApolloClient();
  const { reportSearchCondition, setReportSearchCondition } =
    useContextReportSearchCondition();

  const onUpdateForm = (value: ReportSearchCondition) => {
    form.update(value);
  };

  const onUpdateCsvForm = (value: ReportSearchCondition) => {
    csvForm.update(value);
  };

  const onUpdateSearchCondition = (
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ) => {
    form.update(value);

    if (!isWithRefresh) {
      return;
    }

    reports.refetch(form.data);
  };

  const onCsvDownload = useCallback(async () => {
    csvForm.data.pagination.page = 1;
    const input = mapArrivalListInput(csvForm.data);
    try {
      let data = await getArrivalCsvDownloadData(input, client);
      let arrivalList = getArrivalList(data);

      if (data?.arrivalList?.totalPageCountt > CSV_DOWNLOAD_LIMIT) {
        const iterations = Math.ceil(
          data?.arrivalList?.totalPageCount / CSV_DOWNLOAD_LIMIT
        );

        for (let i = 1; i < iterations; i++) {
          csvForm.data.pagination.page = i + 1; // Adjust input to fetch the next page.
          const input = mapArrivalListInput(csvForm.data);
          data = await getArrivalCsvDownloadData(input, client);
          const additionalReportList = getArrivalList(data);
          arrivalList = [...arrivalList, ...additionalReportList]; // merge the new data with existing reportList
        }
      }

      const { fileName, text } = await genCsv(
        csvForm.data,
        arrivalList.reverse()
      ).catch((e) => {
        console.error(e);
        throw Error("");
      });

      downloadCsvFile(fileName, text);
    } catch (e) {
      console.error(e);
      alert("Failed to download CSV");
    } finally {
      csvForm.data.pagination.page = 1;
    }
  }, [client, csvForm.data]);

  const onSearch = () => {
    form.data.pagination.page = 1;
    setReportSearchCondition(form.data);
    reports.refetch(form.data);
  };
  useEffect(() => {
    if (reportSearchCondition != undefined) {
      form.data = reportSearchCondition;
      form.update(form.data);
      reports.refetch(form.data);
      return;
    } else {
      reports.refetch(form.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultOwner title="Noon Report" selectedTitle={"Arrival"}>
      <Template
        arrivalList={reports.data.arrivalList}
        isLoading={reports.isLoading}
        totalPageCount={reports.data.totalPageCount}
        hasFormError={true}
        onUpdateForm={onUpdateForm}
        form={form.data}
        onSearch={onSearch}
        onUpdateSearchCondition={onUpdateSearchCondition}
        onCsvDownload={onCsvDownload}
        csvForm={csvForm.data}
        onUpdateCsvForm={onUpdateCsvForm}
        hasCsvFormError={csvForm.hasCsvFormError}
      />
    </DefaultOwner>
  );
};

export default ArrivalReport;
