import React from "react";
import { useLocation, useNavigate } from "react-router";
import { db } from "../../../../../dexie/db";
import { BunkeringInput, LorU } from "../../../../../gen/graphql/types";
import Default, { useShip } from "../../../../layouts/DefaultCrew";
import Template from "../../../../templates/Crew/Bunkering/Create/Confirm";
import { useGetLastFrontReport } from "../../../../../hooks/dexie/dexie";
import { Form, LocationState } from "./types";
import { BunkeringDexie } from "../../../../../dexie/Bunkering";
import { getLatLong, getTimeZone } from "../../../../../utils/form-util";

export const mapToCreateBunkeringMutationVariables = (
  form: Form,
  ship_id: number
): BunkeringInput => {
  const {
    B_Vessel_Name,
    B_Voy,
    B_TimeZone_PorN,
    B_TimeZone_HH,
    B_TimeZone_MM,
    B_Bunkering_Date_time,
    B_Lat_Direction,
    B_Lat_Deg,
    B_Lat_Min,
    B_Long_Direction,
    B_Long_Deg,
    B_Long_Min,
    B_PortName,
    B_UNLOC,
    B_Port_LorU,
    B_EUorUK,
    B_Fuel_name_1,
    B_Mass_1,
    B_Volume_1,
    B_Density_1,
    B_Fuel_name_2,
    B_Mass_2,
    B_Volume_2,
    B_Density_2,
    B_Fuel_name_3,
    B_Mass_3,
    B_Volume_3,
    B_Density_3,
    B_Fuel_name_4,
    B_Mass_4,
    B_Volume_4,
    B_Density_4,
    B_Fuel_name_5,
    B_Mass_5,
    B_Volume_5,
    B_Density_5,
    B_Fuel_name_6,
    B_Mass_6,
    B_Volume_6,
    B_Density_6,
    B_Fuel_name_7,
    B_Mass_7,
    B_Volume_7,
    B_Density_7,
    B_Fuel_name_8,
    B_Mass_8,
    B_Volume_8,
    B_Density_8,
    B_Fuel_name_9,
    B_Mass_9,
    B_Volume_9,
    B_Density_9,
    B_Fuel_name_10,
    B_Mass_10,
    B_Volume_10,
    B_Density_10,
    B_Filename,
  } = form;

  const B_Timezone = getTimeZone(B_TimeZone_PorN, B_TimeZone_HH, B_TimeZone_MM);
  const B_LatLong = getLatLong(
    B_Lat_Deg,
    B_Lat_Min,
    B_Lat_Direction,
    B_Long_Deg,
    B_Long_Min,
    B_Long_Direction
  );

  return {
    id: 0,
    ship_id,
    B_Vessel_Name: B_Vessel_Name || "",
    B_Voy: B_Voy || "",
    B_Timezone,
    B_Bunkering_Date_time: B_Bunkering_Date_time || new Date(),
    B_LatLong,
    B_PortName: B_PortName || "",
    B_UNLOC: B_UNLOC || "",
    B_Port_LorU: B_Port_LorU || LorU.Y,
    B_EUorUK,
    B_Fuel_name_1,
    B_Mass_1,
    B_Volume_1,
    B_Density_1,
    B_Fuel_name_2,
    B_Mass_2,
    B_Volume_2,
    B_Density_2,
    B_Fuel_name_3,
    B_Mass_3,
    B_Volume_3,
    B_Density_3,
    B_Fuel_name_4,
    B_Mass_4,
    B_Volume_4,
    B_Density_4,
    B_Fuel_name_5,
    B_Mass_5,
    B_Volume_5,
    B_Density_5,
    B_Fuel_name_6,
    B_Mass_6,
    B_Volume_6,
    B_Density_6,
    B_Fuel_name_7,
    B_Mass_7,
    B_Volume_7,
    B_Density_7,
    B_Fuel_name_8,
    B_Mass_8,
    B_Volume_8,
    B_Density_8,
    B_Fuel_name_9,
    B_Mass_9,
    B_Volume_9,
    B_Density_9,
    B_Fuel_name_10,
    B_Mass_10,
    B_Volume_10,
    B_Density_10,
    B_Filename,
  };
};

type LocationForm = {
  form: Form;
};

const Confirm = (props: LocationState): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const location_form = location.state as LocationForm;

  const ship = useShip();
  if (!props.form && !location_form.form) {
    alert("Input value is not set.");
    navigate(-1);
  }

  const form = props.form || location_form.form;

  const onCreate = async () => {
    try {
      if (!ship?.ship_id) throw Error("Error! ship id is missing");

      const variables = mapToCreateBunkeringMutationVariables(
        form,
        ship?.ship_id
      );

      const newBunkering: BunkeringDexie = {
        id: undefined,
        isDraft: false,
        isSend: false,
        data: variables,
      };

      db.bunkering.add(newBunkering, 1);
      console.log(newBunkering);
    } catch (error) {
      console.error(error);
      alert("Failed to create data.");
      return;
    }

    navigate("/bunkering/create/complete");
  };
  const lastReport = useGetLastFrontReport();

  const onBack = () => {
    navigate(`/bunkering/create`, { state: { form: form } });
  };

  return (
    <Default title="" selectedTitle={"Bunkering"}>
      <Template
        form={location_form.form}
        onCreate={onCreate}
        onBack={onBack}
        lastReport={lastReport}
      />
    </Default>
  );
};

export default Confirm;
