import { useMemo } from "react";
import { useLazyInitializeState } from "../../../../utils/lazy-initialize-state";
import { Form, initializeArrival } from "./Create/types";
import { emptyToDoubleQuotation } from "../../../../utils/formats";
import {
  AdditionalRobForArrivalInput,
  ArrivalInput,
  LorB,
  LorU,
} from "../../../../gen/graphql/types";
import { filterArrUndefinedNull } from "../../../../utils/type-checks";

type Maybe<T> = T | null | undefined;

export const useArrivalForm = (initializes: Maybe<Form>[]) => {
  const [isLoading, data, update] = useLazyInitializeState<Form>(
    initializeArrival,
    initializes
  );

  const hasError = useMemo(() => {
    const {
      A_Vessel_Name,
      A_Voy,
      A_LorB,
      A_Arrival_Port,
      A_Arrival_Date_Time,
      A_SB_or_Stop,
      A_TimeZone_PorN,
      A_TimeZone_HH,
      A_TimeZone_MM,
      A_ETB,
      A_ETC,
      A_ETD,
      A_Berthed,
      A_ROB_at_Arrival_FO,
      A_ROB_at_Arrival_MGO,
      A_ROB_at_Arrival_MECO,
      A_ROB_at_Arrival_MESO,
      A_ROB_at_Arrival_GESO,
      A_ROB_at_Arrival_FW,
      A_Hrs_since_Last_Noon,
      A_Distance_by_OG,
      A_FO_Consumption_per_day_ME,
      A_FO_Consumption_per_day_GE,
      A_FO_Consumption_per_day_B_and_Inc,
      A_MGO_Consumption_per_day_ME,
      A_MGO_Consumption_per_day_GE,
      A_MGO_Consumption_per_day_B_and_Inc,
      A_ROB_FO,
      A_ROB_MGO,
      A_ROB_MECO,
      A_ROB_MESO,
      A_ROB_GESO,
      A_ROB_FW,
      A_Draft_Fore,
      A_Draft_Aft,
      A_FO_CO2_emission,
      A_MGO_CO2_emission,
      // A_Remark,
    } = data;

    const hasError = [
      A_Vessel_Name,
      A_Voy,
      A_LorB,
      A_Arrival_Port,
      A_Arrival_Date_Time,
      A_SB_or_Stop,
      A_TimeZone_PorN,
      A_TimeZone_HH,
      A_TimeZone_MM,
      A_ETB,
      A_ETC,
      A_ETD,
      A_Berthed,
      A_ROB_at_Arrival_FO,
      A_ROB_at_Arrival_MGO,
      A_ROB_at_Arrival_MECO,
      A_ROB_at_Arrival_MESO,
      A_ROB_at_Arrival_GESO,
      A_ROB_at_Arrival_FW,
      A_Hrs_since_Last_Noon,
      A_Distance_by_OG,
      A_FO_Consumption_per_day_ME,
      A_FO_Consumption_per_day_GE,
      A_FO_Consumption_per_day_B_and_Inc,
      A_MGO_Consumption_per_day_ME,
      A_MGO_Consumption_per_day_GE,
      A_MGO_Consumption_per_day_B_and_Inc,
      A_ROB_FO,
      A_ROB_MGO,
      A_ROB_MECO,
      A_ROB_MESO,
      A_ROB_GESO,
      A_ROB_FW,
      A_Draft_Fore,
      A_Draft_Aft,
      A_FO_CO2_emission,
      A_MGO_CO2_emission,
      // D_Remark
    ].some((item) => item === null || item === undefined || item === "");

    return hasError;
  }, [data]);
  const hasErrorScrollUp = () => {
    const {
      A_Vessel_Name,
      A_Voy,
      A_LorB,
      A_Arrival_Port,
      A_Arrival_Date_Time,
      A_SB_or_Stop,
      A_ETB,
      A_ETC,
      A_ETD,
      A_Berthed,
      A_ROB_at_Arrival_FO,
      A_ROB_at_Arrival_MGO,
      A_ROB_at_Arrival_MECO,
      A_ROB_at_Arrival_MESO,
      A_ROB_at_Arrival_GESO,
      A_ROB_at_Arrival_FW,
      A_Hrs_since_Last_Noon,
      A_Distance_by_OG,
      A_FO_Consumption_per_day_ME,
      A_FO_Consumption_per_day_GE,
      A_FO_Consumption_per_day_B_and_Inc,
      A_MGO_Consumption_per_day_ME,
      A_MGO_Consumption_per_day_GE,
      A_MGO_Consumption_per_day_B_and_Inc,
      A_ROB_FO,
      A_ROB_MGO,
      A_ROB_MECO,
      A_ROB_MESO,
      A_ROB_GESO,
      A_ROB_FW,
      A_Draft_Fore,
      A_Draft_Aft,
      A_FO_CO2_emission,
      A_MGO_CO2_emission,
    } = data;

    const upperForm = [
      A_Vessel_Name,
      A_Voy,
      A_LorB,
      A_Arrival_Port,
      A_Arrival_Date_Time,
      A_SB_or_Stop,
    ];

    const lowerForm = [
      A_ETB,
      A_ETC,
      A_ETD,
      A_Berthed,
      A_ROB_at_Arrival_FO,
      A_ROB_at_Arrival_MGO,
      A_ROB_at_Arrival_MECO,
      A_ROB_at_Arrival_MESO,
      A_ROB_at_Arrival_GESO,
      A_ROB_at_Arrival_FW,
      A_Hrs_since_Last_Noon,
      A_Distance_by_OG,
      A_FO_Consumption_per_day_ME,
      A_FO_Consumption_per_day_GE,
      A_FO_Consumption_per_day_B_and_Inc,
      A_MGO_Consumption_per_day_ME,
      A_MGO_Consumption_per_day_GE,
      A_MGO_Consumption_per_day_B_and_Inc,
      A_ROB_FO,
      A_ROB_MGO,
      A_ROB_MECO,
      A_ROB_MESO,
      A_ROB_GESO,
      A_ROB_FW,
      A_Draft_Fore,
      A_Draft_Aft,
      A_FO_CO2_emission,
      A_MGO_CO2_emission,
    ];

    if (upperForm.filter((v) => !v).length != 0) {
      window.scrollTo({
        top: 150,
        behavior: "smooth",
      });
    } else if (lowerForm.filter((v) => !v).length != 0) {
      window.scrollTo({
        top: 400,
        behavior: "smooth",
      });
    }
  };

  return {
    isLoading,
    data,
    hasError,
    update,
    hasErrorScrollUp,
  };
};

export const mapToArrivalFormData = (
  report: ArrivalInput | undefined
): Form | null => {
  if (report === undefined) {
    return null;
  }

  return {
    A_Vessel_Name: report.A_Vessel_Name,
    A_Voy: report.A_Voy || "",
    A_LorB: report.A_LorB || LorB.Ballast,
    A_Arrival_Port: report.A_Arrival_Port || "",
    A_EU_UK_port: report.A_EU_UK_port,
    A_Port_L_U: report.A_Port_L_U || LorU.N,
    A_UNLOC: report.A_UNLOC || "",
    A_Arrival_Date_Time: report.A_Arrival_Date_Time,
    A_SB_or_Stop: report.A_SB_or_Stop,
    A_TimeZone_PorN: String(report?.A_Time_Zone).slice(0, 1),
    A_TimeZone_HH: String(report?.A_Time_Zone).slice(1, 3),
    A_TimeZone_MM: String(report?.A_Time_Zone).slice(4, 6),
    A_ETB: report.A_ETB,
    A_ETC: report.A_ETC,
    A_ETD: report.A_ETD,
    A_Berthed: report.A_Berthed,
    A_ROB_at_Arrival_FO: report.A_ROB_at_Arrival_FO,
    A_ROB_at_Arrival_MGO: report.A_ROB_at_Arrival_MGO,
    A_ROB_at_Arrival_MECO: report.A_ROB_at_Arrival_MECO,
    A_ROB_at_Arrival_MESO: report.A_ROB_at_Arrival_MESO,
    A_ROB_at_Arrival_GESO: report.A_ROB_at_Arrival_GESO,
    A_ROB_at_Arrival_FW: report.A_ROB_at_Arrival_FW,
    A_Hrs_since_Last_Noon: report.A_Hrs_since_Last_Noon,
    A_Distance_by_OG: report.A_Distance_by_OG,
    A_FO_Consumption_per_day_ME: report.A_FO_Consumption_per_day_ME,
    A_FO_Consumption_per_day_GE: report.A_FO_Consumption_per_day_GE,
    A_FO_Consumption_per_day_B_and_Inc:
      report.A_FO_Consumption_per_day_B_and_Inc,
    A_MGO_Consumption_per_day_ME: report.A_MGO_Consumption_per_day_ME,
    A_MGO_Consumption_per_day_GE: report.A_MGO_Consumption_per_day_GE,
    A_MGO_Consumption_per_day_B_and_Inc:
      report.A_MGO_Consumption_per_day_B_and_Inc,
    A_ROB_FO: report.A_ROB_FO,
    A_ROB_MGO: report.A_ROB_MGO,
    A_ROB_MECO: report.A_ROB_MECO,
    A_ROB_MESO: report.A_ROB_MESO,
    A_ROB_GESO: report.A_ROB_GESO,
    A_ROB_FW: report.A_ROB_FW,
    A_Additional_ROB: filterArrUndefinedNull(report.A_Additional_ROB),
    A_Draft_Fore: report.A_Draft_Fore,
    A_Draft_Aft: report.A_Draft_Aft,
    A_FO_CO2_emission: report.A_FO_CO2_emission,
    A_MGO_CO2_emission: report.A_MGO_CO2_emission,
    A_Remark: report.A_Remark || "",
    A_Spare_1: emptyToDoubleQuotation(report.A_Spare_1),
    A_Spare_1_unit_name: emptyToDoubleQuotation(report.A_Spare_1_unit_name),
    A_Spare_2: emptyToDoubleQuotation(report.A_Spare_2),
    A_Spare_2_unit_name: emptyToDoubleQuotation(report.A_Spare_2_unit_name),
    A_Spare_3: emptyToDoubleQuotation(report.A_Spare_3),
    A_Spare_3_unit_name: emptyToDoubleQuotation(report.A_Spare_3_unit_name),
    A_Spare_4: emptyToDoubleQuotation(report.A_Spare_4),
    A_Spare_4_unit_name: emptyToDoubleQuotation(report.A_Spare_4_unit_name),
    A_Spare_5: emptyToDoubleQuotation(report.A_Spare_5),
    A_Spare_5_unit_name: emptyToDoubleQuotation(report.A_Spare_5_unit_name),
    A_Spare_6: emptyToDoubleQuotation(report.A_Spare_6),
    A_Spare_6_unit_name: emptyToDoubleQuotation(report.A_Spare_6_unit_name),
    A_Spare_7: emptyToDoubleQuotation(report.A_Spare_7),
    A_Spare_7_unit_name: emptyToDoubleQuotation(report.A_Spare_7_unit_name),
    A_Spare_8: emptyToDoubleQuotation(report.A_Spare_8),
    A_Spare_8_unit_name: emptyToDoubleQuotation(report.A_Spare_8_unit_name),
    A_Spare_9: emptyToDoubleQuotation(report.A_Spare_9),
    A_Spare_9_unit_name: emptyToDoubleQuotation(report.A_Spare_9_unit_name),
    A_Spare_10: emptyToDoubleQuotation(report.A_Spare_10),
    A_Spare_10_unit_name: emptyToDoubleQuotation(report.A_Spare_10_unit_name),
    A_Spare_11: emptyToDoubleQuotation(report.A_Spare_11),
    A_Spare_11_unit_name: emptyToDoubleQuotation(report.A_Spare_11_unit_name),
    A_Spare_12: emptyToDoubleQuotation(report.A_Spare_12),
    A_Spare_12_unit_name: emptyToDoubleQuotation(report.A_Spare_12_unit_name),
    A_Spare_13: emptyToDoubleQuotation(report.A_Spare_13),
    A_Spare_13_unit_name: emptyToDoubleQuotation(report.A_Spare_13_unit_name),
    A_Spare_14: emptyToDoubleQuotation(report.A_Spare_14),
    A_Spare_14_unit_name: emptyToDoubleQuotation(report.A_Spare_14_unit_name),
    A_Spare_15: emptyToDoubleQuotation(report.A_Spare_15),
    A_Spare_15_unit_name: emptyToDoubleQuotation(report.A_Spare_15_unit_name),
    A_Spare_16: emptyToDoubleQuotation(report.A_Spare_16),
    A_Spare_16_unit_name: emptyToDoubleQuotation(report.A_Spare_16_unit_name),
    A_Spare_17: emptyToDoubleQuotation(report.A_Spare_17),
    A_Spare_17_unit_name: emptyToDoubleQuotation(report.A_Spare_17_unit_name),
    A_Spare_18: emptyToDoubleQuotation(report.A_Spare_18),
    A_Spare_18_unit_name: emptyToDoubleQuotation(report.A_Spare_18_unit_name),
    A_Spare_19: emptyToDoubleQuotation(report.A_Spare_19),
    A_Spare_19_unit_name: emptyToDoubleQuotation(report.A_Spare_19_unit_name),
    A_Spare_20: emptyToDoubleQuotation(report.A_Spare_20),
    A_Spare_20_unit_name: emptyToDoubleQuotation(report.A_Spare_20_unit_name),
  };
};

export const mapToArrivalMutationVariables = (
  form: Form,
  ship_id: number,
  report_id?: number
): ArrivalInput => {
  const {
    A_Vessel_Name,
    A_Voy,
    A_LorB,
    A_Arrival_Port,
    A_EU_UK_port,
    A_Port_L_U,
    A_UNLOC,
    A_Arrival_Date_Time,
    A_SB_or_Stop,
    A_TimeZone_PorN,
    A_TimeZone_HH,
    A_TimeZone_MM,
    A_ETB,
    A_ETC,
    A_ETD,
    A_Berthed,
    A_ROB_at_Arrival_FO,
    A_ROB_at_Arrival_MGO,
    A_ROB_at_Arrival_MECO,
    A_ROB_at_Arrival_MESO,
    A_ROB_at_Arrival_GESO,
    A_ROB_at_Arrival_FW,
    A_Hrs_since_Last_Noon,
    A_Distance_by_OG,
    A_FO_Consumption_per_day_ME,
    A_FO_Consumption_per_day_GE,
    A_FO_Consumption_per_day_B_and_Inc,
    A_MGO_Consumption_per_day_ME,
    A_MGO_Consumption_per_day_GE,
    A_MGO_Consumption_per_day_B_and_Inc,
    A_ROB_FO,
    A_ROB_MGO,
    A_ROB_MECO,
    A_ROB_MESO,
    A_ROB_GESO,
    A_ROB_FW,
    A_Draft_Fore,
    A_Draft_Aft,
    A_FO_CO2_emission,
    A_MGO_CO2_emission,
    A_Remark,
    A_Spare_1,
    A_Spare_1_unit_name,
    A_Spare_2,
    A_Spare_2_unit_name,
    A_Spare_3,
    A_Spare_3_unit_name,
    A_Spare_4,
    A_Spare_4_unit_name,
    A_Spare_5,
    A_Spare_5_unit_name,
    A_Spare_6,
    A_Spare_6_unit_name,
    A_Spare_7,
    A_Spare_7_unit_name,
    A_Spare_8,
    A_Spare_8_unit_name,
    A_Spare_9,
    A_Spare_9_unit_name,
    A_Spare_10,
    A_Spare_10_unit_name,
    A_Spare_11,
    A_Spare_11_unit_name,
    A_Spare_12,
    A_Spare_12_unit_name,
    A_Spare_13,
    A_Spare_13_unit_name,
    A_Spare_14,
    A_Spare_14_unit_name,
    A_Spare_15,
    A_Spare_15_unit_name,
    A_Spare_16,
    A_Spare_16_unit_name,
    A_Spare_17,
    A_Spare_17_unit_name,
    A_Spare_18,
    A_Spare_18_unit_name,
    A_Spare_19,
    A_Spare_19_unit_name,
    A_Spare_20,
    A_Spare_20_unit_name,
  } = form;

  const A_Time_Zone =
    (A_TimeZone_PorN || "") + A_TimeZone_HH + ":" + A_TimeZone_MM;

  return {
    id: report_id || 0,
    ship_id,
    A_Vessel_Name: A_Vessel_Name || "",
    A_Voy: A_Voy || "",
    A_LorB: A_LorB || LorB.Ballast,
    A_Arrival_Port: A_Arrival_Port || "",
    A_UNLOC: A_UNLOC || "",
    A_EU_UK_port: A_EU_UK_port,
    A_Port_L_U: A_Port_L_U,
    A_Arrival_Date_Time: A_Arrival_Date_Time || new Date(),
    A_SB_or_Stop: A_SB_or_Stop || new Date(),
    A_Time_Zone: A_TimeZone_MM ? A_Time_Zone : "",
    A_ETB: A_ETB || new Date(),
    A_ETC: A_ETC || new Date(),
    A_ETD: A_ETD || new Date(),
    A_Berthed: A_Berthed || new Date(),
    A_ROB_at_Arrival_FO: A_ROB_at_Arrival_FO || 0,
    A_ROB_at_Arrival_MGO: A_ROB_at_Arrival_MGO || 0,
    A_ROB_at_Arrival_MECO: A_ROB_at_Arrival_MECO || 0,
    A_ROB_at_Arrival_MESO: A_ROB_at_Arrival_MESO || 0,
    A_ROB_at_Arrival_GESO: A_ROB_at_Arrival_GESO || 0,
    A_ROB_at_Arrival_FW: A_ROB_at_Arrival_FW || 0,
    A_Hrs_since_Last_Noon: A_Hrs_since_Last_Noon || 0,
    A_Distance_by_OG: A_Distance_by_OG || 0,
    A_FO_Consumption_per_day_ME: A_FO_Consumption_per_day_ME || 0,
    A_FO_Consumption_per_day_GE: A_FO_Consumption_per_day_GE || 0,
    A_FO_Consumption_per_day_B_and_Inc: A_FO_Consumption_per_day_B_and_Inc || 0,
    A_MGO_Consumption_per_day_ME: A_MGO_Consumption_per_day_ME || 0,
    A_MGO_Consumption_per_day_GE: A_MGO_Consumption_per_day_GE || 0,
    A_MGO_Consumption_per_day_B_and_Inc:
      A_MGO_Consumption_per_day_B_and_Inc || 0,
    A_ROB_FO: A_ROB_FO || 0,
    A_ROB_MGO: A_ROB_MGO || 0,
    A_ROB_MECO: A_ROB_MECO || 0,
    A_ROB_MESO: A_ROB_MESO || 0,
    A_ROB_GESO: A_ROB_GESO || 0,
    A_ROB_FW: A_ROB_FW || 0,
    A_Draft_Fore: A_Draft_Fore || 0,
    A_Draft_Aft: A_Draft_Aft || 0,
    A_FO_CO2_emission: A_FO_CO2_emission || 0,
    A_MGO_CO2_emission: A_MGO_CO2_emission || 0,
    A_Remark: A_Remark || "",
    A_Additional_ROB: filterArrUndefinedNull(
      form.A_Additional_ROB?.filter((v) => v.A_Fuel_volume !== undefined).map(
        (v) => {
          return {
            ...v,
            id: v.id || 0,
            A_Fuel_volume: v.A_Fuel_volume || 0,
            A_Fuel_Type: v.A_Fuel_Type,
          };
        }
      )
    ) as AdditionalRobForArrivalInput[],
    A_Spare_1,
    A_Spare_1_unit_name,
    A_Spare_2,
    A_Spare_2_unit_name,
    A_Spare_3,
    A_Spare_3_unit_name,
    A_Spare_4,
    A_Spare_4_unit_name,
    A_Spare_5,
    A_Spare_5_unit_name,
    A_Spare_6,
    A_Spare_6_unit_name,
    A_Spare_7,
    A_Spare_7_unit_name,
    A_Spare_8,
    A_Spare_8_unit_name,
    A_Spare_9,
    A_Spare_9_unit_name,
    A_Spare_10,
    A_Spare_10_unit_name,
    A_Spare_11,
    A_Spare_11_unit_name,
    A_Spare_12,
    A_Spare_12_unit_name,
    A_Spare_13,
    A_Spare_13_unit_name,
    A_Spare_14,
    A_Spare_14_unit_name,
    A_Spare_15,
    A_Spare_15_unit_name,
    A_Spare_16,
    A_Spare_16_unit_name,
    A_Spare_17,
    A_Spare_17_unit_name,
    A_Spare_18,
    A_Spare_18_unit_name,
    A_Spare_19,
    A_Spare_19_unit_name,
    A_Spare_20,
    A_Spare_20_unit_name,
  };
};
