import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { db } from "../../../../../dexie/db";
import Default, { useShip } from "../../../../layouts/DefaultCrew";
import Template from "../../../../templates/Crew/Bunkering/Update/Confirm";
import { Form, LocationState } from "../Create/types";
import { useLiveQuery } from "dexie-react-hooks";
import { useGetPreviousReport } from "../../../../../hooks/dexie/dexie";
import { BunkeringDexie } from "../../../../../dexie/Bunkering";
import { mapToUpdateBunkeringMutationVariables } from "../bunkering.module";

export const useGetPreviousBunkering = (reportId: number) => {
  const bunkeringList = useLiveQuery(
    () => db.bunkering.toArray().catch((e) => console.error(e)),
    []
  );
  if (
    !bunkeringList ||
    bunkeringList.length === 0 ||
    !bunkeringList[bunkeringList.length - 1]
  ) {
    return;
  }
  const bunkering = bunkeringList.findIndex((v) => v.id === reportId);

  const previousBunkering = bunkeringList[bunkering - 1];

  return previousBunkering;
};

type LocationForm = {
  form: Form;
};

const Confirm = (props: LocationState): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const ship = useShip();
  const navigate = useNavigate();

  if (reportId === undefined) navigate("/");

  const report_id = Number(reportId);

  const previousReport = useGetPreviousReport(report_id);

  const location = useLocation();

  const location_form = location.state as LocationForm;

  if (!props.form && !location_form.form) {
    navigate(-1);
  }

  const form = props.form || location_form.form;

  const onUpdate = async () => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToUpdateBunkeringMutationVariables(
      form,
      ship.ship_id,
      report_id
    );

    const updateBunkering: BunkeringDexie = {
      id: report_id,
      isDraft: false,
      isSend: false,
      data: variables,
    };
    db.bunkering.update(Number(reportId), updateBunkering);
    navigate("/bunkering/create/complete");
  };

  const onBack = () => {
    navigate("/crew/bunkering");
  };

  return (
    <Default title="" selectedTitle={"Bunkering"}>
      <Template
        form={location_form.form}
        isLoading={false}
        onUpdate={onUpdate}
        onBack={onBack}
        reportId={report_id}
        previousReport={previousReport}
      />
    </Default>
  );
};

export default Confirm;
