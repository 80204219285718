import { Typography } from "@mui/material";
import Column from "../../atoms/Column";
import CommonNumberFIeld from "../../atoms/CommonNumberFIeld";
import { Form } from "../../pages/Crew/NoonReport/Create/types";
import RadioButton from "../../atoms/RadioButton";
import { ReportDexie } from "../../../dexie/Report";
import { getLatLongValues } from "../../../utils/form-util";
import { calcDistance } from "../../../utils/calcDistance";
import { calculateNoonTimeZone } from "../../organisms/Crew/NoonReport/calculateFunctions/calculateNoonValues";

type LatLongProps = {
  form: Form;
  defaultLatLong?: string;
  lastReport: ReportDexie | undefined;
  convertToNumberOrNull: (value: string | null) => number | null;
  isConfirm: boolean;
  isPreview: boolean;
  isServer?: boolean;
  onChange: (
    key: string,
    value: string | number | Date | null | undefined
  ) => void;
  onUpdateForm: (value: Form) => void;
};

const valueCheck = (form: Form, lastReport: ReportDexie | undefined) => {
  if (
    lastReport == null ||
    form.N_Long_Deg == null ||
    form.N_Long_Min == null ||
    form.N_Long_Direction == null ||
    form.N_Lat_Deg == null ||
    form.N_Lat_Min == null ||
    form.N_Lat_Direction == null ||
    form.N_DateTime == null
  ) {
    return {
      isError: false,
      errorMessage: "",
    };
  } else {
    const lastPosition = getLatLongValues(lastReport.data.N_LatLong);
    const from = {
      longitude: {
        deg: Number(lastPosition.Long_Deg),
        min: Number(lastPosition.Long_Min),
        direction: lastPosition.Long_Direction,
      },
      latitude: {
        deg: Number(lastPosition.Lat_Deg),
        min: Number(lastPosition.Lat_Min),
        direction: lastPosition.Lat_Direction,
      },
    };
    const to = {
      longitude: {
        deg: Number(form.N_Long_Deg),
        min: Number(form.N_Long_Min),
        direction: form.N_Long_Direction,
      },
      latitude: {
        deg: Number(form.N_Lat_Deg),
        min: Number(form.N_Lat_Min),
        direction: form.N_Lat_Direction,
      },
    };
    const distance = calcDistance(from, to);
    const periodMilliSecond =
      form.N_DateTime.getTime() - lastReport.data.N_DateTime.getTime();

    if (periodMilliSecond <= 0) {
      return {
        isError: false,
        errorMessage: "",
      };
    }

    // 1日に1500km以上進んでいたらエラー
    if ((distance / periodMilliSecond) * 1000 * 60 * 60 * 24 > 1500) {
      return {
        isError: true,
        errorMessage: "Too fast movement.",
      };
    } else {
      return {
        isError: false,
        errorMessage: "",
      };
    }
  }
};

export const LatLongField = (props: LatLongProps): React.ReactElement => {
  const { isError, errorMessage } = valueCheck(props.form, props.lastReport);

  const onUpdateWithTimezone = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    if (!props.isServer) {
      const form = calculateNoonTimeZone({
        form: { ...props.form, [key]: value },
      });

      props.onUpdateForm({
        ...form,
        [key]: value,
      });
      return;
    }
    props.onChange(key, value);
  };

  return (
    <>
      <Column sx={{ pr: -2, mr: -4 }}>
        <Typography
          sx={{
            pl: 1,
            mb: 1.5,
            textAlign: "left",
            verticalAlign: "top",
            fontSize: "16px",
            fontFamily: "Public Sans",
            lineHeight: "auto",
            color: "#AAAAAA",
          }}
        >
          Lat./ Lon.
        </Typography>
        <Typography
          sx={{
            pl: 1,
            mb: 2.5,
            textAlign: "left",
            fontSize: "16px",
            fontFamily: "Public Sans",
            lineHeight: "auto",
            color: "#AAAAAA",
          }}
        >
          Lat.
        </Typography>
      </Column>
      <Column sx={{ pl: -2 }}>
        <Typography
          sx={{
            pb: 2,
            textAlign: "left",
            verticalAlign: "top",
            fontSize: "16px",
            fontFamily: "Public Sans",
            lineHeight: "auto",
            color: "#AAAAAA",
          }}
        ></Typography>
        <CommonNumberFIeld
          isConfirm={props.isConfirm}
          disabled={props.isPreview}
          id="N_Lat_Deg"
          label="Deg."
          placeholder=""
          value={props.convertToNumberOrNull(props.form.N_Lat_Deg)}
          maxValue={90}
          minValue={0}
          sx={{ width: "80px" }}
          onChange={(e) => {
            props.onChange("N_Lat_Deg", e);
          }}
          errorMessage={isError ? errorMessage : undefined}
        />
      </Column>
      <Column sx={{ pl: -15 }}>
        <Typography sx={{ color: "#AAAAAA", pt: 2 }}>：</Typography>
      </Column>
      <Column sx={{ pl: -15 }}>
        <Typography
          sx={{
            pb: 2,
            textAlign: "left",
            verticalAlign: "top",
            fontSize: "16px",
            fontFamily: "Public Sans",
            lineHeight: "auto",
            color: "#AAAAAA",
          }}
        ></Typography>
        <CommonNumberFIeld
          isConfirm={props.isConfirm}
          disabled={props.isPreview}
          id="N_Lat_Min"
          label="Min."
          placeholder=""
          value={props.convertToNumberOrNull(props.form.N_Lat_Min)}
          maxValue={60}
          minValue={0}
          sx={{ width: "80px" }}
          onChange={(e) => {
            props.onChange("N_Lat_Min", e);
          }}
          errorMessage={isError ? errorMessage : undefined}
        />
      </Column>
      <Column sx={{ mt: 2 }}>
        <RadioButton
          id="N_Lat_Direction"
          label=" "
          label1="North"
          label2="South"
          defaultValue={
            props.defaultLatLong
              ? getLatLongValues(props.defaultLatLong).Lat_Direction
              : "N"
          }
          value1="N"
          value2="S"
          onChange={props.onChange}
        />
      </Column>
      <Column>
        <Typography
          sx={{
            pl: 2,
            mb: 2,
            textAlign: "left",
            verticalAlign: "top",
            fontSize: "16px",
            fontFamily: "Public Sans",
            lineHeight: "auto",
            color: "#AAAAAA",
          }}
        ></Typography>
        <Typography
          sx={{
            pt: 1,
            pb: 1,
            textAlign: "left",
            fontSize: "16px",
            fontFamily: "Public Sans",
            lineHeight: "auto",
            color: "#AAAAAA",
          }}
        >
          Lon.
        </Typography>
      </Column>
      <Column>
        <Typography
          sx={{
            pb: 2,
            textAlign: "left",
            verticalAlign: "top",
            fontSize: "16px",
            fontFamily: "Public Sans",
            lineHeight: "auto",
            color: "#AAAAAA",
          }}
        ></Typography>
        <CommonNumberFIeld
          isConfirm={props.isConfirm}
          disabled={props.isPreview}
          id="N_Long_Deg"
          label="Deg."
          placeholder=""
          value={props.convertToNumberOrNull(props.form.N_Long_Deg)}
          maxValue={180}
          minValue={0}
          sx={{ width: "90px" }}
          onChange={(e) => onUpdateWithTimezone("N_Long_Deg", e)}
          errorMessage={isError ? errorMessage : undefined}
        />
      </Column>
      <Column sx={{ pl: -15 }}>
        <Typography sx={{ color: "#AAAAAA", pt: 2 }}>：</Typography>
      </Column>
      <Column sx={{ pl: -15 }}>
        <Typography
          sx={{
            pb: 2,
            textAlign: "left",
            verticalAlign: "top",
            fontSize: "16px",
            fontFamily: "Public Sans",
            lineHeight: "auto",
            color: "#AAAAAA",
          }}
        ></Typography>
        <CommonNumberFIeld
          isConfirm={props.isConfirm}
          disabled={props.isPreview}
          id="N_Long_Min"
          label="Min."
          placeholder=""
          value={props.convertToNumberOrNull(props.form.N_Long_Min)}
          maxValue={60}
          minValue={0}
          sx={{ width: "80px" }}
          onChange={(e) => onUpdateWithTimezone("N_Long_Min", e)}
          errorMessage={isError ? errorMessage : undefined}
        />
      </Column>
      <Column sx={{ mt: 2 }}>
        <RadioButton
          id="N_Long_Direction"
          defaultValue={
            props.defaultLatLong
              ? getLatLongValues(props.defaultLatLong).Long_Direction
              : "E"
          }
          label=" "
          label1="East"
          label2="West"
          value1="E"
          value2="W"
          onChange={(e, value) => onUpdateWithTimezone(e, value)}
        />
      </Column>
    </>
  );
};
