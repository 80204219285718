import dayjs from "dayjs";
import {
  DepartureListInput,
  DepartureListQuery,
  useDepartureListLazyQuery,
} from "../../gen/graphql/types";
import { useMemo } from "react";
import { Departure } from "../../components/pages/Crew/Departure/Create/types";
import { ReportSearchCondition } from "../../components/pages/pages.module";

export const getDepartureList = (reports?: DepartureListQuery): Departure[] => {
  const reportList = (reports?.departureList?.departureList || [])
    .map((departure): Departure | null => departure)
    .filter((v): v is NonNullable<typeof v> => v != null)
    .sort((a, b) =>
      dayjs(b.D_Departure_Date_Time).diff(dayjs(a.D_Departure_Date_Time))
    );

  return reportList;
};

export const mapDepartureListInput = (
  searchCondition: ReportSearchCondition
): DepartureListInput => {
  return {
    ship_id: searchCondition?.ship_id || null,
    IMO_No: searchCondition.imo_no || null,
    D_Voy: searchCondition?.Voy,
    From_DateTime: searchCondition?.From_DateTime,
    To_DateTime: searchCondition?.To_DateTime,
    pagination: searchCondition.pagination,
  };
};

export const useDepartures = () => {
  const [getData, { data: response, loading: isLoading }] =
    useDepartureListLazyQuery();

  const data = useMemo(() => {
    const totalPageCount = response?.departureList?.totalPageCount || 0;
    const departureList = getDepartureList(response);

    return { totalPageCount, departureList: departureList };
  }, [response]);

  const refetch = (searchCondition: ReportSearchCondition) => {
    const inputdata = mapDepartureListInput(searchCondition);
    getData({
      variables: {
        input: {
          ...inputdata,
          pagination: {
            page: inputdata.pagination?.page,
            limit: inputdata.pagination?.limit,
          },
        },
      },
    });
  };

  return {
    isLoading,
    data,
    refetch,
  };
};
