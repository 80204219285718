import { useCreateFrontendShipDataMutation, useGetShipDataLazyQuery } from './../../../gen/graphql/types';
import { useEffect } from "react";
import { useGlobalLoadContext } from "../../../context/LoadProvider";
import { GetShipDataQuery } from "../../../gen/graphql/types";
import { useShip } from "../DefaultCrew";
import { mapToShip } from "../../pages/Crew/SelectShips/SelectShips";
import { Ship } from "../../pages/Crew/SelectShips/type";
import { db } from "../../../dexie/db";

const getShip = (shipList?: GetShipDataQuery): Ship => {
  const reportList = (shipList?.shipList || [])
    .map((report): Ship | null => mapToShip(report))
    .filter((v): v is NonNullable<typeof v> => v != null);

  return reportList[0]
};

// TODO:: domain層に移行かつ、crew配下に配置する
// 現状、緊急対応として以下のように実装しているが、サーバー画面の誕生や、ユーザー数増加時は設計を変える
export const useCrewSyncShip = () => {
  const {...globalLoadCtx } = useGlobalLoadContext();
  const indexedDBShip = useShip();
  const [getData] = useGetShipDataLazyQuery();
  const [createFrontendShipData] = useCreateFrontendShipDataMutation();

  useEffect(() => {
    if (globalLoadCtx.isFinishedGlobalLoading) return;
    if (!indexedDBShip?.IMO_No) return;

    try {
      getData({
        variables: { filter: { imo_no: indexedDBShip?.IMO_No } },
        onCompleted: async (response) => {
          const serverShip = getShip(response);
          if (!serverShip) {
            throw new Error("Incorrect IMO_No is set.")
          }

          const lastUpdatedString = serverShip.shipMaster?.lastUpdated; // ISO 8601形式の文字列が入っている
          const lastUpdatedDate = lastUpdatedString ? new Date(lastUpdatedString) : undefined;
          if (indexedDBShip.id && indexedDBShip?.lastUpdated?.toISOString() !== lastUpdatedDate?.toISOString()) {
            await db.ship.update(indexedDBShip.id, {
              ...indexedDBShip,
              ship_id: serverShip.id,
              IMO_No: serverShip.IMO_No,
              name: serverShip.name,
              summer_deadweight: serverShip.summer_deadweight,
              propeller_pitch: serverShip.propeller_pitch,
              fo_fuel_type: serverShip.fo_fuel_type,
              type_of_ship_cii: serverShip.type_of_ship_cii,
              cargoFormSetting: serverShip.cargoFormSetting,
              is_mrv_portal_integration_target: serverShip.is_mrv_portal_integration_target,
              lastUpdated: lastUpdatedDate,
            });

            db.ship.get(indexedDBShip.id).then(updatedShip => {
              if (updatedShip) {
                const shipJsonString = JSON.stringify(updatedShip)
                createFrontendShipData({
                  variables: {
                    input: {
                      IMONO: indexedDBShip.IMO_No,
                      FrontendShipData: shipJsonString
                    },
                  },
                  onCompleted: (response) => {
                    console.log("response: ", response)
                  }
                })
              } else {
                console.log('The ship specified by id could not be obtained.');
              }
            })
          }
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      globalLoadCtx.setIsFinishedGlobalLoading(true);
    }    
  }, [indexedDBShip]); // eslint-disable-line react-hooks/exhaustive-deps
}