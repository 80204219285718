import { FilterBooleanType, ReportGraph } from "./types";
import dayjs from "dayjs";
import {
  calcAverage,
  calcStandardDeviation,
} from "../../../../utils/calculate";

export function areAllFiltersTrue(filters: FilterBooleanType): boolean {
  return (
    filters.filter1 && filters.filter2 && filters.filter3 && filters.filter4
  );
}

export function isAnyFilterTrue(filters: FilterBooleanType): boolean {
  return (
    filters.filter1 || filters.filter2 || filters.filter3 || filters.filter4
  );
}

type Period = {
  from: Date;
  to: Date;
};

export function getPastPeriods(endDate: Date, monthInterval: number): Period[] {
  const periods: Period[] = [];
  let end = dayjs(endDate);

  for (let i = 0; i < 4; i++) {
    const start = end.subtract(monthInterval, "month");
    periods.push({
      from: start.toDate(),
      to: end.toDate(),
    });
    end = start;
  }

  return periods;
}

//zスコア
const zScoreFor95Confidence = 1.96;

export function filterByStandardDeviation(
  reports: ReportGraph[]
): ReportGraph[] {
  if (reports.length === 0) return [];

  //平均値と標準偏差を計算
  const values = reports.map((v) => v.N_FO_Consumption_per_day);
  const FOCAverage = calcAverage(values);
  const stdDeviation = calcStandardDeviation(values);

  // 95%信頼区間を計算
  const lowerBound = FOCAverage - zScoreFor95Confidence * stdDeviation;
  const upperBound = FOCAverage + zScoreFor95Confidence * stdDeviation;

  // 95%信頼区間内のデータのみを残す
  const filteredGraph = reports.filter(
    (v) =>
      v.N_FO_Consumption_per_day >= lowerBound &&
      v.N_FO_Consumption_per_day <= upperBound
  );

  return filteredGraph;
}
