import * as React from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import FormBlock from "../../../../organisms/Crew/Cargo/FormBlock";
import { CargoForm } from "../../../../pages/Crew/Cargo/type";
import { useNavigate } from "react-router";
import { pageData } from "../../../../../App";

type ConfirmProps = {
  hasFormError: boolean;
  onUpdateForm(value: CargoForm): void;
  onConfirm(): void;
  onSaveDraft: (value: CargoForm) => void;
  setIsDetail: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  form: CargoForm;
  isConfirm: boolean;
};

const CargoUpdate = (props: ConfirmProps): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Paper
        sx={{
          backgroundColor: "#27292E",
          borderRadius: "20px",
          width: "1240px",
          overflowX: "hidden",
          ".container::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <FormBlock
          title={"Update Cargo Report"}
          isConfirm={props.isConfirm}
          onUpdateForm={props.onUpdateForm}
          form={props.form}
          setFile={props.setFile}
          isPreview={false}
        />

        <Box
          sx={{
            textAlign: "left",
            px: 2,
            py: 4,
          }}
        >
          <Button
            sx={{
              mx: 2,
              width: "250",
              backgroundColor: "#20BE89",
              fontFamily: "Public Sans",
              "&.MuiButton-root": {
                fontFamily: "Public Sans",
              },
              textTransform: "none",
            }}
            variant="contained"
            onClick={() => props.onConfirm()}
          >
            Confirm
          </Button>
          <Button
            sx={{
              mx: 2,
              backgroundColor: "#555860",
              color: "#20BE89",
              textTransform: "none",
            }}
            variant="contained"
            onClick={() => props.onSaveDraft(props.form)}
          >
            Save Draft
          </Button>
          <Button
            sx={{ mx: 2, backgroundColor: "#555860 ", textTransform: "none" }}
            variant="contained"
            onClick={() => navigate(pageData.crewCargo)}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default CargoUpdate;
