import React, { useEffect, useState } from "react";
import { ReportInput } from "../../../../gen/graphql/types";
import Template from "../../../templates/Crew/NoonReport/Reports";
import { Report } from "./Create/types";
import { emptyToHyphen } from "../../../../utils/formats";
import { db } from "../../../../dexie/db";
import { useLiveQuery } from "dexie-react-hooks";
import {
  useContextIDs,
  useContextReportSearchCondition,
} from "../../../../App";
import { ReportDexie } from "../../../../dexie/Report";
import { useReports } from "../../../../hooks/commonQuery/noonReport.hook";
import dayjs from "dayjs";
import { useCrewSearchForm } from "../crew.module";
import { ReportSearchCondition } from "../../pages.module";

export const mapToReport = (report?: Report | null): Report | null => {
  if (!report?.id) {
    return null;
  }
  const id = report?.id;
  const N_Vessel_name = emptyToHyphen(report?.N_Vessel_name);
  const N_Voy = emptyToHyphen(report?.N_Voy);
  const N_LorB = report?.N_LorB;
  const N_Noon_Port = report?.N_Noon_Port;
  const N_UNLOC = report?.N_UNLOC;
  const N_EU_UK_port = report?.N_EU_UK_port;
  const N_Port_L_U = report?.N_Port_L_U;
  const N_DateTime = report?.N_DateTime;
  const N_TimeZone = emptyToHyphen(report?.N_TimeZone);
  const N_LatLong = emptyToHyphen(report?.N_LatLong);
  const N_Steam_mode = emptyToHyphen(report?.N_Steam_mode);
  const N_Hrs_propelling = report?.N_Hrs_propelling;
  const N_Dist_OG = report?.N_Dist_OG;
  const N_Speed_OG = report?.N_Speed_OG;
  const N_Speed_OW = report?.N_Speed_OW;
  const N_ME_rpm_ave = report?.N_ME_rpm_ave;
  const N_ME_Revolution = report?.N_ME_Revolution;
  const N_Slip = report?.N_Slip;
  const N_Weather = report?.N_Weather;
  const N_Wind_direction = report?.N_Wind_direction;
  const N_BF_scale = report?.N_BF_scale;
  const N_Swell = report?.N_Swell;
  const N_Noon_Course = report?.N_Noon_Course;
  const N_Dist_to_go = report?.N_Dist_to_go;
  const N_Next_port = report?.N_Next_port;
  const N_ETA = report?.N_ETA;
  const N_ETB = report?.N_ETB;
  const N_ETD = report?.N_ETD;
  const N_ME_Fuel_index = report?.N_ME_Fuel_index;
  const N_ME_RPM = report?.N_ME_RPM;
  const N_ME_horsepower = report?.N_ME_horsepower;
  const N_ME_power = report?.N_ME_power;
  const N_ME_power_measurement_method = report?.N_ME_power_measurement_method;
  const N_Running_hours_SOx_scrubber = report?.N_Running_hours_SOx_scrubber;
  const N_Running_hours_ME_EGR = report?.N_Running_hours_ME_EGR;
  const N_Running_hours_GE_SCR = report?.N_Running_hours_GE_SCR;
  const N_ROB_FO = report?.N_ROB_FO;
  const N_ROB_MGO = report?.N_ROB_MGO;
  const N_ROB_MECO = report?.N_ROB_MECO;
  const N_ROB_MESO = report?.N_ROB_MESO;
  const N_ROB_GESO = report?.N_ROB_GESO;
  const N_ROB_FW = report?.N_ROB_FW;
  const N_FO_CO2_emission_per_unit = report?.N_FO_CO2_emission_per_unit;
  const N_MGO_CO2_emission_per_unit = report?.N_MGO_CO2_emission_per_unit;
  const N_FO_CO2_emission = report?.N_FO_CO2_emission;
  const N_MGO_CO2_emission = report?.N_MGO_CO2_emission;
  const N_Remark = report?.N_Remark;
  const ship_id = report?.ship_id;

  return {
    id,
    N_Vessel_name,
    N_Voy,
    N_LorB,
    N_Noon_Port,
    N_UNLOC,
    N_EU_UK_port,
    N_Port_L_U,
    N_DateTime,
    N_TimeZone,
    N_LatLong,
    N_Steam_mode,
    N_Hrs_propelling,
    N_Dist_OG,
    N_Speed_OG,
    N_Speed_OW,
    N_ME_rpm_ave,
    N_ME_Revolution,
    N_Slip,
    N_Fuelconsumption: report.N_Fuelconsumption,
    N_Weather,
    N_Wind_direction,
    N_BF_scale,
    N_Swell,
    N_Noon_Course,
    N_Dist_to_go,
    N_Next_port,
    N_ETA,
    N_ETB,
    N_ETD,
    N_ME_Fuel_index,
    N_ME_RPM,
    N_ME_horsepower,
    N_ME_power,
    N_ME_power_measurement_method,
    N_Running_hours_SOx_scrubber,
    N_Running_hours_ME_EGR,
    N_Running_hours_GE_SCR,
    N_ROB_FO,
    N_ROB_MGO,
    N_ROB_MECO,
    N_ROB_MESO,
    N_ROB_GESO,
    N_ROB_FW,
    N_FO_CO2_emission_per_unit,
    N_MGO_CO2_emission_per_unit,
    N_FO_CO2_emission,
    N_MGO_CO2_emission,
    N_Remark,
    ship_id,
  };
};

export type Pagination = {
  page: number;
  limit: number;
};

export const mapToReportVariables = (report: Report): ReportInput => {
  return {
    ship_id: 0,
    N_Vessel_name: report.N_Vessel_name,
    N_Voy: report.N_Voy,
    N_LorB: report.N_LorB,
    N_Noon_Port: report.N_Noon_Port,
    N_UNLOC: report.N_UNLOC,
    N_EU_UK_port: report.N_EU_UK_port,
    N_Port_L_U: report.N_Port_L_U,
    N_DateTime: report.N_DateTime,
    N_TimeZone: report.N_TimeZone,
    N_LatLong: report.N_LatLong,
    N_Steam_mode: "",
    N_Hrs_propelling: 0,
    N_Dist_OG: 0,
    N_Speed_OG: 0,
    N_Speed_OW: 0,
    N_ME_rpm_ave: 0,
    N_ME_Revolution: 0,
    N_Slip: 0,
    N_Weather: undefined,
    N_Wind_direction: undefined,
    N_BF_scale: undefined,
    N_Swell: undefined,
    N_Noon_Course: undefined,
    N_Dist_to_go: undefined,
    N_Next_port: undefined,
    N_ETA: undefined,
    N_ETB: undefined,
    N_ETD: undefined,
    N_ME_Fuel_index: 0,
    N_ME_RPM: 0,
    N_ME_horsepower: 0,
    N_ME_power: 0,
    N_ME_power_measurement_method: report.N_ME_power_measurement_method,
    N_Running_hours_SOx_scrubber: 0,
    N_Running_hours_ME_EGR: 0,
    N_Running_hours_GE_SCR: 0,
    N_ROB_FO: 0,
    N_ROB_MGO: 0,
    N_ROB_MECO: 0,
    N_ROB_MESO: 0,
    N_ROB_GESO: 0,
    N_ROB_FW: 0,
    N_FO_CO2_emission_per_unit: 0,
    N_MGO_CO2_emission_per_unit: 0,
    N_FO_CO2_emission: 0,
    N_MGO_CO2_emission: 0,
    N_FO_Consumption_per_day_BInc: 0,
    N_FO_Consumption_per_day_GE: 0,
    N_FO_Consumption_per_day_ME: 0,
    N_LO_Consumption_per_day_GESO: 0,
    N_LO_Consumption_per_day_MECO: 0,
    N_LO_Consumption_per_day_MESO: 0,
    N_MGO_Consumption_per_day_Boiler: 0,
    N_MGO_Consumption_per_day_GE: 0,
    N_MGO_Consumption_per_day_ME: 0,
    N_Remark: undefined,
  };
};

const Reports = (): React.ReactElement => {
  const frontDbReports = useLiveQuery(() => db.reports.toArray(), [])?.sort(
    (a, b) => dayjs(b.data.N_DateTime).diff(dayjs(a.data.N_DateTime))
  );

  const { reportSearchCondition, setReportSearchCondition } =
    useContextReportSearchCondition();

  const { ship_id } = useContextIDs();
  const form = useCrewSearchForm(ship_id, reportSearchCondition);
  const reports = useReports();
  const [isServerData, setIsServerData] = useState(false);

  const onUpdateForm = (value: ReportSearchCondition) => {
    form.update(value);
  };

  const onUpdateSearchCondition = (
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ) => {
    form.update(value);
    if (!isWithRefresh) {
      return;
    }

    reports.refetch(form.data);
  };

  const deleteReport = (id: number) => {
    db.reports.delete(id).catch((e) => console.error(e));
  };

  const onSearch = () => {
    form.data.pagination.page = 1;

    form.data.ship_id = ship_id;

    if (!form.data.Voy && !form.data.From_DateTime && !form.data.To_DateTime) {
      setIsServerData(false);
    } else {
      reports.refetch(form.data);
      setReportSearchCondition(form.data);
      setIsServerData(true);
    }
  };

  const serverReportList: ReportDexie[] = reports.data.reportList.map(
    (report) => {
      const variavle = mapToReportVariables(report);
      return {
        id: undefined,
        isDraft: false,
        isSend: true,
        reportId: report.id,
        data: variavle,
      };
    }
  );

  useEffect(() => {
    if (
      serverReportList.length > 0 &&
      !reports.isLoading &&
      (form.data.Voy || form.data.From_DateTime || form.data.To_DateTime)
    ) {
      setIsServerData(true);
    }
  }, [
    form.data.From_DateTime,
    form.data.To_DateTime,
    form.data.Voy,
    reports.isLoading,
    serverReportList.length,
  ]);

  return (
    <Template
      reports={isServerData ? serverReportList : frontDbReports || []}
      deleteReport={deleteReport}
      isServerData={isServerData}
      onUpdateForm={onUpdateForm}
      form={form.data}
      onSearch={onSearch}
      onUpdateSearchCondition={onUpdateSearchCondition}
      totalPageCount={reports.data.totalPageCount}
    />
  );
};

export default Reports;
