import React from "react";
import { FocForm } from "../../../../../pages/Owner/Analysis/Setting/Create/types";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import SettingTableCell from "../component/SettingTableCell";
import SettingNumberField from "../component/SettingNumberField";
import { BaselineTitleList } from "../../../../../pages/Owner/Analysis/const";

type FormBlockProps = {
  isPreview?: boolean;
  onChange: (key: string, value: string | number | null | undefined) => void;
  form: FocForm;
};

const FormBlock = (props: FormBlockProps): React.ReactElement => {
  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <SettingTableCell
              sx={{
                width: "240px",
                pr: 0,
                pl: 1,
                borderTop: "1px solid #C9D0DC",
                fontSize: 16,
              }}
            >
              {"Fuel Consumption (mt/day)"}
            </SettingTableCell>
            <SettingTableCell
              colSpan={1}
              sx={{ borderTop: "1px solid #C9D0DC" }}
            ></SettingTableCell>
            <SettingTableCell
              colSpan={2}
              sx={{ width: "1000px", borderTop: "1px solid #C9D0DC" }}
            >
              <SettingNumberField
                id={"foc_1"}
                label={""}
                value={props.form.foc_1}
                onChange={(e) => {
                  props.onChange("foc_1", e);
                }}
              />
              <SettingNumberField
                id={"foc_2"}
                label={""}
                value={props.form.foc_2}
                onChange={(e) => {
                  props.onChange("foc_2", e);
                }}
              />
              <SettingNumberField
                id={"foc_3"}
                label={""}
                value={props.form.foc_3}
                onChange={(e) => {
                  props.onChange("foc_3", e);
                }}
              />
              <SettingNumberField
                id={"foc_4"}
                label={""}
                value={props.form.foc_4}
                onChange={(e) => {
                  props.onChange("foc_4", e);
                }}
              />
              <SettingNumberField
                id={"foc_5"}
                label={""}
                value={props.form.foc_5}
                onChange={(e) => {
                  props.onChange("foc_5", e);
                }}
              />
              <SettingNumberField
                id={"foc_6"}
                label={""}
                value={props.form.foc_6}
                onChange={(e) => {
                  props.onChange("foc_6", e);
                }}
              />
              <SettingNumberField
                id={"foc_7"}
                label={""}
                value={props.form.foc_7}
                onChange={(e) => {
                  props.onChange("foc_7", e);
                }}
              />
              <SettingNumberField
                id={"foc_8"}
                label={""}
                value={props.form.foc_8}
                onChange={(e) => {
                  props.onChange("foc_8", e);
                }}
              />
              <SettingNumberField
                id={"foc_9"}
                label={""}
                value={props.form.foc_9}
                onChange={(e) => {
                  props.onChange("foc_9", e);
                }}
              />
              <SettingNumberField
                id={"foc_10"}
                label={""}
                value={props.form.foc_10}
                onChange={(e) => {
                  props.onChange("foc_10", e);
                }}
              />
            </SettingTableCell>
          </TableRow>

          <TableRow>
            <TableCell
              rowSpan={3}
              style={{ width: 100 }}
              sx={{
                verticalAlign: "top",
                color: "#808080",
                borderBottom: "1px solid #C9D0DC",
                width: "240px",
                pr: 0,
                pl: 1,
                fontSize: 16,
              }}
            >
              {"Speed OG (Avg.)"}
            </TableCell>
            <SettingTableCell
              colSpan={1}
              sx={{ pl: 2, pr: 0 }}
              style={{ width: 1 }}
            >
              <span style={{ color: "#808080" }}>
                {BaselineTitleList.Ballast}
              </span>
            </SettingTableCell>
            <SettingTableCell
              align="left"
              colSpan={1}
              style={{ width: 1000000 }}
              sx={{ width: "1000px !important" }}
            >
              <SettingNumberField
                id={"empty_1"}
                label={""}
                value={props.form.empty_1}
                onChange={(e) => {
                  props.onChange("empty_1", e);
                }}
              />
              <SettingNumberField
                id={"empty_2"}
                label={""}
                value={props.form.empty_2}
                onChange={(e) => {
                  props.onChange("empty_2", e);
                }}
              />
              <SettingNumberField
                id={"empty_3"}
                label={""}
                value={props.form.empty_3}
                onChange={(e) => {
                  props.onChange("empty_3", e);
                }}
              />

              <SettingNumberField
                id={"empty_4"}
                label={""}
                value={props.form.empty_4}
                onChange={(e) => {
                  props.onChange("empty_4", e);
                }}
              />
              <SettingNumberField
                id={"empty_5"}
                label={""}
                value={props.form.empty_5}
                onChange={(e) => {
                  props.onChange("empty_5", e);
                }}
              />
              <SettingNumberField
                id={"empty_6"}
                label={""}
                value={props.form.empty_6}
                onChange={(e) => {
                  props.onChange("empty_6", e);
                }}
              />
              <SettingNumberField
                id={"empty_7"}
                label={""}
                value={props.form.empty_7}
                onChange={(e) => {
                  props.onChange("empty_7", e);
                }}
              />
              <SettingNumberField
                id={"empty_8"}
                label={""}
                value={props.form.empty_8}
                onChange={(e) => {
                  props.onChange("empty_8", e);
                }}
              />
              <SettingNumberField
                id={"empty_9"}
                label={""}
                value={props.form.empty_9}
                onChange={(e) => {
                  props.onChange("empty_9", e);
                }}
              />
              <SettingNumberField
                id={"empty_10"}
                label={""}
                value={props.form.empty_10}
                onChange={(e) => {
                  props.onChange("empty_10", e);
                }}
              />
            </SettingTableCell>
          </TableRow>
          <TableRow>
            <SettingTableCell sx={{ color: "#AAAAAA" }}>
              <span style={{ color: "#808080" }}>
                {BaselineTitleList.Laden}
              </span>
            </SettingTableCell>
            <SettingTableCell colSpan={2}>
              <SettingNumberField
                id={"laden_1"}
                label={""}
                value={props.form.laden_1}
                onChange={(e) => {
                  props.onChange("laden_1", e);
                }}
              />
              <SettingNumberField
                id={"laden_2"}
                label={""}
                value={props.form.laden_2}
                onChange={(e) => {
                  props.onChange("laden_2", e);
                }}
              />
              <SettingNumberField
                id={"laden_3"}
                label={""}
                value={props.form.laden_3}
                onChange={(e) => {
                  props.onChange("laden_3", e);
                }}
              />

              <SettingNumberField
                id={"laden_4"}
                label={""}
                value={props.form.laden_4}
                onChange={(e) => {
                  props.onChange("laden_4", e);
                }}
              />
              <SettingNumberField
                id={"laden_5"}
                label={""}
                value={props.form.laden_5}
                onChange={(e) => {
                  props.onChange("laden_5", e);
                }}
              />
              <SettingNumberField
                id={"laden_6"}
                label={""}
                value={props.form.laden_6}
                onChange={(e) => {
                  props.onChange("laden_6", e);
                }}
              />
              <SettingNumberField
                id={"laden_7"}
                label={""}
                value={props.form.laden_7}
                onChange={(e) => {
                  props.onChange("laden_7", e);
                }}
              />
              <SettingNumberField
                id={"laden_8"}
                label={""}
                value={props.form.laden_8}
                onChange={(e) => {
                  props.onChange("laden_8", e);
                }}
              />
              <SettingNumberField
                id={"laden_9"}
                label={""}
                value={props.form.laden_9}
                onChange={(e) => {
                  props.onChange("laden_9", e);
                }}
              />
              <SettingNumberField
                id={"laden_10"}
                label={""}
                value={props.form.laden_10}
                onChange={(e) => {
                  props.onChange("laden_10", e);
                }}
              />
            </SettingTableCell>
          </TableRow>
          <TableRow>
            <SettingTableCell sx={{ color: "#AAAAAA" }}>
              <span style={{ color: "#808080" }}>
                {BaselineTitleList.Unique}
              </span>
            </SettingTableCell>
            <SettingTableCell colSpan={2}>
              <SettingNumberField
                id={"unique_1"}
                label={""}
                value={props.form.unique_1}
                onChange={(e) => {
                  props.onChange("unique_1", e);
                }}
              />
              <SettingNumberField
                id={"unique_2"}
                label={""}
                value={props.form.unique_2}
                onChange={(e) => {
                  props.onChange("unique_2", e);
                }}
              />
              <SettingNumberField
                id={"unique_3"}
                label={""}
                value={props.form.unique_3}
                onChange={(e) => {
                  props.onChange("unique_3", e);
                }}
              />

              <SettingNumberField
                id={"unique_4"}
                label={""}
                value={props.form.unique_4}
                onChange={(e) => {
                  props.onChange("unique_4", e);
                }}
              />
              <SettingNumberField
                id={"unique_5"}
                label={""}
                value={props.form.unique_5}
                onChange={(e) => {
                  props.onChange("unique_5", e);
                }}
              />
              <SettingNumberField
                id={"unique_6"}
                label={""}
                value={props.form.unique_6}
                onChange={(e) => {
                  props.onChange("unique_6", e);
                }}
              />
              <SettingNumberField
                id={"unique_7"}
                label={""}
                value={props.form.unique_7}
                onChange={(e) => {
                  props.onChange("unique_7", e);
                }}
              />
              <SettingNumberField
                id={"unique_8"}
                label={""}
                value={props.form.unique_8}
                onChange={(e) => {
                  props.onChange("unique_8", e);
                }}
              />
              <SettingNumberField
                id={"unique_9"}
                label={""}
                value={props.form.unique_9}
                onChange={(e) => {
                  props.onChange("unique_9", e);
                }}
              />
              <SettingNumberField
                id={"unique_10"}
                label={""}
                value={props.form.unique_10}
                onChange={(e) => {
                  props.onChange("unique_10", e);
                }}
              />
            </SettingTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default FormBlock;
