import * as React from "react";

type Props = {
  children?: React.ReactNode;
};

const Nowrap = (props: Props): React.ReactElement => {
  return (
    <span style={{ whiteSpace: "nowrap", fontSize: 16 }}>{props.children}</span>
  );
};

export default Nowrap;
