import React from "react";
import Box from "@mui/material/Box";
import { CargoForm } from "../../../pages/Crew/Cargo/type";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import CommonNumberFIeld from "../../../atoms/CommonNumberFIeld";
import { ShipDexie } from "../../../../dexie/Ship";
import { CargoFormRequired, EUorUk, LorU } from "../../../../gen/graphql/types";
import Steppr from "../../../atoms/Stepper";
import RadioButtun from "../../../atoms/RadioButton";
import { ReportDexie } from "../../../../dexie/Report";
import UtcDateTimePicker from "../../../atoms/UtcDateTimePicker";
import { Stack } from "@mui/material";
import CommonSelect from "../../../atoms/CommonSelect";
import { convertToNumberOrNull } from "../../../../utils/type-checks";
import { lightGray, steelBlue, white } from "../../../../utils/color";
import ClearIcon from "@mui/icons-material/Clear";
import { useShip } from "../../../layouts/DefaultCrew";
import { getFileName, setFileName } from "../../../../utils/form-util";
import { convertEmptyStringToNull } from "../../../../utils/formats";
import { calculateCargoTimezone } from "./calculation/calculateCargoValues";

type DefaultRadioButton = {
  defaultTimeZonePlusMinus: string;
  defaultLatDirection: string;
  defaultLongDirection: string;
};

export type FormBlockProps = {
  title: string;
  isPreview: boolean;
  onUpdateForm?: (value: CargoForm) => void;
  ship?: ShipDexie;
  lastReport?: ReportDexie;
  isConfirm: boolean;
  form: CargoForm;
  defaultRadioButton?: DefaultRadioButton;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  isServer?: boolean;
};

const FormBlock = (props: FormBlockProps): React.ReactElement => {
  const ship = useShip();
  const onChange = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    if (typeof value === "string") {
      value = convertEmptyStringToNull(value);
    }

    const form = {
      ...props.form,
      [key]: value,
    };

    props.onUpdateForm?.(form);
  };

  const onUpdateWithTimezone = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    if (!props.isServer) {
      const form = calculateCargoTimezone({
        form: { ...props.form, [key]: value },
      });

      props.onUpdateForm?.({
        ...form,
        [key]: value,
      });
      return;
    }
    onChange(key, value);
  };

  const getFormRequire = (cargoFormRequired?: CargoFormRequired) => {
    if (!ship?.cargoFormSetting || !cargoFormRequired) return true;

    switch (cargoFormRequired) {
      case CargoFormRequired.Required:
        return true;
      case CargoFormRequired.Optional:
        return true;
      case CargoFormRequired.NotRequired:
        return false;
    }
  };

  const cargoIsConfirm = (cargoFormRequired?: CargoFormRequired) => {
    switch (cargoFormRequired) {
      case CargoFormRequired.Required:
        return true && props.isConfirm;
      case CargoFormRequired.Optional:
        return undefined;
      case CargoFormRequired.NotRequired:
        return undefined;
      default:
        return undefined;
    }
  };

  const cargoFormSetting = ship?.cargoFormSetting;

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ p: 2 }}>
      <Box sx={{ pt: 1 }}>
        <Stack direction="row" alignItems="center">
          <Stack alignItems="left" justifyContent={"center"}>
            <Typography
              component="h3"
              variant="subtitle1"
              align="center"
              sx={{
                lineHeight: 1,
                fontWeight: "bold",
                color: "#fff",
                fontSize: "1.5rem",
                verticalAlign: "super",
              }}
            >
              {props.title}
            </Typography>
            <Typography
              sx={{
                pt: 1,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "12px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#ffffff",
              }}
            >
              *(Prev. Data)
            </Typography>
          </Stack>
          <Stack sx={{ width: "60%" }}>
            <Steppr step={0} />
          </Stack>
        </Stack>
      </Box>
      <Stack spacing={2.5} sx={{ pt: 3 }}>
        <Stack direction="row" spacing={2}>
          <CommonTextFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="C_Voyage_No"
            label="Voyage number"
            placeholder=""
            value={props.form.C_Voyage_No}
            onChange={(e: string) => {
              onChange("C_Voyage_No", e);
            }}
          />
          <UtcDateTimePicker
            id={"C_DateTime"}
            isConfirm={props.isConfirm}
            width="26ch"
            label={"Date & Time (Local)"}
            value={props.form.C_DateTime}
            onChange={onChange}
            onChangeKey={"C_DateTime"}
            hasMinDate={true}
          />
        </Stack>
        <Box>
          <Stack
            direction="row"
            spacing={96}
            alignItems="center"
            justifyContent={"left"}
          >
            <Typography
              sx={{
                p: 1,
                pl: 0,
                textAlign: "left",
                fontSize: "16px",
                lineHeight: "auto",
                color: "#AAAAAA",
              }}
            >
              Lat./ Lon.
            </Typography>
            <Typography
              sx={{
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: lightGray,
                pb: 0.5,
              }}
            >
              Time Zone (UTC±)
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent={"left"}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={"left"}
            >
              <Typography
                sx={{
                  pl: 1,
                  textAlign: "left",
                  fontSize: "16px",
                  color: "#AAAAAA",
                }}
              >
                Lat.
              </Typography>
              <Stack direction="row" spacing={0.2} alignItems="center">
                <CommonNumberFIeld
                  isConfirm={props.isConfirm}
                  disabled={props.isPreview}
                  id="C_Lat_Deg"
                  label="Deg."
                  placeholder=""
                  value={convertToNumberOrNull(props.form.C_Lat_Deg)}
                  maxValue={90}
                  minValue={0}
                  sx={{ width: "80px" }}
                  onChange={(e) => {
                    onChange("C_Lat_Deg", e);
                  }}
                />

                <Typography sx={{ color: "#AAAAAA" }}>：</Typography>

                <CommonNumberFIeld
                  isConfirm={props.isConfirm}
                  disabled={props.isPreview}
                  id="C_Lat_Min"
                  label="Min."
                  placeholder=""
                  value={convertToNumberOrNull(props.form.C_Lat_Min)}
                  maxValue={60}
                  minValue={0}
                  sx={{ width: "80px", pr: 0.5 }}
                  onChange={(e) => {
                    onChange("C_Lat_Min", e);
                  }}
                />
              </Stack>
              <RadioButtun
                id="C_Lat_Direction"
                label=" "
                label1="North"
                label2="South"
                defaultValue={
                  props.defaultRadioButton?.defaultLatDirection || "N"
                }
                value1="N"
                value2="S"
                onChange={onChange}
              />
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={"left"}
            >
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontFamily: "Public Sans",
                  lineHeight: "auto",
                  color: "#AAAAAA",
                }}
              >
                Lon.
              </Typography>

              <Stack direction="row" spacing={0.2} alignItems="center">
                <CommonNumberFIeld
                  isConfirm={props.isConfirm}
                  disabled={props.isPreview}
                  id="C_Long_Deg"
                  label="Deg."
                  placeholder=""
                  value={convertToNumberOrNull(props.form.C_Long_Deg)}
                  maxValue={180}
                  minValue={0}
                  sx={{ width: "90px" }}
                  onChange={(e) => onUpdateWithTimezone("C_Long_Deg", e)}
                />

                <Typography sx={{ color: "#AAAAAA" }}>：</Typography>

                <CommonNumberFIeld
                  isConfirm={props.isConfirm}
                  disabled={props.isPreview}
                  id="C_Long_Min"
                  label="Min."
                  placeholder=""
                  value={convertToNumberOrNull(props.form.C_Long_Min)}
                  maxValue={60}
                  minValue={0}
                  sx={{ width: "80px", pr: 0.5 }}
                  onChange={(e) => onUpdateWithTimezone("C_Long_Min", e)}
                />
              </Stack>
              <RadioButtun
                id="C_Long_Direction"
                defaultValue={
                  props.defaultRadioButton?.defaultLongDirection || "E"
                }
                label=" "
                label1="East"
                label2="West"
                value1="E"
                value2="W"
                onChange={(e, value) => onUpdateWithTimezone(e, value)}
              />
            </Stack>
            <Stack direction="row" spacing={0.2} alignItems="center">
              <Stack
                direction="column"
                alignContent={"flex-start"}
                justifyContent={"left"}
              >
                <RadioButtun
                  id="C_TimeZone_PorN"
                  value={props.form.C_TimeZone_PorN}
                  defaultValue={
                    props.defaultRadioButton?.defaultTimeZonePlusMinus || "+"
                  }
                  label=""
                  label1="＋"
                  label2="ー"
                  value1="+"
                  value2="-"
                  onChange={onChange}
                />
              </Stack>
              <Typography
                sx={{
                  pb: 2,
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: "16px",
                  fontFamily: "Public Sans",
                  lineHeight: "auto",
                  color: lightGray,
                }}
              ></Typography>
              <Select
                size="small"
                labelId="C_TimeZone_HH"
                id="C_TimeZone_HH"
                label="Hour."
                placeholder="Hour."
                value={props.form.C_TimeZone_HH}
                onChange={(e: SelectChangeEvent<string | null>) => {
                  onChange("C_TimeZone_HH", e.target.value);
                }}
                inputProps={{
                  "aria-label": `B_TimeZone_HH`,
                }}
                sx={{
                  width: "70px",
                  "& .MuiSelect-outlined": {
                    color: white,
                  },
                  "& .MuiSelect-select": {
                    color: white,
                    border: `1px solid ${steelBlue}`,
                  },
                  "& .MuiSelect-select:hover": {
                    color: white,
                    border: `1px solid ${lightGray} !important`,
                  },

                  "& .MuiSelect-icon": {
                    color: "transparent",
                  },
                  "&:hover fieldset": {
                    border: steelBlue,
                  },
                  textAlign: "left",
                }}
              >
                {[...Array(15).keys()]
                  .map((v) => ("00" + String(v)).slice(-2))
                  .map((v, index) => {
                    return (
                      <MenuItem value={v} key={index}>
                        {v}
                      </MenuItem>
                    );
                  })}
              </Select>

              <Typography sx={{ color: lightGray, pt: 2 }}>：</Typography>
              <Typography
                sx={{
                  pb: 2,
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: "16px",
                  fontFamily: "Public Sans",
                  lineHeight: "auto",
                  color: lightGray,
                }}
              ></Typography>
              <Select
                size="small"
                labelId="B_TimeZone_MM"
                id="B_TimeZone_MM"
                label="Min."
                placeholder="Min."
                value={props.form.C_TimeZone_MM}
                onChange={(e: SelectChangeEvent<string | null>) => {
                  onChange("C_TimeZone_MM", e.target.value);
                }}
                inputProps={{
                  "aria-label": `B_TimeZone_MM`,
                }}
                sx={{
                  width: "70px",
                  "& .MuiSelect-outlined": {
                    color: white,
                  },
                  "& .MuiSelect-select": {
                    color: white,
                    border: `1px solid ${steelBlue}`,
                  },
                  "& .MuiSelect-select:hover": {
                    color: white,
                    border: `1px solid ${lightGray} !important`,
                  },

                  "& .MuiSelect-icon": {
                    color: "transparent",
                  },
                  "&:hover fieldset": {
                    border: steelBlue,
                  },
                  textAlign: "left",
                }}
              >
                {["00", "30", "45"].map((v, index) => {
                  return (
                    <MenuItem value={v} key={index}>
                      {v}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
          </Stack>
        </Box>
        <Stack
          direction="row"
          spacing={2}
          alignItems="end"
          justifyContent={"left"}
        >
          <CommonTextFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="C_Cargo_Port"
            label="Port name"
            placeholder=""
            value={props.form.C_Cargo_Port}
            onChange={(e) => {
              onChange("C_Cargo_Port", e);
            }}
          />
          <CommonTextFIeld
            isConfirm={props.isConfirm}
            disabled={props.isPreview}
            id="C_UNLOC"
            label="UNLOC"
            placeholder=""
            value={props.form.C_UNLOC}
            onChange={(e) => {
              onChange("C_UNLOC", e);
            }}
          />
          <CommonSelect
            id="C_EU_UK_port"
            label="EU/UK"
            value={props.form.C_EU_UK_port}
            onChange={(e) => {
              onChange("C_EU_UK_port", e);
            }}
            selectValues={Object.values(EUorUk)}
            isOptional={true}
          />
          <CommonSelect
            id="C_Port_L_U"
            label="L or U"
            value={props.form.C_Port_L_U}
            onChange={(e) => {
              onChange("C_Port_L_U", e);
            }}
            selectValues={Object.values(LorU)}
          />
        </Stack>
        <Box>
          <Grid
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            container
            sx={{ py: 1 }}
          >
            <Grid item xs={2}>
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "16px",
                  lineHeight: "auto",
                  color: "#AAAAAA",
                }}
              >
                Cargo Loading
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2.5}
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{ py: 1.5, width: "85%" }}
            columns={10.3}
          >
            {getFormRequire(cargoFormSetting?.C_Mass) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Mass)}
                  disabled={props.isPreview}
                  id="C_Loading_Mass"
                  label="Mass"
                  placeholder=""
                  unitName="mt"
                  value={props.form.C_Loading_Mass}
                  onChange={(e) => {
                    onChange("C_Loading_Mass", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_TEU_Full) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_TEU_Full)}
                  disabled={props.isPreview}
                  id="C_Loading_TEU_Full"
                  label="TEU/Full"
                  placeholder=""
                  value={props.form.C_Loading_TEU_Full}
                  onChange={(e) => {
                    onChange("C_Loading_TEU_Full", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_TEU_Empty) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_TEU_Empty)}
                  disabled={props.isPreview}
                  id="C_Loading_TEU_Empty"
                  label="TEU/Empty"
                  placeholder=""
                  value={props.form.C_Loading_TEU_Empty}
                  onChange={(e) => {
                    onChange("C_Loading_TEU_Empty", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Unit) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Unit)}
                  disabled={props.isPreview}
                  id="C_Loading_Unit"
                  label="Unit"
                  placeholder=""
                  value={props.form.C_Loading_Unit}
                  onChange={(e) => {
                    onChange("C_Loading_Unit", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Lane_metres) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Lane_metres)}
                  disabled={props.isPreview}
                  id="C_Lane_metres"
                  label="Lane metres"
                  placeholder=""
                  unitName="metre"
                  value={props.form.C_Loading_Lane_metres}
                  onChange={(e) => {
                    onChange("C_Loading_Lane_metres", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Number_of_Passengers) && (
              <Grid item xs={2.3}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(
                    cargoFormSetting?.C_Number_of_Passengers
                  )}
                  disabled={props.isPreview}
                  id="C_Loading_Number_of_Passengers"
                  label="Number of Passengers"
                  placeholder=""
                  value={props.form.C_Loading_Number_of_Passengers}
                  sx={{ width: 220 }}
                  onChange={(e) => {
                    onChange("C_Loading_Number_of_Passengers", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Volume) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Volume)}
                  disabled={props.isPreview}
                  id="C_Loading_Volume"
                  label="Volume"
                  placeholder=""
                  unitName="m³"
                  value={props.form.C_Loading_Volume}
                  onChange={(e) => {
                    onChange("C_Loading_Volume", e);
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Typography
            sx={{
              pt: 2,
              textAlign: "left",
              fontSize: "16px",
              lineHeight: "auto",
              color: "#AAAAAA",
            }}
          >
            Cargo Unloading
          </Typography>
          <Grid
            container
            spacing={2.5}
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{ py: 1.5, width: "85%" }}
            columns={10.3}
          >
            {getFormRequire(cargoFormSetting?.C_Mass) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Mass)}
                  disabled={props.isPreview}
                  id="C_Unloading_Mass"
                  label="Mass"
                  placeholder=""
                  unitName="mt"
                  value={props.form.C_Unloading_Mass}
                  onChange={(e) => {
                    onChange("C_Unloading_Mass", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_TEU_Full) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_TEU_Full)}
                  disabled={props.isPreview}
                  id="C_Unloading_TEU_Full"
                  label="TEU/Full"
                  placeholder=""
                  value={props.form.C_Unloading_TEU_Full}
                  onChange={(e) => {
                    onChange("C_Unloading_TEU_Full", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_TEU_Empty) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_TEU_Empty)}
                  disabled={props.isPreview}
                  id="C_Unloading_TEU_Empty"
                  label="TEU/Empty"
                  placeholder=""
                  value={props.form.C_Unloading_TEU_Empty}
                  onChange={(e) => {
                    onChange("C_Unloading_TEU_Empty", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Unit) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Unit)}
                  disabled={props.isPreview}
                  id="C_Unloading_Unit"
                  label="Unit"
                  placeholder=""
                  value={props.form.C_Unloading_Unit}
                  onChange={(e) => {
                    onChange("C_Unloading_Unit", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Lane_metres) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Lane_metres)}
                  disabled={props.isPreview}
                  id="C_Unloading_Lane_metres"
                  label="Lane metres"
                  placeholder=""
                  unitName="metre"
                  value={props.form.C_Unloading_Lane_metres}
                  onChange={(e) => {
                    onChange("C_Unloading_Lane_metres", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Number_of_Passengers) && (
              <Grid item xs={2.3}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(
                    cargoFormSetting?.C_Number_of_Passengers
                  )}
                  disabled={props.isPreview}
                  id="C_Unloading_Number_of_Passengers"
                  label="Number of Passengers"
                  placeholder=""
                  value={props.form.C_Unloading_Number_of_Passengers}
                  sx={{ width: 220 }}
                  onChange={(e) => {
                    onChange("C_Unloading_Number_of_Passengers", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Volume) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Volume)}
                  disabled={props.isPreview}
                  id="C_Unloading_Volume"
                  label="Volume"
                  placeholder=""
                  unitName="m³"
                  value={props.form.C_Unloading_Volume}
                  onChange={(e) => {
                    onChange("C_Unloading_Volume", e);
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Typography
            sx={{
              pt: 2,
              textAlign: "left",
              fontSize: "16px",
              lineHeight: "auto",
              color: "#AAAAAA",
            }}
          >
            Cargo Total
          </Typography>
          <Grid
            container
            spacing={2.5}
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{ py: 1.5, width: "85%" }}
            columns={10.3}
          >
            {getFormRequire(cargoFormSetting?.C_Mass) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Mass)}
                  disabled={props.isPreview}
                  id="C_Total_Mass"
                  label="Mass"
                  placeholder=""
                  unitName="mt"
                  value={props.form.C_Total_Mass}
                  onChange={(e) => {
                    onChange("C_Total_Mass", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_TEU_Full) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_TEU_Full)}
                  disabled={props.isPreview}
                  id="C_Total_TEU_Full"
                  label="TEU/Full"
                  placeholder=""
                  value={props.form.C_Total_TEU_Full}
                  onChange={(e) => {
                    onChange("C_Total_TEU_Full", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_TEU_Empty) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_TEU_Empty)}
                  disabled={props.isPreview}
                  id="C_Total_TEU_Empty"
                  label="TEU/Empty"
                  placeholder=""
                  value={props.form.C_Total_TEU_Empty}
                  onChange={(e) => {
                    onChange("C_Total_TEU_Empty", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Unit) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Unit)}
                  disabled={props.isPreview}
                  id="C_Total_Unit"
                  label="Unit"
                  placeholder=""
                  value={props.form.C_Total_Unit}
                  onChange={(e) => {
                    onChange("C_Total_Unit", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Lane_metres) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Lane_metres)}
                  disabled={props.isPreview}
                  id="C_Total_Lane_metres"
                  label="Lane metres"
                  placeholder=""
                  unitName="metre"
                  value={props.form.C_Total_Lane_metres}
                  onChange={(e) => {
                    onChange("C_Total_Lane_metres", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Number_of_Passengers) && (
              <Grid item xs={2.3}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(
                    cargoFormSetting?.C_Number_of_Passengers
                  )}
                  disabled={props.isPreview}
                  id="C_Total_Number_of_Passengers"
                  label="Number of Passengers"
                  placeholder=""
                  value={props.form.C_Total_Number_of_Passengers}
                  sx={{ width: 220 }}
                  onChange={(e) => {
                    onChange("C_Total_Number_of_Passengers", e);
                  }}
                />
              </Grid>
            )}
            {getFormRequire(cargoFormSetting?.C_Volume) && (
              <Grid item xs={2}>
                <CommonNumberFIeld
                  isConfirm={cargoIsConfirm(cargoFormSetting?.C_Volume)}
                  disabled={props.isPreview}
                  id="C_Total_Volume"
                  label="Volume"
                  placeholder=""
                  unitName="m³"
                  value={props.form.C_Total_Volume}
                  onChange={(e) => {
                    onChange("C_Total_Volume", e);
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Typography
            sx={{
              p: 1,
              py: 2,
              textAlign: "left",
              fontSize: "16px",
              lineHeight: "auto",
              color: "#AAAAAA",
            }}
          >
            Deadweight measurement
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent={"left"}
          >
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent={"left"}
            >
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="C_Displacement"
                label=""
                placeholder="Displacement"
                value={props.form.C_Displacement}
                unitName={"mt"}
                sx={{ width: 250 }}
                onChange={(e) => {
                  onChange("C_Displacement", e);
                }}
              />
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="C_Water_density"
                label=""
                placeholder="Water density"
                sx={{ width: 250 }}
                value={props.form.C_Water_density}
                unitName={"mt"}
                onChange={(e) => {
                  onChange("C_Water_density", e);
                }}
              />
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="C_Total_ROB"
                label=""
                placeholder="Total ROB"
                sx={{ width: 250 }}
                value={props.form.C_Total_ROB}
                unitName={"mt"}
                onChange={(e) => {
                  onChange("C_Total_ROB", e);
                }}
              />
            </Stack>
          </Stack>
          <Box
            sx={{
              mt: 3,
              opacity: 1,
              border: "dashed 1px #AAAAAA",
              borderRadius: "10px",
              width: "80%",
              height: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"center"}
            >
              <Typography>{getFileName(props.form.C_Filename)}</Typography>
              {props.form.C_Filename && (
                <IconButton onClick={() => onChange("C_Filename", "")}>
                  <ClearIcon />
                </IconButton>
              )}
            </Stack>
            <Button variant="outlined" component="label">
              Upload
              <input
                type="file"
                hidden
                onChange={(event) => {
                  const file = event.target.files?.item(0);
                  if (file instanceof File) {
                    props.setFile(file);

                    onChange(
                      "C_Filename",
                      setFileName(file.name, ship?.IMO_No || "")
                    );
                  } else {
                    console.warn("File does not exist");
                  }
                }}
              />
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default FormBlock;
