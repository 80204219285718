import { orgRound } from "../../../../../utils/calculate";
import { isNanFinite } from "../../../../../utils/isNanFinite";

type Props = {
    propeller_pitch: number;
    N_ME_rpm_ave: number | null;
    N_Speed_OW: number | null;
}

export function cal_N_Slip(props: Props) {
    if (props.N_ME_rpm_ave === null) {
        return 0;
    }

    if (props.N_Speed_OW === null) {
        return 1;
    }

    if (props.propeller_pitch === 0) {
        return 0;
    }

    const N_Slip = orgRound(
            (1 -
                (30.866 * (props.N_Speed_OW)) / ((props.propeller_pitch / 1000) * props.N_ME_rpm_ave)) * 100,
            100);
    
    if (isNanFinite(N_Slip)) {
        return 0;
    }

    return N_Slip;
}