import { createContext, useContext, useState } from "react";

// crew側で初期ロードを実行したかを管理するstate。その間に同期などを行う
type LoadContextType = {
  isFinishedGlobalLoading: boolean;
  setIsFinishedGlobalLoading: (isLoading: boolean) => void;
}

const globalLoadContext = createContext<LoadContextType | undefined>(undefined);

export const useGlobalLoadContext = (): LoadContextType => {
  const context = useContext(globalLoadContext);
  if (!context) {
    throw new Error("useLoadContext must be used within a LoadProvider");
  }
  return context;
}

export const GlobalLoadProvider = ({ children }: { children: React.ReactNode }) => {
  const [isFinishedGlobalLoading, setIsFinishedGlobalLoading] = useState<boolean>(false);

  return (
    <globalLoadContext.Provider value={{ isFinishedGlobalLoading, setIsFinishedGlobalLoading }}>
      {children}
    </globalLoadContext.Provider>
  );
}