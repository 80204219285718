import React, { useEffect, useMemo, useState } from "react";
import {
  useShipListLazyQuery,
  ShipListQuery,
  Ship as GraphqlShip,
  useCreateFrontendShipDataMutation,
} from "../../../../gen/graphql/types";
import Template from "../../../templates/Crew/SelectShips/SelectShips";
import { db } from "../../../../dexie/db";
import { Form, LocationState, Ship } from "./type";
import { useNavigate } from "react-router";
import { ShipDexie } from "../../../../dexie/Ship";
import { useShip } from "../../../layouts/DefaultCrew";

type OmitedGraphqlShip = Omit<
  GraphqlShip,
  | "created_at"
  | "updated_at"
  | "arrival"
  | "departure"
  | "organizationShipsMapping"
  | "report"
>;

export const mapToShip = (
  ship?: OmitedGraphqlShip | null | undefined
): Ship | null => {
  if (!ship?.id) {
    return null;
  }

  return {
    id: ship?.id,
    IMO_No: ship?.IMO_No,
    name: ship?.name,
    propeller_pitch: ship?.propeller_pitch,
    summer_deadweight: ship?.summer_deadweight,
    fo_fuel_type: ship.fo_fuel_type,
    type_of_ship_cii: ship?.type_of_ship_cii,
    cargoFormSetting: ship.cargoFormSetting,
    is_mrv_portal_integration_target: ship.is_mrv_portal_integration_target,
    shipMaster: ship.shipMaster,
  };
};

const getShipList = (shipList?: ShipListQuery): Ship[] => {
  const reportList = (shipList?.shipList || [])
    .map((report): Ship | null => mapToShip(report))
    .filter((v): v is NonNullable<typeof v> => v != null);

  return reportList;
};

export const useShips = () => {
  const [getData, { data: response, loading: isLoading }] =
    useShipListLazyQuery();

  const data = useMemo(() => {
    const reportList = getShipList(response);

    return reportList;
  }, [response]);

  const refetch = () => {
    getData({});
  };

  return {
    isLoading,
    data,
    refetch,
  };
};

const useForm = (initial?: Form) => {
  const [data, update] = useState<Form>(
    initial || {
      ship_id: null,
    }
  );

  const hasError = useMemo(() => {
    const { ship_id } = data;

    return !ship_id;
  }, [data]);

  return {
    data,
    hasError,
    update,
  };
};

type Props = LocationState;

const SelectShips = (props: Props): React.ReactElement => {
  const navigate = useNavigate();
  const [createFrontendShipData] = useCreateFrontendShipDataMutation();

  const form = useForm(props.form);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const onSubmit = (ship: Ship) => {
    const newShip: ShipDexie = {
      id: undefined,
      ship_id: ship.id,
      IMO_No: ship.IMO_No,
      name: ship.name,
      summer_deadweight: ship.summer_deadweight,
      propeller_pitch: ship.propeller_pitch,
      fo_fuel_type: ship.fo_fuel_type || undefined,
      type_of_ship_cii: ship.type_of_ship_cii || undefined,
      cargoFormSetting: ship.cargoFormSetting || undefined,
      is_mrv_portal_integration_target: ship.is_mrv_portal_integration_target || undefined,
    };

    db.ship.add(newShip, 1).catch((e) => console.error(e));

    const shipJsonString = JSON.stringify(ship)
    createFrontendShipData({
      variables: {
        input: {
          IMONO: ship.IMO_No,
          FrontendShipData: shipJsonString
        },
      },
      onCompleted: (response) => {
        console.log("response: ", response)
      }
    })

    navigate("/");
  };

  const { data, isLoading: dataLoading, refetch } = useShips();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ship = useShip();

  if (ship) {
    navigate("/");
  }

  return (
    <Template
      form={form.data}
      hasFormError={form.hasError}
      onUpdateForm={onUpdateForm}
      onSubmit={onSubmit}
      data={data}
      isLoading={dataLoading}
      refetch={refetch}
      value={form.data.ship_id}
    />
  );
};

export default SelectShips;
