import { EUorUk, FuelType, LorU } from "../../../../../gen/graphql/types";

export type Bunkering = {
  id: number;
  B_Vessel_Name: string;
  B_Voy: string;
  B_Timezone: string;
  B_Bunkering_Date_time: Date;
  B_LatLong: string;
  B_PortName: string;
  B_UNLOC: string;
  B_Port_LorU: LorU;
  B_EUorUK?: EUorUk | null;
  B_Fuel_name_1?: FuelType | null;
  B_Mass_1?: number | null;
  B_Volume_1?: number | null;
  B_Density_1?: number | null;
  B_Fuel_name_2?: FuelType | null;
  B_Mass_2?: number | null;
  B_Volume_2?: number | null;
  B_Density_2?: number | null;
  B_Fuel_name_3?: FuelType | null;
  B_Mass_3?: number | null;
  B_Volume_3?: number | null;
  B_Density_3?: number | null;
  B_Fuel_name_4?: FuelType | null;
  B_Mass_4?: number | null;
  B_Volume_4?: number | null;
  B_Density_4?: number | null;
  B_Fuel_name_5?: FuelType | null;
  B_Mass_5?: number | null;
  B_Volume_5?: number | null;
  B_Density_5?: number | null;
  B_Fuel_name_6?: FuelType | null;
  B_Mass_6?: number | null;
  B_Volume_6?: number | null;
  B_Density_6?: number | null;
  B_Fuel_name_7?: FuelType | null;
  B_Mass_7?: number | null;
  B_Volume_7?: number | null;
  B_Density_7?: number | null;
  B_Fuel_name_8?: FuelType | null;
  B_Mass_8?: number | null;
  B_Volume_8?: number | null;
  B_Density_8?: number | null;
  B_Fuel_name_9?: FuelType | null;
  B_Mass_9?: number | null;
  B_Volume_9?: number | null;
  B_Density_9?: number | null;
  B_Fuel_name_10?: FuelType | null;
  B_Mass_10?: number | null;
  B_Volume_10?: number | null;
  B_Density_10?: number | null;
  B_Filename?: string;
};

export type Form = {
  B_Vessel_Name?: string | null | undefined;
  B_Voy?: string | null | undefined;
  B_TimeZone_PorN?: string | null | undefined;
  B_TimeZone_HH?: string | null | undefined;
  B_TimeZone_MM?: string | null | undefined;
  B_Bunkering_Date_time: Date | null;
  B_Lat_Direction: string;
  B_Lat_Deg: string | null;
  B_Lat_Min: string | null;
  B_Long_Direction: string;
  B_Long_Deg: string | null;
  B_Long_Min: string | null;
  B_PortName: string | null;
  B_UNLOC: string | null;
  B_Port_LorU: LorU;
  B_EUorUK: EUorUk | null;
  B_Fuel_name_1: FuelType | null;
  B_Mass_1: number | null;
  B_Volume_1: number | null;
  B_Density_1: number | null;
  B_Fuel_name_2: FuelType | null;
  B_Mass_2: number | null;
  B_Volume_2: number | null;
  B_Density_2: number | null;
  B_Fuel_name_3: FuelType | null;
  B_Mass_3: number | null;
  B_Volume_3: number | null;
  B_Density_3: number | null;
  B_Fuel_name_4: FuelType | null;
  B_Mass_4: number | null;
  B_Volume_4: number | null;
  B_Density_4: number | null;
  B_Fuel_name_5: FuelType | null;
  B_Mass_5: number | null;
  B_Volume_5: number | null;
  B_Density_5: number | null;
  B_Fuel_name_6: FuelType | null;
  B_Mass_6: number | null;
  B_Volume_6: number | null;
  B_Density_6: number | null;
  B_Fuel_name_7: FuelType | null;
  B_Mass_7: number | null;
  B_Volume_7: number | null;
  B_Density_7: number | null;
  B_Fuel_name_8: FuelType | null;
  B_Mass_8: number | null;
  B_Volume_8: number | null;
  B_Density_8: number | null;
  B_Fuel_name_9: FuelType | null;
  B_Mass_9: number | null;
  B_Volume_9: number | null;
  B_Density_9: number | null;
  B_Fuel_name_10: FuelType | null;
  B_Mass_10: number | null;
  B_Volume_10: number | null;
  B_Density_10: number | null;
  B_Filename?: string;
};

export type BunkeringInformation = {
  B_Fuel_name_1: string | null;
  B_Mass_1: number | null;
  B_Volume_1: number | null;
  B_Density_1: number | null;
  B_Fuel_name_2: string | null;
  B_Mass_2: number | null;
  B_Volume_2: number | null;
  B_Density_2: number | null;
  B_Fuel_name_3: string | null;
  B_Mass_3: number | null;
  B_Volume_3: number | null;
  B_Density_3: number | null;
  B_Fuel_name_4: string | null;
  B_Mass_4: number | null;
  B_Volume_4: number | null;
  B_Density_4: number | null;
  B_Fuel_name_5: string | null;
  B_Mass_5: number | null;
  B_Volume_5: number | null;
  B_Density_5: number | null;
  B_Fuel_name_6: string | null;
  B_Mass_6: number | null;
  B_Volume_6: number | null;
  B_Density_6: number | null;
  B_Fuel_name_7: string | null;
  B_Mass_7: number | null;
  B_Volume_7: number | null;
  B_Density_7: number | null;
  B_Fuel_name_8: string | null;
  B_Mass_8: number | null;
  B_Volume_8: number | null;
  B_Density_8: number | null;
  B_Fuel_name_9: string | null;
  B_Mass_9: number | null;
  B_Volume_9: number | null;
  B_Density_9: number | null;
  B_Fuel_name_10: string | null;
  B_Mass_10: number | null;
  B_Volume_10: number | null;
  B_Density_10: number | null;
}

export type Result = {
  succeed: boolean;
  code?: string | null | undefined;
};

export type LocationState = {
  form?: Form;
  result?: Result;
};

export type Props = {
  form: Form;
};

export const initializeBunkering = {
  ship_id: null,
  user_id: null,
  B_Vessel_Name: null,
  B_Voy: null,
  B_TimeZone_PorN: "+",
  B_TimeZone_HH: "00",
  B_TimeZone_MM: "00",
  B_Bunkering_Date_time: null,
  B_Lat_Direction: "N",
  B_Lat_Deg: null,
  B_Lat_Min: null,
  B_Long_Direction: "E",
  B_Long_Deg: null,
  B_Long_Min: null,
  B_PortName: null,
  B_UNLOC: null,
  B_Port_LorU: LorU.Y,
  B_EUorUK: null,
  B_Fuel_name_1: null,
  B_Mass_1: null,
  B_Volume_1: null,
  B_Density_1: null,
  B_Fuel_name_2: null,
  B_Mass_2: null,
  B_Volume_2: null,
  B_Density_2: null,
  B_Fuel_name_3: null,
  B_Mass_3: null,
  B_Volume_3: null,
  B_Density_3: null,
  B_Fuel_name_4: null,
  B_Mass_4: null,
  B_Volume_4: null,
  B_Density_4: null,
  B_Fuel_name_5: null,
  B_Mass_5: null,
  B_Volume_5: null,
  B_Density_5: null,
  B_Fuel_name_6: null,
  B_Mass_6: null,
  B_Volume_6: null,
  B_Density_6: null,
  B_Fuel_name_7: null,
  B_Mass_7: null,
  B_Volume_7: null,
  B_Density_7: null,
  B_Fuel_name_8: null,
  B_Mass_8: null,
  B_Volume_8: null,
  B_Density_8: null,
  B_Fuel_name_9: null,
  B_Mass_9: null,
  B_Volume_9: null,
  B_Density_9: null,
  B_Fuel_name_10: null,
  B_Mass_10: null,
  B_Volume_10: null,
  B_Density_10: null,
  B_Filename: "",
}