import { useState } from "react";
import { ReportSearchCondition } from "../pages.module";
import { SEARCH_PAGE_LIMIT_OWNER } from "./NoonReport/NoonReport";

export const useOwnerSearchForm = (
  reportSearchCondition?: ReportSearchCondition
) => {
  const [data, update] = useState<ReportSearchCondition>(
    reportSearchCondition
      ? reportSearchCondition
      : {
          imo_no: null,
          Voy: null,
          From_DateTime: null,
          To_DateTime: null,
          pagination: {
            page: 1,
            limit: SEARCH_PAGE_LIMIT_OWNER,
          },
        }
  );

  return {
    data,
    update,
  };
};
