import * as React from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import Detail from "../../../organisms/Owner/Cargo/Detail";
import { CargoForm } from "../../../pages/Crew/Cargo/type";

type ConfirmProps = {
  reportId: number;
  onBack(): void;
  form: CargoForm;
};

const CargoDetail = (props: ConfirmProps): React.ReactElement => {
  const onClickBack = () => {
    props.onBack();
  };

  return (
    <React.Fragment>
      <Paper sx={{ backgroundColor: "#ECEEF2", borderRadius: "20px" }}>
        <Detail
          isPreview={true}
          form={props.form}
          pageTitle={"Cargo Report"}
          isSend={false}
        />

        <Box
          sx={{
            textAlign: "left",
            px: 2,
            py: 4,
          }}
        >
          <Button
            sx={{
              mx: 2,
              width: "200px",
              color: "#FFFFFF",
              backgroundColor: "#20BE89  !important",
              fontFamily: "Public Sans",
              "&.MuiButton-root": {
                fontFamily: "Public Sans",
              },
              textTransform: "none",
            }}
            variant="contained"
            onClick={onClickBack}
          >
            Back
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default CargoDetail;
