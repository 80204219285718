import React, { useMemo } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";
import { VesselNamesQuery, useVesselNamesQuery } from "../../gen/graphql/types";
import { Box } from "@mui/system";
import Loading from "./Loading";
import { nonNullable } from "../../utils/formats";

export type Props = {
  disabled?: boolean;
  required?: boolean;
  minWidth?: number;
  value: string | "";
  sx?: SxProps<Theme>;
  onChange(event: SelectChangeEvent<string>): void;
};

export type ShipSelect = {
  id: number;
  name: string;
  imoNumber: string;
};

const mapToVesselNames = (response?: VesselNamesQuery): ShipSelect[] => {
  const shipList = (response?.shipList || [])
    .filter((v): v is Exclude<typeof v, null> => nonNullable(v))
    .map((ship): ShipSelect | null => {
      const id = ship?.id;
      const name = ship?.name;
      const imoNumber = ship?.IMO_No;

      if (!id || !name) {
        return null;
      }

      return {
        id,
        name,
        imoNumber,
      };
    })
    .filter((v): v is Exclude<typeof v, null> => nonNullable(v));

  return shipList;
};

export const useVesselNames = () => {
  const { data: response, loading: isLoading } = useVesselNamesQuery({});
  const data = useMemo(() => mapToVesselNames(response), [response]);

  function getVesselName(props: { imo_no?: string | null }) {
    if (!props.imo_no) return "";

    if (props.imo_no)
      return data.find((v) => v.imoNumber === props.imo_no)?.name || "";

    return "";
  }

  const getIsMrvPortalIntegrationTargetByShipId = useMemo(
    () => (shipId: number) => {
      const ship = response?.shipList?.find((v) => v?.id === shipId);
      return ship?.is_mrv_portal_integration_target ?? false;
    },
    [response]
  );

  return {
    isLoading,
    getVesselName,
    getIsMrvPortalIntegrationTargetByShipId,
    data,
  };
};

const VesselNamesSelect = (props: Props): React.ReactElement => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const shipList = useVesselNames();

  return (
    <Box sx={{ position: "relative" }}>
      <Loading inner isLoading={shipList.isLoading} zIndex={1} />
      <FormControl
        required={props.required}
        sx={{
          width: props.minWidth,
          "& .MuiSelect-outlined": {
            color: "#FFFFFF",
          },
          "& .MuiSelect-select": {
            color: "#FFFFFF",
            border: `1px solid #464E5A`,
          },
          "& .MuiSelect-icon": {
            color: "#333333",
          },
          "& .MuiFormLabel-root": {
            color: "#AAAAAA",
            border: `1px transparent`,
          },
          "& .MuiSelect-select:hover": {
            color: "#FFFFFF",
            border: `1px solid #AAAAAA !important`,
          },
          "&:hover fieldset": {
            border: "#FFFFFF",
          },
          textAlign: "left",
          ...props.sx,
        }}
        size={"small"}
      >
        <InputLabel id={"vessel-name-select"} sx={{ color: "#AAAAAA" }}>
          {"Vessel Name"}
        </InputLabel>
        <Select
          disabled={props.disabled}
          onChange={props.onChange}
          value={props.value || ""}
          labelId={"vessel-name-select-label"}
          id={"vessel-name-select"}
          label={"Vessel Name"}
        >
          <MenuItem key={0} value="">
            <em>---</em>
          </MenuItem>

          {shipList.data.map((ship) => {
            return (
              <MenuItem key={ship.id} value={ship.imoNumber}>
                {ship.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default VesselNamesSelect;
