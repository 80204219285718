import { ApolloClient } from "@apollo/client";
import {
  ArrivalListDocument,
  ArrivalListInput,
} from "../../../../gen/graphql/types";

export const getArrivalCsvDownloadData = async (
  input: ArrivalListInput,
  client: ApolloClient<object>
) => {
  const { data } = await client.query({
    query: ArrivalListDocument,
    variables: { input },
    fetchPolicy: "network-only",
  });

  return data;
};
