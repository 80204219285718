import * as React from "react";
import Default from "../../layouts/DefaultCrew";
import Reports from "../../pages/Crew/NoonReport/Reports";

const Crew = (): React.ReactElement => {
  return (
    <Default title="Noon Report" selectedTitle={"Noon Report"}>
      <React.Fragment>
        <Reports />
      </React.Fragment>
    </Default>
  );
};

export default Crew;
