import { ShipDexie } from "../../../../dexie/Ship";
import {
  BunkeringInput,
  QueryStatus,
  useCreateBunkeringListMutation,
} from "../../../../gen/graphql/types";
import { nonNullable } from "../../../../utils/formats";
import { uploadFiles } from "../../../../utils/uploadFile.module";
import { checkQueryStatus } from "../../pages.module";
import { mapToBunkeringInput, updateFrontBunkering } from "./SendDataUtil";
import { BunkeringDexie } from "../../../../dexie/Bunkering";
import { ErrorMessageData } from "../../../../hooks/errorMessage.hook";

type Props = {
  checkedBunkeringList: BunkeringDexie[];
  ship: ShipDexie;
  setCheckedBunkering: (value: React.SetStateAction<BunkeringDexie[]>) => void;
  setCheckedBoxBunkering: (value: React.SetStateAction<number[]>) => void;
  createErrorData: (
    dataType: string,
    dataArr: unknown[]
  ) => {
    loading: boolean;
  };
  addErrorMessage: (newErrorMessage: ErrorMessageData) => void;
};

export const useCreateBunkeringListMutationHook = () => {
  const [createBunkeringList, { loading: isBunkeringLoading }] =
    useCreateBunkeringListMutation();

  const createBunkeringMutation = (props: Props) => {
    const bunkeringVariables: BunkeringInput[] = props.checkedBunkeringList
      .map((bunkering) => mapToBunkeringInput(bunkering))
      .filter(nonNullable);

    createBunkeringList({
      variables: {
        input: {
          BunkeringInput: bunkeringVariables,
          IMO_No: props.ship.IMO_No,
        },
      },
      onCompleted: async (data) => {
        try {
          checkQueryStatus(data.createBunkeringList?.CreateBunkeringListStatus);

          if (!data.createBunkeringList) {
            throw new Error(
              "Server Error! createBunkeringList response is empty."
            );
          } else if (!data.createBunkeringList.createdBunkeringCount) {
            throw new Error(
              "Server Error! createBunkeringList createBunkeringListCount is 0."
            );
          } else if (
            data.createBunkeringList.CreateBunkeringListStatus ===
            QueryStatus.Success
          ) {
            console.info("createBunkering Success!");
          }

          const failedGetUploadUrlDbFileList: string[] = [];

          const upLoadFilePath = data.createBunkeringList.uploadFilePaths
            .filter((v): v is NonNullable<typeof v> => v !== null)
            .map((v) => {
              if (v.gcsFilePath === "") {
                failedGetUploadUrlDbFileList.push(v.dbFilePath);
                return;
              }

              const fileData = props.checkedBunkeringList
                .filter((v): v is NonNullable<typeof v> => v !== undefined)
                .find((local) => local.data?.B_Filename === v.dbFilePath);

              if (!fileData || !fileData.bunkeringFile) return;
              return {
                localFilePath: v.dbFilePath,
                remoteFilePath: v.gcsFilePath,
                fileContent: fileData.bunkeringFile,
              };
            })
            .filter((v): v is NonNullable<typeof v> => v !== undefined);

          const failedUploadFileList = (
            await uploadFiles(upLoadFilePath)
          ).filter((v) => !v.sucsess);

          if (
            failedUploadFileList.length > 0 ||
            failedGetUploadUrlDbFileList.length > 0
          ) {
            props.addErrorMessage({
              input: JSON.stringify(bunkeringVariables),
              errorMessage:
                `Failed to upload the following Bunkering files. ${failedUploadFileList
                  .map((v) => v.filename)
                  .concat(failedGetUploadUrlDbFileList)
                  .join(",")}` +
                failedUploadFileList.map((v) => v.errorMessage).join("\n"),
            });
          }

          if (
            props.checkedBunkeringList.length ===
            data.createBunkeringList?.createdBunkeringCount
          ) {
            props.checkedBunkeringList.forEach((report) => {
              updateFrontBunkering(report);
            });
          }
          props.setCheckedBunkering([]);
          props.setCheckedBoxBunkering([]);
        } catch (e) {
          console.error(e);
          props.createErrorData("Bunkering", bunkeringVariables);
          props.addErrorMessage({
            input: JSON.stringify(bunkeringVariables),
            errorMessage: `Server Error! createBunkeringList onCompleted:${e}`,
          });
        }
      },
      onError: (error) => {
        console.error(error);
        //エラーinputデータ送信
        props.createErrorData("Bunkering", bunkeringVariables);
        props.addErrorMessage({
          input: JSON.stringify(bunkeringVariables),
          errorMessage: `Server Error! createBunkeringList onError:${error}`,
        });
      },
    });
  };
  return [{ createBunkeringMutation, isBunkeringLoading }];
};
