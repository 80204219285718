import { ApolloClient } from "@apollo/client";
import {
  BunkeringListDocument,
  BunkeringListInput,
} from "../../../../gen/graphql/types";

export const getBunkeringCsvDownloadData = async (
  input: BunkeringListInput,
  client: ApolloClient<object>
) => {
  const { data } = await client.query({
    query: BunkeringListDocument,
    variables: { input },
    fetchPolicy: "network-only",
  });

  return data;
};
