import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Template from "../../../templates/Crew/Arrival/Create";
import { Form, LocationState } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import { db } from "../../../../dexie/db";
import { ArrivalDexie } from "../../../../dexie/Arrival";
import { pageData } from "../../../../App";
import {
  useArrivalForm,
  mapToArrivalMutationVariables,
} from "./arrival.module";
import { useGetLastFrontReport } from "../../../../hooks/dexie/dexie";

type LocationForm = {
  form: Form;
};

const Create = (props: LocationState): React.ReactElement => {
  const navigate = useNavigate();

  const location = useLocation();
  const [isConfirm, setIsConfirm] = useState(false);

  const location_form = location.state as LocationForm;

  const form1 = props.form || location_form?.form || props.form;

  const form = useArrivalForm([form1]);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const ship = useShip();

  const onSaveDraft = (value: Form) => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToArrivalMutationVariables(value, ship?.ship_id);

    const newArrival: ArrivalDexie = {
      id: undefined,
      isDraft: true,
      isSend: false,
      data: variables,
      draftForm: value,
    };
    db.arrival.add(newArrival, 1).catch((e) => console.error(e));
    navigate(pageData.crewArrival.path());
  };

  const lastReport = useGetLastFrontReport();

  const onConfirm = () => {
    form.hasErrorScrollUp();
    setIsConfirm(true);
    if (form.hasError) return;
    navigate("/arrival/create/confirm", { state: { form: form.data } });
  };

  return (
    <Default title="" selectedTitle={"Arrival"}>
      <Template
        form={form.data}
        hasFormError={form.hasError}
        onUpdateForm={onUpdateForm}
        onConfirm={onConfirm}
        onSaveDraft={onSaveDraft}
        ship={ship}
        lastReport={lastReport}
        isConfirm={isConfirm}
        setIsConfirm={setIsConfirm}
      />
    </Default>
  );
};

export default Create;
