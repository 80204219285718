import * as React from "react";
import { Box } from "@mui/material";
import Optional from "./Optional";
type Props = {
  children?: React.ReactNode;
};

export function OptionalWithChildren(props: Props): React.ReactElement {
  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Box>
        <Optional />
        {props.children}
      </Box>
    </Box>
  );
}
