import { useState } from "react";
import { Form } from "../components/pages/Crew/NoonReport/Create/types";

export type ErrorMessageData = {
  input: string;
  errorMessage: string;
};

function useErrorMessageList() {
  const [errorMessageList, setErrorMessageList] = useState<ErrorMessageData[]>(
    []
  );

  const addErrorMessage = (newErrorMessage: ErrorMessageData) => {
    setErrorMessageList((prevErrorMessageList) => [
      ...prevErrorMessageList,
      newErrorMessage,
    ]);
  };

  const removeErrorMessage = (errorMessage: string) => {
    setErrorMessageList((prevErrorMessageList) =>
      prevErrorMessageList.filter(
        (errorMessageObj) => errorMessageObj.errorMessage !== errorMessage
      )
    );
  }

  const manageErrorMessage = (
    hasError: boolean,
    errorMessage: string,
    form: Form,
  ) => {
    if (hasError) {
      const errorExists = errorMessageList?.some(errorMessageData => errorMessageData.errorMessage === errorMessage);
      if (!errorExists) {
        addErrorMessage?.({ input: JSON.stringify(form), errorMessage });
      }
    } else {
      removeErrorMessage?.(errorMessage);
    }
  };

  return { errorMessageList, addErrorMessage, removeErrorMessage, manageErrorMessage };
}

export default useErrorMessageList;
