import { ReferenceDataType } from "../../../../gen/graphql/types";

export type ReportGraph = {
  id: number;
  N_Speed_OG: number;
  N_FO_Consumption_per_day: number;
  N_DateTime: Date;
};

export type ReferenceDataInput = {
  Speed_OG?: number;
  fuel_consumption_Ballast?: number;
  fuel_consumption_Laden?: number;
  fuel_consumption_Unique?: number;
  dataType?: ReferenceDataType;
};

export type ReferenceData = {
  Speed_OG: number;
  fuel_consumption_Ballast: number;
  fuel_consumption_Laden: number;
  fuel_consumption_Unique?: number;
  dataType?: ReferenceDataType;
};

export type Form = {
  ship_id: number | null;
};

export type Result = {
  succeed: boolean;
  code: string | null;
};

export type FilterBooleanType = {
  filter1: boolean;
  filter2: boolean;
  filter3: boolean;
  filter4: boolean;
};

export type ReferenceDataTypeIndex = ReferenceDataType | typeof UNSELECTED;

export const UNSELECTED = "Unselected";
