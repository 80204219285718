/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import Template from "../../../templates/Owner/Arrival/ArrivalDetail";
import { LocationState, Form } from "../../Crew/Arrival/Create/types";
import { useLazyInitializeState } from "../../../../utils/lazy-initialize-state";
import {
  LorB,
  GetOwnerArrivalQuery,
  useGetOwnerArrivalQuery,
  LorU,
} from "../../../../gen/graphql/types";
import { pageData } from "../../../../App";
import DefaultOwner from "../../../layouts/DefaultOwner";
import { emptyToDoubleQuotation } from "../../../../utils/formats";
import { filterArrUndefinedNull } from "../../../../utils/type-checks";

type Maybe<T> = T | null | undefined;

export const useForm = (initializes: Maybe<Form>[]) => {
  const initialize = {
    ship_id: null,
    user_id: null,
    A_Vessel_Name: null,
    A_Voy: null,
    A_LorB: LorB.Ballast,
    A_Arrival_Port: null,
    A_EU_UK_port: null,
    A_Port_L_U: LorU.N,
    A_UNLOC: "",
    A_Arrival_Date_Time: null,
    A_SB_or_Stop: null,
    A_TimeZone_PorN: "+",
    A_TimeZone_HH: null,
    A_TimeZone_MM: "00",
    A_Time_Zone: null,
    A_ETB: null,
    A_ETC: null,
    A_ETD: null,
    A_Berthed: null,
    A_ROB_at_Arrival_FO: null,
    A_ROB_at_Arrival_MGO: null,
    A_ROB_at_Arrival_MECO: null,
    A_ROB_at_Arrival_MESO: null,
    A_ROB_at_Arrival_GESO: null,
    A_ROB_at_Arrival_FW: null,
    A_Hrs_since_Last_Noon: null,
    A_Distance_by_OG: null,
    A_FO_Consumption_per_day_ME: null,
    A_FO_Consumption_per_day_GE: null,
    A_FO_Consumption_per_day_B_and_Inc: null,
    A_MGO_Consumption_per_day_ME: null,
    A_MGO_Consumption_per_day_GE: null,
    A_MGO_Consumption_per_day_B_and_Inc: null,
    A_ROB_FO: null,
    A_ROB_MGO: null,
    A_ROB_MECO: null,
    A_ROB_MESO: null,
    A_ROB_GESO: null,
    A_ROB_FW: null,
    A_Draft_Fore: null,
    A_Draft_Aft: null,
    A_FO_CO2_emission: null,
    A_MGO_CO2_emission: null,
    A_Remark: null,
    A_Spare_1: null,
    A_Spare_1_unit_name: null,
    A_Spare_2: null,
    A_Spare_2_unit_name: null,
    A_Spare_3: null,
    A_Spare_3_unit_name: null,
    A_Spare_4: null,
    A_Spare_4_unit_name: null,
    A_Spare_5: null,
    A_Spare_5_unit_name: null,
    A_Spare_6: null,
    A_Spare_6_unit_name: null,
    A_Spare_7: null,
    A_Spare_7_unit_name: null,
    A_Spare_8: null,
    A_Spare_8_unit_name: null,
    A_Spare_9: null,
    A_Spare_9_unit_name: null,
    A_Spare_10: null,
    A_Spare_10_unit_name: null,
    A_Spare_11: null,
    A_Spare_11_unit_name: null,
    A_Spare_12: null,
    A_Spare_12_unit_name: null,
    A_Spare_13: null,
    A_Spare_13_unit_name: null,
    A_Spare_14: null,
    A_Spare_14_unit_name: null,
    A_Spare_15: null,
    A_Spare_15_unit_name: null,
    A_Spare_16: null,
    A_Spare_16_unit_name: null,
    A_Spare_17: null,
    A_Spare_17_unit_name: null,
    A_Spare_18: null,
    A_Spare_18_unit_name: null,
    A_Spare_19: null,
    A_Spare_19_unit_name: null,
    A_Spare_20: null,
    A_Spare_20_unit_name: null,
  };
  const [isLoading, data, update] = useLazyInitializeState<Form>(
    initialize,
    initializes
  );

  return {
    isLoading,
    data,
    update,
  };
};

const useGetArrival = (report_id: number) => {
  const input = { id: report_id };

  const { data: response, loading: isLoading } = useGetOwnerArrivalQuery({
    variables: { input },
  });

  const form = useMemo(() => mapToFormData(response), [response]);

  return {
    isLoading,
    form,
  };
};

const mapToFormData = (response?: GetOwnerArrivalQuery): Form | null => {
  if (!response) return null;

  const report = response.getOwnerArrival;

  if (!report) return null;

  return {
    A_Vessel_Name: report.A_Vessel_Name,
    A_Voy: report.A_Voy,
    A_LorB: report.A_LorB || LorB.Ballast,
    A_Arrival_Port: report.A_Arrival_Port,
    A_EU_UK_port: report.A_EU_UK_port,
    A_Port_L_U: report.A_Port_L_U || LorU.N,
    A_UNLOC: emptyToDoubleQuotation(report.A_UNLOC),
    A_Arrival_Date_Time: new Date(report.A_Arrival_Date_Time),
    A_SB_or_Stop: new Date(report.A_SB_or_Stop),
    A_TimeZone_PorN: report?.A_Time_Zone.slice(0, 1),
    A_TimeZone_HH: report?.A_Time_Zone.slice(1, 3),
    A_TimeZone_MM: report?.A_Time_Zone.slice(4, 6),
    A_ETB: report.A_ETB,
    A_ETC: report.A_ETC,
    A_ETD: report.A_ETD,
    A_Berthed: report.A_Berthed,
    A_ROB_at_Arrival_FO: Number(report.A_ROB_at_Arrival_FO),
    A_ROB_at_Arrival_MGO: Number(report.A_ROB_at_Arrival_MGO),
    A_ROB_at_Arrival_MECO: Number(report.A_ROB_at_Arrival_MECO),
    A_ROB_at_Arrival_MESO: Number(report.A_ROB_at_Arrival_MESO),
    A_ROB_at_Arrival_GESO: Number(report.A_ROB_at_Arrival_GESO),
    A_ROB_at_Arrival_FW: Number(report.A_ROB_at_Arrival_FW),
    A_Hrs_since_Last_Noon: Number(report.A_Hrs_since_Last_Noon),
    A_Distance_by_OG: Number(report.A_Distance_by_OG),
    A_FO_Consumption_per_day_ME: Number(report.A_FO_Consumption_per_day_ME),
    A_FO_Consumption_per_day_GE: Number(report.A_FO_Consumption_per_day_GE),
    A_FO_Consumption_per_day_B_and_Inc: Number(
      report.A_FO_Consumption_per_day_B_and_Inc
    ),
    A_MGO_Consumption_per_day_ME: Number(report.A_MGO_Consumption_per_day_ME),
    A_MGO_Consumption_per_day_GE: Number(report.A_MGO_Consumption_per_day_GE),
    A_MGO_Consumption_per_day_B_and_Inc: Number(
      report.A_MGO_Consumption_per_day_B_and_Inc
    ),
    A_ROB_FO: Number(report.A_ROB_FO),
    A_ROB_MGO: Number(report.A_ROB_MGO),
    A_ROB_MECO: Number(report.A_ROB_MECO),
    A_ROB_MESO: Number(report.A_ROB_MESO),
    A_ROB_GESO: Number(report.A_ROB_GESO),
    A_ROB_FW: Number(report.A_ROB_FW),
    A_Additional_ROB: filterArrUndefinedNull(report.A_Additional_ROB),
    A_Draft_Fore: Number(report.A_Draft_Fore),
    A_Draft_Aft: Number(report.A_Draft_Aft),
    A_FO_CO2_emission: Number(report.A_FO_CO2_emission),
    A_MGO_CO2_emission: Number(report.A_MGO_CO2_emission),
    A_Remark: report.A_Remark || "",
    A_Spare_1: emptyToDoubleQuotation(report.A_Spare_1),
    A_Spare_1_unit_name: emptyToDoubleQuotation(report.A_Spare_1_unit_name),
    A_Spare_2: emptyToDoubleQuotation(report.A_Spare_2),
    A_Spare_2_unit_name: emptyToDoubleQuotation(report.A_Spare_2_unit_name),
    A_Spare_3: emptyToDoubleQuotation(report.A_Spare_3),
    A_Spare_3_unit_name: emptyToDoubleQuotation(report.A_Spare_3_unit_name),
    A_Spare_4: emptyToDoubleQuotation(report.A_Spare_4),
    A_Spare_4_unit_name: emptyToDoubleQuotation(report.A_Spare_4_unit_name),
    A_Spare_5: emptyToDoubleQuotation(report.A_Spare_5),
    A_Spare_5_unit_name: emptyToDoubleQuotation(report.A_Spare_5_unit_name),
    A_Spare_6: emptyToDoubleQuotation(report.A_Spare_6),
    A_Spare_6_unit_name: emptyToDoubleQuotation(report.A_Spare_6_unit_name),
    A_Spare_7: emptyToDoubleQuotation(report.A_Spare_7),
    A_Spare_7_unit_name: emptyToDoubleQuotation(report.A_Spare_7_unit_name),
    A_Spare_8: emptyToDoubleQuotation(report.A_Spare_8),
    A_Spare_8_unit_name: emptyToDoubleQuotation(report.A_Spare_8_unit_name),
    A_Spare_9: emptyToDoubleQuotation(report.A_Spare_9),
    A_Spare_9_unit_name: emptyToDoubleQuotation(report.A_Spare_9_unit_name),
    A_Spare_10: emptyToDoubleQuotation(report.A_Spare_10),
    A_Spare_10_unit_name: emptyToDoubleQuotation(report.A_Spare_10_unit_name),
    A_Spare_11: emptyToDoubleQuotation(report.A_Spare_11),
    A_Spare_11_unit_name: emptyToDoubleQuotation(report.A_Spare_11_unit_name),
    A_Spare_12: emptyToDoubleQuotation(report.A_Spare_12),
    A_Spare_12_unit_name: emptyToDoubleQuotation(report.A_Spare_12_unit_name),
    A_Spare_13: emptyToDoubleQuotation(report.A_Spare_13),
    A_Spare_13_unit_name: emptyToDoubleQuotation(report.A_Spare_13_unit_name),
    A_Spare_14: emptyToDoubleQuotation(report.A_Spare_14),
    A_Spare_14_unit_name: emptyToDoubleQuotation(report.A_Spare_14_unit_name),
    A_Spare_15: emptyToDoubleQuotation(report.A_Spare_15),
    A_Spare_15_unit_name: emptyToDoubleQuotation(report.A_Spare_15_unit_name),
    A_Spare_16: emptyToDoubleQuotation(report.A_Spare_16),
    A_Spare_16_unit_name: emptyToDoubleQuotation(report.A_Spare_16_unit_name),
    A_Spare_17: emptyToDoubleQuotation(report.A_Spare_17),
    A_Spare_17_unit_name: emptyToDoubleQuotation(report.A_Spare_17_unit_name),
    A_Spare_18: emptyToDoubleQuotation(report.A_Spare_18),
    A_Spare_18_unit_name: emptyToDoubleQuotation(report.A_Spare_18_unit_name),
    A_Spare_19: emptyToDoubleQuotation(report.A_Spare_19),
    A_Spare_19_unit_name: emptyToDoubleQuotation(report.A_Spare_19_unit_name),
    A_Spare_20: emptyToDoubleQuotation(report.A_Spare_20),
    A_Spare_20_unit_name: emptyToDoubleQuotation(report.A_Spare_20_unit_name),
  };
};

type Props = LocationState;

const DepartureDetail = (props: Props): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);

  const navigate = useNavigate();

  if (!report_id) navigate("/");

  const formData = useGetArrival(report_id);

  const onBack = () => {
    navigate(pageData.ownerArrival.path());
  };

  const form = useForm([props.form, formData.form]);

  return (
    <DefaultOwner title="Noon Report" selectedTitle={"Arrival"}>
      <Template form={form.data} reportId={report_id} onBack={onBack} />
    </DefaultOwner>
  );
};

export default DepartureDetail;
