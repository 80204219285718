import { ReportSearchCondition } from "../components/pages/pages.module";
import { formatToYYYYMMDD } from "./formats";

export const CSV_DOWNLOAD_LIMIT = 1000;

export const downloadCsvFile = (csvFileName: string, csvText: string): void => {
  // BOM for Excel
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csvText], { type: "text/csv" });

  const url = (window.URL || window.webkitURL).createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = csvFileName;
  link.click();

  // revoke
  (window.URL || window.webkitURL).revokeObjectURL(url);
};

const escapeForCSV = (v: string) => {
  // prettier-ignore
  // eslint-disable-next-line no-useless-escape
  return v.replace(/\"/g, '\"\"')
};

export const formatToCsvString = (value: string | undefined | null) => {
  if (value == null || value === undefined) {
    return "";
  }

  return `"${escapeForCSV(value)}"`;
};

const sha256 = async (value: string) => {
  const uint8 = new TextEncoder().encode(value);
  const digest = await crypto.subtle.digest("SHA-1", uint8).catch((e) => {
    throw e;
  });

  return Array.from(new Uint8Array(digest))
    .map((v) => v.toString(16).padStart(2, "0"))
    .join("");
};

export const genSearchConditionHash = (
  searchCondition: ReportSearchCondition
) => {
  const ship_id = searchCondition.ship_id;
  const Voy = searchCondition.Voy;
  const From_DateTime = formatToYYYYMMDD(searchCondition.From_DateTime, "", "");
  const To_DateTime = formatToYYYYMMDD(searchCondition.To_DateTime, "", "");

  const page = searchCondition.pagination.page;
  const limit = searchCondition.pagination.limit;

  const searchConditionCollection = {
    ship_id,
    Voy,
    From_DateTime,
    To_DateTime,
    page,
    limit,
  };
  const searchConditionCollectionKeys = Object.keys(
    searchConditionCollection
  ) as (keyof typeof searchConditionCollection)[];

  const serialized = searchConditionCollectionKeys
    .map((key) => {
      const value = searchConditionCollection[key];

      if (!value) {
        return "";
      }

      return `${key}${value}`;
    })
    .filter((value) => !!value)
    .join("");

  return sha256(serialized);
};
