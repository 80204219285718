import {
  Box,
  FormControl,
  Modal,
  Slider,
  Stack,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import React, { FC, useCallback } from "react";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import VesselNamesSelect from "../../../atoms/VesselNamesSelect";
import {
  SpeedConsumptionFilterCondition,
  meRevolutionMax,
} from "../../../pages/Owner/Analysis/queries";
import Optional from "../../../atoms/Optional";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import { LorB, SteamMode } from "../../../../gen/graphql/types";
import { convertEmptyStringToNull } from "../../../../utils/formats";
import {
  greenTurquoise,
  lightGray,
  paleGray,
  sliderColor,
  steelBlue,
  weightedGray,
  white,
} from "../../../../utils/color";
import ControlCheckbox from "../../../atoms/ControlCheckbox";
import LightGrayButton from "../../../atoms/LightGrayButton";
import SucsessButton from "../../../atoms/SucsessButton";
import { FilterTitleList } from "../../../pages/Owner/Analysis/const";
import { FilterBooleanType } from "../../../pages/Owner/Analysis/types";
import { areAllFiltersTrue } from "../../../pages/Owner/Analysis/analysisUtils";
import {
  beaufortScale,
  hrsPropelling,
  meRpmRange,
} from "./defaultFilterFormValue";
import CommonSelect from "../../../atoms/CommonSelect";

type Props = {
  filterTitle: string;
  isOpen: boolean;
  filterForm: SpeedConsumptionFilterCondition;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateFilterForm(value: SpeedConsumptionFilterCondition): void;
  onChangeGraph(): void;
  hasFilterFormError: boolean;
  onResetFilter(): void;
  checkedMeRevolutionOver100: FilterBooleanType;
  setCheckedMeRevolutionOver100: React.Dispatch<
    React.SetStateAction<FilterBooleanType>
  >;
  isMeUsageFilter: FilterBooleanType;
  setIsMeUsageFilter: React.Dispatch<React.SetStateAction<FilterBooleanType>>;
};

const SpeedConsumptionFilterModal: FC<Props> = (props: Props) => {
  const handleClose = () => props.setIsModalOpen(false);
  const onClickApplyFilter = () => {
    props.onChangeGraph();
    handleClose();
  };

  const onChange = useCallback(
    (
      key: string,
      value: string | number | number[] | Date | null | undefined
    ) => {
      if (typeof value === "string") {
        value = convertEmptyStringToNull(value);
      }

      const form = {
        ...props.filterForm,
        [key]: value,
      };

      props.onUpdateFilterForm(form);
    },
    [props]
  );

  type ModalFormBoolean = {
    checkedMeRevolutionOver100: boolean;
  };

  const modalFormBoolean = (): ModalFormBoolean => {
    switch (props.filterTitle) {
      case FilterTitleList.Filter_1:
        return {
          checkedMeRevolutionOver100: props.checkedMeRevolutionOver100.filter1,
        };
      case FilterTitleList.Filter_2:
        return {
          checkedMeRevolutionOver100: props.checkedMeRevolutionOver100.filter2,
        };

      case FilterTitleList.Filter_3:
        return {
          checkedMeRevolutionOver100: props.checkedMeRevolutionOver100.filter3,
        };
      case FilterTitleList.Filter_4:
        return {
          checkedMeRevolutionOver100: props.checkedMeRevolutionOver100.filter4,
        };

      case FilterTitleList.Filter_All:
        return {
          checkedMeRevolutionOver100: areAllFiltersTrue(
            props.checkedMeRevolutionOver100
          ),
        };
      default:
        return {
          checkedMeRevolutionOver100: false,
        };
    }
  };

  const setCheckedMeRevolutionOver100 = useCallback(
    (value: boolean) => {
      switch (props.filterTitle) {
        case FilterTitleList.Filter_1:
          props.setCheckedMeRevolutionOver100((prev) => {
            return { ...prev, filter1: value };
          });
          break;
        case FilterTitleList.Filter_2:
          props.setCheckedMeRevolutionOver100((prev) => {
            return { ...prev, filter2: value };
          });
          break;

        case FilterTitleList.Filter_3:
          props.setCheckedMeRevolutionOver100((prev) => {
            return { ...prev, filter3: value };
          });
          break;
        case FilterTitleList.Filter_4:
          props.setCheckedMeRevolutionOver100((prev) => {
            return { ...prev, filter4: value };
          });
          break;
        case FilterTitleList.Filter_All:
          props.setCheckedMeRevolutionOver100({
            filter1: value,
            filter2: value,
            filter3: value,
            filter4: value,
          });
          break;
      }
    },
    [props]
  );

  const handleBeaufortChange = (event: Event, newValue: number | number[]) => {
    onChange("bf_scale", newValue as number[]);
  };

  const handleOnClear = () => {
    setCheckedMeRevolutionOver100(false);
    props.onResetFilter();
  };

  const handleMeRevolutionChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    const valueArray = newValue as number[];
    if (Array.isArray(newValue) && valueArray[1] !== meRevolutionMax) {
      setCheckedMeRevolutionOver100(false);
    }
    onChange("me_revolution", valueArray);
  };

  const handleCheckMeRevolutionOver100Change = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        onChange("me_revolution", [
          props.filterForm.me_revolution
            ? props.filterForm.me_revolution[0]
            : 0,
        ]); // Filterのstateにおいてme_revolutionの上限を取り払う (100以上も取得対象とするため)
        setCheckedMeRevolutionOver100(checked);
        return;
      } else if (!checked) {
        if (props.filterForm.me_revolution?.length == 1) {
          onChange("me_revolution", [
            props.filterForm.me_revolution
              ? props.filterForm.me_revolution[0]
              : 0,
            100,
          ]);
        } else {
          onChange("me_revolution", props.filterForm.me_revolution);
        }
      }

      setCheckedMeRevolutionOver100(checked);
    },
    [onChange, props.filterForm.me_revolution, setCheckedMeRevolutionOver100]
  );

  const handleHrsPropellingChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    onChange("hrs_propelling", newValue as number[]);
  };

  const style = {
    color: weightedGray,
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: paleGray,
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography sx={{ fontSize: "24px", pb: 0.5 }}>
            {props.filterTitle}
          </Typography>
        </Stack>

        <Box display="flex" justifyContent="flex-start" width="100%">
          <Typography sx={{ pt: 1, fontWeight: "bold" }}>
            This setting is applied to all filters.
          </Typography>
        </Box>

        <Columns>
          <Column sx={{ ml: 0 }}>
            <VesselNamesSelect
              value={props.filterForm.imo_no ?? ""}
              minWidth={323}
              sx={{
                "& .MuiSelect-select": {
                  color: weightedGray,
                },
                "& .MuiSelect-select:hover": {
                  color: weightedGray,
                },
              }}
              onChange={(event) => {
                onChange("imo_no", event.target.value);
              }}
            />
          </Column>
        </Columns>
        <Columns>
          <Column sx={{ py: 0 }}>
            <Optional />
            <CommonTextFIeld
              id={"voy"}
              label={"Voy#"}
              value={props.filterForm.voy}
              sx={{
                "& .MuiInputBase-input": { color: weightedGray },
              }}
              onChange={(e: string | null) => {
                onChange("voy", e);
              }}
              width={"130px"}
            />
          </Column>
        </Columns>

        <Typography sx={{ pt: 2 }}>
          Set a time period for each of the following months.
        </Typography>

        <RadioGroup
          row
          sx={{ pt: 1 }}
          aria-label="from_to_period"
          name="from_to_period"
          value={props.filterForm.from_to_period}
          onChange={(e) => onChange("from_to_period", e.target.value)}
        >
          <FormControlLabel value={3} control={<Radio />} label="3 months" />
          <FormControlLabel value={6} control={<Radio />} label="6 months" />
          <FormControlLabel value={9} control={<Radio />} label="9 months" />
        </RadioGroup>

        <Columns>
          <Column sx={{ pt: 1.5 }}>
            <Typography id="N_BF_scale">Beaufort</Typography>
            <Optional />
            <FormControl
              sx={{
                minWidth: 120,
                "& .MuiInputBase-input": {
                  color: white,
                  borderColor: steelBlue,
                },
                "& .MuiFormLabel-root": {
                  color: lightGray,
                  border: `1px transparent`,
                  "&:hover fieldset": {
                    color: lightGray,
                  },
                },
                "& .MuiSelect-icon": {
                  color: weightedGray,
                },
              }}
              size="small"
            >
              <Box
                sx={{
                  width: "600px",
                  pl: 1.2,
                }}
              >
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={props.filterForm.bf_scale || []}
                  min={0}
                  max={12}
                  onChange={handleBeaufortChange}
                  valueLabelDisplay="auto"
                  marks={beaufortScale}
                  sx={{ color: sliderColor }}
                />
              </Box>
            </FormControl>
          </Column>
        </Columns>
        <Columns>
          <Column sx={{ pt: 1.5 }}>
            <Typography id="N_ME_RPM">M/E rpm - avg.</Typography>
            <Optional />
            <FormControl
              sx={{
                minWidth: 120,
                "& .MuiInputBase-input": {
                  color: white,
                  borderColor: steelBlue,
                },
                "& .MuiFormLabel-root": {
                  color: lightGray,
                  border: `1px transparent`,
                  "&:hover fieldset": {
                    color: greenTurquoise,
                  },
                },
                "& .MuiSelect-icon": {
                  color: weightedGray,
                },
              }}
              size="small"
            >
              <Box
                sx={{
                  width: "600px",

                  pl: 1,
                  display: "flex",
                }}
              >
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={
                    modalFormBoolean().checkedMeRevolutionOver100
                      ? [
                          props.filterForm.me_revolution
                            ? props.filterForm.me_revolution[0]
                            : 0,
                          100,
                        ]
                      : props.filterForm.me_revolution || []
                  }
                  min={0}
                  max={100}
                  onChange={handleMeRevolutionChange}
                  valueLabelDisplay="auto"
                  marks={meRpmRange}
                  sx={{ color: sliderColor }}
                />
                <ControlCheckbox
                  checked={modalFormBoolean().checkedMeRevolutionOver100}
                  onChange={handleCheckMeRevolutionOver100Change}
                  label="Unlimited"
                />
              </Box>
            </FormControl>
          </Column>
        </Columns>
        <Columns>
          <Column sx={{ pt: 1.5 }}>
            <Typography id="N_BF_scale">Hrs. propelling</Typography>
            <Optional />
            <FormControl
              sx={{
                minWidth: 120,
                "& .MuiInputBase-input": {
                  color: white,
                  borderColor: steelBlue,
                },
                "& .MuiFormLabel-root": {
                  color: lightGray,
                  border: `1px transparent`,
                  "&:hover fieldset": {
                    color: greenTurquoise,
                  },
                },
                "& .MuiSelect-icon": {
                  color: weightedGray,
                },
              }}
              size="small"
            >
              <Box
                sx={{
                  width: "600px",
                  pl: 1,
                  my: 0,
                }}
              >
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={props.filterForm.hrs_propelling || []}
                  min={0}
                  max={30}
                  onChange={handleHrsPropellingChange}
                  valueLabelDisplay="auto"
                  marks={hrsPropelling}
                  sx={{ color: sliderColor }}
                />
              </Box>
            </FormControl>
          </Column>
        </Columns>
        <Columns>
          <Column sx={{ py: 0 }}>
            <CommonSelect
              isOwner
              isOptional
              id={"N_LorB"}
              label={"L or B"}
              value={props.filterForm.ballast_or_laden || ""}
              onChange={(value) => {
                onChange("ballast_or_laden", value);
              }}
              selectValues={Object.values(LorB)}
            />
          </Column>

          <Column sx={{ py: 0 }}>
            <CommonSelect
              isOwner
              isOptional
              id={"N_Steam_mode"}
              label={"Steam Mode"}
              value={props.filterForm.steam_mode || ""}
              onChange={(value) => {
                onChange("steam_mode", value);
              }}
              selectValues={Object.values(SteamMode)}
            />
          </Column>
        </Columns>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent={"flex-start"}
          sx={{ mt: 5 }}
        >
          <SucsessButton
            ButtonName={"Apply"}
            disabled={props.hasFilterFormError}
            onClick={() => onClickApplyFilter()}
            sx={{ mx: 0, width: 200, color: white }}
          />

          <LightGrayButton
            ButtonName={"Clear"}
            onClick={handleOnClear}
            sx={{ mx: 0 }}
          />
          <LightGrayButton
            ButtonName={"Cancel"}
            onClick={handleClose}
            sx={{ mx: 0 }}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default SpeedConsumptionFilterModal;
