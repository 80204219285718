import { Button, SxProps, Theme } from "@mui/material";

type Props = {
  fontSize?: number;
  width?: string;
  ButtonName: string;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
};

const GrayButton = (props: Props) => {
  return (
    <Button
      sx={{
        fontSize: props.fontSize,
        mx: 2,
        width: props.width || 150,
        color: "#FFFFFF",
        backgroundColor: "#555860 !important",
        textTransform: "none",
        fontFamily: "Public Sans",
        ...props.sx,
      }}
      variant="contained"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.ButtonName}
    </Button>
  );
};

export default GrayButton;
