import React, { useMemo } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";

type Props = {
  id: string;
  label: string;
  labelId: string;
  value: string | number | null;
  disabled?: boolean;
  required?: boolean;
  isSmall?: boolean;
  minWidth?: number;
  children?: React.ReactNode;
  onChange(event: SelectChangeEvent<string>): void;
};

export type SearchSelectProps = {
  disabled?: boolean;
  required?: boolean;
  value: string | number | null;
  onChange(event: SelectChangeEvent<string>): void;
};

const SearchSelect = (props: Props): React.ReactElement => {
  const required = props.required || false;
  const minWidth = props.minWidth || 170;
  const value = useMemo(() => String(props.value || ""), [props.value]);

  return (
    <FormControl
      required={required}
      sx={{
        width: minWidth,
        "& .MuiSelect-outlined": {
          color: "#FFFFFF",
        },
        "& .MuiSelect-select": {
          color: "#FFFFFF",
          border: `1px solid #464E5A`,
        },
        "& .MuiSelect-icon": {
          color: "#FFFFFF",
        },
        "& .MuiFormLabel-root": {
          color: "#AAAAAA",
          border: `1px transparent`,
        },
        "& .MuiSelect-select:hover": {
          color: "#FFFFFF",
          border: `1px solid #AAAAAA !important`,
        },
        "&:hover fieldset": {
          border: "#FFFFFF",
        },
        textAlign: "left",
      }}
      size={props.isSmall ? "small" : undefined}
    >
      <InputLabel id={props.labelId} sx={{ color: "#AAAAAA" }}>
        {props.label}
      </InputLabel>
      <Select
        disabled={props.disabled}
        onChange={props.onChange}
        value={value}
        labelId={props.labelId}
        id={props.id}
        label={props.label}
      >
        {props.children}
      </Select>
    </FormControl>
  );
};

export default SearchSelect;
