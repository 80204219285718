import { ArrivalDexie } from "../../../../dexie/Arrival";
import { db } from "../../../../dexie/db";
import { DepartureDexie } from "../../../../dexie/Departure";
import { ReportDexie } from "../../../../dexie/Report";
import {
  ArrivalInput,
  BunkeringInput,
  CargoInput,
  DepartureInput,
  InputMaybe,
  useCreateErrorDataMutation,
} from "../../../../gen/graphql/types";
import { BunkeringDexie } from "../../../../dexie/Bunkering";
import { CargoDexie } from "../../../../dexie/Cargo";
import {
  safeNumberForMappingInputRequrie,
  safeNumberForMappingInput,
} from "../../../../utils/formats";

export const mapToDepartureInput = (
  reportData: DepartureDexie
): InputMaybe<DepartureInput> => {
  const report = reportData.data;

  return {
    id: 0,
    ship_id: report.ship_id,
    D_Vessel_Name: report.D_Vessel_Name,
    D_Voy: report.D_Voy,
    D_Last_Voy: report.D_Last_Voy,
    D_LorB: report.D_LorB,
    D_Departure_Port: report.D_Departure_Port,
    D_EU_UK_port: report.D_EU_UK_port,
    D_Port_L_U: report.D_Port_L_U,
    D_UNLOC: report.D_UNLOC,
    D_Departure_Date_Time: report.D_Departure_Date_Time,
    D_RU_Date_Time: report.D_RU_Date_Time,
    D_Time_Zone: report.D_Time_Zone,
    D_ROB_FO: report.D_ROB_FO,
    D_ROB_MGO: report.D_ROB_MGO,
    D_ROB_MECO: report.D_ROB_MECO,
    D_ROB_MESO: report.D_ROB_MESO,
    D_ROB_GESO: report.D_ROB_GESO,
    D_ROB_FW: report.D_ROB_FW,
    D_Cargo_Name: report.D_Cargo_Name,
    D_Cargo_Qtty: report.D_Cargo_Qtty,
    D_Draft_Fore: report.D_Draft_Fore,
    D_Draft_Aft: report.D_Draft_Aft,
    D_Next_Port_Name: report.D_Next_Port_Name,
    D_ETA: report.D_ETA,
    D_ETB: report.D_ETB,
    D_ETC: report.D_ETC,
    D_ETD: report.D_ETD,
    D_Remark: report.D_Remark,
    D_Spare_1: report.D_Spare_1,
    D_Spare_1_unit_name: report.D_Spare_1_unit_name,
    D_Spare_2: report.D_Spare_2,
    D_Spare_2_unit_name: report.D_Spare_2_unit_name,
    D_Spare_3: report.D_Spare_3,
    D_Spare_3_unit_name: report.D_Spare_3_unit_name,
    D_Spare_4: report.D_Spare_4,
    D_Spare_4_unit_name: report.D_Spare_4_unit_name,
    D_Spare_5: report.D_Spare_5,
    D_Spare_5_unit_name: report.D_Spare_5_unit_name,
    D_Spare_6: report.D_Spare_6,
    D_Spare_6_unit_name: report.D_Spare_6_unit_name,
    D_Spare_7: report.D_Spare_7,
    D_Spare_7_unit_name: report.D_Spare_7_unit_name,
    D_Spare_8: report.D_Spare_8,
    D_Spare_8_unit_name: report.D_Spare_8_unit_name,
    D_Spare_9: report.D_Spare_9,
    D_Spare_9_unit_name: report.D_Spare_9_unit_name,
    D_Spare_10: report.D_Spare_10,
    D_Spare_10_unit_name: report.D_Spare_10_unit_name,
    D_Spare_11: report.D_Spare_11,
    D_Spare_11_unit_name: report.D_Spare_11_unit_name,
    D_Spare_12: report.D_Spare_12,
    D_Spare_12_unit_name: report.D_Spare_12_unit_name,
    D_Spare_13: report.D_Spare_13,
    D_Spare_13_unit_name: report.D_Spare_13_unit_name,
    D_Spare_14: report.D_Spare_14,
    D_Spare_14_unit_name: report.D_Spare_14_unit_name,
    D_Spare_15: report.D_Spare_15,
    D_Spare_15_unit_name: report.D_Spare_15_unit_name,
    D_Spare_16: report.D_Spare_16,
    D_Spare_16_unit_name: report.D_Spare_16_unit_name,
    D_Spare_17: report.D_Spare_17,
    D_Spare_17_unit_name: report.D_Spare_17_unit_name,
    D_Spare_18: report.D_Spare_18,
    D_Spare_18_unit_name: report.D_Spare_18_unit_name,
    D_Spare_19: report.D_Spare_19,
    D_Spare_19_unit_name: report.D_Spare_19_unit_name,
    D_Spare_20: report.D_Spare_20,
    D_Spare_20_unit_name: report.D_Spare_20_unit_name,
  };
};

export const mapToArrivalInput = (
  reportData: ArrivalDexie
): InputMaybe<ArrivalInput> => {
  const report = reportData.data;

  return {
    id: 0,
    ship_id: report.ship_id,
    A_Vessel_Name: report.A_Vessel_Name,
    A_Voy: report.A_Voy,
    A_LorB: report.A_LorB,
    A_Arrival_Port: report.A_Arrival_Port,
    A_EU_UK_port: report.A_EU_UK_port,
    A_Port_L_U: report.A_Port_L_U,
    A_UNLOC: report.A_UNLOC,
    A_Arrival_Date_Time: report.A_Arrival_Date_Time,
    A_SB_or_Stop: report.A_SB_or_Stop,
    A_Time_Zone: report.A_Time_Zone,
    A_ETB: report.A_ETB,
    A_ETC: report.A_ETC,
    A_ETD: report.A_ETD,
    A_Berthed: report.A_Berthed,
    A_ROB_at_Arrival_FO: report.A_ROB_at_Arrival_FO,
    A_ROB_at_Arrival_MGO: report.A_ROB_at_Arrival_MGO,
    A_ROB_at_Arrival_MECO: report.A_ROB_at_Arrival_MECO,
    A_ROB_at_Arrival_MESO: report.A_ROB_at_Arrival_MESO,
    A_ROB_at_Arrival_GESO: report.A_ROB_at_Arrival_GESO,
    A_ROB_at_Arrival_FW: report.A_ROB_at_Arrival_FW,
    A_Hrs_since_Last_Noon: report.A_Hrs_since_Last_Noon,
    A_Distance_by_OG: report.A_Distance_by_OG,
    A_FO_Consumption_per_day_ME: report.A_FO_Consumption_per_day_ME,
    A_FO_Consumption_per_day_GE: report.A_FO_Consumption_per_day_GE,
    A_FO_Consumption_per_day_B_and_Inc:
      report.A_FO_Consumption_per_day_B_and_Inc,
    A_MGO_Consumption_per_day_ME: report.A_MGO_Consumption_per_day_ME,
    A_MGO_Consumption_per_day_GE: report.A_MGO_Consumption_per_day_GE,
    A_MGO_Consumption_per_day_B_and_Inc:
      report.A_MGO_Consumption_per_day_B_and_Inc,
    A_ROB_FO: report.A_ROB_FO,
    A_ROB_MGO: report.A_ROB_MGO,
    A_ROB_MECO: report.A_ROB_MECO,
    A_ROB_MESO: report.A_ROB_MESO,
    A_ROB_GESO: report.A_ROB_GESO,
    A_ROB_FW: report.A_ROB_FW,
    A_Draft_Fore: report.A_Draft_Fore,
    A_Draft_Aft: report.A_Draft_Aft,
    A_FO_CO2_emission: report.A_FO_CO2_emission,
    A_MGO_CO2_emission: report.A_MGO_CO2_emission,
    A_Remark: report.A_Remark,
    A_Spare_1: report.A_Spare_1,
    A_Spare_1_unit_name: report.A_Spare_1_unit_name,
    A_Spare_2: report.A_Spare_2,
    A_Spare_2_unit_name: report.A_Spare_2_unit_name,
    A_Spare_3: report.A_Spare_3,
    A_Spare_3_unit_name: report.A_Spare_3_unit_name,
    A_Spare_4: report.A_Spare_4,
    A_Spare_4_unit_name: report.A_Spare_4_unit_name,
    A_Spare_5: report.A_Spare_5,
    A_Spare_5_unit_name: report.A_Spare_5_unit_name,
    A_Spare_6: report.A_Spare_6,
    A_Spare_6_unit_name: report.A_Spare_6_unit_name,
    A_Spare_7: report.A_Spare_7,
    A_Spare_7_unit_name: report.A_Spare_7_unit_name,
    A_Spare_8: report.A_Spare_8,
    A_Spare_8_unit_name: report.A_Spare_8_unit_name,
    A_Spare_9: report.A_Spare_9,
    A_Spare_9_unit_name: report.A_Spare_9_unit_name,
    A_Spare_10: report.A_Spare_10,
    A_Spare_10_unit_name: report.A_Spare_10_unit_name,
    A_Spare_11: report.A_Spare_11,
    A_Spare_11_unit_name: report.A_Spare_11_unit_name,
    A_Spare_12: report.A_Spare_12,
    A_Spare_12_unit_name: report.A_Spare_12_unit_name,
    A_Spare_13: report.A_Spare_13,
    A_Spare_13_unit_name: report.A_Spare_13_unit_name,
    A_Spare_14: report.A_Spare_14,
    A_Spare_14_unit_name: report.A_Spare_14_unit_name,
    A_Spare_15: report.A_Spare_15,
    A_Spare_15_unit_name: report.A_Spare_15_unit_name,
    A_Spare_16: report.A_Spare_16,
    A_Spare_16_unit_name: report.A_Spare_16_unit_name,
    A_Spare_17: report.A_Spare_17,
    A_Spare_17_unit_name: report.A_Spare_17_unit_name,
    A_Spare_18: report.A_Spare_18,
    A_Spare_18_unit_name: report.A_Spare_18_unit_name,
    A_Spare_19: report.A_Spare_19,
    A_Spare_19_unit_name: report.A_Spare_19_unit_name,
    A_Spare_20: report.A_Spare_20,
    A_Spare_20_unit_name: report.A_Spare_20_unit_name,
  };
};

export const mapToBunkeringInput = (
  reportData: BunkeringDexie
): InputMaybe<BunkeringInput> => {
  const report = reportData.data;

  return {
    id: 0,
    ship_id: report.ship_id,
    B_Vessel_Name: report.B_Vessel_Name,
    B_Voy: report.B_Voy,
    B_Timezone: report.B_Timezone,
    B_Bunkering_Date_time: report.B_Bunkering_Date_time,
    B_LatLong: report.B_LatLong,
    B_PortName: report.B_PortName,
    B_UNLOC: report.B_UNLOC,
    B_Port_LorU: report.B_Port_LorU,
    B_EUorUK: report.B_EUorUK,
    B_Fuel_name_1: report.B_Fuel_name_1,
    B_Mass_1: report.B_Mass_1,
    B_Volume_1: report.B_Volume_1,
    B_Density_1: report.B_Density_1,
    B_Fuel_name_2: report.B_Fuel_name_2,
    B_Mass_2: report.B_Mass_2,
    B_Volume_2: report.B_Volume_2,
    B_Density_2: report.B_Density_2,
    B_Fuel_name_3: report.B_Fuel_name_3,
    B_Mass_3: report.B_Mass_3,
    B_Volume_3: report.B_Volume_3,
    B_Density_3: report.B_Density_3,
    B_Fuel_name_4: report.B_Fuel_name_4,
    B_Mass_4: report.B_Mass_4,
    B_Volume_4: report.B_Volume_4,
    B_Density_4: report.B_Density_4,
    B_Fuel_name_5: report.B_Fuel_name_5,
    B_Mass_5: report.B_Mass_5,
    B_Volume_5: report.B_Volume_5,
    B_Density_5: report.B_Density_5,
    B_Fuel_name_6: report.B_Fuel_name_6,
    B_Mass_6: report.B_Mass_6,
    B_Volume_6: report.B_Volume_6,
    B_Density_6: report.B_Density_6,
    B_Fuel_name_7: report.B_Fuel_name_7,
    B_Mass_7: report.B_Mass_7,
    B_Volume_7: report.B_Volume_7,
    B_Density_7: report.B_Density_7,
    B_Fuel_name_8: report.B_Fuel_name_8,
    B_Mass_8: report.B_Mass_8,
    B_Volume_8: report.B_Volume_8,
    B_Density_8: report.B_Density_8,
    B_Fuel_name_9: report.B_Fuel_name_9,
    B_Mass_9: report.B_Mass_9,
    B_Volume_9: report.B_Volume_9,
    B_Density_9: report.B_Density_9,
    B_Fuel_name_10: report.B_Fuel_name_10,
    B_Mass_10: report.B_Mass_10,
    B_Volume_10: report.B_Volume_10,
    B_Density_10: report.B_Density_10,
    B_Filename: report.B_Filename,
  };
};

export const mapToCargoInput = (
  reportData: CargoDexie
): InputMaybe<CargoInput> | undefined => {
  const report = reportData.data;
  if (!report) return;
  return {
    id: 0,
    C_Timezone: report.C_Timezone,
    C_DateTime: report.C_DateTime,
    C_Latitude: report.C_Latitude,
    C_Longitude: report.C_Longitude,
    C_Voyage_No: report.C_Voyage_No,
    C_Cargo_Port: report.C_Cargo_Port,
    C_UNLOC: report.C_UNLOC,
    C_EU_UK_port: report.C_EU_UK_port,
    C_Port_L_U: report.C_Port_L_U,
    C_Loading_Mass: safeNumberForMappingInput(report.C_Loading_Mass),
    C_Loading_TEU_Full: safeNumberForMappingInput(report.C_Loading_TEU_Full),
    C_Loading_TEU_Empty: safeNumberForMappingInput(report.C_Loading_TEU_Empty),
    C_Loading_Unit: safeNumberForMappingInput(report.C_Loading_Unit),
    C_Loading_Lane_metres: safeNumberForMappingInput(
      report.C_Loading_Lane_metres
    ),
    C_Loading_Number_of_Passengers: safeNumberForMappingInput(
      report.C_Loading_Number_of_Passengers
    ),
    C_Loading_Volume: safeNumberForMappingInput(report.C_Loading_Volume),
    C_Unloading_Mass: safeNumberForMappingInput(report.C_Unloading_Mass),
    C_Unloading_TEU_Full: safeNumberForMappingInput(
      report.C_Unloading_TEU_Full
    ),
    C_Unloading_TEU_Empty: safeNumberForMappingInput(
      report.C_Unloading_TEU_Empty
    ),
    C_Unloading_Unit: safeNumberForMappingInput(report.C_Unloading_Unit),
    C_Unloading_Lane_metres: safeNumberForMappingInput(
      report.C_Unloading_Lane_metres
    ),
    C_Unloading_Number_of_Passengers: safeNumberForMappingInput(
      report.C_Unloading_Number_of_Passengers
    ),
    C_Unloading_Volume: safeNumberForMappingInput(report.C_Unloading_Volume),
    C_Total_Mass: safeNumberForMappingInput(report.C_Total_Mass),
    C_Total_TEU_Full: safeNumberForMappingInput(report.C_Total_TEU_Full),
    C_Total_TEU_Empty: safeNumberForMappingInput(report.C_Total_TEU_Empty),
    C_Total_Unit: safeNumberForMappingInput(report.C_Total_Unit),
    C_Total_Lane_metres: safeNumberForMappingInput(report.C_Total_Lane_metres),
    C_Total_Number_of_Passengers: safeNumberForMappingInput(
      report.C_Total_Number_of_Passengers
    ),
    C_Total_Volume: safeNumberForMappingInput(report.C_Total_Volume),
    C_Displacement: safeNumberForMappingInputRequrie(report.C_Displacement),
    C_Water_density: safeNumberForMappingInputRequrie(report.C_Water_density),
    C_Total_ROB: safeNumberForMappingInputRequrie(report.C_Total_ROB),
    C_Filename: report.C_Filename || undefined,
  };
};

export const updateFrontReport = (props: ReportDexie) => {
  if (!props.id) throw Error("Noon Report data is incorrect.");
  const updateReport: ReportDexie = {
    ...props,
    isDraft: false,
    isSend: true,
  };

  db.reports.update(props.id, updateReport);
};

export const updateFrontDeparture = (props: DepartureDexie) => {
  if (!props.id) throw Error("Departure data is incorrect.");
  const newReport: DepartureDexie = {
    id: props.id,
    isDraft: false,
    isSend: true,
    data: props.data,
  };

  db.departure.update(props.id, newReport);
};

export const updateFrontArrival = (props: ArrivalDexie) => {
  if (!props.id) throw Error("Arrival data is incorrect.");
  const updateArrival: ArrivalDexie = {
    ...props,
    isDraft: false,
    isSend: true,
  };

  db.arrival.update(props.id, updateArrival);
};

export const updateFrontBunkering = (props: BunkeringDexie) => {
  if (!props.id) throw Error("Bunkering data is incorrect.");
  const newReport: BunkeringDexie = {
    id: props.id,
    isDraft: false,
    isSend: true,
    data: props.data,
  };

  db.bunkering.update(props.id, newReport);
};

export const updateFrontCargo = (props: CargoDexie) => {
  if (!props.id) throw Error("Cargo  data is incorrect.");

  const updateCargo: CargoDexie = {
    ...props,
    isDraft: false,
    isSend: true,
  };

  db.cargo.update(props.id, updateCargo);
};

export const useCreateErrorData = () => {
  const [CreateErrorData, { loading }] = useCreateErrorDataMutation();
  const createErrorData = (dataType: string, dataArr: unknown[]) => {
    CreateErrorData({
      variables: {
        input: {
          ErrorDataType: `${dataType}`,
          ErrorData: JSON.stringify(dataArr),
        },
      },
      onCompleted(data) {
        if (!data.createErrorData)
          console.error(`Failed CreateData at ${dataType}.`);
      },
      onError(error) {
        console.error(error);
      },
    });

    return {
      loading,
    };
  };
  return {
    createErrorData,
  };
};
