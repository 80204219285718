import { Departure } from "../../../../gen/graphql/types";
import {
  formatToCsvString,
  genSearchConditionHash,
} from "../../../../utils/download-csv-util";
import { formatToUtcYYYYMMDDHHMM, formatToYYYYMMDDHHMM } from "../../../../utils/formats";
import { ReportSearchCondition } from "../../pages.module";

type CsvDeparture = Omit<
  Departure,
  "created_at" | "updated_at" | "ship_id" | "ship" | "user" | "user_id"
>;

export const genCsv = (
  searchCondition: ReportSearchCondition,
  reportList: CsvDeparture[]
) => {
  const genFileName = async () => {
    const hashed = await genSearchConditionHash(searchCondition).catch((e) => {
      throw e;
    });
    const formatted = formatToYYYYMMDDHHMM(new Date(), "-", "", "");
    return `Departure_${formatted}-${hashed}.csv`;
  };

  const genText = () => {
    const head = [
      '"Report ID"',
      '"Vessel name"',
      '"Voyage number"',
      '"Last voyage number"',
      '"L or B"',
      '"Departure Port"',
      '"UNLOC"',
      '"EU/UK"',
      '"Port L/U"',
      '"Departure Date & Time (Local)"',
      '"R/U Date & Time (Local)"',
      '"Time Zone"',
      '"ROB FO (mt)"',
      '"ROB MGO (mt)"',
      '"ROB MECO (L)"',
      '"ROB MESO (L)"',
      '"ROB GESO (L)"',
      '"ROB FW (mt)"',
      '"ROB Fuel Type 1"',
      '"ROB Fuel Volume 1"',
      '"ROB Fuel Type 2"',
      '"ROB Fuel Volume 2"',
      '"ROB Fuel Type 3"',
      '"ROB Fuel Volume 3"',
      '"ROB Fuel Type 4"',
      '"ROB Fuel Volume 4"',
      '"ROB Fuel Type 5"',
      '"ROB Fuel Volume 5"',
      '"ROB Fuel Type 6"',
      '"ROB Fuel Volume 6"',
      '"ROB Fuel Type 7"',
      '"ROB Fuel Volume 7"',
      '"ROB Fuel Type 8"',
      '"ROB Fuel Volume 8"',
      '"ROB Fuel Type 9"',
      '"ROB Fuel Volume 9"',
      '"ROB Fuel Type 10"',
      '"ROB Fuel Volume 10"',
      '"Cargo name"',
      '"Cargo quantity"',
      '"Draft Fore (m)"',
      '"Draft Aft (m)"',
      '"Next port name"',
      '"ETA"',
      '"ETB"',
      '"ETC"',
      '"ETD"',
      '"Remark"',
      '"Spare 1"',
      '"Spare 1 unit name"',
      '"Spare 2"',
      '"Spare 2 unit name"',
      '"Spare 3"',
      '"Spare 3 unit name"',
      '"Spare 4"',
      '"Spare 4 unit name"',
      '"Spare 5"',
      '"Spare 5 unit name"',
      '"Spare 6"',
      '"Spare 6 unit name"',
      '"Spare 7"',
      '"Spare 7 unit name"',
      '"Spare 8"',
      '"Spare 8 unit name"',
      '"Spare 9"',
      '"Spare 9 unit name"',
      '"Spare 10"',
      '"Spare 10 unit name"',
      '"Spare 11"',
      '"Spare 11 unit name"',
      '"Spare 12"',
      '"Spare 12 unit name"',
      '"Spare 13"',
      '"Spare 13 unit name"',
      '"Spare 14"',
      '"Spare 14 unit name"',
      '"Spare 15"',
      '"Spare 15 unit name"',
      '"Spare 16"',
      '"Spare 16 unit name"',
      '"Spare 17"',
      '"Spare 17 unit name"',
      '"Spare 18"',
      '"Spare 18 unit name"',
      '"Spare 19"',
      '"Spare 19 unit name"',
      '"Spare 20"',
      '"Spare 20 unit name"',
    ].join(",");
    const body = reportList.map((report) => {
      const report_id = report.id;
      const D_Vessel_Name = formatToCsvString(report.D_Vessel_Name);
      const D_Voy = formatToCsvString(report.D_Voy);
      const D_Last_Voy = formatToCsvString(report.D_Last_Voy);
      const D_LorB = formatToCsvString(report.D_LorB);
      const D_Departure_Port = formatToCsvString(report.D_Departure_Port);
      const D_UNLOC = formatToCsvString(report.D_UNLOC);
      const D_EU_UK_port = report.D_EU_UK_port;
      const D_Port_L_U = report.D_Port_L_U;
      const D_Departure_Date_Time = formatToUtcYYYYMMDDHHMM(
        report.D_Departure_Date_Time
      );
      const D_RU_Date_Time = formatToUtcYYYYMMDDHHMM(report.D_RU_Date_Time);
      const D_Time_Zone = formatToCsvString(report.D_Time_Zone);
      const D_ROB_FO = report.D_ROB_FO;
      const D_ROB_MGO = report.D_ROB_MGO;
      const D_ROB_MECO = report.D_ROB_MECO;
      const D_ROB_MESO = report.D_ROB_MESO;
      const D_ROB_GESO = report.D_ROB_GESO;
      const D_ROB_FW = report.D_ROB_FW;
      const All_Additional_ROB_csv_data: string[][] = [];
      const All_Additional_ROB_Arr = report.D_Additional_ROB || [];

      for (let i = 0; i < 10; i++) {
        const dataAtIndex = All_Additional_ROB_Arr
          ? All_Additional_ROB_Arr[i]
          : false;

        if (dataAtIndex) {
          All_Additional_ROB_csv_data.push([
            dataAtIndex.D_Fuel_Type as string,
            dataAtIndex.D_Fuel_volume
              ? dataAtIndex.D_Fuel_volume.toString()
              : "-",
          ]);
        } else {
          All_Additional_ROB_csv_data.push(["", ""]);
        }
      }
      const D_Cargo_Name = formatToCsvString(report.D_Cargo_Name);
      const D_Cargo_Qtty = report.D_Cargo_Qtty;
      const D_Draft_Fore = report.D_Draft_Fore;
      const D_Draft_Aft = report.D_Draft_Aft;
      const D_Next_Port_Name = formatToCsvString(report.D_Next_Port_Name);
      const D_ETA = formatToUtcYYYYMMDDHHMM(report.D_ETA);
      const D_ETB = formatToUtcYYYYMMDDHHMM(report.D_ETB);
      const D_ETC = formatToUtcYYYYMMDDHHMM(report.D_ETC);
      const D_ETD = formatToUtcYYYYMMDDHHMM(report.D_ETD);
      const D_Remark = formatToCsvString(report.D_Remark);
      const D_Spare_1 = formatToCsvString(report.D_Spare_1);
      const D_Spare_1_unit_name = formatToCsvString(
        report.D_Spare_1_unit_name
      );
      const D_Spare_2 = formatToCsvString(report.D_Spare_2);
      const D_Spare_2_unit_name = formatToCsvString(
        report.D_Spare_2_unit_name
      );
      const D_Spare_3 = formatToCsvString(report.D_Spare_3);
      const D_Spare_3_unit_name = formatToCsvString(
        report.D_Spare_3_unit_name
      );
      const D_Spare_4 = formatToCsvString(report.D_Spare_4);
      const D_Spare_4_unit_name = formatToCsvString(
        report.D_Spare_4_unit_name
      );
      const D_Spare_5 = formatToCsvString(report.D_Spare_5);
      const D_Spare_5_unit_name = formatToCsvString(
        report.D_Spare_5_unit_name
      );
      const D_Spare_6 = formatToCsvString(report.D_Spare_6);
      const D_Spare_6_unit_name = formatToCsvString(
        report.D_Spare_6_unit_name
      );
      const D_Spare_7 = formatToCsvString(report.D_Spare_7);
      const D_Spare_7_unit_name = formatToCsvString(
        report.D_Spare_7_unit_name
      );
      const D_Spare_8 = formatToCsvString(report.D_Spare_8);
      const D_Spare_8_unit_name = formatToCsvString(
        report.D_Spare_8_unit_name
      );
      const D_Spare_9 = formatToCsvString(report.D_Spare_9);
      const D_Spare_9_unit_name = formatToCsvString(
        report.D_Spare_9_unit_name
      );
      const D_Spare_10 = formatToCsvString(report.D_Spare_10);
      const D_Spare_10_unit_name = formatToCsvString(
        report.D_Spare_10_unit_name
      );
      const D_Spare_11 = formatToCsvString(report.D_Spare_11);
      const D_Spare_11_unit_name = formatToCsvString(
        report.D_Spare_11_unit_name
      );
      const D_Spare_12 = formatToCsvString(report.D_Spare_12);
      const D_Spare_12_unit_name = formatToCsvString(
        report.D_Spare_12_unit_name
      );
      const D_Spare_13 = formatToCsvString(report.D_Spare_13);
      const D_Spare_13_unit_name = formatToCsvString(
        report.D_Spare_13_unit_name
      );
      const D_Spare_14 = formatToCsvString(report.D_Spare_14);
      const D_Spare_14_unit_name = formatToCsvString(
        report.D_Spare_14_unit_name
      );
      const D_Spare_15 = formatToCsvString(report.D_Spare_15);
      const D_Spare_15_unit_name = formatToCsvString(
        report.D_Spare_15_unit_name
      );
      const D_Spare_16 = formatToCsvString(report.D_Spare_16);
      const D_Spare_16_unit_name = formatToCsvString(
        report.D_Spare_16_unit_name
      );
      const D_Spare_17 = formatToCsvString(report.D_Spare_17);
      const D_Spare_17_unit_name = formatToCsvString(
        report.D_Spare_17_unit_name
      );
      const D_Spare_18 = formatToCsvString(report.D_Spare_18);
      const D_Spare_18_unit_name = formatToCsvString(
        report.D_Spare_18_unit_name
      );
      const D_Spare_19 = formatToCsvString(report.D_Spare_19);
      const D_Spare_19_unit_name = formatToCsvString(
        report.D_Spare_19_unit_name
      );
      const D_Spare_20 = formatToCsvString(report.D_Spare_20);
      const D_Spare_20_unit_name = formatToCsvString(
        report.D_Spare_20_unit_name
      );

      return [
        report_id,
        D_Vessel_Name,
        D_Voy,
        D_Last_Voy,
        D_LorB,
        D_Departure_Port,
        D_UNLOC,
        D_EU_UK_port,
        D_Port_L_U,
        D_Departure_Date_Time,
        D_RU_Date_Time,
        D_Time_Zone,
        D_ROB_FO,
        D_ROB_MGO,
        D_ROB_MECO,
        D_ROB_MESO,
        D_ROB_GESO,
        D_ROB_FW,
        All_Additional_ROB_csv_data.flatMap((v) => v),
        D_Cargo_Name,
        D_Cargo_Qtty,
        D_Draft_Fore,
        D_Draft_Aft,
        D_Next_Port_Name,
        D_ETA,
        D_ETB,
        D_ETC,
        D_ETD,
        D_Remark,
        D_Spare_1,
        D_Spare_1_unit_name,
        D_Spare_2,
        D_Spare_2_unit_name,
        D_Spare_3,
        D_Spare_3_unit_name,
        D_Spare_4,
        D_Spare_4_unit_name,
        D_Spare_5,
        D_Spare_5_unit_name,
        D_Spare_6,
        D_Spare_6_unit_name,
        D_Spare_7,
        D_Spare_7_unit_name,
        D_Spare_8,
        D_Spare_8_unit_name,
        D_Spare_9,
        D_Spare_9_unit_name,
        D_Spare_10,
        D_Spare_10_unit_name,
        D_Spare_11,
        D_Spare_11_unit_name,
        D_Spare_12,
        D_Spare_12_unit_name,
        D_Spare_13,
        D_Spare_13_unit_name,
        D_Spare_14,
        D_Spare_14_unit_name,
        D_Spare_15,
        D_Spare_15_unit_name,
        D_Spare_16,
        D_Spare_16_unit_name,
        D_Spare_17,
        D_Spare_17_unit_name,
        D_Spare_18,
        D_Spare_18_unit_name,
        D_Spare_19,
        D_Spare_19_unit_name,
        D_Spare_20,
        D_Spare_20_unit_name,
      ].join(",");
    });

    return [head, ...body].join("\n");
  };

  return (async () => {
    const fileName = await genFileName().catch((e) => {
      throw e;
    });
    const text = genText();

    return {
      fileName,
      text,
    };
  })();
};
