import { Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Detail from "../../../../organisms/Crew/Cargo/Detail";
import { CargoForm } from "../../../../pages/Crew/Cargo/type";

type ConfirmProps = {
  deleteReport(): void;
  onBack(): void;
  reportId: number;
  form: CargoForm;
  isSend: boolean;
};

const Confirm = (props: ConfirmProps): React.ReactElement => {
  const onClickBack = () => {
    props.onBack();
  };

  return (
    <React.Fragment>
      <Paper sx={{ backgroundColor: "#27292E", borderRadius: "20px" }}>
        <Detail
          isPreview={true}
          form={props.form}
          deleteReport={props.deleteReport}
          pageTitle={"Cargo Report"}
          isSend={props.isSend}
        />

        <Box
          sx={{
            textAlign: "left",
            px: 2,
            py: 4,
          }}
        >
          <Button
            sx={{
              mx: 2,
              width: "200px",
              height: "40px",
              backgroundColor: "#20BE89  !important",
              fontFamily: "Public Sans",
              "&.MuiButton-root": {
                fontFamily: "Public Sans",
              },
              textTransform: "none",
            }}
            variant="contained"
            onClick={onClickBack}
          >
            Back
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default Confirm;
