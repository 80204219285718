import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ANALYSIS_PDF_ID } from "./createAnalysisPDF";
import { formatToYYYYMMDDHHMM } from "../../../../../utils/formats";
import { Legend } from "../ReportView";
import { BaselineTitleList } from "../../../../pages/Owner/Analysis/const";
import { hotPink, skyBlue, vividGreen } from "../../../../../utils/color";
import { SpeedConsumptionFormViews } from "./SpeedConsumptionFormViews";
import { SpeedConsumptionFilterCondition } from "../../../../pages/Owner/Analysis/queries";
import { FilterBooleanType } from "../../../../pages/Owner/Analysis/types";
import { useEffect } from "react";
import { useDownloadZIP } from "./downloadZIP.hook";
import { checkBrowser } from "../../../../../utils/check-browser";

type Props = {
  children?: React.ReactNode;
  inner?: boolean;
  zIndex?: number;
  setIsDownloadZip: React.Dispatch<React.SetStateAction<boolean>>;
  isDownloadZip: boolean;
  filterForm1: SpeedConsumptionFilterCondition;
  filterForm2: SpeedConsumptionFilterCondition;
  filterForm3: SpeedConsumptionFilterCondition;
  filterForm4: SpeedConsumptionFilterCondition;
  checkedMeRevolutionOver100: FilterBooleanType;
  isMeUsageFilter: FilterBooleanType;
};

const isFirefox = "firefox" == checkBrowser();

const fontFamilyPdf = `${isFirefox ? "sans-serif" : ""}`;
const letterSpacingPdf = isFirefox ? 1.3 : undefined;

export const DownloadZIP = (props: Props): React.ReactElement => {
  const { downloadZip } = useDownloadZIP();

  useEffect(() => {
    if (props.isDownloadZip) {
      setTimeout(() => {
        downloadZip(
          [
            props.filterForm1,
            props.filterForm2,
            props.filterForm3,
            props.filterForm4,
          ],
          props.checkedMeRevolutionOver100,
          props.isMeUsageFilter
        ).then(() => {
          props.setIsDownloadZip(false);
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isDownloadZip]);

  return (
    <Box
      id={ANALYSIS_PDF_ID}
      sx={{ fontFamily: fontFamilyPdf, letterSpacing: letterSpacingPdf }}
    >
      {props.isDownloadZip && (
        <Box>
          <Typography
            sx={{
              fontFamily: "inherit",
              letterSpacing: "inherit",
              fontSize: 20,
              width: 250,
              mr: 1,
            }}
          >
            Speed Consumption
          </Typography>
          <Typography
            sx={{
              fontFamily: "inherit",
              letterSpacing: "inherit",
              textAlign: "right",
              fontSize: 14,
              width: "100%",
              pr: 4,
            }}
          >
            {formatToYYYYMMDDHHMM(new Date())}
          </Typography>
        </Box>
      )}
      <>{props.children}</>
      {props.isDownloadZip && (
        <>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <Legend
              title={BaselineTitleList.Ballast}
              circleColor={vividGreen}
            />
            <Legend title={BaselineTitleList.Laden} circleColor={skyBlue} />
            <Legend title={BaselineTitleList.Unique} circleColor={hotPink} />
          </Stack>
          <SpeedConsumptionFormViews
            filterForm1={props.filterForm1}
            filterForm2={props.filterForm2}
            filterForm3={props.filterForm3}
            filterForm4={props.filterForm4}
          />
        </>
      )}
    </Box>
  );
};
