import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { CargoForm } from "../type";
import Default, { useShip } from "../../../../layouts/DefaultCrew";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../../dexie/db";
import Template from "../../../../templates/Crew/Cargo/Update";
import { CargoDexie } from "../../../../../dexie/Cargo";
import Loading from "../../../../atoms/Loading";
import {
  mapToCreateCargoMutationVariables,
  useCargoForm,
} from "../cargo.module";
import { pageData } from "../../../../../App";
import CargoUpdateDetail from "../../../../templates/Crew/Cargo/Update/CargoUpdateDetail";
import { CargoInput } from "../../../../../gen/graphql/types";

const CargoUpdate = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const cargo = useLiveQuery(() => db.cargo.get(report_id));
  const navigate = useNavigate();
  const ship = useShip();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isDetail, setIsDetail] = useState(false);

  if (!report_id) navigate("/");

  const form = useCargoForm([cargo?.draftForm]);

  const onUpdateForm = (value: CargoForm) => {
    form.update(value);
  };

  const onSaveDraft = () => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const updateCargo: CargoDexie = {
      id: report_id,
      isDraft: true,
      isSend: false,
      data: undefined,
      draftForm: form.data,
      cargoFile: form.data.C_Filename ? cargoFile : undefined,
    };
    db.cargo
      .update(report_id, updateCargo)
      .catch((e: unknown) => console.error(e));
    navigate(pageData.crewCargo);
  };
  const [cargoFile, setFile] = useState<File | undefined>(cargo?.cargoFile);

  const onSave = () => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");

    const cargoInput: CargoInput = mapToCreateCargoMutationVariables(form.data);

    const updateCargo: CargoDexie = {
      id: report_id,
      isDraft: false,
      isSend: false,
      data: cargoInput,
      draftForm: form.data,
      cargoFile: form.data.C_Filename && cargoFile ? cargoFile : undefined,
    };
    db.cargo
      .update(report_id, updateCargo)
      .catch((e: unknown) => console.error(e));
    navigate(pageData.crewCargoUpdateComplete);
  };

  const onConfirm = () => {
    setIsConfirm(true);

    form.hasErrorScrollUp();
    if (form.hasError) return;
    setIsDetail(true);
  };

  useEffect(() => {
    setFile(cargo?.cargoFile);
  }, [cargo?.cargoFile]);

  return (
    <Default title="" selectedTitle={"Cargo"}>
      {isDetail ? (
        <CargoUpdateDetail
          action={"Update"}
          form={form.data}
          onSave={onSave}
          setIsDetail={setIsDetail}
        />
      ) : cargo && !form.isLoading ? (
        <Template
          form={form.data}
          hasFormError={form.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          onSaveDraft={onSaveDraft}
          setIsDetail={setIsDetail}
          setFile={setFile}
          isConfirm={isConfirm}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Default>
  );
};

export default CargoUpdate;
