import React from "react";
import Template from "../../templates/Crew/Crew";

const Crew = (): React.ReactElement => {
  return (
    <>
      <Template />
    </>
  );
};

export default Crew;
