import React from "react";
import { useNavigate, useParams } from "react-router";
import { db } from "../../../../dexie/db";
import Default from "../../../layouts/DefaultCrew";
import Template from "../../../templates/Crew/Departure/ReportDetail";
import { LocationState } from "./Create/types";
import { useLiveQuery } from "dexie-react-hooks";
import { pageData } from "../../../../App";
import { mapToFormDepartureData, useDepartureForm } from "./departure.module";
import { useGetLastFrontArrival } from "../../../../hooks/dexie/dexie";

const DepartureDetail = (props: LocationState): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const navigate = useNavigate();

  const onBack = () => {
    navigate(pageData.crewDeparture.path());
  };

  const deleteReport = () => {
    db.departure.delete(report_id).catch((e) => console.error(e));
    navigate(pageData.crewDeparture.path());
  };

  const departure = useLiveQuery(() => db.departure.get(report_id));

  const form = useDepartureForm([
    props.form,
    mapToFormDepartureData(departure?.data),
  ]);

  const previousArrival = useGetLastFrontArrival();

  return (
    <Default title="" selectedTitle={"Departure"}>
      <Template
        form={form.data}
        reportId={report_id}
        deleteReport={deleteReport}
        onBack={onBack}
        lastArrival={previousArrival}
        isSend={departure?.isSend !== undefined ? departure?.isSend : true}
      />
    </Default>
  );
};

export default DepartureDetail;
