import * as React from "react";
import { SxProps, Theme, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type Props = {
  text: string;
  sx?: SxProps<Theme>;
};

const CustomTooltip = (props: Props): React.ReactElement => {
  return (
    <Tooltip placement="bottom-end" arrow title={props.text}>
      <InfoOutlinedIcon
        sx={{ pl: 0.5, ml: 0.5, mt: 1, color: "#AAAAAA", ...props.sx }}
      />
    </Tooltip>
  );
};

export default CustomTooltip;
