import * as React from "react";
import Paper from "@mui/material/Paper";
import { Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { CargoForm } from "../../../../pages/Crew/Cargo/type";
import Detail from "../../../../organisms/Crew/Cargo/Detail";
import { ErrorMessageData } from "../../../../../hooks/errorMessage.hook";
import ErrorMessageList from "../../../../atoms/ErrorMessageList";

type ConfirmProps = {
  action: "Create" | "Update";
  onSave(): void;
  form: CargoForm;
  setIsDetail: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessageList: ErrorMessageData[];
};

const CargoServerUpdateDetail = (props: ConfirmProps): React.ReactElement => {
  return (
    <React.Fragment>
      <Paper
        sx={{
          backgroundColor: "#27292E",
          borderRadius: "20px",
          width: "1240px",
          overflowX: "hidden",
          ".container::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Detail
          pageTitle={`${props.action} Cargo Report`}
          isPreview={false}
          form={props.form}
          isSend={false}
          isServer={true}
        />

        <Divider textAlign="left"></Divider>
        <ErrorMessageList errorMessageList={props.errorMessageList} />

        <Box
          sx={{
            textAlign: "left",
            px: 2,
            py: 4,
          }}
        >
          <Button
            sx={{
              mx: 2,
              width: "250",
              backgroundColor: "#20BE89",
              fontFamily: "Public Sans",
              "&.MuiButton-root": {
                fontFamily: "Public Sans",
              },
              textTransform: "none",
            }}
            variant="contained"
            onClick={() => props.onSave()}
          >
            Save
          </Button>
          <Button
            sx={{ mx: 2, backgroundColor: "#555860 ", textTransform: "none" }}
            variant="contained"
            onClick={() => props.setIsDetail(false)}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default CargoServerUpdateDetail;
