import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import style from "./Loading.module.scss";

type Props = {
  children?: React.ReactNode;
  inner?: boolean;
  zIndex?: number;
  isLoading: boolean;
};

const Loading = (props: Props): React.ReactElement => {
  if (!props.isLoading) {
    return <div />;
  }

  return (
    <div
      className={`${style.loadingOverlay} ${props.inner ? style.inner : ""}`}
      style={{ zIndex: props.zIndex }}
    >
      <div className={style.loadingContainer}>
        <div>{props.children}</div>
        <div className={style.loading}>
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};

export default Loading;
