import { useCallback } from "react";
import {
  LorB,
  NonExistentImoNo,
  SteamMode,
  useReportGraphFilterLazyQuery,
} from "../../../../gen/graphql/types";
import { nonNullable } from "../../../../utils/formats";
import { filterByStandardDeviation } from "./analysisUtils";
import { ReportGraph } from "./types";

const bfScaleMin = 0;
const bfScaleMax = 4;
const meRevolutionMin = 25;
export const meRevolutionMax = 100;
const hrsPropellingDefaultMin = 23; // 基本的にhrsPropellingは23以上のデータのみ取得したいため、フィルターの初期値には23を設定する
const hrsPropellingMax = 30;

export type SpeedConsumptionFilterCondition = {
  imo_no: string | null;
  voy: string | null;
  from_dateTime: Date | null;
  to_dateTime: Date | null;
  bf_scale: number[] | null;
  me_revolution: number[] | null;
  hrs_propelling: number[] | null;
  ballast_or_laden: LorB | null;
  steam_mode: SteamMode | null;
  from_to_period: number;
};

export const ResetFilterCondition: SpeedConsumptionFilterCondition = {
  imo_no: NonExistentImoNo.NonExistentImoNo,
  voy: null,
  from_dateTime: null,
  to_dateTime: null,
  bf_scale: null,
  me_revolution: null,
  hrs_propelling: null,
  ballast_or_laden: null,
  steam_mode: null,
  from_to_period: 0,
};

export const DefaultFilterCondition: SpeedConsumptionFilterCondition = {
  imo_no: null,
  voy: null,
  from_dateTime: null,
  to_dateTime: null,
  bf_scale: [bfScaleMin, bfScaleMax],
  me_revolution: [meRevolutionMin, meRevolutionMax],
  hrs_propelling: [hrsPropellingDefaultMin, hrsPropellingMax],
  ballast_or_laden: null,
  steam_mode: null,
  from_to_period: 3,
};

export function checkRequiredFilterCondition(
  filterCondition: SpeedConsumptionFilterCondition
): boolean {
  return (
    !filterCondition.imo_no ||
    !filterCondition.from_dateTime ||
    !filterCondition.to_dateTime === null
  );
}

export function compareFilterConditions(
  conditions: SpeedConsumptionFilterCondition[],
  reference: SpeedConsumptionFilterCondition
): boolean {
  for (const condition of conditions) {
    if (!areEqualFilterCondition(reference, condition)) {
      return false;
    }
  }
  return true;
}

function areEqualFilterCondition(
  condition1: SpeedConsumptionFilterCondition,
  condition2: SpeedConsumptionFilterCondition
): boolean {
  return (
    condition1.imo_no === condition2.imo_no &&
    condition1.voy === condition2.voy &&
    condition1.from_dateTime === condition2.from_dateTime &&
    condition1.to_dateTime === condition2.to_dateTime &&
    condition1.bf_scale === condition2.bf_scale &&
    condition1.me_revolution === condition2.me_revolution &&
    condition1.hrs_propelling === condition2.hrs_propelling &&
    condition1.ballast_or_laden === condition2.ballast_or_laden &&
    condition1.steam_mode === condition2.steam_mode
  );
}

export const useReportsGraph = () => {
  const [getData, { data: response, loading: isLoading }] =
    useReportGraphFilterLazyQuery();
  const data = useCallback(
    (
      isMeUsageFilter?: boolean,
      isStandardDeviationFilter?: boolean
    ): ReportGraph[] => {
      if (!response?.reportList?.reportList) {
        return [];
      }

      const reportList = response.reportList.reportList
        .filter(
          (v): v is NonNullable<typeof v> =>
            v != null &&
            nonNullable(v.N_Speed_OG) &&
            v.N_Speed_OG != 0 &&
            nonNullable(v.N_Hrs_propelling)
        )
        .map((v) => {
          const fuelConsumptionSUM = isMeUsageFilter
            ? Number(v.N_FO_Consumption_per_day_ME)
            : Number(v.N_FO_Consumption_per_day_ME) +
              Number(v.N_FO_Consumption_per_day_GE) +
              Number(v.N_FO_Consumption_per_day_BInc);

          let foConsumptionPer24H;
          if (nonNullable(v.N_Hrs_propelling) && v.N_Hrs_propelling === 24) {
            foConsumptionPer24H = fuelConsumptionSUM;
          } else if (nonNullable(v.N_Hrs_propelling)) {
            foConsumptionPer24H =
              (fuelConsumptionSUM / v.N_Hrs_propelling) * 24; // N_Hrs_propellingが24でない場合、fuelConsumptionSUMを24時間換算する
          }

          return {
            id: v.id,
            N_Speed_OG: Number(v.N_Speed_OG),
            N_FO_Consumption_per_day: foConsumptionPer24H,
            N_DateTime: v.N_DateTime,
          } as ReportGraph;
        });

      if (isStandardDeviationFilter) {
        return filterByStandardDeviation(reportList);
      }

      return reportList as ReportGraph[];
    },
    [response]
  );

  const refetch = (condition: SpeedConsumptionFilterCondition) => {
    getData({
      variables: {
        input: {
          IMO_No: condition.imo_no,
          From_DateTime: condition?.from_dateTime,
          To_DateTime: condition?.to_dateTime,
          N_Voy: condition?.voy,
          N_LorB: condition?.ballast_or_laden,
          From_BF_scale: condition?.bf_scale?.[0],
          To_BF_scale: condition?.bf_scale?.[1],
          From_ME_RPM: condition?.me_revolution?.[0],
          To_ME_RPM: condition?.me_revolution?.[1],
          From_Hrs_propelling: condition?.hrs_propelling?.[0],
          To_Hrs_propelling: condition?.hrs_propelling?.[1],
          N_Steam_mode: condition?.steam_mode,
        },
      },
    });
  };

  return {
    isLoading,
    data,
    refetch,
  };
};
