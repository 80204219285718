import { useMemo } from "react";
import { useLazyInitializeState } from "../../../../utils/lazy-initialize-state";
import { CargoFormRequired, CargoInput } from "../../../../gen/graphql/types";
import { CargoForm, initializeCargo } from "./type";
import {
  getLatLong,
  getLatLongValues,
  getTimeZone,
  getTimeZoneValues,
} from "../../../../utils/form-util";
import { useShip } from "../../../layouts/DefaultCrew";
import { hasLatLonValidateError } from "../../../../infrastructure/common/validate";

type Maybe<T> = T | null | undefined;

const dynamicFormErrorCheck = (
  cargoFormRequired?: CargoFormRequired,
  value?: string | number | Date | null
) => {
  switch (cargoFormRequired) {
    case CargoFormRequired.Required:
      return value ? false : true;
    case CargoFormRequired.Optional:
      return false;
    case CargoFormRequired.NotRequired:
      return false;
    default:
      return false;
  }
};

export const useCargoForm = (initializes: Maybe<CargoForm>[]) => {
  const ship = useShip();
  const [isLoading, data, update] = useLazyInitializeState<CargoForm>(
    initializeCargo,
    initializes
  );
  const {
    C_DateTime,
    C_Lat_Direction,
    C_Lat_Deg,
    C_Lat_Min,
    C_Long_Direction,
    C_Long_Deg,
    C_Long_Min,
    C_Voyage_No,
    C_Cargo_Port,
    C_UNLOC,
    C_EU_UK_port,
    C_Port_L_U,
    C_Loading_Mass,
    C_Loading_TEU_Full,
    C_Loading_TEU_Empty,
    C_Loading_Unit,
    C_Loading_Lane_metres,
    C_Loading_Number_of_Passengers,
    C_Loading_Volume,
    C_Unloading_Mass,
    C_Unloading_TEU_Full,
    C_Unloading_TEU_Empty,
    C_Unloading_Unit,
    C_Unloading_Lane_metres,
    C_Unloading_Number_of_Passengers,
    C_Unloading_Volume,
    C_Total_Mass,
    C_Total_TEU_Full,
    C_Total_TEU_Empty,
    C_Total_Unit,
    C_Total_Lane_metres,
    C_Total_Number_of_Passengers,
    C_Total_Volume,
    C_Displacement,
    C_Water_density,
    C_Total_ROB,
  } = data;

  const hasError = useMemo(() => {
    return (
      [
        C_DateTime,
        C_Lat_Direction,
        C_Lat_Deg,
        C_Lat_Min,
        C_Long_Direction,
        C_Long_Deg,
        C_Long_Min,
        C_Voyage_No,
        C_Cargo_Port,
        C_UNLOC,
        C_EU_UK_port,
        C_Port_L_U,
        dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Mass, C_Loading_Mass),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_TEU_Full,
          C_Loading_TEU_Full
        ),
        dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Mass, C_Loading_Mass),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_TEU_Full,
          C_Loading_TEU_Full
        ),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_TEU_Empty,
          C_Loading_TEU_Empty
        ),
        dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Unit, C_Loading_Unit),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_Lane_metres,
          C_Loading_Lane_metres
        ),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_Number_of_Passengers,
          C_Loading_Number_of_Passengers
        ),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_Volume,
          C_Loading_Volume
        ),
        dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Mass, C_Unloading_Mass),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_TEU_Full,
          C_Unloading_TEU_Full
        ),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_TEU_Empty,
          C_Unloading_TEU_Empty
        ),
        dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Unit, C_Unloading_Unit),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_Lane_metres,
          C_Unloading_Lane_metres
        ),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_Number_of_Passengers,
          C_Unloading_Number_of_Passengers
        ),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_Volume,
          C_Unloading_Volume
        ),
        dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Mass, C_Total_Mass),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_TEU_Full,
          C_Total_TEU_Full
        ),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_TEU_Empty,
          C_Total_TEU_Empty
        ),
        dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Unit, C_Total_Unit),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_Lane_metres,
          C_Total_Lane_metres
        ),
        dynamicFormErrorCheck(
          ship?.cargoFormSetting?.C_Number_of_Passengers,
          C_Total_Number_of_Passengers
        ),
        dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Volume, C_Total_Volume),
        C_Displacement,
        C_Water_density,
        C_Total_ROB,
      ].some((item) => item === null || item === undefined || item === "") ||
      hasLatLonValidateError(
        C_Lat_Deg,
        C_Lat_Min,
        C_Lat_Direction,
        C_Long_Deg,
        C_Long_Min,
        C_Long_Direction
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const hasErrorScrollUp = () => {
    const upperForm = [
      C_DateTime,
      C_Lat_Direction,
      C_Lat_Deg,
      C_Lat_Min,
      C_Long_Direction,
      C_Long_Deg,
      C_Long_Min,
      C_Voyage_No,
      C_Cargo_Port,
      C_UNLOC,
      C_EU_UK_port,
      C_Port_L_U,
    ];
    const lowerForm = [
      dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Mass, C_Loading_Mass),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_TEU_Full,
        C_Loading_TEU_Full
      ),
      dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Mass, C_Loading_Mass),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_TEU_Full,
        C_Loading_TEU_Full
      ),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_TEU_Empty,
        C_Loading_TEU_Empty
      ),
      dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Unit, C_Loading_Unit),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_Lane_metres,
        C_Loading_Lane_metres
      ),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_Number_of_Passengers,
        C_Loading_Number_of_Passengers
      ),
      dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Volume, C_Loading_Volume),
      dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Mass, C_Unloading_Mass),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_TEU_Full,
        C_Unloading_TEU_Full
      ),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_TEU_Empty,
        C_Unloading_TEU_Empty
      ),
      dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Unit, C_Unloading_Unit),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_Lane_metres,
        C_Unloading_Lane_metres
      ),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_Number_of_Passengers,
        C_Unloading_Number_of_Passengers
      ),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_Volume,
        C_Unloading_Volume
      ),
      dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Mass, C_Total_Mass),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_TEU_Full,
        C_Total_TEU_Full
      ),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_TEU_Empty,
        C_Total_TEU_Empty
      ),
      dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Unit, C_Total_Unit),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_Lane_metres,
        C_Total_Lane_metres
      ),
      dynamicFormErrorCheck(
        ship?.cargoFormSetting?.C_Number_of_Passengers,
        C_Total_Number_of_Passengers
      ),
      dynamicFormErrorCheck(ship?.cargoFormSetting?.C_Volume, C_Total_Volume),
      C_Displacement,
      C_Water_density,
      C_Total_ROB,
    ];
    if (
      upperForm.filter((v) => !v).length != 0 ||
      hasLatLonValidateError(
        C_Lat_Deg,
        C_Lat_Min,
        C_Lat_Direction,
        C_Long_Deg,
        C_Long_Min,
        C_Long_Direction
      )
    ) {
      window.scrollTo({
        top: 150,
        behavior: "smooth",
      });
    } else if (lowerForm.filter((v) => !v).length != 0) {
      window.scrollTo({
        top: 400,
        behavior: "smooth",
      });
    }
  };

  return {
    isLoading,
    data,
    hasError,
    update,
    hasErrorScrollUp,
  };
};

export const mapToCargoFormData = (report: CargoInput): CargoForm => {
  const LatLong = getLatLongValues(
    report.C_Latitude + "/" + report.C_Longitude
  );

  const { TimeZone_PorN, TimeZone_HH, TimeZone_MM } = getTimeZoneValues(
    report.C_Timezone
  );

  return {
    C_TimeZone_PorN: TimeZone_PorN,
    C_TimeZone_HH: TimeZone_HH,
    C_TimeZone_MM: TimeZone_MM,
    C_DateTime: report.C_DateTime,
    C_Lat_Direction: LatLong.Lat_Direction,
    C_Lat_Deg: LatLong.Lat_Deg,
    C_Lat_Min: LatLong.Lat_Min,
    C_Long_Direction: LatLong.Long_Direction,
    C_Long_Deg: LatLong.Long_Deg,
    C_Long_Min: LatLong.Long_Min,
    C_Voyage_No: report.C_Voyage_No,
    C_Cargo_Port: report.C_Cargo_Port,
    C_UNLOC: report.C_UNLOC,
    C_EU_UK_port: report.C_EU_UK_port,
    C_Port_L_U: report.C_Port_L_U,
    C_Loading_Mass: report.C_Loading_Mass || undefined,
    C_Loading_TEU_Full: report.C_Loading_TEU_Full || undefined,
    C_Loading_TEU_Empty: report.C_Loading_TEU_Empty || undefined,
    C_Loading_Unit: report.C_Loading_Unit || undefined,
    C_Loading_Lane_metres: report.C_Loading_Lane_metres || undefined,
    C_Loading_Number_of_Passengers:
      report.C_Loading_Number_of_Passengers || undefined,
    C_Loading_Volume: report.C_Loading_Volume || undefined,
    C_Unloading_Mass: report.C_Unloading_Mass || undefined,
    C_Unloading_TEU_Full: report.C_Unloading_TEU_Full || undefined,
    C_Unloading_TEU_Empty: report.C_Unloading_TEU_Empty || undefined,
    C_Unloading_Unit: report.C_Unloading_Unit || undefined,
    C_Unloading_Lane_metres: report.C_Unloading_Lane_metres || undefined,
    C_Unloading_Number_of_Passengers:
      report.C_Unloading_Number_of_Passengers || undefined,
    C_Unloading_Volume: report.C_Unloading_Volume || undefined,
    C_Total_Mass: report.C_Total_Mass || undefined,
    C_Total_TEU_Full: report.C_Total_TEU_Full || undefined,
    C_Total_TEU_Empty: report.C_Total_TEU_Empty || undefined,
    C_Total_Unit: report.C_Total_Unit || undefined,
    C_Total_Lane_metres: report.C_Total_Lane_metres || undefined,
    C_Total_Number_of_Passengers:
      report.C_Total_Number_of_Passengers || undefined,
    C_Total_Volume: report.C_Total_Volume || undefined,
    C_Displacement: report.C_Displacement || undefined,
    C_Water_density: report.C_Water_density || undefined,
    C_Total_ROB: report.C_Total_ROB || undefined,
    C_Filename: report.C_Filename || "",
  };
};

export const mapToCreateCargoMutationVariables = (
  form: CargoForm,
  report_id?: number
): CargoInput => {
  const LatLong = getLatLong(
    form.C_Lat_Deg?.toString(),
    form.C_Lat_Min,
    form.C_Lat_Direction,
    form.C_Long_Deg,
    form.C_Long_Min,
    form.C_Long_Direction
  ).split("/");

  return {
    id: report_id ?? 0,
    C_Timezone: getTimeZone(
      form.C_TimeZone_PorN,
      form.C_TimeZone_HH,
      form.C_TimeZone_MM
    ),
    C_DateTime: form.C_DateTime || new Date(),
    C_Latitude: LatLong[0],
    C_Longitude: LatLong[1],
    C_Voyage_No: form.C_Voyage_No,
    C_Cargo_Port: form.C_Cargo_Port,
    C_UNLOC: form.C_UNLOC,
    C_EU_UK_port: form.C_EU_UK_port,
    C_Port_L_U: form.C_Port_L_U,
    C_Loading_Mass: form.C_Loading_Mass,
    C_Loading_TEU_Full: form.C_Loading_TEU_Full,
    C_Loading_TEU_Empty: form.C_Loading_TEU_Empty,
    C_Loading_Unit: form.C_Loading_Unit,
    C_Loading_Lane_metres: form.C_Loading_Lane_metres,
    C_Loading_Number_of_Passengers: form.C_Loading_Number_of_Passengers,
    C_Loading_Volume: form.C_Loading_Volume,
    C_Unloading_Mass: form.C_Unloading_Mass,
    C_Unloading_TEU_Full: form.C_Unloading_TEU_Full,
    C_Unloading_TEU_Empty: form.C_Unloading_TEU_Empty,
    C_Unloading_Unit: form.C_Unloading_Unit,
    C_Unloading_Lane_metres: form.C_Unloading_Lane_metres,
    C_Unloading_Number_of_Passengers: form.C_Unloading_Number_of_Passengers,
    C_Unloading_Volume: form.C_Unloading_Volume,
    C_Total_Mass: form.C_Total_Mass,
    C_Total_TEU_Full: form.C_Total_TEU_Full,
    C_Total_TEU_Empty: form.C_Total_TEU_Empty,
    C_Total_Unit: form.C_Total_Unit,
    C_Total_Lane_metres: form.C_Total_Lane_metres,
    C_Total_Number_of_Passengers: form.C_Total_Number_of_Passengers,
    C_Total_Volume: form.C_Total_Volume,
    C_Displacement: form.C_Displacement,
    C_Water_density: form.C_Water_density,
    C_Total_ROB: form.C_Total_ROB,
    C_Filename: form.C_Filename,
  };
};
