import { ViewProps } from "../../../organisms/Owner/Analysis/ReportView";
import { ReferenceData, ReportGraph } from "./types";
import { BaselineTitleList, FilterTitleList } from "./const";
import { calculateLeastSquareFirstCoefficient } from "../../../../utils/calculate-least-square-first-coefficient";
import { isString } from "../../../../utils/type-checks";
import {
  calculateY,
  getMaxValue,
  getReferenceDataCoefficient,
  getTrendlineValues,
} from "./analysisDataUtils";
import { formatToUtcYYYYMMDDHHMM } from "../../../../utils/formats";
import { orgRound } from "../../../../utils/calculate";

const graphIndexes = {
  speedOgIndex: 0,
  graph1Index: 1,
  graph2Index: 3,
  graph3Index: 5,
  graph4Index: 7,
  ballastIndex: 9,
  ladenIndex: 10,
  uniqueIndex: 11,
};

type CustomHTMLContentTYPE = {
  shipName: string;
  date: Date;
  fc: number;
  speed_OG: number;
};

function createCustomHTMLContent(props: CustomHTMLContentTYPE): string {
  const tdStyle = "text-align: left; white-space: nowrap;";
  return `<div style="padding:5px 10px; border:1px solid #ddd;">
            <table style="width: 100%;">
              <tr>
                <td style="${tdStyle}"><b>Vessel Name:</b></td>
                <td style="${tdStyle}">${props.shipName}</td>
              </tr>
              <tr>
                <td style="text-align: left;"><b>Date & Time:</b></td>
                <td style="${tdStyle}">${formatToUtcYYYYMMDDHHMM(
    props.date
  )}</td>
              </tr>
              <tr>
                <td style="text-align: left;"><b>FC：</b></td>
                <td style="${tdStyle}">${orgRound(
    props.fc,
    1000
  )}&nbsp;mt/day</td>
              </tr>
              <tr>
                <td style="text-align: left;"><b>Speed(OG):</b></td>
                <td style="${tdStyle}">${orgRound(
    props.speed_OG,
    1000
  )}&nbsp;knot</td>
              </tr>
            </table>
          </div>`;
}

export type ReportViewProps = {
  referenceDataList: ReferenceData[];
  graph1: ReportGraph[];
  graph2: ReportGraph[];
  graph3: ReportGraph[];
  graph4: ReportGraph[];
};

const getGraphCoefficient = (graphs: ReportGraph[]) => {
  const graphCoordinates = graphs
    .map((graph: ReportGraph) => {
      return [Number(graph.N_Speed_OG), graph.N_FO_Consumption_per_day];
    })
    .filter((v) => v[0] > 0);

  const graphCoefficient = calculateLeastSquareFirstCoefficient(
    graphCoordinates,
    3
  );

  return graphCoefficient;
};

export type HtmlTooltip = { role: string; type: string; p: { html: true } };

export const getReportGraphData = (props: ViewProps) => {
  let report: (string | number | HtmlTooltip)[][] = [
    [
      "Speed_OG",
      FilterTitleList.Filter_1,
      { role: "tooltip", type: "string", p: { html: true } },
      FilterTitleList.Filter_2,
      { role: "tooltip", type: "string", p: { html: true } },
      FilterTitleList.Filter_3,
      { role: "tooltip", type: "string", p: { html: true } },
      FilterTitleList.Filter_4,
      { role: "tooltip", type: "string", p: { html: true } },
      BaselineTitleList.Ballast,
      BaselineTitleList.Laden,
      BaselineTitleList.Unique,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
  ];

  props.graph1
    .filter((v: ReportGraph) => {
      return Number(v.N_Speed_OG) !== 0;
    })
    .forEach((graph1: ReportGraph) => {
      report.push([
        graph1.N_Speed_OG,
        graph1.N_FO_Consumption_per_day,
        createCustomHTMLContent({
          shipName: props.shipNames.ship1,
          date: graph1.N_DateTime,
          fc: graph1.N_FO_Consumption_per_day,
          speed_OG: Number(graph1.N_Speed_OG),
        }),
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
      ]);
    });

  props.graph2
    .filter((v: ReportGraph) => {
      return Number(v.N_Speed_OG) !== 0;
    })
    .forEach((graph2: ReportGraph) => {
      report.push([
        graph2.N_Speed_OG,
        NaN,
        NaN,
        graph2.N_FO_Consumption_per_day,
        createCustomHTMLContent({
          shipName: props.shipNames.ship2,
          date: graph2.N_DateTime,
          fc: graph2.N_FO_Consumption_per_day,
          speed_OG: graph2.N_Speed_OG,
        }),
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
      ]);
    });

  props.graph3.forEach((graph3: ReportGraph) => {
    report.push([
      graph3.N_Speed_OG,
      NaN,
      NaN,
      NaN,
      NaN,
      graph3.N_FO_Consumption_per_day,
      createCustomHTMLContent({
        shipName: props.shipNames.ship3,
        date: graph3.N_DateTime,
        fc: graph3.N_FO_Consumption_per_day,
        speed_OG: graph3.N_Speed_OG,
      }),
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
    ]);
  });

  props.graph4
    .filter((v: ReportGraph) => {
      return Number(v.N_Speed_OG) !== 0;
    })
    .forEach((graph4: ReportGraph) => {
      report.push([
        graph4.N_Speed_OG,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        graph4.N_FO_Consumption_per_day,
        createCustomHTMLContent({
          shipName: props.shipNames.ship4,
          date: graph4.N_DateTime,
          fc: graph4.N_FO_Consumption_per_day,
          speed_OG: graph4.N_Speed_OG,
        }),
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
      ]);
    });

  props.referenceDataList
    .filter((v: ReferenceData) => {
      return Number(v.Speed_OG) !== 0;
    })
    .forEach((data: ReferenceData) => {
      report.push([
        data.Speed_OG,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        data.fuel_consumption_Ballast,
        data.fuel_consumption_Laden,
        data.fuel_consumption_Unique ?? NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
      ]);
    });

  report.sort((a, b) => Number(a[0]) - Number(b[0]));

  const maxValueX = getMaxValue(report, graphIndexes.speedOgIndex);

  const maxValueY1 = getMaxValue(report, graphIndexes.graph1Index);
  const maxValueY2 = getMaxValue(report, graphIndexes.graph2Index);
  const maxValueY3 = getMaxValue(report, graphIndexes.graph3Index);
  const maxValueY4 = getMaxValue(report, graphIndexes.graph4Index);
  const maxValueY5 = getMaxValue(report, graphIndexes.ballastIndex);
  const maxValueY6 = getMaxValue(report, graphIndexes.ladenIndex);
  const maxValueY7 = getMaxValue(report, graphIndexes.uniqueIndex);

  const maxValueY =
    [
      maxValueY1,
      maxValueY2,
      maxValueY3,
      maxValueY4,
      maxValueY5,
      maxValueY6,
      maxValueY7,
    ]
      .sort((a, b) => a - b)
      .pop() || 0;

  const trendlineXValues = getTrendlineValues(
    isString(maxValueX) ? 0 : maxValueX
  );

  const graph1Coefficient = getGraphCoefficient(props.graph1);
  const graph2Coefficient = getGraphCoefficient(props.graph2);
  const graph3Coefficient = getGraphCoefficient(props.graph3);
  const graph4Coefficient = getGraphCoefficient(props.graph4);
  const ballastCoefficient = getReferenceDataCoefficient(
    report,
    graphIndexes.ballastIndex
  );
  const ladenCoefficient = getReferenceDataCoefficient(
    report,
    graphIndexes.ladenIndex
  );
  const uniqueCoefficient = getReferenceDataCoefficient(
    report,
    graphIndexes.uniqueIndex
  );

  trendlineXValues.forEach((x: number) => {
    const y1 = calculateY(graph1Coefficient, x, maxValueY);
    const y2 = calculateY(graph2Coefficient, x, maxValueY);
    const y3 = calculateY(graph3Coefficient, x, maxValueY);
    const y4 = calculateY(graph4Coefficient, x, maxValueY);
    const y5 = calculateY(ballastCoefficient, x, maxValueY);
    const y6 = calculateY(ladenCoefficient, x, maxValueY);
    const y7 = calculateY(uniqueCoefficient, x, maxValueY);

    report.push([
      x,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      NaN,
      y1,
      y2,
      y3,
      y4,
      y5,
      y6,
      y7,
    ]);
  });

  if (report.length === 1) report = []; //ヘッダーのみは表示しない

  return report;
};
