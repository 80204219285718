import {
  ApolloProvider,
  ApolloClient,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { createUploadLink } from "apollo-upload-client";
import { FC } from "react";

export const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

type Props = {
  children?: React.ReactNode;
};

export const AppApolloProvider: FC<Props> = (props: Props) => {
  const auth0 = useAuth0();
  try {
    const client = createClient({
      backendUrl,
      getToken: async () => {
        const claims = await auth0.getIdTokenClaims();
        if (!claims) throw new Error("Authentication failed.");
        return claims.__raw;
      },
    });

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

type Options = {
  backendUrl: string;
  getToken: TokenGenerator;
  debug?: boolean;
};

type TokenGenerator = () => Promise<string>;

export const createClient = ({
  backendUrl,
  getToken,
  debug,
}: Options): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    link: from([
      createAuthLink(getToken),
      createUploadLink({ uri: backendUrl }),
    ]),
    cache: new InMemoryCache(),
    connectToDevTools: !!debug,
  });

const createAuthLink = (getToken: TokenGenerator) =>
  setContext(async (_, { headers }: { headers: Record<string, unknown> }) => {
    const token = await getToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: { ...headers, authorization: token ? `Bearer ${token}` : "" },
    };
  });
