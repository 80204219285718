import React from "react";
import { InputAdornment, SxProps, TextField, Theme } from "@mui/material";
import { nonNullable } from "../../utils/formats";

type Props = {
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  id: string;
  label: string;
  value: number | null | undefined;
  unitName?: string;
  onChange(value: string): void;
  maxValue?: number;
  minValue?: number;
  sx?: SxProps<Theme>;
  isConfirm?: boolean;
  setIsConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage?: string;
};

const valueCheck = (props: Props) => {
  if (props.errorMessage != null) {
    return {
      isError: true,
      errorMessage: props.errorMessage,
    };
  }
  if (props.value !== 0 && !props.value) {
    if (!props.isConfirm) {
      return {
        isError: false,
        errorMessage: "",
      };
    } else {
      return {
        isError: true,
        errorMessage:
          props.errorMessage !== undefined
            ? props.errorMessage
            : "This field is required.",
      };
    }
  }

  if (nonNullable(props.minValue) && props.value < props.minValue) {
    return {
      isError: true,
      errorMessage: `Input value is below the minimum value ${props.minValue}.`,
    };
  } else if (nonNullable(props.maxValue) && props.maxValue < props.value) {
    return {
      isError: true,
      errorMessage: `Input value exceeds the maximum value ${props.maxValue}.`,
    };
  } else {
    return {
      isError: false,
      errorMessage: "",
    };
  }
};

const CommonNumberFIeld = (props: Props): React.ReactElement => {
  const { isError, errorMessage } = valueCheck(props);

  return (
    <TextField
      type="number"
      size="small"
      disabled={props.disabled}
      InputProps={{
        readOnly: props.readonly,
        endAdornment: (
          <InputAdornment position="end">{props.unitName}</InputAdornment>
        ),
      }}
      sx={{
        width: "18ch",
        color: "#FFFFFF",
        "& .MuiInputBase-input": { color: "#FFFFFF" },
        "& label.MuiInputLabel-root": {
          color: "#AAAAAA",
        },
        "& label.Mui-focused": {
          color: "#20BE89",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#20BE89",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#464E5A",
          },
          "&:hover fieldset": {
            borderColor: "#AAAAAA",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#20BE89",
          },
          "&.Mui-disabled fieldset": {
            borderColor: "#383838",
          },
        },
        ...props.sx,
      }}
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      value={props.value === 0 ? 0 : props.value || ""}
      inputProps={{
        "aria-label": `${props.id}`,
        maxLength: 50,
      }}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
      error={isError}
      helperText={errorMessage}
    />
  );
};

export default CommonNumberFIeld;
