import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import Template from "../../../templates/Owner/Bunkering/BunkeringDetail";
import { LocationState, Form } from "../../Crew/Bunkering/Create/types";
import { useLazyInitializeState } from "../../../../utils/lazy-initialize-state";
import {
  useGetOwnerBunkeringQuery,
  LorU,
  GetOwnerBunkeringQuery,
} from "../../../../gen/graphql/types";
import { pageData } from "../../../../App";
import DefaultOwner from "../../../layouts/DefaultOwner";
import { emptyToDoubleQuotation } from "../../../../utils/formats";
import {
  getLatLongValues,
  getTimeZoneValues,
} from "../../../../utils/form-util";
import { setDefaultNullGgraphQLInputToForm } from "../../../../utils/graphql-mappimg";

type Maybe<T> = T | null | undefined;

export const useForm = (initializes: Maybe<Form>[]) => {
  const initialize = {
    ship_id: null,
    user_id: null,
    B_Vessel_Name: null,
    B_Voy: null,
    B_TimeZone_PorN: "+",
    B_TimeZone_HH: "00",
    B_TimeZone_MM: "00",
    B_Bunkering_Date_time: null,
    B_Lat_Direction: "N",
    B_Lat_Deg: null,
    B_Lat_Min: null,
    B_Long_Direction: "E",
    B_Long_Deg: null,
    B_Long_Min: null,
    B_PortName: null,
    B_UNLOC: null,
    B_Port_LorU: LorU.Y,
    B_EUorUK: null,
    B_Fuel_name_1: null,
    B_Mass_1: null,
    B_Volume_1: null,
    B_Density_1: null,
    B_Fuel_name_2: null,
    B_Mass_2: null,
    B_Volume_2: null,
    B_Density_2: null,
    B_Fuel_name_3: null,
    B_Mass_3: null,
    B_Volume_3: null,
    B_Density_3: null,
    B_Fuel_name_4: null,
    B_Mass_4: null,
    B_Volume_4: null,
    B_Density_4: null,
    B_Fuel_name_5: null,
    B_Mass_5: null,
    B_Volume_5: null,
    B_Density_5: null,
    B_Fuel_name_6: null,
    B_Mass_6: null,
    B_Volume_6: null,
    B_Density_6: null,
    B_Fuel_name_7: null,
    B_Mass_7: null,
    B_Volume_7: null,
    B_Density_7: null,
    B_Fuel_name_8: null,
    B_Mass_8: null,
    B_Volume_8: null,
    B_Density_8: null,
    B_Fuel_name_9: null,
    B_Mass_9: null,
    B_Volume_9: null,
    B_Density_9: null,
    B_Fuel_name_10: null,
    B_Mass_10: null,
    B_Volume_10: null,
    B_Density_10: null,
    B_Filename: "",
  };
  const [isLoading, data, update] = useLazyInitializeState<Form>(
    initialize,
    initializes
  );

  return {
    isLoading,
    data,
    update,
  };
};

const useGetBunkering = (report_id: number) => {
  const input = { id: report_id };

  const { data: response, loading: isLoading } = useGetOwnerBunkeringQuery({
    variables: { input },
  });

  const form = useMemo(() => mapToFormData(response), [response]);

  return {
    isLoading,
    form,
  };
};

const mapToFormData = (response?: GetOwnerBunkeringQuery): Form | null => {
  if (!response) return null;

  const report = response.getOwnerBunkering;

  if (!report) return null;

  const { TimeZone_PorN, TimeZone_HH, TimeZone_MM } = getTimeZoneValues(
    report.B_Timezone
  );
  const {
    Lat_Deg,
    Lat_Min,
    Lat_Direction,
    Long_Deg,
    Long_Min,
    Long_Direction,
  } = getLatLongValues(report.B_LatLong);

  return {
    B_Vessel_Name: report.B_Vessel_Name,
    B_Voy: report.B_Voy,
    B_TimeZone_PorN: TimeZone_PorN,
    B_TimeZone_HH: TimeZone_HH,
    B_TimeZone_MM: TimeZone_MM,
    B_Bunkering_Date_time: report.B_Bunkering_Date_time,
    B_Lat_Direction: Lat_Direction,
    B_Lat_Deg: Lat_Deg,
    B_Lat_Min: Lat_Min,
    B_Long_Direction: Long_Direction,
    B_Long_Deg: Long_Deg,
    B_Long_Min: Long_Min,
    B_PortName: report.B_PortName,
    B_UNLOC: report.B_UNLOC,
    B_Port_LorU: report.B_Port_LorU,
    B_EUorUK: report.B_EUorUK || null,
    B_Fuel_name_1: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_1),
    B_Mass_1: setDefaultNullGgraphQLInputToForm(report.B_Mass_1),
    B_Volume_1: setDefaultNullGgraphQLInputToForm(report.B_Volume_1),
    B_Density_1: setDefaultNullGgraphQLInputToForm(report.B_Density_1),
    B_Fuel_name_2: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_2),
    B_Mass_2: setDefaultNullGgraphQLInputToForm(report.B_Mass_2),
    B_Volume_2: setDefaultNullGgraphQLInputToForm(report.B_Volume_2),
    B_Density_2: setDefaultNullGgraphQLInputToForm(report.B_Density_2),
    B_Fuel_name_3: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_3),
    B_Mass_3: setDefaultNullGgraphQLInputToForm(report.B_Mass_3),
    B_Volume_3: setDefaultNullGgraphQLInputToForm(report.B_Volume_3),
    B_Density_3: setDefaultNullGgraphQLInputToForm(report.B_Density_3),
    B_Fuel_name_4: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_4),
    B_Mass_4: setDefaultNullGgraphQLInputToForm(report.B_Mass_4),
    B_Volume_4: setDefaultNullGgraphQLInputToForm(report.B_Volume_4),
    B_Density_4: setDefaultNullGgraphQLInputToForm(report.B_Density_4),
    B_Fuel_name_5: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_5),
    B_Mass_5: setDefaultNullGgraphQLInputToForm(report.B_Mass_5),
    B_Volume_5: setDefaultNullGgraphQLInputToForm(report.B_Volume_5),
    B_Density_5: setDefaultNullGgraphQLInputToForm(report.B_Density_5),
    B_Fuel_name_6: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_6),
    B_Mass_6: setDefaultNullGgraphQLInputToForm(report.B_Mass_6),
    B_Volume_6: setDefaultNullGgraphQLInputToForm(report.B_Volume_6),
    B_Density_6: setDefaultNullGgraphQLInputToForm(report.B_Density_6),
    B_Fuel_name_7: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_7),
    B_Mass_7: setDefaultNullGgraphQLInputToForm(report.B_Mass_7),
    B_Volume_7: setDefaultNullGgraphQLInputToForm(report.B_Volume_7),
    B_Density_7: setDefaultNullGgraphQLInputToForm(report.B_Density_7),
    B_Fuel_name_8: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_8),
    B_Mass_8: setDefaultNullGgraphQLInputToForm(report.B_Mass_8),
    B_Volume_8: setDefaultNullGgraphQLInputToForm(report.B_Volume_8),
    B_Density_8: setDefaultNullGgraphQLInputToForm(report.B_Density_8),
    B_Fuel_name_9: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_9),
    B_Mass_9: setDefaultNullGgraphQLInputToForm(report.B_Mass_9),
    B_Volume_9: setDefaultNullGgraphQLInputToForm(report.B_Volume_9),
    B_Density_9: setDefaultNullGgraphQLInputToForm(report.B_Density_9),
    B_Fuel_name_10: setDefaultNullGgraphQLInputToForm(report.B_Fuel_name_10),
    B_Mass_10: setDefaultNullGgraphQLInputToForm(report.B_Mass_10),
    B_Volume_10: setDefaultNullGgraphQLInputToForm(report.B_Volume_10),
    B_Density_10: setDefaultNullGgraphQLInputToForm(report.B_Density_10),
    B_Filename: emptyToDoubleQuotation(report.B_Filename),
  };
};

type Props = LocationState;

const DepartureDetail = (props: Props): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);

  const navigate = useNavigate();

  if (!report_id) navigate("/");

  const formData = useGetBunkering(report_id);

  const onBack = () => {
    navigate(pageData.ownerBunkering.path());
  };

  const form = useForm([props.form, formData.form]);

  return (
    <DefaultOwner title="Noon Report" selectedTitle={"Bunkering"}>
      <Template form={form.data} reportId={report_id} onBack={onBack} />
    </DefaultOwner>
  );
};

export default DepartureDetail;
