import { db } from "../../dexie/db";
import { useLiveQuery } from "dexie-react-hooks";

export const useGetLastFrontReport = () => {
  const reportList = useLiveQuery(
    () => db.reports.toArray().catch((e) => console.error(e)),
    []
  );
  if (
    !reportList ||
    reportList.length === 0 ||
    !reportList[reportList.length - 1]
  ) {
    return;
  }

  const sortedReportList = reportList.sort(
    (a, b) => a.data.N_DateTime.getTime() - b.data.N_DateTime.getTime()
  );

  const lastReport = sortedReportList[reportList.length - 1];

  return lastReport;
};

export const useGetPreviousReport = (reportId: number) => {
  const reportList = useLiveQuery(
    () => db.reports.toArray().catch((e) => console.error(e)),
    []
  )?.sort((a, b) => a.data.N_DateTime.getTime() - b.data.N_DateTime.getTime());
  if (
    !reportList ||
    reportList.length === 0 ||
    !reportList[reportList.length - 1]
  ) {
    return;
  }

  const report = reportList.findIndex((v) => v.id === reportId);

  const previousReport = reportList[report - 1];

  return previousReport;
};

export const useGetLastFrontArrival = () => {
  const arrivalList = useLiveQuery(
    () => db.arrival.toArray().catch((e) => console.error(e)),
    []
  )?.sort(
    (a, b) =>
      a.data.A_Arrival_Date_Time.getTime() -
      b.data.A_Arrival_Date_Time.getTime()
  );
  if (
    !arrivalList ||
    arrivalList.length === 0 ||
    !arrivalList[arrivalList.length - 1]
  ) {
    return;
  }

  const lastReport = arrivalList[arrivalList.length - 1];

  return lastReport;
};

export const useGetLastFrontDeparture = () => {
  const departureList = useLiveQuery(
    () => db.departure.toArray().catch((e) => console.error(e)),
    []
  )?.sort(
    (a, b) =>
      a.data.D_Departure_Date_Time.getTime() -
      b.data.D_Departure_Date_Time.getTime()
  );
  if (
    !departureList ||
    departureList.length === 0 ||
    !departureList[departureList.length - 1]
  ) {
    return;
  }

  const lastReport = departureList[departureList.length - 1];

  return lastReport;
};
