import { Box, Stack, Typography, Grid } from "@mui/material";
import { FC } from "react";
import { SpeedConsumptionFilterCondition } from "../../../../pages/Owner/Analysis/queries";
import { formatToUtcYYYYMMDDHHMM } from "../../../../../utils/formats";
import { Circle } from "../../../../atoms/Circle";

type GridRowProps = {
  title: string;
  value: string | null;
};

const formatArray = (array: number[] | null) => {
  if (!array) return `-`;
  return `${array[0]} ~ ${array[1] || ""}`;
};

function GridRow(props: GridRowProps) {
  return (
    <>
      <Grid
        item
        xs={5.3}
        sx={{
          textAlign: "left",
          width: "100%",
        }}
      >
        <Typography
          sx={{ fontFamily: "inherit", letterSpacing: "inherit", fontSize: 14 }}
        >
          {props.title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={0.4}
        sx={{
          fontFamily: "inherit",
          letterSpacing: "inherit",
          textAlign: "left",
          width: "100%",
        }}
      >
        <Typography
          sx={{ fontFamily: "inherit", letterSpacing: "inherit", fontSize: 14 }}
        >
          :
        </Typography>
      </Grid>
      <Grid
        item
        xs={6.3}
        sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}
      >
        <Typography
          sx={{
            fontFamily: "inherit",
            letterSpacing: "inherit",
            fontSize: 14,
            textAlign: "center",
            width: "100%",
          }}
        >
          {props.value || "-"}
        </Typography>
      </Grid>
    </>
  );
}

type Props = {
  filterTitle: string;
  titleColor: string;
  filterForm: SpeedConsumptionFilterCondition;
};

const defaultWidth = 290;

export const SpeedConsumptionIndividualFormView: FC<Props> = (props: Props) => {
  return (
    <Box
      sx={{
        width: defaultWidth,
      }}
    >
      <Stack spacing={1} justifyContent="flex-start" alignItems="flex-start">
        <Stack spacing={1}>
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            sx={{ pb: 0.5 }}
          >
            <Circle sx={{ bgcolor: props.titleColor, width: 18, height: 18 }} />
            <Typography
              sx={{
                fontFamily: "inherit",
                letterSpacing: "inherit",
                fontSize: "18px",
              }}
            >
              {props.filterTitle}
            </Typography>
          </Stack>
        </Stack>

        <Grid
          rowSpacing={2}
          container
          item
          xs={12}
          justifyContent={"flex-start"}
          sx={{ p: 0 }}
        >
          <GridRow title={"Imo No"} value={props.filterForm.imo_no} />
          <GridRow title={"Voy#"} value={props.filterForm.voy} />
          <GridRow
            title={"Date Time(From)"}
            value={formatToUtcYYYYMMDDHHMM(props.filterForm.from_dateTime)}
          />
          <GridRow
            title={"Date Time(To)"}
            value={formatToUtcYYYYMMDDHHMM(props.filterForm.to_dateTime)}
          />
          <GridRow
            title={"Beaufort"}
            value={formatArray(props.filterForm.bf_scale)}
          />
          <GridRow
            title={"M/E rpm - avg."}
            value={formatArray(props.filterForm.me_revolution)}
          />
          <GridRow
            title={"Hrs. propelling"}
            value={formatArray(props.filterForm.hrs_propelling)}
          />

          <GridRow
            title={"L or B"}
            value={props.filterForm.ballast_or_laden || "-"}
          />

          <GridRow
            title={"Steam Mode"}
            value={props.filterForm.steam_mode || "-"}
          />
        </Grid>
      </Stack>
    </Box>
  );
};
