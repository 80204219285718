import { Button, SxProps, Theme } from "@mui/material";
import { greenTurquoise, lightGrayD3D3D3 } from "../../utils/color";

type Props = {
  fontSize?: number;
  width?: string;
  ButtonName: string;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
  ariaLabel?: string;
};

const SucsessButton = (props: Props) => {
  return (
    <Button
      sx={{
        fontSize: props.fontSize,
        mx: 2,
        width: props.width || "200px",
        backgroundColor: props.disabled ? lightGrayD3D3D3 : greenTurquoise,
        fontFamily: "Public Sans",
        textTransform: "none",
        ...props.sx,
      }}
      aria-label={props.ariaLabel}
      variant="contained"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.ButtonName}
    </Button>
  );
};

export default SucsessButton;
