import React from "react";
import Paper from "@mui/material/Paper";
import {
  Alert,
  Checkbox,
  Grid,
  Modal,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Nowrap from "../../../atoms/Nowrap";
import NowrapGray from "../../../atoms/NowrapGray";
import CustomTableCell from "../../../atoms/CustomTableCell";
import { formatToUtcYYYYMMDDHHMM } from "../../../../utils/formats";
import { ReportDexie } from "../../../../dexie/Report";
import SucsessButton from "../../../atoms/SucsessButton";
import { useAuth0 } from "@auth0/auth0-react";
import { DepartureDexie } from "../../../../dexie/Departure";
import { ArrivalDexie } from "../../../../dexie/Arrival";
import { useState } from "react";
import { BunkeringDexie } from "../../../../dexie/Bunkering";
import { CargoDexie } from "../../../../dexie/Cargo";
import LightGrayButton from "../../../atoms/LightGrayButton";
import { brightRed } from "../../../../utils/color";
import GrayButton from "../../../atoms/GrayButton";
import { ErrorMessageData } from "../../../../hooks/errorMessage.hook";
import ErrorMessageList from "../../../atoms/ErrorMessageList";

type ConfirmProps = {
  hasFormError: boolean;
  reports: ReportDexie[];
  departures: DepartureDexie[];
  arrivals: ArrivalDexie[];
  bunkerings: BunkeringDexie[];
  cargoList: CargoDexie[];
  handleChangeReport(event: React.ChangeEvent<HTMLInputElement>): void;
  handleChangeAllReport(event: React.ChangeEvent<HTMLInputElement>): void;
  checkedBoxListReport: number[];
  handleChangeDeparture(event: React.ChangeEvent<HTMLInputElement>): void;
  handleChangeAllDeparture(event: React.ChangeEvent<HTMLInputElement>): void;
  checkedBoxListDeparture: number[];
  handleChangeArrival(event: React.ChangeEvent<HTMLInputElement>): void;
  handleChangeAllArrival(event: React.ChangeEvent<HTMLInputElement>): void;
  checkedBoxListArrival: number[];
  handleChangeBunkering(event: React.ChangeEvent<HTMLInputElement>): void;
  handleChangeAllBunkering(event: React.ChangeEvent<HTMLInputElement>): void;
  checkedBoxListBunkering: number[];
  handleChangeCargo(event: React.ChangeEvent<HTMLInputElement>): void;
  handleChangeAllCargo(event: React.ChangeEvent<HTMLInputElement>): void;
  checkedBoxListCargo: number[];
  onCreate(): void;
  isLoading: boolean;
  errorMessageList: ErrorMessageData[];
  onDownloadErrorInfo: () => void;
};

const SendData = (props: ConfirmProps): React.ReactElement => {
  const { isAuthenticated } = useAuth0();
  const [modalOpen, setIsModalOpen] = useState(false);

  const handleChangeCheckBoxAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.handleChangeAllReport(event);
    props.handleChangeAllDeparture(event);
    props.handleChangeAllArrival(event);
    props.handleChangeAllBunkering(event);
    props.handleChangeAllCargo(event);
  };

  return (
    <React.Fragment>
      <Paper
        sx={{
          backgroundColor: "#27292E",
          borderRadius: "15px",
          height: "100%",
          mb: 3,
        }}
      >
        <Typography
          component="h3"
          variant="subtitle1"
          align="left"
          sx={{
            pt: 4,
            pl: 3,
            lineHeight: 1,
            fontWeight: "bold",
            color: "#fff",
            fontSize: "24px",
            verticalAlign: "super",
          }}
        >
          Sending MGMT
        </Typography>
        <Box
          sx={{
            textAlign: "left",
            pt: 3,
            pl: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <SucsessButton
                ariaLabel={"send_report_button"}
                sx={{ mb: 1 }}
                fontSize={16}
                ButtonName={"Send Report"}
                disabled={
                  isAuthenticated === false ||
                  (props.checkedBoxListReport.length === 0 &&
                    props.checkedBoxListDeparture.length === 0 &&
                    props.checkedBoxListArrival.length === 0 &&
                    props.checkedBoxListBunkering.length === 0 &&
                    props.checkedBoxListCargo.length === 0)
                }
                onClick={() => setIsModalOpen(true)}
              />
            </Grid>
            <Grid item>
              {!isAuthenticated && (
                <Typography
                  component="div"
                  align="left"
                  sx={{
                    pt: 1,
                    pl: 3,
                    lineHeight: 1,
                    color: brightRed,
                    fontSize: "20px",
                    verticalAlign: "super",
                  }}
                >
                  You must be logged in to submit reports.
                </Typography>
              )}
              <ErrorMessageList errorMessageList={props.errorMessageList} />
              {props.errorMessageList.length > 0 && (
                <Box
                  sx={{ display: "flex", justifyContent: "center", pb: 2 }}
                >
                  <GrayButton
                    ButtonName={"Download error report"}
                    disabled={false}
                    sx={{ width: 250 }}
                    onClick={() => props.onDownloadErrorInfo()}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ px: 2 }}>
          <Table size="small" sx={{ ml: 0 }}>
            <TableHead>
              <TableRow>
                <CustomTableCell>
                  <Checkbox
                    id={"All_Check_0"}
                    size="small"
                    onChange={handleChangeCheckBoxAll}
                    value={undefined}
                    inputProps={{
                      "aria-label": `All_Check`,
                    }}
                    sx={{
                      my: -1,
                      color: "#B7BABF",
                      "&.Mui-checked": {
                        color: "#20BE89",
                      },
                    }}
                  />
                </CustomTableCell>
                <CustomTableCell sx={{ ml: -1 }}>
                  <NowrapGray>Report Type</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray>ID</NowrapGray>
                </CustomTableCell>
                <CustomTableCell>
                  <NowrapGray> Voy# </NowrapGray>
                </CustomTableCell>

                <CustomTableCell>
                  <NowrapGray> {`Date & Time`} </NowrapGray>
                </CustomTableCell>
              </TableRow>
            </TableHead>
            {props.reports.length ||
            props.departures.length ||
            props.arrivals.length ||
            props.bunkerings.length ||
            props.cargoList.length ? (
              <TableBody>
                {props.reports.map((report, idx) => {
                  return (
                    <TableRow key={idx}>
                      <CustomTableCell sx={{ pr: -16 }}>
                        <Checkbox
                          id={String(idx)}
                          size="small"
                          onChange={props.handleChangeReport}
                          value={idx}
                          checked={props.checkedBoxListReport.includes(idx)}
                          inputProps={{
                            "aria-label": `report_${idx}`,
                          }}
                          sx={{
                            color: "#B7BABF",
                            my: -1,
                            "&.Mui-checked": {
                              color: "#20BE89",
                            },
                          }}
                        />
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>Noon Report</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{report.id}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{report.data.N_Voy}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {formatToUtcYYYYMMDDHHMM(report.data.N_DateTime)}
                        </Nowrap>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
                {props.departures.map((report, idx) => {
                  return (
                    <TableRow key={idx}>
                      <CustomTableCell sx={{ pr: -16 }}>
                        <Checkbox
                          id={String(idx)}
                          size="small"
                          onChange={props.handleChangeDeparture}
                          value={idx}
                          checked={props.checkedBoxListDeparture.includes(idx)}
                          inputProps={{
                            "aria-label": `departure_${idx}`,
                          }}
                          sx={{
                            color: "#B7BABF",
                            my: -1,
                            "&.Mui-checked": {
                              color: "#20BE89",
                            },
                          }}
                        />
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>Departure</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{report.id}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{report.data.D_Voy}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {formatToUtcYYYYMMDDHHMM(
                            report.data.D_Departure_Date_Time
                          )}
                        </Nowrap>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
                {props.arrivals.map((report, idx) => {
                  return (
                    <TableRow key={idx}>
                      <CustomTableCell sx={{ pr: -16 }}>
                        <Checkbox
                          id={String(idx)}
                          size="small"
                          onChange={props.handleChangeArrival}
                          value={idx}
                          checked={props.checkedBoxListArrival.includes(idx)}
                          inputProps={{
                            "aria-label": `arrival_${idx}`,
                          }}
                          sx={{
                            color: "#B7BABF",
                            my: -1,
                            "&.Mui-checked": {
                              color: "#20BE89",
                            },
                          }}
                        />
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>Arrival</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{report.id}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{report.data.A_Voy}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {formatToUtcYYYYMMDDHHMM(
                            report.data.A_Arrival_Date_Time
                          )}
                        </Nowrap>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
                {props.bunkerings.map((report, idx) => {
                  return (
                    <TableRow key={idx}>
                      <CustomTableCell sx={{ pr: -16 }}>
                        <Checkbox
                          id={String(idx)}
                          size="small"
                          onChange={props.handleChangeBunkering}
                          value={idx}
                          checked={props.checkedBoxListBunkering.includes(idx)}
                          inputProps={{
                            "aria-label": `bunkering_${idx}`,
                          }}
                          sx={{
                            color: "#B7BABF",
                            my: -1,
                            "&.Mui-checked": {
                              color: "#20BE89",
                            },
                          }}
                        />
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>Bunkering</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{report.id}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{report.data.B_Voy}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {formatToUtcYYYYMMDDHHMM(
                            report.data.B_Bunkering_Date_time
                          )}
                        </Nowrap>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
                {props.cargoList.map((report, idx) => {
                  return (
                    <TableRow key={idx}>
                      <CustomTableCell sx={{ pr: -16 }}>
                        <Checkbox
                          id={String(idx)}
                          size="small"
                          onChange={props.handleChangeCargo}
                          value={idx}
                          checked={props.checkedBoxListCargo.includes(idx)}
                          inputProps={{
                            "aria-label": `cargo_${idx}`,
                          }}
                          sx={{
                            color: "#B7BABF",
                            my: -1,
                            "&.Mui-checked": {
                              color: "#20BE89",
                            },
                          }}
                        />
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>Cargo</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{report.id}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>{report.data?.C_Voyage_No}</Nowrap>
                      </CustomTableCell>
                      <CustomTableCell>
                        <Nowrap>
                          {formatToUtcYYYYMMDDHHMM(report.data?.C_DateTime)}
                        </Nowrap>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <></>
            )}
          </Table>
        </Box>
      </Paper>
      <Modal
        open={modalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-label={"modal"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            color: "#FFFFFF",
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 630,
            bgcolor: "#27292E",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography sx={{ fontSize: "25px", py: 3 }}>
            Do you want to send these data?
          </Typography>
          <SucsessButton
            ariaLabel={"submit_button"}
            ButtonName={"Submit"}
            disabled={false}
            onClick={() => {
              props.onCreate();
              setIsModalOpen(false);
            }}
            sx={{ ml: 2, mt: 4, width: 150, fontSize: 16, color: "#FFFFFF" }}
          />
          <LightGrayButton
            ButtonName={"Cancel"}
            onClick={() => setIsModalOpen(false)}
            sx={{ ml: 2, mt: 4, width: 150, fontSize: 16 }}
          />
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default SendData;
