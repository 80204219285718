import { Typography } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { FC } from "react";

type Props = {
  styles?: CSSProperties;
};

export const GlobalFooter: FC<Props> = ({ ...props }): React.ReactElement => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      fontSize={18}
      padding={"30px 10px 10px 10px"}
      style={props.styles}
    >
      Copyright © 2022 seawise Co., Ltd. All rights reserved.
    </Typography>
  );
};
