import * as React from "react";
import Paper from "@mui/material/Paper";
import {
  SpeedForm,
  Props,
  FocForm,
} from "../../../../pages/Owner/Analysis/Setting/Create/types";
import SucsessButton from "../../../../atoms/SucsessButton";
import FormBlock_Speed from "./Create/FormBlock_Speed";
import { Box, Stack, Typography } from "@mui/material";
import { Legend } from "../../../../organisms/Owner/Analysis/ReportView";
import FormBlock_FOC from "./Create/FormBlock_FOC";
import { TabsUnstyled } from "@mui/base";
import { TabPanel } from "../../../../atoms/TabPanel";
import { ReferenceDataType } from "../../../../../gen/graphql/types";
import { BaselineTitleList } from "../../../../pages/Owner/Analysis/const";
import {
  hotPink,
  paleGray,
  skyBlue,
  vividGreen,
  weightedGray,
  white,
} from "../../../../../utils/color";
import LightGrayButton from "../../../../atoms/LightGrayButton";
import { useNavigate } from "react-router";
import { useOwnerAnalysisPath } from "../../../../layouts/hooks/useOwnerAnalysisPath";
import VesselNamesSelect from "../../../../atoms/VesselNamesSelect";
import SetingRadioGroup from "./component/SetingRadioGroup";
import { SettingReportView } from "../../../../organisms/Owner/Analysis/Settings/SettingReportView";
import {
  ReferenceDataTypeIndex,
  UNSELECTED,
} from "../../../../pages/Owner/Analysis/types";

type FocProps = {
  FocForm: FocForm;
  hasFocFormError: boolean;
  onUpdateFocForm(value: FocForm): void;
  onFocCreate(value: FocForm): void;
};

type SpeedProps = {
  hasSpeedFormError: boolean;
  onUpdateSpeedForm(value: SpeedForm): void;
  onSpeedCreate(value: SpeedForm): void;
};

type SettingsProps = Props &
  FocProps &
  SpeedProps & {
    formType?: ReferenceDataTypeIndex;
    setFormType: React.Dispatch<React.SetStateAction<ReferenceDataTypeIndex>>;
  };

const Settings = (props: SettingsProps): React.ReactElement => {
  const navigate = useNavigate();

  const { setSelectedShipImoNumber, getOwnerAnalysisPath } =
    useOwnerAnalysisPath();

  const onChangeSpeedForm = (key: string, value?: string | number | null) => {
    const form = {
      ...props.form,
      [key]: value,
    };

    props.onUpdateSpeedForm(form);
  };

  const onChangeFocForm = (key: string, value?: string | number | null) => {
    const form = {
      ...props.FocForm,
      [key]: value,
    };

    props.onUpdateFocForm(form);
  };

  const onClickCreate = () => {
    if (props.formType === ReferenceDataType.Speed) {
      props.onSpeedCreate(props.form);
      return;
    } else if (props.formType === ReferenceDataType.Foc) {
      props.onFocCreate(props.FocForm);
      return;
    }
  };

  return (
    <React.Fragment>
      <Paper
        sx={{
          borderRadius: "20px",
          backgroundColor: paleGray,
          width: "1240px",
          margin: "0 auto",
          color: weightedGray,
          mb: 5,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            pt: 4,
            px: 4,
            pb: 2,

            borderBottomColor: "1px solid green",
          }}
        >
          <Typography sx={{ fontSize: 24 }}>
            Speed Consumption Baseline Setting
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          spacing={3}
          alignItems={"center"}
          justifyContent={"flex-start"}
          sx={{ m: 2 }}
        >
          <VesselNamesSelect
            value={props.form.imo_no ?? ""}
            minWidth={323}
            sx={{
              "& .MuiSelect-select": {
                color: weightedGray,
              },
            }}
            onChange={(event) => {
              if (!event.target.value) props.setFormType(UNSELECTED);
              onChangeFocForm("imo_no", event.target.value);
              onChangeSpeedForm("imo_no", event.target.value);
              setSelectedShipImoNumber(event.target.value || "");
            }}
          />
          <SetingRadioGroup
            setFormType={props.setFormType}
            formType={props.formType}
            isDisabel={props.form.imo_no ? false : true}
          />
        </Stack>
        {props.form.imo_no && props.formType && (
          <Box sx={{ px: 2, pb: 2 }}>
            <TabsUnstyled value={props.formType} aria-label='setting-main-tab'>
              <TabPanel value={props.formType} index={ReferenceDataType.Speed}>
                <FormBlock_Speed
                  form={props.form}
                  onChange={onChangeSpeedForm}
                />
              </TabPanel>
              <TabPanel value={props.formType} index={ReferenceDataType.Foc}>
                <FormBlock_FOC
                  form={props.FocForm}
                  onChange={onChangeFocForm}
                />
              </TabPanel>
            </TabsUnstyled>
          </Box>
        )}
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            pr: 10,
            pt: 1,
          }}
        >
          <Legend title={BaselineTitleList.Ballast} circleColor={vividGreen} />
          <Legend title={BaselineTitleList.Laden} circleColor={skyBlue} />
          <Legend title={BaselineTitleList.Unique} circleColor={hotPink} />
        </Box>

        {props.form.imo_no && props.formType && (
          <SettingReportView
            form={
              props.formType === ReferenceDataType.Speed
                ? props.form
                : undefined
            }
            focForm={
              props.formType === ReferenceDataType.Foc
                ? props.FocForm
                : undefined
            }
          />
        )}
        <Box sx={{ p: 2, textAlign: "left" }}>
          <SucsessButton
            ButtonName={"Save"}
            disabled={props.hasSpeedFormError}
            onClick={() => onClickCreate()}
            sx={{ my: 1.5, fontSize: 16, color: white }}
          />

          <LightGrayButton
            ButtonName={"Back to Analysis"}
            sx={{ height: "38px", width: 170, fontSize: 16 }}
            onClick={() => navigate(getOwnerAnalysisPath())}
          />
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default Settings;
