import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useAuth0 } from "@auth0/auth0-react";
import OwnerName from "../atoms/OwnerName";
import { authCheckCrew, pageData, useSetOrganizationId } from "../../App";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import { GlobalFooter } from "./GlobalFooter/GlobalFooter";
import { OwnerBgColor } from "../../utils/color";
import { MUILink } from "../atoms/Link/Link";
import { useSearchParams } from "react-router-dom";
import { queryParamImoNumber } from "../../utils/const";

const BootstrapButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "1px 25px",
  border: "1px solid",
  lineHeight: 1.5,
  height: 24,
  width: 98,
  backgroundColor: "#F2F4F8",
  borderColor: "#333333",
  fontFamily: ["Public Sans"].join(","),
  "&:hover": {
    backgroundColor: "#20BE89",
    borderColor: "#20BE89",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const mdTheme = createTheme({
  typography: { fontFamily: "Public Sans" },
  palette: {
    mode: "light",
    primary: {
      main: "#20BE89",
      contrastText: "#333333",
    },
  },
});

const headerItemWidth = 1.3;

export type SelectedTitle =
  | "Noon Report"
  | "Departure"
  | "Arrival"
  | "Bunkering"
  | "Cargo"
  | "Analysis";

const setSelectedTitleColor = (selectedTitle: SelectedTitle, title: string) => {
  return selectedTitle === title ? "#20BE89" : "";
};

type DefaultProps = {
  title: string;
  children?: React.ReactNode;
  selectedTitle: SelectedTitle;
};

const Content = (props: DefaultProps) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    loginWithPopup,
    user,
  } = useAuth0();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const queryImoNumber = searchParams.get(queryParamImoNumber);
  const { imo_number: pathImoNumber } = useParams();
  const imoNumber = queryImoNumber || pathImoNumber

  const setOrganizationIdContext = useSetOrganizationId();

  useEffect(() => {
    async function InitialLoginCheck() {
      if (!isLoading && !isAuthenticated) {
        if (location.pathname.includes("/owner/") && imoNumber) {
          sessionStorage.setItem("URL_including_IMO_No", location.pathname);
        }
        loginWithRedirect();
      }

      if (!isLoading && user) {
        if (authCheckCrew(user)) {
          navigate(`/`);
          return <></>;
        }

        const redirectURL = sessionStorage.getItem("URL_including_IMO_No");
        if (redirectURL) {
          navigate(redirectURL);
          sessionStorage.removeItem("URL_including_IMO_No");
        }
      }
    }
    InitialLoginCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    navigate,
    setOrganizationIdContext,
    user,
  ]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          backgroundColor: OwnerBgColor,
          width: "100%",
          pl: -10,
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            pt: 3,
            mr: -20,
            display: "flex",
            color: "#333333",
            justifyContent: "center",
            backgroundColor: "#F5F5F5",
            minWidth: "1240px",
            margin: "0 auto",
            mb: 1,
          }}
        >
          <Box
            component="img"
            sx={{
              height: 26,
              width: 160,
              mr: 1.5,
              justifyContent: "center",
              mt: 0.15,
            }}
            alt="seawise owner"
            src="/images/seawise_owner.png"
          />
          <Typography
            component="h1"
            variant="h6"
            color="#333333"
            noWrap
            sx={{
              flexGrow: 1,
              textAlign: "left",
              fontSize: 24,
              mt: -0.8,
            }}
          >
            <OwnerName />
          </Typography>
          <MUILink
            color="inherit"
            underline="none"
            to={pageData.ownerRoot.path()}
            style={{
              paddingRight: headerItemWidth,
              paddingLeft: headerItemWidth,
              fontSize: 18,
              color: `${setSelectedTitleColor(
                props.selectedTitle,
                "Noon Report"
              )}`,
            }}
          >
            Noon
          </MUILink>
          <MUILink
            color="inherit"
            underline="none"
            to={pageData.ownerDeparture.path()}
            style={{
              paddingRight: headerItemWidth,
              paddingLeft: headerItemWidth,
              fontSize: 18,
              color: `${setSelectedTitleColor(
                props.selectedTitle,
                "Departure"
              )}`,
            }}
          >
            Departure
          </MUILink>
          <MUILink
            color="inherit"
            underline="none"
            to={pageData.ownerArrival.path()}
            style={{
              paddingRight: headerItemWidth,
              paddingLeft: headerItemWidth,
              fontSize: 18,
              color: `${setSelectedTitleColor(props.selectedTitle, "Arrival")}`,
            }}
          >
            Arrival
          </MUILink>
          <MUILink
            color="inherit"
            underline="none"
            to={pageData.ownerBunkering.path()}
            style={{
              paddingRight: headerItemWidth,
              paddingLeft: headerItemWidth,
              fontSize: 18,
              color: `${setSelectedTitleColor(
                props.selectedTitle,
                "Bunkering"
              )}`,
            }}
          >
            Bunkering
          </MUILink>
          <MUILink
            color="inherit"
            underline="none"
            to="/owner/cargo"
            style={{
              paddingRight: headerItemWidth,
              paddingLeft: headerItemWidth,
              fontSize: 18,
              color: `${setSelectedTitleColor(props.selectedTitle, "Cargo")}`,
            }}
          >
            Cargo
          </MUILink>
          <MUILink
            color="inherit"
            underline="none"
            to={
              imoNumber 
                ? `${pageData.ownerAnalysis.path()}?imo_number=${imoNumber}`
                : pageData.ownerAnalysis.path()
              }
            style={{
              paddingRight: headerItemWidth,
              paddingLeft: headerItemWidth,
              fontSize: 18,
              color: `${setSelectedTitleColor(
                props.selectedTitle,
                "Analysis"
              )}`,
            }}
          >
            Analysis
          </MUILink>
          {!isLoading && isAuthenticated ? (
            <BootstrapButton
              loading={isLoading}
              variant="outlined"
              color="inherit"
              onClick={() => logout({ returnTo: window.location.origin })}
              sx={{
                borderRadius: "30px",
                mt: 0.25,
                mr: 1.5,
              }}
            >
              LOGOUT
            </BootstrapButton>
          ) : (
            <BootstrapButton
              loading={isLoading}
              variant="outlined"
              color="inherit"
              onClick={loginWithPopup}
              sx={{
                borderRadius: "30px",
                mt: 0.25,
                mr: 1.5,
              }}
            >
              LOGIN
            </BootstrapButton>
          )}
        </Box>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? "#F5F5F5"
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100%",
            overflow: "auto",
            width: "100%",
            pl: -10,
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              mt: 1,
              mb: 4,
              width: "1240px",
              "&.MuiContainer-root": {
                px: 0,
                margin: "0 auto",
              },
            }}
          >
            <Grid item xs={12}>
              {props.children}
            </Grid>
          </Container>
        </Box>
        <GlobalFooter />
      </Box>
    </ThemeProvider>
  );
};

const Default = (props: DefaultProps): React.ReactElement => {
  return (
    <Content title={props.title} selectedTitle={props.selectedTitle}>
      {props.children}
    </Content>
  );
};

export default Default;
