import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import Optional from "./Optional";
import {
  greenTurquoise,
  lightGray,
  weightedGray,
  white,
} from "../../utils/color";

type Props = {
  disabled?: boolean;
  placeholder?: string;
  id: string;
  label: string;
  value: string | undefined;
  onChange(value: string): void;
  selectValues: string[];
  sx?: SxProps<Theme>;
  isConfirm?: boolean;
  isOptional?: boolean;
  isOwner?: boolean;
};

const CommonSelect = (props: Props): React.ReactElement => {
  return (
    <Stack>
      {props.isOptional && <Optional />}
      <FormControl
        sx={{
          m: 0,
          minWidth: 120,
          "& .Mui-focused": {
            color: greenTurquoise,
          },
          "& .MuiFormLabel-root": {
            color: lightGray,
            border: `1px transparent`,
          },
          "& .MuiFormLabel-filled": {
            color: lightGray,
          },
        }}
        size="small"
      >
        <InputLabel id={props.label} sx={{ color: lightGray, fontSize: 16 }}>
          {props.label}
        </InputLabel>
        <Select
          labelId={props.id}
          id={props.id}
          label={props.label}
          value={props.value || ""}
          placeholder={props.placeholder}
          onChange={(e) => props.onChange(e.target.value)}
          sx={{
            width: "18ch",
            "& .MuiSelect-outlined": {
              border: `1px solid #464E5A`,
            },
            "& .MuiSelect-select": {
              color: props.isOwner ? weightedGray : white,
              border: `1px solid #464E5A`,
            },
            "& .MuiSelect-select:hover": {
              color: props.isOwner ? weightedGray : white,
              border: `1px solid ${lightGray} !important`,
            },
            "& .MuiSelect-icon": {
              color: props.isOwner ? lightGray : white,
            },
            "&:hover fieldset": {
              border: white,
            },
            textAlign: "left",
            ...props.sx,
          }}
        >
          {props.isOptional && (
            <MenuItem key={0} value="">
              <em>---</em>
            </MenuItem>
          )}
          {props.selectValues.map((v, index) => {
            return (
              <MenuItem value={v} key={index}>
                {v}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default CommonSelect;
