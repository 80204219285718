import { SxProps, Theme } from "@mui/material";
import TableCell from "@mui/material/TableCell";

type CustomTableCellProps = {
  align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  colSpan?: number;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};
const CustomTableCell = (props: CustomTableCellProps) => {
  return (
    <TableCell
      align={props.align}
      colSpan={props.colSpan}
      sx={{
        color: "#FFFFFF",
        borderBottom: "1px solid #464E5A",
        ...props.sx,
      }}
    >
      {props.children}
    </TableCell>
  );
};

export default CustomTableCell;
