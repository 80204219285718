import dayjs from "dayjs";
import {
  ReportListInput,
  ReportListQuery,
  useReportListLazyQuery,
} from "../../gen/graphql/types";
import { useMemo } from "react";
import { ReportSearchCondition } from "../../components/pages/pages.module";

export const getReportList = (reports?: ReportListQuery) => {
  const reportList = (reports?.reportList?.reportList || [])
    .filter((v): v is NonNullable<typeof v> => v != null)
    .sort((a, b) => dayjs(b.N_DateTime).diff(dayjs(a.N_DateTime)));

  return reportList;
};

export const mapReportListInput = (
  searchCondition: ReportSearchCondition
): ReportListInput => {
  return {
    ship_id: searchCondition?.ship_id || null,
    IMO_No: searchCondition.imo_no || null,
    N_Voy: searchCondition?.Voy,
    From_DateTime: searchCondition?.From_DateTime,
    To_DateTime: searchCondition?.To_DateTime,
    pagination: searchCondition.pagination,
  };
};

export const useReports = () => {
  const [getData, { data: response, loading: isLoading }] =
    useReportListLazyQuery();

  const data = useMemo(() => {
    const totalPageCount = response?.reportList?.totalPageCount || 0;
    const reportList = getReportList(response);

    return { totalPageCount, reportList: reportList };
  }, [response]);

  const refetch = (searchCondition: ReportSearchCondition) => {
    const inputdata = mapReportListInput(searchCondition);
    getData({
      variables: {
        input: {
          ...inputdata,
          pagination: {
            page: inputdata.pagination?.page,
            limit: inputdata.pagination?.limit,
          },
        },
      },
    });
  };

  return {
    isLoading,
    data,
    refetch,
  };
};
