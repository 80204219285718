import { SpeedConsumptionFilterCondition } from "../../../../pages/Owner/Analysis/queries";
import { AnalysisFilterType } from "../../../../../gen/graphql/types";
import { FilterBooleanType } from "../../../../pages/Owner/Analysis/types";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { formatToYYYYMMDDHHMMSS } from "../../../../../utils/formats";
import { useDownloadCsv } from "./downloadCSV";
import { mapFormToAnalysisSearchParameterDetailInputArr } from "../AnalysisSearchParameterModal/AnalysisSearchParameterModal.hook";
import { createAnalysisPDF } from "./createAnalysisPDF";

// PDFとCSVをZIPに追加し、ダウンロードする処理
export const useDownloadZIP = () => {
  const { handleCsvDownload } = useDownloadCsv();
  const downloadZip = async (
    speedConsumptionFilterConditions: SpeedConsumptionFilterCondition[],
    checkedMeRevolutionOver100: FilterBooleanType,
    isMeUsageFilter: FilterBooleanType
  ) => {
    try {
      const zip = new JSZip();

      await Promise.all([
        createAnalysisPDF().then((pdf) => {
          if (!pdf) {
            alert("PDF creation failed.");
            return;
          }
          zip.file("graph-and-filters.pdf", pdf.output("blob"), {
            binary: true,
          });
        }),
        handleCsvDownload(
          mapFormToAnalysisSearchParameterDetailInputArr([
            {
              filter_type: AnalysisFilterType.Filter1,
              form: speedConsumptionFilterConditions[0],
              checkedMeRevolutionOver100: checkedMeRevolutionOver100.filter1,
            },
            {
              filter_type: AnalysisFilterType.Filter2,
              form: speedConsumptionFilterConditions[1],
              checkedMeRevolutionOver100: checkedMeRevolutionOver100.filter2,
            },
            {
              filter_type: AnalysisFilterType.Filter3,
              form: speedConsumptionFilterConditions[2],
              checkedMeRevolutionOver100: checkedMeRevolutionOver100.filter3,
            },
            {
              filter_type: AnalysisFilterType.Filter4,
              form: speedConsumptionFilterConditions[3],
              checkedMeRevolutionOver100: checkedMeRevolutionOver100.filter4,
            },
          ]),
          isMeUsageFilter
        ).then((graphDataCsv) => {
          if (!graphDataCsv) {
            alert("CSV creation failed.");
            return;
          }
          zip.file("graph-data.csv", graphDataCsv);
        }),
      ]);

      // ZIPファイルの生成とダウンロード
      const content = await zip.generateAsync({ type: "blob" });

      saveAs(content, `Analysis_${formatToYYYYMMDDHHMMSS(new Date())}.zip`);
    } catch (e) {
      console.error(e);
      alert("Failed to create download file.");
    }
  };

  return { downloadZip };
};
