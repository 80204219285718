export function checkBrowser(): string {
  const agent = window.navigator.userAgent.toLowerCase();

  const browserList = {
    msie: "msie",
    edge: "edge",
    chrome: "chrome",
    safari: "safari",
    firefox: "firefox",
    other: "other",
  };

  if (agent.indexOf(browserList.msie) > -1) {
    return browserList.msie;
  } else if (agent.indexOf(browserList.edge) > -1) {
    return browserList.edge;
  } else if (agent.indexOf(browserList.chrome) > -1) {
    return browserList.chrome;
  } else if (agent.indexOf(browserList.safari) > -1) {
    return browserList.safari;
  } else if (agent.indexOf(browserList.firefox) > -1) {
    return browserList.firefox;
  } else {
    return browserList.edge;
  }
}
