import { Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Detail from "../../../organisms/Crew/Bunkering/Detail";
import { Props } from "../../../pages/Crew/Bunkering/Create/types";
import { ReportDexie } from "../../../../dexie/Report";

type ReportDetailProps = Props & {
  deleteReport(): void;
  onBack(): void;
  reportId: number;
  previousReport: ReportDexie | undefined;
  isSend: boolean;
};

const ReportDetail = (props: ReportDetailProps): React.ReactElement => {
  const onClickBack = () => {
    props.onBack();
  };

  return (
    <React.Fragment>
      <Paper sx={{ backgroundColor: "#27292E", borderRadius: "20px" }}>
        <Detail
          isPreview={true}
          form={props.form}
          deleteReport={props.deleteReport}
          pageTitle={"Bunkering Report"}
          previousReport={props.previousReport}
          isSend={props.isSend}
          isServer={false}
        />

        <Box
          sx={{
            textAlign: "left",
            px: 2,
            py: 4,
          }}
        >
          <Button
            sx={{
              mx: 2,
              width: "200px",
              height: "40px",
              backgroundColor: "#20BE89  !important",
              fontFamily: "Public Sans",
              "&.MuiButton-root": {
                fontFamily: "Public Sans",
              },
              textTransform: "none",
            }}
            variant="contained"
            onClick={onClickBack}
          >
            Back
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default ReportDetail;
