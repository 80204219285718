import { getOffsetForLongitude } from "../../../../../utils/calc-timezone";
import { calcSum, orgRound } from "../../../../../utils/calculate";
import { Form } from "../../../../pages/Crew/NoonReport/Create/types";
import { FormBlockProps } from "../FormBlock";
import { cal_N_FO_CO2_Emission_Per_Unit } from "./N_FO_CO2_emission_per_unit";
import { cal_N_ME_rpm_ave } from "./N_ME_rpm_ave";
import { cal_N_MGO_CO2_emission_per_unit } from "./N_MGO_CO2_emission_per_unit";
import { cal_N_Slip } from "./N_Slip";
import { calculateSpeedOG } from "./N_SpeedOG";

export const calculateNoonValues = (props: FormBlockProps) => {
  if (!props.ship) return;
  props.form.N_Vessel_name = props.ship.name;

  props.form.N_ME_rpm_ave = cal_N_ME_rpm_ave(
    props.form.N_Hrs_propelling,
    props.form.N_ME_Revolution
  );

  const FO_CO2_emission_SUM = calcSum([
    props.form.N_FO_Consumption_per_day_ME,
    props.form.N_FO_Consumption_per_day_GE,
    props.form.N_FO_Consumption_per_day_BInc,
    props.form.N_FO_Additional_volume_1,
    props.form.N_FO_Additional_volume_2,
    props.form.N_FO_Additional_volume_3,
    props.form.N_FO_Additional_volume_4,
    props.form.N_FO_Additional_volume_5,
  ]);

  const MGO_CO2_emission_SUM = calcSum([
    props.form.N_MGO_Consumption_per_day_ME,
    props.form.N_MGO_Consumption_per_day_GE,
    props.form.N_MGO_Consumption_per_day_Boiler,
    props.form.N_MGO_Additional_volume_1,
    props.form.N_MGO_Additional_volume_2,
    props.form.N_MGO_Additional_volume_3,
    props.form.N_MGO_Additional_volume_4,
    props.form.N_MGO_Additional_volume_5,
  ]);

  props.form.N_Slip = cal_N_Slip({
    propeller_pitch: props.ship.propeller_pitch,
    N_ME_rpm_ave: props.form.N_ME_rpm_ave,
    N_Speed_OW: props.form.N_Speed_OW,
  });

  props.form.N_FO_CO2_emission_per_unit = cal_N_FO_CO2_Emission_Per_Unit({
    N_Hrs_propelling: props.form.N_Hrs_propelling,
    FO_CO2_emission_SUM: FO_CO2_emission_SUM,
    summer_deadweight: props.ship.summer_deadweight,
    N_Speed_OG: props.form.N_Speed_OG,
  });

  props.form.N_MGO_CO2_emission_per_unit = cal_N_MGO_CO2_emission_per_unit({
    MGO_CO2_emission_SUM: MGO_CO2_emission_SUM,
    summer_deadweight: props.ship.summer_deadweight,
    N_Hrs_propelling: props.form.N_Hrs_propelling,
    N_Speed_OG: props.form.N_Speed_OG,
  });

  props.form.N_FO_CO2_emission = orgRound(FO_CO2_emission_SUM * 3.114, 1000);

  props.form.N_MGO_CO2_emission = orgRound(MGO_CO2_emission_SUM * 3.206, 1000);

  props.form.N_Speed_OG = calculateSpeedOG(
    props.form.N_Dist_OG,
    props.form.N_Hrs_propelling
  );
};

type TimeZoneProps = {
  form: Form;
};

export const calculateNoonTimeZone = (props: TimeZoneProps) => {
  const timezone = getOffsetForLongitude({
    longDirection: props.form.N_Long_Direction,
    longDeg: props.form.N_Long_Deg,
    longMin: props.form.N_Long_Min,
  });

  if (timezone) {
    props.form.N_TimeZone_PorN = timezone.TimeZone_PorN;
    props.form.N_TimeZone_HH = timezone.TimeZone_HH;
    props.form.N_TimeZone_MM = timezone.TimeZone_MM;
  }

  return props.form;
};
