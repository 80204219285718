import { FC, memo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateReport from "./components/pages/Crew/NoonReport/Create";
import CreateReportConfirm from "./components/pages/Crew/NoonReport/Create/Confirm";
import UpdateConfirm from "./components/pages/Crew/NoonReport/Update/Confirm";
import UpdateReport from "./components/pages/Crew/NoonReport/Update";
import SendData from "./components/pages/Crew/SendingMGMT/SendData";
import CreateReportComplete from "./components/pages/Crew/NoonReport/Create/Complete";
import UpdateComplete from "./components/pages/Crew/NoonReport/Update/Complete";
import Crew from "./components/pages/Crew/Crew";
import SelectShips from "./components/pages/Crew/SelectShips/SelectShips";
import ArrivalCrew from "./components/pages/Crew/Arrival/Arrival";
import DepartureCrew from "./components/pages/Crew/Departure/Departure";
import BunkeringCrew from "./components/pages/Crew/Bunkering/Bunkering";
import ReportDetailReport from "./components/pages/Crew/NoonReport/ReportDetail";
import Report from "./components/pages/Owner/Analysis/Analysis";
import NoonReport from "./components/pages/Owner/NoonReport/NoonReport";
import Owner from "./components/pages/Owner/Owner";
import CreateDeparture from "./components/pages/Crew/Departure/Create";
import ConfirmDeparture from "./components/pages/Crew/Departure/Create/Confirm";
import UpdateDeparture from "./components/pages/Crew/Departure/Update";
import UpdateConfirmDeparture from "./components/pages/Crew/Departure/Update/Confirm";
import CompleteDeparture from "./components/pages/Crew/Departure/Create/Complete";
import CreateArrival from "./components/pages/Crew/Arrival/Create";
import ConfirmArrival from "./components/pages/Crew/Arrival/Create/Confirm";
import ConfirmBunkering from "./components/pages/Crew/Bunkering/Create/Confirm";
import UpdateArrival from "./components/pages/Crew/Arrival/Update";
import UpdateBunkering from "./components/pages/Crew/Bunkering/Update";
import UpdateConfirmArrival from "./components/pages/Crew/Arrival/Update/Confirm";
import UpdateConfirmBunkering from "./components/pages/Crew/Bunkering/Update/Confirm";
import CompleteArrival from "./components/pages/Crew/Arrival/Create/Complete";
import CompleteBunkering from "./components/pages/Crew/Bunkering/Create/Complete";
import OwnerDeparture from "./components/pages/Owner/Departure/Departure";
import OwnerArrival from "./components/pages/Owner/Arrival/Arrival";
import OwnerBunkering from "./components/pages/Owner/Bunkering/Bunkering";
import CreateBunkering from "./components/pages/Crew/Bunkering/Create";
import ReportDetailDeparture from "./components/pages/Crew/Departure/DepartureDetail";
import ReportDetailArrival from "./components/pages/Crew/Arrival/ReportDetail";
import ReportDetailBunkering from "./components/pages/Crew/Bunkering/BunkeringDetail";
import Setting from "./components/pages/Owner/Analysis/Setting/Setting";
import ServerUpdateReport from "./components/pages/Crew/NoonReport/ServerUpdate";
import ServerUpdateReportConfirm from "./components/pages/Crew/NoonReport/ServerUpdate/Confirm";
import OwnerReportDetail from "./components/pages/Owner/NoonReport/ReportDetail";
import OwnerDepartureDetail from "./components/pages/Owner/Departure/DepartureDetail";
import OwnerArrivalDetail from "./components/pages/Owner/Arrival/ArrivalDetail";
import OwnerBunkeringDetail from "./components/pages/Owner/Bunkering/BunkeringDetail";
import ServerUpdateDeparture from "./components/pages/Crew/Departure/ServerUpdate";
import ServerUpdateDepartureConfirm from "./components/pages/Crew/Departure/ServerUpdate/ServerUpdateDepartureConfirm";
import ServerUpdateDepartureComplete from "./components/pages/Crew/Departure/ServerUpdate/ServerUpdateDepartureComplete";
import ServerUpdateArrival from "./components/pages/Crew/Arrival/ServerUpdate";
import ServerUpdateArrivalConfirm from "./components/pages/Crew/Arrival/ServerUpdate/ServerUpdateArrivalConfirm";
import ServerUpdateArrivalComplete from "./components/pages/Crew/Arrival/ServerUpdate/ServerUpdateArrivalComplete";
import ServerUpdateBunkering from "./components/pages/Crew/Bunkering/ServerUpdate";
import ServerUpdateBunkeringComplete from "./components/pages/Crew/Bunkering/ServerUpdate/ServerUpdateBunkeringComplete";
import Cargo from "./components/pages/Crew/Cargo";
import CargoCreate from "./components/pages/Crew/Cargo/Create";
import CargoCreateComplete from "./components/pages/Crew/Cargo/Create/Complete";
import CargoReportDetail from "./components/pages/Crew/Cargo/ReportDetail";
import CargoUpdate from "./components/pages/Crew/Cargo/Update";
import CargoUpdateComplete from "./components/pages/Crew/Cargo/Update/Complete";
import OwnerCargo from "./components/pages/Owner/Cargo";
import OwnerCargoDetail from "./components/pages/Owner/Cargo/CargoDetail";
import ServerUpdateCargo from "./components/pages/Crew/Cargo/ServerUpdate";
import { RhfSample } from "./components/pages/RhfSample";
import { NotFound404 } from "./components/pages/NotFound404";

export const RouteCrewSelectShip = "/selectShip";

export const RouteConfig: FC = memo(function RouteConfig() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Crew />} />
        <Route path={RouteCrewSelectShip} element={<SelectShips />} />
        {/* Crew Report Create   */}
        <Route path={`crew/create`} element={<CreateReport />} />
        <Route path={`create/confirm`} element={<CreateReportConfirm />} />
        <Route path={`create/complete`} element={<CreateReportComplete />} />
        {/*  Crew Report Update */}
        <Route path={`update/:reportId`} element={<UpdateReport />} />
        {/*  Crew ServerReport Update */}
        <Route
          path={`report/serverUpdate/:reportId`}
          element={<ServerUpdateReport />}
        />
        <Route
          path={`report/serverUpdate/confirm/:reportId`}
          element={<ServerUpdateReportConfirm />}
        />
        {/*  Crew Report Detail */}
        <Route
          path={`reportDetail/:reportId`}
          element={<ReportDetailReport />}
        />
        <Route path={`update/confirm/:reportId`} element={<UpdateConfirm />} />
        <Route path={`update/complete`} element={<UpdateComplete />} />
        {/* Crew Departure  */}
        <Route path={`crew/departure`} element={<DepartureCrew />} />
        <Route path={`departure/create`} element={<CreateDeparture />} />
        <Route
          path={`departure/create/confirm`}
          element={<ConfirmDeparture />}
        />
        <Route
          path={`departure/create/complete`}
          element={<CompleteDeparture />}
        />
        {/*  Crew Departure Update */}
        <Route
          path={`departure/update/:reportId`}
          element={<UpdateDeparture />}
        />
        <Route
          path={`departure/update/confirm/:reportId`}
          element={<UpdateConfirmDeparture />}
        />
        {/*  Crew Departure Detail */}
        <Route
          path={`/crew/departure/reportDetail/:reportId`}
          element={<ReportDetailDeparture />}
        />
        {/*  Crew ServerDeparture Update */}
        <Route
          path={`departure/serverUpdate/:reportId`}
          element={<ServerUpdateDeparture />}
        />
        <Route
          path={`departure/serverUpdate/confirm/:reportId`}
          element={<ServerUpdateDepartureConfirm />}
        />
        <Route
          path={`departure/serverUpdate/complete`}
          element={<ServerUpdateDepartureComplete />}
        />
        {/* Crew Arrival  */}
        <Route path={`crew/arrival`} element={<ArrivalCrew />} />
        <Route path={`arrival/create`} element={<CreateArrival />} />
        <Route path={`arrival/create/confirm`} element={<ConfirmArrival />} />
        {/*  Crew Arrival Update */}
        <Route path={`arrival/update/:reportId`} element={<UpdateArrival />} />
        <Route
          path={`arrival/update/confirm/:reportId`}
          element={<UpdateConfirmArrival />}
        />
        {/*  Crew Departure Detail */}
        <Route
          path={`/crew/arrival/reportDetail/:reportId`}
          element={<ReportDetailArrival />}
        />
        <Route path={`arrival/create/complete`} element={<CompleteArrival />} />
        {/*  Crew ServerArrival Update */}
        <Route
          path={`arrival/serverUpdate/:reportId`}
          element={<ServerUpdateArrival />}
        />
        <Route
          path={`arrival/serverUpdate/confirm/:reportId`}
          element={<ServerUpdateArrivalConfirm />}
        />
        <Route
          path={`arrival/serverUpdate/complete`}
          element={<ServerUpdateArrivalComplete />}
        />
        {/* Crew Bunkering  */}
        <Route path={`crew/bunkering`} element={<BunkeringCrew />} />
        <Route path={`bunkering/create`} element={<CreateBunkering />} />
        <Route
          path={`bunkering/create/confirm`}
          element={<ConfirmBunkering />}
        />
        {/*  Crew Bunkering Update */}
        <Route
          path={`bunkering/update/:reportId`}
          element={<UpdateBunkering />}
        />
        <Route
          path={`bunkering/update/confirm/:reportId`}
          element={<UpdateConfirmBunkering />}
        />
        {/*  Crew Bunkering Detail */}
        <Route
          path={`/crew/bunkering/reportDetail/:reportId`}
          element={<ReportDetailBunkering />}
        />
        <Route
          path={`bunkering/create/complete`}
          element={<CompleteBunkering />}
        />
        {/*  Crew ServerBunkering Update */}
        <Route
          path={`bunkering/serverUpdate/:reportId`}
          element={<ServerUpdateBunkering />}
        />

        <Route
          path={`bunkering/serverUpdate/complete`}
          element={<ServerUpdateBunkeringComplete />}
        />
        {/*  Crew Cargo */}
        <Route path={`crew/cargo`} element={<Cargo />} />
        <Route path={`crew/cargo/create`} element={<CargoCreate />} />
        <Route
          path={`crew/cargo/create/complete`}
          element={<CargoCreateComplete />}
        />

        <Route
          path={`crew/cargo/reportDetail/:reportId`}
          element={<CargoReportDetail />}
        />
        <Route path={`cargo/update/:reportId`} element={<CargoUpdate />} />
        <Route
          path={`crew/cargo/update/complete`}
          element={<CargoUpdateComplete />}
        />

        <Route
          path={`cargo/serverUpdate/:reportId`}
          element={<ServerUpdateCargo />}
        />

        {/* Send Data */}
        <Route path={`sendData`} element={<SendData />} />
        {/* Owner */}
        <Route path={`owner`} element={<Owner />} />
        <Route path={`owner/:imo_number`} element={<Owner />} />
        {/* Noon Report */}
        {/* Owner */}
        <Route path={`owner`} element={<NoonReport />} />
        <Route path={`noonrReport/:organization_id`} element={<NoonReport />} />
        <Route
          path={`owner/report/detail/:reportId`}
          element={<OwnerReportDetail />}
        />
        <Route path={`owner/departure`} element={<OwnerDeparture />} />
        <Route
          path={`owner/departure/detail/:reportId`}
          element={<OwnerDepartureDetail />}
        />
        <Route path={`owner/arrival`} element={<OwnerArrival />} />
        <Route
          path={`owner/arrival/detail/:reportId`}
          element={<OwnerArrivalDetail />}
        />
        <Route path={`owner/bunkering`} element={<OwnerBunkering />} />
        <Route
          path={`owner/bunkering/detail/:reportId`}
          element={<OwnerBunkeringDetail />}
        />
        {/* Owner Cargo */}
        <Route path={`owner/cargo`} element={<OwnerCargo />} />
        <Route
          path={`owner/cargo/detail/:reportId`}
          element={<OwnerCargoDetail />}
        />

        {/* Analysis */}
        <Route path={`owner/analysis`} element={<Report />} />
        {/* Settings */}
        <Route path={`owner/analysis/setting`} element={<Setting />} />

        {/* rhfSample */}
        <Route path={`rhfSample`} element={<RhfSample />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </BrowserRouter>
  );
});
