import { useCallback, useEffect, useState } from "react";
import { ReportSearchCondition } from "../../pages.module";
import {
  SEARCH_PAGE_LIMIT_OWNER,
  useCsvDownloadForm,
} from "../NoonReport/NoonReport";
import { useApolloClient } from "@apollo/client";
import { useContextReportSearchCondition } from "../../../../App";
import { getBunkeringCsvDownloadData } from "./BunkeringUtil";
import {
  CSV_DOWNLOAD_LIMIT,
  downloadCsvFile,
} from "../../../../utils/download-csv-util";
import { genCsv } from "./BunkeringCsvFormat";
import Template from "../../../templates/Owner/Bunkering/Bunkering";
import DefaultOwner from "../../../layouts/DefaultOwner";
import {
  getBunkeringList,
  mapBunkeringListInput,
  useBunkerings,
} from "../../../../hooks/commonQuery/bunkering";

const useForm = () => {
  const [data, update] = useState<ReportSearchCondition>({
    imo_no: null,
    Voy: null,
    From_DateTime: null,
    To_DateTime: null,
    pagination: {
      page: 1,
      limit: SEARCH_PAGE_LIMIT_OWNER,
    },
  });

  return {
    data,
    update,
  };
};

const BunkeringReport = (): React.ReactElement => {
  const reports = useBunkerings();
  const form = useForm();
  const csvForm = useCsvDownloadForm();
  const client = useApolloClient();
  const { reportSearchCondition, setReportSearchCondition } =
    useContextReportSearchCondition();

  const onUpdateForm = (value: ReportSearchCondition) => {
    form.update(value);
  };

  const onUpdateCsvForm = (value: ReportSearchCondition) => {
    csvForm.update(value);
  };

  const onUpdateSearchCondition = (
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ) => {
    form.update(value);

    if (!isWithRefresh) {
      return;
    }

    reports.refetch(form.data);
  };

  const onCsvDownload = useCallback(async () => {
    csvForm.data.pagination.page = 1;
    const input = mapBunkeringListInput(csvForm.data);
    try {
      let data = await getBunkeringCsvDownloadData(input, client);
      let reportList = getBunkeringList(data);

      if (data?.arrivalList?.totalPageCountt > CSV_DOWNLOAD_LIMIT) {
        const iterations = Math.ceil(
          data?.arrivalList?.totalPageCount / CSV_DOWNLOAD_LIMIT
        );

        for (let i = 1; i < iterations; i++) {
          csvForm.data.pagination.page = i + 1; // Adjust input to fetch the next page.
          const input = mapBunkeringListInput(csvForm.data);
          data = await getBunkeringCsvDownloadData(input, client);
          const additionalReportList = getBunkeringList(data);
          reportList = [...reportList, ...additionalReportList]; // merge the new data with existing reportList
        }
      }

      const { fileName, text } = await genCsv(
        csvForm.data,
        reportList.reverse()
      ).catch((e) => {
        console.error(e);
        throw Error("");
      });

      downloadCsvFile(fileName, text);
    } catch (e) {
      console.error(e);
      alert("Failed to download CSV");
    } finally {
      csvForm.data.pagination.page = 1;
    }
  }, [client, csvForm.data]);

  const onSearch = () => {
    form.data.pagination.page = 1;
    setReportSearchCondition(form.data);
    reports.refetch(form.data);
  };
  useEffect(() => {
    if (reportSearchCondition != undefined) {
      form.data = reportSearchCondition;
      form.update(form.data);
      reports.refetch(form.data);
      return;
    } else {
      reports.refetch(form.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultOwner title="Noon Report" selectedTitle={"Bunkering"}>
      <Template
        bunkeringList={reports.data.bunkeringList}
        isLoading={reports.isLoading}
        totalPageCount={reports.data.totalPageCount}
        hasFormError={true}
        onUpdateForm={onUpdateForm}
        form={form.data}
        onSearch={onSearch}
        onUpdateSearchCondition={onUpdateSearchCondition}
        onCsvDownload={onCsvDownload}
        csvForm={csvForm.data}
        onUpdateCsvForm={onUpdateCsvForm}
        hasCsvFormError={csvForm.hasCsvFormError}
      />
    </DefaultOwner>
  );
};

export default BunkeringReport;
