export const windDirectionsWithRange = [
  {'direction': 'N', 'degree': 0, 'range': [0, 22.5]},
  {'direction': 'N', 'degree': 0, 'range': [337.5, 360]},
  {'direction': 'NE', 'degree': 45, 'range': [22.5, 67.5]},
  {'direction': 'E', 'degree': 90, 'range': [67.5, 112.5]},
  {'direction': 'SE', 'degree': 135, 'range': [112.5, 157.5]},
  {'direction': 'S', 'degree': 180, 'range': [157.5, 202.5]},
  {'direction': 'SW', 'degree': 225, 'range': [202.5, 247.5]},
  {'direction': 'W', 'degree': 270, 'range': [247.5, 292.5]},
  {'direction': 'NW', 'degree': 315, 'range': [292.5, 337.5]},
]

const getWindObj = (degree: number | null | undefined) => {
  if (degree == null) {
    return null;
  }
  const windObj = windDirectionsWithRange.find(obj => {
    const [min, max] = obj.range;
    return degree >= min && degree < max;
  });
  return windObj;
};

export const convertDegreeToDirection = (degree: number | null | undefined): string => {
  const windObj = getWindObj(degree);
  return windObj ? windObj.direction : '';
};

export const convertWindDirectionRangeToDegree = (degree: number | null | undefined): string => {
  const windObj = getWindObj(degree);
  return windObj ? windObj.degree.toString() : '';
};

interface WindDirection {
  direction: string;
  degree: number;
}

export const getWindDirectionArray = (): WindDirection[] => {
  const result: WindDirection[] = [];

  windDirectionsWithRange.forEach(windDirection => {
    const existingItem = result.find(item => item.direction === windDirection.direction);

    if (!existingItem) {
      result.push({
        'direction': windDirection.direction,
        'degree': windDirection.degree
      });
    }
  });

  return result;
}
