import * as React from "react";
import { Paper, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import Columns from "../../../../atoms/Columns";
import Column from "../../../../atoms/Column";
import Steppr from "../../../../atoms/Stepper";
import CommonText from "../../../../atoms/CommonText";
import { pageData } from "../../../../../App";

const ServerUpdateDepartureComplete = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Paper
        elevation={1}
        sx={{
          pl: 2,
          backgroundColor: "#27292E",
          borderRadius: "20px",
          textAlign: "left",
          height: "892px",
        }}
      >
        <Box component="form" noValidate autoComplete="off">
          <Box sx={{ pt: 0.5, px: 2 }}>
            <Columns
              sx={{
                display: "flex",
                alignItems: "top",
              }}
            >
              <Column>
                <Typography
                  component="h3"
                  variant="subtitle1"
                  align="center"
                  sx={{
                    lineHeight: 1,
                    fontWeight: "bold",
                    color: "#fff",
                    fontSize: "1.5rem",
                    verticalAlign: "super",
                  }}
                >
                  Create Arrival Report
                </Typography>
                <Typography
                  sx={{
                    pt: 1,
                    textAlign: "left",
                    verticalAlign: "top",
                    fontSize: "12px",
                    fontFamily: "Public Sans",
                    lineHeight: "auto",
                    color: "#ffffff",
                  }}
                ></Typography>
              </Column>
              <Column sx={{ width: "60%" }}>
                <Steppr step={2} />
              </Column>
            </Columns>
          </Box>
        </Box>
        <Box sx={{ p: 2, textAlign: "left" }}>
          <CommonText sx={{ mb: 2 }}>Server Data saved.</CommonText>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            onClick={() => navigate(pageData.crewArrival.path())}
            sx={{ backgroundColor: "#20BE89", textTransform: "none" }}
          >
            Back to Arrival Report List
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default ServerUpdateDepartureComplete;
