import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Template from "../../../templates/Crew/NoonReport/Update";
import { Form } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../dexie/db";
import { ReportDexie } from "../../../../dexie/Report";
import { useGetPreviousReport } from "../../../../hooks/dexie/dexie";
import Loading from "../../../atoms/Loading";
import {
  mapToCreateReportMutationVariables,
  mapToFormData,
  useNoonReportForm,
  useNoonReportFormValidation,
} from "./noonReport.module";

const Update = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const [isConfirm, setIsConfirm] = useState(false);
  const location = useLocation();
  const location_form = location.state;
  const [robFile, setFile] = useState<File | undefined>(undefined);

  const navigate = useNavigate();
  const ship = useShip();

  if (!report_id) navigate("/");

  const previousReport = useGetPreviousReport(report_id);

  const onSaveDraft = (value: Form) => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToCreateReportMutationVariables(value, ship?.ship_id);

    const updateReport: ReportDexie = {
      id: report_id,
      isDraft: true,
      isSend: false,
      data: variables,
      draftForm: value,
      robFile: value.N_ROB_Filename ? robFile : undefined,
    };
    db.reports.update(report_id, updateReport).catch((e) => console.error(e));
    navigate(`/`);
  };

  const item = useLiveQuery(() => db.reports.get(report_id));

  const reportList = useLiveQuery(async () => {
    return (await db.reports.reverse().toArray()).filter(
      (report) => report.id !== Number(reportId)
    );
  }, []);

  const form = useNoonReportForm([
    location_form,
    item?.draftForm,
    mapToFormData(item?.data),
  ]);
  const formValidation = useNoonReportFormValidation(
    form.data,
    reportList,
    previousReport
  );

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const onConfirm = () => {
    setIsConfirm(true);

    formValidation.manageNoonReportErrorMessage();
    formValidation.hasErrorScrollUp();
    if (formValidation.hasError) {
      return;
    }

    navigate(`/update/confirm/${reportId}`, {
      state: { form: form.data, robFile },
    });
  };

  return (
    <Default title="Noon Report" selectedTitle={"Noon Report"}>
      {item && !form.isLoading ? (
        <Template
          defaultReport={item}
          form={form.data}
          reportId={reportId || ""}
          hasFormError={formValidation.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          onSaveDraft={onSaveDraft}
          ship={ship}
          previousReport={previousReport}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
          setFile={setFile}
          errorMessageList={formValidation.errorMessageList}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Default>
  );
};

export default Update;
