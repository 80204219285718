import {
  CreateReferenceDataMutationVariables,
  ReferenceDataInput,
  ReferenceDataType,
} from "../../../../../../gen/graphql/types";
import { FocForm, SpeedForm } from "./types";

export const mapToCreateReferenceDataMutationVariables = (
  form: SpeedForm
): CreateReferenceDataMutationVariables => {
  const inputData: ReferenceDataInput[] = [];

  form.speed_OG_1 !== null &&
    inputData.push({
      IMO_No: form.imo_no,
      Speed_OG: form.speed_OG_1,
      fuel_consumption_Ballast: form.empty_1,
      fuel_consumption_Laden: form.laden_1,
      fuel_consumption_Unique: form.unique_1,
      dataType: ReferenceDataType.Speed,
    });

  form.speed_OG_2 !== null &&
    inputData.push({
      IMO_No: form.imo_no,
      Speed_OG: form.speed_OG_2,
      fuel_consumption_Ballast: form.empty_2,
      fuel_consumption_Laden: form.laden_2,
      fuel_consumption_Unique: form.unique_2,
      dataType: ReferenceDataType.Speed,
    });

  form.speed_OG_3 !== null &&
    inputData.push({
      IMO_No: form.imo_no,
      Speed_OG: form.speed_OG_3,
      fuel_consumption_Ballast: form.empty_3,
      fuel_consumption_Laden: form.laden_3,
      fuel_consumption_Unique: form.unique_3,
      dataType: ReferenceDataType.Speed,
    });

  form.speed_OG_4 !== null &&
    inputData.push({
      IMO_No: form.imo_no,
      Speed_OG: form.speed_OG_4,
      fuel_consumption_Ballast: form.empty_4,
      fuel_consumption_Laden: form.laden_4,
      fuel_consumption_Unique: form.unique_4,
      dataType: ReferenceDataType.Speed,
    });

  form.speed_OG_5 !== null &&
    inputData.push({
      IMO_No: form.imo_no,
      Speed_OG: form.speed_OG_5,
      fuel_consumption_Ballast: form.empty_5,
      fuel_consumption_Laden: form.laden_5,
      fuel_consumption_Unique: form.unique_5,
      dataType: ReferenceDataType.Speed,
    });

  form.speed_OG_6 !== null &&
    inputData.push({
      IMO_No: form.imo_no,
      Speed_OG: form.speed_OG_6,
      fuel_consumption_Ballast: form.empty_6,
      fuel_consumption_Laden: form.laden_6,
      fuel_consumption_Unique: form.unique_6,
      dataType: ReferenceDataType.Speed,
    });

  form.speed_OG_7 !== null &&
    inputData.push({
      IMO_No: form.imo_no,
      Speed_OG: form.speed_OG_7,
      fuel_consumption_Ballast: form.empty_7,
      fuel_consumption_Laden: form.laden_7,
      fuel_consumption_Unique: form.unique_7,
      dataType: ReferenceDataType.Speed,
    });

  form.speed_OG_8 !== null &&
    inputData.push({
      IMO_No: form.imo_no,
      Speed_OG: form.speed_OG_8,
      fuel_consumption_Ballast: form.empty_8,
      fuel_consumption_Laden: form.laden_8,
      fuel_consumption_Unique: form.unique_8,
      dataType: ReferenceDataType.Speed,
    });

  form.speed_OG_9 !== null &&
    inputData.push({
      IMO_No: form.imo_no,
      Speed_OG: form.speed_OG_9,
      fuel_consumption_Ballast: form.empty_9,
      fuel_consumption_Laden: form.laden_9,
      fuel_consumption_Unique: form.unique_9,
      dataType: ReferenceDataType.Speed,
    });

  form.speed_OG_10 !== null &&
    inputData.push({
      IMO_No: form.imo_no,
      Speed_OG: form.speed_OG_10,
      fuel_consumption_Ballast: form.empty_10,
      fuel_consumption_Laden: form.laden_10,
      fuel_consumption_Unique: form.unique_10,
      dataType: ReferenceDataType.Speed,
    });

  const fuel_consumption_Ballast_Arr = inputData
    .map((v) => v.fuel_consumption_Ballast as number | null)
    .filter((v): v is Exclude<typeof v, null> => v !== null);

  const fuel_consumption_Laden_Arr = inputData
    .map((v) => v.fuel_consumption_Laden as number | null)
    .filter((v): v is Exclude<typeof v, null> => v !== null);
  if (
    inputData.length === 0 ||
    fuel_consumption_Ballast_Arr.length === 0 ||
    fuel_consumption_Laden_Arr.length === 0
  )
    inputData[0] = {
      IMO_No: form.imo_no,
      Speed_OG: 0,
      fuel_consumption_Ballast: 0,
      fuel_consumption_Laden: 0,
      fuel_consumption_Unique: 0,
      dataType: ReferenceDataType.Speed,
    };

  return {
    input: { ReferenceDataListInput: inputData },
  };
};

export const mapToCreateFocReferenceDataMutationVariables = (
  form: FocForm
): CreateReferenceDataMutationVariables => {
  const inputData: ReferenceDataInput[] = [];

  // for _1
  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_1 || 0,
    fuel_consumption_Ballast: form.foc_1 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.laden_1 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: form.foc_1 || null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.unique_1 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: form.foc_1 || null,
    dataType: ReferenceDataType.Foc,
  });

  // for _2
  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_2 || 0,
    fuel_consumption_Ballast: form.foc_2 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.laden_2 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: form.foc_2 || null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.unique_2 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: form.foc_2,
    dataType: ReferenceDataType.Foc,
  });

  // for _3
  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_3 || 0,
    fuel_consumption_Ballast: form.foc_3 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.laden_3 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: form.foc_3 || null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.unique_3 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: form.foc_3 || null,
    dataType: ReferenceDataType.Foc,
  });

  // for _4
  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_4 || 0,
    fuel_consumption_Ballast: form.foc_4 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.laden_4 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: form.foc_4 || null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.unique_4 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: form.foc_4 || null,
    dataType: ReferenceDataType.Foc,
  });

  // for _5
  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_5 || 0,
    fuel_consumption_Ballast: form.foc_5 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.laden_5 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: form.foc_5 || null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.unique_5 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: form.foc_5 || null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_6 || 0,
    fuel_consumption_Ballast: form.foc_6 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  // for _6
  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_6 || 0,
    fuel_consumption_Ballast: form.foc_6 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.laden_6 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: form.foc_6 || null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.unique_6 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: form.foc_6 || null,
    dataType: ReferenceDataType.Foc,
  });

  // for _7
  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_7 || 0,
    fuel_consumption_Ballast: form.foc_7 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.laden_7 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: form.foc_7 || null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.unique_7 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: form.foc_7 || null,
    dataType: ReferenceDataType.Foc,
  });

  // for _8
  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_8 || 0,
    fuel_consumption_Ballast: form.foc_8 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.laden_8 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: form.foc_8 || null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.unique_8 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: form.foc_8 || null,
    dataType: ReferenceDataType.Foc,
  });

  // for _9
  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_9 || 0,
    fuel_consumption_Ballast: form.foc_9 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.laden_9 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: form.foc_9 || null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.unique_9 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: form.foc_9 || null,
    dataType: ReferenceDataType.Foc,
  });

  // for _10
  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.empty_10 || 0,
    fuel_consumption_Ballast: form.foc_10 || null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.laden_10 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: form.foc_10 || null,
    fuel_consumption_Unique: null,
    dataType: ReferenceDataType.Foc,
  });

  inputData.push({
    IMO_No: form.imo_no,
    Speed_OG: form.unique_10 || 0,
    fuel_consumption_Ballast: null,
    fuel_consumption_Laden: null,
    fuel_consumption_Unique: form.foc_10 || null,
    dataType: ReferenceDataType.Foc,
  });

  const fuel_consumption_Ballast_Arr = inputData
    .map((v) => v.fuel_consumption_Ballast as number | null)
    .filter((v): v is Exclude<typeof v, null> => v !== null);

  const fuel_consumption_Laden_Arr = inputData
    .map((v) => v.fuel_consumption_Laden as number | null)
    .filter((v): v is Exclude<typeof v, null> => v !== null);
  if (
    inputData.length === 0 ||
    fuel_consumption_Ballast_Arr.length === 0 ||
    fuel_consumption_Laden_Arr.length === 0
  )
    inputData[0] = {
      IMO_No: form.imo_no,
      Speed_OG: 0,
      fuel_consumption_Ballast: 0,
      fuel_consumption_Laden: 0,
      fuel_consumption_Unique: 0,
      dataType: ReferenceDataType.Foc,
    };

  return {
    input: { ReferenceDataListInput: inputData },
  };
};
