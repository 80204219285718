import { FuelType } from "../../../../../gen/graphql/types";
import { AdditionalROB } from "../../../../pages/Crew/NoonReport/Create/types";

export const getOneAdditionalROB = (
  fuelType: FuelType,
  N_Additional_ROB?: AdditionalROB[]
) => {
  if (N_Additional_ROB === undefined) return undefined;

  return N_Additional_ROB.find((v) => v.N_Fuel_Type === fuelType);
};

export const setAdditionalAdditionalROB = (
  fuelType: FuelType,
  targetIndex?: number,
  value?: number,
  N_Additional_ROB: AdditionalROB[] = []
) => {
  const updatedAdditionalROB = [...N_Additional_ROB];

  if (targetIndex === undefined || !updatedAdditionalROB[targetIndex]) {
    updatedAdditionalROB.push({
      N_Fuel_Type: fuelType,
      N_Fuel_volume: value,
    });
  } else {
    updatedAdditionalROB[targetIndex] = {
      N_Fuel_Type: fuelType,
      N_Fuel_volume: value,
    };
  }
  return updatedAdditionalROB;
};

export const filterAdditionalROBByFuelType = (
  fuelTypeList: FuelType[],
  additionalROB?: AdditionalROB[]
) => {
  return additionalROB?.filter((v) => fuelTypeList.includes(v.N_Fuel_Type));
};
