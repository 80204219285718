import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Template from "../../../templates/Crew/Departure/Create";
import { Form, LocationState } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import { db } from "../../../../dexie/db";
import { DepartureDexie } from "../../../../dexie/Departure";
import { useGetLastFrontArrival } from "../../../../hooks/dexie/dexie";
import { pageData } from "../../../../App";
import {
  useDepartureForm,
  mapToDepartureMutationVariables,
} from "./departure.module";

type LocationForm = {
  form: Form;
};

const Create = (props: LocationState): React.ReactElement => {
  const navigate = useNavigate();

  const location = useLocation();
  const ship = useShip();
  const [isConfirm, setIsConfirm] = useState(false);

  const location_form = location.state as LocationForm;

  const form1 = props.form || location_form?.form || props.form;

  const form = useDepartureForm([form1]);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };
  const onSaveDraft = (value: Form) => {
    try {
      if (!ship?.ship_id) throw Error("Error! ship id is missing");
      const variables = mapToDepartureMutationVariables(value, ship?.ship_id);

      const newReport: DepartureDexie = {
        id: undefined,
        isDraft: true,
        isSend: false,
        data: variables,
        draftForm: value,
      };

      db.departure.add(newReport, 1).catch((e) => console.error(e));
      navigate(pageData.crewDeparture.path());
    } catch (error) {
      console.error(error);
    }
  };

  const lastArrival = useGetLastFrontArrival();

  const onConfirm = () => {
    form.hasErrorScrollUp();
    setIsConfirm(true);
    if (form.hasError) return;
    navigate("/departure/create/confirm", { state: { form: form.data } });
  };

  return (
    <Default title="" selectedTitle={"Departure"}>
      <Template
        form={form.data}
        hasFormError={form.hasError}
        onUpdateForm={onUpdateForm}
        onConfirm={onConfirm}
        onSaveDraft={onSaveDraft}
        ship={ship}
        lastArrival={lastArrival}
        isConfirm={isConfirm}
        setIsConfirm={setIsConfirm}
      />
    </Default>
  );
};

export default Create;
