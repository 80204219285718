import { getTimeZoneValues } from "./form-util";
import { isNumeric } from "./type-checks";

interface TimeZone {
  timeZone: string;
  eastWest: string;
  lonStart: number;
  lonEnd: number;
}

const timeZones: TimeZone[] = [
  { timeZone: "+00:00", eastWest: "E", lonStart: 0, lonEnd: 7.5 },
  { timeZone: "+01:00", eastWest: "E", lonStart: 7.5, lonEnd: 22.5 },
  { timeZone: "+02:00", eastWest: "E", lonStart: 22.5, lonEnd: 37.5 },
  { timeZone: "+03:00", eastWest: "E", lonStart: 37.5, lonEnd: 52.5 },
  { timeZone: "+04:00", eastWest: "E", lonStart: 52.5, lonEnd: 67.5 },
  { timeZone: "+05:00", eastWest: "E", lonStart: 67.5, lonEnd: 82.5 },
  { timeZone: "+06:00", eastWest: "E", lonStart: 82.5, lonEnd: 97.5 },
  { timeZone: "+07:00", eastWest: "E", lonStart: 97.5, lonEnd: 112.5 },
  { timeZone: "+08:00", eastWest: "E", lonStart: 112.5, lonEnd: 127.5 },
  { timeZone: "+09:00", eastWest: "E", lonStart: 127.5, lonEnd: 142.5 },
  { timeZone: "+10:00", eastWest: "E", lonStart: 142.5, lonEnd: 157.5 },
  { timeZone: "+11:00", eastWest: "E", lonStart: 157.5, lonEnd: 172.5 },
  { timeZone: "+12:00", eastWest: "E", lonStart: 172.5, lonEnd: 180 },

  { timeZone: "+00:00", eastWest: "W", lonStart: 0, lonEnd: 7.5 },
  { timeZone: "-01:00", eastWest: "W", lonStart: 7.5, lonEnd: 22.5 },
  { timeZone: "-02:00", eastWest: "W", lonStart: 22.5, lonEnd: 37.5 },
  { timeZone: "-03:00", eastWest: "W", lonStart: 37.5, lonEnd: 52.5 },
  { timeZone: "-04:00", eastWest: "W", lonStart: 52.5, lonEnd: 67.5 },
  { timeZone: "-05:00", eastWest: "W", lonStart: 67.5, lonEnd: 82.5 },
  { timeZone: "-06:00", eastWest: "W", lonStart: 82.5, lonEnd: 97.5 },
  { timeZone: "-07:00", eastWest: "W", lonStart: 97.5, lonEnd: 112.5 },
  { timeZone: "-08:00", eastWest: "W", lonStart: 112.5, lonEnd: 127.5 },
  { timeZone: "-09:00", eastWest: "W", lonStart: 127.5, lonEnd: 142.5 },
  { timeZone: "-10:00", eastWest: "W", lonStart: 142.5, lonEnd: 157.5 },
  { timeZone: "-11:00", eastWest: "W", lonStart: 157.5, lonEnd: 172.5 },
  { timeZone: "-12:00", eastWest: "W", lonStart: 172.5, lonEnd: 180 },
];

type Props = {
  longDirection: string | null | undefined;
  longDeg: string | null | undefined;
  longMin: string | null | undefined;
};

export function getOffsetForLongitude(form: Props):
  | {
      TimeZone_PorN: string;
      TimeZone_HH: string;
      TimeZone_MM: string;
    }
  | undefined {
  const data = processCoordinate(form);
  if (data === undefined) return;
  for (const range of timeZones) {
    if (data.eastWest === range.eastWest) {
      if (
        range.lonStart <= data.lon &&
        (data.lon < range.lonEnd || (range.lonEnd === 180 && data.lon === 180))
      ) {
        return getTimeZoneValues(range.timeZone);
      }
    }
  }
  return undefined;
}

function processCoordinate(
  form: Props
): { lon: number; eastWest: string } | undefined {
  if (!form.longDirection || !form.longDeg || !isNumeric(form.longDeg)) return;

  const numericPart =
    Number(form.longDeg) +
    (isNumeric(form.longMin) ? Number(form.longMin) / 60 : 0);

  return { lon: Number(numericPart), eastWest: form.longDirection };
}
