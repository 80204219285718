import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { db } from "../../../../../dexie/db";
import Default, { useShip } from "../../../../layouts/DefaultCrew";
import Template from "../../../../templates/Crew/Departure/Update/Confirm";
import { Form, LocationState } from "../Create/types";
import { useLiveQuery } from "dexie-react-hooks";
import { DepartureDexie } from "../../../../../dexie/Departure";
import { useGetPreviousArrival } from "../../Arrival/Update/Confirm";
import { mapToDepartureMutationVariables } from "../departure.module";

export const useGetPreviousDeparture = (reportId: number) => {
  const departureList = useLiveQuery(
    () => db.departure.toArray().catch((e) => console.error(e)),
    []
  );
  if (
    !departureList ||
    departureList.length === 0 ||
    !departureList[departureList.length - 1]
  ) {
    return;
  }
  const departure = departureList.findIndex((v) => v.id === reportId);

  const previousDeparture = departureList[departure - 1];

  return previousDeparture;
};

type LocationForm = {
  form: Form;
};

const Confirm = (props: LocationState): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const ship = useShip();
  const navigate = useNavigate();

  if (reportId === undefined) navigate("/");

  const report_id = Number(reportId);

  const previousArrival = useGetPreviousArrival(report_id);

  const location = useLocation();

  const location_form = location.state as LocationForm;

  if (!props.form && !location_form.form) {
    navigate(-1);
  }

  const form = props.form || location_form.form;

  const onUpdate = async () => {
    if (!ship?.ship_id) throw Error("Error! ship id is missing");
    const variables = mapToDepartureMutationVariables(form, ship?.ship_id);
    const updateDeparture: DepartureDexie = {
      id: report_id,
      isDraft: false,
      isSend: false,
      data: variables,
    };
    db.departure.update(Number(reportId), updateDeparture);
    navigate("/departure/create/complete");
  };

  const onBack = () => {
    navigate("/crew/departure");
  };

  return (
    <Default title="" selectedTitle={"Departure"}>
      <Template
        form={location_form.form}
        reportId={report_id}
        isLoading={false}
        onUpdate={onUpdate}
        onBack={onBack}
        previousArrival={previousArrival}
      />
    </Default>
  );
};

export default Confirm;
