import * as React from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import FormBlock from "../../../organisms/Crew/NoonReport/FormBlock";
import { Form, Props } from "../../../pages/Crew/NoonReport/Create/types";
import { ShipDexie } from "../../../../dexie/Ship";
import { ReportDexie } from "../../../../dexie/Report";
import { ErrorMessageData } from "../../../../hooks/errorMessage.hook";
import ErrorMessageList from "../../../atoms/ErrorMessageList";

type ConfirmProps = Props & {
  hasFormError: boolean;
  reportId: string;
  onUpdateForm(value: Form): void;
  onConfirm(value: Form): void;
  onSaveDraft(value: Form): void;
  ship: ShipDexie | undefined;
  defaultReport: ReportDexie;
  previousReport: ReportDexie | undefined;
  isConfirm: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  errorMessageList: ErrorMessageData[];
};

const Update = (props: ConfirmProps): React.ReactElement => {
  const navigate = useNavigate();

  const onUpdateForm = (value: Form) => {
    props.onUpdateForm(value);
  };

  const onClickConfirm = () => {
    props.onConfirm(props.form);
  };

  const onClickSaveDraft = () => {
    props.onSaveDraft(props.form);
  };

  return (
    <React.Fragment>
      <Paper sx={{ backgroundColor: "#27292E", borderRadius: "20px" }}>
      <ErrorMessageList errorMessageList={props.errorMessageList} />
        <FormBlock
          defaultLatLong={props.defaultReport.data.N_LatLong}
          defaultTimeZone={props.defaultReport.data.N_TimeZone}
          title="Update Noon Report"
          isPreview={false}
          onUpdateForm={onUpdateForm}
          form={props.form}
          ship={props.ship}
          lastReport={props.previousReport}
          isConfirm={props.isConfirm}
          setIsConfirm={props.setIsConfirm}
          setFile={props.setFile}
        />

        <Box
          sx={{
            textAlign: "left",
            px: 2,
            py: 4,
          }}
        >
          <Button
            sx={{
              mx: 2,
              width: "250",
              backgroundColor: "#20BE89  !important",
              fontFamily: "Public Sans",
              "&.MuiButton-root": {
                fontFamily: "Public Sans",
              },
              textTransform: "none",
            }}
            variant="contained"
            onClick={onClickConfirm}
          >
            Confirm
          </Button>
          <Button
            sx={{
              mx: 2,
              backgroundColor: "#555860",
              color: "#20BE89",
              textTransform: "none",
            }}
            variant="contained"
            onClick={onClickSaveDraft}
          >
            Save Draft
          </Button>
          <Button
            sx={{ mx: 2, backgroundColor: "#555860 ", textTransform: "none" }}
            variant="contained"
            onClick={() => navigate("/")}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default Update;
