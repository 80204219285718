export const orgRound = (value: number | null, base: number) => {
  if (value === null) return 0;
  return Math.round(value * base) / base;
};

// 平均値を計算
export function calcAverage(values: number[]): number {
  return values.reduce((sum, value) => sum + value, 0) / values.length;
}

// 標準偏差を計算
export function calcStandardDeviation(values: number[]): number {
  const avg = calcAverage(values);
  const squareDiffs = values.map((value) => Math.pow(value - avg, 2));
  return Math.sqrt(calcAverage(squareDiffs));
}

// 合計値を計算
export function calcSum(values: (number | null | undefined)[]): number {
  return (
    values.reduce(
      (sum, value) => (sum ? Number(sum) : 0) + (value ? Number(value) : 0),
      0
    ) ?? 0
  );
}
