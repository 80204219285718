import {
  Box,
  FormControlLabel,
  Grid,
  Modal,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import {
  darkGrayHoverColor,
  paleGray,
  weightedGray,
} from "../../../../utils/color";
import { FilterBooleanType } from "../../../pages/Owner/Analysis/types";
import { FilterTitleList } from "../../../pages/Owner/Analysis/const";
import LightGrayButton from "../../../atoms/LightGrayButton";

const getIsMeUsage = (
  filterTitle: string,
  targetFilterTitle: string,
  prevIsMeUsage: boolean
) => {
  if (filterTitle === targetFilterTitle) {
    return prevIsMeUsage ? false : true;
  }
  return prevIsMeUsage;
};

const switchTextKeyIndex = {
  MEOnly: "ME Only" as switchTextKeyType,
  StandarDeviation: "Standard deviation" as switchTextKeyType,
};

type switchTextKeyType = "ME Only" | "Standard deviation";

const switchText = {
  "ME Only": { off: "Total", on: "M/E Only" },
  "Standard deviation": { off: "Off", on: "On" },
};

type RowProps = {
  filterTitle: string;
  isMeUsage: boolean;
  setIsMeUsageFilter: React.Dispatch<React.SetStateAction<FilterBooleanType>>;
  switchTextKey: switchTextKeyType;
};

const FineTuningFilterModalRow = (props: RowProps) => {
  return (
    <>
      <Grid
        item
        xs={props.switchTextKey === switchTextKeyIndex.StandarDeviation ? 4 : 3}
        sx={{
          py: 0,
        }}
        alignItems="baseline"
      >
        <Typography>
          {props.filterTitle === FilterTitleList.Filter_All
            ? "All Filter"
            : props.filterTitle}
        </Typography>
      </Grid>
      <Grid
        item
        xs={props.switchTextKey === switchTextKeyIndex.StandarDeviation ? 8 : 9}
        sx={{
          py: 0,
        }}
      >
        <Stack direction="row" spacing={0.5} alignItems="baseline">
          <Typography>{switchText[props.switchTextKey].off}</Typography>
          <FormControlLabel
            control={
              <Switch
                sx={{
                  "&.MuiSwitch-colorPrimary": {
                    color: darkGrayHoverColor,
                  },
                }}
                checked={props.isMeUsage}
                onChange={() => {
                  if (props.filterTitle === FilterTitleList.Filter_All) {
                    props.setIsMeUsageFilter((prev) => {
                      const IsMeUsage = prev.filter1 ? false : true;
                      return {
                        filter1: IsMeUsage,
                        filter2: IsMeUsage,
                        filter3: IsMeUsage,
                        filter4: IsMeUsage,
                      };
                    });
                  } else {
                    props.setIsMeUsageFilter((prev) => {
                      return {
                        filter1: getIsMeUsage(
                          props.filterTitle,
                          FilterTitleList.Filter_1,
                          prev.filter1
                        ),
                        filter2: getIsMeUsage(
                          props.filterTitle,
                          FilterTitleList.Filter_2,
                          prev.filter2
                        ),
                        filter3: getIsMeUsage(
                          props.filterTitle,
                          FilterTitleList.Filter_3,
                          prev.filter3
                        ),
                        filter4: getIsMeUsage(
                          props.filterTitle,
                          FilterTitleList.Filter_4,
                          prev.filter4
                        ),
                      };
                    });
                  }
                }}
              />
            }
            label={switchText[props.switchTextKey].on}
          />
        </Stack>
      </Grid>
    </>
  );
};

type Props = {
  isOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMeUsageFilter: FilterBooleanType;
  setIsMeUsageFilter: React.Dispatch<React.SetStateAction<FilterBooleanType>>;
  isStandardDeviationFilter: FilterBooleanType;
  setIsStandardDeviationFilter: React.Dispatch<
    React.SetStateAction<FilterBooleanType>
  >;
};

export function FuelConsumptionUsageFilterModal(props: Props) {
  const handleClose = () => props.setIsModalOpen(false);

  const style = {
    color: weightedGray,
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 630,
    bgcolor: paleGray,
    boxShadow: 24,
    p: 2.5,
  };
  return (
    <Modal
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={style}>
          <Typography sx={{ fontSize: "24px", pb: 2, fontWeight: "bold" }}>
            {"Fine Tuning Filters"}
          </Typography>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent={"space-between"}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  pb: 1,
                  fontWeight: "bold",
                  whiteSpace: "pre-line",
                }}
              >
                {`Filter by fuel consumption usages \n(M/E, G/E, Boiler)`}
              </Typography>

              <Grid
                container
                item
                xs={12}
                justifyContent={"center"}
                alignItems="baseline"
              >
                <FineTuningFilterModalRow
                  filterTitle={FilterTitleList.Filter_All}
                  isMeUsage={
                    props.isMeUsageFilter.filter1 &&
                    props.isMeUsageFilter.filter2 &&
                    props.isMeUsageFilter.filter3 &&
                    props.isMeUsageFilter.filter4
                  }
                  setIsMeUsageFilter={props.setIsMeUsageFilter}
                  switchTextKey={switchTextKeyIndex.MEOnly}
                />
                <FineTuningFilterModalRow
                  filterTitle={FilterTitleList.Filter_1}
                  isMeUsage={props.isMeUsageFilter.filter1}
                  setIsMeUsageFilter={props.setIsMeUsageFilter}
                  switchTextKey={switchTextKeyIndex.MEOnly}
                />
                <FineTuningFilterModalRow
                  filterTitle={FilterTitleList.Filter_2}
                  isMeUsage={props.isMeUsageFilter.filter2}
                  setIsMeUsageFilter={props.setIsMeUsageFilter}
                  switchTextKey={switchTextKeyIndex.MEOnly}
                />
                <FineTuningFilterModalRow
                  filterTitle={FilterTitleList.Filter_3}
                  isMeUsage={props.isMeUsageFilter.filter3}
                  setIsMeUsageFilter={props.setIsMeUsageFilter}
                  switchTextKey={switchTextKeyIndex.MEOnly}
                />
                <FineTuningFilterModalRow
                  filterTitle={FilterTitleList.Filter_4}
                  isMeUsage={props.isMeUsageFilter.filter4}
                  setIsMeUsageFilter={props.setIsMeUsageFilter}
                  switchTextKey={switchTextKeyIndex.MEOnly}
                />
              </Grid>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  pb: 1,
                  fontWeight: "bold",
                  whiteSpace: "pre-line",
                }}
              >
                {"Filter by standard deviation \n (within 95%)"}
              </Typography>

              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <FineTuningFilterModalRow
                  filterTitle={FilterTitleList.Filter_All}
                  isMeUsage={
                    props.isStandardDeviationFilter.filter1 &&
                    props.isStandardDeviationFilter.filter2 &&
                    props.isStandardDeviationFilter.filter3 &&
                    props.isStandardDeviationFilter.filter4
                  }
                  setIsMeUsageFilter={props.setIsStandardDeviationFilter}
                  switchTextKey={switchTextKeyIndex.StandarDeviation}
                />
                <FineTuningFilterModalRow
                  filterTitle={FilterTitleList.Filter_1}
                  isMeUsage={props.isStandardDeviationFilter.filter1}
                  setIsMeUsageFilter={props.setIsStandardDeviationFilter}
                  switchTextKey={switchTextKeyIndex.StandarDeviation}
                />
                <FineTuningFilterModalRow
                  filterTitle={FilterTitleList.Filter_2}
                  isMeUsage={props.isStandardDeviationFilter.filter2}
                  setIsMeUsageFilter={props.setIsStandardDeviationFilter}
                  switchTextKey={switchTextKeyIndex.StandarDeviation}
                />
                <FineTuningFilterModalRow
                  filterTitle={FilterTitleList.Filter_3}
                  isMeUsage={props.isStandardDeviationFilter.filter3}
                  setIsMeUsageFilter={props.setIsStandardDeviationFilter}
                  switchTextKey={switchTextKeyIndex.StandarDeviation}
                />
                <FineTuningFilterModalRow
                  filterTitle={FilterTitleList.Filter_4}
                  isMeUsage={props.isStandardDeviationFilter.filter4}
                  setIsMeUsageFilter={props.setIsStandardDeviationFilter}
                  switchTextKey={switchTextKeyIndex.StandarDeviation}
                />
              </Grid>
            </Box>
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
            <LightGrayButton ButtonName={"Clear"} onClick={handleClose} />
          </Box>
        </Box>
      </>
    </Modal>
  );
}
