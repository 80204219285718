import { TableCell, TableRow } from "@mui/material";
import { emptyToHyphen } from "../../../../utils/formats";
import CustomOwnerTableCell from "../../../atoms/CustomOwnerTableCell";
import {
  lightGrayC9D0DC,
  mediumGray,
  weightedGray,
} from "../../../../utils/color";

type TableDataRowProps = {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  unitName?: string;
  previousData?: string;
};

export const TableDataRow = (props: TableDataRowProps) => {
  return (
    <TableRow>
      <CustomOwnerTableCell sx={{ color: "#888888", width: "250px" }}>
        {props.title}
      </CustomOwnerTableCell>
      <CustomOwnerTableCell />
      <CustomOwnerTableCell
        colSpan={2}
        sx={{
          color: "#333333",
          wordBreak: "break-word",
          whiteSpace: "pre-line",
        }}
      >
        {emptyToHyphen(props.data)}
        <span style={{ color: "#333333", paddingLeft: 5 }}>
          {props.unitName || ""}
        </span>
      </CustomOwnerTableCell>
      <CustomOwnerTableCell sx={{ color: "#333333" }}></CustomOwnerTableCell>
    </TableRow>
  );
};

type Table2DataRowProps = {
  topTitle: string;
  title1: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data1: any;
  unitName?: string;
  title2: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data2: any;
};

export const Table2DataRow = (props: Table2DataRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell
          rowSpan={2}
          sx={{
            verticalAlign: "top",
            color: "#888888",
            borderBottom: "1px solid #C9D0DC",
          }}
        >
          {props.topTitle}
        </TableCell>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title1}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data1)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell sx={{ color: "#888888" }}></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title2}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data2)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell
          colSpan={2}
          sx={{ color: "#888888" }}
        ></CustomOwnerTableCell>
      </TableRow>
    </>
  );
};

type Table3DataRowProps = {
  topTitle: string;
  title1: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data1: any;
  unitName?: string;
  title2: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data2: any;
  title3: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data3: any;
};

export const Table3DataRow = (props: Table3DataRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell
          rowSpan={3}
          sx={{
            verticalAlign: "top",
            color: "#888888",
            borderBottom: "1px solid #C9D0DC",
          }}
        >
          {props.topTitle}
        </TableCell>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title1}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data1)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell sx={{ color: "#888888" }}></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title2}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data2)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell sx={{ color: "#333333" }}></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title3}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data3)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell sx={{ color: "#888888" }}></CustomOwnerTableCell>
      </TableRow>
    </>
  );
};

type Table4DataRowProps = {
  topTitle: string;
  title1: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data1: any;
  title2: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data2: any;
  title3: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data3: any;
  title4: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data4: any;
  unitName1?: string;
  unitName2?: string;
  unitName3?: string;
};

export const Table4DataRow = (props: Table4DataRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell
          rowSpan={4}
          sx={{
            verticalAlign: "top",
            color: "#888888",
            borderBottom: "1px solid #C9D0DC",
          }}
        >
          {props.topTitle}
        </TableCell>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title1}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data1)}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell sx={{ color: "#888888" }}></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title2}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data2)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName1 || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell sx={{ color: "#333333" }}></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title3}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data3)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName2 || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell sx={{ color: "#888888" }}></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title4}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data4)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName3 || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell sx={{ color: "#888888" }}></CustomOwnerTableCell>
      </TableRow>
    </>
  );
};

type Table6DataRowProps = {
  topTitle: string;
  title1: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data1: any;
  title2: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data2: any;
  title3: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data3: any;
  title4: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data4: any;
  title5: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data5: any;
  title6: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data6: any;
  unitName1?: string;
  unitName2?: string;
};

export const Table6DataRow = (props: Table6DataRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell
          rowSpan={6}
          sx={{
            verticalAlign: "top",
            color: "#888888",
            borderBottom: "1px solid #C9D0DC",
          }}
        >
          {props.topTitle}
        </TableCell>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title1}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data1)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName1 || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title2}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data2)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName1 || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title3}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data3)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName2 || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title4}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data4)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName2 || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title5}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell colSpan={2} sx={{ color: "#333333" }}>
          {emptyToHyphen(props.data5)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName2 || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell></CustomOwnerTableCell>
      </TableRow>
      <TableRow>
        <CustomOwnerTableCell sx={{ color: "#888888" }}>
          {props.title6}
        </CustomOwnerTableCell>
        <CustomOwnerTableCell
          colSpan={2}
          sx={{ color: "#333333", borderBottomColor: "#C9D0DC" }}
        >
          {emptyToHyphen(props.data6)}
          <span style={{ color: "#333333", paddingLeft: 5 }}>
            {props.unitName1 || ""}
          </span>
        </CustomOwnerTableCell>
        <CustomOwnerTableCell></CustomOwnerTableCell>
      </TableRow>
    </>
  );
};

type TableAnyDataRowProps = {
  topTitle: string;
  title: string[];
  data: (string | number | null | undefined)[];
  unitName: string[];
};

export const TableAnyDataRow = (props: TableAnyDataRowProps) => {
  if (props.title.length === 0) return <></>;

  return (
    <>
      {props.title.map((v, index) => {
        return (
          <TableRow key={index}>
            {index == 0 && (
              <TableCell
                rowSpan={props.title.length}
                sx={{
                  verticalAlign: "top",
                  color: mediumGray,
                  borderBottom: `1px solid ${lightGrayC9D0DC}`,
                }}
              >
                {props.topTitle}
              </TableCell>
            )}
            <CustomOwnerTableCell sx={{ color: mediumGray }}>
              {props.title[index]}
            </CustomOwnerTableCell>
            <CustomOwnerTableCell
              colSpan={2}
              sx={{
                color: weightedGray,
                borderBottomColor: `${
                  index === props.title.length - 1 ? lightGrayC9D0DC : ""
                }`,
              }}
            >
              {emptyToHyphen(props.data[index])}
              <span style={{ color: weightedGray, paddingLeft: 5 }}>
                {props.unitName[index] || ""}
              </span>
            </CustomOwnerTableCell>
            <CustomOwnerTableCell></CustomOwnerTableCell>
          </TableRow>
        );
      })}
    </>
  );
};

type SpareTableDataRowProps = {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  unitName?: string | null;
  previousData?: string;
};

export const SpareDataRow = (props: SpareTableDataRowProps) => {
  return (
    <TableRow>
      <CustomOwnerTableCell sx={{ color: "#888888", width: "250px" }}>
        {props.title}
      </CustomOwnerTableCell>
      <CustomOwnerTableCell
        colSpan={2}
        sx={{ wordBreak: "break-word", whiteSpace: "pre-line" }}
      >
        {emptyToHyphen(props.data)}
        <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
          {props.unitName || ""}
        </span>
      </CustomOwnerTableCell>
      <CustomOwnerTableCell sx={{ color: "#888888" }}></CustomOwnerTableCell>
      <CustomOwnerTableCell />
    </TableRow>
  );
};
