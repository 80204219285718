import React, { useEffect, useState } from "react";
import Default from "../../../layouts/DefaultCrew";
import Template from "../../../templates/Crew/Bunkering/Bunkering";
import { Bunkering as BunkeringType } from "./Create/types";
import { db } from "../../../../dexie/db";
import { useLiveQuery } from "dexie-react-hooks";
import { ReportingErrorBoundary } from "../../../atoms/ReportingErrorBoundary";
import { BunkeringInput } from "../../../../gen/graphql/types";
import {
  useContextIDs,
  useContextReportSearchCondition,
} from "../../../../App";
import { BunkeringDexie } from "../../../../dexie/Bunkering";
import { useCrewSearchForm } from "../crew.module";
import { ReportSearchCondition } from "../../pages.module";
import { useBunkerings } from "../../../../hooks/commonQuery/bunkering";

export const mapToBunkeringVariables = (
  report: BunkeringType
): BunkeringInput => {
  return {
    id: report.id,
    ship_id: 0,
    B_Vessel_Name: report.B_Vessel_Name,
    B_Voy: report.B_Voy,
    B_Timezone: report.B_Timezone,
    B_Bunkering_Date_time: report.B_Bunkering_Date_time,
    B_LatLong: report.B_LatLong,
    B_PortName: report.B_PortName,
    B_UNLOC: report.B_UNLOC,
    B_Port_LorU: report.B_Port_LorU,
    B_EUorUK: report.B_EUorUK,
    B_Fuel_name_1: report.B_Fuel_name_1,
    B_Mass_1: report.B_Mass_1,
    B_Volume_1: report.B_Volume_1,
    B_Density_1: report.B_Density_1,
    B_Fuel_name_2: report.B_Fuel_name_2,
    B_Mass_2: report.B_Mass_2,
    B_Volume_2: report.B_Volume_2,
    B_Density_2: report.B_Density_2,
    B_Fuel_name_3: report.B_Fuel_name_3,
    B_Mass_3: report.B_Mass_3,
    B_Volume_3: report.B_Volume_3,
    B_Density_3: report.B_Density_3,
    B_Fuel_name_4: report.B_Fuel_name_4,
    B_Mass_4: report.B_Mass_4,
    B_Volume_4: report.B_Volume_4,
    B_Density_4: report.B_Density_4,
    B_Fuel_name_5: report.B_Fuel_name_5,
    B_Mass_5: report.B_Mass_5,
    B_Volume_5: report.B_Volume_5,
    B_Density_5: report.B_Density_5,
    B_Fuel_name_6: report.B_Fuel_name_6,
    B_Mass_6: report.B_Mass_6,
    B_Volume_6: report.B_Volume_6,
    B_Density_6: report.B_Density_6,
    B_Fuel_name_7: report.B_Fuel_name_7,
    B_Mass_7: report.B_Mass_7,
    B_Volume_7: report.B_Volume_7,
    B_Density_7: report.B_Density_7,
    B_Fuel_name_8: report.B_Fuel_name_8,
    B_Mass_8: report.B_Mass_8,
    B_Volume_8: report.B_Volume_8,
    B_Density_8: report.B_Density_8,
    B_Fuel_name_9: report.B_Fuel_name_9,
    B_Mass_9: report.B_Mass_9,
    B_Volume_9: report.B_Volume_9,
    B_Density_9: report.B_Density_9,
    B_Fuel_name_10: report.B_Fuel_name_10,
    B_Mass_10: report.B_Mass_10,
    B_Volume_10: report.B_Volume_10,
    B_Density_10: report.B_Density_10,
    B_Filename: report.B_Filename,
  };
};

const Bunkering = (): React.ReactElement => {
  const frontDbBunkering = useLiveQuery(
    () => db.bunkering.reverse().toArray(),
    []
  );
  const { ship_id } = useContextIDs();
  const { reportSearchCondition } = useContextReportSearchCondition();
  const form = useCrewSearchForm(ship_id, reportSearchCondition);
  const reports = useBunkerings();
  const [isServerData, setIsServerData] = useState(false);

  const onUpdateForm = (value: ReportSearchCondition) => {
    form.update(value);
  };

  const onUpdateSearchCondition = (
    value: ReportSearchCondition,
    isWithRefresh?: boolean
  ) => {
    form.update(value);
    if (!isWithRefresh) {
      return;
    }

    reports.refetch(form.data);
  };

  const onSearch = () => {
    form.data.pagination.page = 1;
    form.data.ship_id = ship_id;

    if (!form.data.Voy && !form.data.From_DateTime && !form.data.To_DateTime) {
      setIsServerData(false);
    } else {
      reports.refetch(form.data);
    }
  };

  const serverReportList: BunkeringDexie[] = reports.data.bunkeringList.map(
    (report) => {
      const variavle = mapToBunkeringVariables(report);
      return {
        id: undefined,
        isDraft: false,
        isSend: true,
        bunkeringId: report.id,
        data: variavle,
      };
    }
  );

  useEffect(() => {
    if (
      serverReportList.length > 0 &&
      !reports.isLoading &&
      (form.data.Voy || form.data.From_DateTime || form.data.To_DateTime)
    ) {
      setIsServerData(true);
    }
  }, [
    form.data.From_DateTime,
    form.data.To_DateTime,
    form.data.Voy,
    reports.isLoading,
    serverReportList.length,
  ]);

  const deleteReport = (id: number) => {
    db.bunkering.delete(id).catch((e) => console.error(e));
  };

  return (
    <Default title="" selectedTitle={"Bunkering"}>
      <ReportingErrorBoundary>
        <Template
          reports={isServerData ? serverReportList : frontDbBunkering || []}
          deleteReport={deleteReport}
          isServerData={isServerData}
          form={form.data}
          onUpdateForm={onUpdateForm}
          totalPageCount={reports.data.totalPageCount}
          onSearch={onSearch}
          onUpdateSearchCondition={onUpdateSearchCondition}
        />
      </ReportingErrorBoundary>
    </Default>
  );
};

export default Bunkering;
