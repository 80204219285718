import React, { useEffect, useState } from "react";
import { NonExistentImoNo } from "../../../../gen/graphql/types";
import Template from "../../../templates/Owner/Analysis/Analysis";
import DefaultOwner from "../../../layouts/DefaultOwner";
import {
  DefaultFilterCondition,
  ResetFilterCondition,
  SpeedConsumptionFilterCondition,
  checkRequiredFilterCondition,
  useReportsGraph,
} from "./queries";
import { useVesselNames } from "../../../atoms/VesselNamesSelect";
import { queryParamImoNumber } from "../../../../utils/const";
import { useSearchParams } from "react-router-dom";
import { getPastPeriods, isAnyFilterTrue } from "./analysisUtils";
import { useReferenceData } from "./analysis.hook";

export const useFilterForm = () => {
  const [data, update] = useState<SpeedConsumptionFilterCondition>(
    DefaultFilterCondition
  );

  const hasFilterFormError = data
    ? !data.imo_no || !data.from_dateTime || !data.to_dateTime
    : false;

  return {
    data,
    update,
    hasFilterFormError,
  };
};

const defaultFilterBooleans = {
  filter1: false,
  filter2: false,
  filter3: false,
  filter4: false,
};

export type IndividualFSpeedConsumptionFilterCondition = {
  condition1: SpeedConsumptionFilterCondition;
  condition2: SpeedConsumptionFilterCondition;
  condition3: SpeedConsumptionFilterCondition;
  condition4: SpeedConsumptionFilterCondition;
};

const Analysis = (): React.ReactElement => {
  const shipList = useVesselNames();
  const [searchParams] = useSearchParams();
  const imoNumber = searchParams.get(queryParamImoNumber);
  const [isMeUsageFilter, setIsMeUsageFilter] = useState(defaultFilterBooleans);

  const [isStandardDeviationFilter, setIsStandardDeviationFilter] = useState(
    defaultFilterBooleans
  );

  // ダッシュボードからAnalysis画面に遷移した際に、グラフのデータを取得したかどうかを判別するstate
  const [isGraph1DataFetched, setIsGraph1DataFetched] =
    useState<boolean>(false);
  const [isGraph2DataFetched, setIsGraph2DataFetched] =
    useState<boolean>(false);
  const [isGraph3DataFetched, setIsGraph3DataFetched] =
    useState<boolean>(false);
  const [isGraph4DataFetched, setIsGraph4DataFetched] =
    useState<boolean>(false);

  const referenceDataList = useReferenceData();

  const filterAllForm = useFilterForm();
  const filterForm1 = useFilterForm();
  const filterForm2 = useFilterForm();
  const filterForm3 = useFilterForm();
  const filterForm4 = useFilterForm();

  const reports1 = useReportsGraph();
  const reports2 = useReportsGraph();
  const reports3 = useReportsGraph();
  const reports4 = useReportsGraph();

  const onUpdateFilterForm1 = (condition: SpeedConsumptionFilterCondition) => {
    filterForm1.update(condition);
  };

  const onUpdateFilterForm2 = (condition: SpeedConsumptionFilterCondition) => {
    filterForm2.update(condition);
  };

  const onUpdateFilterForm3 = (condition: SpeedConsumptionFilterCondition) => {
    filterForm3.update(condition);
  };

  const onUpdateFilterForm4 = (condition: SpeedConsumptionFilterCondition) => {
    filterForm4.update(condition);
  };

  const onUpdateAllFilterForm = (
    condition: SpeedConsumptionFilterCondition
  ) => {
    const periods = getPastPeriods(new Date(), condition.from_to_period);
    filterAllForm.update({
      ...condition,
      from_dateTime: periods[0].from,
      to_dateTime: periods[0].to,
    });

    filterForm1.update({
      ...condition,
      from_dateTime: periods[0].from,
      to_dateTime: periods[0].to,
    });
    filterForm2.update({
      ...condition,
      from_dateTime: periods[1].from,
      to_dateTime: periods[1].to,
    });
    filterForm3.update({
      ...condition,
      from_dateTime: periods[2].from,
      to_dateTime: periods[2].to,
    });
    filterForm4.update({
      ...condition,
      from_dateTime: periods[3].from,
      to_dateTime: periods[3].to,
    });
  };

  const onApplyFilter1 = (condition: SpeedConsumptionFilterCondition) => {
    if (checkRequiredFilterCondition(condition)) return;
    referenceDataList.refetch(condition.imo_no);
    reports1.refetch(condition);
  };

  const onApplyFilter2 = (condition: SpeedConsumptionFilterCondition) => {
    if (checkRequiredFilterCondition(condition)) return;
    referenceDataList.refetch(condition.imo_no);
    reports2.refetch(condition);
  };

  const onApplyFilter3 = (condition: SpeedConsumptionFilterCondition) => {
    if (checkRequiredFilterCondition(condition)) return;
    referenceDataList.refetch(condition.imo_no);
    reports3.refetch(condition);
  };

  const onApplyFilter4 = (condition: SpeedConsumptionFilterCondition) => {
    if (checkRequiredFilterCondition(condition)) return;
    referenceDataList.refetch(condition.imo_no);
    reports4.refetch(condition);
  };

  const onResetFilter1 = () => {
    filterForm1.update(DefaultFilterCondition);
    reports1.refetch(ResetFilterCondition);
  };

  const onResetFilter2 = () => {
    filterForm2.update(DefaultFilterCondition);
    reports2.refetch(ResetFilterCondition);
  };

  const onResetFilter3 = () => {
    filterForm3.update(DefaultFilterCondition);
    reports3.refetch(ResetFilterCondition);
  };

  const onResetFilter4 = () => {
    filterForm4.update(DefaultFilterCondition);
    reports4.refetch(ResetFilterCondition);
  };

  const onResetAllFilter = () => {
    onResetFilter1();
    onResetFilter2();
    onResetFilter3();
    onResetFilter4();
    filterAllForm.update(DefaultFilterCondition);
    referenceDataList.refetch(NonExistentImoNo.NonExistentImoNo);
  };

  useEffect(() => {
    if (!shipList.isLoading && imoNumber) {
      if (isNaN(parseInt(imoNumber))) {
        return;
      }

      const currentDate = new Date();
      const dateRanges = {
        pastQuarter: { start: -3, end: 0 },
        secondLastQuarter: { start: -6, end: -3 },
        thirdLastQuarter: { start: -9, end: -6 },
        fourthLastQuarter: { start: -12, end: -9 },
      };

      const graphConfigurations = [
        {
          onApplyFilter: onApplyFilter1,
          setIsGraphDataFetched: setIsGraph1DataFetched,
          filterForm: filterForm1,
        },
        {
          onApplyFilter: onApplyFilter2,
          setIsGraphDataFetched: setIsGraph2DataFetched,
          filterForm: filterForm2,
        },
        {
          onApplyFilter: onApplyFilter3,
          setIsGraphDataFetched: setIsGraph3DataFetched,
          filterForm: filterForm3,
        },
        {
          onApplyFilter: onApplyFilter4,
          setIsGraphDataFetched: setIsGraph4DataFetched,
          filterForm: filterForm4,
        },
      ];

      Object.values(dateRanges).forEach((range, index) => {
        const startDate = new Date(currentDate);
        startDate.setMonth(currentDate.getMonth() + range.start);
        const endDate = new Date(currentDate);
        endDate.setMonth(currentDate.getMonth() + range.end);

        const { filterForm, onApplyFilter, setIsGraphDataFetched } =
          graphConfigurations[index];
        const updateFilter = {
          ...DefaultFilterCondition,
          imo_no: imoNumber,
          from_dateTime: startDate,
          to_dateTime: endDate,
        };
        filterForm.update(updateFilter);
        onApplyFilter(updateFilter);
        setIsGraphDataFetched(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipList.isLoading]);

  return (
    <DefaultOwner title="Noon Report" selectedTitle={"Analysis"}>
      <Template
        referenceDataList={
          isAnyFilterTrue(isMeUsageFilter)
            ? []
            : referenceDataList.data.referenceDataList
        }
        graph1={reports1.data(
          isMeUsageFilter.filter1,
          isStandardDeviationFilter.filter1
        )}
        filterForm1={filterForm1.data}
        hasFilterFormError1={filterForm1.hasFilterFormError}
        onUpdateFilterForm1={onUpdateFilterForm1}
        onApplyFilter1={onApplyFilter1}
        isGraph1DataFetched={isGraph1DataFetched}
        graph2={reports2.data(
          isMeUsageFilter.filter2,
          isStandardDeviationFilter.filter2
        )}
        filterForm2={filterForm2.data}
        hasFilterFormError2={filterForm2.hasFilterFormError}
        onUpdateFilterForm2={onUpdateFilterForm2}
        onApplyFilter2={onApplyFilter2}
        isGraph2DataFetched={isGraph2DataFetched}
        graph3={reports3.data(
          isMeUsageFilter.filter3,
          isStandardDeviationFilter.filter3
        )}
        filterForm3={filterForm3.data}
        hasFilterFormError3={filterForm3.hasFilterFormError}
        onUpdateFilterForm3={onUpdateFilterForm3}
        onApplyFilter3={onApplyFilter3}
        isGraph3DataFetched={isGraph3DataFetched}
        graph4={reports4.data(
          isMeUsageFilter.filter4,
          isStandardDeviationFilter.filter4
        )}
        filterForm4={filterForm4.data}
        hasFilterFormError4={filterForm4.hasFilterFormError}
        onUpdateFilterForm4={onUpdateFilterForm4}
        onApplyFilter4={onApplyFilter4}
        isGraph4DataFetched={isGraph4DataFetched}
        {...{
          onResetFilter1,
          onResetFilter2,
          onResetFilter3,
          onResetFilter4,
          onResetAllFilter,
          setIsMeUsageFilter,
          isMeUsageFilter,

          onUpdateAllFilterForm,
          filterAllForm,
          isStandardDeviationFilter,
          setIsStandardDeviationFilter,
        }}
      />
    </DefaultOwner>
  );
};

export default Analysis;
