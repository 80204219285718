import { useMemo } from "react";
import {
  useReferenceDataListLazyQuery,
  ReferenceDataListQuery,
} from "../../../../gen/graphql/types";
import { ReferenceData } from "./types";
import { safeNumber } from "../../../../utils/formats";

const getReferenceData = (
  reports?: ReferenceDataListQuery
): ReferenceData[] => {
  const referenceDataList = (reports?.referenceDataList || [])
    .map((report): ReferenceData | null => {
      if (!report?.Speed_OG) {
        return null;
      }
      return {
        Speed_OG: Number(report.Speed_OG),
        fuel_consumption_Ballast: safeNumber(report.fuel_consumption_Ballast),
        fuel_consumption_Laden: safeNumber(report.fuel_consumption_Laden),
        fuel_consumption_Unique: safeNumber(report.fuel_consumption_Unique),
        dataType: report.dataType,
      } as ReferenceData;
    })
    .filter((v): v is NonNullable<typeof v> => v != null);

  return referenceDataList;
};

export const useReferenceData = () => {
  const [getData, { data: response, loading: isLoading }] =
    useReferenceDataListLazyQuery();

  const data = useMemo(() => {
    const referenceDataList = getReferenceData(response);

    return { referenceDataList };
  }, [response]);

  const refetch = (imo_no: string | null) => {
    if (!imo_no) return;

    getData({
      variables: {
        input: { IMO_No: imo_no },
      },
    });
  };

  return {
    isLoading,
    data,
    refetch,
  };
};
