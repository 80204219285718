import { Button, Divider, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Column from "../../../../atoms/Column";
import Columns from "../../../../atoms/Columns";
import Detail from "../../../../organisms/Crew/Departure/Detail";
import { useNavigate } from "react-router";
import { Props } from "../../../../pages/Crew/Departure/Create/types";
import SucsessButton from "../../../../atoms/SucsessButton";
import { ArrivalDexie } from "../../../../../dexie/Arrival";

type ConfirmProps = Props & {
  onCreate(): void;
  onBack(): void;
  lastReport: ArrivalDexie | undefined;
};

const Confirm = (props: ConfirmProps): React.ReactElement => {
  const navigate = useNavigate();

  const onClickSave = () => {
    props.onCreate();
  };

  const onClickBack = () => {
    props.onBack();
  };

  return (
    <React.Fragment>
      <Paper sx={{ backgroundColor: "#27292E", borderRadius: "20px" }}>
        <Detail
          isPreview={false}
          form={props.form}
          pageTitle={"Create Departure"}
          lastArrival={props.lastReport}
          isSend={false}
          reportId={0}
        />

        <Divider textAlign="left"></Divider>

        <Box sx={{ p: 2 }}>
          <Columns>
            <Column>
              <SucsessButton
                ariaLabel={"Save_Button"}
                ButtonName={"Save"}
                disabled={false}
                onClick={onClickSave}
              />
            </Column>

            <Column>
              <Button
                sx={{
                  mx: 2,
                  color: "#AAAAAA",
                  width: 150,
                  backgroundColor: "#555860",
                  textTransform: "none",
                }}
                variant="contained"
                disabled={false}
                onClick={onClickBack}
              >
                {`Back & Edit`}
              </Button>
            </Column>
            <Column>
              <Button
                sx={{
                  mx: 2,
                  width: 150,
                  color: "#AAAAAA",
                  backgroundColor: "#555860 ",
                  textTransform: "none",
                }}
                variant="contained"
                disabled={false}
                onClick={() => navigate("/")}
              >
                Cancel
              </Button>
            </Column>
          </Columns>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default Confirm;
