import { ReportDexie } from "../../../../dexie/Report";
import {
  CreateReportListResponse,
  QueryStatus,
  ReportInput,
  useCreateReportListMutation,
} from "../../../../gen/graphql/types";
import { nonNullable } from "../../../../utils/formats";
import { uploadFiles } from "../../../../utils/uploadFile.module";
import { updateFrontReport } from "./SendDataUtil";
import { ErrorMessageData } from "../../../../hooks/errorMessage.hook";
import {
  CreateReportListCountZeroError,
  CreateReportListEmptyResponseError,
  NoonReportDuplicateError,
} from "../../../../utils/error";
import { checkQueryStatus } from "../../pages.module";

type Props = {
  notDuplicatedNoonReportList: ReportDexie[];
  setCheckedReport: (value: React.SetStateAction<ReportDexie[]>) => void;
  setCheckedBoxReport: (value: React.SetStateAction<number[]>) => void;
  createErrorData: (
    dataType: string,
    dataArr: unknown[]
  ) => {
    loading: boolean;
  };
  addErrorMessage: (newErrorMessage: ErrorMessageData) => void;
};

export const useCreateReportListMutationHook = () => {
  const [createReport, { loading: isReportLoading }] =
    useCreateReportListMutation();

  const createReportMutation = (props: Props) => {
    const reportVariables: ReportInput[] = props.notDuplicatedNoonReportList
      .map((report) => report.data)
      .filter(nonNullable);

    createReport({
      variables: {
        input: {
          ReportListInput: reportVariables,
        },
      },
      onCompleted: async (data) => {
        try {
          handleCreateReportListResponseStatus(data.createReportList);
          const createReportList = data.createReportList;

          const failedGetUploadUrlDbFileList: string[] = [];
          const upLoadFilePath = createReportList.uploadFilePaths
            .filter((v): v is NonNullable<typeof v> => v !== null)
            .map((v) => {
              if (v.gcsFilePath === "") {
                failedGetUploadUrlDbFileList.push(v.dbFilePath);
                return;
              }

              const fileData = props.notDuplicatedNoonReportList
                .filter((v): v is NonNullable<typeof v> => v !== undefined)
                .find((local) => local.data?.N_ROB_Filename === v.dbFilePath);

              if (!fileData || !fileData.robFile) return;
              return {
                localFilePath: v.dbFilePath,
                remoteFilePath: v.gcsFilePath,
                fileContent: fileData.robFile,
              };
            })
            .filter((v): v is NonNullable<typeof v> => v !== undefined);

          const failedUploadFileList = (
            await uploadFiles(upLoadFilePath)
          ).filter((v) => !v.sucsess);

          if (
            failedUploadFileList.length > 0 ||
            failedGetUploadUrlDbFileList.length > 0
          ) {
            props.addErrorMessage({
              input: JSON.stringify(reportVariables),
              errorMessage:
                `Failed to upload the following noon files. ${failedUploadFileList
                  .map((v) => v.filename)
                  .concat(failedGetUploadUrlDbFileList)
                  .join(",")}` +
                failedUploadFileList.map((v) => v.errorMessage).join("\n"),
            });
          }

          if (
            props.notDuplicatedNoonReportList.length ===
            createReportList.createdReportCount
          ) {
            props.notDuplicatedNoonReportList.forEach((report) => {
              updateFrontReport(report);
            });
          }
          props.setCheckedReport([]);
          props.setCheckedBoxReport([]);
        } catch (e) {
          console.error(e);
          props.createErrorData("Report", reportVariables);
          props.addErrorMessage({
            input: JSON.stringify(reportVariables),
            errorMessage: `Server Error! createReportList onCompleted:${e}`,
          });
        }
      },
      onError: (error) => {
        console.error(error);
        //エラーinputデータ送信
        props.createErrorData("Report", reportVariables);
        props.addErrorMessage({
          input: JSON.stringify(reportVariables),
          errorMessage: `Server Error: Unable to create the report list due to an unexpected issue. detail : ${error}`,
        });
      },
    });
  };
  return [{ createReportMutation, isReportLoading }];
};

function handleCreateReportListResponseStatus(
  createReportList: CreateReportListResponse
) {
  checkQueryStatus(createReportList.CreateReportListStatus);

  if (!createReportList) {
    throw CreateReportListEmptyResponseError;
  }

  if (!createReportList.createdReportCount) {
    throw CreateReportListCountZeroError;
  }

  if (createReportList.CreateReportListStatus === QueryStatus.DuplicateError) {
    throw NoonReportDuplicateError;
  }
}
