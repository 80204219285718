import * as React from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import FormBlock from "../../../organisms/Crew/Departure/FormBlock";
import { Form, Props } from "../../../pages/Crew/Departure/Create/types";
import { ShipDexie } from "../../../../dexie/Ship";
import { ArrivalDexie } from "../../../../dexie/Arrival";
import { pageData } from "../../../../App";

type ConfirmProps = Props & {
  hasFormError: boolean;
  onUpdateForm(value: Form): void;
  onConfirm(): void;
  onSaveDraft(value: Form): void;
  ship: ShipDexie | undefined;
  lastArrival: ArrivalDexie | undefined;
  isConfirm: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
};

const Create = (props: ConfirmProps): React.ReactElement => {
  const navigate = useNavigate();
  const onUpdateForm = (value: Form) => {
    props.onUpdateForm(value);
  };

  const onClickConfirm = () => {
    props.onConfirm();
  };

  const onClickSaveDraft = () => {
    props.onSaveDraft(props.form);
  };

  return (
    <React.Fragment>
      <Paper sx={{ backgroundColor: "#27292E", borderRadius: "20px" }}>
        <FormBlock
          title="Create Departure Report"
          isPreview={false}
          onUpdateForm={onUpdateForm}
          form={props.form}
          ship={props.ship}
          lastArrival={props.lastArrival}
          isConfirm={props.isConfirm}
          setIsConfirm={props.setIsConfirm}
        />

        <Box
          sx={{
            textAlign: "left",
            px: 2,
            py: 4,
          }}
        >
          <Button
            sx={{
              mx: 2,
              width: "250",
              backgroundColor: "#20BE89  !important",
              fontFamily: "Public Sans",
              "&.MuiButton-root": {
                fontFamily: "Public Sans",
              },
              textTransform: "none",
            }}
            aria-label="Confirm_Button"
            variant="contained"
            onClick={onClickConfirm}
          >
            Confirm
          </Button>
          <Button
            sx={{
              mx: 2,
              backgroundColor: "#555860",
              color: "#20BE89",
              textTransform: "none",
            }}
            variant="contained"
            onClick={onClickSaveDraft}
          >
            Save Draft
          </Button>
          <Button
            sx={{ mx: 2, backgroundColor: "#555860 ", textTransform: "none" }}
            variant="contained"
            onClick={() => navigate(pageData.crewDeparture.path())}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default Create;
