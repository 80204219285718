import React, { useState } from "react";
import Box from "@mui/material/Box";
import Columns from "../../../atoms/Columns";
import Column from "../../../atoms/Column";
import { Form, Props } from "../../../pages/Crew/NoonReport/Create/types";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CommonTextFIeld from "../../../atoms/CommonTextFIeld";
import CommonNumberFIeld from "../../../atoms/CommonNumberFIeld";
import { ShipDexie } from "../../../../dexie/Ship";
import {
  LorB,
  SteamMode,
  MePowerMeasurementMethod,
  EUorUk,
  LorU,
} from "../../../../gen/graphql/types";
import CommonNumberFIeldStandard from "../../../atoms/CommonNumberFIeldStandard";
import Optional from "../../../atoms/Optional";
import Steppr from "../../../atoms/Stepper";
import RadioButtun from "../../../atoms/RadioButton";
import { ReportDexie } from "../../../../dexie/Report";
import LastReportText from "../../../atoms/LastReportText";
import CustomTooltip from "../../../atoms/CustomTooltip";
import UtcDateTimePicker from "../../../atoms/UtcDateTimePicker";
import {
  convertEmptyStringToNull,
  formatToUtcYYYYMMDDHHMM,
  nonNullable,
} from "../../../../utils/formats";
import FormBlock_Spare from "./FormBlock_Spare";
import {
  convertWindDirectionRangeToDegree,
  getWindDirectionArray,
} from "../../../../utils/convert-wind-direction-degree";
import { LatLongField } from "../../../molecules/Crew/LatLongField";
import {
  convertToNumberOrNull,
  isNumeric,
} from "../../../../utils/type-checks";
import CommonSelect from "../../../atoms/CommonSelect";

import { FoConsumption } from "./Consumption/FoConsumption";
import { MgoConsumption } from "./Consumption/MgoConsumption";
import { LoConsumption } from "./Consumption/LoConsumption";
import { OtherConsumption } from "./Consumption//OtherConsumption";

import RobAdditional from "./ROB/RobAdditional";

import RobFileUpload from "./ROB/RobFileUpload";
import { BF_SCALE } from "../../../../utils/const";
import {
  N_Dist_OG_MAX_VALUE,
  N_Speed_OG_MAX_VALUE,
} from "../../../pages/Crew/NoonReport/const";
import { calculateNoonValues } from "./calculateFunctions/calculateNoonValues";

export type NestedFormBlockProps = FormBlockProps & {
  onChange: (
    key: string,
    value: string | number | Date | null | undefined
  ) => void;
};

export type FormBlockProps = Props & {
  title: string;
  isPreview: boolean;
  onUpdateForm: (value: Form) => void;
  ship?: ShipDexie;
  defaultTimeZone?: string;
  defaultLatLong?: string;
  lastReport?: ReportDexie;
  isConfirm: boolean;
  isServer?: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
};

const convertMePowerMeasurementMethodEnum = (
  value: MePowerMeasurementMethod
): string => {
  switch (value) {
    case MePowerMeasurementMethod.ShaftPowerMeter:
      return "Shaft power meter";
    case MePowerMeasurementMethod.PressureMeasurementInstrument:
      return "Pressure measurement instrument";
    case MePowerMeasurementMethod.FuelIndex:
      return "Fuel index";
  }
};

const FormBlock = (props: FormBlockProps): React.ReactElement => {
  const [previousSteamMode, setPreviousSteamMode] = useState<string | null>(
    props.form.N_Steam_mode
  );

  const onChange = (
    key: string,
    value: string | number | Date | null | undefined
  ) => {
    if (typeof value === "string") {
      value = convertEmptyStringToNull(value);
    }

    const form = {
      ...props.form,
      [key]: value,
    };

    props.onUpdateForm?.(form);
  };

  const onSteamModeChange = (value: string | null) => {
    props.form.N_Steam_mode = value;

    props.onUpdateForm?.({ ...props.form });
    setPreviousSteamMode(value);
  };

  calculateNoonValues(props);

  const lastReport = props.lastReport?.data;

  return (
    <Box component="form" noValidate autoComplete="off">
      <Box sx={{ pt: 0.5, px: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "top",
          }}
        >
          <Column>
            <Typography
              component="h3"
              variant="subtitle1"
              align="center"
              sx={{
                lineHeight: 1,
                fontWeight: "bold",
                color: "#fff",
                fontSize: "1.5rem",
                verticalAlign: "super",
              }}
            >
              {props.title}
            </Typography>
            <Typography
              sx={{
                pt: 1,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "12px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#ffffff",
              }}
            >
              *(Prev. Data)
            </Typography>
          </Column>
          <Column sx={{ width: "60%" }}>
            <Steppr step={0} />
          </Column>
        </Columns>
      </Box>
      <Box sx={{ px: 2 }}>
        <Columns
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Column
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CommonTextFIeld
              disabled={props.isPreview}
              isConfirm={props.isConfirm}
              id="N_Voy"
              label="Voyage number"
              placeholder=""
              value={props.form.N_Voy}
              onChange={(e: string | null) => {
                onChange("N_Voy", e);
              }}
            />
            <LastReportText lastData={String(lastReport?.N_Voy)} />
          </Column>
          <Column
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                "& .Mui-focused": {
                  color: "#20BE89",
                },
                "& .MuiFormLabel-root": {
                  color: "#AAAAAA",
                  border: `1px transparent`,
                },
                "& .MuiFormLabel-filled": {
                  color: "#AAAAAA",
                },
              }}
              size="small"
            >
              <InputLabel id="N_LorB" sx={{ color: "#AAAAAA" }}>
                L or B
              </InputLabel>
              <Select
                labelId="N_LorB"
                id="N_LorB"
                label="Loading condition"
                value={props.form.N_LorB}
                onChange={(e: SelectChangeEvent<string | null>) => {
                  onChange("N_LorB", e.target.value);
                }}
                sx={{
                  width: "18ch",
                  "& .MuiSelect-outlined": {
                    border: `1px solid #464E5A !important`,
                  },
                  "& .MuiSelect-select": {
                    color: "#FFFFFF",
                    border: `1px solid #464E5A`,
                  },
                  "& .MuiSelect-select:hover": {
                    color: "#FFFFFF",
                    border: `1px solid #AAAAAA !important`,
                  },
                  "& .MuiSelect-icon": {
                    color: "#FFFFFF",
                  },
                  "&:hover fieldset": {
                    border: "#FFFFFF",
                  },
                  textAlign: "left",
                }}
              >
                {Object.values(LorB).map((v, index) => {
                  return (
                    <MenuItem value={v} key={index}>
                      {v}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <LastReportText lastData={String(lastReport?.N_LorB)} />
          </Column>

          <Column>
            <UtcDateTimePicker
              id={"N_DateTime"}
              label={"Date & Time (Local)"}
              value={props.form.N_DateTime}
              onChange={onChange}
              onChangeKey={"N_DateTime"}
              hasMinDate={true}
            />
          </Column>
        </Columns>
        <Columns
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LatLongField
            form={props.form}
            defaultLatLong={props.defaultLatLong}
            lastReport={props.lastReport}
            convertToNumberOrNull={convertToNumberOrNull}
            isConfirm={props.isConfirm}
            isPreview={props.isPreview}
            onChange={onChange}
            onUpdateForm={props.onUpdateForm}
          />
          <Column sx={{ mt: -1 }}>
            <Typography
              sx={{
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#AAAAAA",
                pb: 0.5,
              }}
            >
              Time Zone (UTC±)
            </Typography>
            <RadioButtun
              id="N_TimeZone_PorN"
              value={props.form.N_TimeZone_PorN}
              defaultValue={props.defaultTimeZone?.slice(0, 1) || "+"}
              label=""
              label1="＋"
              label2="ー"
              value1="+"
              value2="-"
              onChange={onChange}
            />
          </Column>
          <Column sx={{ pl: -2 }}>
            <Typography
              sx={{
                pb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#AAAAAA",
              }}
            ></Typography>
            <Select
              size="small"
              labelId="N_TimeZone_HH"
              id="N_TimeZone_HH"
              label="Hour."
              placeholder="Hour."
              value={props.form.N_TimeZone_HH || "00"}
              onChange={(e: SelectChangeEvent<string | null>) => {
                onChange("N_TimeZone_HH", e.target.value);
              }}
              inputProps={{
                "aria-label": `N_TimeZone_HH`,
              }}
              sx={{
                width: "70px",
                "& .MuiSelect-outlined": {
                  color: "#FFFFFF",
                },
                "& .MuiSelect-select": {
                  color: "#FFFFFF",
                  border: `1px solid #464E5A`,
                },
                "& .MuiSelect-select:hover": {
                  color: "#FFFFFF",
                  border: `1px solid #AAAAAA !important`,
                },

                "& .MuiSelect-icon": {
                  color: "transparent",
                },
                "&:hover fieldset": {
                  border: "#464E5A",
                },
                textAlign: "left",
              }}
            >
              {[...Array(15).keys()]
                .map((v) => ("00" + String(v)).slice(-2))
                .map((v, index) => {
                  return (
                    <MenuItem value={v} key={index}>
                      {v}
                    </MenuItem>
                  );
                })}
            </Select>
          </Column>
          <Column sx={{ pl: -15 }}>
            <Typography sx={{ color: "#AAAAAA", pt: 2 }}>：</Typography>
          </Column>
          <Column sx={{ pl: -15 }}>
            <Typography
              sx={{
                pb: 2,
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "16px",
                fontFamily: "Public Sans",
                lineHeight: "auto",
                color: "#AAAAAA",
              }}
            ></Typography>
            <Select
              size="small"
              labelId="N_TimeZone_MM"
              id="N_TimeZone_MM"
              label="Min."
              placeholder="Min."
              value={props.form.N_TimeZone_MM || "00"}
              onChange={(e: SelectChangeEvent<string | null>) => {
                onChange("N_TimeZone_MM", e.target.value);
              }}
              inputProps={{
                "aria-label": `N_TimeZone_MM`,
              }}
              sx={{
                width: "70px",
                "& .MuiSelect-outlined": {
                  color: "#FFFFFF",
                },
                "& .MuiSelect-select": {
                  color: "#FFFFFF",
                  border: `1px solid #464E5A`,
                },
                "& .MuiSelect-select:hover": {
                  color: "#FFFFFF",
                  border: `1px solid #AAAAAA !important`,
                },

                "& .MuiSelect-icon": {
                  color: "transparent",
                },
                "&:hover fieldset": {
                  border: "#464E5A",
                },
                textAlign: "left",
              }}
            >
              {["00", "30", "45"].map((v, index) => {
                return (
                  <MenuItem value={v} key={index}>
                    {v}
                  </MenuItem>
                );
              })}
            </Select>
          </Column>
        </Columns>
        <Stack
          direction="row"
          spacing={2}
          alignItems="end"
          justifyContent={"left"}
        >
          <Column sx={{ pt: 0, pl: 0 }}>
            <Optional />
            <CommonTextFIeld
              disabled={props.isPreview}
              id="N_Noon_Port"
              label="Port Name"
              placeholder=""
              value={props.form.N_Noon_Port}
              onChange={(e) => {
                onChange("N_Noon_Port", e);
              }}
            />
          </Column>
          <Column sx={{ pt: 0, pl: 0 }}>
            <Optional />
            <CommonTextFIeld
              disabled={props.isPreview}
              id="N_UNLOC"
              label="UNLOC"
              placeholder=""
              value={props.form.N_UNLOC}
              onChange={(e) => {
                onChange("N_UNLOC", e);
              }}
            />
          </Column>
          <CommonSelect
            id="N_EU_UK_port"
            label="EU/UK"
            value={props.form.N_EU_UK_port || ""}
            onChange={(e) => {
              onChange("N_EU_UK_port", e);
            }}
            selectValues={Object.values(EUorUk)}
            isOptional={true}
          />
          <CommonSelect
            id="N_Port_L_U"
            label="L or U"
            value={props.form.N_Port_L_U}
            onChange={(e) => {
              onChange("N_Port_L_U", e);
            }}
            selectValues={Object.values(LorU)}
          />
        </Stack>
        <Box>
          <Columns>
            <Column
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                sx={{
                  minWidth: 120,
                  "& .MuiInputBase-input": {
                    color: "#FFFFFF",
                    borderColor: "#464E5A",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#AAAAAA",
                    border: `1px transparent`,
                    "&:hover fieldset": {
                      color: "#20BE89",
                    },
                  },
                }}
                size="small"
              >
                <InputLabel id="N_Steam_mode" sx={{ color: "#AAAAAA" }}>
                  Steam mode
                </InputLabel>
                <Select
                  labelId="N_Steam_mode"
                  id="N_Steam_mode"
                  label="Steam_mode"
                  value={props.form.N_Steam_mode}
                  onChange={(e: SelectChangeEvent<string | null>) => {
                    onSteamModeChange(e.target.value);
                  }}
                  inputProps={{
                    "aria-label": `N_Steam_mode`,
                  }}
                  sx={{
                    width: "18ch",
                    "& .MuiSelect-outlined": {
                      border: `1px solid #464E5A !important`,
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                      border: `1px solid #464E5A`,
                    },
                    "& .MuiSelect-select:hover": {
                      color: "#FFFFFF",
                      border: `1px solid #AAAAAA !important`,
                    },
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "&:hover fieldset": {
                      border: "#FFFFFF",
                    },
                    textAlign: "left",
                  }}
                >
                  {Object.values(SteamMode).map((v, index) => {
                    return (
                      <MenuItem value={v} key={index}>
                        {v}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_Hrs_propelling"
                label="Hrs. Propelling"
                placeholder=""
                value={props.form.N_Hrs_propelling}
                unitName={"hour"}
                minValue={0}
                maxValue={25}
                onChange={(e: string | null) => {
                  onChange("N_Hrs_propelling", e);
                }}
                sx={{ width: "220px" }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_Dist_OG"
                label="Distance travelled over ground (nautical mile)"
                placeholder=""
                value={props.form.N_Dist_OG}
                unitName={"nautical mile"}
                minValue={0}
                maxValue={N_Dist_OG_MAX_VALUE}
                sx={{ width: "550px" }}
                onChange={(e: string | null) => {
                  onChange("N_Dist_OG", e);
                }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_Speed_OG"
                label="Speed over the ground (avg. knot)"
                placeholder=""
                value={props.form.N_Speed_OG}
                unitName={"knot"}
                readonly={true}
                minValue={0}
                maxValue={N_Speed_OG_MAX_VALUE}
                onChange={(e: string | null) => {
                  onChange("N_Speed_OG", e);
                }}
                sx={{ width: "370px" }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_Speed_OG)}
              unitName={"knot"}
            />
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_Speed_OW"
                label="Log speed (avg. knot)"
                placeholder=""
                value={props.form.N_Speed_OW}
                unitName={"knot"}
                minValue={0}
                maxValue={55}
                onChange={(e: string | null) => {
                  onChange("N_Speed_OW", e);
                }}
                sx={{ width: "270px" }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_Speed_OW)}
              unitName={"knot"}
            />
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column sx={{ pt: 0 }}>
              <Optional />
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                id="N_ME_rpm_total"
                label="M/E Revolution - total"
                placeholder=""
                value={props.form.N_ME_rpm_total}
                minValue={props.lastReport?.draftForm?.N_ME_rpm_total ?? 100}
                onChange={(e: string | null) => {
                  const N_ME_rpm_total = convertToNumberOrNull(e);

                  let N_ME_Revolution = props.form.N_ME_Revolution;

                  if (
                    !props.isServer
                  ) {
                    if (
                      N_ME_rpm_total !== null &&
                      props.lastReport?.draftForm?.N_ME_rpm_total
                    ) {
                      N_ME_Revolution =
                        N_ME_rpm_total -
                        props.lastReport.draftForm.N_ME_rpm_total;
                    } else if (N_ME_rpm_total === null) {
                      N_ME_Revolution = null;
                    }
                  }

                  props.onUpdateForm?.({
                    ...props.form,
                    N_ME_rpm_total,
                    N_ME_Revolution,
                  });
                }}
                sx={{ width: "320px" }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(props.lastReport?.draftForm?.N_ME_rpm_total)}
            />

            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={
                  props.isPreview ||
                  (props.lastReport?.draftForm?.N_ME_rpm_total ? true : false)
                }
                id="N_ME_Revolution"
                label="M/E Revolution - Noon to Noon"
                placeholder=""
                value={props.form.N_ME_Revolution}
                minValue={props.form.N_Steam_mode === SteamMode.Stop ? 0 : 100}
                maxValue={999999}
                onChange={(e: string | null) => {
                  onChange("N_ME_Revolution", e);
                }}
                sx={{ width: "320px" }}
              />
              <CustomTooltip
                text={
                  "Difference figure of M/E Revolution from last to this date."
                }
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={true}
                id="N_ME_rpm_ave"
                label="M/E rpm - avg."
                placeholder=""
                value={props.form.N_ME_rpm_ave}
                unitName={"rpm"}
                minValue={0}
                maxValue={999}
                onChange={(e: string | null) => {
                  onChange("N_ME_rpm_ave", e);
                }}
                sx={{ width: "220px" }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_ME_rpm_ave)}
              unitName={"rpm"}
            />
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                readonly={true}
                id="N_Slip"
                label="Slip ratio (%)"
                placeholder=""
                value={props.form.N_Slip}
                unitName={"%"}
                onChange={(e: string | null) => {
                  onChange("N_Slip", e);
                }}
              />
            </Column>
          </Columns>
          <FoConsumption onChange={onChange} {...props} />
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column
              sx={{
                width: "270px",
                textAlign: "left",
              }}
            >
              <Typography sx={{ color: "#AAAAAA", pl: 2, pr: 2 }}>
                FO_CO2_emission
              </Typography>{" "}
            </Column>
            <Column>
              <CommonNumberFIeldStandard
                id="N_FO_CO2_emission_per_unit"
                label=""
                placeholder=""
                value={props.form.N_FO_CO2_emission_per_unit || 0}
                minValue={0}
                unitName="- g/ton-nautical miles"
                onChange={(e: string | null) => {
                  onChange("N_FO_CO2_emission_per_unit", e);
                }}
              />
            </Column>
            <LastReportText
              lastData={String(lastReport?.N_FO_CO2_emission_per_unit)}
            />
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column
              sx={{
                width: "270px",
                textAlign: "left",
              }}
            >
              <Typography sx={{ color: "#AAAAAA", pl: 2, pr: 2 }}>
                FO_CO2_emission
              </Typography>{" "}
            </Column>
            <Column>
              <CommonNumberFIeldStandard
                id="N_FO_CO2_emission"
                label=""
                placeholder=""
                value={props.form.N_FO_CO2_emission || 0}
                minValue={0}
                unitName="- mt"
                onChange={(e: string | null) => {
                  onChange("N_FO_CO2_emission", e);
                }}
              />
            </Column>
            <LastReportText lastData={String(lastReport?.N_FO_CO2_emission)} />
          </Columns>
          <MgoConsumption onChange={onChange} {...props} />
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column
              sx={{
                width: "270px",
                textAlign: "left",
              }}
            >
              <Typography sx={{ color: "#AAAAAA", pl: 2 }}>
                MGO_CO2_emission
              </Typography>{" "}
            </Column>
            <Column>
              <CommonNumberFIeldStandard
                id="N_MGO_CO2_emission_per_unit"
                label=""
                placeholder=""
                value={props.form.N_MGO_CO2_emission_per_unit || 0}
                minValue={0}
                unitName="- g/ton-nautical miles"
                onChange={(e: string | null) => {
                  onChange("N_MGO_CO2_emission_per_unit", e);
                }}
              />
            </Column>
            <LastReportText
              lastData={String(lastReport?.N_MGO_CO2_emission_per_unit)}
            />
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column
              sx={{
                width: "270px",
                textAlign: "left",
              }}
            >
              <Typography sx={{ color: "#AAAAAA", pl: 2 }}>
                MGO_CO2_emission
              </Typography>{" "}
            </Column>
            <Column>
              <CommonNumberFIeldStandard
                id="N_MGO_CO2_emission"
                label=""
                placeholder=""
                value={props.form.N_MGO_CO2_emission || 0}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("N_MGO_CO2_emission", e);
                }}
                unitName="- mt"
              />
            </Column>
            <LastReportText lastData={String(lastReport?.N_MGO_CO2_emission)} />
          </Columns>
          <LoConsumption onChange={onChange} {...props} />
          {props.ship?.is_mrv_portal_integration_target && (
            <OtherConsumption onChange={onChange} {...props} />
          )}
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <Optional />
              <CommonTextFIeld
                disabled={props.isPreview}
                id="N_Weather"
                label="Weather"
                placeholder=""
                value={props.form.N_Weather}
                onChange={(e: string | null) => {
                  onChange("N_Weather", e);
                }}
              />
            </Column>
            <CustomTooltip text={"Weather information code"} sx={{ mt: 4 }} />
            <Column>
              <Optional />
              <FormControl
                sx={{
                  minWidth: 120,
                  "& .MuiInputBase-input": {
                    color: "#FFFFFF",
                    borderColor: "#464E5A",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#AAAAAA",
                    border: `1px transparent`,
                    "&:hover fieldset": {
                      color: "#20BE89",
                    },
                  },
                }}
                size="small"
              >
                <InputLabel id="N_Wind_direction" sx={{ color: "#AAAAAA" }}>
                  Wind Direction
                </InputLabel>
                <Select
                  data-testid={"N_Wind_direction"}
                  labelId="N_Wind_direction"
                  id="N_Wind_direction"
                  label="Wind Direction"
                  value={convertWindDirectionRangeToDegree(
                    props.form.N_Wind_direction
                  )}
                  onChange={(e: SelectChangeEvent<string | null>) => {
                    onChange("N_Wind_direction", e.target.value);
                  }}
                  inputProps={{
                    "aria-label": `N_Wind_direction`,
                  }}
                  sx={{
                    width: "40ch",
                    "& .MuiSelect-outlined": {
                      border: `1px solid #464E5A !important`,
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                      border: `1px solid #464E5A`,
                    },
                    "& .MuiSelect-select:hover": {
                      color: "#FFFFFF",
                      border: `1px solid #AAAAAA !important`,
                    },
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "&:hover fieldset": {
                      border: "#FFFFFF",
                    },
                    textAlign: "left",
                  }}
                >
                  {getWindDirectionArray().map((obj, index) => {
                    return (
                      <MenuItem value={obj.degree} key={index}>
                        {obj.direction}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Column>
            <Column>
              <CommonSelect
                isOptional
                id="N_BF_scale"
                label="BF Scale"
                value={
                  nonNullable(props.form.N_BF_scale)
                    ? String(props.form.N_BF_scale)
                    : ""
                }
                onChange={(e) => {
                  onChange("N_BF_scale", e);
                }}
                selectValues={BF_SCALE}
              />
            </Column>
            <Column>
              <Optional />
              <CommonNumberFIeld
                disabled={props.isPreview}
                id="N_Swell"
                label="Swell"
                placeholder=""
                value={props.form.N_Swell}
                unitName={"m"}
                minValue={0}
                maxValue={30}
                onChange={(e: string | null) => {
                  onChange("N_Swell", e);
                }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <Optional />
              <CommonNumberFIeld
                disabled={props.isPreview}
                id="N_Noon_Course"
                label="Noon Course"
                placeholder=""
                value={props.form.N_Noon_Course}
                unitName={"degree"}
                maxValue={359}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("N_Noon_Course", e);
                }}
                sx={{ width: "225px" }}
              />
            </Column>
            <Column>
              <Optional />
              <CommonNumberFIeld
                disabled={props.isPreview}
                id="N_Dist_to_go"
                label="Dist to go"
                placeholder=""
                value={props.form.N_Dist_to_go}
                unitName={"nautical mile"}
                minValue={0}
                maxValue={25000}
                onChange={(e: string | null) => {
                  onChange("N_Dist_to_go", e);
                }}
                sx={{ width: "250px" }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 3.5 }}
              lastData={String(lastReport?.N_Dist_to_go)}
              unitName={"nautical mile"}
            />
            <Column>
              <Optional />
              <CommonTextFIeld
                disabled={props.isPreview}
                id="N_Next_port"
                label="Next port"
                placeholder=""
                value={props.form.N_Next_port}
                onChange={(e: string | null) => {
                  onChange("N_Next_port", e);
                }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 3.5 }}
              lastData={String(lastReport?.N_Next_port)}
            />
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <Optional />
              <UtcDateTimePicker
                id={"N_ETA"}
                label={"ETA"}
                value={props.form.N_ETA}
                onChange={onChange}
                onChangeKey={"N_ETA"}
                hasMinDate={true}
              />
            </Column>
            <LastReportText
              sx={{ pt: 3.5 }}
              lastData={formatToUtcYYYYMMDDHHMM(lastReport?.N_ETA)}
            />
            <CustomTooltip
              text={"Estimated Time of Arrival"}
              sx={{ mt: 4, ml: -1.5 }}
            />
            <Column>
              <Optional />
              <UtcDateTimePicker
                id={"N_ETB"}
                label={"ETB"}
                value={props.form.N_ETB || null}
                onChange={onChange}
                onChangeKey={"N_ETB"}
                hasMinDate={true}
              />
            </Column>
            <LastReportText
              sx={{ pt: 3.5 }}
              lastData={formatToUtcYYYYMMDDHHMM(lastReport?.N_ETB)}
            />
            <CustomTooltip
              text={"Estimated Time of Berthing"}
              sx={{ mt: 4, ml: -1.5 }}
            />
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <Optional />
              <UtcDateTimePicker
                id={"N_ETD"}
                label={"ETD"}
                value={props.form.N_ETD}
                onChange={onChange}
                onChangeKey={"N_ETD"}
                hasMinDate={true}
              />
            </Column>
            <LastReportText
              sx={{ pt: 3.5 }}
              lastData={formatToUtcYYYYMMDDHHMM(lastReport?.N_ETD)}
            />
            <CustomTooltip
              text={"Estimated Time of Departure"}
              sx={{ mt: 4, ml: -1.5 }}
            />
          </Columns>
          <Columns
            sx={{
              pt: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_ME_Fuel_index"
                label="M/E Fuel index"
                placeholder=""
                value={props.form.N_ME_Fuel_index}
                unitName={"%"}
                maxValue={120}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("N_ME_Fuel_index", e);
                }}
                sx={{ width: "200px" }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_ME_RPM"
                label="M/E RPM"
                placeholder=""
                value={props.form.N_ME_RPM}
                unitName={"rpm"}
                minValue={0}
                maxValue={999}
                onChange={(e: string | null) => {
                  onChange("N_ME_RPM", e);
                }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              pt: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_ME_horsepower"
                label="M/E horsepower"
                placeholder=""
                value={props.form.N_ME_horsepower}
                unitName={"kw"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("N_ME_horsepower", e);
                }}
                sx={{ width: "225px" }}
              />
            </Column>
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_ME_power"
                label="M/E power"
                placeholder=""
                value={props.form.N_ME_power}
                unitName={"%"}
                minValue={0}
                onChange={(e: string | null) => {
                  onChange("N_ME_power", e);
                }}
                sx={{ width: "200px" }}
              />
            </Column>
            <Column>
              <FormControl
                sx={{
                  minWidth: 120,
                  "& .MuiInputBase-input": {
                    color: "#FFFFFF",
                    borderColor: "#464E5A",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#AAAAAA",
                    border: `1px transparent`,
                    "&:hover fieldset": {
                      color: "#20BE89",
                    },
                  },
                }}
                size="small"
              >
                <InputLabel
                  id="N_ME_power_measurement_method"
                  sx={{ color: "#AAAAAA" }}
                >
                  M/E power measurement method
                </InputLabel>
                <Select
                  labelId="N_ME_power_measurement_method"
                  id="N_ME_power_measurement_method"
                  label="M/E power measurement method"
                  value={props.form.N_ME_power_measurement_method}
                  onChange={(e: SelectChangeEvent<string | null>) => {
                    onChange("N_ME_power_measurement_method", e.target.value);
                  }}
                  inputProps={{
                    "aria-label": `N_ME_power_measurement_method`,
                  }}
                  sx={{
                    width: "40ch",
                    "& .MuiSelect-outlined": {
                      border: `1px solid #464E5A !important`,
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                      border: `1px solid #464E5A`,
                    },
                    "& .MuiSelect-select:hover": {
                      color: "#FFFFFF",
                      border: `1px solid #AAAAAA !important`,
                    },
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "&:hover fieldset": {
                      border: "#FFFFFF",
                    },
                    textAlign: "left",
                  }}
                >
                  {Object.values(MePowerMeasurementMethod).map((v, index) => {
                    return (
                      <MenuItem value={v} key={index}>
                        {convertMePowerMeasurementMethodEnum(v)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column>
              <Typography
                sx={{
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: "14px",
                  fontFamily: "Public Sans",
                  lineHeight: "auto",
                  color: "#AAAAAA",
                  pl: 1,
                  mb: -2,
                }}
              >
                Running_hours
              </Typography>
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_Running_hours_SOx_scrubber"
                label="SOx scrubber"
                placeholder=""
                value={props.form.N_Running_hours_SOx_scrubber}
                unitName={"hour"}
                minValue={0}
                maxValue={25}
                onChange={(e: string | null) => {
                  onChange("N_Running_hours_SOx_scrubber", e);
                }}
                sx={{ width: "210px" }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_Running_hours_SOx_scrubber)}
              unitName={"h"}
            />
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_Running_hours_ME_EGR"
                label="M/E EGR"
                placeholder=""
                value={props.form.N_Running_hours_ME_EGR}
                unitName={"hour"}
                minValue={0}
                maxValue={25}
                onChange={(e: string | null) => {
                  onChange("N_Running_hours_ME_EGR", e);
                }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_Running_hours_ME_EGR)}
              unitName={"h"}
            />
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_Running_hours_GE_SCR"
                label="G/E SCR"
                placeholder=""
                value={props.form.N_Running_hours_GE_SCR}
                unitName={"hour"}
                minValue={0}
                maxValue={25}
                onChange={(e: string | null) => {
                  onChange("N_Running_hours_GE_SCR", e);
                }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_Running_hours_GE_SCR)}
              unitName={"h"}
            />
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Column sx={{ mb: -3 }}>
              <Typography
                sx={{
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: "14px",
                  fontFamily: "Public Sans",
                  lineHeight: "auto",
                  color: "#AAAAAA",
                  pl: 1,
                  mb: -2,
                }}
              >
                ROB
              </Typography>
              <CustomTooltip
                text={"Retention of Oil on Board"}
                sx={{ mt: -2, ml: 5.5, mb: 0 }}
              />
            </Column>
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_ROB_FO"
                label="FO (mt)"
                placeholder=""
                value={props.form.N_ROB_FO}
                unitName={"mt"}
                minValue={-9999}
                maxValue={9999}
                onChange={(e: string | null) => {
                  onChange("N_ROB_FO", e);
                }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_ROB_FO)}
              unitName={"mt"}
            />
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_ROB_MGO"
                label="MGO (mt)"
                placeholder=""
                value={props.form.N_ROB_MGO}
                unitName={"mt"}
                minValue={-9999}
                maxValue={9999}
                onChange={(e: string | null) => {
                  onChange("N_ROB_MGO", e);
                }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_ROB_MGO)}
              unitName={"mt"}
            />
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_ROB_MECO"
                label="MECO (L)"
                placeholder=""
                value={props.form.N_ROB_MECO}
                unitName={"L"}
                minValue={-999999}
                maxValue={999999}
                onChange={(e: string | null) => {
                  onChange("N_ROB_MECO", e);
                }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_ROB_MECO)}
              unitName={"L"}
            />
          </Columns>
          <Columns
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_ROB_MESO"
                label="MESO (L)"
                placeholder=""
                value={props.form.N_ROB_MESO}
                unitName={"L"}
                minValue={-999999}
                maxValue={999999}
                onChange={(e: string | null) => {
                  onChange("N_ROB_MESO", e);
                }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_ROB_MESO)}
              unitName={"L"}
            />
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_ROB_GESO"
                label="GESO (L)"
                placeholder="N_ROB_GESO"
                value={props.form.N_ROB_GESO}
                unitName={"L"}
                minValue={-99999}
                maxValue={99999}
                onChange={(e: string | null) => {
                  onChange("N_ROB_GESO", e);
                }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_ROB_GESO)}
              unitName={"L"}
            />
            <Column>
              <CommonNumberFIeld
                isConfirm={props.isConfirm}
                disabled={props.isPreview}
                id="N_ROB_FW"
                label="FW (mt)"
                placeholder=""
                value={props.form.N_ROB_FW}
                unitName={"mt"}
                minValue={0}
                maxValue={9999}
                onChange={(e: string | null) => {
                  onChange("N_ROB_FW", e);
                }}
              />
            </Column>
            <LastReportText
              sx={{ pt: 1.5 }}
              lastData={String(lastReport?.N_ROB_FW)}
              unitName={"mt"}
            />
          </Columns>
          {props.ship?.is_mrv_portal_integration_target && (
            <>
              <RobAdditional {...props} />
              <RobFileUpload onChange={onChange} {...props} />
            </>
          )}
          <Columns
            sx={{
              pl: 2,
              display: "flex",
              alignItems: "end",
            }}
          >
            <TextField
              disabled={props.isPreview}
              sx={{
                margin: "left",
                width: "1000px",
                pt: 4,
                "& .MuiInputBase-input": { color: "#FFFFFF" },
                "& label.Mui-focused": {
                  color: "#20BE89",
                },
                "& label": {
                  mt: 4,
                  color: "#AAAAAA",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#464E5A",
                  },
                  "&:hover fieldset": {
                    borderColor: "#AAAAAA",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#20BE89",
                  },
                },
                textAlign: "left",
              }}
              rows={6}
              id="N_Remark"
              label="Remark"
              placeholder=""
              multiline
              value={props.form.N_Remark || ""}
              inputProps={{
                maxLength: 2000,
              }}
              onChange={(e) => {
                onChange("N_Remark", e.target.value);
              }}
            />
          </Columns>
          <FormBlock_Spare {...props} />
        </Box>
      </Box>
    </Box>
  );
};

export default FormBlock;
