import { useState } from "react";
import { ReportSearchCondition } from "../pages.module";

export const SEARCH_PAGE_LIMIT_CREW = 15;

export const useCrewSearchForm = (
  ship_id: number | null,
  contextReportSearchCondition?: ReportSearchCondition
) => {
  const [data, update] = useState<ReportSearchCondition>(
    contextReportSearchCondition
      ? contextReportSearchCondition
      : {
          ship_id: ship_id,
          imo_no: null,
          Voy: null,
          From_DateTime: null,
          To_DateTime: null,
          pagination: {
            page: 1,
            limit: SEARCH_PAGE_LIMIT_CREW,
          },
        }
  );

  return {
    data,
    update,
  };
};
