import * as React from 'react'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material'
import Box from '@mui/material/Box'

type Props = {
  children?: React.ReactNode
  sx?: SxProps<Theme>
}

const Column = (props: Props): React.ReactElement => {
  return <Box sx={{ pt: 2, pl: 2, ...props.sx }}>{props.children}</Box>
}

export default Column
