import { TableCell, TableRow } from "@mui/material";
import { emptyToHyphen } from "../../../../utils/formats";
import CustomTableCell from "../../../atoms/CustomTableCell";
import {
  lightGray,
  mediumGray,
  steelBlue,
  white,
} from "../../../../utils/color";

type TableDataRowProps = {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  unitName?: string;
  previousData?: string | number | null;
};

export const TableDataRow = (props: TableDataRowProps) => {
  return (
    <TableRow>
      <CustomTableCell sx={{ color: "#AAAAAA", width: "250px" }}>
        {props.title}
      </CustomTableCell>
      <CustomTableCell />
      <CustomTableCell
        colSpan={2}
        sx={{ wordBreak: "break-word", whiteSpace: "pre-line" }}
      >
        {emptyToHyphen(props.data)}
        <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
          {props.unitName || ""}
        </span>
      </CustomTableCell>
      <CustomTableCell sx={{ color: "#AAAAAA" }}>
        {props.previousData}
        <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
          {props.previousData ? props.unitName : ""}
        </span>
      </CustomTableCell>
    </TableRow>
  );
};

type Table2DataRowProps = {
  topTitle: string;
  title1: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data1: any;
  unitName?: string;
  previousData1?: string;
  title2: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data2: any;
  previousData2?: string;
};

export const Table2DataRow = (props: Table2DataRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell
          rowSpan={2}
          sx={{
            verticalAlign: "top",
            color: "#AAAAAA",
            borderBottom: "1px solid #464E5A",
          }}
        >
          {props.topTitle}
        </TableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title1}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data1)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData1}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData1 ? props.unitName : ""}
          </span>
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title2}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data2)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData2}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData2 ? props.unitName : ""}
          </span>
        </CustomTableCell>
      </TableRow>
    </>
  );
};

type Table3DataRowProps = {
  topTitle: string;
  title1: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data1: any;
  unitName?: string;
  previousData1?: string | number | null;
  title2: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data2: any;
  previousData2?: string | number | null;
  title3: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data3: any;
  previousData3?: string | number | null;
};

export const Table3DataRow = (props: Table3DataRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell
          rowSpan={3}
          sx={{
            verticalAlign: "top",
            color: "#AAAAAA",
            borderBottom: "1px solid #464E5A",
          }}
        >
          {props.topTitle}
        </TableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title1}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data1)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData1}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData1 ? props.unitName : ""}
          </span>
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title2}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data2)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData2}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData2 ? props.unitName : ""}
          </span>
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title3}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data3)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData3}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData3 ? props.unitName : ""}
          </span>
        </CustomTableCell>
      </TableRow>
    </>
  );
};

type Table4DataRowProps = {
  topTitle: string;
  title1: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data1: string | number | null;
  title2: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data2: string | number | null;
  title3: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data3: string | number | null;
  title4: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data4: string | number | null;
  unitName1?: string;
  unitName2?: string;
  unitName3?: string;
};

export const Table4DataRow = (props: Table4DataRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell
          rowSpan={4}
          sx={{
            verticalAlign: "top",
            color: mediumGray,
            borderBottom: `1px solid ${steelBlue}`,
          }}
        >
          {props.topTitle}
        </TableCell>
        <CustomTableCell sx={{ color: lightGray }}>
          {props.title1}
        </CustomTableCell>
        <CustomTableCell colSpan={2} sx={{ color: lightGray }}>
          {emptyToHyphen(props.data1)}
        </CustomTableCell>
        <CustomTableCell sx={{ color: lightGray }}></CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: lightGray }}>
          {props.title2}
        </CustomTableCell>
        <CustomTableCell colSpan={2} sx={{ color: lightGray }}>
          {emptyToHyphen(props.data2)}
          <span style={{ color: lightGray, paddingLeft: 5 }}>
            {props.unitName1 || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: lightGray }}></CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: lightGray }}>
          {props.title3}
        </CustomTableCell>
        <CustomTableCell colSpan={2} sx={{ color: lightGray }}>
          {emptyToHyphen(props.data3)}
          <span style={{ color: lightGray, paddingLeft: 5 }}>
            {props.unitName2 || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: lightGray }}></CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: lightGray }}>
          {props.title4}
        </CustomTableCell>
        <CustomTableCell colSpan={2} sx={{ color: lightGray }}>
          {emptyToHyphen(props.data4)}
          <span style={{ color: lightGray, paddingLeft: 5 }}>
            {props.unitName3 || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: lightGray }}></CustomTableCell>
      </TableRow>
    </>
  );
};

type Table6DataRowProps = {
  topTitle: string;
  title1: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data1: any;
  previousData1?: string | number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title2: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data2: any;
  previousData2?: string | number | null;
  title3: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data3: any;
  previousData3?: string | number | null;
  title4: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data4: any;
  previousData4?: string | number | null;
  title5: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data5: any;
  previousData5?: string | number | null;
  title6: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data6: any;
  previousData6?: string | number | null;
  unitName1?: string;
  unitName2?: string;
};

export const Table6DataRow = (props: Table6DataRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell
          rowSpan={6}
          sx={{
            verticalAlign: "top",
            color: "#AAAAAA",
            borderBottom: "1px solid #464E5A",
          }}
        >
          {props.topTitle}
        </TableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title1}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data1)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName1 || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData1}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData1 ? props.unitName1 : ""}
          </span>
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title2}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data2)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName1 || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData2}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData2 ? props.unitName1 : ""}
          </span>
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title3}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data3)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName2 || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData3}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData3 ? props.unitName2 : ""}
          </span>
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title4}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data4)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName2 || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData4}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData4 ? props.unitName2 : ""}
          </span>
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title5}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data5)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName2 || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData5}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData5 ? props.unitName2 : ""}
          </span>
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.title6}
        </CustomTableCell>
        <CustomTableCell colSpan={2}>
          {emptyToHyphen(props.data6)}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.unitName1 || ""}
          </span>
        </CustomTableCell>
        <CustomTableCell sx={{ color: "#AAAAAA" }}>
          {props.previousData6}
          <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
            {props.previousData6 ? props.unitName1 : ""}
          </span>
        </CustomTableCell>
      </TableRow>
    </>
  );
};

type TableAnyDataRowProps = {
  topTitle: string;
  title: string[];
  data: (string | number | null | undefined)[];
  previousData?: (string | number)[];
  unitName: string[];
};

export const TableAnyDataRow = (props: TableAnyDataRowProps) => {
  if (props.title.length === 0) return <></>;

  return (
    <>
      {props.title.map((v, index) => {
        return (
          <TableRow key={index}>
            {index == 0 && (
              <TableCell
                rowSpan={props.title.length}
                sx={{
                  verticalAlign: "top",
                  color: lightGray,
                  borderBottom: `1px solid ${steelBlue}`,
                }}
              >
                {props.topTitle}
              </TableCell>
            )}
            <CustomTableCell sx={{ color: lightGray }}>
              {props.title[index]}
            </CustomTableCell>
            <CustomTableCell
              colSpan={2}
              sx={{
                color: white,
                borderBottomColor: `${
                  index === props.title.length - 1 ? steelBlue : ""
                }`,
              }}
            >
              {emptyToHyphen(props.data[index])}
              <span style={{ color: lightGray, paddingLeft: 5 }}>
                {props.unitName[index] || ""}
              </span>
            </CustomTableCell>
            <CustomTableCell sx={{ color: lightGray }}>
              {props.previousData && props.previousData[index]}
              <span style={{ color: lightGray, paddingLeft: 5 }}>
                {props.previousData ? props.unitName[index] : ""}
              </span>
            </CustomTableCell>
          </TableRow>
        );
      })}
    </>
  );
};

type SpareTableDataRowProps = {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  unitName?: string | null;
  previousData?: string;
};

export const SpareDataRow = (props: SpareTableDataRowProps) => {
  return (
    <TableRow>
      <CustomTableCell sx={{ color: "#AAAAAA", width: "250px" }}>
        {props.title}
      </CustomTableCell>
      <CustomTableCell
        colSpan={2}
        sx={{ wordBreak: "break-word", whiteSpace: "pre-line" }}
      >
        {emptyToHyphen(props.data)}
        <span style={{ color: "#AAAAAA", paddingLeft: 5 }}>
          {props.unitName || ""}
        </span>
      </CustomTableCell>
      <CustomTableCell sx={{ color: "#AAAAAA" }}></CustomTableCell>
      <CustomTableCell />
    </TableRow>
  );
};
