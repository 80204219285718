import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { ArrivalDexie } from "../../dexie/Arrival";
import { formatToYYYYMMDDHHMM } from "../../utils/formats";
import CustomTableCell from "./CustomTableCell";
import Nowrap from "./Nowrap";
import NowrapGray from "./NowrapGray";

type Props = {
  isOpen: boolean;
  report: ArrivalDexie | undefined;
  id: string;
  voy: string;
  DateTime: string;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteFrontData(id: number): void;
};

const DeleteModalDepature: FC<Props> = (props: Props) => {
  const handleClose = () => props.setIsModalOpen(false);

  if (!props.report || props.report.id === undefined || !props.report?.data)
    return <></>;

  const deleteId = props.report.id;

  const onClickDelete = () => {
    props.deleteFrontData(deleteId);
    handleClose();
  };

  const style = {
    color: "#FFFFFF",
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 630,
    bgcolor: "#27292E",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography sx={{ fontSize: "25px", py: 3 }}>
          Do you really want to delete this data?
        </Typography>

        <Table size="small" sx={{ mx: 1, color: "#FFFFFF" }}>
          <TableHead>
            <TableRow>
              <CustomTableCell sx={{ width: "200px" }}>
                <NowrapGray>ID</NowrapGray>
              </CustomTableCell>
              <CustomTableCell sx={{ width: "200px" }}>
                <NowrapGray>Voy#</NowrapGray>
              </CustomTableCell>
              <CustomTableCell sx={{ width: "200px" }}>
                <NowrapGray>{`Date & Time`}</NowrapGray>
              </CustomTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <CustomTableCell>
                <Nowrap>{props.id}</Nowrap>
              </CustomTableCell>
              <CustomTableCell>
                <Nowrap>{props.voy}</Nowrap>
              </CustomTableCell>
              <CustomTableCell>
                <Nowrap>{formatToYYYYMMDDHHMM(props.DateTime)}</Nowrap>
              </CustomTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button
          color="error"
          sx={{
            textTransform: "none",
            mt: 4,
            width: 150,
            fontFamily: ["Public Sans"].join(","),
            color: "#FFFFFF",
            "& .MuiInputBase-input": { color: "#FFFFFF" },
            "& .MuiTextField-root": { color: "#FFFFFF" },

            "& label.Mui-focused": {
              color: "#20BE89",
            },
            "& label.MuiInputLabel-root": {
              color: "#AAAAAA",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#20BE89",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#464E5A",
              },
              "&:hover fieldset": {
                borderColor: "#AAAAAA",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#20BE89",
              },
            },
          }}
          variant="contained"
          disabled={false}
          onClick={onClickDelete}
        >
          Delete
        </Button>
        <Button
          sx={{
            textTransform: "none",
            ml: 2,
            mt: 4,
            width: 150,
            backgroundColor: "#9FA6B0",
            fontFamily: ["Public Sans"].join(","),
            color: "#FFFFFF",
            "& .MuiInputBase-input": { color: "#FFFFFF" },
            "& .MuiTextField-root": { color: "#FFFFFF" },

            "& label.Mui-focused": {
              color: "#20BE89",
            },
            "& label.MuiInputLabel-root": {
              color: "#AAAAAA",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#20BE89",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#464E5A",
              },
              "&:hover fieldset": {
                borderColor: "#AAAAAA",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#20BE89",
              },
            },
          }}
          variant="contained"
          disabled={false}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteModalDepature;
