import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Template from "../../../templates/Crew/Arrival/ServerUpdate";
import { AdditionalROBForArrival, Form } from "./Create/types";
import Default, { useShip } from "../../../layouts/DefaultCrew";
import {
  LorB,
  useGetArrivalQuery,
  GetArrivalQuery,
  LorU,
} from "../../../../gen/graphql/types";
import { pageData, useContextIDs } from "../../../../App";
import { emptyToDoubleQuotation } from "../../../../utils/formats";
import Loading from "../../../atoms/Loading";
import { useArrivalForm } from "./arrival.module";
import { filterArrUndefinedNull } from "../../../../utils/type-checks";

const useGetReport = (report_id: number, ship_id: number) => {
  const input = { id: report_id, ship_id };
  const { data: response, loading: isLoading } = useGetArrivalQuery({
    variables: { input },
    fetchPolicy: "network-only",
  });
  const defaultTimeZone = response?.getArrival?.A_Time_Zone || "+00:00";
  const form = useMemo(() => mapToFormArrivalData(response), [response]);

  return {
    isLoading,
    form,
    defaultTimeZone,
  };
};

const mapToFormArrivalData = (response?: GetArrivalQuery): Form | null => {
  if (!response) return null;

  const report = response.getArrival;

  if (!report) return null;

  return {
    A_Vessel_Name: report.A_Vessel_Name,
    A_Voy: String(report.A_Voy),
    A_LorB: report.A_LorB || LorB.Ballast,
    A_Arrival_Port: String(report.A_Arrival_Port),
    A_EU_UK_port: report.A_EU_UK_port,
    A_Port_L_U: report.A_Port_L_U || LorU.N,
    A_UNLOC: emptyToDoubleQuotation(report.A_UNLOC),
    A_Arrival_Date_Time: report.A_Arrival_Date_Time,
    A_SB_or_Stop: report.A_SB_or_Stop,
    A_TimeZone_PorN: String(report?.A_Time_Zone).slice(0, 1),
    A_TimeZone_HH: String(report?.A_Time_Zone).slice(1, 3),
    A_TimeZone_MM: String(report?.A_Time_Zone).slice(4, 6),
    A_ETB: report.A_ETB,
    A_ETC: report.A_ETC,
    A_ETD: report.A_ETD,
    A_Berthed: report.A_Berthed,
    A_ROB_at_Arrival_FO: report.A_ROB_at_Arrival_FO,
    A_ROB_at_Arrival_MGO: report.A_ROB_at_Arrival_MGO,
    A_ROB_at_Arrival_MECO: report.A_ROB_at_Arrival_MECO,
    A_ROB_at_Arrival_MESO: report.A_ROB_at_Arrival_MESO,
    A_ROB_at_Arrival_GESO: report.A_ROB_at_Arrival_GESO,
    A_ROB_at_Arrival_FW: report.A_ROB_at_Arrival_FW,
    A_Hrs_since_Last_Noon: report.A_Hrs_since_Last_Noon,
    A_Distance_by_OG: report.A_Distance_by_OG,
    A_FO_Consumption_per_day_ME: report.A_FO_Consumption_per_day_ME,
    A_FO_Consumption_per_day_GE: report.A_FO_Consumption_per_day_GE,
    A_FO_Consumption_per_day_B_and_Inc:
      report.A_FO_Consumption_per_day_B_and_Inc,
    A_MGO_Consumption_per_day_ME: report.A_MGO_Consumption_per_day_ME,
    A_MGO_Consumption_per_day_GE: report.A_MGO_Consumption_per_day_GE,
    A_MGO_Consumption_per_day_B_and_Inc:
      report.A_MGO_Consumption_per_day_B_and_Inc,
    A_ROB_FO: report.A_ROB_FO,
    A_ROB_MGO: report.A_ROB_MGO,
    A_ROB_MECO: report.A_ROB_MECO,
    A_ROB_MESO: report.A_ROB_MESO,
    A_ROB_GESO: report.A_ROB_GESO,
    A_ROB_FW: report.A_ROB_FW,
    A_Additional_ROB: filterArrUndefinedNull(
      report.A_Additional_ROB?.map((v) => {
        if (!v) return null;
        return {
          id: v.id,
          A_Fuel_Type: v.A_Fuel_Type,
          A_Fuel_volume: v.A_Fuel_volume,
        } as unknown as AdditionalROBForArrival;
      })
    ),
    A_Draft_Fore: report.A_Draft_Fore,
    A_Draft_Aft: report.A_Draft_Aft,
    A_FO_CO2_emission: report.A_FO_CO2_emission,
    A_MGO_CO2_emission: report.A_MGO_CO2_emission,
    A_Remark: report.A_Remark || "",
    A_Spare_1: emptyToDoubleQuotation(report.A_Spare_1),
    A_Spare_1_unit_name: emptyToDoubleQuotation(report.A_Spare_1_unit_name),
    A_Spare_2: emptyToDoubleQuotation(report.A_Spare_2),
    A_Spare_2_unit_name: emptyToDoubleQuotation(report.A_Spare_2_unit_name),
    A_Spare_3: emptyToDoubleQuotation(report.A_Spare_3),
    A_Spare_3_unit_name: emptyToDoubleQuotation(report.A_Spare_3_unit_name),
    A_Spare_4: emptyToDoubleQuotation(report.A_Spare_4),
    A_Spare_4_unit_name: emptyToDoubleQuotation(report.A_Spare_4_unit_name),
    A_Spare_5: emptyToDoubleQuotation(report.A_Spare_5),
    A_Spare_5_unit_name: emptyToDoubleQuotation(report.A_Spare_5_unit_name),
    A_Spare_6: emptyToDoubleQuotation(report.A_Spare_6),
    A_Spare_6_unit_name: emptyToDoubleQuotation(report.A_Spare_6_unit_name),
    A_Spare_7: emptyToDoubleQuotation(report.A_Spare_7),
    A_Spare_7_unit_name: emptyToDoubleQuotation(report.A_Spare_7_unit_name),
    A_Spare_8: emptyToDoubleQuotation(report.A_Spare_8),
    A_Spare_8_unit_name: emptyToDoubleQuotation(report.A_Spare_8_unit_name),
    A_Spare_9: emptyToDoubleQuotation(report.A_Spare_9),
    A_Spare_9_unit_name: emptyToDoubleQuotation(report.A_Spare_9_unit_name),
    A_Spare_10: emptyToDoubleQuotation(report.A_Spare_10),
    A_Spare_10_unit_name: emptyToDoubleQuotation(report.A_Spare_10_unit_name),
    A_Spare_11: emptyToDoubleQuotation(report.A_Spare_11),
    A_Spare_11_unit_name: emptyToDoubleQuotation(report.A_Spare_11_unit_name),
    A_Spare_12: emptyToDoubleQuotation(report.A_Spare_12),
    A_Spare_12_unit_name: emptyToDoubleQuotation(report.A_Spare_12_unit_name),
    A_Spare_13: emptyToDoubleQuotation(report.A_Spare_13),
    A_Spare_13_unit_name: emptyToDoubleQuotation(report.A_Spare_13_unit_name),
    A_Spare_14: emptyToDoubleQuotation(report.A_Spare_14),
    A_Spare_14_unit_name: emptyToDoubleQuotation(report.A_Spare_14_unit_name),
    A_Spare_15: emptyToDoubleQuotation(report.A_Spare_15),
    A_Spare_15_unit_name: emptyToDoubleQuotation(report.A_Spare_15_unit_name),
    A_Spare_16: emptyToDoubleQuotation(report.A_Spare_16),
    A_Spare_16_unit_name: emptyToDoubleQuotation(report.A_Spare_16_unit_name),
    A_Spare_17: emptyToDoubleQuotation(report.A_Spare_17),
    A_Spare_17_unit_name: emptyToDoubleQuotation(report.A_Spare_17_unit_name),
    A_Spare_18: emptyToDoubleQuotation(report.A_Spare_18),
    A_Spare_18_unit_name: emptyToDoubleQuotation(report.A_Spare_18_unit_name),
    A_Spare_19: emptyToDoubleQuotation(report.A_Spare_19),
    A_Spare_19_unit_name: emptyToDoubleQuotation(report.A_Spare_19_unit_name),
    A_Spare_20: emptyToDoubleQuotation(report.A_Spare_20),
    A_Spare_20_unit_name: emptyToDoubleQuotation(report.A_Spare_20_unit_name),
  };
};

const ServerUpdate = (): React.ReactElement => {
  const { reportId } = useParams<"reportId">();
  const report_id = Number(reportId);
  const { ship_id } = useContextIDs();
  const navigate = useNavigate();
  const ship = useShip();
  const [isConfirm, setIsConfirm] = useState(false);
  const location = useLocation();

  if (!report_id) navigate("/");

  const formData = useGetReport(report_id, ship_id);

  const form = useArrivalForm([location.state?.form, formData.form]);

  const onUpdateForm = (value: Form) => {
    form.update(value);
  };

  const onConfirm = () => {
    form.hasErrorScrollUp();
    setIsConfirm(true);
    if (form.hasError) return;
    navigate(pageData.serverUpdateArrivalConfirm.path(String(report_id)), {
      state: { form: form.data },
    });
  };

  return (
    <Default title="Noon Report" selectedTitle={"Arrival"}>
      {!formData.isLoading && !form.isLoading ? (
        <Template
          defaultTimeZone={formData.defaultTimeZone}
          form={form.data}
          reportId={reportId || ""}
          hasFormError={form.hasError}
          onUpdateForm={onUpdateForm}
          onConfirm={onConfirm}
          ship={ship}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
        />
      ) : (
        <Loading isLoading={true} />
      )}
    </Default>
  );
};

export default ServerUpdate;
