import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schemaSample = z.object({
  textSample: z.preprocess(
    (val) => (val === "" || val === undefined ? null : val),
    z.string().nullable()
  ),
  numberSample: z.preprocess(
    (val) => (val === "" || val === undefined ? null : Number(val)),
    z.number().min(0).max(100).nullable()
  ),
  dateSample: z.preprocess(
    (val) => (val === "" ? null : val),
    z.coerce.date().nullable()
  ),
  selectSample: z.preprocess(
    (val) => (!val ? "" : val),
    z.coerce.string().nullable()
  ),
  radioSample: z.preprocess(
    (val) => (val === "" || val === undefined ? null : val),
    z.coerce.string().nullable()
  ),
});

export type schemaSample = z.infer<typeof schemaSample>;

const initialValues = {
  textSample: null,
  numberSample: null,
  dateSample: null,
  selectSample: "1",
  radioSample: "testRadio1",
};

export const useRhfSampleForm = () => {
  const basicInfoForm = useForm<schemaSample>({
    mode: "onChange",
    resolver: zodResolver(schemaSample),
    defaultValues: initialValues,
  });

  return basicInfoForm;
};
